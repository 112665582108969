import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Notify } from "../../../../constants/utils";
import {
  createShipmentMethodsTypes,
  allActiveShipmentMethodsTypes,
  searchShipmentMethodsTypes,
  deleteShipmentMethodsTypes,
  updateShipmentMethodsTypes,
  getShipmentMethodsType,
  getAllShipmentMethodsTypes,
} from "../../../../store/actions/catalogActions";
import { shipmentMethodTypesReducer } from "../../../../store/reducers/catalogReducer";

export class ListShipmentMethodTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      sequenceNum: "",
      shipmentMethodTypeId: "",
      description: "",
      isDisabled: false,
      price: "",
      _id: "",
      active:true,
    };
  }
  async componentDidMount() {
    this.props.all();

    this.setState({
      data: [],
    });
    if (this.props.location.search) {
      const method = this.props.location.search.split(":")[1];
      const shipmentMethodTypes = Array.from(this.props.shipmentMethodTypes);
      var carrierShipmentMethod = shipmentMethodTypes.filter(
        (carrierShipmentMethod) => {
          return carrierShipmentMethod.name == method;
        }
      );
      const {
        name,
        sequence,
        description,
        _id,
        price,
          active
      } = carrierShipmentMethod[0];

      await this.setState({
        shipmentMethodTypeId: name,
        sequenceNum: sequence,
        description: description,
        price: price,
        isDisabled: true,
        active,
        _id: _id,
      });
    }
    // this.props.create({
    //   name: "Night_time_shipment892",
    //   description: "night time shipment detail",
    //   sequence: 1,
    //   isActive: true,
    //   isDeleted: false,
    // });
    // console.log(this.props.get(3));
    //this.props.allActive();
    //this.props.search({ name: "Night_time_shipment891" });
    // this.props.update({
    //   name: "Night_time_shipment891",
    //   description: "changed to night time delivery deep in",
    // });
    //this.props.delete({ isActive: false, isDeleted: true });
  }

  render() {
    const shipmentMethodTypes = Array.from(this.props.shipmentMethodTypes);
    return (
      <div id="content-main-section" class="leftonly">
        <div id="centerdiv" class="no-clear">
          <div class="button-bar tab-bar">
            <ul>
              <li>
                <ul>
                  <li class="selected">
                    <a href="/catalog/control/ListShipmentMethodTypes">
                      Shipment Method Types
                    </a>
                  </li>
                  <li>
                    <a href="/catalog/control/ListQuantityBreaks">
                      Quantity Breaks
                    </a>
                  </li>
                  <li>
                    <a href="/catalog/control/ListCarrierShipmentMethods">
                      Carrier Shipment Methods
                    </a>
                  </li>
                  <li>
                    <a href="/catalog/control/ListTimeSlots">
                      Product Time Slots
                    </a>
                  </li>
                  <li>
                    <a href="/catalog/control/ListCarrierAllocation">
                      Carrier Allocation
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div> </div>
          <div id="screenlet_1" class="screenlet">
            <div class="screenlet-title-bar">
              <ul>
                <li class="h3">List Shipment Method Types</li>
              </ul>
            </div>
            <div id="screenlet_1_col" class="screenlet-body">
              <div class="nav-pager">
                <ul>
                  <li class="nav-first-disabled">First</li>
                  <li class="nav-previous-disabled">Previous</li>
                  <li class="nav-page-select">
                    Page
                    <select
                      name="page"
                      size="1"
                      onchange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1=200&amp;VIEW_INDEX_1='+this.value)"
                    >
                      <option selected="selected" value="0">
                        1
                      </option>
                    </select>
                  </li>
                  <li class="nav-next-disabled">Next</li>
                  <li class="nav-last-disabled">Last</li>
                  <li class="nav-pagesize">
                    <select
                      name="pageSize"
                      size="1"
                      onchange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')"
                    >
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option selected="selected" value="200">
                        200
                      </option>
                    </select>
                    Items per page
                  </li>
                  <li class="nav-displaying">Displaying 1 - 23 of 23</li>
                </ul>
              </div>
              <table class="basic-table" cellspacing="0">
                <tbody>
                  <tr class="header-row">
                    <td>
                      <label
                        id="ListShipmentMethodTypes_shipmentMethodTypeId_title"
                        for="ListShipmentMethodTypes_shipmentMethodTypeId"
                      >
                        _id
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListShipmentMethodTypes_shipmentMethodTypeId_title"
                        for="ListShipmentMethodTypes_shipmentMethodTypeId"
                      >
                        Shipment Method Type Id
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListShipmentMethodTypes_description_title"
                        for="ListShipmentMethodTypes_description"
                      >
                        Description
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListShipmentMethodTypes_sequenceNum_title"
                        for="ListShipmentMethodTypes_sequenceNum"
                      >
                        Sequence Num
                      </label>
                    </td>
                    <td> </td>
                  </tr>
                  {shipmentMethodTypes &&
                    shipmentMethodTypes.map((method) => {
                      return (
                        <tr>
                          <td>{method._id}</td>
                          <td>
                            <Link
                              to={
                                "/catalog/control/ListShipmentMethodTypes?name:" +
                                method.name
                              }
                              title={method.name}
                            >
                              {method.name}
                            </Link>
                          </td>

                          <td>{method.description}</td>
                          <td>{method.sequence}</td>
                          <td>
                            <form
                              action="/catalog/control/deleteShipmentMethodType"
                              method="post"
                              name="ListShipmentMethodTypes_o_0_0_o_deleteLink"
                            >
                              <input
                                name="VIEW_SIZE_1"
                                type="hidden"
                                value="200"
                              />
                              <input
                                name="VIEW_INDEX_1"
                                type="hidden"
                                value="0"
                              />
                              <input
                                name="shipmentMethodType_Id"
                                type="hidden"
                                value={method._id}
                              />
                            </form>
                            <a
                              class="buttontext"
                              href
                              onClick={(e) => this.handleRemove(e, method._id)}
                            >
                              Remove
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <div class="nav-pager">
                <ul>
                  <li class="nav-first-disabled">First</li>
                  <li class="nav-previous-disabled">Previous</li>
                  <li class="nav-page-select">
                    Page
                    <select
                      name="page"
                      size="1"
                      onchange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1=200&amp;VIEW_INDEX_1='+this.value)"
                    >
                      <option selected="selected" value="0">
                        1
                      </option>
                    </select>
                  </li>
                  <li class="nav-next-disabled">Next</li>
                  <li class="nav-last-disabled">Last</li>
                  <li class="nav-pagesize">
                    <select
                      name="pageSize"
                      size="1"
                      onchange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')"
                    >
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option selected="selected" value="200">
                        200
                      </option>
                    </select>
                    Items per page
                  </li>
                  <li class="nav-displaying">Displaying 1 - 23 of 23</li>
                </ul>
              </div>
            </div>
          </div>
          <div id="screenlet_2" class="screenlet">
            <div class="screenlet-title-bar">
              <ul>
                <li class="h3">Edit Shipment Method Types</li>
              </ul>
            </div>
            <div id="screenlet_2_col" class="screenlet-body">
              <form
                id="EditShipmentMethodType"
                class="basic-form"
                action="/catalog/control/createShipmentMethodType"
                method="post"
                name="EditShipmentMethodType"
                novalidate="novalidate"
              >
                <table class="basic-table" cellspacing="0">
                  <tbody>
                    <tr>
                      <td class="label">
                        <label
                          id="EditShipmentMethodType_shipmentMethodTypeId_title"
                          for="EditShipmentMethodType_shipmentMethodTypeId"
                        >
                          Shipment Method Type Id
                        </label>
                        {}*
                      </td>
                      <td>
                        <input
                          id="EditShipmentMethodType_shipmentMethodTypeId"
                          class="required"
                          maxlength="20"
                          onChange={(e) => this.handleChange(e)}
                          value={this.state.shipmentMethodTypeId}
                          name="shipmentMethodTypeId"
                          size="30"
                          type="readOnly"
                          disabled={this.state.isDisabled}
                        />{" "}
                        *
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="EditShipmentMethodType_description_title"
                          for="EditShipmentMethodType_description"
                        >
                          Description{}*
                        </label>
                      </td>
                      <td>
                        <input
                          id="EditShipmentMethodType_description"
                          class="required"
                          maxlength="250"
                          onChange={(e) => this.handleChange(e)}
                          name="description"
                          value={this.state.description}
                          size="60"
                          type="text"
                        />{" "}
                        *
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="EditShipmentMethodType_description_title"
                          for="EditShipmentMethodType_description"
                        >
                          Price{}*
                        </label>
                      </td>
                      <td>
                        <input
                          id="EditShipmentMethodType_description"
                          class="required"
                          maxlength="250"
                          onChange={(e) => this.handleChange(e)}
                          name="price"
                          value={this.state.price}
                          size="10"
                          type="number"
                        />{" "}
                        *
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <input
                            name={"active"}
                        type={"checkbox"}
                        checked={this.state.active}
                        onChange={(e)=>this.setState(prevState=>{return{
                          active:!prevState.active
                        }})}
                        />
                        <label> isActive</label>
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="EditShipmentMethodType_sequenceNum_title"
                          for="EditShipmentMethodType_sequenceNum"
                        >
                          Sequence Num
                        </label>
                      </td>
                      <td>
                        <input
                          id="EditShipmentMethodType_sequenceNum"
                          name="sequenceNum"
                          onChange={(e) => this.handleChange(e)}
                          value={this.state.sequenceNum}
                          size="6"
                          type="text"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="label"> </td>
                      <td colspan="4">
                        <input
                          class="smallSubmit"
                          name="submitButton"
                          type="submit"
                          onClick={(e) => this.handleSubmit(e)}
                          value="Submit"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleChange = (e) => {
    var value = e.target.value;
    var key = e.target.name;
    this.setState({ [key]: value });
  };
  handleRemove = (e, _id) => {
    this.setState({
      sequenceNum: "",
      shipmentMethodTypeId: "",
      description: "",
      isDisabled: false,
      _id: "",
    });
    this.props.update({ _id: _id, isActive: false, isDeleted: true });
  };
  handleSubmit = (e) => {
    e.preventDefault();

    if (
      this.state.shipmentMethodTypeId === "" ||
      this.state.description === "" ||
      this.state.price === ""
    ) {
      Notify("danger", "Please fill all the required fields", "Required");
      return;
    }

    const {
      _id,
      sequenceNum,
      shipmentMethodTypeId,
      description,
      price,
        active,
    } = this.state;
    let data = {};
    data.active = active;
    data = sequenceNum ? { ...data, sequence: sequenceNum } : data;
    data = shipmentMethodTypeId
      ? { ...data, name: shipmentMethodTypeId }
      : data;
    data = description ? { ...data, description: description } : data;
    data = price ? { ...data, price: price } : data;
    this.setState({
      sequenceNum: "",
      shipmentMethodTypeId: "",
      description: "",
      price: "",
      active:true,
      isDisabled: false,
    });
    if (_id) {
      data = _id ? { ...data, _id: _id } : data;
      this.setState({ _id: "" });
      this.props.update(data);
      this.props.history.push("/catalog/control/ListShipmentMethodTypes");
    } else {
      this.props.create(data);
    }
  };
}

const mapStateToProps = (state) => ({
  submitted: state.catalog?.shipmentMethodTypesReducer?.submitted,
  shipmentMethodTypes:
    state.catalog?.shipmentMethodTypesReducer?.shipment_method_types,
});

const mapDispatchToProps = (dispatch, router) => ({
  all: () => dispatch(getAllShipmentMethodsTypes()),
  create: (data) => dispatch(createShipmentMethodsTypes(data, router)),
  get: (data) => dispatch(getShipmentMethodsType(data, router)),
  allActive: () => dispatch(allActiveShipmentMethodsTypes()),
  search: (data) => dispatch(searchShipmentMethodsTypes(data, router)),
  update: (data) => dispatch(updateShipmentMethodsTypes(data, router)),
  delete: (data) => dispatch(deleteShipmentMethodsTypes(data, router)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListShipmentMethodTypes)
);
