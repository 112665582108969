import React, {Component} from 'react';
import {connect} from 'react-redux';
import CloseIcon from "@material-ui/icons/Close";
import {Modal} from "@material-ui/core";
import {receivePayment} from "../../../store/actions/orderActions";

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        receive:(data)=>dispatch(receivePayment(data))
    };
}

class ReceivePayment extends Component {
    state = {
        mode: '',
        transectionId: '',
        amount: null,
        date: '',
    }
    submit = () => {
        let obj = {
            paymentMode: this.state.mode,
            paidAmount: this.state.amount,
            transactionId: this.state.transectionId,
            paymentDate: this.state.date,
            orderId: this.props.orderId
        }
        this.props.receive(obj);
        this.props.handleClose(true)

    }

    render() {
        let paymentMethods = [
            'Moto',
            'Payfort',
            'Cash'
        ]
        return (
            <Modal
                open={this.props.modalOpen}
                onClose={this.props.handleClose}
                //   style={{ overflow: "scroll" }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div
                    style={{
                        height: "450px",
                        width: "400px",
                        backgroundColor: "white",
                        position: "absolute",
                        top: "50px",
                        border: "2px solid white",
                        left: "600px",
                        //alignItems: "center",
                        display: "flex",
                        overflow: "scroll",
                        flexDirection: "column",
                        alignItems: 'center'
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            backgroundColor: "#527894",
                            borderRadius: "5px",
                            width: "100%",
                            height: "30px",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        <CloseIcon
                            onClick={(e) => this.props.handleClose(e)}
                            style={{
                                color: "#6caad4",
                                marginRight: "5px",
                                backgroundColor: "#e3f1fc",
                                borderRadius: "5px",
                                cursor: "pointer",
                            }}
                        />
                    </div>
                    <div style={{width: '96%', display: 'flex', flexDirection: 'column'}}>
                        <label htmlFor={'payment'}>Payment mode:</label>
                        <select
                            id={'address'}
                            value={this.state.mode}
                            onChange={(e) => this.setState({mode: e.target.value})}
                        >
                            <option value={''}></option>
                            {paymentMethods?.map((method) => (
                                <option value={method}>
                                    {method}
                                </option>
                            ))}
                        </select>

                        <label htmlFor={'tId'}>Transection id:</label>
                        <input
                            id={'tId'}
                            type={'text'}
                            onChange={(e) => this.setState({transectionId: e.target.value})}
                            value={this.state.transectionId}
                        />
                        <label htmlFor={'date'}>Payment Date:</label>
                        <input
                            id={'date'}
                            type={'date'}
                            onChange={(e) => this.setState({date: e.target.value})}
                            value={this.state.date}
                        />
                        <label htmlFor={'amount'}>Amount:</label>
                        <input
                            id={'amount'}
                            type={'number'}
                            onChange={(e) => this.setState({amount: e.target.value})}
                            value={this.state.amount}
                        />
                    </div>
                    <div style={{
                        width: '96%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        marginTop: '10px'
                    }}>
                        <button type={"button"} onClick={() => this.submit()}>Update</button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReceivePayment);