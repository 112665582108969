import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { getOrdersList } from "../../../store/actions/orderActions";

const orderStatusList = ["All", "Waiting", "Processing", "Confirmed", "Held"];

export class Main extends Component {
  componentDidMount() {
    this.props.getOrdersList({});
  }

  onSubmitFindOrders = (e) => {
    e.preventDefault();
    this.props.getOrdersList({});
  };
  render() {
    const { searchedOrders } = this.props;
    console.log("this is searchOrder", searchedOrders);
    return (
      <div id="content-main-section" className="leftonly">
        <div id="centerdiv" className="no-clear">
          <div id="orderLookup" className="screenlet">
            <div className="screenlet-title-bar">
              <ul>
                <li className="h3">Lookup Order(s)</li>
              </ul>
            </div>
            <div className="screenlet-body">
              <form onSubmit={this.onSubmitFindOrders}>
                <input
                  name="changeStatusAndTypeState"
                  type="hidden"
                  value="Y"
                />
                <table className="basic-table" cellspacing="0">
                  <tbody>
                    <tr>
                      <td className="label" align="right">
                        Status
                      </td>
                      <td></td>
                      <td nowrap="nowrap">
                        <div>
                          <input name="viewall" type="checkbox" value="Y" />
                          All{" "}
                          <input name="viewcreated" type="checkbox" value="Y" />
                          Waiting{" "}
                          <input
                            name="viewprocessing"
                            type="checkbox"
                            value="Y"
                          />
                          Processing{" "}
                          <input
                            name="viewapproved"
                            type="checkbox"
                            value="Y"
                          />
                          Confirmed{" "}
                          <input name="viewhold" type="checkbox" value="Y" />
                          Held{" "}
                          <input
                            name="viewcompleted"
                            type="checkbox"
                            value="Y"
                          />
                          Completed{" "}
                          <input
                            name="viewrejected"
                            type="checkbox"
                            value="Y"
                          />
                          Rejected{" "}
                          <input
                            name="viewcancelled"
                            type="checkbox"
                            value="Y"
                          />
                          Cancelled
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="label" align="right">
                        Type
                      </td>
                      <td></td>
                      <td nowrap="nowrap">
                        <div>
                          <input
                            name="view_SALES_ORDER"
                            type="checkbox"
                            value="Y"
                          />{" "}
                          Sales Order{" "}
                          <input
                            name="view_PURCHASE_ORDER"
                            type="checkbox"
                            value="Y"
                          />{" "}
                          Purchase Order
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="label" align="right">
                        Filter
                      </td>
                      <td></td>
                      <td nowrap="nowrap">
                        <div>
                          <input
                            name="filterInventoryProblems"
                            type="checkbox"
                            value="Y"
                          />{" "}
                          Inventory problems{" "}
                          <input
                            name="filterAuthProblems"
                            type="checkbox"
                            value="Y"
                          />{" "}
                          Authorisation Problems
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="label" align="right">
                        Filter (purchase orders)
                      </td>
                      <td></td>
                      <td nowrap="nowrap">
                        <div>
                          <input
                            name="filterPartiallyReceivedPOs"
                            type="checkbox"
                            value="Y"
                          />{" "}
                          partially received{" "}
                          <input
                            name="filterPOsOpenPastTheirETA"
                            type="checkbox"
                            value="Y"
                          />{" "}
                          open past their ETA{" "}
                          <input
                            name="filterPOsWithRejectedItems"
                            type="checkbox"
                            value="Y"
                          />{" "}
                          with rejected items
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" align="center">
                        {" "}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" align="center">
                        <button className="buttontext" type="submit">
                          Find
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
          <div id="findOrdersList" className="screenlet">
            <div className="screenlet-title-bar">
              <ul>
                <li className="h3">Order List</li>
              </ul>
            </div>
            <div className="screenlet-body">
              <table className="basic-table hover-bar" cellspacing="0">
                <tbody>
                  <tr className="header-row">
                    <td width="10%">Date</td>
                    <td width="10%">Order Nbr</td>
                    <td width="15%">Customer Name</td>
                    <td width="10%">Customer Mobile</td>
                    <td width="10%">Source</td>
                    <td width="10%">Grand Total</td>
                    <td colspan="2" width="8%">
                      Status
                    </td>
                  </tr>
                  {searchedOrders &&
                    searchedOrders.map((order) => {
                      return (
                        <tr>
                          <td>{order?.createdDate}</td>
                          <td>
                            <Link
                              to={`/ordermgr/control/orderview?orderId=${order._id}`}
                            >
                              {order?._id}
                            </Link>
                          </td>
                          <td>{order?.senderName}</td>
                          <td>{order?.senderPhone}</td>
                          <td></td>
                          <td>{order?.amount}</td>
                          <td>{order?.orderStatus}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <table className="basic-table" cellspacing="0">
                <tbody>
                  <tr>
                    <td></td>
                    <td align="right"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  searchedOrders: state.orderReducer.searchedOrders,
});

export default withRouter(connect(mapStateToProps, { getOrdersList })(Main));
