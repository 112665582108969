import {
  postRequest,
  putRequest,
  getRequest,
  deleteRequest,
} from "../services";
import { redirect, Notify } from "../../constants/utils";

//sitemap
export const generateSitemap = (id) => async (dispatch, getState) => {
  return await getRequest("sitemap/generator")
    .then((res) => {
      Notify("success", "Sitemap generated");
    })
    .catch((err) => {
      Notify("danger", "Requested Resource no Found");
    });
};

// Catalogs
export const createCatalog = (data, router) => async (dispatch, getState) => {
  var token = getState().auth?.token;
  dispatch({ type: "CATALOG_SUBMIT", payload: true });
  await postRequest("catalogs/create", data, true, {
    "access-token": token,
  }).then((response) => {
    redirect(router, "/catalog/control/FindCatalog");
  });
  dispatch({ type: "CATALOG_SUBMIT", payload: false });
};
export const updateCatalog = (data, router) => async (dispatch, getState) => {
  var token = getState().auth?.token;
  dispatch({ type: "CATALOG_SUBMIT", payload: true });
  await putRequest("catalogs/update/" + data?._id, data, true, {
    "access-token": token,
  }).then((response) => {
    redirect(router, "/catalog/control/FindCatalog");
  });
  dispatch({ type: "CATALOG_SUBMIT", payload: false });
};
export const searchCatalog = (data) => async (dispatch, getState) => {
  var token = getState().auth?.token;
  dispatch({ type: "CATALOG_SUBMIT", payload: true });
  await putRequest("catalogs/search", data, true, {
    "access-token": token,
  }).then((response) => {
    dispatch({ type: "CATALOG_SEARCHED", payload: response.data });
  });
  dispatch({ type: "CATALOG_SUBMIT", payload: false });
};
export const getCatalog = (id) => async (dispatch, getState) => {
  return await getRequest("catalogs/get/" + id);
};
export const getCatalogCategories = (data) => async (dispatch, getState) => {
  var token = getState().auth?.token;
  putRequest("categories/search", data, false, {
    "access-token": token,
  }).then((res) => {
    dispatch({ type: "CATALOG_CATEGORIES", payload: res?.data });
  });
};
export const deleteCategories =
  (id, callback) => async (dispatch, getState) => {
    var token = getState().auth?.token;
    dispatch({ type: "CATEGORIES_DELETE_REQUEST", deleteLoading: true });
    deleteRequest(`categories/delete/${id}`, {}, true, {
      "access-token": token,
    })
      .then((res) => {
        dispatch({ type: "CATEGORIES_DELETE", payload: res?.data });
        callback && callback();
      })
      .catch((err) => {
        dispatch({ type: "CATEGORIES_DELETE_ERROR" });
      });
  };
export const allActiveCatalogs = () => async (dispatch) => {
  getRequest("catalogs/allActive").then((res) => {
    dispatch({ type: "CATALOG_ACTIVE", payload: res?.data });
  });
};
//Meta
export const createMeta = (data, callback) => async (dispatch, getState) => {
  var token = getState().auth?.token;
  dispatch({ type: "META_SUBMIT", payload: true });
  try {
    await postRequest("metadata/create", data, true, {
      "access-token": token,
    }).then((response) => {
      callback && callback();
      // redirect(router, "/catalog/control/FindMeta");
    });
    dispatch({ type: "META_SUBMIT", payload: false });
  } catch (e) {
    Notify("danger", "Product Duplicated Successfully");
    dispatch({ type: "META_SUBMIT", payload: false });
  }
};
export const updateMeta = (data, callback) => async (dispatch, getState) => {
  dispatch({ type: "META_SUBMIT", payload: true });
  var token = getState().auth?.token;
  try {
    await putRequest("metadata/update", data, true, {
      "access-token": token,
    }).then((response) => {
      callback && callback();
    });
    dispatch({ type: "META_SUBMIT", payload: false });
  } catch (e) {
    dispatch({ type: "META_SUBMIT", payload: false });
  }
};
export const allActiveMeta = () => async (dispatch) => {
  getRequest("metadata/allActive?admin=true").then((res) => {
    dispatch({ type: "META_ACTIVE", payload: res.data });
  });
};
export const getMeta = (id, cb) => async (dispatch, getState) => {
  return await getRequest(`metadata/findByUniqueKey?uniqueKey=${id}`).then(
    (res) => {
      cb && cb(res?.data);
    }
  );
};
export const deleteMeta = (id, callback) => async (dispatch, getState) => {
  var token = getState().auth?.token;
  dispatch({ type: "META_DELETE_REQUEST", deleteLoading: true });
  deleteRequest(`metadata/delete/${id}`, {}, true, {
    "access-token": token,
  })
    .then((res) => {
      dispatch({ type: "META_DELETE", payload: res?.data });
      callback && callback();
    })
    .catch((err) => {
      dispatch({ type: "META_DELETE_ERROR" });
    });
};
//End Meta

// Categories
export const createCategories =
  (data, router, callback) => async (dispatch, getState) => {
    var token = getState().auth?.token;
    dispatch({ type: "CATEGORY_SUBMIT", payload: true });
    try {
      await postRequest("categories/create", data, true, {
        "access-token": token,
      }).then((response) => {
        callback && callback();
        redirect(router, "/catalog/control/FindCategory");
      });
      dispatch({ type: "CATEGORY_SUBMIT", payload: false });
    } catch (e) {
      dispatch({ type: "CATEGORY_SUBMIT", payload: false });
    }
  };
export const updateCategory =
  (data, router, route = false, callback) =>
  async (dispatch, getState) => {
    const { location } = router;
    dispatch({ type: "CATEGORY_SUBMIT", payload: true });
    var token = getState().auth?.token;
    try {
      await putRequest("categories/update/" + data?._id, data, true, {
        "access-token": token,
      }).then((response) => {
        callback && callback();
        redirect(
          router,
          route
            ? "/catalog/control/FindCategory"
            : location.pathname + location.search
        );
      });
      dispatch({ type: "CATEGORY_SUBMIT", payload: false });
    } catch (e) {
      dispatch({ type: "CATEGORY_SUBMIT", payload: false });
    }
  };
export const getCategory = (id, cb) => async (dispatch, getState) => {
  return await getRequest("categories/get/" + id).then((res) => {
    cb && cb(res?.data);
  });
};
export const searchCategories = (data) => async (dispatch, getState) => {
  dispatch({ type: "CATEGORY_SUBMIT", payload: true });
  try {
    var token = getState().auth?.token;
    var res = await putRequest("categories/search", data, false, {
      "access-token": token,
    }).then((res) => {
      dispatch({ type: "CATEGORIES_SEARCHED", payload: res?.data });
    });
    dispatch({ type: "CATEGORY_SUBMIT", payload: false });
    return res;
  } catch (e) {
    dispatch({ type: "CATEGORY_SUBMIT", payload: false });
  }
};
export const allActiveCategories = () => async (dispatch) => {
  getRequest("categories/getAllActive?admin=true").then((res) => {
    dispatch({ type: "CATEGORIES_ACTIVE", payload: res.data });
  });
};
//Shipping
export const createShipmentMethodsTypes =
  (data, router) => async (dispatch, getState) => {
    var token = getState().auth?.token;
    dispatch({ type: "SHIPMENT_METHOD_TYPES_SUBMIT", payload: true });
    await postRequest("shipment-methods-types/create", data, true, {
      "access-token": token,
    })
      .then((response) => {
        console.log(
          "this is response of createShipmentMethodTypes",
          response.data
        );
        dispatch({
          type: "SHIPMENT_METHOD_ADD",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({ type: "SHIPMENT_METHODS_TYPES", payload: false });
      });
  };

export const searchShipmentMethodsTypes =
  (data) => async (dispatch, getState) => {
    var token = getState().auth?.token;
    console.log("this is searchReq body", data);
    await putRequest(
      "shipment-methods-types/search?limit=100&offset=0",
      data,
      true,
      {
        "access-token": token,
      }
    ).then((response) => {
      console.log("this is response of search", response.data);
      dispatch({
        type: "SHIPMENT_METHOD_TYPES_ALL_ACTIVE",
        payload: response.data,
      });
    });
  };

export const deleteShipmentMethodsTypes =
  (id) => async (dispatch, getState) => {
    var token = getState().auth?.token;
    dispatch({ type: "SHIPMENT_METHODS_TYPES_DELETE", payload: true });
    await putRequest(`shipment-methods-types/delete/${id}`, true, {
      "access-token": token,
    }).then((response) => {
      dispatch({
        type: "SHIPMENT_METHOD_TYPES_ALL_ACTIVE",
        payload: response.data,
      });
    });
    dispatch({ type: "SHIPMENT_METHODS_TYPES_SEARCHED", payload: false });
  };

export const updateShipmentMethodsTypes =
  (data, router) => async (dispatch, getState) => {
    var token = getState().auth?.token;

    await putRequest(`shipment-methods-types/update/${data._id}`, data, true, {
      "access-token": token,
    }).then((response) => {
      dispatch({
        type: "SHIPMENT_METHOD_TYPES_ALL_ACTIVE",
        payload: response.data,
      });
    });
  };

export const getShipmentMethodsType = (id) => async (dispatch) => {
  await getRequest(`shipment-methods-types/get/${id}`).then((response) => {});
};
export const allActiveShipmentMethodsTypes = () => async (dispatch) => {
  getRequest("shipment-methods-types/allActive").then((res) => {
    dispatch({ type: "SHIPMENT_METHOD_TYPES_ALL_ACTIVE", payload: res?.data });
  });
};
export const getAllShipmentMethodsTypes =
  (data) => async (dispatch, getState) => {
    var token = getState().auth?.token;
    await getRequest("shipment-methods-types/all", true, {
      "access-token": token,
    }).then((response) => {
      dispatch({
        type: "SHIPMENT_METHOD_TYPES_ALL_ACTIVE",
        payload: response.data,
      });
    });
  };
//carrier Shipment
export const createCarrierShipmentMethodsTypes =
  (data, router) => async (dispatch, getState) => {
    var token = getState().auth?.token;
    dispatch({ type: "CARRIER_SHIPMENT_METHOD_TYPES_SUBMIT", payload: true });
    await postRequest("carrier-shipment-methods/create", data, true, {
      "access-token": token,
    })
      .then((response) => {
        dispatch({
          type: "CARRIER_SHIPMENT_METHOD_ADD",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "CARRIER_SHIPMENT_METHOD_TYPES_SUBMIT",
          payload: false,
        });
      });
  };

export const updateCarrierShipmentMethodsTypes =
  (data, router) => async (dispatch, getState) => {
    var token = getState().auth?.token;

    await putRequest(
      `carrier-shipment-methods/update/${data._id}`,
      data,
      true,
      {
        "access-token": token,
      }
    ).then((response) => {
      dispatch({
        type: "CARRIER_SHIPMENT_METHOD_TYPES_ALL_ACTIVE",
        payload: response.data,
      });
    });
  };

export const allActiveCarrierShipmentMethodsTypes = () => async (dispatch) => {
  getRequest("carrier-shipment-methods/allActive").then((res) => {
    dispatch({
      type: "CARRIER_SHIPMENT_METHOD_TYPES_ALL_ACTIVE",
      payload: res?.data,
    });
  });
};
//List Time Slots

export const createTimeSlots = (data, router) => async (dispatch, getState) => {
  var token = getState().auth?.token;
  dispatch({ type: "TIME_SLOTS_SUBMIT", payload: true });
  await postRequest("time-slots/create", data, true, {
    "access-token": token,
  })
    .then((response) => {
      dispatch({
        type: "TIME_SLOTS_ADD",
        payload: response.data,
      });
    })
    .catch(() => {
      dispatch({
        type: "TIME_SLOTS_SUBMIT",
        payload: false,
      });
    });
};

export const updatetimeSlots = (data, router) => async (dispatch, getState) => {
  var token = getState().auth?.token;

  await putRequest(`time-slots/update/${data._id}`, data, true, {
    "access-token": token,
  }).then((response) => {
    dispatch({
      type: "TIME_SLOTS_ALL_ACTIVE",
      payload: response.data,
    });
  });
};

export const allActiveTimeSlots = () => async (dispatch) => {
  getRequest("time-slots/allActive").then((res) => {
    console.log(res.data);
    dispatch({
      type: "TIME_SLOTS_ALL_ACTIVE",
      payload: res?.data,
    });
  });
};

//Carrier allocation
export const createCarrierAllocation =
  (data, router) => async (dispatch, getState) => {
    var token = getState().auth?.token;
    dispatch({ type: "CARRIER_ALLOCAITON_SUBMIT", payload: true });
    await postRequest("carrier-allocations/create", data, true, {
      "access-token": token,
    })
      .then((response) => {
        dispatch({
          type: "CARRIER_ALLOCATION_ADD",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "CARRIER_ALLOCAITON_SUBMIT",
          payload: false,
        });
      });
  };

export const updateCarrierAllocation =
  (data, router) => async (dispatch, getState) => {
    var token = getState().auth?.token;

    await putRequest(`carrier-allocations/update/${data._id}`, data, true, {
      "access-token": token,
    }).then((response) => {
      dispatch({
        type: "CARRIER_ALLOCATION",
        payload: response.data,
      });
    });
  };

export const allActiveCarrierAllocation = () => async (dispatch) => {
  getRequest("carrier-allocations/allActive").then((res) => {
    console.log("this is response", res.data);
    dispatch({
      type: "CARRIER_ALLOCATION",
      payload: res?.data,
    });
  });
};

// Products
export const createProduct = (data, router) => async (dispatch, getState) => {
  var token = getState().auth?.token;
  dispatch({ type: "PRODUCT_SUBMIT", payload: true });
  await postRequest("products/create", data, true, {
    "access-token": token,
  })
    .then((response) => {
      redirect(router, "/catalog/control/FindProduct");
    })
    .catch(() => {
      dispatch({ type: "PRODUCT_SUBMIT", payload: false });
    });
};
export const createBulkProduct =
  (data, router) => async (dispatch, getState) => {
    var token = getState().auth?.token;
    dispatch({ type: "PRODUCT_SUBMIT", payload: true });
    await postRequest("products/bulkcreateBySlug", data, true, {
      "access-token": token,
    })
      .then((response) => {
        redirect(router, "/catalog/control/FindProduct");
      })
      .catch(() => {
        dispatch({ type: "PRODUCT_SUBMIT", payload: false });
      });
  };
export const uploadBulkImages =
  (data, router) => async (dispatch, getState) => {
    var token = getState().auth?.token;
    // dispatch({ type: "PRODUCT_SUBMIT", payload: true });
    console.log("request sent");
    await postRequest("products/bulkImage", data, true, {
      "access-token": token,
    })
      .then((response) => {
        // redirect(router, "/catalog/control/FindProduct");
      })
      .catch(() => {
        // dispatch({ type: "PRODUCT_SUBMIT", payload: false });
      });
  };
// Navigation
export const searchNavigation =
  (data, router, pageSize, offset) => async (dispatch, getState) => {
    var token = getState().auth?.token;
    dispatch({ type: "NAVIGATION_SUBMIT", payload: true });
    data.isAdmin = true;
    await putRequest(
      "products/admin-search?limit=" + pageSize + "&offset=" + offset,
      data,
      true,
      {
        "access-token": token,
      }
    ).then((response) => {
      dispatch({
        type: "NAVIGATION_SEARCHED",
        payload: response.data?.products,
      });
      dispatch({
        type: "NAVIGATION_OTHER_DATA",
        payload: response.data?.otherData,
      });
    });
    dispatch({ type: "NAVIGATION_SUBMIT", payload: false });
  };

// End Navigation
export const searchProduct =
  (data, router, pageSize, offset) => async (dispatch, getState) => {
    var token = getState().auth?.token;
    dispatch({ type: "PRODUCT_SUBMIT", payload: true });
    data.isAdmin = true;
    await putRequest(
      "products/admin-search?limit=" + pageSize + "&offset=" + offset,
      data,
      true,
      {
        "access-token": token,
      }
    ).then((response) => {
      dispatch({ type: "PRODUCT_SEARCHED", payload: response.data?.products });
      dispatch({
        type: "PRODUCT_OTHER_DATA",
        payload: response.data?.otherData,
      });
    });
    dispatch({ type: "PRODUCT_SUBMIT", payload: false });
  };

export const deleteProduct = (id, callback) => async (dispatch, getState) => {
  var token = getState().auth?.token;
  deleteRequest(`products/delete/${id}`, {}, true, {
    "access-token": token,
  }).then((res) => {
    dispatch({ type: "PRODUCT_DELETE", payload: res?.data });
    callback && callback();
  });
};

export const advancesearchProduct =
  (data, router) => async (dispatch, getState) => {
    var token = getState().auth?.token;
    dispatch({ type: "PRODUCT_SUBMIT", payload: true });
    await putRequest("products/advancedsearch", data, true, {
      "access-token": token,
    }).then((response) => {
      dispatch({ type: "PRODUCT_SEARCHED", payload: response.data?.products });
      redirect(router, "/catalog/control/SearchView?advancedSearched=Y");
    });
    dispatch({ type: "PRODUCT_SUBMIT", payload: false });
  };

export const getProduct = (id) => async (dispatch) => {
  return await getRequest("products/get/" + id + "/true?isAdmin=true");
};
export const getProductPrice = (id) => async (dispatch) => {
  return await getRequest("products/getproductprice/" + id);
};

export const updateProduct = (data, router) => async (dispatch, getState) => {
  var token = getState().auth?.token;

  let id = data._id;
  // console.log('id before', id);
  if (data instanceof FormData) {
    for (var pair of data.entries()) {
      if (pair[0] === "_id") {
        id = pair[1];
      }
    }
  }

  dispatch({ type: "PRODUCT_SUBMIT", payload: true });
  await putRequest("products/update/" + id, data, true, {
    "access-token": token,
  }).then((response) => {
    redirect(router, "/catalog/control/FindProduct");
  });
  dispatch({ type: "PRODUCT_SUBMIT", payload: false });
};
export const duplicateProduct =
  (data, router) => async (dispatch, getState) => {
    var token = getState().auth?.token;
    dispatch({ type: "PRODUCT_SUBMIT", payload: true });
    await postRequest("products/duplicate", data, false, {
      "access-token": token,
    }).then((response) => {
      redirect(router, "/catalog/control/FindProduct");
      Notify("success", "Product Duplicated Successfully");
    });
    dispatch({ type: "PRODUCT_SUBMIT", payload: false });
  };
export const createProductPromo =
  (data, router) => async (dispatch, getState) => {
    var token = getState().auth?.token;
    dispatch({ type: "PRODUCT_PROMO", payload: true });
    try {
      await postRequest("promos/create", data, false, {
        "access-token": token,
      }).then((response) => {
        dispatch({ type: "PRODUCT_PROMO_LIST_ADD", payload: response.data });
        redirect(router, "/catalog/control/FindProductPromo");
        Notify("success", "Product Promo Created Successfully");
      });
    } catch (err) {}
  };

export const getAllProductPromo =
  (params = "") =>
  async (dispatch, getState) => {
    dispatch({ type: "PRODUCT_PROMO_LIST_TYPE", payload: true });
    try {
      let url = params ? `promos?filterType=${params}` : "promos";
      let response = await getRequest(url);
      dispatch({ type: "PRODUCT_PROMO_LIST", payload: response.data });
      dispatch({ type: "PRODUCT_PROMO_LIST_TYPE", payload: false });
    } catch (err) {
      dispatch({ type: "PRODUCT_PROMO_LIST_TYPE", payload: false });
    }
  };
export const getPromoByID = (id) => async (dispatch, getState) => {
  try {
    return await getRequest(`promos/getone/${id}`);
  } catch (e) {
    Notify("danger", "Requested Resource no Found");
  }
};
export const updatePromo =
  (data, router, route = false) =>
  async (dispatch, getState) => {
    dispatch({ type: "PROMO_SUBMIT", payload: true });
    var token = getState().auth?.token;
    try {
      await putRequest("promos/update/" + data?._id, data, true, {
        "access-token": token,
      }).then((response) => {
        redirect(router, "/catalog/control/FindProductPromo");
      });
      dispatch({ type: "PROMO_SUBMIT", payload: false });
    } catch (e) {
      dispatch({ type: "PROMO_SUBMIT", payload: false });
    }
  };

export const createPromoRules =
  (data, router) => async (dispatch, getState) => {
    var token = getState().auth?.token;
    try {
      await postRequest("promoRules/create", data, false, {
        "access-token": token,
      }).then((response) => {
        //redirect(router, "/catalog/control/FindProductPromo");
        dispatch({ type: "PROMO_RULES_ADD", payload: response.data });
        Notify("success", "Product Promo Created Successfully");
      });
    } catch (err) {}
  };
export const updatePromoRule =
  (data, router, route = false) =>
  async (dispatch, getState) => {
    dispatch({ type: "PROMO_SUBMIT", payload: true });
    var token = getState().auth?.token;
    try {
      await putRequest("promoRules/update/" + data?._id, data, true, {
        "access-token": token,
      }).then((response) => {
        dispatch({ type: "PROMO_RULES", payload: response.data });
        Notify("success", "Rule updated successfully.");
        //redirect(router, "/catalog/control/FindProductPromo");
      });
    } catch (e) {}
  };

export const getPromoRule = (id) => async (dispatch, getState) => {
  var token = getState().auth?.token;
  try {
    await getRequest(`promoRules/getone/${id}`, {
      "access-token": token,
    }).then((response) => {
      dispatch({ type: "PROMO_RULES", payload: response.data });
      // redirect(router, "/catalog/control/FindProductPromo");
    });
  } catch (e) {}
};
export const getAllActivePromoRules = (id) => async (dispatch, getState) => {
  var token = getState().auth?.token;
  try {
    await getRequest(`promoRules/allActive/${id}`, {
      "access-token": token,
    }).then((response) => {
      console.log("this is promoRules", response.data);
      dispatch({ type: "PROMO_RULES", payload: response.data });
      // redirect(router, "/catalog/control/FindProductPromo");
    });
  } catch (e) {}
};

export const createRuleConditions =
  (data, router) => async (dispatch, getState) => {
    var token = getState().auth?.token;
    dispatch({ type: "PRODUCT_PROMO", payload: true });
    try {
      await postRequest("ruleConditions/create", data, true, {
        "access-token": token,
      }).then((response) => {
        //redirect(router, "/catalog/control/FindProductPromo");
        dispatch({ type: "RULE_CONDITIONS_ADD", payload: response.data });
        Notify("success", "Rule Condition Created Successfully");
      });
    } catch (err) {}
  };
export const updateRuleConditions =
  (data, router, route = false) =>
  async (dispatch, getState) => {
    var token = getState().auth?.token;
    try {
      await putRequest("ruleConditions/update/" + data?._id, data, true, {
        "access-token": token,
      }).then((response) => {
        dispatch({ type: "RULE_CONDITIONS", payload: response.data });
        Notify("success", "Condition Updated Successfully");
        //redirect(router, "/catalog/control/FindProductPromo");
      });
    } catch (e) {}
  };

export const getRuleCondition = (id) => async (dispatch, getState) => {
  var token = getState().auth?.token;
  try {
    await getRequest(`ruleConditions/getone/${id}`, {
      "access-token": token,
    }).then((response) => {
      dispatch({ type: "RULE_CONDITIONS", payload: response.data });
      // redirect(router, "/catalog/control/FindProductPromo");
    });
  } catch (e) {}
};

export const getAllActiveRuleConditions =
  (id) => async (dispatch, getState) => {
    var token = getState().auth?.token;
    try {
      await getRequest(`ruleConditions/allActive/${id}`, {
        "access-token": token,
      }).then((response) => {
        console.log("ruleConditions", response.data);
        dispatch({ type: "RULE_CONDITIONS", payload: response.data });
        // redirect(router, "/catalog/control/FindProductPromo");
      });
    } catch (e) {}
  };

//ruleAttachments
export const createRuleAttachments =
  (data, router) => async (dispatch, getState) => {
    var token = getState().auth?.token;
    try {
      await postRequest("ruleAttachments/create", data, false, {
        "access-token": token,
      }).then((response) => {
        console.log("this is response", response.data);
        //redirect(router, "/catalog/control/FindProductPromo");
        // dispatch({ type: "RULE_CONDITIONS_ADD", payload: response.data });
        Notify("success", "Rule Attachment Created");
      });
    } catch (err) {}
  };

export const getAllActiveRuleAttachments =
  (id) => async (dispatch, getState) => {
    var token = getState().auth?.token;
    try {
      await getRequest(`ruleAttachments/allActive/${id}`, {
        "access-token": token,
      }).then((response) => {
        console.log("ruleAttachments", response.data);
        // dispatch({ type: "PROMO_RULES", payload: response.data });
        // redirect(router, "/catalog/control/FindProductPromo");
      });
    } catch (e) {}
  };

export const updateRuleAttachments =
  (data, router, route = false) =>
  async (dispatch, getState) => {
    var token = getState().auth?.token;
    try {
      await putRequest("ruleAttachments/update/" + data?._id, data, true, {
        "access-token": token,
      }).then((response) => {
        // dispatch({ type: "RULE_CONDITIONS", payload: response.data });
        //redirect(router, "/catalog/control/FindProductPromo");
      });
    } catch (e) {}
  };

//Promo Codes
export const createPromoCodes =
  (data, router) => async (dispatch, getState) => {
    var token = getState().auth?.token;
    try {
      console.log("reached in action", data);
      await postRequest("promoCodes/create", data, false, {
        "access-token": token,
      }).then((response) => {
        dispatch({ type: "PROMO_CODES_ADD", payload: response.data });
        Notify("success", "Product Promo Code Created Successfully");
        redirect(router, "/catalog/control/PromoCodes?promoId=" + data.promoId);
      });
    } catch (err) {}
  };
export const createBulkPromoCodes =
  (data, router) => async (dispatch, getState) => {
    var token = getState().auth?.token;
    await postRequest("promoCodes/bulkcreate", data, true, {
      "access-token": token,
    })
      .then((response) => {
        dispatch({ type: "PROMO_CODES_ADD", payload: response.data });
        // redirect(router, "/catalog/control/FindProduct");
      })
      .catch(() => {});
  };
export const getAllActivePromoCodes = (id) => async (dispatch, getState) => {
  var token = getState().auth?.token;
  try {
    await getRequest(`promoCodes/allActive/${id}`, {
      "access-token": token,
    }).then((response) => {
      console.log("ruleAttachments", response.data);
      dispatch({ type: "PROMO_CODES", payload: response.data });
      // redirect(router, "/catalog/control/FindProductPromo");
    });
  } catch (e) {}
};

export const updatePromoCodes =
  (data, router, route = false) =>
  async (dispatch, getState) => {
    var token = getState().auth?.token;
    try {
      await putRequest("promoCodes/update/" + data?._id, data, true, {
        "access-token": token,
      }).then((response) => {
        console.log(data.promoId);
        dispatch({ type: "PROMO_CODES", payload: response.data });
        redirect(router, "/catalog/control/PromoCodes?promoId=" + data.promoId);
      });
    } catch (e) {}
  };
export const getPromoCode = (id) => async (dispatch, getState) => {
  var token = getState().auth?.token;
  try {
    await getRequest(`promoCodes/getone/${id}`, {
      "access-token": token,
    }).then((response) => {
      console.log("this is getPromoCode", response.data);
      // dispatch({ type: "RULE_CONDITIONS", payload: response.data });
      // redirect(router, "/catalog/control/FindProductPromo");
    });
  } catch (e) {}
};

export const uploadImage = (file, router) => async (dispatch, getState) => {
  var token = getState().auth?.token;
  let fileName = file?.name;
  let fileType = file?.type;
  var uData = {
    fileName: fileName,
    fileType: fileType,
  };
  dispatch({ type: "PRODUCT_SUBMIT", payload: true });
  await postRequest("products/upload-to-s3", uData, false, {
    "access-token": token,
  }).then((response) => {
    var returnData = response.data;
    var signedRequest = returnData.signedRequest;
    var url = returnData.url;
    console.log("Received a signed request " + signedRequest, "url:", url);
    var options = {
      headers: {
        "Content-Type": fileType,
      },
    };
    putRequest(signedRequest, file, false, options, false).then((res) =>
      console.log("Response from s3", res, url)
    );
  });
  dispatch({ type: "PRODUCT_SUBMIT", payload: false });
};
export const createProductPrice =
  (data, router) => async (dispatch, getState) => {
    var token = getState().auth?.token;

    dispatch({ type: "PRODUCT_PRICE", payload: true });
    try {
      await postRequest("prices/create", data, false, {
        "access-token": token,
      }).then((response) => {
        redirect(router, "/catalog/control/FindProduct");
        Notify("success", "Product Price Created Successfully");
      });
      dispatch({ type: "PRODUCT_PRICE", payload: false });
    } catch (err) {
      dispatch({ type: "PRODUCT_PRICE", payload: false });
    }
  };
export const updateProductPrice =
  (data, router, route = false) =>
  async (dispatch, getState) => {
    const { location } = router;
    dispatch({ type: "PRICE_SUBMIT", payload: true });
    var token = getState().auth?.token;
    try {
      await putRequest("prices/update/" + data?._id, data, true, {
        "access-token": token,
      }).then((response) => {
        redirect(router, "/catalog/control/FindProduct");
        Notify("success", "Product Price Updated Successfully");
      });
      dispatch({ type: "PRICE_SUBMIT", payload: false });
    } catch (e) {
      dispatch({ type: "PRICE_SUBMIT", payload: false });
    }
  };
export const deleteProductPrice =
  (id, router, route = false) =>
  async (dispatch, getState) => {
    const { location } = router;
    dispatch({ type: "PRICE_SUBMIT", payload: true });
    var token = getState().auth?.token;
    try {
      await putRequest("prices/delete/" + id, null, true, {
        "access-token": token,
      }).then((response) => {
        redirect(router, "/catalog/control/FindProduct");
        Notify("success", "Product Price Deleted Successfully");
      });
      dispatch({ type: "PRICE_SUBMIT", payload: false });
    } catch (e) {
      dispatch({ type: "PRICE_SUBMIT", payload: false });
    }
  };
export const getAllProductPrice = (id) => async (dispatch, getState) => {
  dispatch({ type: "PRODUCT_PRICE_LIST_TYPE", payload: true });
  try {
    let url = `prices/all/${id}`;
    let response;
    return (response = await getRequest(url));
    dispatch({ type: "PRODUCT_PRICE_LIST", payload: response.data });
    dispatch({ type: "PRODUCT_PRICE_LIST_TYPE", payload: false });
  } catch (err) {
    dispatch({ type: "PRODUCT_PRICE_LIST_TYPE", payload: false });
  }
};

export const attachFeaturesToProduct = (data, router) => async (dipatch) => {
  const token = localStorage.getItem("token");
  postRequest("products/attachFeatures", data, false, {
    "access-token": token,
  }).then((res) => {
    Notify("success", "Feature is attached to this Product");
  });
};

// Features
export const createFeatureTypes = (data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  postRequest("features/type/create", data, false, {
    "access-token": token,
  }).then((response) => {
    redirect(router, "/catalog/control/EditFeatureTypes");
    Notify("success", "Feature Type updated successfully");
  });
};

export const searchFeatureTypes = (data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  getRequest("features/type/search", data, false, {
    "access-token": token,
  }).then((response) => {
    dispatch({ type: "SEARCH_FEATURE_TYPES", payload: response.data });
    Notify("success", "Feature Types are listed");
  });
};

export const getFeatureTypes = (id, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  getRequest("features/type/list/" + id, false, { "access-token": token }).then(
    (response) => {
      dispatch({ type: "GET_FEATURE_TYPE", payload: response.data });
      // redirect(router, '/catalog/control/EditFeatureType');
      // Notify('success', 'Fea');
    }
  );
};

export const getAllFeatureTypes = (data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  getRequest("features/type/list/", data, false, {
    "access-token": token,
  }).then((response) => {
    dispatch({ type: "GET_All_FEATURE_TYPES", payload: response.data });
    // redirect(router, '/catalog/control/EditFeatureType');
    // Notify('success', 'Feature Types are listed');
  });
};

export const updateFeatureTypes = (id, data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  putRequest("features/type/update/" + id, data, false, {
    "access-token": token,
  }).then((response) => {
    redirect(router, "/catalog/control/EditFeatureTypes");
    Notify("success", "Feature Type is updated");
  });
};

export const createFeatureCategory = (data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  postRequest("features/category/create", data, false, {
    "access-token": token,
  }).then((res) => {
    redirect(router, "/catalog/control/EditFeatureCategories");
    Notify("success", "Feature Category is created");
  });
};

export const searchFeatureCategory = (data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  getRequest("features/category/search", data, false, {
    "access-token": token,
  }).then((res) => {
    dispatch({ type: "SEARCH_FEATURE_CATEGORY", payload: res.data });
  });
};

export const getAllFeatureCategory = (data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  getRequest("features/category/list", data, false, {
    "access-token": token,
  }).then((res) => {
    dispatch({ type: "GET_ALL_FEATURE_CATEGORIES", payload: res.data });
  });
};

export const getFeatureCategroy = (id, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  getRequest("features/category/list/" + id, false, {
    "access-token": token,
  }).then((res) => {
    dispatch({ type: "GET_FEATURE_CATEGORY", payload: res.data });
  });
};

export const updateFeatureCategory = (id, data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  putRequest("features/category/update/" + id, data, false, {
    "access-token": token,
  }).then((res) => {
    // dispatch({type: 'UPDATE_FEATURE_CATEGORY', payload: res.data});
    redirect(router, "/catalog/control/EditFeatureCategories");
    Notify("success", "Feaure Category is updated");
  });
};

//Features
export const createFeature = (data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  postRequest("features/create", data, false, { "access-token": token }).then(
    (res) => {
      redirect(router, "/catalog/control/EditFeatureCategories");
      Notify("success", "Feature is created");
    }
  );
};

export const getAllFeatures = (data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  getRequest("features/list", data, false, { "access-token": token }).then(
    (res) => {
      dispatch({ type: "GET_ALL_FEATURES", payload: res.data });
    }
  );
};

export const getOneFeature = (id, data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  getRequest("features/list/" + id, data, false, {
    "access-token": token,
  }).then((res) => {
    dispatch({ type: "GET_ONE_FEATURE", payload: res.data });
  });
};

export const updateFeature = (id, data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  putRequest("features/update/" + id, data, false, {
    "access-token": token,
  }).then((res) => {
    dispatch({ type: "UPDATE_FEATURE", payload: res.data });
    redirect(router, "/catalog/control/EditFeatureCategories");
    Notify("success", "Feature is updated");
  });
};

export const createInteraction = (data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  postRequest("features/interaction/create", data, false, {
    "access-token": token,
  }).then((res) => {
    // redirect(router, "/catalog/control/EditFeatureInterActions");
    Notify("success", "interaction is created");
  });
};

export const searchInteraction = (data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  getRequest("features/interaction/search", data, false, {
    "access-token": token,
  }).then((res) => {
    dispatch({ type: "SEARCH_INTERACION", payload: res.data });
  });
};

export const getAllInteractions = (data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  getRequest("features/interaction/list", data, false, {
    "access-token": token,
  }).then((res) => {
    dispatch({ type: "ALL_INTERACTIONS", payload: res.data });
  });
};

export const createGeo = (data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  postRequest("geos/create", data, false, {
    "access-token": token,
  }).then((res) => {
    // redirect(router, "/catalog/control/EditFeatureInterActions");
    Notify("success", "geo is created");
  });
};

export const getGeoOfType = (data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  console.log("type:", data);
  getRequest("geos/type/allActive/" + data, false, {
    "access-token": token,
  }).then((res) => {
    dispatch({ type: "ALL_GEOS", payload: res.data });
  });
};
// export const getAllInteractions = (data, router) => async dispatch => {
//     const token = localStorage.getItem('token');
//     getRequest('features/interaction/list', data, false, {'access-token': token}).then(res => {
//         dispatch({type: 'ALL_INTERACTIONS', payload: res.data});
//     })
// };

export const getFeatureByTypeAndIdCode = (data, router) => async (dipatch) => {
  const token = localStorage.getItem("token");
  return await postRequest("features/getByType", data, false, {
    "access-token": token,
  });
};

export const getAllGeo = (data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  getRequest("geos/allActive/", false, {
    "access-token": token,
  }).then((res) => {
    dispatch({ type: "ALL_OVER_GEOS", payload: res.data });
  });
};

export const getProductsByCategory = (data, cb, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  return await getRequest("products/getByCategory/" + data, false, {
    "access-token": token,
  }).then((res) => {
    cb && cb(res.data);
    return res.data;
  });
};

export const updateProductPrecedence = (data, router) => async (dipatch) => {
  const token = localStorage.getItem("token");
  return await postRequest("products/updatePrecedence", data, true, {
    "access-token": token,
  }).then((res) => {
    console.log("this is updated List", res.data);
  });
};

export const createContent = (data, router) => async (dispatch, getState) => {
  var token = getState().auth?.token;
  await postRequest("content/create", data, false, {
    "access-token": token,
  }).then((response) => {
    dispatch({ type: "ADD_CONTENT", payload: response.data });
  });
};

export const getAllActiveContents = (id, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  putRequest("content/getContent/" + id, {}, true, {
    "access-token": token,
  }).then((res) => {
    dispatch({ type: "ALL_CONTENT", payload: res.data });
  });
};

export const updateContent = (data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");

  putRequest("content/update/" + data.id, data, true, {
    "access-token": token,
  }).then((res) => {
    dispatch({ type: "ALL_CONTENT", payload: res.data });
  });
};

export const addIdentifications = (data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  postRequest("products/addIds", data, true, {
    "access-token": token,
  }).then((res) => {
    dispatch({ type: "ADD_IDENTIFICATIONS", payload: res.data });
  });
};

export const updateIdentifications = (data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  putRequest("products/updateIds/" + data.id, data, true, {
    "access-token": token,
  }).then((res) => {
    dispatch({ type: "ALL_IDENTIFICATIONS", payload: res.data });
  });
};

export const getAllActiveIdentifications =
  (data, router) => async (dispatch) => {
    const token = localStorage.getItem("token");
    putRequest("products/allActiveIdentifications/" + data, data, true, {
      "access-token": token,
    }).then((res) => {
      dispatch({ type: "ALL_IDENTIFICATIONS", payload: res.data });
    });
  };

export const createConfigs = (data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  postRequest("prodcutConfigs/create", data, true, {
    "access-token": token,
  }).then((res) => {
    dispatch({ type: "ALL_CONFIGS", payload: res.data.product });
  });
};

export const getAddonProducts = (data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  putRequest("products/getAddons", data, true, {
    "access-token": token,
  }).then((res) => {
    dispatch({ type: "ALL_ADDONS", payload: res.data });
  });
};

export const getConfigs = (id, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  putRequest("prodcutConfigs/get/" + id, {}, true, {
    "access-token": token,
  }).then((res) => {
    let temp = [];
    if (res?.data?.product) {
      temp = res.data.product;
    }

    dispatch({ type: "ALL_CONFIGS", payload: temp });
  });
};

export const updateConfigs = (data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  putRequest("prodcutConfigs/update/" + data.id, data, true, {
    "access-token": token,
  }).then((res) => {
    dispatch({ type: "ALL_CONFIGS", payload: res.data.product });
  });
};

export const updateContents = (data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  let res = await putRequest(
    "categories/updateContent/" + data.id,
    data,
    true,
    {
      "access-token": token,
    }
  );
  return res.data;
};

export const createAssociation = (data, router) => async (dispatch) => {
  let res = await putRequest("products/attachVariantProduct", data, true);
  dispatch({ type: "ALL_ASSOCIATIONS", payload: res.data });
};
export const getAssociation = (id, router) => async (dispatch) => {
  let res = await getRequest("products/getVariantProduct/" + id, {}, true);
  dispatch({ type: "ALL_ASSOCIATIONS", payload: res.data });
};
export const updateAssociation = (data, router) => async (dispatch) => {
  let res = await putRequest("products/updateAssociations", data, true);
  dispatch({ type: "ALL_ASSOCIATIONS", payload: res.data });
};

export const getAssociationToThis = (id, router) => async (dispatch) => {
  let res = await getRequest(
    "products/getVariantProductToThis/" + id,
    {},
    true
  );
  dispatch({ type: "ALL_ASSOCIATIONS_TO", payload: res.data });
};
export const updateAssociationTo = (data, router) => async (dispatch) => {
  let res = await putRequest("products/updateAssociationsTo", data, true);
  dispatch({ type: "ALL_ASSOCIATIONS_TO", payload: res.data });
};

/** CITIES  */
export const getAllCities = () => async (dispatch, getState) => {
  const token = getState().auth?.token;
  dispatch({ type: "GET_CITIES_START" });
  try {
    const res = await getRequest("cities/allActive", null, false, {
      "access-token": token,
    });
    dispatch({ type: "GET_CITIES_SUCCESS", payload: res?.data || [] });
  } catch (err) {
    console.log(err);
    dispatch({ type: "GET_CITIES_ERROR" });
    Notify("danger", err.message, "Error");
  }
};

export const createCity = (data, callback) => async (dispatch, getState) => {
  const token = getState().auth?.token;
  dispatch({ type: "SUBMITTED", payload: true });
  try {
    const res = await postRequest("cities/create", data, true, {
      "access-token": token,
    });
    if (res?.data) {
      dispatch({ type: "SUBMITTED", payload: false });
      callback();
    }
  } catch (err) {
    console.log(err);
    dispatch({ type: "SUBMITTED", payload: false });
  }
};

export const updateCity = (data, callback) => async (dispatch, getState) => {
  const token = getState().auth?.token;
  dispatch({ type: "SUBMITTED", payload: true });
  try {
    const res = await putRequest("cities/update", data, true, {
      "access-token": token,
    });
    if (res?.data) {
      dispatch({ type: "SUBMITTED", payload: false });
      callback();
    }
  } catch (err) {
    console.log(err);
    dispatch({ type: "SUBMITTED", payload: false });
  }
};

export const deleteCity = (id) => async (dispatch, getState) => {
  const token = getState().auth?.token;
  try {
    const res = await deleteRequest(`cities/delete/${id}`, {}, true, {
      "access-token": token,
    });
    if (res?.data) {
      dispatch(getAllCities());
    }
  } catch (err) {
    console.log(err);
  }
};

/** Create City With Shipment Tpyes For Product */
export const getAllProductCitiesList =
  (productId) => async (dispatch, getState) => {
    const token = getState().auth?.token;
    dispatch({ type: "GET_PRODUCT_CITIES_START" });
    try {
      const res = await getRequest(
        `cityShipment/allActive/${productId}`,
        null,
        false,
        {
          "access-token": token,
        }
      );
      dispatch({
        type: "GET_PRODUCT_CITIES_SUCCESS",
        payload: res?.data || [],
      });
    } catch (err) {
      console.log(err);
      dispatch({ type: "GET_PRODUCT_CITIES_ERROR" });
      Notify("danger", err.message, "Error");
    }
  };

export const createCityShipment =
  (data, callback) => async (dispatch, getState) => {
    const token = getState().auth?.token;
    dispatch({ type: "SUBMITTED", payload: true });
    try {
      const res = await postRequest("cityShipment/create", data, true, {
        "access-token": token,
      });
      if (res?.data) {
        dispatch({ type: "SUBMITTED", payload: false });
        callback();
      }
    } catch (err) {
      // console.log(err);
      dispatch({ type: "SUBMITTED", payload: false });
    }
  };

export const updateCityShipment =
  (data, callback) => async (dispatch, getState) => {
    const token = getState().auth?.token;
    dispatch({ type: "SUBMITTED", payload: true });
    try {
      const res = await putRequest("cityShipment/update", data, true, {
        "access-token": token,
      });
      if (res?.data) {
        dispatch({ type: "SUBMITTED", payload: false });
        callback();
      }
    } catch (err) {
      // console.log(err);
      dispatch({ type: "SUBMITTED", payload: false });
    }
  };

export const deleteCityShipment =
  (payload, callback) => async (dispatch, getState) => {
    const token = getState().auth?.token;
    try {
      const res = await deleteRequest(
        `cityShipment/delete?productId=${payload?.productId}&cityId=${payload?.cityId}`,
        {},
        true,
        {
          "access-token": token,
        }
      );
      if (res?.data) {
        callback();
      }
    } catch (err) {
      console.log(err);
    }
  };
