const initialState = {
  token: null,
  user: {},
  submitted: false,
};
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "STORE_USER":
      var payload = action.payload;
      var token = payload.token;
      var data = {
        ...state,
        user: payload,
        token: token,
      };
      return data;
    case "LOGOUT":
      // localStorage.clear();
      var final = {
        ...state,
        user: {},
        token: null,
      };
      return final;
    case "SUBMITTED":
      return { ...state, submitted: action.payload };
    default:
      return state;
  }
};
export default authReducer;
