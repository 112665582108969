import React, { Component } from 'react';
import { connect } from "react-redux";
import { putRequest } from "../../../store/services";
import { Link } from "react-router-dom";

class SupervisorDashboard extends Component {
    state = {
        foundOrders: []
    }

    async componentDidMount() {
        const response = await putRequest(
            `orders/search`,
            {},
            false,
            { headers: { "access-token": this.props.token } },
            true
        );
        console.log("this response", response.data);
        this.setState({ foundOrders: response.data });
    }

    render() {
        return (
            <div id="content-main-section" class="leftonly">
                <div id="centerdiv" class="no-clear">
                    <form id="lookuporder" method="post" name="lookuporder">
                        <div id="findOrders" class="screenlet">
                            <div class="screenlet-title-bar">
                                <ul>
                                    <li class="h3">Supervisor Dashboard</li>
                                </ul>
                            </div>
                            <div class="screenlet-body">
                                <table class="basic-table hover-bar" cellspacing="0">
                                    <tbody>
                                        <tr class="header-row">
                                            <th width="1%">
                                                <input
                                                    id="checkAllOrders"
                                                    name="checkAllOrders"
                                                    type="checkbox"
                                                    value="1"
                                                />
                                            </th>
                                            <th width="5%">Order Type</th>
                                            <th width="5%">Order Id</th>
                                            <th width="5%">Order Name</th>
                                            <th width="5%">External ID</th>
                                            <th width="5%">Customer Name</th>
                                            <th width="5%">Customer Mobile</th>
                                            <th align="right" width="5%">
                                                Items Ordered
                                    </th>
                                            <th align="right" width="14%">
                                                Order Item Details
                                    </th>
                                            <th align="right" width="10%">
                                                Grand Total
                                    </th>
                                            <th width="5%">Status</th>
                                            <th width="15%">Order Date</th>
                                            <th width="5%">Order Amount</th>
                                            <th width="5%">Party ID</th>
                                            <th width="5%">Source</th>
                                            <th width="5%">Sub Source</th>
                                        </tr>
                                        {this.state.foundOrders && this.state.foundOrders.length ? (
                                            this.state.foundOrders.map((order) => {
                                                return [
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            <Link
                                                                to={`/ordermgr/control/orderview?orderId=${order._id}`}
                                                            >
                                                                {order._id}
                                                            </Link>
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>{order.user.name}</td>
                                                        <td>{order.user.mobile}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>{order.orderStatus}</td>
                                                        <td>{order.createdDate}</td>
                                                        <td>{order.amount}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>,
                                                ];
                                            })
                                        ) : (
                                                <tr>
                                                    <td colSpan="16">
                                                        <h2> !Record Not Found</h2>
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default connect()(SupervisorDashboard);