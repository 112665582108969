import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

export class FindRequest extends Component {
  render() {
    return (
      <div id="content-main-section">
        <div class="page-title">Find Requests</div>
        <div class="button-bar">
          <a class="buttontext create" href="/ordermgr/control/request">
            {" "}
            New Request{" "}
          </a>
        </div>
        <div class="clear"> </div>
        <div id="searchOptions" class="screenlet">
          <div class="screenlet-title-bar">
            <ul>
              <li class="h3">Search Options</li>
              <li class="expanded">
                <a title="Collapse" href>
                  {" "}
                </a>
              </li>
            </ul>
          </div>
          <div id="searchOptions_col" class="screenlet-body">
            <div id="search-options">
              <form
                id="FindRequests"
                class="basic-form"
                action="/ordermgr/control/FindRequest"
                method="post"
                name="FindRequests"
              >
                <input
                  id="FindRequests_noConditionFind"
                  name="noConditionFind"
                  type="hidden"
                  value="Y"
                />
                <table class="basic-table" cellspacing="0">
                  <tbody>
                    <tr>
                      <td class="label">
                        <label
                          id="FindRequests_custRequestId_title"
                          for="FindRequests_custRequestId"
                        >
                          Cust Request Id
                        </label>
                      </td>
                      <td>
                        <select class="selectBox" name="custRequestId_op">
                          <option value="equals">Equals</option>
                          <option value="like">Begins With</option>
                          <option selected="selected" value="contains">
                            Contains
                          </option>
                          <option value="empty">Is Empty</option>
                          <option value="notEqual">Not Equal</option>
                        </select>
                        <input
                          maxlength="20"
                          name="custRequestId"
                          size="20"
                          type="text"
                        />
                        <input
                          checked="checked"
                          name="custRequestId_ic"
                          type="checkbox"
                          value="Y"
                        />
                        Ignore Case
                      </td>
                      <td class="label">
                        <label
                          id="FindRequests_custRequestTypeId_title"
                          for="FindRequests_custRequestTypeId"
                        >
                          Cust Request Type Id
                        </label>
                      </td>
                      <td>
                        <span class="ui-widget">
                          <select
                            id="FindRequests_custRequestTypeId"
                            multiple="multiple"
                            name="custRequestTypeId"
                            size="1"
                          >
                            <option value=""> </option>
                            <option value="RF_BUGFIX">
                              Request For Bug Fix
                            </option>
                            <option value="RF_CATALOG">
                              Request For Catalog
                            </option>
                            <option value="RF_FEATURE">
                              Request For Feature
                            </option>
                            <option value="RF_INFO">
                              Request For Information
                            </option>
                            <option value="RF_PARENT_BACKLOG">
                              Request For Parent Product Backlog
                            </option>
                            <option value="RF_PROD_BACKLOG">
                              Request For Product Backlog Items
                            </option>
                            <option value="RF_PROPOSAL">
                              Request For Proposal
                            </option>
                            <option value="RF_PUR_QUOTE">
                              Request For Purchase Quote
                            </option>
                            <option value="RF_QUOTE">Request For Quote</option>
                            <option value="RF_SCRUM_MEETINGS">
                              Request For Scrum Meetings
                            </option>
                            <option value="RF_SUPPORT">
                              Request For Support
                            </option>
                            <option value="RF_UNPLAN_BACKLOG">
                              Request For Unplan Backlog Items
                            </option>
                          </select>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="FindRequests_statusId_title"
                          for="FindRequests_statusId"
                        >
                          Status ID
                        </label>
                      </td>
                      <td>
                        <span class="ui-widget">
                          <select
                            id="FindRequests_statusId"
                            multiple="multiple"
                            name="statusId"
                            size="1"
                          >
                            <option value=""> </option>
                            <option value="CRQ_DRAFT">Draft</option>
                            <option value="CRQ_SUBMITTED">Submitted</option>
                            <option value="CRQ_ACCEPTED">Accepted</option>
                            <option value="CRQ_REVIEWED">Reviewed</option>
                            <option value="CRQ_COMPLETED">Completed</option>
                            <option value="CRQ_REOPENED">Reopened</option>
                            <option value="CRQ_PENDING">Pending Cust.</option>
                            <option value="CRQ_REJECTED">Rejected</option>
                            <option value="CRQ_CANCELLED">Cancelled</option>
                          </select>
                        </span>
                      </td>
                      <td class="label">
                        <label
                          id="FindRequests_fromPartyId_title"
                          for="FindRequests_fromPartyId"
                        >
                          From Party Id
                        </label>
                      </td>
                      <td>
                        <div id="0_lookupId_FindRequests_fromPartyId_auto">
                          {" "}
                        </div>
                        <span class="field-lookup">
                          <input
                            id="0_lookupId_FindRequests_fromPartyId"
                            class="ui-autocomplete-input"
                            autocomplete="off"
                            name="fromPartyId"
                            size="25"
                            type="text"
                          />
                          <a href id="0_lookupId_button">
                            {" "}
                          </a>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="FindRequests_custRequestDate_title"
                          for="FindRequests_custRequestDate"
                        >
                          Cust Request Date
                        </label>
                      </td>
                      <td>
                        <span class="view-calendar">
                          <input
                            id="custRequestDate_fld0_value"
                            class="hasDatepicker"
                            title="dd-MM-yyyy HH:mm:ss"
                            maxlength="30"
                            name="custRequestDate_fld0_value"
                            size="25"
                            type="text"
                          />
                          <button
                            class="ui-datepicker-trigger"
                            type="button"
                          ></button>
                          <select
                            class="selectBox"
                            name="custRequestDate_fld0_op"
                          >
                            <option value="equals">Equals</option>
                            <option value="sameDay">Same Day</option>
                            <option value="greaterThanFromDayStart">
                              Greater Than From Day Start
                            </option>
                            <option value="greaterThan">Greater Than</option>
                          </select>
                          <input
                            id="custRequestDate_fld1_value"
                            class="hasDatepicker"
                            title="dd-MM-yyyy HH:mm:ss"
                            maxlength="30"
                            name="custRequestDate_fld1_value"
                            size="25"
                            type="text"
                          />
                          <button
                            class="ui-datepicker-trigger"
                            type="button"
                          ></button>
                          <select
                            class="selectBox"
                            name="custRequestDate_fld1_op"
                          >
                            <option value="opLessThan">Less Than</option>
                            <option value="upToDay">Up To Day</option>
                            <option value="upThruDay">Up Through Day</option>
                            <option value="empty">Is Empty</option>
                          </select>
                        </span>
                      </td>
                      <td class="label">
                        <label
                          id="FindRequests_responseRequiredDate_title"
                          for="FindRequests_responseRequiredDate"
                        >
                          Response Required Date
                        </label>
                      </td>
                      <td>
                        <span class="view-calendar">
                          <input
                            id="responseRequiredDate_fld0_value"
                            class="hasDatepicker"
                            title="dd-MM-yyyy HH:mm:ss"
                            maxlength="30"
                            name="responseRequiredDate_fld0_value"
                            size="25"
                            type="text"
                          />
                          <button
                            class="ui-datepicker-trigger"
                            type="button"
                          ></button>
                          <select
                            class="selectBox"
                            name="responseRequiredDate_fld0_op"
                          >
                            <option selected="selected" value="equals">
                              Equals
                            </option>
                            <option value="sameDay">Same Day</option>
                            <option value="greaterThanFromDayStart">
                              Greater Than From Day Start
                            </option>
                            <option value="greaterThan">Greater Than</option>
                          </select>
                          <input
                            id="responseRequiredDate_fld1_value"
                            class="hasDatepicker"
                            title="dd-MM-yyyy HH:mm:ss"
                            maxlength="30"
                            name="responseRequiredDate_fld1_value"
                            size="25"
                            type="text"
                          />
                          <button
                            class="ui-datepicker-trigger"
                            type="button"
                          ></button>
                          <select
                            class="selectBox"
                            name="responseRequiredDate_fld1_op"
                          >
                            <option value="opLessThan">Less Than</option>
                            <option value="upToDay">Up To Day</option>
                            <option value="upThruDay">Up Through Day</option>
                            <option value="empty">Is Empty</option>
                          </select>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="FindRequests_custRequestName_title"
                          for="FindRequests_custRequestName"
                        >
                          Cust Request Name
                        </label>
                      </td>
                      <td colspan="4">
                        <select class="selectBox" name="custRequestName_op">
                          <option value="equals">Equals</option>
                          <option value="like">Begins With</option>
                          <option selected="selected" value="contains">
                            Contains
                          </option>
                          <option value="empty">Is Empty</option>
                          <option value="notEqual">Not Equal</option>
                        </select>
                        <input
                          maxlength="60"
                          name="custRequestName"
                          size="40"
                          type="text"
                        />
                        <input
                          checked="checked"
                          name="custRequestName_ic"
                          type="checkbox"
                          value="Y"
                        />
                        Ignore Case
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="FindRequests_productStoreId_title"
                          for="FindRequests_productStoreId"
                        >
                          Product Store
                        </label>
                      </td>
                      <td>
                        <span class="ui-widget">
                          <select
                            id="FindRequests_productStoreId"
                            multiple="multiple"
                            name="productStoreId"
                            size="1"
                          >
                            <option value=""> </option>
                            <option value="30000">FNPAE</option>
                          </select>
                        </span>
                      </td>
                      <td class="label">
                        <label
                          id="FindRequests_salesChannelEnumId_title"
                          for="FindRequests_salesChannelEnumId"
                        >
                          Sales Channel
                        </label>
                      </td>
                      <td>
                        <span class="ui-widget">
                          <select
                            id="FindRequests_salesChannelEnumId"
                            multiple="multiple"
                            name="salesChannelEnumId"
                            size="1"
                          >
                            <option value=""> </option>
                            <option value="GC_SALES_CHANNEL">
                              Google Checkout Channel
                            </option>
                            <option value="WEB_SALES_CHANNEL">
                              Web Channel
                            </option>
                            <option value="POS_SALES_CHANNEL">
                              POS Channel
                            </option>
                            <option value="FAX_SALES_CHANNEL">
                              Fax Channel
                            </option>
                            <option value="PHONE_SALES_CHANNEL">
                              Phone Channel
                            </option>
                            <option value="EMAIL_SALES_CHANNEL">
                              E-Mail Channel
                            </option>
                            <option value="SNAIL_SALES_CHANNEL">
                              Snail Mail Channel
                            </option>
                            <option value="AFFIL_SALES_CHANNEL">
                              Affiliate Channel
                            </option>
                            <option value="EBAY_SALES_CHANNEL">
                              eBay Channel
                            </option>
                            <option value="ONLN_DSKTP_AFLT_NT">
                              Online-Desktop-Affiliate Network
                            </option>
                            <option value="ONLN_DSKTP_ALLIANCE">
                              Online-Desktop-Alliance
                            </option>
                            <option value="ONLN_DSKTP_DIRECT">
                              Online-Desktop-direct
                            </option>
                            <option value="ONLN_DSKTP_EMAIL">
                              Online-Desktop-email
                            </option>
                            <option value="ONLN_DSKTP_PPC">
                              Online-Desktop-ppc
                            </option>
                            <option value="ONLN_DSKTP_ORGANIC">
                              Online-Desktop-organic
                            </option>
                            <option value="ONLN_DSKTP_OTHERS">
                              Online-Desktop-others
                            </option>
                            <option value="ONLN_MBL_AFLT_NT">
                              Online-Mobile-affiliate network
                            </option>
                            <option value="ONLN_MBL_ALLIANCE">
                              Online-Mobile-alliance
                            </option>
                            <option value="ONLN_MBL_DIRECT">
                              Online-Mobile-direct
                            </option>
                            <option value="ONLN_MBL_EMAIL">
                              Online-Mobile-email
                            </option>
                            <option value="ONLN_MBL_PPC">
                              Online-Mobile-ppc
                            </option>
                            <option value="ONLN_MBL_ORGANIC">
                              Online-Mobile-organic
                            </option>
                            <option value="ONLN_MBL_OTHERS">
                              Online-Mobile-others
                            </option>
                            <option value="ONLN_TAB_AFLT_NT">
                              Online-Tablet-affiliate network
                            </option>
                            <option value="ONLN_TAB_ALLIANCE">
                              Online-Tablet-alliance
                            </option>
                            <option value="ONLN_TAB_DIRECT">
                              Online-Tablet-direct
                            </option>
                            <option value="ONLN_TAB_EMAIL">
                              Online-Tablet-email
                            </option>
                            <option value="ONLN_TAB_PPC">
                              Online-Tablet-ppc
                            </option>
                            <option value="ONLN_TAB_ORGANIC">
                              Online-Tablet-organic
                            </option>
                            <option value="ONLN_TAB_OTHERS">
                              Online-Tablet-others
                            </option>
                            <option value="CALLCTR_ABC">
                              Call Center-abandoned cart
                            </option>
                            <option value="CALLCTR_CBL">Call Center-cbl</option>
                            <option value="CALLCTR_ODB">Call Center-odb</option>
                            <option value="CALLCTR_BMF_ORDERS">
                              Call Center-BMF Orders
                            </option>
                            <option value="CALLCTR_ORDERCALL">
                              Call Center-ordercall
                            </option>
                            <option value="CALLCTR_PNC">Call Center-pnc</option>
                            <option value="CALLCTR_CHAT">
                              Call Center-Chat
                            </option>
                            <option value="CALLCTR_OTHERS">
                              Call Center-others
                            </option>
                            <option value="CALLCTR_PNI">Call Center-PNI</option>
                            <option value="CALLCTR_FNP_STAFF">
                              Call Center-FNP Staff
                            </option>
                            <option value="WEBSITES_TAJONLN">
                              Websites-tajonline
                            </option>
                            <option value="WEBSITES_INDIAMART">
                              Websites-indiamart
                            </option>
                            <option value="WEBSITES_INDIATIMES">
                              Websites-indiatimes
                            </option>
                            <option value="WEBSITES_OTHERS">
                              Websites-others
                            </option>
                            <option value="WEBSITES">WEBSITES</option>
                            <option value="MOBILE_ANDROID">
                              Mobile App Android
                            </option>
                            <option value="MOBILE_IPHONE">
                              Mobile App Iphone
                            </option>
                            <option value="MOBILE_BLACKBERRY">
                              Mobile App Blackberry
                            </option>
                            <option value="MOBILE_WINDOWS">
                              Mobile App Windows
                            </option>
                            <option value="SHOPHO_DIRECT_VENDOR">
                              SHOPHO DIRECT VENDOR
                            </option>
                            <option value="SHOPHO_FNP_COCO">
                              SHOPHO FNP-COCO
                            </option>
                            <option value="SHOPHO_FRANCHISEE">
                              SHOPHO FNP-Franchisee
                            </option>
                            <option value="SHOPHO_INTERNATIONAL">
                              SHOPHO INTERNATIONAL
                            </option>
                            <option value="SHOPHO_NON_FNP">
                              SHOPHO NON FNP
                            </option>
                            <option value="PRDS_MNFG_EMAIL">
                              Outsorucing vendor for manufacturing
                            </option>
                            <option value="PRDS_MNFG_UPDATE">
                              Outsorucing vendor for update manufacturing
                            </option>
                            <option value="UNKNWN_SALES_CHANNEL">
                              Unknown Channel
                            </option>
                          </select>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="FindRequests_openDateTime_title"
                          for="FindRequests_openDateTime"
                        >
                          Open Date Time
                        </label>
                      </td>
                      <td>
                        <span class="view-calendar">
                          <input
                            id="openDateTime_fld0_value"
                            class="hasDatepicker"
                            title="dd-MM-yyyy HH:mm:ss"
                            maxlength="30"
                            name="openDateTime_fld0_value"
                            size="25"
                            type="text"
                          />
                          <button
                            class="ui-datepicker-trigger"
                            type="button"
                          ></button>
                          <select class="selectBox" name="openDateTime_fld0_op">
                            <option value="equals">Equals</option>
                            <option value="sameDay">Same Day</option>
                            <option value="greaterThanFromDayStart">
                              Greater Than From Day Start
                            </option>
                            <option value="greaterThan">Greater Than</option>
                          </select>
                          <input
                            id="openDateTime_fld1_value"
                            class="hasDatepicker"
                            title="dd-MM-yyyy HH:mm:ss"
                            maxlength="30"
                            name="openDateTime_fld1_value"
                            size="25"
                            type="text"
                          />
                          <button
                            class="ui-datepicker-trigger"
                            type="button"
                          ></button>
                          <select class="selectBox" name="openDateTime_fld1_op">
                            <option value="opLessThan">Less Than</option>
                            <option value="upToDay">Up To Day</option>
                            <option value="upThruDay">Up Through Day</option>
                            <option value="empty">Is Empty</option>
                          </select>
                        </span>
                      </td>
                      <td class="label">
                        <label
                          id="FindRequests_closedDateTime_title"
                          for="FindRequests_closedDateTime"
                        >
                          Closed Date Time
                        </label>
                      </td>
                      <td>
                        <span class="view-calendar">
                          <input
                            id="closedDateTime_fld0_value"
                            class="hasDatepicker"
                            title="dd-MM-yyyy HH:mm:ss"
                            maxlength="30"
                            name="closedDateTime_fld0_value"
                            size="25"
                            type="text"
                          />
                          <button
                            class="ui-datepicker-trigger"
                            type="button"
                          ></button>
                          <select
                            class="selectBox"
                            name="closedDateTime_fld0_op"
                          >
                            <option selected="selected" value="equals">
                              Equals
                            </option>
                            <option value="sameDay">Same Day</option>
                            <option value="greaterThanFromDayStart">
                              Greater Than From Day Start
                            </option>
                            <option value="greaterThan">Greater Than</option>
                          </select>
                          <input
                            id="closedDateTime_fld1_value"
                            class="hasDatepicker"
                            title="dd-MM-yyyy HH:mm:ss"
                            maxlength="30"
                            name="closedDateTime_fld1_value"
                            size="25"
                            type="text"
                          />
                          <button
                            class="ui-datepicker-trigger"
                            type="button"
                          ></button>
                          <select
                            class="selectBox"
                            name="closedDateTime_fld1_op"
                          >
                            <option value="opLessThan">Less Than</option>
                            <option value="upToDay">Up To Day</option>
                            <option value="upThruDay">Up Through Day</option>
                            <option value="empty">Is Empty</option>
                          </select>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="FindRequests_internalComment_title"
                          for="FindRequests_internalComment"
                        >
                          Internal Comment
                        </label>
                      </td>
                      <td>
                        <select class="selectBox" name="internalComment_op">
                          <option value="equals">Equals</option>
                          <option value="like">Begins With</option>
                          <option selected="selected" value="contains">
                            Contains
                          </option>
                          <option value="empty">Is Empty</option>
                          <option value="notEqual">Not Equal</option>
                        </select>
                        <input
                          maxlength="250"
                          name="internalComment"
                          size="60"
                          type="text"
                        />
                        <input
                          checked="checked"
                          name="internalComment_ic"
                          type="checkbox"
                          value="Y"
                        />
                        Ignore Case
                      </td>
                      <td class="label">
                        <label
                          id="FindRequests_reason_title"
                          for="FindRequests_reason"
                        >
                          Reason
                        </label>
                      </td>
                      <td>
                        <input
                          id="FindRequests_reason"
                          name="reason"
                          size="25"
                          type="text"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="FindRequests_custEstimatedMilliSeconds_title"
                          for="FindRequests_custEstimatedMilliSeconds"
                        >
                          Cust Estimated Milli Seconds
                        </label>
                      </td>
                      <td colspan="4">
                        <input
                          name="custEstimatedMilliSeconds_fld0_value"
                          size="6"
                          type="text"
                        />
                        <select
                          class="selectBox"
                          name="custEstimatedMilliSeconds_fld0_op"
                        >
                          <option value="equals">Equals</option>
                          <option value="greaterThan">Greater Than</option>
                          <option
                            selected="selected"
                            value="greaterThanEqualTo"
                          >
                            Greater Than Equals
                          </option>
                        </select>
                        <br />
                        <input
                          name="custEstimatedMilliSeconds_fld1_value"
                          size="6"
                          type="text"
                        />
                        <select
                          class="selectBox"
                          name="custEstimatedMilliSeconds_fld1_op"
                        >
                          <option value="lessThan">Less Than</option>
                          <option selected="selected" value="lessThanEqualTo">
                            Less Than Equals
                          </option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="FindRequests_custSequenceNum_title"
                          for="FindRequests_custSequenceNum"
                        >
                          Cust Sequence Num
                        </label>
                      </td>
                      <td colspan="4">
                        <input
                          name="custSequenceNum_fld0_value"
                          size="6"
                          type="text"
                        />
                        <select
                          class="selectBox"
                          name="custSequenceNum_fld0_op"
                        >
                          <option value="equals">Equals</option>
                          <option value="greaterThan">Greater Than</option>
                          <option
                            selected="selected"
                            value="greaterThanEqualTo"
                          >
                            Greater Than Equals
                          </option>
                        </select>
                        <br />
                        <input
                          name="custSequenceNum_fld1_value"
                          size="6"
                          type="text"
                        />
                        <select
                          class="selectBox"
                          name="custSequenceNum_fld1_op"
                        >
                          <option value="lessThan">Less Than</option>
                          <option selected="selected" value="lessThanEqualTo">
                            Less Than Equals
                          </option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="FindRequests_parentCustRequestId_title"
                          for="FindRequests_parentCustRequestId"
                        >
                          Parent Cust Request Id
                        </label>
                      </td>
                      <td colspan="4">
                        <select class="selectBox" name="parentCustRequestId_op">
                          <option value="equals">Equals</option>
                          <option value="like">Begins With</option>
                          <option selected="selected" value="contains">
                            Contains
                          </option>
                          <option value="empty">Is Empty</option>
                          <option value="notEqual">Not Equal</option>
                        </select>
                        <input
                          maxlength="20"
                          name="parentCustRequestId"
                          size="20"
                          type="text"
                        />
                        <input
                          checked="checked"
                          name="parentCustRequestId_ic"
                          type="checkbox"
                          value="Y"
                        />
                        Ignore Case
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="FindRequests_billed_title"
                          for="FindRequests_billed"
                        >
                          Billed
                        </label>
                      </td>
                      <td colspan="4">
                        <select class="selectBox" name="billed_op">
                          <option value="equals">Equals</option>
                          <option value="like">Begins With</option>
                          <option selected="selected" value="contains">
                            Contains
                          </option>
                          <option value="empty">Is Empty</option>
                          <option value="notEqual">Not Equal</option>
                        </select>
                        <input name="billed" size="25" type="text" />
                        <input
                          checked="checked"
                          name="billed_ic"
                          type="checkbox"
                          value="Y"
                        />
                        Ignore Case
                      </td>
                    </tr>
                    <tr>
                      <td class="label"> </td>
                      <td colspan="4">
                        <input
                          class="smallSubmit"
                          name="submitButton"
                          type="submit"
                          value="Find"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
        <div id="screenlet_1" class="screenlet">
          <div id="screenlet_1_col" class="screenlet-body no-padding">
            <h3>Search Results</h3>
            <div id="search-results">
              <div class="nav-pager">
                <ul>
                  <li class="nav-first-disabled">First</li>
                  <li class="nav-previous-disabled">Previous</li>
                  <li class="nav-next-disabled">Next</li>
                  <li class="nav-last-disabled">Last</li>
                  <li class="nav-pagesize">
                    <select
                      name="pageSize"
                      size="1"
                      onchange="submitPagination(this, '')"
                    >
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option selected="selected" value="200">
                        200
                      </option>
                    </select>
                    Items per page
                  </li>
                  <li class="nav-displaying">Displaying 1 - 0 of 0</li>
                </ul>
              </div>
              <table class="basic-table hover-bar" cellspacing="0">
                <tbody>
                  <tr class="header-row-2">
                    <td>
                      <a
                        class="sort-order"
                        href="/ordermgr/control/FindRequest?intra-app=custRequestId&amp;noConditionFind=N"
                      >
                        Cust Request Id
                      </a>
                    </td>
                    <td>
                      <label
                        id="ListRequestList_custRequestTypeId_title"
                        for="ListRequestList_custRequestTypeId"
                      >
                        Cust Request Type Id
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListRequestList_productStoreId_title"
                        for="ListRequestList_productStoreId"
                      >
                        Product Store Id
                      </label>
                    </td>
                    <td>
                      <a
                        class="sort-order"
                        href="/ordermgr/control/FindRequest?intra-app=custRequestName&amp;noConditionFind=N"
                      >
                        Cust Request Name
                      </a>
                    </td>
                    <td>
                      <a
                        class="sort-order"
                        href="/ordermgr/control/FindRequest?intra-app=priority&amp;noConditionFind=N"
                      >
                        Priority
                      </a>
                    </td>
                    <td>
                      <a
                        class="sort-order"
                        href="/ordermgr/control/FindRequest?intra-app=custRequestDate&amp;noConditionFind=N"
                      >
                        Cust Request Date
                      </a>
                    </td>
                    <td>
                      <a
                        class="sort-order"
                        href="/ordermgr/control/FindRequest?intra-app=responseRequiredDate&amp;noConditionFind=N"
                      >
                        Response Required Date
                      </a>
                    </td>
                    <td>
                      <a
                        class="sort-order"
                        href="/ordermgr/control/FindRequest?intra-app=fromPartyId&amp;noConditionFind=N"
                      >
                        From Party Id
                      </a>
                    </td>
                    <td>
                      <a
                        class="sort-order"
                        href="/ordermgr/control/FindRequest?intra-app=statusId&amp;noConditionFind=N"
                      >
                        Status ID
                      </a>
                    </td>
                    <td>
                      <a
                        class="sort-order"
                        href="/ordermgr/control/FindRequest?intra-app=lastModifiedDate&amp;noConditionFind=N"
                      >
                        Last Modified Date
                      </a>
                    </td>
                    <td>
                      <label
                        id="ListRequestList_rejectButton_title"
                        for="ListRequestList_rejectButton"
                      >
                        Reject
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="nav-pager">
                <ul>
                  <li class="nav-first-disabled">First</li>
                  <li class="nav-previous-disabled">Previous</li>
                  <li class="nav-next-disabled">Next</li>
                  <li class="nav-last-disabled">Last</li>
                  <li class="nav-pagesize">
                    <select
                      name="pageSize"
                      size="1"
                      onchange="submitPagination(this, '')"
                    >
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option selected="selected" value="200">
                        200
                      </option>
                    </select>
                    Items per page
                  </li>
                  <li class="nav-displaying">Displaying 1 - 0 of 0</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FindRequest)
);
