import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import {
  deleteNavigation,
  getNavigationList,
} from "../../../../store/actions/navigationAction";
function FindNavigation() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [pageNumber, setPageNumber] = useState();
  const [noOfPages, setNoOfPages] = useState();
  const [pageSize, setPageSize] = useState();
  const [orderString, setOrderString] = useState([]);
  const {
    navigations: { data },
  } = useSelector((state) => state);

  const handleSubmit = () => {};

  useEffect(() => {
    dispatch(getNavigationList());
  }, [dispatch]);

  const handleDeleteNavigation = (id) => {
    dispatch(deleteNavigation(id));
  };

  return (
    <div id="content-main-section" class="leftonly">
      <div id="centerdiv" class="no-clear">
        <div class="button-bar button-style-2">
          <ul>
            <li>
              <ul>
                <li class="buttontext create">
                  <Link
                    class="buttontext create"
                    to={{
                      pathname: "/catalog/control/AddNavigation",
                      state: { isSubMenu: false },
                    }}
                  >
                    New Navigation
                  </Link>
                </li>
                <li class="buttontext create">
                  <Link
                    class="buttontext create"
                    to={{
                      pathname: "/catalog/control/AddNavigation",
                      state: { isSubMenu: true },
                    }}
                  >
                    New Sub Navigation
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div id="screenlet_1" class="screenlet">
          <div id="screenlet_1_col" class="screenlet-body no-padding">
            <h3>Search Results</h3>
            <div id="search-results">
              {/*<DataTable>*/}
              <React.Fragment>
                <div className="nav-pager">
                  <ul>
                    <li className="nav-first-disabled">First</li>
                    <li className="nav-previous-disabled">Previous</li>
                    <li className="nav-page-select">
                      Page
                      <select
                        name="page"
                        size="1"
                        value={pageNumber}
                        onChange={(e) => {
                          setPageNumber(e.target.value);
                          handleSubmit();
                        }}
                      >
                        {Array(noOfPages)
                          .fill(0)
                          .map((val, i) => (
                            <option value={i}>{i + 1}</option>
                          ))}
                      </select>
                    </li>
                    <li className="nav-next-disabled">Next</li>
                    <li className="nav-last-disabled">Last</li>
                    <li className="nav-pagesize">
                      <select
                        name="pageSize"
                        size="1"
                        value={pageSize}
                        onChange={(e) => {
                          setPageSize(e.target.value);
                          setPageNumber(0);
                          handleSubmit();
                        }}
                      >
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option defaultValue value="200">
                          200
                        </option>
                      </select>
                      Items per page
                    </li>
                    <li className="nav-displaying">Displaying 1 - 8 of 8</li>
                  </ul>
                </div>
                <table class="basic-table hover-bar" cellspacing="0">
                  <tbody>
                    <tr class="header-row-2">
                      <td>
                        <a
                          class="sort-order"
                          href="/catalog/control/FindNavigation?sortField=productId&amp;noConditionFind=N"
                          onClick={(e) => {
                            e.preventDefault();
                            if (
                              orderString[0] == "productId" &&
                              orderString[1] == "DESC"
                            ) {
                              setOrderString(["productId", "ASC"]);
                            } else {
                              setOrderString(["productId", "DESC"]);
                            }

                            handleSubmit();
                          }}
                        >
                          Id
                        </a>
                      </td>
                      <td>
                        <Link
                          class="sort-order"
                          to="/catalog/control/FindNavigation?sortField=productTypeId&amp;noConditionFind=N"
                        >
                          Header Menu
                        </Link>
                      </td>
                      <td>
                        <Link
                          class="sort-order"
                          to="/catalog/control/FindNavigation?sortField=productTypeId&amp;noConditionFind=N"
                        >
                          Header Menu Slug
                        </Link>
                      </td>
                      <td>
                        <Link
                          class="sort-order"
                          to="/catalog/control/FindNavigation?sortField=internalName&amp;noConditionFind=N"
                          onClick={(e) => {
                            e.preventDefault();

                            if (
                              orderString[0] == "internalName" &&
                              orderString[1] == "DESC"
                            ) {
                              setOrderString(["internalName", "ASC"]);
                            } else {
                              setOrderString(["internalName", "DESC"]);
                            }

                            handleSubmit();
                          }}
                        >
                          Sub Menu
                        </Link>
                      </td>
                      <td>
                        <Link
                          class="sort-order"
                          to="/catalog/control/FindNavigation?sortField=internalName&amp;noConditionFind=N"
                          onClick={(e) => {
                            e.preventDefault();

                            if (
                              orderString[0] == "internalName" &&
                              orderString[1] == "DESC"
                            ) {
                              setOrderString(["internalName", "ASC"]);
                            } else {
                              setOrderString(["internalName", "DESC"]);
                            }

                            handleSubmit();
                          }}
                        >
                          Sub Menu Slug
                        </Link>
                      </td>
                      <td>
                        <Link
                          class="sort-order"
                          to="/catalog/control/FindNavigation?sortField=brandName&amp;noConditionFind=N"
                          onClick={(e) => {
                            e.preventDefault();
                            if (
                              orderString[0] == "brandName" &&
                              orderString[1] == "DESC"
                            ) {
                              setOrderString(["brandName", "ASC"]);
                            } else {
                              setOrderString(["brandName", "DESC"]);
                            }
                            handleSubmit();
                          }}
                        >
                          Child Menu
                        </Link>
                      </td>
                      <td>
                        <Link
                          class="sort-order"
                          to="/catalog/control/FindNavigation?sortField=brandName&amp;noConditionFind=N"
                          onClick={(e) => {
                            e.preventDefault();
                            if (
                              orderString[0] == "brandName" &&
                              orderString[1] == "DESC"
                            ) {
                              setOrderString(["brandName", "ASC"]);
                            } else {
                              setOrderString(["brandName", "DESC"]);
                            }
                            handleSubmit();
                          }}
                        >
                          Child Menu Slug
                        </Link>
                      </td>
                      <td>Action</td>
                    </tr>
                    {data.map((navigation) => {
                      return (
                        <tr>
                          <td>
                            <Link
                              to={{
                                pathname: "/catalog/control/EditNavigation",
                                search: "?navigationId=" + navigation.id,
                                state: { isSubMenu: false },
                              }}
                              title="Navigation Id"
                            >
                              {navigation.id}
                            </Link>
                          </td>
                          <td>{navigation?.name}</td>
                          <td>{navigation?.slug}</td>
                          <td>{navigation?.mainMenu?.name}</td>
                          <td>{navigation?.mainMenu?.slug}</td>
                          <td>{navigation?.subMenu?.name}</td>
                          <td>{navigation?.subMenu?.slug}</td>
                          <td>
                            {!Object.values(navigation?.subMenu).length && (
                              <button
                                onClick={() => {
                                  history.push({
                                    pathname: "/catalog/control/EditNavigation",
                                    search: "?navigationId=" + navigation.id,
                                    state: { isSubMenu: true },
                                  });
                                }}
                              >
                                Edit Sub Menu
                              </button>
                            )}
                            <button
                              onClick={() =>
                                handleDeleteNavigation(navigation?.id)
                              }
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="nav-pager">
                  <ul>
                    <li className="nav-first-disabled">First</li>
                    <li className="nav-previous-disabled">Previous</li>
                    <li className="nav-page-select">
                      Page
                      <select
                        name="page"
                        size="1"
                        value={pageNumber}
                        onChange={(e) => {
                          setPageNumber(e.target.value);
                          handleSubmit();
                        }}
                      >
                        {Array(noOfPages)
                          .fill(0)
                          .map((val, i) => (
                            <option value={i}>{i + 1}</option>
                          ))}
                      </select>
                    </li>
                    <li className="nav-next-disabled">Next</li>
                    <li className="nav-last-disabled">Last</li>
                    <li className="nav-pagesize">
                      <select
                        name="pageSize"
                        size="1"
                        value={pageSize}
                        onChange={(e) => {
                          setPageSize(e.target.value);
                          setPageNumber(0);
                          handleSubmit();
                        }}
                      >
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option defaultValue value="200">
                          200
                        </option>
                      </select>
                      Items per page
                    </li>
                    <li className="nav-displaying">Displaying 1 - 8 of 8</li>
                  </ul>
                </div>
              </React.Fragment>
              {/*</DataTable>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FindNavigation;
