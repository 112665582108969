import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteCity,
  getAllCities,
} from "../../../../store/actions/catalogActions";

export default function Cities() {
  const dispatch = useDispatch();

  const {
    catalog: {
      citiesReducer: { cities, loading },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(getAllCities());
  }, [dispatch]);

  const handleRemove = (cityId) => {
    dispatch(deleteCity(cityId));
  };

  return (
    <div id="content-main-section" className="leftonly">
      <div id="centerdiv" className="no-clear">
        <div className="button-bar button-style-2">
          <ul>
            <li>
              <ul>
                <li className="buttontext create">
                  <Link
                    className="buttontext create"
                    to={{
                      pathname: "/catalog/control/AddCity",
                    }}
                  >
                    New City
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div id="screenlet_1" className="screenlet">
          <div className="screenlet-title-bar">
            <ul>
              <li className="h3">Cities List</li>
            </ul>
          </div>
          <div id="screenlet_1_col" className="screenlet-body">
            <div className="nav-pager">
              <ul>
                <li className="nav-first-disabled">First</li>
                <li className="nav-previous-disabled">Previous</li>
                <li className="nav-page-select">
                  Page
                  <select
                    name="page"
                    size="1"
                    // onchange="submitPagination(this, '/catalog/control/Cities?VIEW_SIZE_1=200&amp;VIEW_INDEX_1='+value)"
                  >
                    <option selected="selected" value="0">
                      1
                    </option>
                  </select>
                </li>
                <li className="nav-next-disabled">Next</li>
                <li className="nav-last-disabled">Last</li>
                <li className="nav-pagesize">
                  <select
                    name="pageSize"
                    size="1"
                    // onchange="submitPagination(this, '/catalog/control/Cities?VIEW_SIZE_1='+value+'&amp;VIEW_INDEX_1=0')"
                  >
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option selected="selected" value="200">
                      200
                    </option>
                  </select>
                  Items per page
                </li>
                <li className="nav-displaying">Displaying 1 - 23 of 23</li>
              </ul>
            </div>
            <table className="basic-table" cellspacing="0">
              <tbody>
                <tr className="header-row">
                  <td>
                    <label
                      id="Cities_countryId_title"
                      htmlFor="Cities_countryId"
                    >
                      Country Id
                    </label>
                  </td>
                  <td>
                    <label id="Cities_cityName_title" htmlFor="Cities_cityName">
                      City Name
                    </label>
                  </td>
                  <td> </td>
                </tr>
                {cities.length &&
                  cities.map((city) => {
                    return (
                      <tr>
                        <td>
                          <Link
                            to={`/catalog/control/EditCity?cityId=${city._id}`}
                          >
                            <button>{city.countryId}</button>
                          </Link>
                        </td>
                        <td>{city.name}</td>
                        <td>
                          <button
                            className="buttontext"
                            onClick={() => handleRemove(city._id)}
                            disabled={loading}
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <div className="nav-pager">
              <ul>
                <li className="nav-first-disabled">First</li>
                <li className="nav-previous-disabled">Previous</li>
                <li className="nav-page-select">
                  Page
                  <select
                    name="page"
                    size="1"
                    // onchange="submitPagination(this, '/catalog/control/Cities?VIEW_SIZE_1=200&amp;VIEW_INDEX_1='+value)"
                  >
                    <option selected="selected" value="0">
                      1
                    </option>
                  </select>
                </li>
                <li className="nav-next-disabled">Next</li>
                <li className="nav-last-disabled">Last</li>
                <li className="nav-pagesize">
                  <select
                    name="pageSize"
                    size="1"
                    // onchange="submitPagination(this, '/catalog/control/Cities?VIEW_SIZE_1='+value+'&amp;VIEW_INDEX_1=0')"
                  >
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option selected="selected" value="200">
                      200
                    </option>
                  </select>
                  Items per page
                </li>
                <li className="nav-displaying">Displaying 1 - 23 of 23</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
