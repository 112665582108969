import React, { Component } from "react";
import Main from "./containers/Main";
import ViewProfile from "./containers/profile/ViewProfile";
import EditPerson from "./containers/profile/EditPerson";
import CreditCard from "./containers/profile/CreditCard";
import GiftCard from "./containers/profile/GiftCard";
import EftAccount from "./containers/profile/EftAccount";
import BillingAccount from "./containers/profile/BillingAccount";

export default class index extends Component {
  render() {
    const { match } = this.props;
    const { params } = match;
    return <div>{this.container(params.sub)}</div>;
  }

  container = (container) => {
    switch (container) {
      case "main":
        return <Main />;
      case "viewprofile":
        return <ViewProfile {...this.props} />;

      case "editperson":
        return <EditPerson {...this.props} />;

      case "editcreditcard":
        return <CreditCard {...this.props} />;

      case "editgiftcard":
        return <GiftCard {...this.props} />;

      case "editeftaccount":
        return <EftAccount {...this.props} />;

      case "EditBillingAccount":
        return <BillingAccount {...this.props} />;

      default:
        return (
          <div>
            <h2>Not Found ( Container Mismatch )</h2>
          </div>
        );
    }
  };
}
