import React, { Component } from "react";
import { connect } from "react-redux";
import {
  updateProductPrice,
  getAllFeatures,
} from "../../../../store/actions/catalogActions";
import { withRouter } from "react-router-dom";

class PriceList extends Component {
  constructor() {
    super();
    this.state = {
      updatedFromDate: "",
      updatedThroughDate: "",
      updatedPrice: "",
      updatedTermUomId: "",
      updatedTaxPersentage: "",
      updatedTaxAuthorityParty: "",
      updatedTaxAuthGeoId: "",
      updatedTaxInPrice: "",
      updatedCustomPriceCalculation: "",
      featureId: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    const fieldName = event.target.name;
    let fieldValue = "";
    if (event.target.value === "Y") {
      fieldValue = true;
    } else if (event.target.value === "N") {
      fieldValue = false;
    } else {
      fieldValue = event.target.value;
    }
    this.setState({ [fieldName]: fieldValue });
  }
  handleUpdateSubmit(event, id) {
    event.preventDefault();
    const {
      updatedThroughDate,
      updatedPrice,
      updatedTermUomId,
      updatedTaxPersentage,
      updatedTaxAuthorityParty,
      updatedTaxAuthGeoId,
      updatedTaxInPrice,
      updatedCustomPriceCalculation,
      featureId,
    } = this.state;
    let auth = JSON.parse(localStorage.getItem("persist:auth"));
    const user = JSON.parse(auth.auth);
    const userName = user.user.email;
    let data = {};
    data = userName ? { ...data, lastModifiedBy: userName } : data;
    data = id ? { ...data, _id: id } : data;
    data = updatedThroughDate
      ? { ...data, throughDate: updatedThroughDate }
      : data;
    data = updatedPrice ? { ...data, price: updatedPrice } : data;
    data = updatedTermUomId ? { ...data, termUomId: updatedTermUomId } : data;
    data = updatedTaxPersentage
      ? { ...data, taxPersentage: updatedTaxPersentage }
      : data;
    data = updatedTaxAuthorityParty
      ? { ...data, taxAuthorityParty: updatedTaxAuthorityParty }
      : data;
    data = updatedTaxAuthGeoId
      ? { ...data, taxAuthGeoId: updatedTaxAuthGeoId }
      : data;
    data = updatedTaxInPrice
      ? { ...data, taxInPrice: updatedTaxInPrice }
      : { ...data, taxInPrice: false };
    data = updatedCustomPriceCalculation
      ? {
          ...data,
          customPriceCalculation: updatedCustomPriceCalculation,
        }
      : { ...data, customPriceCalculation: false };
    data = featureId ? { ...data, featureId } : { ...data };
    this.props.updateProductPrice(data);
  }
  componentDidMount() {
    const { price } = this.props;
    this.props.getAllFeatures();
    this.setState({ updatedPrice: price.price, featureId: price.featureId });
  }
  render() {
    const {
      updatedThroughDate,
      updatedPrice,
      updatedTermUomId,
      updatedTaxPersentage,
      updatedTaxAuthorityParty,
      updatedTaxAuthGeoId,
      updatedTaxInPrice,
      updatedCustomPriceCalculation,
    } = this.state;
    const { price } = this.props;
    let throughDate = "";
    if (price.throughDate && price.throughDate !== null) {
      let date = new Date(price.throughDate);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();
      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }
      throughDate = year + "-" + month + "-" + dt;
    }
    return (
      <form
        id="UpdateProductPrice_o_0"
        className="basic-form"
        onSubmit={(event) => {
          this.handleUpdateSubmit(event, price._id);
        }}
        name="UpdateProductPrice_o_0"
        noValidate="novalidate"
      >
        <input
          id="UpdateProductPrice_productId_o_0"
          name="productId"
          type="hidden"
          value=""
        />
        <input
          id="UpdateProductPrice_productPriceTypeId_o_0"
          name="productPriceTypeId"
          type="hidden"
          value="DEFAULT_PRICE"
        />
        <input
          id="UpdateProductPrice_productPricePurposeId_o_0"
          name="productPricePurposeId"
          type="hidden"
          value="PURCHASE"
        />
        <input
          id="UpdateProductPrice_currencyUomId_o_0"
          name="currencyUomId"
          type="hidden"
          value={price.currencyUom}
        />
        <input
          id="UpdateProductPrice_productStoreGroupId_o_0"
          name="productStoreGroupId"
          type="hidden"
          value={price.productStoreGroupId}
        />
        <input
          id="UpdateProductPrice_fromDate_o_0"
          name="fromDate"
          type="hidden"
          value={price.fromDate}
        />
        <select
          name="cars"
          id="cars"
          value={this.state.featureId}
          onChange={(e) => this.setState({ featureId: e.target.value })}
        >
          <option value=""></option>
          {this.props?.features.map((feature) => (
            <option value={feature._id}>{feature.id}</option>
          ))}
        </select>
        <span className="view-calendar">
          <input
            id="UpdateProductPrice_thruDate_o_0_i18n"
            title="dd-MM-yyyy HH:mm:ss"
            maxLength="30"
            name="updatedThroughDate"
            value={updatedThroughDate ? updatedThroughDate : throughDate}
            onChange={this.handleChange}
            size="25"
            type="date"
          />
          <button className="ui-datepicker-trigger" type="button"></button>
          <input name="" type="hidden" value="Timestamp" />{" "}
        </span>
        <input
          id="UpdateProductPrice_price_o_0"
          name="updatedPrice"
          size="25"
          type="text"
          onChange={this.handleChange}
          value={updatedPrice}
        />{" "}
        *
        <span className="ui-widget">
          <select
            id="UpdateProductPrice_termUomId_o_0"
            name="updatedTermUomId"
            size="1"
            value={updatedTermUomId ? updatedTermUomId : price.termUomId}
            onChange={this.handleChange}
          >
            <option value=""> </option>
            <option value="AREA_A">Area: Acre (A)</option>
            <option value="AREA_a">Area: Are (a)</option>
            <option value="AREA_cm2">Area: Square Centimeter (cm2)</option>
            <option value="AREA_ft2">Area: Square Foot (ft2)</option>
            <option value="AREA_ha">Area: Hectare (ha)</option>
            <option value="AREA_in2">Area: Square Inch (in2)</option>
            <option value="AREA_km2">Area: Square Kilometer (km2)</option>
            <option value="AREA_m2">Area: Square Meter (m2)</option>
            <option value="AREA_mi2">Area: Square Mile (mi2)</option>
            <option value="AREA_mm2">Area: Square Millimeter (mm2)</option>
            <option value="AREA_rd2">Area: Square Rod (rd2)</option>
            <option value="AREA_yd2">Area: Square Yard (yd2)</option>
            <option value="DATA_B">Data Size: Byte of Data (B)</option>
            <option value="DATA_b">Data Size: Bit of Data (b)</option>
            <option value="DATA_GB">Data Size: Gigabyte of Data (GB)</option>
            <option value="DATA_Gb">Data Size: Gigabit of Data (Gb)</option>
            <option value="DATA_KB">Data Size: Kilobyte of Data (KB)</option>
            <option value="DATA_Kb">Data Size: Kilobit of Data (Kb)</option>
            <option value="DATA_MB">Data Size: Megabyte of Data (MB)</option>
            <option value="DATA_Mb">Data Size: Megabit of Data (Mb)</option>
            <option value="DATA_PB">Data Size: Petabyte of Data (PB)</option>
            <option value="DATA_Pb">Data Size: Petabit of Data (Pb)</option>
            <option value="DATA_TB">Data Size: Terabyte of Data (TB)</option>
            <option value="DATA_Tb">Data Size: Terabit of Data (Tb)</option>
            <option value="DATASPD_bps">
              Data Speed: Bit-per-second of Data (bps)
            </option>
            <option value="DATASPD_Gbps">
              Data Speed: Gigabit-per-second of Data (Gbps)
            </option>
            <option value="DATASPD_Kbps">
              Data Speed: Kilobit-per-second of Data (Kbps)
            </option>
            <option value="DATASPD_Mbps">
              Data Speed: Megabit-per-second of Data (Mbps)
            </option>
            <option value="DATASPD_Tbps">
              Data Speed: Terabit-per-second of Data (Tbps)
            </option>
            <option value="VDRY_cm3">Dry Volume: Cubic centimeter (cm3)</option>
            <option value="VDRY_ft3">Dry Volume: Cubic foot (ft3)</option>
            <option value="VDRY_in3">Dry Volume: Cubic inch (in3)</option>
            <option value="VDRY_m3">Dry Volume: Cubic meter (m3)</option>
            <option value="VDRY_mm3">Dry Volume: Cubic millimeter (mm3)</option>
            <option value="VDRY_ST">
              Dry Volume: Stere (cubic meter) (ST)
            </option>
            <option value="VDRY_yd3">Dry Volume: Cubic yard (yd3)</option>
            <option value="EN_Btu">Energy: British Thermal Unit (Btu)</option>
            <option value="EN_cal15">Energy: Calorie (@15.5c) (cal15)</option>
            <option value="EN_HP">Energy: Horsepower (mechanical) (HP)</option>
            <option value="EN_J">Energy: Joule (absolute) (J)</option>
            <option value="EN_Kw">Energy: Kilowatt (Kw)</option>
            <option value="EN_w">Energy: Watt (w)</option>
            <option value="LEN_A">Length: Angstrom (A)</option>
            <option value="LEN_cb">Length: Cable (cb)</option>
            <option value="LEN_chG">
              Length: Chain (Gunter's/surveyor's) (chG)
            </option>
            <option value="LEN_chR">
              Length: Chain (Ramden's/engineer's) (chR)
            </option>
            <option value="LEN_cm">Length: Centimeter (cm)</option>
            <option value="LEN_dam">Length: Dekameter (dam)</option>
            <option value="LEN_dm">Length: Decimeter (dm)</option>
            <option value="LEN_fm">Length: Fathom (fm)</option>
            <option value="LEN_ft">Length: Foot (ft)</option>
            <option value="LEN_fur">Length: Furlong (fur)</option>
            <option value="LEN_hand">
              Length: Hand (horse's height) (hand)
            </option>
            <option value="LEN_in">Length: Inch (in)</option>
            <option value="LEN_km">Length: Kilometer (km)</option>
            <option value="LEN_league">Length: League (league)</option>
            <option value="LEN_lnG">Length: Link (Gunter's) (lnG)</option>
            <option value="LEN_lnR">Length: Link (Ramden's) (lnR)</option>
            <option value="LEN_m">Length: Meter (m)</option>
            <option value="LEN_mi">Length: Mile (statute/land) (mi)</option>
            <option value="LEN_mil">Length: Mil (Milli-inch) (mil)</option>
            <option value="LEN_mm">Length: Millimeter (mm)</option>
            <option value="LEN_nmi">Length: Mile (nautical/sea) (nmi)</option>
            <option value="LEN_pica">Length: Pica (type size) (pica)</option>
            <option value="LEN_point">Length: Point (type size) (point)</option>
            <option value="LEN_rd">Length: Rod (rd)</option>
            <option value="LEN_u">Length: Micrometer (Micron) (u)</option>
            <option value="LEN_yd">Length: Yard (yd)</option>
            <option value="VLIQ_bbl">Liquid Volume: Barrel (US) (bbl)</option>
            <option value="VLIQ_cup">Liquid Volume: Cup (cup)</option>
            <option value="VLIQ_dr">Liquid Volume: Dram (US) (dr)</option>
            <option value="VLIQ_galUK">Liquid Volume: Gallon (UK) (gal)</option>
            <option value="VLIQ_galUS">Liquid Volume: Gallon (US) (gal)</option>
            <option value="VLIQ_gi">
              Liquid Volume: Gill (1/4 UK pint) (gi)
            </option>
            <option value="VLIQ_HL">Liquid Volume: Hectoliter (hL)</option>
            <option value="VLIQ_L">Liquid Volume: Liter (L)</option>
            <option value="VLIQ_M3">
              Liquid Volume: Cubic meter (liquid) (m3)
            </option>
            <option value="VLIQ_ml">Liquid Volume: Milliliter (ml)</option>
            <option value="VLIQ_ozUK">
              Liquid Volume: Ounce, fluid (UK) (fl. oz (UK))
            </option>
            <option value="VLIQ_ozUS">
              Liquid Volume: Ounce, fluid (US) (fl. oz (US))
            </option>
            <option value="VLIQ_ptUK">
              Liquid Volume: Pint (UK) (pt (UK))
            </option>
            <option value="VLIQ_ptUS">
              Liquid Volume: Pint (US) (pt (US))
            </option>
            <option value="VLIQ_qt">Liquid Volume: Quart (qt)</option>
            <option value="VLIQ_Tbs">Liquid Volume: Tablespoon (Tbs)</option>
            <option value="VLIQ_tsp">Liquid Volume: Teaspoon (tsp)</option>
            <option value="OTH_A">Other: Amphere - Electric current (A)</option>
            <option value="OTH_box">Other: Box (bx)</option>
            <option value="OTH_cd">
              Other: Candela - Luminosity (intensity of light) (cd)
            </option>
            <option value="OTH_ea">Other: Each (ea)</option>
            <option value="OTH_mol">
              Other: Mole - Substance (molecule) (mol)
            </option>
            <option value="OTH_pk">Other: Package (pk)</option>
            <option value="OTH_pp">Other: Per Person (pp)</option>
            <option value="TEMP_C">Temperature: Degrees Celsius (C)</option>
            <option value="TEMP_F">Temperature: Degrees Fahrenheit (F)</option>
            <option value="TEMP_K">Temperature: Kelvin (K)</option>
            <option value="TF_century">
              Time/Frequency: Time in Centuries (century)
            </option>
            <option value="TF_day">Time/Frequency: Time in Days (day)</option>
            <option value="TF_decade">
              Time/Frequency: Time in Decades (decade)
            </option>
            <option value="TF_hr">Time/Frequency: Time in Hours (hr)</option>
            <option value="TF_millenium">
              Time/Frequency: Time in Millenia (millenium)
            </option>
            <option value="TF_min">
              Time/Frequency: Time in Minutes (min)
            </option>
            <option value="TF_mon">Time/Frequency: Time in Months (mon)</option>
            <option value="TF_ms">
              Time/Frequency: Time in Milli-Seconds (ms)
            </option>
            <option value="TF_s">Time/Frequency: Time in Seconds (s)</option>
            <option value="TF_score">
              Time/Frequency: Time in Scores (score)
            </option>
            <option value="TF_wk">Time/Frequency: Time in Weeks (wk)</option>
            <option value="TF_yr">Time/Frequency: Time in Years (yr)</option>
            <option value="WT_dr_avdp">Weight: Dram (avdp) (dr avdp)</option>
            <option value="WT_dwt">Weight: Pennyweight (dwt)</option>
            <option value="WT_g">Weight: Gram (g)</option>
            <option value="WT_gr">Weight: Grain (gr)</option>
            <option value="WT_kg">Weight: Kilogram (kg)</option>
            <option value="WT_lb">Weight: Pound (avdp) (lb)</option>
            <option value="WT_lt">Weight: Ton (long or British) (lt)</option>
            <option value="WT_mg">Weight: Milligram (mg)</option>
            <option value="WT_mt">Weight: Ton (metric) (mt)</option>
            <option value="WT_oz">Weight: Ounce (avdp) (oz)</option>
            <option value="WT_oz_tr">Weight: Ounce (troy) (oz tr)</option>
            <option value="WT_sh_t">Weight: Ton (short) (sh t)</option>
            <option value="WT_st">Weight: Stone (st)</option>
          </select>
        </span>
        <span className="ui-widget">
          <select
            id="UpdateProductPrice_customPriceCalcService_o_0"
            name="updatedCustomPriceCalculation"
            size="1"
            value={
              updatedCustomPriceCalculation || price.customPriceCalculation
                ? "Y"
                : "N"
            }
            onChange={this.handleChange}
          >
            <option value="">---</option>
            <option value="Y">Y</option>
            <option value="N">N</option>
          </select>
        </span>
        <input
          id="UpdateProductPrice_taxPercentage_o_0"
          name="updatedTaxPersentage"
          size="25"
          type="text"
          value={
            updatedTaxPersentage ? updatedTaxPersentage : price.taxPersentage
          }
          onChange={this.handleChange}
        />{" "}
        <input
          id="UpdateProductPrice_taxAuthPartyId_o_0"
          maxLength="20"
          name="updatedTaxAuthorityParty"
          size="20"
          type="text"
          value={
            updatedTaxAuthorityParty
              ? updatedTaxAuthorityParty
              : price.taxAuthorityParty
          }
          onChange={this.handleChange}
        />{" "}
        <input
          id="UpdateProductPrice_taxAuthGeoId_o_0"
          maxLength="20"
          name="updatedTaxAuthGeoId"
          size="20"
          type="text"
          value={updatedTaxAuthGeoId ? updatedTaxAuthGeoId : price.taxAuthGeoId}
          onChange={this.handleChange}
        />
        <span className="ui-widget">
          <select
            id="UpdateProductPrice_taxInPrice_o_0"
            value={updatedTaxInPrice || price.taxInPrice ? "Y" : "N"}
            onChange={this.handleChange}
            name="updatedTaxInPrice"
            size="1"
          >
            <option value="">---</option>
            <option value="Y">Y</option>
            <option value="N">N</option>
          </select>
        </span>
        <input name="submitButton" type="submit" value="Update" />
      </form>
    );
  }
}
const mapStateToProps = (state) => ({
  submitted: state?.catalog?.products?.submitted,
  categories: state?.catalog?.categories?.active,
  features: state?.catalog?.catalogs?.features,
});

const mapDispatchToProps = (dispatch, router) => ({
  updateProductPrice: (data) => dispatch(updateProductPrice(data, router)),
  getAllFeatures: () => dispatch(getAllFeatures()),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PriceList)
);
