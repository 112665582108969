import React from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import moment from "moment";

class OpenOrderItemsReport extends React.Component {
    state={
        itemsPerPage:20,
        data:[],
        noOfPages:'',
        activePage:1,
    }
    componentDidMount() {
       let data= this.props.data;
       let noOfPages = data.length/this.state.itemsPerPage;
       let modulo = data.length%this.state.itemsPerPage;
       if(modulo>0){
           noOfPages++;
       }
       this.setState({noOfPages})

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.data!==this.props.data){
            let data= this.props.data;
            let noOfPages = data.length/this.state.itemsPerPage;
            let modulo = data.length%this.state.itemsPerPage;
            if(modulo>0){
                noOfPages++;
            }
            this.setState({noOfPages})
        }
    }
    getPages=()=>{
        let list =[];
        for(let i = 1;i < this.state.noOfPages;i++){
            list.push(
                <option selected={this.state.activePage===i} value={i}>
                    {i}
                </option>
            )
        }
        return list;
    }
    setPages=(value)=>{
        let data= this.props.data;
        let noOfPages = data.length/this.state.itemsPerPage;
        let modulo = data.length%this.state.itemsPerPage;
        if(modulo>0){
            noOfPages++;
        }
        this.setState({noOfPages})
        this.setState({itemsPerPage:value,noOfPages,activePage:1})
    }

    render() {
        return (
            <div id="screenlet_1" className="screenlet">
                <div className="screenlet-title-bar">
                    <ul>
                        <li className="h3">Open Order Items Report</li>
                    </ul>
                </div>
                <div id="screenlet_1_col" className="screenlet-body">
                    <div className="nav-pager">
                        <ul>
                            <li className="nav-first-disabled">First</li>
                            <li className="nav-previous-disabled">Previous</li>
                            <li className="nav-page-select">
                                Page
                                <select
                                    name="page"
                                    size="1"
                                    value={this.state.activePage}
                                    onChange={(e)=>this.setState({activePage:e.target.value})}
                                >
                                    {this.getPages()}
                                </select>
                            </li>
                            <li className="nav-next-disabled">Next</li>
                            <li className="nav-last-disabled">Last</li>
                            <li className="nav-pagesize">
                                <select
                                    name="pageSize"
                                    size="1"
                                    value={this.state.itemsPerPage}
                                    onChange={(e)=>this.setState({itemsPerPage:e.target.value})}
                                >
                                    <option selected="selected" value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option  value="200">
                                        200
                                    </option>
                                </select>
                                Items per page
                            </li>
                            <li className="nav-displaying">Displaying 1 - 23 of 23</li>
                        </ul>
                    </div>
                    <div>
                        <table className="basic-table" cellSpacing="0">
                            <thead>
                            <tr>
                                <th>Order Date</th>
                                <th>Order Id</th>
                                <th>Product</th>
                                <th>Product Description </th>
                                <th>Quantity</th>
                                <th>List Price</th>
                                <th>Web Price</th>
                                <th>Calculated Markup</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.data.map((d,index)=>{
                                 return(
                                     (index<(this.state.activePage)*this.state.itemsPerPage) && ((this.state.activePage-1) * this.state.itemsPerPage <= index) &&
                                    (<tr>
                                        <td>{moment(d.orderDate).utc(true).format('Y-MM-DD')}</td>
                                        <td><a href={"/ordermgr/control/orderview?orderId=" + d.orderId}>{d.orderId}</a></td>
                                        <td>{d.product}</td>
                                        <td>{d.description}</td>
                                        <td>{d.quantity}</td>
                                        <td>{d.listPrice}</td>
                                        <td>{d.webPrice}</td>
                                        <td>{d.calculatedMarkup}</td>
                                    </tr>)
                                )
                            })}

                            </tbody>
                        </table>
                    </div>
                    <div className="nav-pager">
                        <ul>
                            <li className="nav-first-disabled">First</li>
                            <li className="nav-previous-disabled">Previous</li>
                            <li className="nav-page-select">
                                Page
                                <select
                                    name="page"
                                    size="1"
                                    value={this.state.activePage}
                                    onChange={(e)=>this.setState({activePage:e.target.value})}
                                >
                                    {this.getPages()}
                                </select>
                            </li>
                            <li className="nav-next-disabled">Next</li>
                            <li className="nav-last-disabled">Last</li>
                            <li className="nav-pagesize">
                                <select
                                    name="pageSize"
                                    size="1"
                                    value={this.state.itemsPerPage}

                                    onChange={(e)=>this.setPates(e.target.value)}
                                >
                                    <option value="20" selected="selected">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option  value="200">
                                        200
                                    </option>
                                </select>
                                Items per page
                            </li>
                            <li className="nav-displaying">Displaying 1 - 23 of 23</li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    data:state?.orderReducer?.items,
});

const mapDispatchToProps=(dispatch,router) => ({

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OpenOrderItemsReport));