import React from "react";
import {withRouter} from "react-router";
import {putRequest} from "../../../store/services";
import {Notify} from "../../../constants/utils";
import {getOrder} from "../../../store/actions/orderActions";
import {connect} from "react-redux"

class CreateNewNote extends React.Component {
    state = {
        orderId: '',
        text: '',
        internalNote: false,
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        this.setState({orderId: query?.get("orderId")})
    }

    handleSubmit = async () => {
        if (this.state.text === "") {
            Notify('danger', "Note cannot be empty");
            return;
        }
        let data = {
            orderId: this.state.orderId,
            text: this.state.text,
            internalNotes: this.state.internalNote,
            createdBy: this.props?.user?.name
        };
        await putRequest('notes/create', data, false);
        const oId = this.props.location.search.split("=")[1];
        this.props.get(oId);
        this.props.history.goBack();
    }

    render() {
        return (
            <div id="screenlet_1" className="screenlet">
                <div className="screenlet-title-bar">
                    <ul>
                        <li className="h3">Add Note</li>
                    </ul>
                </div>
                <div id="screenlet_1_col" className="screenlet-body">
                    <table className="basic-table" cellSpacing="0">
                        <tbody>
                        <tr>
                            <td class="label"><a href={"/ordermgr/control/orderview?orderId=" + this.state.orderId}>
                                <button>Back to Order</button>
                            </a>
                            </td>
                        </tr>
                        <tr>
                            <td class="label">Note</td>
                            <td><textarea rows={8} value={this.state.text}
                                          onChange={(e) => this.setState({text: e.target.value})}></textarea></td>
                        </tr>
                        <tr>
                            <td class={"label"}>Internal Note</td>
                            <td>
                                <select
                                    value={this.state.internalNote}
                                    onChange={(e) => this.setState({internalNote: e.target.value})}
                                >
                                    <option selected={"selected"} value={false}>N</option>
                                    <option value={true}>Y</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td class={"label"}></td>
                            <td>
                                <button onClick={() => this.handleSubmit()}>submit</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
const mapStateToProps=(state)=>({
    user:state?.auth?.user,
});
const mapDispatchToProps=(dispatch,router)=>({
    get:(id)=>dispatch(getOrder(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateNewNote));
