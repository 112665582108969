import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Notify } from "../../../../constants/utils";
import {
  updatetimeSlots,
  createTimeSlots,
  allActiveTimeSlots,
  allActiveCarrierShipmentMethodsTypes,
} from "../../../../store/actions/catalogActions";
import { TextField } from "@material-ui/core";
import { timeSlotsReducer } from "../../../../store/reducers/catalogReducer";
export class ListTimeSlots extends Component {
  async componentDidMount() {
    this.props.allActive();
    this.props.allActiveCarrierShipmentMethodsTypes();
    const carrierShipmentMethodTypes = Array.from(
      this.props.carrierShipmentMethodTypes
    );
    if (carrierShipmentMethodTypes) {
      await this.setState({
        carrierShipmentMethod: carrierShipmentMethodTypes[0]?._id,
      });
    }
    if (this.props.location.search) {
      const id = this.props.location.search.split(":")[1];
      const timeSlots = Array.from(this.props.timeSlots);
      var timeSlot = timeSlots.filter((timeSlot) => {
        return timeSlot._id == id;
      });
      const {
        startTime,
        endTime,
        vendorStartTime,
        vendorEndTime,
        isDynamic,
        leadTime,
        carrierShipmentMethod,
        displayBeginTime,
        displayEndTime,
        displayText,
        isDisabled,
        _id,
      } = timeSlot[0];

      this.setState({
        startTime: startTime,
        endTime: endTime,
        vendorStartTime: vendorStartTime,
        vendorEndTime: vendorEndTime,
        isDynamic: isDynamic,
        leadTime: leadTime,
        carrierShipmentMethod: carrierShipmentMethod,
        displayBeginTime: displayBeginTime,
        displayEndTime: displayEndTime,
        displayText: displayText,
        isDisabled: true,
        _id: _id,
      });
    }
    this.setState({
      data: [],
    });
  }

  // this.props.create({
  //   timeSlot: 10000,
  //   roleType: "Carrier",
  //   sequence: 1,
  // });
  // console.log(this.props.get(3));
  //this.props.allActive();
  //this.props.search({ name: "Night_time_shipment891" });
  // this.props.update({
  //   name: "Night_time_shipment891",
  //   description: "changed to night time delivery deep in",
  // });
  //this.props.delete({ isActive: false, isDeleted: true });
  state = {
    startTime: "",
    endTime: "",
    vendorStartTime: "",
    vendorEndTime: "",
    isDynamic: true,
    carrierShipmentMethod: "",
    leadTime: "",
    displayBeginTime: "",
    displayEndTime: "",
    displayText: "",
    data: [],
    isDisabled: false,
  };

  handleChange = (e) => {
    var value = e.target.value;
    var key = e.target.name;
    this.setState({ [key]: value });
    console.log("value set", this.state);
  };

  render() {
    const timeSlots = Array.from(this.props.timeSlots);
    const carrierShipmentMethodTypes = Array.from(
      this.props.carrierShipmentMethodTypes
    );
    return (
      <div id="content-main-section" class="leftonly">
        <div id="centerdiv" class="no-clear">
          <div class="button-bar tab-bar">
            <ul>
              <li>
                <ul>
                  <li>
                    <a href="/catalog/control/ListShipmentMethodTypes">
                      Shipment Method Types
                    </a>
                  </li>
                  <li>
                    <a href="/catalog/control/ListQuantityBreaks">
                      Quantity Breaks
                    </a>
                  </li>
                  <li>
                    <a href="/catalog/control/ListCarrierShipmentMethods">
                      Carrier Shipment Methods
                    </a>
                  </li>
                  <li class="selected">
                    <a href="/catalog/control/ListTimeSlots">
                      Product Time Slots
                    </a>
                  </li>
                  <li>
                    <a href="/catalog/control/ListCarrierAllocation">
                      Carrier Allocation
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div></div>
          <div id="screenlet_1" class="screenlet">
            <div class="screenlet-title-bar">
              <ul>
                <li class="h3">List Time Slots</li>
              </ul>
            </div>
            <div id="screenlet_1_col" class="screenlet-body">
              <div class="nav-pager">
                <ul>
                  <li class="nav-first-disabled">First</li>
                  <li class="nav-previous-disabled">Previous</li>
                  <li class="nav-page-select">
                    Page
                    <select
                      name="page"
                      size="1"
                      onchange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1=200&amp;VIEW_INDEX_1='+this.value)"
                    >
                      <option selected="selected" value="0">
                        1
                      </option>
                    </select>
                  </li>
                  <li class="nav-next-disabled">Next</li>
                  <li class="nav-last-disabled">Last</li>
                  <li class="nav-pagesize">
                    <select
                      name="pageSize"
                      size="1"
                      onchange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')"
                    >
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option selected="selected" value="200">
                        200
                      </option>
                    </select>
                    Items per page
                  </li>
                  <li class="nav-displaying">Displaying 1 - 23 of 23</li>
                </ul>
              </div>
              <table class="basic-table" cellspacing="0">
                <tbody>
                  <tr class="header-row">
                    <td>
                      <label
                        id="ListTimeSlots_timeSlotTypeId_title"
                        for="ListTimeSlots_timeSlotTypeId"
                      >
                        Time Slot Id
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListTimeSlots_startTime_title"
                        for="ListTimeSlots_startTime"
                      >
                        Start Time
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListTimeSlots_endTime_title"
                        for="ListTimeSlots_endTime"
                      >
                        End Time
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListTimeSlots_vendorStartTime_title"
                        for="ListTimeSlots_vendorStartTime"
                      >
                        Vendor Start Time
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListTimeSlots_vendorEndTime_title"
                        for="ListTimeSlots_vendorEndTime"
                      >
                        Vendor End Time
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListTimeSlots_isDynamic_title"
                        for="ListTimeSlots_isDynamic"
                      >
                        Is Dynamic
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListTimeSlots_leadTime_title"
                        for="ListTimeSlots_leadTime"
                      >
                        Lead Time
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListTimeSlots_leadTime_title"
                        for="ListTimeSlots_leadTime"
                      >
                        Carrier Shipment Method
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListTimeSlots_displayBeginTime_title"
                        for="ListTimeSlots_displayBeginTime"
                      >
                        Display Begin Time
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListTimeSlots_displayEndTime_title"
                        for="ListTimeSlots_displayEndTime"
                      >
                        Display End Time
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListTimeSlots_displayText_title"
                        for="ListTimeSlots_displayText"
                      >
                        Display Text
                      </label>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  {timeSlots &&
                    timeSlots.map((slot) => {
                      return (
                        <tr>
                          <td>
                            <form
                              action="/catalog/control/ListTimeSlots"
                              method="post"
                              name="ListTimeSlots_o_0_0_o_editLink"
                            >
                              <input
                                name="VIEW_SIZE_1"
                                type="hidden"
                                value="200"
                              />
                              <input
                                name="VIEW_INDEX_1"
                                type="hidden"
                                value="0"
                              />
                              <input
                                name="carrierShipmentMethodTypeId"
                                type="hidden"
                                value="COUR_DELIVERY"
                              />
                            </form>
                            <a
                              class="buttontext"
                              href={
                                "/catalog/control/ListTimeSlots?timeSlotId=" +
                                slot._id
                              }
                              onClick={(e) => this.handleEdit(e, slot)}
                            >
                              {slot._id}
                            </a>
                          </td>

                          <td>{slot.startTime}</td>
                          <td>{slot.endTime}</td>
                          <td></td>
                          <td></td>
                          <td>{slot.isDynamic}</td>
                          <td></td>
                          <td>{slot?.carrierShipmentMethodType?.methodType}</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            <form
                              action="/catalog/control/editCarrierShipmentMethod"
                              method="post"
                              name="ListTimeSlots_o_0_0_o_editLink"
                            >
                              <input
                                name="VIEW_SIZE_1"
                                type="hidden"
                                value="200"
                              />
                              <input
                                name="VIEW_INDEX_1"
                                type="hidden"
                                value="0"
                              />
                              <input
                                name="carrierShipmentMethodTypeId"
                                type="hidden"
                                value="COUR_DELIVERY"
                              />
                            </form>
                            <a
                              class="buttontext"
                              href={
                                "/catalog/control/ListTimeSlots?timeslotId:" +
                                slot._id
                              }
                            >
                              Edit
                            </a>
                          </td>
                          <td>
                            <form
                              action="/catalog/control/deleteCarrierShipmentMethod"
                              method="post"
                              name="ListTimeSlots_o_0_0_o_deleteLink"
                            >
                              <input
                                name="VIEW_SIZE_1"
                                type="hidden"
                                value="200"
                              />
                              <input
                                name="VIEW_INDEX_1"
                                type="hidden"
                                value="0"
                              />
                              <input
                                name="carrierShipmentMethodTypeId"
                                type="hidden"
                                value="COUR_DELIVERY"
                              />
                            </form>
                            <a
                              class="buttontext"
                              href
                              onClick={(e) => this.handleRemove(e, slot._id)}
                            >
                              Remove
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <div class="nav-pager">
                <ul>
                  <li class="nav-first-disabled">First</li>
                  <li class="nav-previous-disabled">Previous</li>
                  <li class="nav-page-select">
                    Page
                    <select
                      name="page"
                      size="1"
                      onchange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1=200&amp;VIEW_INDEX_1='+this.value)"
                    >
                      <option selected="selected" value="0">
                        1
                      </option>
                    </select>
                  </li>
                  <li class="nav-next-disabled">Next</li>
                  <li class="nav-last-disabled">Last</li>
                  <li class="nav-pagesize">
                    <select
                      name="pageSize"
                      size="1"
                      onchange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')"
                    >
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option selected="selected" value="200">
                        200
                      </option>
                    </select>
                    Items per page
                  </li>
                  <li class="nav-displaying">Displaying 1 - 23 of 23</li>
                </ul>
              </div>
            </div>
          </div>
          <div id="screenlet_2" class="screenlet">
            <div class="screenlet-title-bar">
              <ul>
                <li class="h3">Edit Carrier Shipment Methods</li>
              </ul>
            </div>
            <div id="screenlet_2_col" class="screenlet-body">
              <form
                id="EditShipmentMethodType"
                class="basic-form"
                action="/catalog/control/createShipmentMethodType"
                method="post"
                name="EditShipmentMethodType"
                novalidate="novalidate"
              >
                <table class="basic-table" cellspacing="0">
                  <tbody>
                    <tr>
                      <td class="label">
                        <label
                          id="EditTimeSlots_startTimeId_title"
                          for="EditTimeSlots_startTimeId"
                        >
                          Start Time
                        </label>
                        {}*
                      </td>
                      <td>
                        <TextField
                          id="startTime"
                          type="time"
                          value={this.state.startTime}
                          onChange={(e) => {
                            this.setState({ startTime: e.target.value });
                          }}
                          inputProps={{ step: 300 }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="EditTimeSlots_endTimeId_title"
                          for="EditTimeSlots_endTimeId"
                        >
                          End Time
                        </label>
                        {}*
                      </td>
                      <td>
                        <TextField
                          id="endTime"
                          type="time"
                          value={this.state.endTime}
                          onChange={(e) => {
                            this.setState({ endTime: e.target.value });
                          }}
                          inputProps={{ step: 300 }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="EditTimeSlots_vendorStartTimeId_title"
                          for="EditTimeSlots_vendorStartTimeId"
                        >
                          Vendor Start Time
                        </label>
                      </td>
                      <td>
                        <TextField
                          style={{ marginVartical: 0 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          id="vendorStartTime"
                          type="time"
                          value={this.state.vendorStartTime}
                          onChange={(e) => {
                            this.setState({ vendorStartTime: e.target.value });
                          }}
                          inputProps={{ step: 300 }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="EditTimeSlots_vendorEndTimeId_title"
                          for="EditTimeSlots_vendorEndTimeId"
                        >
                          Vendor End Time
                        </label>
                      </td>
                      <td>
                        <TextField
                          id="vendorEndTime"
                          value={this.state.vendorEndTime}
                          onChange={(e) => {
                            this.setState({ vendorEndTime: e.target.value });
                          }}
                          type="time"
                          inputProps={{ step: 100 }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="EditTimeSlots_isDynamicId_title"
                          for="EditTimeSlots_isDynamicId"
                        >
                          Is Dynamic
                        </label>
                      </td>
                      <td>
                        <select
                          name="isDynamic"
                          id="isDynamic"
                          onChange={(e) => this.handleChange(e)}
                        >
                          <option value="1">Y</option>
                          <option value="0">N</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr style={{ marginTop: "20px" }}>
                      <td class="label">
                        <label
                          id="EditTimeSlots_leadTimeId_title"
                          for="EditTimeSlots_leadTime"
                        >
                          Lead Time
                        </label>
                      </td>
                      <td>
                        <input
                          id="EditTimeSlots_leadTimeId_input"
                          maxlength="250"
                          onChange={(e) => this.handleChange(e)}
                          name="leadTime"
                          value={this.state.leadTime}
                          size="60"
                          type="text"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="EditCarrierShipmentMethod_shipmentMethodTypeId_title"
                          for="EditCarrierShipmentMethod_shipmentMethodTypeId"
                        >
                          Carrier Shipment Method
                        </label>
                        {}*
                      </td>
                      <td>
                        <select
                          id="EditProduct_productTypeId"
                          name="productTypeId"
                          class="required"
                          value={this.state.carrierShipmentMethod}
                          disabled={this.state.isDisabled}
                          onChange={(e) =>
                            this.setState({
                              carrierShipmentMethod: e.target.value,
                            })
                          }
                          size="1"
                        >
                          {carrierShipmentMethodTypes &&
                            carrierShipmentMethodTypes.map(
                              (carrierShipmentMethod) => {
                                return (
                                  <option value={carrierShipmentMethod._id}>
                                    {carrierShipmentMethod.methodType}[
                                    {carrierShipmentMethod._id}]
                                  </option>
                                );
                              }
                            )}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="EditTimeSlots_displayBeginTime_title"
                          for="EditTimeSlots_displayBeginTime"
                        >
                          Display Begin Time
                        </label>
                      </td>
                      <td>
                        <TextField
                          id="time"
                          type="time"
                          value={this.state.displayBeginTime}
                          onChange={(e) => {
                            this.setState({ displayBeginTime: e.target.value });
                          }}
                          inputProps={{ step: 300 }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="EditTimeSlots_displayEndTime_title"
                          for="EditTimeSlots_displayEndTime"
                        >
                          Display End Time
                        </label>
                      </td>
                      <td>
                        <TextField
                          id="displayEndTime"
                          value={this.state.displayEndTime}
                          onChange={(e) => {
                            this.setState({ displayEndTime: e.target.value });
                          }}
                          type="time"
                          inputProps={{ step: 300 }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="EditTimeSlots_displayText_title"
                          for="EditTimeSlots_displayText"
                        >
                          Display Text
                        </label>
                      </td>
                      <td>
                        <input
                          id="EditTimeSlots_displayText_input"
                          maxlength="250"
                          onChange={(e) => this.handleChange(e)}
                          value={this.state.displayText}
                          name="displayText"
                          size="60"
                          type="text"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="label"></td>
                      <td colspan="4">
                        <input
                          class="smallSubmit"
                          name="submitButton"
                          type="submit"
                          onClick={(e) => this.handleSubmit(e)}
                          value="Submit"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleRemove = (e, _id) => {
    this.setState({
      timeSlot: "",
      startTime: "",
      endTime: "",
      vendorStartTime: "",
      vendorEndTime: "",
      isDynamic: true,
      leadTime: "",
      displayBeginTime: "",
      displayEndTime: "",
      displayText: "",
      carrierShipmentMethod: "",
      data: [],
      isDisabled: false,
      _id: "",
    });
    this.props.update({ _id: _id, isActive: false, isDeleted: true });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      _id,
      startTime,
      endTime,
      vendorStartTime,
      vendorEndTime,
      isDynamic,
      leadTime,
      displayBeginTime,
      displayEndTime,
      displayText,
      carrierShipmentMethod,
    } = this.state;
    if (startTime === "" || endTime === "" || carrierShipmentMethod === "") {
      Notify("danger", "Please fill all the fields marked *", "Error");
      return;
    }
    let data = {};
    data = startTime ? { ...data, startTime: startTime } : data;
    data = endTime ? { ...data, endTime: endTime } : data;
    data = vendorStartTime
      ? { ...data, vendorStartTime: vendorStartTime }
      : data;
    data = vendorEndTime ? { ...data, vendorEndTime: vendorEndTime } : data;
    data = isDynamic ? { ...data, isDynamic: isDynamic } : data;
    data = leadTime ? { ...data, leadTime: leadTime } : data;
    data = displayBeginTime
      ? { ...data, displayBeginTime: displayBeginTime }
      : data;
    data = displayEndTime ? { ...data, displayEndTime: displayEndTime } : data;
    data = displayText ? { ...data, displayText: displayText } : data;
    data = carrierShipmentMethod
      ? { ...data, carrierShipmentMethod: carrierShipmentMethod }
      : data;

    this.setState({
      startTime: "",
      endTime: "",
      vendorStartTime: "",
      vendorEndTime: "",
      isDynamic: true,
      leadTime: "",
      displayBeginTime: "",
      displayEndTime: "",
      displayText: "",
      carrierShipmentMethod: "",
      isDisabled: false,
    });
    if (_id) {
      data = _id ? { ...data, _id: _id } : data;
      this.setState({ _id: "" });
      this.props.update(data);
      this.props.history.push("/catalog/control/ListTimeSlots");
    } else {
      this.props.create(data);
    }
  };
}

const mapStateToProps = (state) => ({
  submitted: state.catalog?.timeSlotsReducer?.submitted,
  timeSlots: state.catalog?.timeSlotsReducer?.time_slots_types,
  carrierShipmentMethodTypes:
    state.catalog?.carrierShipmentMethodTypesReducer?.shipment_method_types,
});

const mapDispatchToProps = (dispatch, router) => ({
  create: (data) => dispatch(createTimeSlots(data, router)),
  allActive: () => dispatch(allActiveTimeSlots()),
  update: (data) => dispatch(updatetimeSlots(data, router)),
  allActiveCarrierShipmentMethodsTypes: () =>
    dispatch(allActiveCarrierShipmentMethodsTypes()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListTimeSlots)
);
