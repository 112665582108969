import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { duplicateProduct } from "../../../../store/actions/catalogActions";

export class DuplicateProduct extends Component {
  render() {
    const { title, comments, description } = this.state;
    const { product, submitted } = this.props;
    return (
      <React.Fragment>
        <div id="screenlet_2_col" class="screenlet-body">
          <form
            id="EditProductDup"
            class="basic-form"
            onSubmit={(e) => this.handleSubmit(e)}
          >
            <table class="basic-table" cellspacing="0">
              <tbody>
                <tr>
                  <td class="label">
                    <label
                      id="EditProductDup_productId_title"
                      for="EditProductDup_productId"
                    >
                      Duplicate / Remove Selected with New ID
                    </label>
                  </td>
                  <td colspan="10">
                    <input
                      id="EditProductDup_productId"
                      maxlength="20"
                      name="productId"
                      size="20"
                      type="text"
                      value={product}
                      disabled={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td class="label">
                    <label
                      id="EditProductDup_newInternalName_title"
                      for="EditProductDup_newInternalName"
                    >
                      Internal Name
                    </label>
                  </td>
                  <td colspan="10">
                    <input
                      id="EditProductDup_newInternalName"
                      maxlength="255"
                      name="title"
                      onChange={this.handleChange}
                      value={title}
                      size="30"
                      type="text"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="label">
                    <label
                      id="EditProductDup_newProductName_title"
                      for="EditProductDup_newProductName"
                    >
                      Product Name
                    </label>
                  </td>
                  <td colspan="10">
                    <input
                      id="EditProductDup_newProductName"
                      maxlength="60"
                      name="newProductName"
                      size="30"
                      type="text"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="label">
                    <label
                      id="EditProductDup_newDescription_title"
                      for="EditProductDup_newDescription"
                    >
                      Product Description
                    </label>
                  </td>
                  <td colspan="10">
                    <textarea
                      id="EditProductDup_newDescription"
                      class="textAreaBox"
                      cols="60"
                      maxlength="255"
                      name="comments"
                      onChange={this.handleChange}
                      rows="2"
                    >
                      {comments}
                    </textarea>
                  </td>
                </tr>
                <tr>
                  <td class="label">
                    <label
                      id="EditProductDup_newLongDescription_title"
                      for="EditProductDup_newLongDescription"
                    >
                      Long Description
                    </label>
                  </td>
                  <td colspan="10">
                    <textarea
                      id="EditProductDup_newLongDescription"
                      class="textAreaBox dojo-ResizableTextArea"
                      cols="60"
                      onChange={this.handleChange}
                      name="description"
                      rows="7"
                    >
                      {description}
                    </textarea>
                  </td>
                </tr>
                <tr>
                  <td class="label">
                    <label
                      id="EditProductDup_duplicateTitle_title"
                      class="h1"
                      for="EditProductDup_duplicateTitle"
                    >
                      Duplicate
                    </label>
                  </td>
                  <td colspan="10"> </td>
                </tr>
                <tr>
                  <td class="label">
                    <label
                      id="EditProductDup_duplicatePrices_title"
                      for="EditProductDup_duplicatePrices"
                    >
                      Prices
                    </label>
                  </td>
                  <td>
                    <input
                      id="EditProductDup_duplicatePrices"
                      checked="checked"
                      name="duplicatePrices"
                      type="checkbox"
                      value="Y"
                    />
                  </td>
                  <td class="label">
                    <label
                      id="EditProductDup_duplicateIDs_title"
                      for="EditProductDup_duplicateIDs"
                    >
                      ID
                    </label>
                  </td>
                  <td>
                    <input
                      id="EditProductDup_duplicateIDs"
                      checked="checked"
                      name="duplicateIDs"
                      type="checkbox"
                      value="Y"
                    />
                  </td>
                  <td class="label">
                    <label
                      id="EditProductDup_duplicateContent_title"
                      for="EditProductDup_duplicateContent"
                    >
                      Content
                    </label>
                  </td>
                  <td>
                    <input
                      id="EditProductDup_duplicateContent"
                      checked="checked"
                      name="duplicateContent"
                      type="checkbox"
                      value="Y"
                    />
                  </td>
                  <td class="label">
                    <label
                      id="EditProductDup_duplicateCategoryMembers_title"
                      for="EditProductDup_duplicateCategoryMembers"
                    >
                      Category Members
                    </label>
                  </td>
                  <td>
                    <input
                      id="EditProductDup_duplicateCategoryMembers"
                      checked="checked"
                      name="duplicateCategoryMembers"
                      type="checkbox"
                      value="Y"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="label">
                    <label
                      id="EditProductDup_duplicateAssocs_title"
                      for="EditProductDup_duplicateAssocs"
                    >
                      Assocs
                    </label>
                  </td>
                  <td>
                    <input
                      id="EditProductDup_duplicateAssocs"
                      checked="checked"
                      name="duplicateAssocs"
                      type="checkbox"
                      value="Y"
                    />
                  </td>
                  <td class="label">
                    <label
                      id="EditProductDup_duplicateAttributes_title"
                      for="EditProductDup_duplicateAttributes"
                    >
                      Attributes
                    </label>
                  </td>
                  <td>
                    <input
                      id="EditProductDup_duplicateAttributes"
                      checked="checked"
                      name="duplicateAttributes"
                      type="checkbox"
                      value="Y"
                    />
                  </td>
                  <td class="label">
                    <label
                      id="EditProductDup_duplicateFeatureAppls_title"
                      for="EditProductDup_duplicateFeatureAppls"
                    >
                      Feature Appls
                    </label>
                  </td>
                  <td>
                    <input
                      id="EditProductDup_duplicateFeatureAppls"
                      checked="checked"
                      name="duplicateFeatureAppls"
                      type="checkbox"
                      value="Y"
                    />
                  </td>
                  <td class="label">
                    <label
                      id="EditProductDup_duplicateInventoryItems_title"
                      for="EditProductDup_duplicateInventoryItems"
                    >
                      Inventory Items
                    </label>
                  </td>
                  <td>
                    <input
                      id="EditProductDup_duplicateInventoryItems"
                      name="duplicateInventoryItems"
                      type="checkbox"
                      value="Y"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="label">
                    <label
                      id="EditProductDup_removeTitle_title"
                      class="h1"
                      for="EditProductDup_removeTitle"
                    >
                      Remove
                    </label>
                  </td>
                  <td colspan="10"> </td>
                </tr>
                <tr>
                  <td class="label">
                    <label
                      id="EditProductDup_removePrices_title"
                      for="EditProductDup_removePrices"
                    >
                      Prices
                    </label>
                  </td>
                  <td>
                    <input
                      id="EditProductDup_removePrices"
                      name="removePrices"
                      type="checkbox"
                      value="Y"
                    />
                  </td>
                  <td class="label">
                    <label
                      id="EditProductDup_removeIDs_title"
                      for="EditProductDup_removeIDs"
                    >
                      ID
                    </label>
                  </td>
                  <td>
                    <input
                      id="EditProductDup_removeIDs"
                      name="removeIDs"
                      type="checkbox"
                      value="Y"
                    />
                  </td>
                  <td class="label">
                    <label
                      id="EditProductDup_removeContent_title"
                      for="EditProductDup_removeContent"
                    >
                      Content
                    </label>
                  </td>
                  <td>
                    <input
                      id="EditProductDup_removeContent"
                      name="removeContent"
                      type="checkbox"
                      value="Y"
                    />
                  </td>
                  <td class="label">
                    <label
                      id="EditProductDup_removeCategoryMembers_title"
                      for="EditProductDup_removeCategoryMembers"
                    >
                      Category Members
                    </label>
                  </td>
                  <td>
                    <input
                      id="EditProductDup_removeCategoryMembers"
                      name="removeCategoryMembers"
                      type="checkbox"
                      value="Y"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="label">
                    <label
                      id="EditProductDup_removeAssocs_title"
                      for="EditProductDup_removeAssocs"
                    >
                      Assocs
                    </label>
                  </td>
                  <td>
                    <input
                      id="EditProductDup_removeAssocs"
                      name="removeAssocs"
                      type="checkbox"
                      value="Y"
                    />
                  </td>
                  <td class="label">
                    <label
                      id="EditProductDup_removeAttributes_title"
                      for="EditProductDup_removeAttributes"
                    >
                      Attributes
                    </label>
                  </td>
                  <td>
                    <input
                      id="EditProductDup_removeAttributes"
                      name="removeAttributes"
                      type="checkbox"
                      value="Y"
                    />
                  </td>
                  <td class="label">
                    <label
                      id="EditProductDup_removeFeatureAppls_title"
                      for="EditProductDup_removeFeatureAppls"
                    >
                      Feature Appls
                    </label>
                  </td>
                  <td>
                    <input
                      id="EditProductDup_removeFeatureAppls"
                      name="removeFeatureAppls"
                      type="checkbox"
                      value="Y"
                    />
                  </td>
                  <td class="label">
                    <label
                      id="EditProductDup_removeInventoryItems_title"
                      for="EditProductDup_removeInventoryItems"
                    >
                      Inventory Items
                    </label>
                  </td>
                  <td>
                    <input
                      id="EditProductDup_removeInventoryItems"
                      name="removeInventoryItems"
                      type="checkbox"
                      value="Y"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="label"> </td>
                  <td colspan="4">
                    <input
                      class="smallSubmit"
                      name="submitButton"
                      type="submit"
                      value="Duplicate"
                      disabled={submitted}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </React.Fragment>
    );
  }
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      title: "",
      comments: "",
      description: "",
    };
  }
  handleChange = (e) => {
    var value = e.target.value;
    var key = e.target.name;
    this.setState({ [key]: value });
  };
  componentDidMount() {
    this.setState({ _id: this.props.product });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.duplicate(this.state);
  };
}

const mapStateToProps = (state) => ({
  submitted: state?.catalog?.products?.submitted,
});

const mapDispatchToProps = (dispatch, router) => ({
  duplicate: (data) => dispatch(duplicateProduct(data, router)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DuplicateProduct)
);
