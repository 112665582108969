import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {getAllRefundOrders,refundOrder,rejectRefund} from "../../../store/actions/orderActions";



class RefundOrders extends React.Component{
    componentDidMount() {
    this.props.getOrders();
    }
    refund=async(id)=>{
    await this.props.refundOrder(id);
    await this.props.getOrders()    ;
    }
    reject=async (id)=>{
       await this.props.rejectR(id);
       await this.props.getOrders();
    }
    renderStatusA=(status,orderId)=>{
        if(status===null){
            return(
                <td width={'7%'}>
                    <button onClick={() => this.refund(orderId)}>Proceed for refund</button>
                </td>
            )
        }
        else if(status==="Accepted"){
            return(
                <td width={'7%'}>
                    Accepted
                </td>
            )
        }
        else{
            return(
                <td width={'7%'}>
                </td>
            )
        }
    }
    renderStatusR=(status,orderId)=>{
        if(status===null){
            return(
                <td width={'7%'}>
                    <button onClick={()=>this.reject(orderId)}>Reject refund</button>
                </td>
            )
        }
        else if(status==='Rejected'){
            return(
                <td width={'7%'}>
                    Rejected
                </td>
            )
        }
        else{
            return(
                <td width={'7%'}>
                </td>
            )
        }
    }

    render() {
        return(
            <div id="screenlet_1" className="screenlet">
                <div className="screenlet-title-bar">
                    <ul>
                        <li className="h3">Order List</li>
                    </ul>
                </div>
                <div id="screenlet_1_col" className="screenlet-body">
                    <table>
                        <thead>
                        <tr>
                            <th width={'7%'}>Order nbr</th>
                            <th width={'7%'}>Customer Name</th>
                            <th width={'7%'}>Customer Mobile</th>
                            <th width={'7%'}>Sub Order nbr</th>
                            <th width={'7%'}>Order Total amount</th>
                            <th width={'7%'}>Cancel Date and Time</th>
                            <th width={'7%'}>Refund Date and Time</th>
                            <th width={'7%'}>Refund Type</th>
                            <th width={'7%'}>Payment Mode</th>
                            <th width={'7%'}>Action</th>
                            <th width={'7%'}>Action</th>
                            <th width={'7%'}>Remarks</th>
                            <th width={'7%'}>Bank Details</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.props.orders?.map((order)=>{return(
                            <tr>
                                <td width={'7%'}>{order._id}</td>
                                <td width={'7%'}>{order.senderName}</td>
                                <td width={'7%'}>{order.senderPhone}</td>
                                <td width={'7%'}></td>
                                <td width={'7%'}>{order.amount}</td>
                                <td width={'7%'}>Cancel Date and Time</td>
                                <td width={'7%'}>Refund Date and Time</td>
                                <td width={'7%'}>{order.refundType}</td>
                                <td width={'7%'}>{order.paymentMethod}</td>
                                {this.renderStatusA(order.refundStatus,order._id)}
                                {this.renderStatusR(order.refundStatus,order._id)}
                                {/*{order.refundStatus===null ? <td width={'7%'}>*/}
                                {/*    <button onClick={() => this.refund(order._id)}>Proceed for refund</button>*/}
                                {/*</td>:(order.refundStatus?<td>Accepted</td>:<td></td>)}*/}
                                {/*{order.refundStatus===null?<td width={'7%'}>*/}
                                {/*    <button onClick={()=>this.reject(order._id)}>Reject refund</button>*/}
                                {/*</td>:(!order.refundStatus?<td>Rejected</td>:<td></td>)}*/}
                                {/*<td width={'7%'}>{order?.notes[order.notes.length-1]?.text}</td>*/}
                                <td width={'7%'}>{order?.notes?.[0]?.text}</td>
                                <td width={'7%'}>{order?.BankDetails}</td>
                            </tr>
                        )})}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
const mapStateToProps=(state)=>({
    orders:state?.orderReducer?.refundOrders
});
const mapDispatchToProps=(dispatch,router)=>({
    getOrders:()=>dispatch(getAllRefundOrders()),
    refundOrder:(id)=>dispatch(refundOrder(id)),
    rejectR:(id)=>dispatch(rejectRefund(id))
});
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(RefundOrders));
