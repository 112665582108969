import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Notify } from "../../../../constants/utils";
import moment from "moment";
import {
  getRequest,
  putRequest,
  postRequest,
} from "../../../../store/services";
let storeUrl = process.env.REACT_APP_STORE_URL;
export class PNC_PNI_ABC extends Component {
  constructor() {
    super();
    this.state = {
      startDate: "",
      endDate: "",
      data: [],
      collapseStateSub: [],
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount = async () => {
    let newNow = moment();
    var startDate = newNow.startOf("day").toDate();
    var endDate = newNow.endOf("day").toDate();
    const response = await getRequest(
      `orders/${this.props.type == "abc"?"abc":'pni'}?start=${startDate}&end=${endDate}`,
      null,
      true,
      { headers: { "access-token": this.props.token } }
    );
    this.setState({ data: response.data });
    if (response.data.length > 0) {
      let a = new Array(response.data.length);
      for (let i = 0; i < a.length; i++) {
        a[i] = false;
      }
      this.setState({ collapseStateSub: a });
    }
  };

  handleDateChange = (event) => {
    const fieldName = event.target.name;
    console.log("date", event.target.value);
    this.setState({ [fieldName]: event.target.value });
  };
  handleDelete = async (e, _id) => {
    e.preventDefault();
    const { startDate, endDate } = this.state;
    let newNow = moment(startDate);
    let newEnd = moment(endDate);
    var start = newNow.startOf("day").toDate();
    var end = newEnd.endOf("day").toDate();
    const response = await putRequest(
      `orders/deleteAbc/${_id}?start=${start}&end=${end}`,
      null,
      true,
      { headers: { "access-token": this.props.token } }
    );
    this.setState({ data: response.data });
    if (response.data.length > 0) {
      let a = new Array(response.data.length);
      for (let i = 0; i < a.length; i++) {
        a[i] = false;
      }
      this.setState({ collapseStateSub: a });
    }
  };
  async handleSubmit(e) {
    e.preventDefault();
    const { startDate, endDate } = this.state;
    if (startDate === "" || endDate === "") {
      Notify("danger", "Please enter start and end date.", "Error");
      return;
    }
    let newNow = moment(startDate);
    let newEnd = moment(endDate);

    var start = newNow.startOf("day").toDate();
    var end = newEnd.endOf("day").toDate();

    if (this.props.type == "abc") {
      const response = await getRequest(
        `orders/abc?start=${start}&end=${end}`,
        null,
        true,
        { headers: { "access-token": this.props.token } }
      );
      this.setState({ data: response.data });
      if (response.data.length > 0) {
        let a = new Array(response.data.length);
        for (let i = 0; i < a.length; i++) {
          a[i] = false;
        }
        this.setState({ collapseStateSub: a });
      }
      console.log("this is response from abc", response.data);
    }

    if (this.props.type == "pni") {
      const response = await getRequest(
        `orders/pni?start=${start}&end=${end}`,
        null,
        true,
        { headers: { "access-token": this.props.token } }
      );

      this.setState({ data: response.data });
      if (response.data.length > 0) {
        let a = new Array(response.data.length);
        for (let i = 0; i < a.length; i++) {
          a[i] = false;
        }
        this.setState({ collapseStateSub: a });
      }
    }
  }
  toggleSub = (index) => {
    let array = [...this.state.collapseStateSub];
    array[index] = !array[index];
    this.setState({ collapseStateSub: array });
  };
  load = async (user) => {
    let email = user.email;
    let _id = user._id;
    let profile = user.profile;

    //get token
    const response = await postRequest(
      "orders/sign/",
      { email: email, _id: _id, profile: profile },
      true,
      { headers: { "access-token": this.props.token } }
    );
    window.open(`${storeUrl}?meta=${response.data}`, "_blank");
    //load new window
  };
  render() {
    const { startDate, endDate, data } = this.state;
    return (
      <div id="content-main-section" className="leftonly">
        <div id="centerdiv" className="no-clear">
          <input id="userLoginId" type="hidden" value="mohan@fnp.uae" />{" "}
          <input id="dialerskill" type="hidden" value="OBOUT" />{" "}
          <input id="dialerlistid" type="hidden" value="5" />
          <form
            id="lookuporder"
            action="/ordermgr/control/abc"
            method="post"
            name="lookuporder"
          >
            <div id="findOrders" className="screenlet">
              <div className="screenlet-body">
                <table className="basic-table" cellspacing="0">
                  <tbody>
                    <tr>
                      <td align="right" width="25%">
                        <span className="label">Order Date From</span>
                      </td>
                      <td width="5%"> </td>
                      <td align="left">
                        <input
                          type="date"
                          name="startDate"
                          value={startDate}
                          onChange={this.handleDateChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td align="right" width="25%">
                        <span className="label">Order Date Through</span>
                      </td>
                      <td width="5%"> </td>
                      <td align="left">
                        <input
                          type="date"
                          name="endDate"
                          value={endDate}
                          onChange={this.handleDateChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td align="right" width="25%">
                        {" "}
                      </td>
                      <td>
                        <input
                          type="submit"
                          onClick={this.handleSubmit}
                          value="Find"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </form>
          <div id="findOrdersList" className="screenlet productDetailsStyle">
            <div className="screenlet-title-bar">
              <ul>
                <li className="h3">Abandoned Order List</li>
                <li className="h3">SearchResult:{data?.length}</li>
              </ul>
            </div>
            <div className="screenlet-body">
              <table className="basic-table hover-bar subtable" cellspacing="0">
                <tbody>
                  <tr className="header-row">
                    <th width="10%">CartId</th>
                    <th width="8%"></th>
                    <th width="10%">Customer Id</th>
                    <th width="10%">Customer Name</th>
                    <th width="10%">Primary Number</th>
                    <th width="10%">Billing Number</th>
                    <th width="10%">Payment Method</th>
                    <th width="10%">Created Date</th>
                    <th width="10%">Product Details</th>
                    <th width="10%">Order Amount</th>
                    <th width="10%">Dispose</th>
                  </tr>
                  {data && data.length ? (
                    data.map((cart, index) => {
                      return [
                        <tr>
                          <td>
                            <Link
                              to={
                                ""
                                // `/ordermgr/control/orderview?orderId=${cart._id}`
                              }
                            >
                              {cart._id}
                            </Link>
                          </td>
                          <td>
                            <button onClick={(e) => this.load(cart.user)}>
                              LoadCart
                            </button>
                          </td>
                          <td>{cart.customer}</td>
                          <td>{cart.user.name}</td>
                          <td>{cart.user.mobile}</td>
                          <td></td>
                          <td></td>
                          <td>{cart.createdDate}</td>
                          <td>
                            <p>
                              <button
                                class="btn btn-primary"
                                type="button"
                                onClick={(e) => this.toggleSub(index)}
                              >
                                {this.state.collapseStateSub[index]
                                  ? "--"
                                  : "+"}
                              </button>
                            </p>
                            <div
                              style={{
                                display: this.state.collapseStateSub[index]
                                  ? "block"
                                  : "none",
                              }}
                            >
                              <div class="card card-body">
                                <table>
                                  <tr>
                                    <th>Product Id</th>
                                    <th>Price</th>
                                    <th>Recipient Name</th>
                                  </tr>

                                  {/* {order.Cart.map((cart) => {
                                    return (
                                      <tr>
                                        <td>{cart?.products[0]?.productId}</td>
                                        <td>{cart?.amount}</td>
                                        <td>{cart?.address?.name}</td>
                                      </tr>
                                    );
                                  })} */}
                                  <tr>
                                    <td>{cart?.products[0]?.productId}</td>
                                    <td>{cart?.amount}</td>
                                    <td>{cart?.address?.name}</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </td>
                          <td>{cart.amount}</td>
                          <td>
                            <Link
                              to=""
                              onClick={(e) => this.handleDelete(e, cart?._id)}
                            >
                              dispose
                            </Link>
                          </td>
                        </tr>,
                      ];
                    })
                  ) : (
                    <tr>
                      <td colSpan="9">
                        <h2>!Record Not Found</h2>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="clear"> </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ token: state.auth.token });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PNC_PNI_ABC);
