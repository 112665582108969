const catalogsState = {
  submitted: false,
  catalogs_searched: [],
  catalog_categories: [],
  active: [],
  feature_types: [],
  feature_type: {},
  all_feature_types: [],
  feature_categories: [],
  all_feature_categories: [],
  feature_categroy: {},
  features: [],
  feature: {},
  searched_features: [],
  interactions: [],
};

const catalogs = (state = catalogsState, action) => {
  switch (action.type) {
    case "CATALOG_SUBMIT":
      return { ...state, submitted: action.payload };
    case "CATALOG_SEARCHED":
      return { ...state, catalogs_searched: action.payload };
    case "CATALOG_CATEGORIES":
      return { ...state, catalog_categories: action.payload };
    case "CATALOG_ACTIVE":
      return { ...state, active: action.payload };
    case "SEARCH_FEATURE_TYPES":
      return { ...state, feature_types: action.payload };
    case "GET_FEATURE_TYPE":
      return { ...state, feature_type: action.payload };
    case "GET_All_FEATURE_TYPES":
      return { ...state, all_feature_types: action.payload };
    case "UPDATE_FEATURE_TYPE":
      return { ...state, feature_type: action.payload };
    case "SEARCH_FEATURE_CATEGORY":
      return { ...state, feature_categories: action.payload };
    case "GET_ALL_FEATURE_CATEGORIES":
      return { ...state, all_feature_categories: action.payload };
    case "GET_FEATURE_CATEGORY":
      return { ...state, feature_categroy: action.payload };
    case "UPDATE_FEATURE_CATEGORY":
      return { ...state, feature_category: action.payload };
    case "GET_ALL_FEATURES":
      return { ...state, features: action.payload };
    case "GET_ONE_FEATURE":
      return { ...state, feature: action.payload };
    case "UPDATE_FEATURE":
      return { ...state, feature: action.payload };
    case "SEARCH_INTERACION":
      return { ...state, interactions: action.payload };
    case "ALL_INTERACTIONS":
      return { ...state, interactions: action.payload };
    default:
      return state;
  }
};
//Meta
const metaState = {
  submitted: false,
  active: [],
  meta_searched: [],
  deleteLoading: false,
  deleteeMeta: [],
};
const meta = (state = metaState, action) => {
  switch (action.type) {
    case "SUBMITTED":
      return { ...state, submitted: action.payload };
    case "META_ACTIVE":
      return { ...state, active: action.payload };
    case "META_DELETE_REQUEST":
      return { ...state, deleteLoading: true };
    case "META_DELETE":
      return {
        ...state,
        deleteMeta: action.payload,
        deleteLoading: false,
      };
    case "META_DELETE_ERROR":
      return {
        ...state,
        deleteMeta: action.payload,
        deleteLoading: false,
      };
    default:
      return state;
  }
};
//End Meta

const categoriesState = {
  submitted: false,
  active: [],
  categories_searched: [],
  deleteLoading: false,
  deleteCategories: [],
};
const categories = (state = categoriesState, action) => {
  switch (action.type) {
    case "SUBMITTED":
      return { ...state, submitted: action.payload };
    case "CATEGORIES_ACTIVE":
      return { ...state, active: action.payload };
    case "CATEGORIES_SEARCHED":
      return { ...state, categories_searched: action.payload };
    case "CATEGORIES_DELETE_REQUEST":
      return { ...state, deleteLoading: true };
    case "CATEGORIES_DELETE":
      return {
        ...state,
        deleteCategories: action.payload,
        deleteLoading: false,
      };
    case "CATEGORIES_DELETE_ERROR":
      return {
        ...state,
        deleteCategories: action.payload,
        deleteLoading: false,
      };
    default:
      return state;
  }
};
//Navigation
const navigationState = {
  submitted: false,
  navigation_searched: [],
  navigationOtherData: { noPages: 1, totalProducts: 0 },
  deleteNavigation: [],
};
const navigation = (state = navigationState, action) => {
  switch (action.type) {
    case "NAVIGATION_SUBMIT":
      return { ...state, submitted: action.payload };
    case "NAVIGATION_SEARCHED":
      return { ...state, navigation_searched: action.payload };
    case "NAVIGATION_OTHER_DATA":
      return { ...state, navigationOtherData: action.payload };
    case "NAVIGATION_DELETE":
      return { ...state, deleteNavigation: action.payload };
    default:
      return state;
  }
};
//End Navigation

const productsState = {
  submitted: false,
  products_searched: [],
  products_bulk_upload: [],
  productOtherData: { noPages: 1, totalProducts: 0 },
  deleteProduct: [],
};
const products = (state = productsState, action) => {
  switch (action.type) {
    case "PRODUCT_SUBMIT":
      return { ...state, submitted: action.payload };
    case "PRODUCT_SEARCHED":
      return { ...state, products_searched: action.payload };
    case "PRODUCT_OTHER_DATA":
      return { ...state, productOtherData: action.payload };
    case "PRODUCT_DELETE":
      return { ...state, deleteProduct: action.payload };
    default:
      return state;
  }
};
const shipmentMethodTypesState = {
  sumbitted: false,
  shipment_method_types: [],
  all_types: [],
};
const shipmentMethodTypesReducer = (
  state = shipmentMethodTypesState,
  action
) => {
  switch (action.type) {
    case "SHIPMENT_METHOD_TYPES_ALL":
      return { ...state, all_types: action.payload };
    case "SHIPMENT_METHOD_TYPES_ALL_ACTIVE":
      return { ...state, shipment_method_types: action.payload };
    case "SHIPMENT_METHOD_ADD":
      return {
        ...state,
        shipment_method_types: [...state.shipment_method_types, action.payload],
      };

    case "SHIPMENT_METHOD_TYPES_SUBMIT":
      return { ...state, submitted: action.payload };
    default:
      return state;
  }
};
const carrierShipmentMethodTypesState = {
  sumbitted: false,
  shipment_method_types: [],
};
const carrierShipmentMethodTypesReducer = (
  state = carrierShipmentMethodTypesState,
  action
) => {
  switch (action.type) {
    case "CARRIER_SHIPMENT_METHOD_TYPES_ALL_ACTIVE":
      return { ...state, shipment_method_types: action.payload };

    case "CARRIER_SHIPMENT_METHOD_TYPES_SUBMIT":
      return { ...state, submitted: action.payload };
    case "CARRIER_SHIPMENT_METHOD_ADD":
      return {
        ...state,
        shipment_method_types: [...state.shipment_method_types, action.payload],
      };
    default:
      return state;
  }
};

const timeSlotsState = {
  sumbitted: false,
  time_slots_types: [],
};
const timeSlotsReducer = (state = timeSlotsState, action) => {
  switch (action.type) {
    case "TIME_SLOTS_ALL_ACTIVE":
      return { ...state, time_slots_types: action.payload };
    case "TIME_SLOTS_ADD":
      return {
        ...state,
        time_slots_types: [...state.time_slots_types, action.payload],
      };

    case "TIME_SLOTS_SUBMIT":
      return { ...state, submitted: action.payload };
    default:
      return state;
  }
};
const carrierAllocationState = {
  submitted: false,
  carrier_allocations: [],
};
const carrierAllocaitonReducer = (state = carrierAllocationState, action) => {
  switch (action.type) {
    case "CARRIER_ALLOCATION":
      return { ...state, carrier_allocations: action.payload };
    case "CARRIER_ALLOCAITON_SUBMIT":
      return { ...state, submitted: action.payload };
    case "CARRIER_ALLOCATION_ADD":
      return {
        ...state,
        carrier_allocations: [...state.carrier_allocations, action.payload],
      };
    default:
      return state;
  }
};

const promosState = { submitted: false, promosList: [] };
const promos = (state = promosState, action) => {
  switch (action.type) {
    case "PRODUCT_PROMO_LIST_TYPE":
      return { ...state, submitted: action.payload };
    case "PRODUCT_PROMO_LIST":
      return { ...state, promosList: action.payload };
    case "PRODUCT_PROMO_LIST_ADD":
      return { ...state, promosList: [...state.promosList, action.payload] };
    default:
      return state;
  }
};
const promoRulesState = { submitted: false, promoRulesList: [] };
const promoRules = (state = promoRulesState, action) => {
  switch (action.type) {
    case "PRODUCT_PROMO_LIST_TYPE":
      return { ...state, submitted: action.payload };
    case "PROMO_RULES":
      return { ...state, promoRulesList: action.payload };
    case "PROMO_RULES_ADD":
      return {
        ...state,
        promoRulesList: [...state.promoRulesList, action.payload],
      };
    default:
      return state;
  }
};
const ruleConditionState = { submitted: false, ruleConditionList: [] };
const ruleConditions = (state = ruleConditionState, action) => {
  switch (action.type) {
    case "PRODUCT_PROMO_LIST_TYPE":
      return { ...state, submitted: action.payload };
    case "RULE_CONDITIONS":
      return { ...state, ruleConditionList: action.payload };
    case "RULE_CONDITIONS_ADD":
      console.log("this is ruleConditionsList", action.payload);
      return {
        ...state,
        ruleConditionList: [...state.ruleConditionList, action.payload],
      };
    default:
      return state;
  }
};

const promoCodesState = { submitted: false, promoCodesList: [] };
const promoCodesReducer = (state = promoCodesState, action) => {
  switch (action.type) {
    case "PRODUCT_PROMO_LIST_TYPE":
      return { ...state, submitted: action.payload };
    case "PROMO_CODES":
      return { ...state, promoCodesList: action.payload };
    case "PROMO_CODES_ADD":
      return {
        ...state,
        promoCodesList: [...state.promoCodesList, action.payload],
      };
    default:
      return state;
  }
};

const geosState = { geos: [], allGeos: [] };
const geo = (state = geosState, action) => {
  switch (action.type) {
    case "ALL_GEOS":
      return { ...state, geos: action.payload };
    case "ALL_OVER_GEOS":
      return { ...state, allGeos: action.payload };
    default:
      return state;
  }
};
const contentState = { content: [] };
const contentReducer = (state = contentState, action) => {
  switch (action.type) {
    case "ALL_CONTENT":
      return { ...state, content: action.payload };
    case "ADD_CONTENT":
      return { ...state, content: [...state.content, action.payload] };
    default:
      return state;
  }
};
const identificationState = { content: [] };
const identificationReducer = (state = identificationState, action) => {
  switch (action.type) {
    case "ALL_IDENTIFICATIONS":
      return { ...state, content: action.payload };
    case "ADD_IDENTIFICATIONS":
      return { ...state, content: [...state.content, action.payload] };
    default:
      return state;
  }
};
const configsState = { content: [], addons: [] };
const configReducer = (state = configsState, action) => {
  switch (action.type) {
    case "ALL_CONFIGS":
      return { ...state, content: action.payload };
    case "ADD_CONFIG":
      return { ...state, content: [...state.content, action.payload] };
    case "ALL_ADDONS":
      return { ...state, addons: action.payload };
    default:
      return state;
  }
};

const associationsState = { associations: [], associationsTo: [] };
const associationsReducer = (state = associationsState, action) => {
  switch (action.type) {
    case "ALL_ASSOCIATIONS":
      return { ...state, associations: action.payload };
    case "ADD_ASSOCIATIONS":
      return {
        ...state,
        associations: [...state.associations, action.payload],
      };
    case "ALL_ASSOCIATIONS_TO":
      return { ...state, associationsTo: action.payload };
    default:
      return state;
  }
};

const citiesState = {
  cities: [],
  isSubmit: false,
  loading: false,
};
const citiesReducer = (state = citiesState, action) => {
  switch (action.type) {
    case "GET_CITIES_START":
      return { ...state, loading: true };
    case "GET_CITIES_SUCCESS":
      return {
        ...state,
        cities: action.payload,
        loading: false,
        isSubmit: false,
      };
    case "GET_CITIES_ERROR":
      return { ...state, loading: false, isSubmit: false };
    case "SUBMITTED":
      return { ...state, isSubmit: action.payload };
    default:
      return state;
  }
};

const productCitiesState = {
  data: [],
  isSubmitting: false,
  loading: false,
};
const productCitiesReducer = (state = productCitiesState, action) => {
  switch (action.type) {
    case "GET_PRODUCT_CITIES_START":
      return { ...state, loading: true };
    case "GET_PRODUCT_CITIES_SUCCESS":
      return {
        ...state,
        data: action.payload,
        loading: false,
        isSubmitting: false,
      };
    case "GET_PRODUCT_CITIES_ERROR":
      return { ...state, loading: false, isSubmitting: false };
    case "SUBMITTED":
      return { ...state, isSubmitting: action.payload };
    default:
      return state;
  }
};

export {
  catalogs,
  meta,
  categories,
  products,
  navigation,
  promos,
  shipmentMethodTypesReducer,
  carrierShipmentMethodTypesReducer,
  timeSlotsReducer,
  carrierAllocaitonReducer,
  geo,
  promoRules,
  ruleConditions,
  promoCodesReducer,
  contentReducer,
  identificationReducer,
  configReducer,
  associationsReducer,
  citiesReducer,
  productCitiesReducer,
};
