import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getRequest, deleteRequest } from '../../../../store/services';
import { Notify, redirect } from "../../../../constants/utils";
import './bulkBlock.scss';
import CustomModal from './CustomModal';
import { addToBlock, editToBlock } from '../../../../store/actions/bulkBlockActions';
import { allActiveShipmentMethodsTypes, allActiveTimeSlots, getGeoOfType } from '../../../../store/actions/catalogActions';

class BulkBlock extends Component {
    state = {
        mode: 'add',
        areaModal: false,
        dateModal: false,
        shippingModal: false,
        timeSlotModal: false,
        selectState: true,
        timeSelectState: true,
        areaState: true,
        ruleName: '',
        date: [],
        shippingName: '',
        findedMethods: [],
        methods: [],
        shippingMethods: [],
        timeSlotName: '',
        findedSlots: [],
        slots: [],
        timeSlots: [],
        geoType: '',
        geoTypeName: '',
        findedGeos: [],
        geos: [],
        geoIds: [],
        list: [],
        rule: '',
        selectedRule: [],
        geoWithBlock: [],
        blockId: null
    }

    componentDidMount() {
        const token = localStorage.getItem('token');

        this.props.getShipmentTypes();
        this.props.getTimeSlots();

        // getRequest("bulkCreate/allActive", null, true, {
        //     "access-token": token,
        // }).then((res) => {
        //     this.setState({ list: res.data })
        // });

        getRequest("geos/allActive/blockList", null, true, {
            "access-token": token,
        }).then((res) => {
            this.setState({ list: res.data })
        });
    }

    areaModalToggle = () => this.setState({ areaModal: !this.state.areaModal });
    dateModalToggle = () => this.setState({ dateModal: !this.state.dateModal });
    shippingModalToggle = () => this.setState({ shippingModal: !this.state.shippingModal });
    timeSlotModalToggle = () => this.setState({ timeSlotModal: !this.state.timeSlotModal });

    onDateChange = (e) => {
        this.setState({
            date: [...this.state.date, e.target.value]
        })
    }

    blockHandler = () => {
        if(this.state.geos.length === 0) {
            Notify('danger', 'Area/Group/Pin is required', 'Required');
        } else {
            if(this.state.mode === 'add') {
                this.props.addToBlock({
                    ruleName: this.state.ruleName,
                    date: this.state.date,
                    shippingMethods: this.state.shippingMethods,
                    timeSlots: this.state.timeSlots,
                    geos: this.state.geoIds,
                });
            } else {
                this.props.editToBlock({
                    ruleName: this.state.ruleName,
                    date: this.state.date,
                    shippingMethods: this.state.shippingMethods,
                    timeSlots: this.state.timeSlots,
                    geos: this.state.geoIds,
                    id: this.state.blockId
                });
            }
    
            this.setState({
                ruleName: '',
                date: [],
                findedMethods: [],
                shippingName: '',
                methods: [],
                shippingMethods: [],
                timeSlotName: '',
                findedSlots: [],
                slots: [],
                timeSlots: [],
                geoType: '',
                geoTypeName: '',
                findedGeos: [],
                geos: [],
                geoIds: []
            });
        }
    }

    dateRemoveHandler = (dateStr) => {
        this.setState({ date: this.state.date.filter(d => d !== dateStr) });
    }

    shippingNameHandler = (e) => {
        this.setState({
            selectState: true,
            shippingName: e.target.value
        });

        const finded = this.props.shipmentMethods.filter(shpm => {
            if (e.target.value !== '') {
                return shpm.name.toLowerCase().includes(e.target.value.toLowerCase())
            }
        });
        this.setState({ findedMethods: finded })
    }

    onSelectionClick = (id) => {
        const finded = this.props.shipmentMethods.filter(
            shpm => shpm._id === id
        );

        this.setState({
            selectState: false,
            shippingName: finded[0].name,
            methods: [...this.state.methods, `${finded[0].description} [${finded[0].name}]`],
            shippingMethods: [...this.state.shippingMethods, finded[0].name]
        })
    }

    methodRemoveHandler = (methodStr) => {
        this.setState({ methods: this.state.methods.filter(d => d !== methodStr) });
    }

    timeSlotNameHandler = (e) => {
        this.setState({
            timeSelectState: true,
            timeSlotName: e.target.value
        });

        const finded = this.props.timeSlotsTypes.filter(slty => {
            if (e.target.value !== '') {
                return slty._id === parseInt(e.target.value)
            }
        });
        this.setState({ findedSlots: finded })
    }

    onSlotSelectionClick = (id) => {
        const finded = this.props.timeSlotsTypes.filter(
            slty => slty._id === parseInt(id)
        );

        this.setState({
            timeSelectState: false,
            timeSlotName: finded[0]._id,
            slots: [...this.state.slots, `${finded[0].startTime} ${finded[0].endTime} [${finded[0]._id}]`],
            timeSlots: [...this.state.timeSlots, finded[0]._id]
        })
    }

    slotsRemoveHandler = (methodStr) => {
        this.setState({ slots: this.state.slots.filter(d => d !== methodStr) });
    }

    geoTypeChange = (e) => {
        this.setState({ geoType: e.target.value });
        this.props.getGeoOfType(e.target.value);
    }

    geoChange = (e) => {
        this.setState({
            areaState: true,
            geoTypeName: e.target.value
        });

        const finded = this.props.geos.filter(shpm => {
            if (e.target.value !== '') {
                return shpm.geoId.toLowerCase().includes(e.target.value.toLowerCase())
            }
        });

        this.setState({ findedGeos: finded })
    }

    onGeoIdClick = (id) => {
        const finded = this.props.geos.filter(
            shpm => shpm._id === id
        );

        this.setState({
            areaState: false,
            geoTypeName: finded[0].geoId,
            geos: [...this.state.geos, `${finded[0].geoType} [${finded[0].geoId}]`],
            geoIds: [...this.state.geoIds, finded[0]._id]
        })
    }

    geoRemoveHandler = (str) => {
        this.setState({ geos: this.state.geos.filter(d => d !== str) });
    }

    selectChange = (e) => {
        if (e.target.value !== '') {
            const selected = this.state.list.filter(geo => geo._id === parseInt(e.target.value));

            this.setState({
                rule: e.target.value,
                selectedRule: selected[0].blockList,
                ruleName: '',
                date: [],
                findedMethods: [],
                shippingName: '',
                methods: [],
                shippingMethods: [],
                timeSlotName: '',
                findedSlots: [],
                slots: [],
                timeSlots: [],
                geoType: '',
                geoTypeName: '',
                findedGeos: [],
                geos: [],
                geoIds: []
            })
        } else {
            this.setState({
                ruleName: '',
                date: [],
                findedMethods: [],
                shippingName: '',
                methods: [],
                shippingMethods: [],
                timeSlotName: '',
                findedSlots: [],
                slots: [],
                timeSlots: [],
                geoType: '',
                geoTypeName: '',
                findedGeos: [],
                geos: [],
                geoIds: []
            })
        }
    }

    removeRule = (e, id) => {
        e.preventDefault();
        const token = localStorage.getItem('token');

        deleteRequest("bulkCreate/delete/" + id, null, true, {
            "access-token": token,
        }).then((res) => {
            const list = this.state.list.map(ls => {
                ls.blockList = ls.blockList.filter(bls => bls._id !== id);
                return ls;
            })

            this.setState({
                list: list,
                selectedRule: [],
                rule: ''
            })
        });
    }

    modifyHandler = async (e, id) => {
        e.preventDefault();
        
        const filtered = this.state.selectedRule.filter(bl => bl._id === id);

        await this.setState({
            ruleName: '',
            date: [],
            findedMethods: [],
            shippingName: '',
            methods: [],
            shippingMethods: [],
            timeSlotName: '',
            findedSlots: [],
            slots: [],
            timeSlots: [],
            geoType: '',
            geoTypeName: '',
            findedGeos: [],
            geos: [],
            geoIds: [],
            blockId: id
        })

        if (filtered.length > 0) {
            const selectedGeo = filtered[0];

            let geos = [];
            let geoIds = [];
            let dates = [];
            let shippingMethods = [];
            let methods = [];
            let slots = [];
            let timeSlots = [];

            if (selectedGeo.geos !== 'ALL') {
                for (let geo of selectedGeo.geos) {
                    geos.push(`${geo.geoType} [${geo.geoId}]`)
                    geoIds.push(geo._id)
                }
            }

            if (selectedGeo.date !== 'ALL') {
                const Sldates = selectedGeo.date.split(';');

                for (const date of Sldates) {
                    if (date !== '') {
                        dates.push(date);
                    }
                }
            }

            if (selectedGeo.shippingMethods !== 'ALL') {
                // const SlshippingMethods = selectedGeo.shipmentMethodsTypes.split(';');
                // name, description
                for (const method of selectedGeo.shipmentMethodsTypes) {
                    // if (method !== '') {
                    shippingMethods.push(method.name);
                    methods.push(`${method.description} [${method.name}]`);
                    // }
                }
            }

            if (selectedGeo.timeSlots !== 'ALL') {
                // const SlshippingMethods = selectedGeo.shipmentMethodsTypes.split(';');
                // name, description
                // slots ["20:41:00 21:41:00 [1]"]
                for (const slot of selectedGeo.timeSlots) {
                    // if (method !== '') {
                    timeSlots.push(slot._id);
                    slots.push(`${slot.startTime} ${slot.endTime} [${slot._id}]`);
                    // }
                }
            }

            this.setState({
                mode: 'edit',
                ruleName: selectedGeo.ruleName,
                geos: [...this.state.geos, ...geos],
                geoIds: [...this.state.geoIds, ...geoIds],
                date: [...this.state.date, ...dates],
                shippingMethods: [...this.state.shippingMethods, ...shippingMethods],
                methods: [...this.state.methods, ...methods],
                timeSlots: [...this.state.timeSlots, ...timeSlots],
                slots: [...this.state.slots, ...slots],
            })

        }
    }

    render() {
        return (
            <div className="bulk-block">
                {/* <div className="block-bar">
                    <ul>
                        <li>
                            <Link to="/catalog/control/BlockList">List</Link>
                        </li>
                    </ul>
                </div> */}

                <label htmlFor="" style={{ marginRight: '5px' }}>
                    <b>Select Rule:</b>
                </label>
                <select name="" id=""
                    value={this.state.rule}
                    onChange={this.selectChange}
                >
                    <option value="">[Select]</option>
                    {this.state.list.map(geo => {
                        return <option value={geo._id} key={geo._id}>{geo.geoId}</option>
                    })}
                </select>

                {this.state.rule !== '' ? (
                    <table>
                        <thead>
                            <tr>
                                <th>Area/Group/Pin</th>
                                <th>Date</th>
                                <th>Shipping Method</th>
                                <th>Timeslot</th>
                                <th>Rule Name</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.selectedRule.map(lt => {
                                return (
                                    <tr key={lt._id}>
                                        <td>
                                            {lt.geos !== 'ALL' ? (
                                                lt.geos.map((geo, index) => {
                                                    if ((lt.geos.length - 1) !== index && lt.geos.length > 1) {
                                                        return `${geo.geoId},`
                                                    } else {
                                                        return `${geo.geoId}`
                                                    }
                                                })
                                            ) : (
                                                    lt.geos
                                                )}
                                        </td>
                                        <td>
                                            {lt.date}
                                        </td>
                                        <td>
                                            {lt.shippingMethods !== 'ALL' ? (
                                                lt.shippingMethods.split(';').join(',')
                                            ) : (
                                                    lt.shippingMethods
                                                )}
                                            {/* {lt.shippingMethods} */}
                                        </td>
                                        <td>
                                            {lt.timeSlots !== 'ALL' ? (
                                                lt.timeSlots.map((slot, index) => {
                                                    if ((lt.timeSlots.length - 1) !== index && lt.timeSlots.length > 1) {
                                                        return `${slot.startTime}-${slot.endTime},`
                                                    } else {
                                                        return `${slot.startTime}-${slot.endTime}`
                                                    }
                                                })
                                            ) : (
                                                    lt.timeSlots
                                                )}
                                        </td>
                                        <td>
                                            {lt.ruleName}
                                        </td>
                                        <td>
                                            <b>
                                                <a href="#" style={{ marginRight: '5px' }} onClick={e => this.modifyHandler(e, lt._id)}>Modify</a>
                                                <a href="#" onClick={e => this.removeRule(e, lt._id)}>Remove</a>
                                            </b>
                                        </td>
                                    </tr>
                                )

                            })}
                        </tbody>
                    </table>
                ) : ('')}

                <br />

                <b>Block / Unblock a new Pincode</b>
                <table >
                    <thead>
                        <tr>
                            <th>Area/Group/Pin</th>
                            <th>Date</th>
                            <th>Shipping Method</th>
                            <th>Timeslot</th>
                            <th>Rule Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div>
                                    {this.state.geos.map(geo => {
                                        return (
                                            <p key={geo}>
                                                {geo}
                                                <span
                                                    style={{ fontWeight: '700', marginLeft: '4px', color: '#f00', cursor: 'pointer' }}
                                                    onClick={() => this.geoRemoveHandler(geo)}
                                                >
                                                    &#9747;
                                                </span>
                                            </p>
                                        );
                                    })}
                                </div>
                                <button className="btn-bulk" onClick={this.areaModalToggle}>Choose</button>
                            </td>
                            <td>
                                <div>
                                    {this.state.date.map(dateStr => {
                                        return (
                                            <p key={dateStr}>
                                                {dateStr}
                                                <span
                                                    style={{ fontWeight: '700', marginLeft: '4px', color: '#f00', cursor: 'pointer' }}
                                                    onClick={() => this.dateRemoveHandler(dateStr)}
                                                >
                                                    &#9747;
                                    </span>
                                            </p>
                                        );
                                    })}
                                </div>
                                <button className="btn-bulk" onClick={this.dateModalToggle}>Choose</button>
                            </td>
                            <td>
                                {this.state.methods.map(methodStr => {
                                    return (
                                        <p key={methodStr}>
                                            {methodStr}
                                            <span
                                                style={{ fontWeight: '700', marginLeft: '4px', color: '#f00', cursor: 'pointer' }}
                                                onClick={() => this.methodRemoveHandler(methodStr)}
                                            >
                                                &#9747;
                                    </span>
                                        </p>
                                    );
                                })}
                                <button className="btn-bulk" onClick={this.shippingModalToggle}>Choose</button>
                            </td>
                            <td>
                                <div>
                                    {this.state.slots.map(methodStr => {
                                        return (
                                            <p key={methodStr}>
                                                {methodStr}
                                                <span
                                                    style={{ fontWeight: '700', marginLeft: '4px', color: '#f00', cursor: 'pointer' }}
                                                    onClick={() => this.slotsRemoveHandler(methodStr)}
                                                >
                                                    &#9747;
                                                </span>
                                            </p>
                                        );
                                    })}
                                </div>
                                <button className="btn-bulk" onClick={this.timeSlotModalToggle}>Choose</button>
                            </td>
                            <td>
                                <input type="text" name="rule_name"
                                    value={this.state.ruleName}
                                    onChange={e => this.setState({ ruleName: e.target.value })}
                                />
                            </td>
                            <td>
                                <button className="btn-bulk" onClick={this.blockHandler}>Block Now</button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <CustomModal active={this.state.areaModal} modalController={this.areaModalToggle}>
                    <label
                        id=""
                        title=""
                        htmlFor=""
                    >
                        <b>Select Geo Type:</b>
                    </label>

                    <select
                        id="EditProdCatalog_useQuickAdd"
                        className="valid"
                        name="useQuickAdd"
                        size="1"
                        onChange={this.geoTypeChange}
                    >
                        <option value="">-----Select One-----</option>
                        <option value="country">Country</option>
                        <option value="locality">Locality</option>
                        <option value="delivery_area">Delivery Area</option>
                        <option value="delivery_area_group">Delivery Area Group</option>
                        <option value="postal_code">Postal Code</option>
                    </select>

                    <div style={{ width: '80%' }}>
                        <input
                            id="geo"
                            title="Select date"
                            name="geo"
                            // value={(this.state.date[this.state.date.length - 1]) ? this.state.date[this.state.date.length - 1] : this.state.date[0]}
                            defaultValue={this.state.geoTypeName}
                            onChange={this.geoChange}
                            // size="25"
                            // style={{ width: '100%' }}
                            type="text"
                            style={{ marginTop: '10px',  width: '100%' }}
                            autoComplete="off"
                        />

                        <div className={`custom-selection ${(this.state.findedGeos.length > 0 && this.state.areaState) ? 'dis-block' : ''}`}>
                            {this.state.findedGeos.map(geo => {
                                return (
                                    <div
                                        className="custom-selection__select"
                                        onClick={() => this.onGeoIdClick(geo._id)}
                                        key={geo._id}
                                    >
                                        {`${geo.geoType} [${geo.geoId}]`}
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="bulk-actions">
                        <button className="btn-bulk" onClick={this.areaModalToggle}>Done</button>
                        <button className="btn-bulk" onClick={this.areaModalToggle}>Cancel</button>
                    </div>
                    <div style={{ marginTop: '10px' }}>
                        {this.state.geos.map(geo => {
                            return (
                                <p key={geo}>
                                    {geo}
                                    <span
                                        style={{ fontWeight: '700', marginLeft: '4px', color: '#f00', cursor: 'pointer' }}
                                        onClick={() => this.geoRemoveHandler(geo)}
                                    >
                                        &#9747;
                                    </span>
                                </p>
                            );
                        })}
                    </div>
                </CustomModal>

                <CustomModal active={this.state.dateModal} modalController={this.dateModalToggle}>
                    <input
                        id="date"
                        title="Select date"
                        name="date"
                        // value={(this.state.date[this.state.date.length - 1]) ? this.state.date[this.state.date.length - 1] : this.state.date[0]}
                        defaultValue=""
                        onChange={this.onDateChange}
                        size="25"
                        type="date"
                    />

                    <div className="bulk-actions">
                        <button className="btn-bulk" onClick={this.dateModalToggle}>Done</button>
                        <button className="btn-bulk" onClick={this.dateModalToggle}>Cancel</button>
                    </div>

                    <div style={{ marginTop: '10px' }}>
                        {this.state.date.map(dateStr => {
                            return (
                                <p key={dateStr}>
                                    {dateStr}
                                    <span
                                        style={{ fontWeight: '700', marginLeft: '4px', color: '#f00', cursor: 'pointer' }}
                                        onClick={() => this.dateRemoveHandler(dateStr)}
                                    >
                                        &#9747;
                                    </span>
                                </p>
                            );
                        })}
                    </div>
                </CustomModal>

                <CustomModal active={this.state.shippingModal} modalController={this.shippingModalToggle}>
                    <label
                        id=""
                        title=""
                        htmlFor=""
                    >
                        <b>Enter Shipping Method</b>
                    </label>
                    <div style={{ width: '80%' }}>
                        <input type="text" name="shipping_name"
                            style={{ width: '100%' }}
                            value={this.state.shippingName}
                            onChange={this.shippingNameHandler}
                            autoComplete="off"
                        />
                        {/* {(this.state.findedMethods.length < 0 && this.state.selectState) ? ('') : ( */}
                        <div className={`custom-selection ${(this.state.findedMethods.length > 0 && this.state.selectState) ? 'dis-block' : ''}`}>
                            {this.state.findedMethods.map(method => {
                                return (
                                    <div
                                        className="custom-selection__select"
                                        onClick={() => this.onSelectionClick(method._id)}
                                        key={method._id}
                                    >
                                        {`${method.description} [${method.name}]`}
                                    </div>
                                )
                            })}
                        </div>
                        {/* )} */}
                    </div>

                    <div className="bulk-actions">
                        <button className="btn-bulk" onClick={this.shippingModalToggle}>Done</button>
                        <button className="btn-bulk" onClick={this.shippingModalToggle}>Cancel</button>
                    </div>

                    <div style={{ marginTop: '10px' }}>
                        {this.state.methods.map(methodStr => {
                            return (
                                <p key={methodStr}>
                                    {methodStr}
                                    <span
                                        style={{ fontWeight: '700', marginLeft: '4px', color: '#f00', cursor: 'pointer' }}
                                        onClick={() => this.methodRemoveHandler(methodStr)}
                                    >
                                        &#9747;
                                    </span>
                                </p>
                            );
                        })}
                    </div>
                </CustomModal>

                <CustomModal active={this.state.timeSlotModal} modalController={this.timeSlotModalToggle}>
                    <label
                        id=""
                        title=""
                        htmlFor=""
                    >
                        <b>Enter Time Slot</b>
                    </label>
                    <div style={{ width: '80%' }}>
                        <input type="text" name="shipping_name"
                            style={{ width: '100%' }}
                            value={this.state.timeSlotName}
                            onChange={this.timeSlotNameHandler}
                            autoComplete="off"
                        />
                        {/* {(this.state.findedMethods.length < 0 && this.state.selectState) ? ('') : ( */}
                        <div className={`custom-selection ${(this.state.findedSlots.length > 0 && this.state.timeSelectState) ? 'dis-block' : ''}`}>
                            {this.state.findedSlots.map(slot => {
                                return (
                                    <div
                                        className="custom-selection__select"
                                        onClick={() => this.onSlotSelectionClick(slot._id)}
                                        key={slot._id}
                                    >
                                        {`${slot.startTime} ${slot.endTime} [${slot._id}]`}
                                    </div>
                                )
                            })}
                        </div>
                        {/* )} */}
                    </div>

                    <div className="bulk-actions">
                        <button className="btn-bulk" onClick={this.timeSlotModalToggle}>Done</button>
                        <button className="btn-bulk" onClick={this.timeSlotModalToggle}>Cancel</button>
                    </div>

                    <div style={{ marginTop: '10px' }}>
                        {this.state.slots.map(methodStr => {
                            return (
                                <p key={methodStr}>
                                    {methodStr}
                                    <span
                                        style={{ fontWeight: '700', marginLeft: '4px', color: '#f00', cursor: 'pointer' }}
                                        onClick={() => this.slotsRemoveHandler(methodStr)}
                                    >
                                        &#9747;
                                    </span>
                                </p>
                            );
                        })}
                    </div>
                </CustomModal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    shipmentMethods: state.catalog.shipmentMethodTypesReducer.shipment_method_types,
    timeSlotsTypes: state.catalog.timeSlotsReducer.time_slots_types,
    geos: state.catalog.geo.geos
});

const mapDispatchToProps = (dispatch, router) => ({
    addToBlock: (data) => dispatch(addToBlock(data, router)),
    editToBlock: (data) => dispatch(editToBlock(data, router)),
    getShipmentTypes: () => dispatch(allActiveShipmentMethodsTypes()),
    getTimeSlots: () => dispatch(allActiveTimeSlots()),
    getGeoOfType: (data) => dispatch(getGeoOfType(data, router)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BulkBlock);