import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";
import {searchProduct, advancesearchProduct} from "../../../../store/actions/catalogActions";
import queryString from "query-string";


class SearchView extends Component {
    render() {
    const {products_searched} = this.props;
    const {id} = this.state;
        return (<>

            <div id="content-main-section" className="leftonly">
                <div id="centerdiv" className="no-clear">
                    <div className="screenlet">
                        <div className="screenlet-title-bar">
                            <h3>Search Products, you searched for:</h3>
                        </div>
                        <div className="screenlet-body">
                            <div><a className="buttontext"
                                    href="/catalog/control/keywordsearch?removeConstraint=0&amp;clearSearch=N&amp;SEARCH_CATEGORY_ID=100">X</a> Category:
                                {id} (Include All Sub Categories)
                            </div>
                            <span className="label">Sorted By:</span>Keyword Relevancy
                            <div><Link className="buttontext"
                                    to={`/catalog/control/AdvancedSearch?categoryId=${id}`}>Refine Search</Link>
                            </div>
                            <table className="basic-table" cellSpacing="0">
                                <tbody>
                                <tr>
                                    <td><input name="selectAll" type="checkbox" value="0"/> <strong>Product</strong>
                                    </td>
                                    <td align="right"><strong> 1 - 15 of 15 <a className="buttontext"
                                                                               href="/catalog/control/keywordsearch/~VIEW_INDEX=0/~VIEW_SIZE=99999/~clearSearch=N/~PAGING=N/~noConditionFind=N">Paging
                                        Off</a> </strong></td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        <hr/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <form method="post" name="products">
                                <input name="productStoreId" type="hidden" value=""/>
                                <table className="basic-table" cellSpacing="0">
                                    <tbody>
                                    {products_searched && products_searched.length ?products_searched.map(product => {
                                       return [
                                           <tr valign="middle">
                                               <td><input name="selectResult" type="checkbox" value="GZ-1000"/> <Link
                                                   className="buttontext"
                                                   to={`/catalog/control/EditFnpProduct?productId=${product._id}`}>
                                                   {product.productId}{product.internalName}</Link></td>
                                           </tr>
                                       ]
                                    }):(<tr>
                                        <td><h2>Record Not Fount</h2></td>
                                    </tr>)}
                                    </tbody>
                                </table>
                            </form>
                            <table className="basic-table" cellSpacing="0">
                                <tbody>
                                <tr>
                                    <td colSpan="2">
                                        <hr/>
                                    </td>
                                </tr>
                                <tr>
                                    <td align="right"><strong> 1 - 15 of 15 <a className="buttontext"
                                                                               href="/catalog/control/keywordsearch/~VIEW_INDEX=0/~VIEW_SIZE=99999/~clearSearch=N/~PAGING=N/~noConditionFind=N">Paging
                                        Off</a> </strong></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <hr/>
                    <span className="label"><strong>NOTE:</strong></span> The check boxes above are used only to add to
                    and remove from the text box below, they will not limit the other actions for the forms below!
                    <hr/>
                    <div id="screenlet_1" className="screenlet">
                        <div className="screenlet-title-bar">
                            <ul>
                                <li className="h3">Create Virtual with Variants</li>
                            </ul>
                        </div>
                        <div id="screenlet_1_col" className="screenlet-body">
                            <form action="/catalog/control/quickCreateVirtualWithVariants" method="post"
                                  name="quickCreateVirtualWithVariants">
                                <table className="basic-table" cellSpacing="0">
                                    <tbody>
                                    <tr className="header-row">
                                        <td><strong>Quick Create Virtual from Variants</strong></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <br/> <span className="label">Variant Product IDs:</span>
                                            <textarea cols="20" name="variantProductIdsBag" rows="6"></textarea>
                                            <span className="label">Hazmat:</span>
                                            <select name="productFeatureIdOne">
                                                <option value="">- None -</option>
                                                <option value="1000">Hazmat</option>
                                                <option value="1001">Non-Hazmat</option>
                                            </select>
                                            <input type="submit" value="Create Virtual Product"/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                    <hr/>
                    <div>
                        <form action="/catalog/control/searchRemoveFromCategory" method="post"
                              name="searchRemoveFromCategory">
                            <span className="label">Remove Results From Category:</span>
                            <div id="3_lookupId_SE_SEARCH_CATEGORY_ID_auto"></div>
                            <span className="field-lookup"><input id="3_lookupId_SE_SEARCH_CATEGORY_ID"
                                                                  className="ui-autocomplete-input" autoComplete="off"
                                                                  maxLength="20" name="SE_SEARCH_CATEGORY_ID" size="20"
        type="text"/> <a href id="3_lookupId_button">{" "}</a></span>
                            <input name="clearSearch" type="hidden" value="N"/> <input className="smallSubmit"
                                                                                       type="submit" value="Remove"/>
                        </form>
                    </div>
                    <hr/>
                    <div>
                        <form action="/catalog/control/searchExpireFromCategory" method="post"
                              name="searchExpireFromCategory">
                            <span className="label">Expire Results From Category:</span>
                            <div id="4_lookupId_SE_SEARCH_CATEGORY_ID_auto"></div>
                            <span className="field-lookup"><input id="4_lookupId_SE_SEARCH_CATEGORY_ID"
                                                                  className="ui-autocomplete-input" autoComplete="off"
                                                                  maxLength="20" name="SE_SEARCH_CATEGORY_ID" size="20"
        type="text"/> <a href="/" id="4_lookupId_button">{''}</a></span>
                            <span className="label">Through</span> <span className="view-calendar"> <input
                            id="thruDate1_i18n" title="dd-MM-yyyy HH:mm:ss" maxLength="30" name="thruDate_i18n"
                            size="25" type="text"/> <input id="thruDate1" className="hasDatepicker, heightWith"
            
                                                           title="dd-MM-yyyy HH:mm:ss" maxLength="30" name="thruDate"
                                                           size="25" type="text"/><button
                            className="ui-datepicker-trigger" type="button"></button> <input name="" type="hidden"
                                                                                             value="Timestamp"/> </span>
                            <input name="clearSearch" type="hidden" value="N"/> <input className="smallSubmit"
                                                                                       type="submit" value="Expire"/>
                        </form>
                    </div>
                    <hr/>
                    <div>
                        <form action="/catalog/control/searchAddToCategory" method="post" name="searchAddToCategory">
                            <span className="label">Add Results To Category:</span>
                            <div id="5_lookupId_SE_SEARCH_CATEGORY_ID_auto"></div>
                            <span className="field-lookup"><input id="5_lookupId_SE_SEARCH_CATEGORY_ID"
                                                                  className="ui-autocomplete-input" autoComplete="off"
                                                                  maxLength="20" name="SE_SEARCH_CATEGORY_ID" size="20"
        type="text"/> <a href="/" id="5_lookupId_button">{""}</a></span>
                            <span className="label">From</span> <span className="view-calendar"> <input
                            id="fromDate1_i18n" title="dd-MM-yyyy HH:mm:ss" maxLength="30" name="fromDate_i18n"
                            size="25" type="text"/> <input id="fromDate1" className="hasDatepicker,heightWith"
                                                        
                                                           title="dd-MM-yyyy HH:mm:ss" maxLength="30" name="fromDate"
                                                           size="25" type="text"/><button
                            className="ui-datepicker-trigger" type="button"></button> <input name="" type="hidden"
                                                                                             value="Timestamp"/> </span>
                            <input name="clearSearch" type="hidden" value="N"/> <input className="smallSubmit"
                                                                                       type="submit"
                                                                                       value="Add to Category"/>
                        </form>
                    </div>
                    <hr/>
                    <div>
                        <form action="/catalog/control/searchAddFeature" method="post" name="searchAddFeature">
                            <span className="label">Add Feature to Results:</span><br/> <span className="label">Feature Id</span><input
                            name="productFeatureId" size="10" type="text" value=""/> <span className="label">From</span>
                            <span className="view-calendar"> <input id="fromDate2_i18n" title="dd-MM-yyyy HH:mm:ss"
                                                                    maxLength="30" name="fromDate_i18n" size="25"
                                                                    type="text"/> <input id="fromDate2"
                                                                                         className="hasDatepicker heightWith"
                                                                                        
                                                                                         title="dd-MM-yyyy HH:mm:ss"
                                                                                         maxLength="30" name="fromDate"
                                                                                         size="25" type="text"/><button
                                className="ui-datepicker-trigger" type="button"></button> <input name="" type="hidden"
                                                                                                 value="Timestamp"/> </span>
                            <span className="label">Through</span> <span className="view-calendar"> <input
                            id="thruDate2_i18n" title="dd-MM-yyyy HH:mm:ss" maxLength="30" name="thruDate_i18n"
                            size="25" type="text"/> <input id="thruDate2" className="hasDatepicker, heightWith"
                                                           
                                                           title="dd-MM-yyyy HH:mm:ss" maxLength="30" name="thruDate"
                                                           size="25" type="text"/><button
                            className="ui-datepicker-trigger" type="button"></button> <input name="" type="hidden"
                                                                                             value="Timestamp"/> </span>
                            <br/> <span className="label">Amount</span><input name="amount" size="5" type="text"
                                                                              value=""/> <span
                            className="label">Sequence</span><input name="sequenceNum" size="5" type="text" value=""/>
                            <span className="label">Feature Application Type</span> <span
                            className="label">Category ID:</span>
                            <select name="productFeatureApplTypeId" size="1">
                                <option value="DISTINGUISHING_FEAT">Distinguishing</option>
                                <option value="OPTIONAL_FEATURE">Optional</option>
                                <option value="REQUIRED_FEATURE">Required</option>
                                <option value="SELECTABLE_FEATURE">Selectable</option>
                                <option value="STANDARD_FEATURE">Standard</option>
                            </select>
                            <input name="clearSearch" type="hidden" value="N"/> <input className="smallSubmit"
                                                                                       type="submit"
                                                                                       value="Add Feature"/>
                        </form>
                    </div>
                    <hr/>
                    <div>
                        <form action="/catalog/control/searchRemoveFeature" method="post" name="searchRemoveFeature">
                            <span className="label">Remove Feature from Results:</span><br/> <span className="label">Feature Id</span><input
                            name="productFeatureId" size="10" type="text" value=""/> <input name="clearSearch"
                                                                                            type="hidden" value="N"/>
                            <input className="smallSubmit" type="submit" value="Remove Feature"/></form>
                    </div>
                    <hr/>
                    <div>
                        <form action="" method="post" name="searchShowParams"><span className="label">Plain Search Parameters:</span><input
                            name="searchParameters" size="60" type="text"
                            value="S_CAT1=100&amp;S_CSB1=Y&amp;S_O=SKR&amp;S_A=N"/> <br/> <span className="label">HTML Search Parameters:</span><input
                            name="searchParameters" size="60" type="text"
                            value="S_CAT1=100&amp;amp;S_CSB1=Y&amp;amp;S_O=SKR&amp;amp;S_A=N"/> <input
                            name="clearSearch" type="hidden" value="N"/></form>
                    </div>
                    <hr/>
                    <div><span className="label">Export Product List to Screen:</span><a className="buttontext"
                                                                                         href="/catalog/control/searchExportProductList?clearSearch=N">Export</a>
                    </div>
                </div>
            </div>
        </>);
    }

    constructor() {
        super();
        this.state = {
            categoryId: ''
        };
    }
    componentDidMount() {
        const url = new URLSearchParams(this.props?.location?.search);
        let categoryId = url?.get("categoryId");
        if(categoryId) {
            this.setState({id:categoryId});
        }
        let idFilterType = url?.get("idFilterType");
        let idOperator = url?.get("idOperator");
        let advancedSearched = url?.get("advancedSearched");
        let data = {};
        if(advancedSearched === 'Y') {
            return;
        }
        data = categoryId?{...data, categoryId: categoryId}: data;
        data = idFilterType?{...data, idFilterType: idFilterType}: data;
        data = idOperator?{...data, idOperator: idOperator}: data;
         this.props.search(data);
    }
}
const mapStateToProps = (state) => (
    ()=> {
        return {
            products_searched: state.catalog.products.products_searched,
        }});

const mapDispatchToProps = (dispatch, router) => ({
    search: (data) => dispatch(searchProduct(data, router)),
    advancesearchProduct: (data) => dispatch(advancesearchProduct(data, router)),

});
export default
    withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchView)
    );
