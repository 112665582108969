import React, { Component } from "react";
import { Notify } from "../../../../constants/utils";

import "../../styles.scss";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getAllFeatures,
  getAllFeatureCategory,
  getAllInteractions,
  updateProduct,
  getProduct,
  attachFeaturesToProduct,
  getFeatureByTypeAndIdCode,
  createInteraction,
} from "../../../../store/actions/catalogActions";
import queryString from "query-string";
import EditProductNav from "./EditProductNav";
import { getRequest } from "../../../../store/services";

class Feature extends Component {
  render() {
    const { feature, Interactions } = this.state;
    console.log(" thse are features", feature);
    const query = queryString.parse(this.props.location.search);
    const product = query.productId;
    return (
      <div id="content-main-section" className="leftonly">
        <div id="centerdiv" className="no-clear">
          <EditProductNav product={product} />

          <div>
            <div className="screenlet">
              <div className="screenlet-title-bar">
                <h3>Edit Product Features</h3>
              </div>
              <div className="screenlet-body">
                <form>
                  <input name="_useRowSubmit" type="hidden" value="Y" />{" "}
                  <input name="_checkGlobalScope" type="hidden" value="Y" />{" "}
                  <input name="productId" type="hidden" value="CAKE100" />
                  <table className="basic-table" cellSpacing="0">
                    <tbody>
                      <tr className="header-row">
                        <td width="2%">
                          <strong>ID</strong>
                        </td>
                        <td>
                          <strong>Name</strong>
                        </td>
                        <td>
                          <strong>Description</strong>
                        </td>
                        <td>
                          <strong>Uom Id</strong>
                        </td>
                        <td>
                          <strong>Type</strong>
                        </td>
                        <td>
                          <strong>Category</strong>
                        </td>
                        <td>
                          <strong>From Date</strong>
                        </td>
                        <td>
                          <strong>
                            Through Date, Amount, Sequence, Application Type
                          </strong>
                        </td>
                        <td>
                          <strong>
                            All
                            <input name="selectAll" type="checkbox" value="Y" />
                          </strong>
                        </td>
                      </tr>

                      {feature?.map((one) => (
                        <tr>
                          <td width="2%">{one._id}</td>
                          <td>{one.id}</td>
                          <td>{one.description}</td>
                          <td>{one.unitOfMeasurId}</td>
                          <td>{one.Type.id}</td>
                          <td>
                            <button>
                              {one.Category.id}[{one.Category.description}]
                            </button>
                          </td>
                          <td>{one.createdDate}</td>
                          <td>
                            <input type="text" />
                          </td>
                          <td>
                            <input type="text" />
                          </td>
                          <td>
                            <input
                              style={{ width: "100px" }}
                              value={one.featureToProducts._id}
                              type="text"
                            />
                          </td>
                          <td>
                            <select>
                              <option value="distinguishing">
                                Distinguishing
                              </option>
                              <option value="optional">Optional</option>
                              <option value="required">Required</option>
                              <option value="selectable">Selectable</option>
                              <option selected="selected" value="standard">
                                Standard
                              </option>
                            </select>
                          </td>
                          <td>
                            <button>Delete</button>
                          </td>
                        </tr>
                      ))}

                      <tr>
                        <td colSpan="8" align="center">
                          <input name="_rowCount" type="hidden" value="0" />{" "}
                          <input type="submit" value="Update" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
            </div>

            <div className="screenlet">
              <div className="screenlet-title-bar">
                <h3>Add Product Feature From Category or Group</h3>
              </div>
              <div className="screenlet-body">
                <form onSubmit={this.handleSubmit}>
                  <input name="productId" type="hidden" value="CAKE100" />
                  <select
                    onChange={this.handleChange}
                    name="featureId"
                    size="1"
                  >
                    <option selected="selected" value="">
                      Choose a Feature Category
                    </option>
                    {this.props.features?.map((one) => (
                      <option value={one._id}>
                        {one.description} [{one.id}]
                      </option>
                    ))}
                  </select>
                  <select name="productFeatureGroupId" size="1">
                    <option selected="selected" value="">
                      Choose a Feature Group
                    </option>
                  </select>
                  <span className="label">Feature Application Type: </span>
                  <select name="type" onChange={this.handleChange}>
                    <option value="distinguishing">Distinguishing</option>
                    <option value="optional">Optional</option>
                    <option value="required">Required</option>
                    <option value="selectable">Selectable</option>
                    <option selected="selected" value="standard">
                      Standard
                    </option>
                  </select>
                  <input type="submit" value="Add" />
                </form>
              </div>
            </div>

            <div className="screenlet">
              <div className="screenlet-title-bar">
                <h3>Add Product Feature with Type and ID Code</h3>
              </div>
              <div className="screenlet-body">
                <form onSubmit={this.saveByType}>
                  <span className="label">Feature Type: </span>
                  <select
                    onChange={this.handleChange}
                    name="featureType"
                    size="1"
                  >
                    <option selected="selected" value="">
                      Choose a Feature Type
                    </option>
                    {this.props.features?.map((one) => (
                      <option value={one.Type._id}>{one.Type?.id}</option>
                    ))}
                  </select>
                  <span className="label">ID Code: </span>
                  <input
                    onChange={this.handleChange}
                    name="idCode"
                    size="10"
                    type="text"
                  />
                  <br />
                  <span className="label">Feature Application Type: </span>
                  <select name="type" onChange={this.handleChange}>
                    <option value="distinguishing">Distinguishing</option>
                    <option value="optional">Optional</option>
                    <option value="required">Required</option>
                    <option value="selectable">Selectable</option>
                    <option selected="selected" value="standard">
                      Standard
                    </option>
                  </select>
                  <br />
                  <span className="label">From : </span>
                  <span className="view-calendar">
                    <input
                      onChange={this.handleChange}
                      title="dd-MM-yyyy HH:mm:ss"
                      maxLength="30"
                      name="fromDate"
                      size="25"
                      type="text"
                    />
                    <button
                      className="ui-datepicker-trigger"
                      type="button"
                    ></button>
                  </span>
                  <span className="label">Through : </span>
                  <span className="view-calendar">
                    <input
                      onChange={this.handleChange}
                      id="thruDate1_i18n"
                      title="dd-MM-yyyy HH:mm:ss"
                      maxLength="30"
                      name="throughDate"
                      size="25"
                      type="text"
                    />
                    <button
                      className="ui-datepicker-trigger"
                      type="button"
                    ></button>
                  </span>
                  <span className="label">Sequence : </span>
                  <input name="sequenceNum" size="5" type="text" />{" "}
                  <input type="submit" value="Add" />
                </form>
              </div>
            </div>

            <div className="screenlet">
              <div className="screenlet-title-bar">
                <h3>Add Product Feature with ID</h3>
              </div>
              <div className="screenlet-body">
                <form onSubmit={this.handleSubmit}>
                  <input name="productId" type="hidden" value="CAKE100" />{" "}
                  <span className="label">ID: </span>
                  <span className="label">
                    <span className="field-lookup">
                      <input
                        onChange={this.handleChange}
                        className="ui-autocomplete-input"
                        autoComplete="off"
                        maxLength="20"
                        name="featureId"
                        size="20"
                        type="text"
                      />
                      <a id="3_lookupId_button"></a>
                    </span>{" "}
                  </span>{" "}
                  <span className="label">Feature Application Type: </span>
                  <select name="type" onChange={this.handleChange}>
                    <option value="distinguishing">Distinguishing</option>
                    <option value="optional">Optional</option>
                    <option value="required">Required</option>
                    <option value="selectable">Selectable</option>
                    <option selected="selected" value="standard">
                      Standard
                    </option>
                  </select>
                  <br />
                  <span className="label">From : </span>
                  <span className="view-calendar">
                    <input
                      onChange={this.handleChange}
                      title="dd-MM-yyyy HH:mm:ss"
                      maxLength="30"
                      name="fromDate"
                      size="25"
                      type="text"
                    />
                    <button
                      className="ui-datepicker-trigger"
                      type="button"
                    ></button>
                  </span>
                  <span className="label">Through : </span>
                  <span className="view-calendar">
                    <input
                      onChange={this.handleChange}
                      id="thruDate1_i18n"
                      title="dd-MM-yyyy HH:mm:ss"
                      maxLength="30"
                      name="throughDate"
                      size="25"
                      type="text"
                    />
                    <button
                      className="ui-datepicker-trigger"
                      type="button"
                    ></button>
                  </span>
                  <span className="label">Sequence : </span>
                  <input name="sequenceNum" size="5" type="text" />{" "}
                  <input type="submit" value="Add" />
                </form>
              </div>
            </div>
          </div>

          <div id="screenlet_1" className="screenlet">
            <div className="screenlet-title-bar">
              <ul>
                <li className="h3">List Feature Interactions</li>
              </ul>
            </div>

            <div id="screenlet_1_col" className="screenlet-body">
              <div className="nav-pager">
                <ul>
                  <li className="nav-first-disabled">First</li>
                  <li className="nav-previous-disabled">Previous</li>
                  <li className="nav-next-disabled">Next</li>
                  <li className="nav-last-disabled">Last</li>
                  <li className="nav-pagesize">
                    <select
                      name="pageSize"
                      size="1"
                      onChange="submitPagination(this, '')"
                    >
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option selected="selected" value="200">
                        200
                      </option>
                    </select>
                    Items per page
                  </li>
                  <li className="nav-displaying">Displaying 1 - 0 of 0</li>
                </ul>
              </div>
              <table className="basic-table" cellSpacing="0">
                <tbody>
                  <tr className="header-row">
                    <td>
                      <label
                        id="ListFeatureInteractions_productFeatureId_title"
                        htmlFor="ListFeatureInteractions_productFeatureId"
                      >
                        Product Feature Id
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListFeatureInteractions_productFeatureIdTo_title"
                        htmlFor="ListFeatureInteractions_productFeatureIdTo"
                      >
                        Product Feature Id To
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListFeatureInteractions_productFeatureIactnTypeId_title"
                        htmlFor="ListFeatureInteractions_productFeatureIactnTypeId"
                      >
                        Interaction Type
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListFeatureInteractions_removeFeatureInterAction_title"
                        htmlFor="ListFeatureInteractions_removeFeatureInterAction"
                      >
                        Delete
                      </label>
                    </td>
                  </tr>
                  {Interactions?.map((one) => (
                    <tr>
                      <td>{one.Feature.id}</td>
                      <td>{one.ToFeature.id}</td>
                      <td>{one.relation}</td>
                      <td>
                        <button>Delete</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="nav-pager">
                <ul>
                  <li className="nav-first-disabled">First</li>
                  <li className="nav-previous-disabled">Previous</li>
                  <li className="nav-next-disabled">Next</li>
                  <li className="nav-last-disabled">Last</li>
                  <li className="nav-pagesize">
                    <select
                      name="pageSize"
                      size="1"
                      onChange="submitPagination(this, '')"
                    >
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option selected="selected" value="200">
                        200
                      </option>
                    </select>
                    Items per page
                  </li>
                  <li className="nav-displaying">Displaying 1 - 0 of 0</li>
                </ul>
              </div>
            </div>
          </div>

          <div id="screenlet_2" className="screenlet">
            <div className="screenlet-title-bar">
              <ul>
                <li className="h3">Add Feature Interaction</li>
              </ul>
            </div>
            <div id="screenlet_2_col" className="screenlet-body">
              <form onSubmit={this.saveInteraction}>
                <table className="basic-table" cellSpacing="0">
                  <tbody>
                    <tr>
                      <td className="label">
                        <label
                          id="AddFeatureInteraction_productFeatureId_title"
                          htmlFor="AddFeatureInteraction_productFeatureId"
                        >
                          Product Feature Id
                        </label>
                      </td>
                      <td>
                        <span className="ui-widget">
                          <select
                            onChange={this.handleChange}
                            id="AddFeatureInteraction_productFeatureId"
                            name="featureId"
                            size="1"
                          >
                            <option value="">Select Feature Id </option>
                            {feature?.map((one) => (
                              <option value={one._id}>{one.id}</option>
                            ))}
                          </select>
                        </span>
                      </td>
                      <td className="label">
                        <label
                          id="AddFeatureInteraction_productFeatureIdTo_title"
                          htmlFor="AddFeatureInteraction_productFeatureIdTo"
                        >
                          Product Feature Id To
                        </label>
                      </td>
                      <td>
                        <span className="ui-widget">
                          <select
                            onChange={this.handleChange}
                            id="AddFeatureInteraction_productFeatureIdTo"
                            name="featureIdTo"
                            size="1"
                          >
                            <option value="">Select Feature Id To </option>
                            {feature?.map((one) => (
                              <option value={one._id}>{one.id}</option>
                            ))}
                          </select>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        <label
                          id="AddFeatureInteraction_productFeatureIactnTypeId_title"
                          htmlFor="AddFeatureInteraction_productFeatureIactnTypeId"
                        >
                          Interaction Type
                        </label>
                      </td>
                      <td colSpan="4">
                        <span className="ui-widget">
                          <select
                            onChange={this.handleChange}
                            name="relation"
                            size="1"
                          >
                            <option value="dependent feature">
                              Dependent Feature
                            </option>
                            <option value="incompatible feature">
                              Incompatible Feature
                            </option>
                          </select>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="label"></td>
                      <td colSpan="4">
                        <input
                          className="smallSubmit"
                          name="submitButton"
                          type="submit"
                          value="Add"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>

          <div id="screenlet_3" className="screenlet">
            <div className="screenlet-title-bar">
              <ul>
                <li className="h3">Product Feature Attributes</li>
              </ul>
            </div>
            <div id="screenlet_3_col" className="screenlet-body">
              <form>
                <input
                  id="AddProductFeatureApplAttr_productId"
                  name="productId"
                  type="hidden"
                  value="CAKE100"
                />
                <table className="basic-table" cellSpacing="0">
                  <tbody>
                    <tr>
                      <td className="label">
                        <label
                          id="AddProductFeatureApplAttr_productFeatureId_title"
                          htmlFor="AddProductFeatureApplAttr_productFeatureId"
                        >
                          Product Feature Id
                        </label>
                      </td>
                      <td>
                        <span className="ui-widget">
                          <select
                            id="AddProductFeatureApplAttr_productFeatureId"
                            name="productFeatureId"
                            size="1"
                          >
                            <option value=""> </option>
                          </select>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        <label
                          id="AddProductFeatureApplAttr_fromDate_title"
                          htmlFor="AddProductFeatureApplAttr_fromDate"
                        >
                          From Date
                        </label>
                      </td>
                      <td>
                        <span className="view-calendar">
                          {" "}
                          <input
                            id="AddProductFeatureApplAttr_fromDate_i18n"
                            title="dd-MM-yyyy HH:mm:ss"
                            maxLength="30"
                            name="fromDate_i18n"
                            size="25"
                            type="text"
                          />{" "}
                          <input
                            id="AddProductFeatureApplAttr_fromDate"
                            className="hasDatepicker productFeature"
                            title="dd-MM-yyyy HH:mm:ss"
                            maxLength="30"
                            name="fromDate"
                            size="25"
                            type="text"
                          />
                          <button
                            className="ui-datepicker-trigger"
                            type="button"
                          ></button>{" "}
                          <input name="" type="hidden" value="Timestamp" />{" "}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        <label
                          id="AddProductFeatureApplAttr_attrName_title"
                          htmlFor="AddProductFeatureApplAttr_attrName"
                        >
                          Attr Name
                        </label>
                      </td>
                      <td>
                        <input
                          id="AddProductFeatureApplAttr_attrName"
                          className="required"
                          maxLength="60"
                          name="attrName"
                          size="40"
                          type="text"
                        />{" "}
                        *
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        <label
                          id="AddProductFeatureApplAttr_attrValue_title"
                          htmlFor="AddProductFeatureApplAttr_attrValue"
                        >
                          Attr Value
                        </label>
                      </td>
                      <td>
                        <input
                          id="AddProductFeatureApplAttr_attrValue"
                          maxLength="250"
                          name="attrValue"
                          size="60"
                          type="text"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label"></td>
                      <td colSpan="4">
                        <input
                          className="smallSubmit"
                          name="submitButton"
                          type="submit"
                          value="Add"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>

          <div id="screenlet_4" className="screenlet">
            <div className="screenlet-title-bar">
              <ul>
                <li className="h3">List Product Feature Appl Attrs</li>
              </ul>
            </div>
            <div id="screenlet_4_col" className="screenlet-body">
              <div className="nav-pager">
                <ul>
                  <li className="nav-first-disabled">First</li>
                  <li className="nav-previous-disabled">Previous</li>
                  <li className="nav-next-disabled">Next</li>
                  <li className="nav-last-disabled">Last</li>
                  <li className="nav-pagesize">
                    <select
                      name="pageSize"
                      size="1"
                      onChange="submitPagination(this, '')"
                    >
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option selected="selected" value="200">
                        200
                      </option>
                    </select>
                    Items per page
                  </li>
                  <li className="nav-displaying">Displaying 1 - 0 of 0</li>
                </ul>
              </div>
              <table className="basic-table" cellSpacing="0">
                <tbody>
                  <tr className="header-row">
                    <td>
                      <label
                        id="ListProductFeatureApplAttrs_productFeatureId_title"
                        htmlFor="ListProductFeatureApplAttrs_productFeatureId"
                      >
                        Product Feature Id
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListProductFeatureApplAttrs_fromDate_title"
                        htmlFor="ListProductFeatureApplAttrs_fromDate"
                      >
                        From Date
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListProductFeatureApplAttrs_attrName_title"
                        htmlFor="ListProductFeatureApplAttrs_attrName"
                      >
                        Attr Name
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListProductFeatureApplAttrs_attrValue_title"
                        htmlFor="ListProductFeatureApplAttrs_attrValue"
                      >
                        Attr Value
                      </label>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <div className="nav-pager">
                <ul>
                  <li className="nav-first-disabled">First</li>
                  <li className="nav-previous-disabled">Previous</li>
                  <li className="nav-next-disabled">Next</li>
                  <li className="nav-last-disabled">Last</li>
                  <li className="nav-pagesize">
                    <select
                      name="pageSize"
                      size="1"
                      onChange="submitPagination(this, '')"
                    >
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option selected="selected" value="200">
                        200
                      </option>
                    </select>
                    Items per page
                  </li>
                  <li className="nav-displaying">Displaying 1 - 0 of 0</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      features: [],
      interactions: [],
      featureId: "",
      type: "",
      productId: "",
      fromDate: "",
      throughDate: "",
      featureType: "",
      idCode: "",
      featureIdTo: "",
      relation: "dependent feature",
    };
  }

  async componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    const product = query.productId;
    const data = await this.props.productDetails(product);
    const {
      _id,
      productId,
      title,
      internalName,
      brandName,
      OEMPartyId,
      comments,
      isVirtual,
      introductionDate,
      releaseDate,
      supportThroughDate,
      salesDiscontinuationThroughDate,
      amount,
      returnable,
      includeInPromotions,
      taxable,
      autoCreateKeywords,
      description,
      deliveryInfo,
      careInfo,
      category,
      images,
      isActive,
      isDeleted,
      Feature,
      Interactions,
    } = data?.data?.product;
    this.setState({
      Interactions,
      Feature,
      productId,
      title,
      internalName,
      brandName,
      OEMPartyId,
      comments,
      isVirtual,
      introductionDate,
      releaseDate,
      supportThroughDate,
      salesDiscontinuationThroughDate,
      amount,
      returnable,
      includeInPromotions,
      taxable,
      autoCreateKeywords,
      description,
      deliveryInfo,
      careInfo,
      category: category?._id,
      images,
      isActive,
      isDeleted,
      _id,
    });
    this.setState({ feature: Feature });
    this.setState({ interactions: Interactions });

    this.props.getAllFeatures();
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  saveByType = async (e) => {
    e.preventDefault();
    const query = queryString.parse(this.props.location.search);
    const productId = query.productId;
    const { featureType, idCode, type, fromDate, throughDate } = this.state;
    const forGetFeature = { idCode: idCode, type: featureType };
    const featureData = await this.props.byTypeAndIdCode(forGetFeature);

    if (featureData.data) {
      const featureId = featureData.data._id;
      const featureToAttach = {
        productId,
        featureId,
        type,
        fromDate,
        throughDate,
      };
      const filteredFeature = {};
      Object.keys(featureToAttach).forEach((item) => {
        if (featureToAttach[item]) {
          filteredFeature[item] = featureToAttach[item];
        }
      });
      this.props.attachFeature(filteredFeature);
    } else {
      Notify("danger", "Feature type and Id Code doesn't match");
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const query = queryString.parse(this.props.location.search);
    const productId = query.productId;
    const { featureId, type, fromDate, throughDate } = this.state;

    const token = localStorage.getItem("token");
    getRequest("features/getByFeauteID/" + featureId, null, false, {
      "access-token": token,
    }).then((response) => {
      console.log("this is response", response.data);
      let obj = {
        productId,
        featureId: response.data._id,
        type,
        fromDate,
        throughDate,
      };
      let data = {};
      Object.keys(obj).forEach((item) => {
        if (obj[item]) {
          data[item] = obj[item];
        }
      });
      this.props.attachFeature(data);
    });
  };

  saveInteraction = async (e) => {
    e.preventDefault();
    const query = queryString.parse(this.props.location.search);
    const productId = query.productId;
    const { featureId, featureIdTo, relation } = this.state;
    const obj = { id: featureId, toId: featureIdTo, relation, productId };

    this.props.createInteraction(obj);
  };
}

const mapStateToProps = (state) => ({
  features: state?.catalog?.catalogs?.features,
});

const mapDispatchToProps = (dispatch, router) => ({
  getAllFeatures: (data) => dispatch(getAllFeatures(data, router)),
  productDetails: (id) => dispatch(getProduct(id, router)),
  attachFeature: (data) => dispatch(attachFeaturesToProduct(data, router)),
  byTypeAndIdCode: (data) => dispatch(getFeatureByTypeAndIdCode(data, router)),
  createInteraction: (data) => dispatch(createInteraction(data, router)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Feature)
);
