import React, {Component} from "react";
import {connect} from "react-redux";
import PopUp from "../../../Catalog/containers/shipping/partyPopUp";
import {putRequest} from "../../../../store/services";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {withRouter} from "react-router";
import pdfMake from "pdfmake";
import moment from "moment";

var dd = {
    content: [


        // using a { text: '...' } object lets you set styling properties
        {text: 'Sales by Store Report', fontSize: 20},


        // if you set the value of text to an array instead of a string, you'll be able
        // to style any part individually

    ]
};
var arrayText = {
    text: [
        'Order Status Id: ',
        {text: 'Completed', fontSize: 15},
    ]
};

var productsArray = [
    ['Store', 'Title', 'Qty Sold', 'Value Sold'],
];
var tableObj = {
    layout: 'lightHorizontalLines', // optional
    table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ['*', 'auto', 100, '*'],

        body: []
    }
}

export class OrderPurchaseReportOptions extends Component {
    state = {
        party: '',
        modalOpen: false,
        fromDate: '',
        throughDate: '',
        deliveryStatus: '',
        data: [],
        deliveryStatusItems: '',
        fromDateItems: '',
        throughDateItems: '',
    }
    partySelect = (id) => {
        this.setState({party: id, modalOpen: false});
    }
    handleClose = () => {
        this.setState({modalOpen: false});
    }
    salesByStoreReport = async (e, type) => {
        e.preventDefault();
        if (type === "store") {
            let {party, fromDate, throughDate, deliveryStatus} = this.state;
            fromDate = moment(fromDate).startOf("day").toDate();
            throughDate = moment(throughDate).endOf("day").toDate();
            let dataObj = {partyId: party, fromDate, throughDate, deliveryStatus,type};
            let dataArray = await putRequest('cart/report', dataObj, false);
            this.setState({data: dataArray?.data});
            this.makePdf(dataArray?.data);
        }
        if (type === 'items') {
            let {fromDateItems, throughDateItems, deliveryStatusItems} = this.state;
            fromDateItems = moment(fromDateItems).startOf("day").toDate();
            throughDateItems = moment(throughDateItems).endOf("day").toDate();
            let dataObj = {
                fromDate: fromDateItems,
                throughDate: throughDateItems,
                deliveryStatus: deliveryStatusItems,
                type
            };
            let dataArray = await putRequest('cart/report', dataObj, false);
            this.props.saveData(dataArray.data);
            this.props.history.push({
                pathname:'/ordermgr/control/OpenOrderItemsReport',
            })
        }

    }
    makePdf = (data) => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        let parentObject = JSON.parse(JSON.stringify(dd));
        if (this.state.party) {
            let obj = JSON.parse(JSON.stringify(arrayText));
            obj.text[0] = 'partyId';
            obj.text[1].text = this.state.party
            parentObject.content.push(obj);
        }
        if (this.state.fromDate) {
            let obj = JSON.parse(JSON.stringify(arrayText));
            obj.text[0] = 'From Date:';
            obj.text[1].text = moment(this.state.fromDate).utc(true).format("Y-MM-DD");
            parentObject.content.push(obj);
        }
        if (this.state.throughDate) {
            let obj = JSON.parse(JSON.stringify(arrayText));
            obj.text[0] = 'through Date:';
            obj.text[1].text = moment(this.state.throughDate).utc(true).format("Y-MM-DD");
            parentObject.content.push(obj);
        }
        let parentArray = JSON.parse(JSON.stringify(productsArray));
        for (let i = 0; i < data.length; i++) {
            let array = [];
            array.push('GAB');
            array.push(data[i].name);
            array.push(data[i].quantity);
            array.push(data[i].amount);
            parentArray.push(array);
        }
        let tableObjCopy = JSON.parse(JSON.stringify(tableObj));
        tableObjCopy.table.body = parentArray;
        parentObject.content.push(tableObjCopy);
        pdfMake.createPdf(parentObject).open();

    }


    render() {
        return (
            <div id="content-main-section">
                <h3>
                    Four Birt reports (Last 3 Months Sales, Sales, Sales Order Discount
                    Code, Product Demand) require to feed the BI data base (note that the
                    bi.properties file will need to be updated after September 2023). Then
                    only new approved orders will be taken into account.
                </h3>
                <PopUp
                    partySelect={this.partySelect}
                    handleClose={this.handleClose}
                    modalOpen={this.state.modalOpen}
                />
                <table width="100%">
                    <tbody>
                    <tr>
                        <td
                            id="portalColumn_00001"
                            class="portal-column connectedSortable"
                            style={{verticalAlign: "top", width: "50%"}}
                        >
                            <div id="PP_OrderReportPageSalesByStore00001" class="noClass">
                                <div id="screenlet_1" class="screenlet">
                                    <div class="screenlet-title-bar">
                                        <ul>
                                            <li class="h3">Sales by Store Report</li>
                                        </ul>
                                    </div>
                                    <div id="screenlet_1_col" class="screenlet-body">
                                        <form
                                            // id="SalesByStoreReport"
                                            // class="basic-form"
                                            //action="/ordermgr/control/SalesByStoreReport.pdf"
                                            onSubmit={(e) => this.salesByStoreReport(e, "store")}
                                            // method="post"
                                            // name="SalesByStoreReport"
                                            // target="_BLANK"
                                        >
                                            <table class="basic-table" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="SalesByStoreReport_productStoreId_title"
                                                            for="SalesByStoreReport_productStoreId"
                                                        >
                                                            Product Store Id
                                                        </label>
                                                    </td>
                                                    <td>
                                                    <span class="ui-widget">
                                                      <select
                                                          id="SalesByStoreReport_productStoreId"
                                                          name="productStoreId"
                                                          size="1"
                                                      >
                                                        <option value="">- Any -</option>
                                                        <option value="30000">GAB</option>
                                                      </select>
                                                    </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="SalesByStoreReport_toPartyId_title"
                                                            for="SalesByStoreReport_toPartyId"
                                                        >
                                                            To Party Id
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <div id="0_lookupId_SalesByStoreReport_toPartyId_auto">
                                                            {" "}
                                                        </div>
                                                        <span class="field-lookup">
                                  <input
                                      id="0_lookupId_SalesByStoreReport_toPartyId"
                                      class="ui-autocomplete-input"
                                      autocomplete="off"
                                      name="toPartyId"
                                      size="25"
                                      value={this.state.party}
                                      onChange={(e) => this.setState({party: e.target.value})}
                                      type="number"
                                  />{" "}
                                                            <a style={{cursor: 'pointer'}} id="0_lookupId_button"
                                                               onClick={() => this.setState({modalOpen: true})}>
                                    {" "}
                                  </a>
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="SalesByStoreReport_orderStatusId_title"
                                                            for="SalesByStoreReport_orderStatusId"
                                                        >
                                                            Order Status Id
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="ui-widget">
                                  <select
                                      id="SalesByStoreReport_orderStatusId"
                                      name="orderStatusId"
                                      size="1"
                                      value={this.state.deliveryStatus}
                                      onChange={(e) => this.setState({deliveryStatus: e.target.value})}
                                  >
                                    <option value="">- Any -</option>
                                    <option value="allocated">
                                      Allocated
                                    </option>
                                    <option value="accepted">
                                      Accepted
                                    </option>
                                    <option value="OUT_FOR_DELIVERY">
                                      Out for delivery
                                    </option>
                                    <option value="ATTEMPTED">
                                      Attempted
                                    </option>
                                    <option value="DELIVERED">Delivered</option>

                                  </select>
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="SalesByStoreReport_fromOrderDate_title"
                                                            for="SalesByStoreReport_fromOrderDate"
                                                        >
                                                            From Date
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="view-calendar">
                                  {" "}
                                    <input
                                        id="SalesByStoreReport_fromOrderDate_i18n"
                                        title="dd-MM-yyyy HH:mm:ss"
                                        maxlength="30"
                                        name="fromOrderDate_i18n"
                                        value={this.state.fromDate}
                                        onChange={(e) => this.setState({fromDate: e.target.value})}
                                        size="25"
                                        type="date"
                                    />{" "}
                                    {/*<input*/}
                                    {/*  id="SalesByStoreReport_fromOrderDate"*/}
                                    {/*  class="hasDatepicker"*/}
                                    {/*  title="dd-MM-yyyy HH:mm:ss"*/}
                                    {/*  maxlength="30"*/}
                                    {/*  name="fromOrderDate"*/}
                                    {/*  size="25"*/}
                                    {/*  type="date"*/}
                                    {/*/>*/}
                                    {/*<button*/}
                                    {/*  class="ui-datepicker-trigger"*/}
                                    {/*  type="button"*/}
                                    {/*></button>{" "}*/}
                                    {/*<input*/}
                                    {/*  name=""*/}
                                    {/*  type="hidden"*/}
                                    {/*  value="Timestamp"*/}
                                    {/*/>{" "}*/}
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="SalesByStoreReport_thruOrderDate_title"
                                                            for="SalesByStoreReport_thruOrderDate"
                                                        >
                                                            Through Date
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="view-calendar">
                                  {" "}
                                    <input
                                        id="SalesByStoreReport_thruOrderDate_i18n"
                                        title="dd-MM-yyyy HH:mm:ss"
                                        maxlength="30"
                                        value={this.state.throughDate}
                                        onChange={(e) => this.setState({throughDate: e.target.value})}
                                        name="thruOrderDate_i18n"
                                        size="25"
                                        type="date"
                                    />{" "}
                                    {/*<input*/}
                                    {/*  id="SalesByStoreReport_thruOrderDate"*/}
                                    {/*  class="hasDatepicker"*/}
                                    {/*  title="dd-MM-yyyy HH:mm:ss"*/}
                                    {/*  maxlength="30"*/}
                                    {/*  name="thruOrderDate"*/}
                                    {/*  size="25"*/}
                                    {/*  type="text"*/}
                                    {/*/>*/}
                                    {/*<button*/}
                                    {/*  class="ui-datepicker-trigger"*/}
                                    {/*  type="button"*/}
                                    {/*></button>{" "}*/}
                                    {/*<input*/}
                                    {/*  name=""*/}
                                    {/*  type="hidden"*/}
                                    {/*  value="Timestamp"*/}
                                    {/*/>{" "}*/}
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label"></td>
                                                    <td colspan="4">
                                                        <input
                                                            class="smallSubmit"
                                                            name="submitButton"
                                                            type="submit"
                                                            value="Run"
                                                        />
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div id="PP_OrderReportPageOpenOrderItems00001" class="noClass">
                                <div id="screenlet_2" class="screenlet">
                                    <div class="screenlet-title-bar">
                                        <ul>
                                            <li class="h3">Open Order Items Report</li>
                                        </ul>
                                    </div>
                                    <div id="screenlet_2_col" class="screenlet-body">
                                        <form
                                            // id="OpenOrderItemsReport"
                                            // class="basic-form"
                                            // action="/ordermgr/control/OpenOrderItemsReport"
                                            // method="post"
                                            // name="OpenOrderItemsReport"
                                            onSubmit={(e) => this.salesByStoreReport(e, 'items')}
                                        >
                                            <table class="basic-table" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="OpenOrderItemsReport_productStoreId_title"
                                                            for="OpenOrderItemsReport_productStoreId"
                                                        >
                                                            Product Store Id
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="ui-widget">
                                  <select
                                      id="OpenOrderItemsReport_productStoreId"
                                      name="productStoreId"
                                      size="1"
                                  >
                                    <option value="">- Any -</option>
                                    <option value="30000">FNPAE [30000]</option>
                                  </select>
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="OpenOrderItemsReport_orderTypeId_title"
                                                            for="OpenOrderItemsReport_orderTypeId"
                                                        >
                                                            Order Type Id
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="ui-widget">
                                  <select
                                      id="OpenOrderItemsReport_orderTypeId"
                                      name="orderTypeId"
                                      size="1"
                                  >
                                    <option value="SALES_ORDER">
                                      Sales Order
                                    </option>
                                    <option value="PURCHASE_ORDER">
                                      Purchase Order
                                    </option>
                                  </select>
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="OpenOrderItemsReport_orderStatusId_title"
                                                            for="OpenOrderItemsReport_orderStatusId"
                                                        >
                                                            Order Status Id
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="ui-widget">
                                  <select
                                      id="OpenOrderItemsReport_orderStatusId"
                                      name="orderStatusId"
                                      value={this.state.deliveryStatusItems}
                                      onChange={(e) => this.setState({deliveryStatusItems: e.target.value})}
                                      size="1"
                                  >
                                    <option value="">- Any -</option>
                                    <option value="allocated">
                                      Allocated
                                    </option>
                                    <option value="accepted">
                                      Accepted
                                    </option>
                                    <option value="OUT_FOR_DELIVERY">
                                      Out for delivery
                                    </option>
                                    <option value="ATTEMPTED">
                                      Attempted
                                    </option>
                                    <option value="DELIVERED">Delivered</option>
                                  </select>
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="OpenOrderItemsReport_fromOrderDate_title"
                                                            for="OpenOrderItemsReport_fromOrderDate"
                                                        >
                                                            From Date(Order Date&gt;=)
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="view-calendar">
                                  {" "}
                                    <input
                                        id="OpenOrderItemsReport_fromOrderDate_i18n"
                                        title="dd-MM-yyyy HH:mm:ss"
                                        maxlength="30"
                                        name="fromOrderDate_i18n"
                                        value={this.state.fromDateItems}
                                        onChange={(e) => this.setState({fromDateItems: e.target.value})}
                                        size="25"
                                        type="date"
                                    />{" "}
                                    {/*<input*/}
                                    {/*    id="OpenOrderItemsReport_fromOrderDate"*/}
                                    {/*    class="hasDatepicker"*/}
                                    {/*    title="dd-MM-yyyy HH:mm:ss"*/}
                                    {/*    maxlength="30"*/}
                                    {/*    name="fromOrderDate"*/}
                                    {/*    size="25"*/}
                                    {/*    type="text"*/}
                                    {/*/>*/}
                                    {/*<button*/}
                                    {/*    class="ui-datepicker-trigger"*/}
                                    {/*    type="button"*/}
                                    {/*></button>*/}
                                    {/*  {" "}*/}
                                    {/*  <input*/}
                                    {/*      name=""*/}
                                    {/*      type="hidden"*/}
                                    {/*      value="Timestamp"*/}
                                    {/*  />{" "}*/}
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="OpenOrderItemsReport_thruOrderDate_title"
                                                            for="OpenOrderItemsReport_thruOrderDate"
                                                        >
                                                            Through Date(Order Date&lt;)
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="view-calendar">
                                  {" "}
                                    <input
                                        id="OpenOrderItemsReport_thruOrderDate_i18n"
                                        title="dd-MM-yyyy HH:mm:ss"
                                        maxlength="30"
                                        name="thruOrderDate_i18n"
                                        value={this.state.throughDateItems}
                                        onChange={(e) => this.setState({throughDateItems: e.target.value})}

                                        size="25"
                                        type="date"
                                    />{" "}
                                    {/*  <input*/}
                                    {/*      id="OpenOrderItemsReport_thruOrderDate"*/}
                                    {/*      class="hasDatepicker"*/}
                                    {/*      title="dd-MM-yyyy HH:mm:ss"*/}
                                    {/*      maxlength="30"*/}
                                    {/*      name="thruOrderDate"*/}
                                    {/*      size="25"*/}
                                    {/*      type="text"*/}
                                    {/*  />*/}
                                    {/*<button*/}
                                    {/*    class="ui-datepicker-trigger"*/}
                                    {/*    type="button"*/}
                                    {/*></button>*/}
                                    {/*  {" "}*/}
                                    {/*  <input*/}
                                    {/*      name=""*/}
                                    {/*      type="hidden"*/}
                                    {/*      value="Timestamp"*/}
                                    {/*  />{" "}*/}
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label"></td>
                                                    <td colspan="4">
                                                        <input
                                                            class="smallSubmit"
                                                            name="submitButton"
                                                            type="submit"
                                                            value="Run"
                                                        />
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div id="PP_OrderReportPagePurchasesByOrg00001" class="noClass">
                                <div id="screenlet_3" class="screenlet">
                                    <div class="screenlet-title-bar">
                                        <ul>
                                            <li class="h3">Purchases by Organization Report</li>
                                        </ul>
                                    </div>
                                    <div id="screenlet_3_col" class="screenlet-body">
                                        <form
                                            id="PurchasesByOrganizationReport"
                                            class="basic-form"
                                            action="/ordermgr/control/PurchasesByOrganizationReport.pdf"
                                            method="post"
                                            name="PurchasesByOrganizationReport"
                                            target="_BLANK"
                                        >
                                            <table class="basic-table" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="PurchasesByOrganizationReport_fromPartyId_title"
                                                            for="PurchasesByOrganizationReport_fromPartyId"
                                                        >
                                                            From Party
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="ui-widget">
                                  <select
                                      id="PurchasesByOrganizationReport_fromPartyId"
                                      name="fromPartyId"
                                      size="1"
                                  >
                                    <option value="">- Any -</option>
                                    <option value="AcctBigSupplier">
                                      Acct Big Supplier [AcctBigSupplier]
                                    </option>
                                    <option value="AEV-N1010418">
                                      AE-Abu-Dhabi-FNP RAJESH [AEV-N1010418]
                                    </option>
                                    <option value="AEV-N1010418-BD">
                                      AE-Abu-Dhabi-FNP_RAJESH-BD
                                      [AEV-N1010418-BD]
                                    </option>
                                    <option value="AEV-N1010418_1HR">
                                      AE-Abu-Dhabi_1HR [AEV-N1010418_1HR]
                                    </option>
                                    <option value="AEV-N1010418_2DD">
                                      AE-Abu-Dhabi-FNP RAJESH 2DD
                                      [AEV-N1010418_2DD]
                                    </option>
                                    <option value="AEV-N1010418_ENDD">
                                      AE-Abu-Dhabi-FNP RAJESH Exp-Next Day
                                      [AEV-N1010418_ENDD]
                                    </option>
                                    <option value="AEV-N1010418_VP">
                                      AE-Abu-Dhabi-Valetine_Priority_VP
                                      [AEV-N1010418_VP]
                                    </option>
                                    <option value="AEV-N1120219-3P">
                                      AE_POArts [AEV-N1120219-3P]
                                    </option>
                                    <option value="AEV-N1261216">
                                      AE- FNP RAJESH . [AEV-N1261216]
                                    </option>
                                    <option value="AEV-N1261216-GT">
                                      AE-FNP-GUITAEIST-SERVICE_RAJESH-GT
                                      [AEV-N1261216-GT]
                                    </option>
                                    <option value="AEV-N1261216_1HR">
                                      AE-FNP RAJESH 1HR [AEV-N1261216_1HR]
                                    </option>
                                    <option value="AEV-N1261216_2DD">
                                      AE- FNP RAJESH 2DD [AEV-N1261216_2DD]
                                    </option>
                                    <option value="AEV-N1261216_CNDD">
                                      AE- FNP RAJESH CNDD [AEV-N1261216_CNDD]
                                    </option>
                                    <option value="AEV-N1261216_ED">
                                      AE- FNP RAJESH ED [AEV-N1261216_ED]
                                    </option>
                                    <option value="AEV-N1261216_EG2DD">
                                      AE-FNP_RAJESH-Exp-gifts_2DD
                                      [AEV-N1261216_EG2DD]
                                    </option>
                                    <option value="AEV-N1261216_ENDD">
                                      AE-FNP RAJESH Exp-Next Day ENDD
                                      [AEV-N1261216_ENDD]
                                    </option>
                                    <option value="AEV-N1261216_FD">
                                      AE- FNP RAJESH Free [AEV-N1261216_FD]
                                    </option>
                                    <option value="AEV-N1261216_FDRK">
                                      AE FNP Free Delivery for Rakhi .
                                      [AEV-N1261216_FDRK]
                                    </option>
                                    <option value="AEV-N1261216_VP">
                                      AE-FNP RAJESH_VALENTINE_VP ,
                                      [AEV-N1261216_VP]
                                    </option>
                                    <option value="AEV-N2120219-3P">
                                      AE_Thebakery [AEV-N2120219-3P]
                                    </option>
                                    <option value="AEV-N3120219-3P">
                                      AE_NJD [AEV-N3120219-3P]
                                    </option>
                                    <option value="AEV-N4120219-3P">
                                      AE_Gearfour [AEV-N4120219-3P]
                                    </option>
                                    <option value="AEV-N5120219-3P">
                                      AE_IQCreative . [AEV-N5120219-3P]
                                    </option>
                                    <option value="externaluser">
                                      External User [externaluser]
                                    </option>
                                    <option value="INT-1060619">
                                      INT-Kuwait [INT-1060619]
                                    </option>
                                    <option value="INT-11290419">
                                      INT-Saudi-Arabia [INT-11290419]
                                    </option>
                                    <option value="INT-1130719">
                                      INT-FNP-Singapore [INT-1130719]
                                    </option>
                                    <option value="INT-120419">
                                      INT-OMAN [INT-120419]
                                    </option>
                                    <option value="INT-1260819">
                                      INT-India [INT-1260819]
                                    </option>
                                    <option value="INT-21290419">
                                      INT-Egypt [INT-21290419]
                                    </option>
                                    <option value="INT-2260819">
                                      INT-UK [INT-2260819]
                                    </option>
                                    <option value="INT-31290419">
                                      INT-Qatar [INT-31290419]
                                    </option>
                                    <option value="INT-3260819">
                                      INT-China [INT-3260819]
                                    </option>
                                    <option value="INT-41290419">
                                      INT-Bahrain [INT-41290419]
                                    </option>
                                    <option value="INT-4260819">
                                      INT-Sri-lanka [INT-4260819]
                                    </option>
                                    <option value="INT-51290419">
                                      INT-Jordan [INT-51290419]
                                    </option>
                                    <option value="INT-5260819">
                                      INT-Pakistan [INT-5260819]
                                    </option>
                                    <option value="INT-568612C_3DD">
                                      INT_GBOS_INTernational_SAU_3DD
                                      [INT-568612C_3DD]
                                    </option>
                                    <option value="INT-61290419">
                                      INT-Philippines [INT-61290419]
                                    </option>
                                    <option value="INT-71290419">
                                      INT-Lebanon [INT-71290419]
                                    </option>
                                    <option value="TST_COURSUP">
                                      Test COURSUP [TST_COURSUP]
                                    </option>
                                    <option value="TST_SUP12">
                                      TST_SUP12_FN TST_SUP12_LN [TST_SUP12]
                                    </option>
                                    <option value="TST_SUP13">
                                      Test sup13 [TST_SUP13]
                                    </option>
                                    <option value="TST_SUP14">
                                      Test sup14 [TST_SUP14]
                                    </option>
                                    <option value="TST_SUP15">
                                      Test sup15 [TST_SUP15]
                                    </option>
                                  </select>
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="PurchasesByOrganizationReport_toPartyId_title"
                                                            for="PurchasesByOrganizationReport_toPartyId"
                                                        >
                                                            To Party Id
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="ui-widget">
                                  <select
                                      id="PurchasesByOrganizationReport_toPartyId"
                                      name="toPartyId"
                                      size="1"
                                  >
                                    <option value="ACCOUNTING">
                                      Accounting department [ACCOUNTING]
                                    </option>
                                    <option value="anz_div">
                                      Australia and New Zealand [anz_div]
                                    </option>
                                    <option value="asia_div">
                                      Asia [asia_div]
                                    </option>
                                    <option value="bu_div">
                                      Business Unit Division [bu_div]
                                    </option>
                                    <option value="Company">
                                      Your Company Name Here [Company]
                                    </option>
                                    <option value="cf_div">
                                      Corporate Functions Division [cf_div]
                                    </option>
                                    <option value="DEV">
                                      Development department [DEV]
                                    </option>
                                    <option value="DemoLeadOwnersGroup">
                                      A Group of Lead Owners
                                      [DemoLeadOwnersGroup]
                                    </option>
                                    <option value="DevTeam1">
                                      Development Team1 [DevTeam1]
                                    </option>
                                    <option value="DevTeam2">
                                      Development Team2 [DevTeam2]
                                    </option>
                                    <option value="DevTeam3">
                                      Development Team3 [DevTeam3]
                                    </option>
                                    <option value="DevTeam4">
                                      Development Team4 [DevTeam4]
                                    </option>
                                    <option value="Enterprise">
                                      Large Enterprise, Inc. [Enterprise]
                                    </option>
                                    <option value="fnp">
                                      Ferns N Petals Pvt. Ltd. [fnp]
                                    </option>
                                    <option value="fnpae">
                                      Ferns N Petals Pvt. Ltd. [fnpae]
                                    </option>
                                    <option value="gizmo_div">
                                      Gizmo Business Unit [gizmo_div]
                                    </option>
                                    <option value="HUMAN_RES">
                                      Human Resources [HUMAN_RES]
                                    </option>
                                    <option value="INFO_TECH">
                                      Information Technology [INFO_TECH]
                                    </option>
                                    <option value="india_div">
                                      India [india_div]
                                    </option>
                                    <option value="LEGAL">
                                      Legal Department [LEGAL]
                                    </option>
                                    <option value="LOGISTICS">
                                      Logistics [LOGISTICS]
                                    </option>
                                    <option value="MAINTENANCE">
                                      Maintenance Department [MAINTENANCE]
                                    </option>
                                    <option value="MARKETING">
                                      Marketing department [MARKETING]
                                    </option>
                                    <option value="md_div">
                                      Market Development Division [md_div]
                                    </option>
                                    <option value="na_div">
                                      North America [na_div]
                                    </option>
                                    <option value="PRODUCTION">
                                      Production Department [PRODUCTION]
                                    </option>
                                    <option value="PUBLIC_REL">
                                      Public Relations [PUBLIC_REL]
                                    </option>
                                    <option value="RECEIVING">
                                      Receiving Department [RECEIVING]
                                    </option>
                                    <option value="R_AND_D">
                                      Research and Development [R_AND_D]
                                    </option>
                                    <option value="SALES">
                                      Sales department [SALES]
                                    </option>
                                    <option value="SHIPPING">
                                      Shipping Department [SHIPPING]
                                    </option>
                                    <option value="sa_div">
                                      Central and South America [sa_div]
                                    </option>
                                    <option value="TESTING">
                                      Testing department [TESTING]
                                    </option>
                                    <option value="TestingTeam1">
                                      Testing Team1 [TestingTeam1]
                                    </option>
                                    <option value="TestingTeam2">
                                      Testing Team2 [TestingTeam2]
                                    </option>
                                    <option value="we_div">
                                      Western Europe [we_div]
                                    </option>
                                    <option value="widget_div">
                                      Widget Business Unit [widget_div]
                                    </option>
                                  </select>
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="PurchasesByOrganizationReport_orderStatusId_title"
                                                            for="PurchasesByOrganizationReport_orderStatusId"
                                                        >
                                                            Order Status Id
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="ui-widget">
                                  <select
                                      id="PurchasesByOrganizationReport_orderStatusId"
                                      name="orderStatusId"
                                      size="1"
                                  >
                                    <option value="">- Any -</option>
                                    <option value="ORDER_APPROVED">
                                      Confirmed
                                    </option>
                                    <option value="ORDER_CANCELLED">
                                      Cancelled
                                    </option>
                                    <option value="ORDER_COMPLETED">
                                      Completed
                                    </option>
                                    <option value="ORDER_CREATED">
                                      Waiting
                                    </option>
                                    <option value="ORDER_HOLD">Held</option>
                                    <option value="ORDER_PROCESSING">
                                      Processing
                                    </option>
                                    <option value="ORDER_REJECTED">
                                      Rejected
                                    </option>
                                    <option value="ORDER_SENT">Sent</option>
                                  </select>
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="PurchasesByOrganizationReport_fromOrderDate_title"
                                                            for="PurchasesByOrganizationReport_fromOrderDate"
                                                        >
                                                            From Date
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="view-calendar">
                                  {" "}
                                    <input
                                        id="PurchasesByOrganizationReport_fromOrderDate_i18n"
                                        title="dd-MM-yyyy HH:mm:ss"
                                        maxlength="30"
                                        name="fromOrderDate_i18n"
                                        size="25"
                                        type="text"
                                    />{" "}
                                    <input
                                        id="PurchasesByOrganizationReport_fromOrderDate"
                                        class="hasDatepicker"
                                        title="dd-MM-yyyy HH:mm:ss"
                                        maxlength="30"
                                        name="fromOrderDate"
                                        size="25"
                                        type="text"
                                    />
                                  <button
                                      class="ui-datepicker-trigger"
                                      type="button"
                                  ></button>
                                    {" "}
                                    <input
                                        name=""
                                        type="hidden"
                                        value="Timestamp"
                                    />{" "}
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="PurchasesByOrganizationReport_thruOrderDate_title"
                                                            for="PurchasesByOrganizationReport_thruOrderDate"
                                                        >
                                                            Through Date
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="view-calendar">
                                  {" "}
                                    <input
                                        id="PurchasesByOrganizationReport_thruOrderDate_i18n"
                                        title="dd-MM-yyyy HH:mm:ss"
                                        maxlength="30"
                                        name="thruOrderDate_i18n"
                                        size="25"
                                        type="text"
                                    />{" "}
                                    <input
                                        id="PurchasesByOrganizationReport_thruOrderDate"
                                        class="hasDatepicker"
                                        title="dd-MM-yyyy HH:mm:ss"
                                        maxlength="30"
                                        name="thruOrderDate"
                                        size="25"
                                        type="text"
                                    />
                                  <button
                                      class="ui-datepicker-trigger"
                                      type="button"
                                  ></button>
                                    {" "}
                                    <input
                                        name=""
                                        type="hidden"
                                        value="Timestamp"
                                    />{" "}
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label"></td>
                                                    <td colspan="4">
                                                        <input
                                                            class="smallSubmit"
                                                            name="submitButton"
                                                            type="submit"
                                                            value="Run"
                                                        />
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="PP_OrderReportPageOrderByReferrer00001"
                                class="noClass"
                            >
                                <div id="screenlet_4" class="screenlet">
                                    <div class="screenlet-title-bar">
                                        <ul>
                                            <li class="h3">Order By Referrer</li>
                                        </ul>
                                    </div>
                                    <div id="screenlet_4_col" class="screenlet-body">
                                        <form
                                            id="OrderByReferrer"
                                            class="basic-form"
                                            action="/ordermgr/control/OrderByReferrer.pdf"
                                            method="post"
                                            name="OrderByReferrer"
                                            target="_BLANK"
                                        >
                                            <input
                                                id="OrderByReferrer_referrerUrl"
                                                name="referrerUrl"
                                                type="hidden"
                                            />
                                            <table cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="label"></td>
                                                    <td>All Referrer</td>
                                                </tr>
                                                <tr>
                                                    <td class="label"></td>
                                                    <td colspan="4">
                                                        <input
                                                            name="submit"
                                                            type="submit"
                                                            value="Run"
                                                        />
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div id="PP_OrderReportPageOrderByChannel00001" class="noClass">
                                <div id="screenlet_5" class="screenlet">
                                    <div class="screenlet-title-bar">
                                        <ul>
                                            <li class="h3">Sale Orders By Channel</li>
                                        </ul>
                                    </div>
                                    <div id="screenlet_5_col" class="screenlet-body">
                                        <form
                                            id="SaleOrdersByChannel"
                                            class="basic-form"
                                            action="/ordermgr/control/OrdersByChannel.pdf"
                                            method="post"
                                            name="SaleOrdersByChannel"
                                            target="_BLANK"
                                        >
                                            <table cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="SaleOrdersByChannel_SalesChannel_title"
                                                            for="SaleOrdersByChannel_SalesChannel"
                                                        >
                                                            Sales Channel
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="ui-widget">
                                  <select
                                      id="SaleOrdersByChannel_SalesChannel"
                                      name="SalesChannel"
                                      size="1"
                                  >
                                    <option value="">- Any -</option>
                                    <option value="Affiliate Channel">
                                      Affiliate Channel
                                    </option>
                                    <option value="Call Center-abandoned cart">
                                      Call Center-abandoned cart
                                    </option>
                                    <option value="Call Center-BMF Orders">
                                      Call Center-BMF Orders
                                    </option>
                                    <option value="Call Center-cbl">
                                      Call Center-cbl
                                    </option>
                                    <option value="Call Center-Chat">
                                      Call Center-Chat
                                    </option>
                                    <option value="Call Center-FNP Staff">
                                      Call Center-FNP Staff
                                    </option>
                                    <option value="Call Center-odb">
                                      Call Center-odb
                                    </option>
                                    <option value="Call Center-ordercall">
                                      Call Center-ordercall
                                    </option>
                                    <option value="Call Center-others">
                                      Call Center-others
                                    </option>
                                    <option value="Call Center-pnc">
                                      Call Center-pnc
                                    </option>
                                    <option value="Call Center-PNI">
                                      Call Center-PNI
                                    </option>
                                    <option value="eBay Channel">
                                      eBay Channel
                                    </option>
                                    <option value="E-Mail Channel">
                                      E-Mail Channel
                                    </option>
                                    <option value="Fax Channel">
                                      Fax Channel
                                    </option>
                                    <option value="Google Checkout Channel">
                                      Google Checkout Channel
                                    </option>
                                    <option value="Mobile App Android">
                                      Mobile App Android
                                    </option>
                                    <option value="Mobile App Blackberry">
                                      Mobile App Blackberry
                                    </option>
                                    <option value="Mobile App Iphone">
                                      Mobile App Iphone
                                    </option>
                                    <option value="Mobile App Windows">
                                      Mobile App Windows
                                    </option>
                                    <option value="Online-Desktop-Affiliate Network">
                                      Online-Desktop-Affiliate Network
                                    </option>
                                    <option value="Online-Desktop-Alliance">
                                      Online-Desktop-Alliance
                                    </option>
                                    <option value="Online-Desktop-direct">
                                      Online-Desktop-direct
                                    </option>
                                    <option value="Online-Desktop-email">
                                      Online-Desktop-email
                                    </option>
                                    <option value="Online-Desktop-organic">
                                      Online-Desktop-organic
                                    </option>
                                    <option value="Online-Desktop-others">
                                      Online-Desktop-others
                                    </option>
                                    <option value="Online-Desktop-ppc">
                                      Online-Desktop-ppc
                                    </option>
                                    <option value="Online-Mobile-affiliate network">
                                      Online-Mobile-affiliate network
                                    </option>
                                    <option value="Online-Mobile-alliance">
                                      Online-Mobile-alliance
                                    </option>
                                    <option value="Online-Mobile-direct">
                                      Online-Mobile-direct
                                    </option>
                                    <option value="Online-Mobile-email">
                                      Online-Mobile-email
                                    </option>
                                    <option value="Online-Mobile-organic">
                                      Online-Mobile-organic
                                    </option>
                                    <option value="Online-Mobile-others">
                                      Online-Mobile-others
                                    </option>
                                    <option value="Online-Mobile-ppc">
                                      Online-Mobile-ppc
                                    </option>
                                    <option value="Online-Tablet-affiliate network">
                                      Online-Tablet-affiliate network
                                    </option>
                                    <option value="Online-Tablet-alliance">
                                      Online-Tablet-alliance
                                    </option>
                                    <option value="Online-Tablet-direct">
                                      Online-Tablet-direct
                                    </option>
                                    <option value="Online-Tablet-email">
                                      Online-Tablet-email
                                    </option>
                                    <option value="Online-Tablet-organic">
                                      Online-Tablet-organic
                                    </option>
                                    <option value="Online-Tablet-others">
                                      Online-Tablet-others
                                    </option>
                                    <option value="Online-Tablet-ppc">
                                      Online-Tablet-ppc
                                    </option>
                                    <option value="Phone Channel">
                                      Phone Channel
                                    </option>
                                    <option value="POS Channel">
                                      POS Channel
                                    </option>
                                    <option value="Outsorucing vendor for manufacturing">
                                      Outsorucing vendor for manufacturing
                                    </option>
                                    <option value="Outsorucing vendor for update manufacturing">
                                      Outsorucing vendor for update
                                      manufacturing
                                    </option>
                                    <option value="SHOPHO DIRECT VENDOR">
                                      SHOPHO DIRECT VENDOR
                                    </option>
                                    <option value="SHOPHO FNP-COCO">
                                      SHOPHO FNP-COCO
                                    </option>
                                    <option value="SHOPHO FNP-Franchisee">
                                      SHOPHO FNP-Franchisee
                                    </option>
                                    <option value="SHOPHO INTERNATIONAL">
                                      SHOPHO INTERNATIONAL
                                    </option>
                                    <option value="SHOPHO NON FNP">
                                      SHOPHO NON FNP
                                    </option>
                                    <option value="Snail Mail Channel">
                                      Snail Mail Channel
                                    </option>
                                    <option value="Unknown Channel">
                                      Unknown Channel
                                    </option>
                                    <option value="WEBSITES">WEBSITES</option>
                                    <option value="Websites-indiamart">
                                      Websites-indiamart
                                    </option>
                                    <option value="Websites-indiatimes">
                                      Websites-indiatimes
                                    </option>
                                    <option value="Websites-others">
                                      Websites-others
                                    </option>
                                    <option value="Websites-tajonline">
                                      Websites-tajonline
                                    </option>
                                    <option value="Web Channel">
                                      Web Channel
                                    </option>
                                  </select>
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label"></td>
                                                    <td colspan="4">
                                                        <input
                                                            name="submit"
                                                            type="submit"
                                                            value="Run"
                                                        />
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="PP_OrderReportPageOrderDiscountCode00001"
                                class="noClass"
                            >
                                <div id="screenlet_6" class="screenlet">
                                    <div class="screenlet-title-bar">
                                        <ul>
                                            <li class="h3">Sales Order Discount Code</li>
                                        </ul>
                                    </div>
                                    <div id="screenlet_6_col" class="screenlet-body">
                                        <form
                                            id="SaleOrderDiscountCode"
                                            class="basic-form"
                                            action="/ordermgr/control/OrderDiscountCode.pdf"
                                            method="post"
                                            name="SaleOrderDiscountCode"
                                            target="_BLANK"
                                        >
                                            <input
                                                id="SaleOrderDiscountCode_discountCodeUrl"
                                                name="discountCodeUrl"
                                                type="hidden"
                                            />
                                            <table cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="label"></td>
                                                    <td>All order items with discount code</td>
                                                </tr>
                                                <tr>
                                                    <td class="label"></td>
                                                    <td colspan="4">
                                                        <input
                                                            name="submit"
                                                            type="submit"
                                                            value="Run"
                                                        />
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td
                            id="portalColumn_00002"
                            class="portal-column connectedSortable"
                            style={{verticalAlign: "top", width: "auto"}}
                        >
                            <div
                                id="PP_OrderReportPagePurchasesByProduct00001"
                                class="noClass"
                            >
                                <div id="screenlet_7" class="screenlet">
                                    <div class="screenlet-title-bar">
                                        <ul>
                                            <li class="h3">Purchases by Product Report</li>
                                        </ul>
                                    </div>
                                    <div id="screenlet_7_col" class="screenlet-body">
                                        <form
                                            id="OrderPurchaseProductOptions"
                                            class="basic-form"
                                            action="/ordermgr/control/OrderPurchaseReportProduct.pdf"
                                            method="post"
                                            name="OrderPurchaseProductOptions"
                                            target="_BLANK"
                                        >
                                            <table class="basic-table" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="OrderPurchaseReportOptions_productStoreId_title"
                                                            for="OrderPurchaseReportOptions_productStoreId"
                                                        >
                                                            Product Store Id
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="ui-widget">
                                  <select
                                      id="OrderPurchaseReportOptions_productStoreId"
                                      name="productStoreId"
                                      size="1"
                                  >
                                    <option value="">- Any -</option>
                                    <option value="30000">FNPAE [30000]</option>
                                  </select>
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="OrderPurchaseReportOptions_orderTypeId_title"
                                                            for="OrderPurchaseReportOptions_orderTypeId"
                                                        >
                                                            Order Type Id
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="ui-widget">
                                  <select
                                      id="OrderPurchaseReportOptions_orderTypeId"
                                      name="orderTypeId"
                                      size="1"
                                  >
                                    <option value="">- Any -</option>
                                    <option value="PURCHASE_ORDER">
                                      Purchase Order
                                    </option>
                                    <option value="SALES_ORDER">
                                      Sales Order
                                    </option>
                                  </select>
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="OrderPurchaseReportOptions_originFacilityId_title"
                                                            for="OrderPurchaseReportOptions_originFacilityId"
                                                        >
                                                            Origin Facility Id
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="ui-widget">
                                  <select
                                      id="OrderPurchaseReportOptions_originFacilityId"
                                      name="originFacilityId"
                                      size="1"
                                  >
                                    <option value="">- Any -</option>
                                    <option value="10000">
                                      FNP-WAREHOUSE [10000]
                                    </option>
                                    <option value="30000">
                                      FNP-WAREHOUSE [30000]
                                    </option>
                                    <option value="MyRetailStore">
                                      My Retail Store [MyRetailStore]
                                    </option>
                                    <option value="WebStoreWarehouse">
                                      Web Store Warehouse [WebStoreWarehouse]
                                    </option>
                                  </select>
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="OrderPurchaseReportOptions_terminalId_title"
                                                            for="OrderPurchaseReportOptions_terminalId"
                                                        >
                                                            Terminal Id
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <input
                                                            id="OrderPurchaseReportOptions_terminalId"
                                                            maxlength="20"
                                                            name="terminalId"
                                                            size="10"
                                                            type="text"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="OrderPurchaseReportOptions_statusId_title"
                                                            for="OrderPurchaseReportOptions_statusId"
                                                        >
                                                            Status ID
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="ui-widget">
                                  <select
                                      id="OrderPurchaseReportOptions_statusId"
                                      name="statusId"
                                      size="1"
                                  >
                                    <option value="">- Any -</option>
                                    <option value="ORDER_CREATED">
                                      Waiting
                                    </option>
                                    <option value="ORDER_SENT">Sent</option>
                                    <option value="ORDER_PROCESSING">
                                      Processing
                                    </option>
                                    <option value="ORDER_APPROVED">
                                      Confirmed
                                    </option>
                                    <option value="ORDER_HOLD">Held</option>
                                    <option
                                        selected="selected"
                                        value="ORDER_COMPLETED"
                                    >
                                      Completed
                                    </option>
                                    <option value="ORDER_REJECTED">
                                      Rejected
                                    </option>
                                    <option value="ORDER_CANCELLED">
                                      Cancelled
                                    </option>
                                  </select>
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="OrderPurchaseProductOptions_fromOrderDate_title"
                                                            for="OrderPurchaseProductOptions_fromOrderDate"
                                                        >
                                                            From Date(Order Date&gt;=)
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="view-calendar">
                                  {" "}
                                    <input
                                        id="OrderPurchaseProductOptions_fromOrderDate_i18n"
                                        title="dd-MM-yyyy HH:mm:ss"
                                        maxlength="30"
                                        name="fromOrderDate_i18n"
                                        size="25"
                                        type="text"
                                    />{" "}
                                    <input
                                        id="OrderPurchaseProductOptions_fromOrderDate"
                                        class="hasDatepicker"
                                        title="dd-MM-yyyy HH:mm:ss"
                                        maxlength="30"
                                        name="fromOrderDate"
                                        size="25"
                                        type="text"
                                    />
                                  <button
                                      class="ui-datepicker-trigger"
                                      type="button"
                                  ></button>
                                    {" "}
                                    <input
                                        name=""
                                        type="hidden"
                                        value="Timestamp"
                                    />{" "}
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="OrderPurchaseProductOptions_thruOrderDate_title"
                                                            for="OrderPurchaseProductOptions_thruOrderDate"
                                                        >
                                                            Through Date(Order Date&lt;)
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="view-calendar">
                                  {" "}
                                    <input
                                        id="OrderPurchaseProductOptions_thruOrderDate_i18n"
                                        title="dd-MM-yyyy HH:mm:ss"
                                        maxlength="30"
                                        name="thruOrderDate_i18n"
                                        size="25"
                                        type="text"
                                    />{" "}
                                    <input
                                        id="OrderPurchaseProductOptions_thruOrderDate"
                                        class="hasDatepicker"
                                        title="dd-MM-yyyy HH:mm:ss"
                                        maxlength="30"
                                        name="thruOrderDate"
                                        size="25"
                                        type="text"
                                    />
                                  <button
                                      class="ui-datepicker-trigger"
                                      type="button"
                                  ></button>
                                    {" "}
                                    <input
                                        name=""
                                        type="hidden"
                                        value="Timestamp"
                                    />{" "}
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label"></td>
                                                    <td colspan="4">
                                                        <input
                                                            class="smallSubmit"
                                                            name="submitButton"
                                                            type="submit"
                                                            value="Run"
                                                        />
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="PP_OrderReportPagePurchByPaymMeth00001"
                                class="noClass"
                            >
                                <div id="screenlet_8" class="screenlet">
                                    <div class="screenlet-title-bar">
                                        <ul>
                                            <li class="h3">Purchases by Payment Method Report</li>
                                        </ul>
                                    </div>
                                    <div id="screenlet_8_col" class="screenlet-body">
                                        <form
                                            id="OrderPurchasePaymentOptions"
                                            class="basic-form"
                                            action="/ordermgr/control/OrderPurchaseReportPayment.pdf"
                                            method="post"
                                            name="OrderPurchasePaymentOptions"
                                            target="_BLANK"
                                        >
                                            <table class="basic-table" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="OrderPurchaseReportOptions_productStoreId_title"
                                                            for="OrderPurchaseReportOptions_productStoreId"
                                                        >
                                                            Product Store Id
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="ui-widget">
                                  <select
                                      id="OrderPurchaseReportOptions_productStoreId"
                                      name="productStoreId"
                                      size="1"
                                  >
                                    <option value="">- Any -</option>
                                    <option value="30000">FNPAE [30000]</option>
                                  </select>
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="OrderPurchaseReportOptions_orderTypeId_title"
                                                            for="OrderPurchaseReportOptions_orderTypeId"
                                                        >
                                                            Order Type Id
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="ui-widget">
                                  <select
                                      id="OrderPurchaseReportOptions_orderTypeId"
                                      name="orderTypeId"
                                      size="1"
                                  >
                                    <option value="">- Any -</option>
                                    <option value="PURCHASE_ORDER">
                                      Purchase Order
                                    </option>
                                    <option value="SALES_ORDER">
                                      Sales Order
                                    </option>
                                  </select>
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="OrderPurchaseReportOptions_originFacilityId_title"
                                                            for="OrderPurchaseReportOptions_originFacilityId"
                                                        >
                                                            Origin Facility Id
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="ui-widget">
                                  <select
                                      id="OrderPurchaseReportOptions_originFacilityId"
                                      name="originFacilityId"
                                      size="1"
                                  >
                                    <option value="">- Any -</option>
                                    <option value="10000">
                                      FNP-WAREHOUSE [10000]
                                    </option>
                                    <option value="30000">
                                      FNP-WAREHOUSE [30000]
                                    </option>
                                    <option value="MyRetailStore">
                                      My Retail Store [MyRetailStore]
                                    </option>
                                    <option value="WebStoreWarehouse">
                                      Web Store Warehouse [WebStoreWarehouse]
                                    </option>
                                  </select>
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="OrderPurchaseReportOptions_terminalId_title"
                                                            for="OrderPurchaseReportOptions_terminalId"
                                                        >
                                                            Terminal Id
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <input
                                                            id="OrderPurchaseReportOptions_terminalId"
                                                            maxlength="20"
                                                            name="terminalId"
                                                            size="10"
                                                            type="text"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="OrderPurchaseReportOptions_statusId_title"
                                                            for="OrderPurchaseReportOptions_statusId"
                                                        >
                                                            Status ID
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="ui-widget">
                                  <select
                                      id="OrderPurchaseReportOptions_statusId"
                                      name="statusId"
                                      size="1"
                                  >
                                    <option value="">- Any -</option>
                                    <option value="ORDER_CREATED">
                                      Waiting
                                    </option>
                                    <option value="ORDER_SENT">Sent</option>
                                    <option value="ORDER_PROCESSING">
                                      Processing
                                    </option>
                                    <option value="ORDER_APPROVED">
                                      Confirmed
                                    </option>
                                    <option value="ORDER_HOLD">Held</option>
                                    <option
                                        selected="selected"
                                        value="ORDER_COMPLETED"
                                    >
                                      Completed
                                    </option>
                                    <option value="ORDER_REJECTED">
                                      Rejected
                                    </option>
                                    <option value="ORDER_CANCELLED">
                                      Cancelled
                                    </option>
                                  </select>
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="OrderPurchasePaymentOptions_fromOrderDate_title"
                                                            for="OrderPurchasePaymentOptions_fromOrderDate"
                                                        >
                                                            From Date(Order Date&gt;=)
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="view-calendar">
                                  {" "}
                                    <input
                                        id="OrderPurchasePaymentOptions_fromOrderDate_i18n"
                                        title="dd-MM-yyyy HH:mm:ss"
                                        maxlength="30"
                                        name="fromOrderDate_i18n"
                                        size="25"
                                        type="text"
                                    />{" "}
                                    <input
                                        id="OrderPurchasePaymentOptions_fromOrderDate"
                                        class="hasDatepicker"
                                        title="dd-MM-yyyy HH:mm:ss"
                                        maxlength="30"
                                        name="fromOrderDate"
                                        size="25"
                                        type="text"
                                    />
                                  <button
                                      class="ui-datepicker-trigger"
                                      type="button"
                                  ></button>
                                    {" "}
                                    <input
                                        name=""
                                        type="hidden"
                                        value="Timestamp"
                                    />{" "}
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="OrderPurchasePaymentOptions_thruOrderDate_title"
                                                            for="OrderPurchasePaymentOptions_thruOrderDate"
                                                        >
                                                            Through Date(Order Date&lt;)
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="view-calendar">
                                  {" "}
                                    <input
                                        id="OrderPurchasePaymentOptions_thruOrderDate_i18n"
                                        title="dd-MM-yyyy HH:mm:ss"
                                        maxlength="30"
                                        name="thruOrderDate_i18n"
                                        size="25"
                                        type="text"
                                    />{" "}
                                    <input
                                        id="OrderPurchasePaymentOptions_thruOrderDate"
                                        class="hasDatepicker"
                                        title="dd-MM-yyyy HH:mm:ss"
                                        maxlength="30"
                                        name="thruOrderDate"
                                        size="25"
                                        type="text"
                                    />
                                  <button
                                      class="ui-datepicker-trigger"
                                      type="button"
                                  ></button>
                                    {" "}
                                    <input
                                        name=""
                                        type="hidden"
                                        value="Timestamp"
                                    />{" "}
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label"></td>
                                                    <td colspan="4">
                                                        <input
                                                            class="smallSubmit"
                                                            name="submitButton"
                                                            type="submit"
                                                            value="Run"
                                                        />
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="PP_OrderReportPageLast3MSalesReport00001"
                                class="noClass"
                            >
                                <div id="screenlet_9" class="screenlet">
                                    <div class="screenlet-title-bar">
                                        <ul>
                                            <li class="h3">Last 3 Months Sales Report</li>
                                        </ul>
                                    </div>
                                    <div id="screenlet_9_col" class="screenlet-body">
                                        <form
                                            id="Last3MonthsSalesReport"
                                            class="basic-form"
                                            action="/ordermgr/control/Last3MonthsSalesReport.pdf"
                                            method="post"
                                            name="Last3MonthsSalesReport"
                                            target="_BLANK"
                                        >
                                            <table cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="label"></td>
                                                    <td colspan="4">
                                                        <input
                                                            name="submit"
                                                            type="submit"
                                                            value="Run"
                                                        />
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="PP_OrderReportPageCouponSalesReport00001"
                                class="noClass"
                            >
                                <div id="screenlet_10" class="screenlet">
                                    <div class="screenlet-title-bar">
                                        <ul>
                                            <li class="h3">Coupon Sales Report</li>
                                        </ul>
                                    </div>
                                    <div id="screenlet_10_col" class="screenlet-body">
                                        <form
                                            id="CouponSalesReport"
                                            class="basic-form"
                                            action="/ordermgr/control/CouponSalesReport.pdf"
                                            method="post"
                                            name="CouponSalesReport"
                                            target="_BLANK"
                                        >
                                            <table cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="label"></td>
                                                    <td colspan="4">
                                                        <input
                                                            name="submit"
                                                            type="submit"
                                                            value="Run"
                                                        />
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div id="PP_OrderReportPageSalesReport00001" class="noClass">
                                <div id="screenlet_11" class="screenlet">
                                    <div class="screenlet-title-bar">
                                        <ul>
                                            <li class="h3">Sales Report</li>
                                        </ul>
                                    </div>
                                    <div id="screenlet_11_col" class="screenlet-body">
                                        <form
                                            id="SalesReport"
                                            class="basic-form"
                                            action="/ordermgr/control/checkReportBy"
                                            method="post"
                                            name="SalesReport"
                                            target="_BLANK"
                                        >
                                            <table class="basic-table" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="SalesReport_fromDate_title"
                                                            for="SalesReport_fromDate"
                                                        >
                                                            From Date
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="view-calendar">
                                  {" "}
                                    <input
                                        id="SalesReport_fromDate_i18n"
                                        title="dd-MM-yyyy HH:mm:ss"
                                        maxlength="10"
                                        name="fromDate_i18n"
                                        size="10"
                                        type="text"
                                    />{" "}
                                    <input
                                        id="SalesReport_fromDate"
                                        class="hasDatepicker"
                                        title="dd-MM-yyyy HH:mm:ss"
                                        maxlength="10"
                                        name="fromDate"
                                        size="10"
                                        type="text"
                                    />
                                  <button
                                      class="ui-datepicker-trigger"
                                      type="button"
                                  ></button>
                                    {" "}
                                    <input
                                        name=""
                                        type="hidden"
                                        value="Timestamp"
                                    />{" "}
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="SalesReport_reportBy_title"
                                                            for="SalesReport_reportBy"
                                                        >
                                                            Report By
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="ui-widget">
                                  <select
                                      id="SalesReport_reportBy"
                                      name="reportBy"
                                      size="1"
                                  >
                                    <option value="day">Days</option>
                                    <option value="week">Weeks</option>
                                    <option value="month">Months</option>
                                  </select>
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="SalesReport_exportType_title"
                                                            for="SalesReport_exportType"
                                                        >
                                                            Format Type
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="ui-widget">
                                  <select
                                      id="SalesReport_exportType"
                                      name="exportType"
                                      size="1"
                                  >
                                    <option value="pdf">PDF</option>
                                    <option value="excel">Excel</option>
                                    <option value="html">HTML</option>
                                  </select>
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label"></td>
                                                    <td colspan="4">
                                                        <input
                                                            class="smallSubmit"
                                                            name="submitButton"
                                                            type="submit"
                                                            value="Run"
                                                        />
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div id="PP_OrderReportPageNBO_Report00001" class="noClass">
                                <div id="screenlet_12" class="screenlet">
                                    <div class="screenlet-title-bar">
                                        <ul>
                                            <li class="h3">Net Before Overhead Report</li>
                                        </ul>
                                    </div>
                                    <div id="screenlet_12_col" class="screenlet-body">
                                        <form
                                            id="NetBeforeOverheadReport"
                                            class="basic-form"
                                            action="/ordermgr/control/NetBeforeOverheadReport.pdf"
                                            method="post"
                                            name="NetBeforeOverheadReport"
                                            target="_BLANK"
                                        >
                                            <table class="basic-table" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="NetBeforeOverheadReport_productStoreId_title"
                                                            for="NetBeforeOverheadReport_productStoreId"
                                                        >
                                                            Product Store Id
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="ui-widget">
                                  <select
                                      id="NetBeforeOverheadReport_productStoreId"
                                      name="productStoreId"
                                      size="1"
                                  >
                                    <option value="">- Any -</option>
                                    <option value="30000">FNPAE [30000]</option>
                                  </select>
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label"></td>
                                                    <td colspan="4">
                                                        <input
                                                            class="smallSubmit"
                                                            name="submitButton"
                                                            type="submit"
                                                            value="Run"
                                                        />
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div id="PP_OrderReportPagePDR_Report00001" class="noClass">
                                <div id="screenlet_13" class="screenlet">
                                    <div class="screenlet-title-bar">
                                        <ul>
                                            <li class="h3">Product Demand Report</li>
                                        </ul>
                                    </div>
                                    <div id="screenlet_13_col" class="screenlet-body">
                                        <form
                                            id="ProductDemandReport"
                                            class="basic-form"
                                            action="/ordermgr/control/ProductDemandReport.pdf"
                                            method="post"
                                            name="ProductDemandReport"
                                            target="_BLANK"
                                        >
                                            <table class="basic-table" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="label">
                                                        <label
                                                            id="ProductDemandReport_productStoreId_title"
                                                            for="ProductDemandReport_productStoreId"
                                                        >
                                                            Product Store Id
                                                        </label>
                                                    </td>
                                                    <td>
                                <span class="ui-widget">
                                  <select
                                      id="ProductDemandReport_productStoreId"
                                      name="productStoreId"
                                      size="1"
                                  >
                                    <option value="">- Any -</option>
                                    <option value="30000">FNPAE [30000]</option>
                                  </select>
                                </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="label"></td>
                                                    <td colspan="4">
                                                        <input
                                                            class="smallSubmit"
                                                            name="submitButton"
                                                            type="submit"
                                                            value="Run"
                                                        />
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps =(dispatch,router) =>({
    saveData:(data)=>dispatch({type:'ITEMS_DATA',payload:data}),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderPurchaseReportOptions));

