import React, { Component } from 'react';
import Main from './containers/Main';

class Geo extends Component {
    render() {
        const { match } = this.props;
        const { params } = match;
        return <div>{this.container(params.sub)}</div>;
    }

    container = (container) => {
        switch (container) {
            case "main":
                return <Main />;
            default:
                return (
                    <div>
                        <h2>Not Found ( Container Mismatch )</h2>
                    </div>
                );
        }
    }
}

export default Geo;