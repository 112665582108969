import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  createFeature,
  createFeatureCategory,
  getAllFeatureCategory,
  getAllFeatures,
  getAllFeatureTypes,
  getFeatureCategroy,
  getOneFeature,
  searchFeatureCategory,
  updateFeature,
  updateFeatureCategory,
} from "../../../../store/actions/catalogActions";
import queryString from "query-string";

class CreateNewFeature extends Component {
  render() {
    let {
      id,
      type,
      category,
      description,
      unitOfMeasurId,
      quantity,
      defaultAmount,
      defaultSequenceNumber,
      Abbreviation,
      idCode,
    } = this.state;
    console.log("these are types", this.props.all_feature_types);
    console.log("these are catagories", this.props.all_feature_categories);
    return this.props.new ? (
      <div>
        <div id="content-main-section" className="leftonly">
          <div id="centerdiv" className="no-clear">
            <div>
              <ul style={{ display: "flex", listStyle: "none" }}>
                <a
                  href="/catalog/control/EditFeatureTypes"
                  style={{ textDecoration: "none" }}
                >
                  <li className="featureLi">Feature Type</li>
                </a>
                <a
                  href="/catalog/control/EditFeatureCategories"
                  style={{ textDecoration: "none" }}
                >
                  <li className="featureLi">Feature Category</li>
                </a>
                <a
                  href="/catalog/control/EditFeatureGroups"
                  style={{ textDecoration: "none" }}
                >
                  <li className="featureLi">Feature Group</li>
                </a>
                <a
                  href="/catalog/control/EditFeatureInterActions"
                  style={{ textDecoration: "none" }}
                >
                  <li className="featureLi">Feature interAction</li>
                </a>
              </ul>
            </div>

            <div id="screenlet_1" className="screenlet">
              <div className="screenlet-title-bar">
                <ul>
                  <li className="h3">Edit Feature :</li>
                </ul>
              </div>
              <div id="screenlet_1_col" className="screenlet-body">
                <form
                  onSubmit={this.handleSubmit}
                  id="EditProductFeature"
                  className="basic-form"
                  action="/catalog/control/createProductFeature"
                  method="post"
                  name="EditProductFeature"
                  noValidate="novalidate"
                >
                  <table className="basic-table" cellSpacing="0">
                    <tbody>
                      <tr>
                        <td className="label">
                          <label
                            id="EditProductFeature_productFeatureId_title"
                            htmlFor="EditProductFeature_productFeatureId"
                          >
                            Feature Id
                          </label>
                        </td>
                        <td>
                          <input
                            id="EditProductFeature_productFeatureId"
                            maxLength="20"
                            name="id"
                            value={id}
                            size="20"
                            onChange={this.handleChange}
                            type="text"
                          />{" "}
                          <span className="tooltip">
                            Could Not Find Product Config Item with ID []
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="label">
                          <label
                            id="EditProductFeature_productFeatureTypeId_title"
                            htmlFor="EditProductFeature_productFeatureTypeId"
                          >
                            Feature Type
                          </label>
                        </td>
                        <td>
                          <span className="ui-widget">
                            <select
                              id="EditProductFeature_productFeatureTypeId"
                              value={type}
                              onChange={this.handleChange}
                              name="type"
                              size="1"
                            >
                              <option value="">--</option>
                              {this.props.all_feature_types?.map((one) => (
                                <option value={one._id}>{one.id}</option>
                              ))}
                            </select>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="label">
                          <label
                            id="EditProductFeature_productFeatureCategoryId_title"
                            htmlFor="EditProductFeature_productFeatureCategoryId"
                          >
                            Feature Category
                          </label>
                        </td>
                        <td>
                          <span className="ui-widget">
                            <select
                              onChange={this.handleChange}
                              id="EditProductFeature_productFeatureCategoryId"
                              className="categroy"
                              name="category"
                              value={category}
                              size="1"
                            >
                              <option selected="selected" value="">
                                --
                              </option>
                              {this.props.all_feature_categories?.map((one) => (
                                <option value={one._id}>{one.id}</option>
                              ))}
                            </select>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="label">
                          <label
                            id="EditProductFeature_description_title"
                            htmlFor="EditProductFeature_description"
                          >
                            Description
                          </label>
                        </td>
                        <td>
                          <input
                            id="EditProductFeature_description"
                            maxLength="250"
                            name="description"
                            value={description}
                            onChange={this.handleChange}
                            size="60"
                            type="text"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="label">
                          <label
                            id="EditProductFeature_uomId_title"
                            htmlFor="EditProductFeature_uomId"
                          >
                            Unit of Measure ID
                          </label>
                        </td>
                        <td>
                          <input
                            onChange={this.handleChange}
                            id="EditProductFeature_uomId"
                            maxLength="20"
                            value={unitOfMeasurId}
                            name="unitOfMeasurId"
                            size="20"
                            type="text"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="label">
                          <label
                            id="EditProductFeature_numberSpecified_title"
                            htmlFor="EditProductFeature_numberSpecified"
                          >
                            Number / Quantity
                          </label>
                        </td>
                        <td>
                          <input
                            id="EditProductFeature_numberSpecified"
                            value={quantity}
                            name="quantity"
                            onChange={this.handleChange}
                            size="25"
                            type="text"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="label">
                          <label
                            id="EditProductFeature_defaultAmount_title"
                            htmlFor="EditProductFeature_defaultAmount"
                          >
                            Default Amount
                          </label>
                        </td>
                        <td>
                          <input
                            id="EditProductFeature_defaultAmount"
                            value={defaultAmount}
                            name="defaultAmount"
                            onChange={this.handleChange}
                            size="6"
                            type="text"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="label">
                          <label
                            id="EditProductFeature_defaultSequenceNum_title"
                            htmlFor="EditProductFeature_defaultSequenceNum"
                          >
                            Default Sequence Number
                          </label>
                        </td>
                        <td>
                          <input
                            id="EditProductFeature_defaultSequenceNum"
                            name="defaultSequenceNumber"
                            value={defaultSequenceNumber}
                            onChange={this.handleChange}
                            size="6"
                            type="text"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="label">
                          <label
                            id="EditProductFeature_abbrev_title"
                            htmlFor="EditProductFeature_abbrev"
                          >
                            Abbreviation
                          </label>
                        </td>
                        <td>
                          <input
                            id="EditProductFeature_abbrev"
                            maxLength="20"
                            name="Abbreviation"
                            value={Abbreviation}
                            onChange={this.handleChange}
                            size="20"
                            type="text"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="label">
                          <label
                            id="EditProductFeature_idCode_title"
                            htmlFor="EditProductFeature_idCode"
                          >
                            ID Code
                          </label>
                        </td>
                        <td>
                          <input
                            id="EditProductFeature_idCode"
                            maxLength="60"
                            value={idCode}
                            name="idCode"
                            onChange={this.handleChange}
                            size="40"
                            type="text"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="label"></td>
                        <td colSpan="4">
                          <input
                            className="smallSubmit"
                            name="submitButton"
                            type="submit"
                            value="Update"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
          </div>
        </div>
        <p>
          This document has been edited with the free version of the instant
          HTML converter. Try it{" "}
          <a href="https://htmled.it/" rel="nofollow noopener">
            here
          </a>{" "}
          and use it every time for your projects.
        </p>
      </div>
    ) : (
      <div id="content-main-section" className="leftonly">
        <div id="centerdiv" className="no-clear">
          <div>
            <ul style={{ display: "flex", listStyle: "none" }}>
              <a
                href="/catalog/control/EditFeatureTypes"
                style={{ textDecoration: "none" }}
              >
                <li className="featureLi">Feature Type</li>
              </a>
              <a
                href="/catalog/control/EditFeatureCategories"
                style={{ textDecoration: "none" }}
              >
                <li className="featureLi">Feature Category</li>
              </a>
              <a
                href="/catalog/control/EditFeatureGroups"
                style={{ textDecoration: "none" }}
              >
                <li className="featureLi">Feature Group</li>
              </a>
              <a
                href="/catalog/control/EditFeatureInterActions"
                style={{ textDecoration: "none" }}
              >
                <li className="featureLi">Feature interAction</li>
              </a>
            </ul>
          </div>

          <div className="screenlet">
            <div className="screenlet-title-bar">
              <h3>Edit Features for Feature Category</h3>
            </div>
            <div className="screenlet-body">
              <div className="button-bar">
                <a
                  className="buttontext create"
                  href="/catalog/control/CreateFeature"
                >
                  Create New Feature
                </a>
              </div>
              <br />
              <form
                action="/catalog/control/QuickAddProductFeatures"
                method="post"
              >
                <div>
                  Add <input name="featureNum" size="3" type="text" value="1" />{" "}
                  new features to this category{" "}
                  <input className="smallSubmit" type="submit" value="Create" />
                </div>
                <input
                  name="productFeatureCategoryId"
                  type="hidden"
                  value="1000"
                />
              </form>
            </div>
          </div>
          <div className="screenlet">
            <div className="screenlet-title-bar">
              <h3>Product Feature Maintenance</h3>
            </div>
            <div className="screenlet-body">
              <table border="0" width="100%" cellPadding="2">
                <tbody>
                  <tr>
                    <td align="right">
                      <span className="label">
                        {" "}
                        <strong> 1 - 2 of 2 </strong>{" "}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <form method="post" name="selectAllForm">
                <input name="_useRowSubmit" type="hidden" value="Y" />{" "}
                <input name="_checkGlobalScope" type="hidden" value="N" />
                <input
                  name="productFeatureCategoryId"
                  type="hidden"
                  value="1000"
                />
                <table className="basic-table" cellSpacing="0">
                  <tbody>
                    <tr className="header-row">
                      <td width="3%">
                        <strong>Id</strong>
                      </td>
                      <td>
                        <strong>Name</strong>
                      </td>
                      <td>
                        <strong>Description</strong>
                      </td>
                      <td>
                        <strong>Feature Type</strong>
                      </td>
                      <td>
                        <strong>Feature Category</strong>
                      </td>
                      <td>
                        <strong>Unit of Measure ID</strong>
                      </td>
                      <td>
                        <strong>Quantity</strong>
                      </td>
                      <td>
                        <strong>Amount</strong>
                      </td>
                      <td>
                        <strong>ID Seq Num</strong>
                      </td>
                      <td>
                        <strong>ID Code</strong>
                      </td>
                      <td>
                        <strong>Abbrev</strong>
                      </td>
                      <td align="right">
                        <strong>
                          All
                          <input name="selectAll" type="checkbox" value="Y" />
                        </strong>
                      </td>
                    </tr>
                    {this.props.singleCategory?.Features?.map((one) => (
                      <tr>
                        <td width="3%">
                          <strong>{one._id}</strong>
                        </td>
                        <td>
                          <Link
                            to={`/catalog/control/CreateFeature?id=${one.id}`}
                            onClick={() => this.props.oneFeature(one._id)}
                          >
                            {one.id}
                          </Link>
                        </td>
                        <td>{one.description}</td>
                        <td>{one.Type?.id}</td>
                        <td>{one.Category?.id}</td>
                        <td>{one.unitOfMeasurId}</td>
                        <td>{one.quantity}</td>
                        <td>{one.defaultAmount}</td>
                        <td>{one.defaultSequenceNumber}</td>
                        <td>{one.idCode}</td>
                        <td>{one.Abbreviation}</td>
                      </tr>
                    ))}

                    <tr>
                      <td colSpan="11" align="center">
                        <input name="_rowCount" type="hidden" value="2" />
                        <input type="submit" value="Update" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  constructor(props) {
    super(props);

    this.state = {
      id: "",
      type: "",
      category: "",
      description: "",
      unitOfMeasurId: "",
      quantity: 1,
      defaultAmount: 0,
      defaultSequenceNumber: 0,
      Abbreviation: "",
      idCode: "",
    };
  }

  handleSubmit = (e) => {
    delete this.state.feature;
    e.preventDefault();
    const query = queryString.parse(this.props.location.search);
    const _id = query.id;
    if (_id) {
      let obj = {};
      Object.keys(this.state).forEach((item) => {
        if (this.state[item]) {
          obj[item] = this.state[item];
        }
      });

      this.props.update(_id, obj);
    } else {
      this.props.create(this.state);
    }
  };

  componentWillReceiveProps(newProps, nextContext) {
    const query = queryString.parse(this.props.location.search);
    const id = query.id;
    if (newProps.feature && id) {
      this.setState({
        id: newProps.feature.id,
        type: newProps.feature.type,
        category: newProps.feature.category,
        description: newProps.feature.description,
        unitOfMeasurId: newProps.feature.unitOfMeasurId,
        quantity: newProps.feature.quantity,
        defaultAmount: newProps.feature.defaultAmount,
        defaultSequenceNumber: newProps.feature.defaultSequenceNumber,
        Abbreviation: newProps.feature.Abbreviation,
        idCode: newProps.feature.idCode,
      });
    }
    if (newProps.feature?.length) {
      this.setState({ feature: newProps.feature });
    }
  }

  componentDidMount() {
    this.props.allTypes();
    this.props.allCategories();
  }
}

const mapStateToProps = (state) => ({
  features: state?.catalog?.catalogs?.features,
  all_feature_types: state?.catalog?.catalogs?.all_feature_types,
  all_feature_categories: state?.catalog?.catalogs?.all_feature_categories,
  singleCategory: state?.catalog?.catalogs?.feature_categroy,
  feature: state?.catalog?.catalogs?.feature,
});

const mapDispatchToProps = (dispatch, router) => ({
  create: (data) => dispatch(createFeature(data, router)),
  allTypes: (data) => dispatch(getAllFeatureTypes(data, router)),
  allCategories: (data) => dispatch(getAllFeatureCategory(data, router)),
  oneFeature: (id, data) => dispatch(getOneFeature(id, data, router)),
  update: (id, data) => dispatch(updateFeature(id, data, router)),
  // getAllFeatures: (data, router) => dispatch(getAllFeatures(data, router)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateNewFeature)
);
