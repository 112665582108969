import React, { Component } from "react";
import { connect } from "react-redux";

export class EditRequirement extends Component {
  render() {
    return (
      <div id="content-main-section">
        <div>
          <h1>Requirement []</h1>
        </div>
        <div id="screenlet_1" class="screenlet">
          <div class="screenlet-title-bar">
            <ul>
              <li class="h3">Edit Requirement</li>
            </ul>
          </div>
          <div id="screenlet_1_col" class="screenlet-body">
            <form
              id="EditRequirement"
              class="basic-form"
              action="/ordermgr/control/createRequirement"
              method="post"
              name="EditRequirement"
            >
              <input
                id="EditRequirement_requirementId"
                name="requirementId"
                type="hidden"
              />
              <table class="basic-table" cellspacing="0">
                <tbody>
                  <tr>
                    <td class="label">
                      <label
                        id="EditRequirement_requirementTypeId_title"
                        for="EditRequirement_requirementTypeId"
                      >
                        Requirement Type Id
                      </label>
                    </td>
                    <td>
                      <span class="ui-widget">
                        <select
                          id="EditRequirement_requirementTypeId"
                          name="requirementTypeId"
                          size="1"
                        >
                          <option value="CUSTOMER_REQUIREMENT">
                            Customer Requirement
                          </option>
                          <option value="INTERNAL_REQUIREMENT">
                            Internal Requirement
                          </option>
                          <option value="PRODUCT_REQUIREMENT">
                            Product Requirement
                          </option>
                          <option value="WORK_REQUIREMENT">
                            Work Requirement
                          </option>
                        </select>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="label">
                      <label
                        id="EditRequirement_facilityId_title"
                        for="EditRequirement_facilityId"
                      >
                        Facility Id
                      </label>
                    </td>
                    <td>
                      <span class="ui-widget">
                        <select
                          id="EditRequirement_facilityId"
                          name="facilityId"
                          size="1"
                        >
                          <option value="10000">[10000]</option>
                          <option value="30000">[30000]</option>
                          <option value="MyRetailStore">
                            Example Retail (POS) Store [MyRetailStore]
                          </option>
                          <option value="WebStoreWarehouse">
                            Warehouse exclusively for the Web Store
                            [WebStoreWarehouse]
                          </option>
                        </select>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="label">
                      <label
                        id="EditRequirement_deliverableId_title"
                        for="EditRequirement_deliverableId"
                      >
                        Deliverable Id
                      </label>
                    </td>
                    <td>
                      <input
                        id="EditRequirement_deliverableId"
                        maxlength="20"
                        name="deliverableId"
                        size="20"
                        type="text"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="label">
                      <label
                        id="EditRequirement_fixedAssetId_title"
                        for="EditRequirement_fixedAssetId"
                      >
                        Fixed Asset Id
                      </label>
                    </td>
                    <td>
                      <div id="0_lookupId_EditRequirement_fixedAssetId_auto">
                        {" "}
                      </div>
                      <span class="field-lookup">
                        <input
                          id="0_lookupId_EditRequirement_fixedAssetId"
                          class="ui-autocomplete-input"
                          autocomplete="off"
                          name="fixedAssetId"
                          size="25"
                          type="text"
                        />{" "}
                        <a href id="0_lookupId_button">
                          {" "}
                        </a>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="label">
                      <label
                        id="EditRequirement_productId_title"
                        for="EditRequirement_productId"
                      >
                        Product Id
                      </label>
                    </td>
                    <td>
                      <div id="1_lookupId_EditRequirement_productId_auto">
                        {" "}
                      </div>
                      <span class="field-lookup">
                        <input
                          id="1_lookupId_EditRequirement_productId"
                          class="ui-autocomplete-input"
                          autocomplete="off"
                          name="productId"
                          size="25"
                          type="text"
                        />{" "}
                        <a href id="1_lookupId_button">
                          {" "}
                        </a>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="label">
                      <label
                        id="EditRequirement_statusId_title"
                        for="EditRequirement_statusId"
                      >
                        Status ID
                      </label>
                    </td>
                    <td>
                      <span class="ui-widget">
                        <select
                          id="EditRequirement_statusId"
                          name="statusId"
                          size="1"
                        >
                          <option selected="selected" value="REQ_CREATED">
                            Created
                          </option>
                          <option value="REQ_PROPOSED">Proposed</option>
                          <option value="REQ_APPROVED">Approved</option>
                          <option value="REQ_ORDERED">Ordered</option>
                          <option value="REQ_REJECTED">Rejected</option>
                        </select>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="label">
                      <label
                        id="EditRequirement_description_title"
                        for="EditRequirement_description"
                      >
                        Description
                      </label>
                    </td>
                    <td>
                      <input
                        id="EditRequirement_description"
                        maxlength="250"
                        name="description"
                        size="60"
                        type="text"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="label">
                      <label
                        id="EditRequirement_requirementStartDate_title"
                        for="EditRequirement_requirementStartDate"
                      >
                        Requirement Start Date
                      </label>
                    </td>
                    <td>
                      <span class="view-calendar">
                        {" "}
                        <input
                          id="EditRequirement_requirementStartDate_i18n"
                          title="dd-MM-yyyy HH:mm:ss"
                          maxlength="30"
                          name="requirementStartDate_i18n"
                          size="25"
                          type="text"
                        />{" "}
                        <input
                          id="EditRequirement_requirementStartDate"
                          class="hasDatepicker"
                          title="dd-MM-yyyy HH:mm:ss"
                          maxlength="30"
                          name="requirementStartDate"
                          size="25"
                          type="text"
                        />
                        <button
                          class="ui-datepicker-trigger"
                          type="button"
                        ></button>{" "}
                        <input name="" type="hidden" value="Timestamp" />{" "}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="label">
                      <label
                        id="EditRequirement_requiredByDate_title"
                        for="EditRequirement_requiredByDate"
                      >
                        Required By Date
                      </label>
                    </td>
                    <td>
                      <span class="view-calendar">
                        {" "}
                        <input
                          id="EditRequirement_requiredByDate_i18n"
                          title="dd-MM-yyyy HH:mm:ss"
                          maxlength="30"
                          name="requiredByDate_i18n"
                          size="25"
                          type="text"
                        />{" "}
                        <input
                          id="EditRequirement_requiredByDate"
                          class="hasDatepicker"
                          title="dd-MM-yyyy HH:mm:ss"
                          maxlength="30"
                          name="requiredByDate"
                          size="25"
                          type="text"
                        />
                        <button
                          class="ui-datepicker-trigger"
                          type="button"
                        ></button>{" "}
                        <input name="" type="hidden" value="Timestamp" />{" "}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="label">
                      <label
                        id="EditRequirement_estimatedBudget_title"
                        for="EditRequirement_estimatedBudget"
                      >
                        Estimated Budget
                      </label>
                    </td>
                    <td>
                      <input
                        id="EditRequirement_estimatedBudget"
                        name="estimatedBudget"
                        size="6"
                        type="text"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="label">
                      <label
                        id="EditRequirement_quantity_title"
                        for="EditRequirement_quantity"
                      >
                        Quantity
                      </label>
                    </td>
                    <td>
                      <input
                        id="EditRequirement_quantity"
                        name="quantity"
                        size="25"
                        type="text"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="label">
                      <label
                        id="EditRequirement_useCase_title"
                        for="EditRequirement_useCase"
                      >
                        Use Case
                      </label>
                    </td>
                    <td>
                      <textarea
                        id="EditRequirement_useCase"
                        cols="60"
                        name="useCase"
                        rows="2"
                      ></textarea>
                    </td>
                  </tr>
                  <tr>
                    <td class="label">
                      <label
                        id="EditRequirement_reason_title"
                        for="EditRequirement_reason"
                      >
                        Reason
                      </label>
                    </td>
                    <td>
                      <input
                        id="EditRequirement_reason"
                        maxlength="250"
                        name="reason"
                        size="60"
                        type="text"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="label">
                      <label
                        id="EditRequirement_custRequestId_title"
                        for="EditRequirement_custRequestId"
                      >
                        Cust Request Id
                      </label>
                    </td>
                    <td>
                      <input
                        id="EditRequirement_custRequestId"
                        name="custRequestId"
                        size="25"
                        type="text"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="label">
                      <label
                        id="EditRequirement_custRequestItemSeqId_title"
                        for="EditRequirement_custRequestItemSeqId"
                      >
                        Cust Request Item Seq Id
                      </label>
                    </td>
                    <td>
                      <input
                        id="EditRequirement_custRequestItemSeqId"
                        name="custRequestItemSeqId"
                        size="25"
                        type="text"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="label"> </td>
                    <td colspan="4">
                      <input
                        class="smallSubmit"
                        name="submitButton"
                        type="submit"
                        value="Submit"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EditRequirement);
