import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom"
import {
    getProduct
} from "../../../../store/actions/catalogActions";
import {withRouter} from "react-router-dom";


class FindProductById extends Component {
    render() {
        const {id,_id, internalName} = this.state;
        return (
            <div id="content-main-section" className="leftonly">
                <div id="centerdiv" className="no-clear">
                    <div className="screenlet">
                        <div className="screenlet-title-bar">
                            <div className="h3">Find Product with ID Value</div>
                        </div>
                        <div className="screenlet-body">
                            <form onSubmit={this.formSubmit}>
                            <span className="label">ID Value:</span> <input maxLength="50"
                                                                            name="id"
                                                                            size="20"
                                                                            type="text"
                                                                            onChange={this.handleOnChange}
                                                                            value={id}/>

                            <input value="Find"
                                      type="submit" className="buttontext" onClick={this.formSubmit}/>
                            </form>
                            <br/>
                            <h1>Search Results for Product with ID Value: {_id ? _id :id}</h1>
                            <table className="basic-table" cellSpacing="0">
                                <tbody>
                                {_id ? <tr className="alternate-row" valign="middle">
                                    <td>{_id}</td>
                                    <td></td>
                                    <td><Link className="buttontext" to={`/catalog/control/EditFnpProduct?productId=${id}`}>
                                        {internalName} </Link> (ID Value was the actual productId of this product.)
                                    </td>
                                </tr> : (<tr>
                                    <td><h2>No results found</h2></td>
                                </tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    constructor() {
        super();
        this.state = {
             id: '',
            _id: '',
            internalName: ''
        }
    }
    handleOnChange = (e)=>{
        this.setState({[e.target.name]:e.target.value});

    }
    formSubmit = async(e)=>{
        e.preventDefault();
        try{
            const data = await this.props.getProductDetails(this.state.id);
            if (data.data && data.data._id) {
                this.setState({_id: data.data._id, internalName: data.data.internalName});
            } else {
                this.setState({_id: ''});
            }
        }catch(e){
            this.setState({_id: ''});
        }
    }
    async componentDidMount() {
        const url = new URLSearchParams(this.props?.location?.search);
        const productId = url?.get("productId");
        this.setState({id: productId});
        const data = await this.props.getProductDetails(productId);
        if (data.data && data.data._id) {
            this.setState({_id: data.data._id, internalName: data.data.internalName});
        }
    }
}

const mapStateToProps = (state) => ({
    submitted: state,
    categories: state,
});

const mapDispatchToProps = (dispatch, router) => ({
    getProductDetails: (id) => dispatch(getProduct(id)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(FindProductById)
);
