import React from "react";
import { Link } from "react-router-dom";
import {
  postRequest,
  putRequest,
  getRequest,
} from "../../../../store/services";

class OccasionMessages extends React.Component {
  constructor() {
    super();

    this.state = {
      editModeOccasion: false,
      editModeMessage: false,
      occasions: [
        // {
        //     id: 1,
        //     occasionName: 'Birthday',
        //     occasionsMessages: [
        //         { id: 1, message: 'THis is test a Anniversary Message of testing the long message of occasions and too much long for occ THis is test a Anniversary Message of testing the long message of occasions and too much long for occ THis is test a Anniversary Message of testing the long message of occasions and too much long for occ THis is test a Anniversary Message of testing the long message of occasions and too much long for occ' }
        //     ]
        // },
        // {
        //     id: 2,
        //     occasionName: 'Anniversary',
        //     occasionsMessages: [
        //         { id: 1, message: 'Anniversary Message' }
        //     ]
        // },
      ],
      selectedOccasion: 0,
      occasionMessages: [],
      selectedMessage: 0,
      occasion: "",
      message: "",
    };
  }

  async componentDidMount() {
    try {
      const res = await getRequest("occasion/all");
      console.log(res);
      this.setState({ occasions: res.data });
    } catch (error) {
      console.log(error);
    }
  }

  onOccasionSelect = (e) => {
    const occasion = this.state.occasions.find(
      (oc) => oc._id == e.target.value
    );

    this.setState({
      selectedOccasion: e.target.value,
      occasion: occasion.occasionName,
      occasionMessages: occasion.occasionMessages,
      selectedMessage: 0,
      message: "",
    });
  };

  onMessageSelect = (id) => {
    const occasion = this.state.occasions.find(
      (oc) => oc._id == this.state.selectedOccasion
    );
    const message = occasion.occasionMessages.find((msg) => msg._id == id);

    this.setState({
      selectedMessage: id,
      message: message.message,
    });
  };

  onOccasionSubmit = async () => {
    if (this.state.selectedOccasion === 0) {
      const res = await postRequest("occasion/create", {
        occasionName: this.state.occasion,
      });

      console.log(res);
      window.location.reload();
    } else {
      const res = await putRequest(
        `occasion/update/${this.state.selectedOccasion}`,
        {
          occasionName: this.state.occasion,
        }
      );
      window.location.reload();
    }
  };

  onMessageSubmit = async () => {
    if (this.state.selectedMessage === 0) {
      const res = await postRequest(
        `occasion/create/${this.state.selectedOccasion}/message`,
        {
          message: this.state.message,
        }
      );

      console.log(res);
      window.location.reload();
    } else {
      const res = await putRequest(
        `occasion/update/message/${this.state.selectedMessage}`,
        {
          message: this.state.message,
        }
      );
      window.location.reload();
    }
  };

  onMessageDelete = async (id) => {
    const res = await putRequest(`occasion/remove/message/${id}`);
    window.location.reload();
  };

  render() {
    return (
      <div id="content-main-section" class="leftonly">
        <div id="screenlet_1" class="screenlet">
          <div class="screenlet-title-bar">
            <ul>
              <li class="h3">Occasions</li>
            </ul>
          </div>
          <div id="screenlet_1_col" class="screenlet-body">
            <label htmlFor="" style={{ marginRight: "5px" }}>
              <b>Select Occasion:</b>
            </label>
            <select
              name=""
              id=""
              value={this.state.selectedOccasion}
              onChange={this.onOccasionSelect}
            >
              <option value="">[Select]</option>
              {this.state.occasions.map((occasion) => {
                // return <option value={geo._id} key={geo._id}>{geo.geoId}</option>
                return (
                  <option value={occasion._id} key={occasion._id}>
                    {occasion.occasionName}
                  </option>
                );
              })}
            </select>
          </div>

          <div id="screenlet_2" class="screenlet">
            <div class="screenlet-title-bar">
              <ul>
                <li class="h3">
                  {this.state.selectedOccasion ? "Edit" : "Add"} Occasion
                </li>
              </ul>
            </div>
            <div id="screenlet_2_col" class="screenlet-body">
              <table class="basic-table" cellspacing="0">
                <tbody>
                  <tr>
                    <td class="label">
                      <label
                        id="EditShipmentMethodType_shipmentMethodTypeId_title"
                        for="EditShipmentMethodType_shipmentMethodTypeId"
                      >
                        Occasion Name
                      </label>
                      {}*
                    </td>
                    <td>
                      <input
                        id="EditShipmentMethodType_shipmentMethodTypeId"
                        class="required"
                        maxlength="20"
                        onChange={(e) =>
                          this.setState({ occasion: e.target.value })
                        }
                        value={this.state.occasion}
                        name="shipmentMethodTypeId"
                        size="30"
                        // type="readOnly"
                        // disabled={this.state.isDisabled}
                      />{" "}
                      *
                    </td>
                  </tr>
                  <tr>
                    <td class="label"> </td>
                    <td colspan="4">
                      <input
                        class="smallSubmit"
                        name="submitButton"
                        type="submit"
                        onClick={this.onOccasionSubmit}
                        value={"Submit"}
                        disabled={!this.state.occasion}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div id="screenlet_1" class="screenlet">
          <div class="screenlet-title-bar">
            <ul>
              <li class="h3">Occasions Messages</li>
            </ul>
          </div>
          <div id="screenlet_1_col" class="screenlet-body">
            <div class="nav-pager">
              <ul>
                <li class="nav-first-disabled">First</li>
                <li class="nav-previous-disabled">Previous</li>
                <li class="nav-page-select">
                  Page
                  <select
                    name="page"
                    size="1"
                    onchange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1=200&amp;VIEW_INDEX_1='+this.value)"
                  >
                    <option selected="selected" value="0">
                      1
                    </option>
                  </select>
                </li>
                <li class="nav-next-disabled">Next</li>
                <li class="nav-last-disabled">Last</li>
                <li class="nav-pagesize">
                  <select
                    name="pageSize"
                    size="1"
                    onchange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')"
                  >
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option selected="selected" value="200">
                      200
                    </option>
                  </select>
                  Items per page
                </li>
                <li class="nav-displaying">Displaying 1 - 23 of 23</li>
              </ul>
            </div>
            <table class="basic-table" cellspacing="0">
              <tbody>
                <tr class="header-row">
                  <td>
                    <label
                      id="ListShipmentMethodTypes_shipmentMethodTypeId_title"
                      for="ListShipmentMethodTypes_shipmentMethodTypeId"
                    >
                      Message Id
                    </label>
                  </td>
                  <td>
                    <label
                      id="ListShipmentMethodTypes_description_title"
                      for="ListShipmentMethodTypes_description"
                    >
                      Message
                    </label>
                  </td>
                  <td> </td>
                </tr>

                {/* Map */}
                {this.state.occasionMessages.map((message) => {
                  return (
                    <tr>
                      <td>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            this.onMessageSelect(message._id);
                          }}
                        >
                          {message._id}
                        </a>
                      </td>

                      <td>{message.message}</td>
                      <td>
                        <form
                          action="/catalog/control/deleteShipmentMethodType"
                          method="post"
                          name="ListShipmentMethodTypes_o_0_0_o_deleteLink"
                        >
                          <input name="VIEW_SIZE_1" type="hidden" value="200" />
                          <input name="VIEW_INDEX_1" type="hidden" value="0" />
                          <input
                            name="shipmentMethodType_Id"
                            type="hidden"
                            // value={method._id}
                          />
                        </form>
                        <a
                          class="buttontext"
                          href
                          onClick={(e) => {
                            e.preventDefault();
                            this.onMessageDelete(message._id);
                          }}
                        >
                          Remove
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div class="nav-pager">
              <ul>
                <li class="nav-first-disabled">First</li>
                <li class="nav-previous-disabled">Previous</li>
                <li class="nav-page-select">
                  Page
                  <select
                    name="page"
                    size="1"
                    onchange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1=200&amp;VIEW_INDEX_1='+this.value)"
                  >
                    <option selected="selected" value="0">
                      1
                    </option>
                  </select>
                </li>
                <li class="nav-next-disabled">Next</li>
                <li class="nav-last-disabled">Last</li>
                <li class="nav-pagesize">
                  <select
                    name="pageSize"
                    size="1"
                    onchange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')"
                  >
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option selected="selected" value="200">
                      200
                    </option>
                  </select>
                  Items per page
                </li>
                <li class="nav-displaying">Displaying 1 - 23 of 23</li>
              </ul>
            </div>
          </div>

          <div id="screenlet_2" class="screenlet">
            <div class="screenlet-title-bar">
              <ul>
                <li class="h3">
                  {this.state.selectedMessage ? "Edit" : "Add"} Message
                </li>
              </ul>
            </div>
            <div id="screenlet_2_col" class="screenlet-body">
              <table class="basic-table" cellspacing="0">
                <tbody>
                  <tr>
                    <td class="label">
                      <label
                        id="EditShipmentMethodType_shipmentMethodTypeId_title"
                        for="EditShipmentMethodType_shipmentMethodTypeId"
                      >
                        Message
                      </label>
                      {}*
                    </td>
                    <td>
                      <textarea
                        id="w3review"
                        name="w3review"
                        rows="4"
                        cols="50"
                        value={this.state.message}
                        onChange={(e) =>
                          this.setState({ message: e.target.value })
                        }
                        disabled={!this.state.selectedOccasion}
                      />
                      {/* {" "}* */}
                    </td>
                  </tr>
                  <tr>
                    <td class="label"> </td>
                    <td colspan="4">
                      <input
                        class="smallSubmit"
                        name="submitButton"
                        type="submit"
                        onClick={this.onMessageSubmit}
                        value={"Submit"}
                        disabled={!this.state.message}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OccasionMessages;
