import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import '../../styles.scss';

class GiftCard extends Component {

    render() {
        const {userById} = this.props;
        const query = new URLSearchParams(this.props?.location?.search);
        const partyId = query.get('partyId');

        return (
            <div id="searchOptions" className="screenlet" style={{marginTop: '10px'}}>
                <div className="screenlet-title-bar">
                    <ul>
                        <li className="h3">Create New Gift Card</li>
                    </ul>
                </div>

                <div id="searchOptions_col" className="screenlet-body">
                    <div id="search-options">
                        <form>
                            <table cellSpacing="0" className='basic-table'>
                                <tbody>
                                <tr>
                                    <td colSpan={2}>
                                        <Link className="buttontext create"
                                              to={`viewprofile?partyId=${partyId}&_id=${userById?._id}`}>Cancel/Done</Link> {' '}
                                        <button type='submit' className='buttontext create'>
                                            Save
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='label'>
                                        <label>Card Number</label>
                                    </td>
                                    <td>
                                        <input type='text'/>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='label'>
                                        <label>Pin Number</label>
                                    </td>
                                    <td>
                                        <input type='text'/>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='label'>
                                        <label>Expire Date</label>
                                    </td>
                                    <td>
                                        <select>
                                            <option></option>
                                            <option value="01">01</option>
                                            <option value="02">02</option>
                                            <option value="03">03</option>
                                            <option value="04">04</option>
                                            <option value="05">05</option>
                                            <option value="06">06</option>
                                            <option value="07">07</option>
                                            <option value="08">08</option>
                                            <option value="09">09</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select> {' '}

                                        <select>
                                            <option></option>
                                            <option value="2020">2020</option>
                                            <option value="2021">2021</option>
                                            <option value="2022">2022</option>
                                            <option value="2023">2023</option>
                                            <option value="2024">2024</option>
                                            <option value="2025">2025</option>
                                            <option value="2026">2026</option>
                                            <option value="2027">2027</option>
                                            <option value="2028">2028</option>
                                            <option value="2029">2029</option>
                                            <option value="2030">2030</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='label'>
                                        <label>Description</label>
                                    </td>
                                    <td>
                                        <input type='text'/>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <Link className="buttontext create"
                                              to={`viewprofile?partyId=${partyId}&_id=${userById?._id}`}>Cancel/Done</Link> {' '}
                                        <button type='submit' className='buttontext create'>
                                            Save
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                </div>

            </div>
        );

    }

}


const mapStateToProps = (state) => ({
    userById: state.partyManager?.users?.userById
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GiftCard);
