import React, { Component } from "react";
import RichTextEditor from "./RichTextEditor";
import { connect } from "react-redux";
import {updateContents} from "../../../../store/actions/catalogActions";
import {getRequest} from "../../../../store/services";

class Contents extends React.Component{
    constructor() {
        super();
        this.state={
            _id:'',
            content:''
        }
    }

  async  componentDidMount() {
        const url = new URLSearchParams(this.props?.location?.search);
        const category = url?.get("productCategoryId");
        let response = await getRequest('categories/getContent/'+category);
        this.setState({_id:category,content:response?.data?.content})
    }
    updateContent=()=>{
        let data = {content:this.state.content,id:this.state._id};
        this.props.update(data)
    }

    setFieldValue=(content,html)=>{
       this.setState({content:html})
    }
    render(){
        return(<div id="content-main-section" className="leftonly">
            <div id="centerdiv" className="no-clear">
                <div className="screenlet">
                    <div className="button-bar tab-bar">
                        <ul>
                            <li>
                                <ul>
                                    <li>
                                        <a
                                            href={
                                                "/catalog/control/EditCategory?productCategoryId=" +
                                                this.state._id
                                            }
                                        >
                                            Category
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={
                                                "/catalog/control/ProductSequencing?productCategoryId=" +
                                                this.state._id
                                            }
                                        >
                                            Product Sequencing
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={
                                                "/catalog/control/Contents?productCategoryId=" +
                                                this.state._id
                                            }
                                        >
                                            Contents
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className="screenlet-title-bar">
                        <ul>
                            <li className="h3">Create Product Content</li>
                        </ul>
                    </div>
                    <div className="screenlet-body">
                        <RichTextEditor
                            {...{
                                // error:
                                //     errors.content_description &&
                                //     touched.content_description,
                                required: true,

                                label: "",
                                value: this.state.content,
                                onChange: (html) => {
                                    this.setFieldValue(
                                        "content_description",
                                        html
                                    );
                                },
                                name: "content_description",
                            }}
                        />
                        <button type={"button"} onClick={this.updateContent}>add</button>
                    </div>
                </div>
            </div>

        </div>);
    }
}
const mapStateToProps=(state)=>({

})
const mapDispatchToProps=(dispatch)=>({
    update:(data)=>dispatch(updateContents(data))
})

export default connect(mapStateToProps,mapDispatchToProps)(Contents)