import React, { Component } from "react";
import suneditor from "suneditor";
import plugins from "suneditor/src/plugins";
import "./RichTextEditor.scss"
import $ from "jquery";

import { getEditorStyles, stripHTML_ENTITIESV2 } from "../../../../constants/utils";
import { RICH_TEXT_EDITOR_OPTIONS } from "../../../../constants/utils";
// import { GENERIC_FILE_UPLOAD, IMAGE_PATH } from "../../api/constants";
// import { setMESTEditorToolbarHeightVar } from "../header";


// add to constants start from here





// add to constants end from here
let options = { ...RICH_TEXT_EDITOR_OPTIONS, plugins: plugins };
export default class RichTextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = { html: "", finalFH: "" };
    this.editor = null;
    this.styledHtml = this.getStyledHtml("<br>");
    this.initialValue = null;
    this.emptyValue = "<p></p>";
  }
  componentDidMount() {
    this.initializeEditor();
  }

  componentDidUpdate(prevProps, prevState) {
    const { html } = this.state;
    if (this.state !== prevState) {
      // console.log({
      //   prevProps,
      //   value,
      //   name: prevProps.name,
      //   news_type: this.props.news_type,
      // });
      if (prevProps.value?.length && prevProps.value !== this.emptyValue) {
        var newHtml = html === this.styledHtml ? this.emptyValue : html;
        // console.log({
        //   sendingHtmlInIf: newHtml,
        //   name: prevProps.name,
        //   news_type: this.props.news_type,
        // });
        this.props.onChange(newHtml);
      } else if (html !== this.styledHtml) {
        // console.log({
        //   sendingHtmlInElse: html,
        //   name: prevProps.name,
        //   news_type: this.props.news_type,
        // });
        this.props.onChange(html);
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    const { value } = nextProps;
    const { html, finalFH } = this.state;
    if (
      (value !== this.props.value &&
        value !== this.emptyValue &&
        value !== html &&
        value !== finalFH) ||
      (value === "" && value !== this.props.value)
    ) {
      // console.log({ receivedInE: value, name: nextProps.name });
      var finalHtml = this.getFinalHtml(value);
      this.setState({ finalFH: finalHtml }, () =>
        this.setInitialHtml(finalHtml)
      );
    }
    if (this.props.value !== nextProps.value) {


    }
  }
  initializeEditor = () => {
    const { name } = this.props;
    let value = this.props.value;
    if (name !== undefined) {
      const initEditor = suneditor.init({
        ...options,
        linkProtocol: `${window.location.protocol}//`,
        historyStackDelayTime: 100,
      });
      var ref = "rich-text-editor-" + name;
      this.editor = initEditor.create(ref);
      this.editor.onload = this.onLoadEditor;
      this.editor.onFocus = this.onEditorFocus;
      this.editor.onChange = this.handleChange;
      this.editor.onBlur = this.handleBlur;
      this.editor.onImageUploadBefore = function (
        files,
        info,
        core,
        uploadHandler
      ) {
        try {
          resizeImage(files, core, info);
        } catch (err) {
          uploadHandler(err.toString());
        }
      };
      function resizeImage(files, core, info) {
        const uploadFile = files[0];
        const img = document.createElement("img");
        const canvas = document.createElement("canvas");
        const reader = new FileReader();
        reader.onload = function (e) {
          img.src = e.target.result;
          img.onload = function () {
            let ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            const MAX_WIDTH = 1000;
            const MAX_HEIGHT = 1000;
            let width = img.width;
            let height = img.height;
            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }
            canvas.width = width;
            canvas.height = height;
            ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);
            canvas.toBlob(
              async function (blob) {
                let data = new FormData();
                var f = new File([blob], uploadFile.name);
                data.append("file", f);
                var res = await $.ajax({
                  data: data,
                  type: "POST",
                  // url: GENERIC_FILE_UPLOAD,
                  cache: false,
                  contentType: false,
                  processData: false,
                });
                // debugger;
                var resData = res?.data?.result?.[0];
                const file = { name: resData.name, size: resData.size };
                // const imageUrl = `${IMAGE_PATH}/${resData.url}`;
                const imageUrl = ``;
                if (info.isUpdate) {
                  core.plugins.image.update_src.call(
                    core,
                    imageUrl,
                    info.element,
                    file
                  );
                  return;
                } else {
                  core.plugins.image.create_image.call(
                    core,
                    imageUrl,
                    info.linkValue,
                    info.linkNewWindow,
                    info.inputWidth,
                    info.inputHeight,
                    info.align,
                    file
                  );
                }
                core.closeLoading();
              },
              uploadFile.type,
              1
            );
          };
        };
        reader.readAsDataURL(uploadFile);
      }
      this.editor.onClick = (e) => {
        var editor = this.editor.getContext()?.element?.wysiwyg;
        if (
          editor.childNodes.length > 1 &&
          editor?.lastChild.innerHTML === "<br>"
        ) {
          // debugger;
          editor.removeChild(editor?.lastChild);
        }
      };
      var finalHtml = this.getFinalHtml(value);
      this.setState({ finalFH: finalHtml });
      this.setInitialHtml(finalHtml);
    }
  };

  onLoadEditor = (core) => {
    
  };
  onEditorFocus = (event, core) => {


  };
  getFinalHtml = (value) => {
    // var allSubNodes = [];
    // $.parseHTML(value).map((n, key) => {
    //   if (n.localName !== "br" && n.localName !== undefined) {
    //     // allSubNodes.push(
    //     //   `<${n.localName} class="${n.className}">${n.innerHTML}</${n.localName}>`
    //     // );
    //     allSubNodes.push(n.outerHTML);
    //   }
    //   return true;
    // });
    // var finalHtml = allSubNodes.length ? `<p>${allSubNodes.join("")}</p>` : "";
    // // debugger;
    // // console.log({ finalHtml });
    // return finalHtml;
    return value;
  };
  componentWillUnmount() {
    this.editor && this.editor.destroy();
  }
  handleChange = (html, core) => {
    // console.log({ html, contents: this.editor.getContents() }, new Date());
    this.setHtmlInState(html);
  };

  handleBlur= (html, core) =>{
    const tag= core.getContents();
    const replaced= tag.replaceAll(/href/g, 'onClick="openSunEditorHref(event)" href')
    this.setState({html:replaced})

  }
  setInitialHtml = (html) => {
    html = this.removeEmptyTags(html);
    if (this.isEmpty(html)) {
      html = this.styledHtml;
    }
    this.setState({ html }, () => this.editor.setContents(html));
  };
  setHtmlInState = (html) => {
    if (this.isEmpty(html)) {
      html = this.styledHtml;
      this.editor.setContents(html);
    }
    this.setState({ html });
  };
  removeEmptyTags = (html) => {
    return html;
    // console.log(html, $.parseHTML(html));
    // const regex = /(<(?!\/)[^>]+>)+(<\/[^>]+>)+/gi;
    // return html ? html.replace("<p></p>", "") : "<p></p>";
  };
  isEmpty = (value) => {
    value = stripHTML_ENTITIESV2(value);
    // const newHtmlInnerText = $.parseHTML(value)[0]?.innerText;
    const res =
      value?.length === 0 ||
      value.trim()?.length === 0 ||
      value.replace("\n", "")?.length === 0;
    // newHtmlInnerText?.length === 0;
    return res;
  };
  getStyledHtml = (value) => {
    const stylesString = this.getStyles();
    return `<p><span style="${stylesString}">${value}</span></p>`;
  };
  getStyledHtmlV2 = (value) => {
    const stylesString = this.getStyles();
    return `<span style="${stylesString}">${value}</span>`;
  };
  getStyles = () => {
    const { styles } = this.props;
    const stylesF = styles ? styles : getEditorStyles();
    const keys = Object.keys(stylesF);
    const values = Object.values(stylesF);
    let stylesString = "";
    keys.map((val, index) => {
      const value = values?.[index] ? values?.[index].toString() : "";
      stylesString += `${val}:${
        value.split(" ")?.length > 1 ? `'${value}'` : value
      };`;
      return true;
    });
    return stylesString;
  };
  render() {
    const { name, error, required, label, lang, normalize } = this.props;
    return (
      <div
        className={`form-group  ${error ? "invalid" : ""} ${
          normalize ? "mb-0" : ""
        }`}
        id={"fg-rich-text-editor-" + name}
      >
        {/*<label className="font-weight-bold">*/}
        {/*  {`${label || ""}${required ? "*" : ""}`}*/}
        {/*</label>*/}
        <textarea id={"rich-text-editor-" + name}></textarea>
        {/*<small class="error-msg-editor">{lang?.ThisFieldIsRequired}</small>*/}
      </div>
    );
  }
}
