import React from "react";
import { Modal, Collapse } from "@material-ui/core";
import {putRequest} from "../../store/services";
import CloseIcon from "@material-ui/icons/Close";

export default class Dispose extends React.Component{
    state={
        height:window.innerHeight,
        width:window.innerWidth,
        reason:'',
    }
    componentDidMount() {
        window.addEventListener("resize", this.setDimensions.bind(this));
    }
    setDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };
    submit=async()=>{
        let orderId = this.props.orderId;
        let cartId = this.props.cartId;
        let data = {};
        if(orderId){
           data.orderId=orderId
        }
        if(cartId){
            data.cartId=cartId;
        }
        data.reason=this.state.reason;
        this.props.disposeOrder(data);
    }
    render() {
        return(
            <div>
                <Modal
                    open={this.props.modalOpen}
                    onClose={this.props.handleClose}
                    //   style={{ overflow: "scroll" }}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div
                        style={{
                            height: "200px",
                            width: "400px",
                            backgroundColor: "white",
                            position: "absolute",
                            top: (this.state.height-200)/2,
                            border: "2px solid white",
                            left: (this.state.width-400)/2,
                            alignItems: "center",
                            display: "flex",
                            overflow: "scroll",
                            flexDirection: "column",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                backgroundColor: "#527894",
                                borderRadius: "5px",
                                width: "100%",
                                height: "30px",
                                justifyContent: "flex-end",
                                alignItems: "center",
                            }}
                        >
                            <CloseIcon
                                onClick={(e) => this.props.handleClose(e)}
                                style={{
                                    color: "#6caad4",
                                    marginRight: "5px",
                                    backgroundColor: "#e3f1fc",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                }}
                            />

                        </div>
                        <div style={{marginTop:'20px'}}>
                            <label>Reasons:</label>
                            <select onChange={(e)=>this.setState({reason:e.target.value})} value={this.state.reason}>
                                <option value=""></option>
                                <option value={"CALL_BACK"}>Call Back</option>
                                <option value={"CONTACT_NUMBER_NOT_REACHABLE"}>Contact Number Not Reachable</option>
                                <option value={"CONTACT_NUMBER_SWITCHED_OFF"}>Contact Number Switched Off</option>
                                <option value={"WRONG_NUMBER"}>Wrong Number</option>
                                <option value={"PREFER_ONLINE_METHOD"}>Prefer Online Method</option>
                                <option value={"CUSTOMER_NOT_INTERESTED"}>Customer Not Interested</option>
                                <option value={"NO_CREDIT/DEBIT_CARD"}>No Credit/Debit Card</option>
                                <option value={"RECIPIENT_CONTACT_NUMBER"}>Recipient Contact Number</option>
                                <option value={"TEST_ORDER"}>Test Order</option>
                                <option value={"DUPLICATE_ORDER"}>Duplicate Order</option>
                                <option value={"CUSTOMER_REVISITED"}>Customer Revisited</option>
                                <option value={"LINK_SENT"}>Link Sent</option>
                                <option value={"ORDER_TAKEN"}>Order Taken</option>
                                <option value={"OTHER"}>Other</option>
                                <option value={"EMPTY_CART"}>Empty Cart</option>
                            </select>
                        </div>
                        <div style={{marginTop:'30px',flexDirection:'row',justifyContent:'space-between',width:'100%',display:'flex'}}>
                            <button onClick={()=>this.props.handleClose()} style={{marginLeft:'30px'}}>Cancel</button>
                            <button onClick={()=>this.submit()} style={{marginRight:'30px'}}>Ok</button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}