import React, { Component } from "react";
import { connect } from "react-redux";
import {withRouter} from "react-router-dom";
import {
    // createProductPromo,
    // updatePromo,
    // getPromoByID,
    updatePromoCodes,
    getAllProductPromo,
    createPromoCodes
} from "../../../../store/actions/catalogActions";
import {TextField} from "@material-ui/core";


class PromotionCodeEdit extends Component {
    render() {
        const promosList = Array.from(this.props.promosList);
        let {name,
            userEntered,
            requireEmailOrParty,
            useLimitPerCode,
            promoId,
            useLimitPerCustomer,} = this.state;

        return(
            <div id="content-main-section" className="leftonly">
                <div id="centerdiv" className="no-clear">
                    <div id="screenlet_1" className="screenlet">
                        <div className="screenlet-title-bar">
                            <ul>
                                <li className="h3">Edit Product Promos</li>
                            </ul>
                        </div>
                        <div id="screenlet_1_col" className="screenlet-body">
                            <form id="EditProductPromo" className="basic-form"
                                  onSubmit={this.submitForm}
                                  noValidate="novalidate">
                                <table className="basic-table" cellSpacing="0">
                                    <tbody>
                                    <tr>
                                        <td className="label"><label id="EditProductPromo_promoName_title"
                                                                     htmlFor="EditProductPromo_promoName">Product Promo Code Id</label></td>
                                        <td><input value={this.state.promoCodeId} onChange={this.handleChange} maxLength="60" name="name"
                                                  onChange={(e)=>this.setState({promoCodeId:e.target.value})} size="40" type="text"/></td>
                                    </tr>
                                    <tr>
                                        <td className="label"><label id="EditProductPromo_promoText_title"
                                                                     htmlFor="EditProductPromo_promoText">Product Promo Id</label></td>
                                        <td>
                                            <select id="EditProductPromo_userEntered" name="userEntered" size="1"
                                                    name="promoId"
                                                    value={promoId} onChange={(e)=>this.setState({promoId:e.target.value})}
                                            >
                                                <option></option>
                                                {promosList && promosList.map((promo)=>{
                                                   return(<option value={promo._id}>{promo.promoText}</option>);
                                                })}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label"><label id="EditProductPromo_userEntered_title"
                                                                     htmlFor="EditProductPromo_userEntered">User
                                            Entered</label></td>
                                        <td>
                           <span className="ui-widget">
                              <select id="EditProductPromo_userEntered" name="userEntered" size="1"
                                      name="userEntered"
                                      value={userEntered} onChange={this.handleChange}
                              >
                                 <option selected="selected" value={true}>Y</option>
                                 <option value={false}>N</option>
                              </select>
                           </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label"><label id="EditProductPromo_showToCustomer_title"
                                                                     htmlFor="EditProductPromo_showToCustomer">Require Email or Party</label></td>
                                        <td>
                           <span className="ui-widget">
                              <select id="EditProductPromo_showToCustomer"
                                      name="requireEmailOrParty"
                                      value={requireEmailOrParty} onChange={this.handleChange}
                                      size="1">
                                 <option value={true}>Y</option>
                                 <option value={false}>N</option>
                              </select>
                           </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label"><label id="EditProductPromo_useLimitPerCustomer_title"
                                                                     htmlFor="EditProductPromo_useLimitPerCustomer">Use
                                            Limit Per Code</label></td>
                                        <td><input id="EditProductPromo_useLimitPerCustomer"
                                                   name="useLimitPerCode"
                                                   value={useLimitPerCode} onChange={this.handleChange}
                                                   size="6" type="text"/></td>
                                    </tr>
                                    <tr>
                                        <td className="label"><label id="EditProductPromo_useLimitPerPromotion_title"
                                                                     htmlFor="EditProductPromo_useLimitPerPromotion">Use
                                            Limit Per Customer</label></td>
                                        <td><input id="EditProductPromo_useLimitPerPromotion"
                                                   name="useLimitPerCustomer"
                                                   value={useLimitPerCustomer} onChange={this.handleChange}
                                                   size="6" type="text"/></td>
                                    </tr>
                                    <tr>
                                        <td className="label"><label id="EditProductPromo_billbackFactor_title"
                                                                     htmlFor="EditProductPromo_billbackFactor">From Date</label></td>
                                        <td>
                                            <TextField
                                                style={{marginVartical: 0}}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="fromDate"
                                                id="vendorStartTime"
                                                type="date"
                                                value={this.state.fromDate}
                                                onChange={(e) => {
                                                    this.setState({fromDate: e.target.value});
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label"><label id="EditProductPromo_overrideOrgPartyId_title"
                                                                     htmlFor="EditProductPromo_overrideOrgPartyId">Through Date</label></td>
                                        <td>
                                            <TextField
                                                style={{marginVartical: 0}}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                id="vendorStartTime"
                                                type="date"
                                                name="throughDate"
                                                value={this.state.throughDate}
                                                onChange={(e) => {
                                                    this.setState({throughDate: e.target.value});
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label"></td>
                                        <td><input className="smallSubmit" type="submit"
                                                   value={this.state._id? 'Update':'Create'}/></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    handleChange = (e)=>{
        this.setState({[e.target.name]:e.target.value});
    }
    submitForm = (e)=>{
        e.preventDefault();
        const promoCodeId=this.props.location.search.split(":")[1];
        if (promoCodeId) {
            let data = this.state;
            this.props.updatePromoCodes(data)
        } else {
           // console.log(this.state);
            this.props.createPromoCodes(this.state);
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            _id:'',
            promoCodeId:'',
            promoId:'',
            requireEmailOrParty:false,
            userEntered:false,
            useLimitPerCode:'',
            useLimitPerCustomer:'',
            fromDate:'',
            throughDate:'',
        }
    }
    async componentDidMount() {
        await this.props.getAllProductPromo();
        if(this.props.location.search){
            const promoCodeId=this.props.location.search.split(":")[1];
            if(promoCodeId) {
                const promoCodesList = Array.from(this.props?.promoCodesList);
                var promoCode = promoCodesList.filter((promoCodesListMethod)=>{
                    return  promoCodesListMethod.promoCodeId==promoCodeId;
                })
                if (promoCode) {
                    const{_id,promoCodeId,promoId,requireEmailOrParty,userEntered,useLimitPerCode,useLimitPerCustomer,fromDate,throughDate}=promoCode[0];
                    console.log(fromDate.split('T')[0]);
                    this.setState({
                        _id,promoCodeId,promoId,requireEmailOrParty,userEntered,useLimitPerCode,useLimitPerCustomer,fromDate:fromDate.split('T')[0],throughDate:throughDate.split('T')[0]
                    });
                }
            }
        }

    }
}
const mapStateToProps = (state) => (
    ()=> {
        return {
            promoCodesList: state?.catalog?.promoCodesReducer.promoCodesList,
            promosList: state?.catalog?.promos.promosList,
        }});

const mapDispatchToProps = (dispatch, router) => ({
    getAllProductPromo:()=>dispatch(getAllProductPromo()),
    updatePromoCodes:(data)=>dispatch(updatePromoCodes(data,router)),
    createPromoCodes:(data)=>dispatch(createPromoCodes(data,router)),
});
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PromotionCodeEdit)
);
