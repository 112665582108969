import React, {Component} from  'react'
import EditProductNav from "./EditProductNav";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import {connect} from "react-redux";
import {
    createProductPrice,
    getProduct,
    updateProduct,
    updateProductPrice
} from "../../../../store/actions/catalogActions";

class EditProductCategories extends Component {
    render() {
        const query = queryString.parse(this.props.location.search);
        const product = query.productId;
        return (
            <>
            <div id="content-main-section" className="leftonly">
                <div id="centerdiv" className="no-clear">
                    <EditProductNav
                        product={product}
                        expandAbles={
                            <React.Fragment>
                                <li class="buttontext expand-all">
                                    <a
                                        href
                                        onClick={(e) => this.toggleA(e, "expand")}
                                        class="buttontext expand-all"
                                    >
                                        Expand all
                                    </a>
                                </li>
                                <li class="buttontext collapse-all">
                                    <a
                                        href
                                        onClick={(e) => this.toggleA(e, "collapse")}
                                        class="buttontext collapse-all"
                                    >
                                        Collapse all
                                    </a>
                                </li>
                            </React.Fragment>
                        }
                        // mode={mode}
                    />
                    <div className="button-bar tab-bar">
                        <p>Category Members For: Christmas Fortune [ID:CAKE100]</p>
                    </div>
                    <div id="screenlet_1" className="screenlet">
                        <div className="screenlet-title-bar">
                            <ul>
                                <li className="h3">Edit Product Categories</li>
                            </ul>
                        </div>
                        <div id="screenlet_1_col" className="screenlet-body">
                            <div className="nav-pager">

                                <ul>
                                    <li className="nav-first-disabled">First</li>
                                    <li className="nav-previous-disabled">Previous</li>
                                    <li className="nav-page-select">
                                        Page
                                        <select name="page" size="1"
                                                onChange="submitPagination(this, '/catalog/control/EditProductCategories?productId=CAKE100&amp;VIEW_SIZE_1=200&amp;VIEW_INDEX_1='+this.value)">
                                            <option selected="selected" value="0">1</option>
                                        </select>
                                    </li>
                                    <li className="nav-next-disabled">Next</li>
                                    <li className="nav-last-disabled">Last</li>
                                    <li className="nav-pagesize">
                                        <select name="pageSize" size="1"
                                                onChange="submitPagination(this, '/catalog/control/EditProductCategories?productId=CAKE100&amp;VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')">
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option selected="selected" value="200">200</option>
                                        </select>
                                        Items per page
                                    </li>
                                    <li className="nav-displaying">Displaying 1 - 2 of 2</li>
                                </ul>
                            </div>
                            <table className="basic-table" cellSpacing="0">
                                <tbody>
                                <tr className="header-row">
                                    <td><label id="UpdateProductCategoryMember_productCategoryId_title"
                                               htmlFor="UpdateProductCategoryMember_productCategoryId">Category
                                        [ID]</label></td>
                                    <td><label id="UpdateProductCategoryMember_fromDate_title"
                                               htmlFor="UpdateProductCategoryMember_fromDate">From Date Time</label>
                                    </td>
                                    <td><label id="UpdateProductCategoryMember_thruDate_title"
                                               htmlFor="UpdateProductCategoryMember_thruDate">Through Date
                                        Time</label> - <label id="UpdateProductCategoryMember_comments_title"
                                                              htmlFor="UpdateProductCategoryMember_comments">Comments</label> - <label
                                        id="UpdateProductCategoryMember_sequenceNum_title"
                                        htmlFor="UpdateProductCategoryMember_sequenceNum">Sequence Num</label> - <label
                                        id="UpdateProductCategoryMember_quantity_title"
                                        htmlFor="UpdateProductCategoryMember_quantity">Quantity</label> - <label
                                        id="UpdateProductCategoryMember_submitButton_title"
                                        htmlFor="UpdateProductCategoryMember_submitButton">Update</label></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>gifts&amp;#x2f;al-barsha-dubai <a className="buttontext" title="Category [ID]"
                                                                          href="/catalog/control/EditCategory?productCategoryId=gifts%2Fal-barsha-dubai"> gifts/al-barsha-dubai</a>
                                    </td>
                                    <td>27-11-2017 16:41:43</td>
                                    <td>
                                        <form id="UpdateProductCategoryMember_o_0" className="basic-form"
                                              action="/catalog/control/updateProductToCategory" method="post"
                                              name="UpdateProductCategoryMember_o_0"><input
                                            id="UpdateProductCategoryMember_productCategoryId_o_0"
                                            name="productCategoryId" type="hidden" value="gifts/al-barsha-dubai"/>
                                            <input id="UpdateProductCategoryMember_productId_o_0" name="productId"
                                                   type="hidden" value="CAKE100"/> <input
                                                id="UpdateProductCategoryMember_fromDate_o_0" name="fromDate"
                                                type="hidden" value="27-11-2017 16:41:43"/> <span
                                                className="view-calendar"> <input
                                                id="UpdateProductCategoryMember_thruDate_o_0_i18n"
                                                title="dd-MM-yyyy HH:mm:ss" maxLength="30" name="thruDate_i18n"
                                                size="25" type="text"/> <input
                                                id="UpdateProductCategoryMember_thruDate_o_0" className="hasDatepicker heightWith"
                                                title="dd-MM-yyyy HH:mm:ss" maxLength="30" name="thruDate" size="25"
                                                type="text"/><button className="ui-datepicker-trigger"
                                                                     type="button"></button> <input name=""
                                                                                                    type="hidden"
                                                                                                    value="Timestamp"/> </span>
                                            <textarea id="UpdateProductCategoryMember_comments_o_0" cols="60"
                                                      name="comments"
                                                      rows="3">via Populate Category by suhail@fnp.com</textarea> <input
                                                id="UpdateProductCategoryMember_sequenceNum_o_0" name="sequenceNum"
                                                size="6" type="text" value="1080"/> <input
                                                id="UpdateProductCategoryMember_quantity_o_0" name="quantity" size="25"
                                                type="text"/> <input className="smallSubmit" name="submitButton"
                                                                     type="submit" value="Update"/></form>
                                    </td>
                                    <td>
                                        <form action="/catalog/control/removeProductFromCategory" method="post"
                                              name="UpdateProductCategoryMember_o_0_0_o_deleteLink"><input
                                            name="fromDate" type="hidden" value="27-11-2017 16:41:43"/><input
                                            name="productCategoryId" type="hidden" value="gifts/al-barsha-dubai"/><input
                                            name="VIEW_SIZE_1" type="hidden" value="200"/><input name="productId"
                                                                                                 type="hidden"
                                                                                                 value="CAKE100"/><input
                                            name="VIEW_INDEX_1" type="hidden" value="0"/></form>
                                        <a href="/" className="buttontext">Delete</a>
                                    </td>
                                </tr>
                                <tr className="alternate-row">
                                    <td>gifts&amp;#x2f;marina-dubai <a className="buttontext" title="Category [ID]"
                                                                       href="/catalog/control/EditCategory?productCategoryId=gifts%2Fmarina-dubai"> gifts/marina-dubai</a>
                                    </td>
                                    <td>27-11-2017 16:48:08</td>
                                    <td>
                                        <form id="UpdateProductCategoryMember_o_1" className="basic-form"
                                              action="/catalog/control/updateProductToCategory" method="post"
                                              name="UpdateProductCategoryMember_o_1"><input
                                            id="UpdateProductCategoryMember_productCategoryId_o_1"
                                            name="productCategoryId" type="hidden" value="gifts/marina-dubai"/> <input
                                            id="UpdateProductCategoryMember_productId_o_1" name="productId"
                                            type="hidden" value="CAKE100"/> <input
                                            id="UpdateProductCategoryMember_fromDate_o_1" name="fromDate" type="hidden"
                                            value="27-11-2017 16:48:08"/> <span className="view-calendar"> <input
                                            id="UpdateProductCategoryMember_thruDate_o_1_i18n"
                                            title="dd-MM-yyyy HH:mm:ss" maxLength="30" name="thruDate_i18n" size="25"
                                            type="text"/> <input id="UpdateProductCategoryMember_thruDate_o_1"
                                                                 className="hasDatepicker heightWith"
                                                                 title="dd-MM-yyyy HH:mm:ss" maxLength="30"
                                                                 name="thruDate" size="25" type="text"/><button
                                            className="ui-datepicker-trigger" type="button"></button> <input name=""
                                                                                                             type="hidden"
                                                                                                             value="Timestamp"/> </span>
                                            <textarea id="UpdateProductCategoryMember_comments_o_1" cols="60"
                                                      name="comments"
                                                      rows="3">via Populate Category by suhail@fnp.com</textarea> <input
                                                id="UpdateProductCategoryMember_sequenceNum_o_1" name="sequenceNum"
                                                size="6" type="text" value="1080"/> <input
                                                id="UpdateProductCategoryMember_quantity_o_1" name="quantity" size="25"
                                                type="text"/> <input className="smallSubmit" name="submitButton"
                                                                     type="submit" value="Update"/></form>
                                    </td>
                                    <td>
                                        <form action="/catalog/control/removeProductFromCategory" method="post"
                                              name="UpdateProductCategoryMember_o_1_0_o_deleteLink"><input
                                            name="fromDate" type="hidden" value="27-11-2017 16:48:08"/><input
                                            name="productCategoryId" type="hidden" value="gifts/marina-dubai"/><input
                                            name="VIEW_SIZE_1" type="hidden" value="200"/><input name="productId"
                                                                                                 type="hidden"
                                                                                                 value="CAKE100"/><input
                                            name="VIEW_INDEX_1" type="hidden" value="0"/></form>
                                        <a href="/" className="buttontext">Delete</a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <div className="nav-pager">

                                <ul>
                                    <li className="nav-first-disabled">First</li>
                                    <li className="nav-previous-disabled">Previous</li>
                                    <li className="nav-page-select">
                                        Page
                                        <select name="page" size="1"
                                                onChange="submitPagination(this, '/catalog/control/EditProductCategories?productId=CAKE100&amp;VIEW_SIZE_1=200&amp;VIEW_INDEX_1='+this.value)">
                                            <option selected="selected" value="0">1</option>
                                        </select>
                                    </li>
                                    <li className="nav-next-disabled">Next</li>
                                    <li className="nav-last-disabled">Last</li>
                                    <li className="nav-pagesize">
                                        <select name="pageSize" size="1"
                                                onChange="submitPagination(this, '/catalog/control/EditProductCategories?productId=CAKE100&amp;VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')">
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option selected="selected" value="200">200</option>
                                        </select>
                                        Items per page
                                    </li>
                                    <li className="nav-displaying">Displaying 1 - 2 of 2</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div id="screenlet_2" className="screenlet">
                        <div className="screenlet-title-bar">
                            <ul>
                                <li className="h3">Add Product Categories</li>
                            </ul>
                        </div>
                        <div id="screenlet_2_col" className="screenlet-body">
                            <form id="AddProductCategoryMember" className="basic-form"
                                  action="/catalog/control/addProductToCategory" method="post"
                                  name="AddProductCategoryMember">
                                <input id="AddProductCategoryMember_productId" name="productId" type="hidden"
                                       value="CAKE100"/>
                                <table className="basic-table" cellSpacing="0">
                                    <tbody>
                                    <tr>
                                        <td className="label"><label
                                            id="AddProductCategoryMember_productCategoryId_title"
                                            htmlFor="AddProductCategoryMember_productCategoryId">Product Category
                                            ID</label></td>
                                        <td>
                                            <div id="3_lookupId_AddProductCategoryMember_productCategoryId_auto"></div>
                                            <span className="field-lookup"><input
                                                id="3_lookupId_AddProductCategoryMember_productCategoryId"
                                                className="ui-autocomplete-input" autoComplete="off"
                                                name="productCategoryId" size="25" type="text"/> <a
                                                id="3_lookupId_button"></a></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label"><label id="AddProductCategoryMember_fromDate_title"
                                                                     htmlFor="AddProductCategoryMember_fromDate">From
                                            Date</label></td>
                                        <td><span className="view-calendar"> <input
                                            id="AddProductCategoryMember_fromDate_i18n" title="dd-MM-yyyy HH:mm:ss"
                                            maxLength="30" name="fromDate_i18n" size="25" type="text"/> <input
                                            id="AddProductCategoryMember_fromDate" className="hasDatepicker heightWith"
                                            title="dd-MM-yyyy HH:mm:ss" maxLength="30" name="fromDate" size="25"
                                            type="text"/><button className="ui-datepicker-trigger"
                                                                 type="button"></button> <input name="" type="hidden"
                                                                                                value="Timestamp"/> </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label"><label id="AddProductCategoryMember_thruDate_title"
                                                                     htmlFor="AddProductCategoryMember_thruDate">Through
                                            Date</label></td>
                                        <td><span className="view-calendar"> <input
                                            id="AddProductCategoryMember_thruDate_i18n" title="dd-MM-yyyy HH:mm:ss"
                                            maxLength="30" name="thruDate_i18n" size="25" type="text"/> <input
                                            id="AddProductCategoryMember_thruDate" className="hasDatepicker heightWith"
                                            title="dd-MM-yyyy HH:mm:ss" maxLength="30" name="thruDate" size="25"
                                            type="text"/><button className="ui-datepicker-trigger"
                                                                 type="button"></button> <input name="" type="hidden"
                                                                                                value="Timestamp"/> </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label"><label id="AddProductCategoryMember_comments_title"
                                                                     htmlFor="AddProductCategoryMember_comments">Comments</label>
                                        </td>
                                        <td><textarea id="AddProductCategoryMember_comments" cols="60" name="comments"
                                                      rows="3"></textarea></td>
                                    </tr>
                                    <tr>
                                        <td className="label"><label id="AddProductCategoryMember_sequenceNum_title"
                                                                     htmlFor="AddProductCategoryMember_sequenceNum">Sequence
                                            Num</label></td>
                                        <td><input id="AddProductCategoryMember_sequenceNum" name="sequenceNum" size="6"
                                                   type="text"/></td>
                                    </tr>
                                    <tr>
                                        <td className="label"><label id="AddProductCategoryMember_quantity_title"
                                                                     htmlFor="AddProductCategoryMember_quantity">Quantity</label>
                                        </td>
                                        <td><input id="AddProductCategoryMember_quantity" name="quantity" size="25"
                                                   type="text"/></td>
                                    </tr>
                                    <tr>
                                        <td className="label"></td>
                                        <td colSpan="4"><input className="smallSubmit" name="submitButton" type="submit"
                                                               value="Add"/></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        <p>Read <a href="https://html-online.com/articles/">HTML</a> articles in our blog section to learn everything
            about web design and development. You will find great tips.</p>
                </>
        )
    }
    constructor() {
        super();
        this.state = {

        }
    }
}

const mapStateToProps = (state) => ({
    submitted: state?.catalog?.products?.submitted,
    categories: state?.catalog?.categories?.active,
});

const mapDispatchToProps = (dispatch, router) => ({
    createProductPrice:(data)=> dispatch(createProductPrice(data,router)),
    updateProduct:(data)=> dispatch(updateProduct(data,router)),
    getProduct:(data)=> dispatch(getProduct(data)),
    updateProductPrice:(data)=> dispatch(updateProductPrice(data, router)),

});
export default withRouter(
    connect(mapStateToProps,mapDispatchToProps)(EditProductCategories)
);
