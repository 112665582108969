import React, {Component} from "react";
import '../../styles.scss';
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import {
    createFeatureCategory,
    searchFeatureCategory,
    getAllFeatureCategory, getFeatureCategroy, updateFeatureCategory, getAllFeatureTypes, getAllFeatures
} from "../../../../store/actions/catalogActions";
import queryString from "query-string";

class EditFeatureCategories extends Component {
    render() {
        let {id, parent, description, feature_categories} = this.state;
        return (
            this.props.new ?
                <div>

                    <div>
                        <ul style={{display: "flex", listStyle: "none"}}>
                            <a href='/catalog/control/EditFeatureTypes'
                               style={{textDecoration: 'none'}}>
                                <li className="featureLi">Feature Type</li>
                            </a>
                            <a href='/catalog/control/EditFeatureCategories'
                               style={{textDecoration: 'none'}}>
                                <li className="featureLi">Feature Category</li>
                            </a>
                            <a href='/catalog/control/EditFeatureGroups'
                               style={{textDecoration: 'none'}}>
                                <li className="featureLi">Feature Group</li>
                            </a>
                            <a href='/catalog/control/EditFeatureInterActions'
                               style={{textDecoration: 'none'}}>
                                <li className="featureLi">Feature interAction</li>
                            </a>
                        </ul>
                    </div>

                    <div style={{marginTop: "1em"}} className="screenlet-title-bar">
                        <ul>
                            <li className="h3">Edit Feature Type</li>
                        </ul>
                    </div>

                    <div style={{backgroundColor: 'white'}}>
                        <form onSubmit={this.handleSubmit}>
                            <table>
                                <tbody>
                                <tr>
                                    <td style={{textAlign: "right"}}>Product Feature Type Id</td>
                                    <td><input type="text" value={id} name="id" onChange={this.handleChange}/></td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: "right"}}>Product Parent Category</td>
                                    <td><select name="parent" value={parent} onChange={this.handleChange}>
                                        <option value="">--</option>
                                        {this.props.all_feature_categories?.map(one => (
                                            <option value={one._id}>{one.id}</option>
                                        ))}
                                    </select></td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: "right"}}>Description</td>
                                    <td><input value={description} type="text" name="description"
                                               onChange={this.handleChange}/></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <button type="submit">Submit</button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>

                </div>
                :
                <div>
                    <div>
                        <ul style={{display: "flex", listStyle: "none"}}>
                            <a href='/catalog/control/EditFeatureTypes'
                               style={{textDecoration: 'none'}}>
                                <li className="featureLi">Feature Type</li>
                            </a>
                            <a href='/catalog/control/EditFeatureCategories'
                               style={{textDecoration: 'none'}}>
                                <li className="featureLi">Feature Category</li>
                            </a>
                            <a href='/catalog/control/EditFeatureGroups'
                               style={{textDecoration: 'none'}}>
                                <li className="featureLi">Feature Group</li>
                            </a>
                            <a href='/catalog/control/EditFeatureInterActions'
                               style={{textDecoration: 'none'}}>
                                <li className="featureLi">Feature interAction</li>
                            </a>
                        </ul>
                    </div>
                    <div style={{marginTop: "1em"}}>
                        <h1>Edit Product Feature Categories</h1>
                    </div>
                    <div style={{marginTop: "1em"}}>
                        <button className="h3"><a style={{textDecoration: 'none'}}
                                                  href="/catalog/control/CreateProductFeature">New Feature Category</a>
                        </button>
                    </div>

                    <div style={{marginTop: "1em"}} className="screenlet-title-bar">
                        <ul>
                            <li className="h3">Search Options</li>
                        </ul>
                    </div>

                    <div style={{backgroundColor: "white", padding: "1em"}}>
                        <form onSubmit={this.onClickFind}>
                            <table>
                                <tbody>
                                <tr>
                                    <td><label className="h3" style={{textAlign: "right"}}> ID </label></td>
                                    <td><select className="selectBox" name="idFilterType" onChange={this.handleChange}>
                                        <option value="equals">Equals</option>
                                        <option value="beginWith">Begins With</option>
                                        <option defaultValue value="contains">Contains</option>
                                        <option value="empty">Is Empty</option>
                                        <option value="notEqual">Not Equal</option>
                                    </select></td>
                                    <td><input name="id" type="text" value={id} onChange={this.handleChange}/></td>
                                    <td><input type="checkbox"/></td>
                                    <td><label>ignore case</label></td>
                                </tr>

                                <tr>
                                    <td><label className="h3" style={{textAlign: "right"}}> Description </label></td>
                                    <td><select className="selectBox" name="descriptionFilterType"
                                                onChange={this.handleChange}>
                                        <option value="equals">Equals</option>
                                        <option value="beginWith">Begins With</option>
                                        <option defaultValue value="contains">Contains</option>
                                        <option value="empty">Is Empty</option>
                                        <option value="notEqual">Not Equal</option>
                                    </select></td>
                                    <td><input type="text" name="description" onChange={this.handleChange}/></td>
                                    <td><input type="checkbox"/></td>
                                    <td><label>ignore case</label></td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td>
                                        <button className="h3" type="submit">Find</button>
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </form>
                    </div>

                    <div style={{marginTop: '1em'}}>

                        <div id="screenlet_1" className="screenlet">
                            <div id="screenlet_1_col" className="screenlet-body no-padding">
                                <h3>Search Results</h3>
                                <div id="search-results">
                                    <div className="nav-pager">
                                        <ul>
                                            <li className="nav-first-disabled">First</li>
                                            <li className="nav-previous-disabled">Previous</li>
                                            <li className="nav-page-select">
                                                Page
                                                <select
                                                    name="page"
                                                    size="1"
                                                    onChange="submitPagination(this, '/catalog/control/FindCatalog?noConditionFind=Y&amp;VIEW_SIZE_1=200&amp;VIEW_INDEX_1='+this.value)"
                                                >
                                                    <option defaultValue value="0">
                                                        1
                                                    </option>
                                                </select>
                                            </li>
                                            <li className="nav-next-disabled">Next</li>
                                            <li className="nav-last-disabled">Last</li>
                                            <li className="nav-pagesize">
                                                <select
                                                    name="pageSize"
                                                    size="1"
                                                    onChange="submitPagination(this, '/catalog/control/FindCatalog?noConditionFind=Y&amp;VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')"
                                                >
                                                    <option value="20">20</option>
                                                    <option value="30">30</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option defaultValue value="200">
                                                        200
                                                    </option>
                                                </select>
                                                Items per page
                                            </li>
                                            <li className="nav-displaying">Displaying 1 - 20 of 20</li>
                                        </ul>
                                    </div>
                                    <table className="basic-table hover-bar" cellSpacing="0">
                                        <tbody>
                                        <tr className="header-row-2">
                                            <td>
                                                ID
                                            </td>
                                            <td>
                                                Description
                                            </td>
                                            <td>
                                                Update
                                            </td>
                                        </tr>
                                        {feature_categories?.map((one, index) => (
                                            <tr>
                                                <td><Link onClick={() => this.props.getOne(one._id)}
                                                          to={`/catalog/control/EditFeatureCategoryFeatures?id=${one._id}`}>{one.id}</Link>
                                                </td>
                                                <td><input type="text"
                                                           onChange={(e) => this.handlePartialSubmit(one, index, e)}
                                                           value={one.description}/></td>
                                                <td>
                                                    <button onClick={() => this.updateDescription(one)}>Update</button>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    <div className="nav-pager">
                                        <ul>
                                            <li className="nav-first-disabled">First</li>
                                            <li className="nav-previous-disabled">Previous</li>
                                            <li className="nav-page-select">
                                                Page
                                                <select name="page" size="1">
                                                    <option defaultValue value="0">
                                                        1
                                                    </option>
                                                </select>
                                            </li>
                                            <li className="nav-next-disabled">Next</li>
                                            <li className="nav-last-disabled">Last</li>
                                            <li className="nav-pagesize">
                                                <select name="pageSize" size="1">
                                                    <option value="20">20</option>
                                                    <option value="30">30</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option defaultValue value="200">
                                                        200
                                                    </option>
                                                </select>
                                                Items per page
                                            </li>
                                            <li className="nav-displaying">Displaying 1 - 20 of 20</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
        );
    }

    constructor(props) {
        super(props);

        this.state = {
            id: '',
            description: "",
            parent: "",
            idFilterType: 'equals',
            descriptionFilterType: 'equals',
            feature_categories: []
        }
    }

    componentDidMount() {
        const query = queryString.parse(this.props.location.search);
        const id = query.id;
        if (this.props.new && id) {
            this.props.one(id);
        }
        this.props.all();

    }

    componentWillReceiveProps(newProps, nextContext) {
        const query = queryString.parse(this.props.location.search);
        const id = query.id;
        if (newProps.feature_categroy && id) {
            this.setState({
                id: newProps.feature_categroy.id,
                description: newProps.feature_categroy.description,
                parent: newProps.feature_categroy.ParentType?._id
            })
        }
        if (newProps.feature_categories.length) {
            this.setState({feature_categories: newProps.feature_categories})
        }
    }

    handlePartialSubmit = (element, index, e) => {
        let {feature_categories} = this.state;
        feature_categories[index].description = e.target.value;
        this.setState({feature_categories});
    }

    updateDescription = async (element) => {
        let {feature_categories} = this.state;
        let {id, parent, description} = this.state;
        await this.props.update(element._id, {description: element.description});
        this.props.all();

    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const query = queryString.parse(this.props.location.search);
        const _id = query.id;
        if (_id) {
            let {id, parent, description} = this.state;
            this.props.update(_id, {id, parent, description});
        } else {
            let {id, parent, description} = this.state;
            let obj = {id, description};
            if (parent !== '')
                obj = {...obj, parent};
            this.props.save(obj);
        }
    };

    onClickFind = (e) => {
        e.preventDefault();
        const {id, description, idFilterType, descriptionFilterType} = this.state;
        let data = {};
        data = id ? {...data, id: id} : data;
        data = description ? {...data, description: description} : data;
        data = idFilterType ? {...data, idFilterType: idFilterType} : data;
        data = descriptionFilterType ? {...data, descriptionFilterType: descriptionFilterType} : data;
        this.props.search(data);
    };

}


const mapStateToProps = (state) => ({
    feature_categories: state?.catalog?.catalogs?.feature_categories,
    all_feature_categories: state?.catalog?.catalogs?.all_feature_categories,
    feature_categroy: state?.catalog?.catalogs?.feature_categroy,
});

const mapDispatchToProps = (dispatch, router) => ({
    save: (data) => dispatch(createFeatureCategory(data, router)),
    search: (data) => dispatch(searchFeatureCategory(data, router)),
    all: (data) => dispatch(getAllFeatureCategory(data, router)),
    getOne: (data) => dispatch(getFeatureCategroy(data, router)),
    update: (id, data) => dispatch(updateFeatureCategory(id, data, router)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(EditFeatureCategories)
);
