import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EditProductNav from "./EditProductNav";
import queryString from "query-string";
import DataTable from "../../../components/DataTable";
import {
  addIdentifications,
  updateIdentifications,
  getAllActiveIdentifications,
} from "../../../../store/actions/catalogActions";
export class EditProductGoodIdentifications extends Component {
  state = {
    flag: false,
    idType: "",
    idValue: "",
    idsArray: [],
  };
  componentDidMount = async () => {
    const query = queryString.parse(this.props.location.search);
    const product = query.productId;
    let obj;
    this.props.getAllActiveIdentifications(product);
  };
  handleUpdateSelect = (content) => {};
  handleBack = () => {};
  handleDelete = async (content) => {};
  handleContentCreate = () => {};
  render() {
    const query = queryString.parse(this.props.location.search);
    const product = query.productId;
    return (
      <React.Fragment>
        <div id="content-main-section" class="leftonly">
          <div id="centerdiv" class="no-clear">
            <EditProductNav product={product} />
            <div id="screenlet_1" class="screenlet">
              <div class="screenlet-title-bar">
                <ul>
                  <li class="h3">Edit Product Content</li>
                </ul>
              </div>
              <div id="screenlet_1_col" class="screenlet-body">
                <DataTable>
                  <table class="basic-table" cellspacing="0">
                    <tbody>
                      <tr class="header-row">
                        <td>
                          <label
                            id="ListProductContentInfos_editProductContentInfo_title"
                            for="ListProductContentInfos_editProductContentInfo"
                          >
                            ID TYPE
                          </label>
                        </td>
                        <td>
                          <label
                            id="ListProductContentInfos_productContentTypeId_title"
                            for="ListProductContentInfos_productContentTypeId"
                          >
                            Id Value - update
                          </label>
                        </td>
                        <td>
                          <label
                            id="ListProductContentInfos_fromDate_title"
                            for="ListProductContentInfos_fromDate"
                          ></label>
                        </td>
                        <td></td>
                      </tr>
                      {/* //  {this.props.content?.map((content) => ( */}
                      {this.props.identifications?.map((id, index) => (
                        <tr>
                          <td>{id.idType}</td>
                          <td>
                            <input
                              id="3_lookupId_AddProductContentAssoc_contentId"
                              class="ui-autocomplete-input"
                              autocomplete="off"
                              name="contentId"
                              value={this.state.idsArray[index]}
                              onChange={(e) => {
                                let idsArray = this.state.idsArray;
                                idsArray[index] = e.target.value;
                                this.setState({ idsArray });
                              }}
                              size="25"
                              type="text"
                            />
                          </td>
                          <td>
                            <button onClick={(e) => this.update(id, index)}>
                              update
                            </button>
                          </td>
                          <td>
                            <button onClick={(e) => this.delete(id, index)}>
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}

                      {/* ))} */}
                    </tbody>
                  </table>
                </DataTable>
              </div>
              <div class="screenlet-title-bar">
                <ul>
                  <li class="h3">Edit Product Content</li>
                </ul>
              </div>
              <div id="screenlet_1_col" class="screenlet-body">
                <table class="basic-table" cellspacing="0">
                  <tbody>
                    <tr>
                      <td class="label">
                        <label
                          id="AddProductContentAssoc_contentId_title"
                          for="AddProductContentAssoc_contentId"
                        >
                          ID Type
                        </label>
                      </td>

                      <td>
                        <select
                          value={this.state.type}
                          onChange={(e) =>
                            this.setState({ idType: e.target.value })
                          }
                          id="AddProductContentAssoc_productContentTypeId"
                          name="productContentTypeId"
                          size="1"
                        >
                          <option value="EAN">EAN</option>
                          <option value="GOOGLE_ID">Google Id</option>
                          <option value="GOOGLE_ID_DE">Google Id de</option>
                          <option value="GOOGLE_ID_EN_GB">
                            Google Id en_GB
                          </option>
                          <option value="GOOGLE_ID_EN_US">
                            Google Id en_US
                          </option>
                          <option value="HS_CODE">
                            Harmonized System Codes (HS Code)
                          </option>
                          <option value="ISBN">ISBN</option>
                          <option value="LIBRARY">Library of Congress</option>
                          <option value="MANUFACTURER">
                            Manufacturer (Model) Number
                          </option>
                          <option value="MODEL">Model Year</option>
                          <option value="OTHER">Other</option>
                          <option value="REPLACEMENT_VALUE">
                            Replacement value for partyId in the invoice export
                            function in accounting
                          </option>
                          <option value="SKU">SKU</option>
                          <option value="UPCA">UPCA</option>
                          <option value="UPSA">UPSA</option>
                        </select>
                      </td>
                    </tr>

                    <tr>
                      <td class="label">
                        <label
                          id="AddProductContentAssoc_contentId_title"
                          for="AddProductContentAssoc_contentId"
                        >
                          ID Value
                        </label>
                      </td>

                      <td>
                        <input
                          id="3_lookupId_AddProductContentAssoc_contentId"
                          class="ui-autocomplete-input"
                          autocomplete="off"
                          name="contentId"
                          value={this.state.idValue}
                          onChange={(e) =>
                            this.setState({ idValue: e.target.value })
                          }
                          size="25"
                          type="text"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <button onClick={(e) => this.createIds()}>
                          Create
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  componentDidUpdate(prevProps) {
    if (prevProps.identifications !== this.props.identifications) {
      let idsArray = [];
      for (let i = 0; i < this.props.identifications.length; i++) {
        idsArray.push(this.props.identifications[i].idValue);
      }
      this.setState({ idsArray });
    }
  }

  createIds = () => {
    let { idType, idValue } = this.state;
    const query = queryString.parse(this.props.location.search);
    const productId = query.productId;
    let dataObj = { idType, idValue, productId };
    this.props.addIdentifications(dataObj);
    this.setState({ idType: "", idValue: "" });
  };
  update = (ID, index) => {
    let idType = ID.idType;
    let idValue = this.state.idsArray[index];
    let productId = ID.productId;
    let id = ID._id;
    let obj = { id, productId, idValue, idType };
    this.props.updateIdentifications(obj);
  };
  delete = (ID, index) => {
    let productId = ID.productId;
    let id = ID._id;
    let obj = { id, productId, isActive: false, isDeleted: true };
    this.props.updateIdentifications(obj);
  };
}

const mapStateToProps = (state) => ({
  token: state?.auth?.token,
  identifications: state?.catalog?.identificationReducer.content,
});

const mapDispatchToProps = (dispatch, router) => ({
  addIdentifications: (data) => dispatch(addIdentifications(data, router)),
  updateIdentifications: (data) =>
    dispatch(updateIdentifications(data, router)),
  getAllActiveIdentifications: (id) =>
    dispatch(getAllActiveIdentifications(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditProductGoodIdentifications)
);
