import React, { Component } from 'react';
import { getRequest } from '../../../../store/services';

class BlockList extends Component {
    state = {
        list: [],
        rule: '',
        ruleName: '',
    }

    componentDidMount() {
        const token = localStorage.getItem('token');

        // getRequest("bulkCreate/allActive", null, true, {
        //     "access-token": token,
        // }).then((res) => {
        //     this.setState({ list: res.data })
        // });

        getRequest("geos/allActive/blockList", null, true, {
            "access-token": token,
        }).then((res) => {
            this.setState({ list: res.data })
        });
    }

    areaModalToggle = () => { };
    dateModalToggle = () => { };
    shippingModalToggle = () => { };
    timeSlotModalToggle = () => { };

    blockHandler = () => {}

    blockingList = () => {
        const formData = new FormData();
    }

    render() {
        return (
            <div className="bulk-block">
                <label htmlFor="" style={{ marginRight: '5px' }}>
                    <b>Select Rule:</b>
                </label>
                <select name="" id=""
                    value={this.state.rule}
                    onChange={e => this.setState({ rule: e.target.value })}
                >
                    <option value="">[Select]</option>
                    {this.state.list.map(b => {
                        return <option value={b._id} key={b._id}>{b.ruleName}</option>
                    })}
                </select>

                <table>
                    <thead>
                        <tr>
                            <th>Area/Group/Pin</th>
                            <th>Date</th>
                            <th>Shipping Method</th>
                            <th>Timeslot</th>
                            <th>Rule Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <button className="btn-bulk" onClick={this.areaModalToggle}>Choose</button>
                            </td>
                            <td>
                                <button className="btn-bulk" onClick={this.dateModalToggle}>Choose</button>
                            </td>
                            <td>
                                <button className="btn-bulk" onClick={this.shippingModalToggle}>Choose</button>
                            </td>
                            <td>
                                <button className="btn-bulk" onClick={this.timeSlotModalToggle}>Choose</button>
                            </td>
                            <td>
                                <input type="text" name="rule_name"
                                    value={this.state.ruleName}
                                    onChange={e => this.setState({ ruleName: e.target.value })}
                                />
                            </td>
                            <td>
                                <button className="btn-bulk" onClick={this.blockHandler}>Block Now</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default BlockList;