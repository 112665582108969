import React, {Component} from "react";
import '../../styles.scss';
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import {
    createFeature,
    createInteraction,
    getAllFeatures, getOneFeature,
    searchInteraction
} from "../../../../store/actions/catalogActions";
import queryString from "query-string";

class EditFeatureCategories extends Component {
    render() {
        const {id, toId, descriptionFilterType, idFilterType} = this.state;
        return (
            this.props.new ?
                <div>

                    <div>
                        <ul style={{display: "flex", listStyle: "none"}}>
                            <a href='/catalog/control/EditFeatureTypes'
                               style={{textDecoration: 'none'}}>
                                <li className="featureLi">Feature Type</li>
                            </a>
                            <a href='/catalog/control/EditFeatureCategories'
                               style={{textDecoration: 'none'}}>
                                <li className="featureLi">Feature Category</li>
                            </a>
                            <a href='/catalog/control/EditFeatureGroups'
                               style={{textDecoration: 'none'}}>
                                <li className="featureLi">Feature Group</li>
                            </a>
                            <a href='/catalog/control/EditFeatureInterActions'
                               style={{textDecoration: 'none'}}>
                                <li className="featureLi">Feature interAction</li>
                            </a>
                        </ul>
                    </div>

                    <div style={{marginTop: "1em"}} className="screenlet-title-bar">
                        <ul>
                            <li className="h3">Edit Feature Type</li>
                        </ul>
                    </div>

                    <div style={{backgroundColor: 'white'}}>
                        <form onSubmit={this.handleSubmit}>
                            <table>
                                <tbody>
                                <tr>
                                    <td className="h3" style={{textAlign: "right"}}>Product Feature Id:</td>
                                    <td><select name="id" onChange={this.handleChange}>
                                        <option value=''>--</option>
                                        {this.props.features?.map(one => (
                                            <option value={one._id}>{one.id}</option>
                                        ))}
                                    </select></td>
                                </tr>
                                <tr>
                                    <td className="h3" style={{textAlign: "right"}}>Product Feature Id To:</td>
                                    <td><select name="toId" onChange={this.handleChange}>
                                        <option value=''>--</option>
                                        {this.props.features?.map(one => (
                                            <option value={one._id}>{one.id}</option>
                                        ))}
                                    </select></td>
                                </tr>
                                <tr>
                                    <td className="h3" style={{textAlign: "right"}}>Interaction Type:</td>
                                    <td><select name="relation" onChange={this.handleChange}>
                                        <option value="dependent feature">dependent features</option>
                                        <option value="incompatible feature">incompatible features</option>
                                    </select></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <button type="submit">Update</button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>

                </div>

                :

                <div>
                    <div>
                        <ul style={{display: "flex", listStyle: "none"}}>
                            <a href='/catalog/control/EditFeatureTypes'
                               style={{textDecoration: 'none'}}>
                                <li className="featureLi">Feature Type</li>
                            </a>
                            <a href='/catalog/control/EditFeatureCategories'
                               style={{textDecoration: 'none'}}>
                                <li className="featureLi">Feature Category</li>
                            </a>
                            <a href='/catalog/control/EditFeatureGroups'
                               style={{textDecoration: 'none'}}>
                                <li className="featureLi">Feature Group</li>
                            </a>
                            <a href='/catalog/control/EditFeatureInterActions'
                               style={{textDecoration: 'none'}}>
                                <li className="featureLi">Feature interAction</li>
                            </a>
                        </ul>
                    </div>
                    <div style={{marginTop: "1em"}}>
                        <h1>Edit Feature InterActions</h1>
                    </div>
                    <div style={{marginTop: "1em"}}>
                        <button className="h3"><a style={{textDecoration: 'none'}}
                                                  href="/catalog/control/EditFeatureInterAction">New Feature
                            InterActions</a></button>
                    </div>

                    <div style={{marginTop: "1em"}} className="screenlet-title-bar">
                        <ul>
                            <li className="h3">Search Options</li>
                        </ul>
                    </div>

                    <div style={{backgroundColor: "white", padding: "1em"}}>
                        <form onSubmit={this.onClickFind}>
                            <table>
                                <tbody>
                                <tr>
                                    <td><label className="h3" style={{textAlign: "right"}}> Product Feature Id </label>
                                    </td>
                                    <td><select name='idFilterType' onChange={this.handleChange} className="selectBox">
                                        <option value="equals">Equals</option>
                                        <option value="beginWith">Begins With</option>
                                        <option defaultValue value="contains">Contains</option>
                                        <option value="empty">Is Empty</option>
                                        <option value="notEqual">Not Equal</option>
                                    </select></td>
                                    <td><input name="id" onChange={this.handleChange} type="text"/></td>
                                    <td><input type="checkbox"/></td>
                                    <td><label>ignore case</label></td>
                                </tr>

                                <tr>
                                    <td><label className="h3" style={{textAlign: "right"}}> Product Feature Id
                                        To </label>
                                    </td>
                                    <td><select name="descriptionFilterType" onChange={this.handleChange}
                                                className="selectBox">
                                        <option value="equals">Equals</option>
                                        <option value="beginWith">Begins With</option>
                                        <option defaultValue value="contains">Contains</option>
                                        <option value="empty">Is Empty</option>
                                        <option value="notEqual">Not Equal</option>
                                    </select></td>
                                    <td><input name="toId" onChange={this.handleChange} type="text"/></td>
                                    <td><input type="checkbox"/></td>
                                    <td><label>ignore case</label></td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td>
                                        <button type="submit" className="h3">Find</button>
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </form>
                    </div>

                    <div style={{marginTop: '1em'}}>

                        <div id="screenlet_1" className="screenlet">
                            <div id="screenlet_1_col" className="screenlet-body no-padding">
                                <h3>Search Results</h3>
                                <div id="search-results">
                                    <div className="nav-pager">
                                        <ul>
                                            <li className="nav-first-disabled">First</li>
                                            <li className="nav-previous-disabled">Previous</li>
                                            <li className="nav-page-select">
                                                Page
                                                <select
                                                    name="page"
                                                    size="1"
                                                    onChange="submitPagination(this, '/catalog/control/FindCatalog?noConditionFind=Y&amp;VIEW_SIZE_1=200&amp;VIEW_INDEX_1='+this.value)"
                                                >
                                                    <option defaultValue value="0">
                                                        1
                                                    </option>
                                                </select>
                                            </li>
                                            <li className="nav-next-disabled">Next</li>
                                            <li className="nav-last-disabled">Last</li>
                                            <li className="nav-pagesize">
                                                <select
                                                    name="pageSize"
                                                    size="1"
                                                    onChange="submitPagination(this, '/catalog/control/FindCatalog?noConditionFind=Y&amp;VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')"
                                                >
                                                    <option value="20">20</option>
                                                    <option value="30">30</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option defaultValue value="200">
                                                        200
                                                    </option>
                                                </select>
                                                Items per page
                                            </li>
                                            <li className="nav-displaying">Displaying 1 - 20 of 20</li>
                                        </ul>
                                    </div>
                                    <table className="basic-table hover-bar" cellSpacing="0">
                                        <tbody>
                                        <tr className="header-row-2">
                                            <td>
                                                Product Feature Id
                                            </td>
                                            <td>
                                                Product Feature Id To
                                            </td>
                                            <td>
                                                Interaction Type
                                            </td>
                                            <td>
                                                Delete
                                            </td>
                                        </tr>
                                        {this.props.interactions?.map(one => (
                                            <tr>
                                                <td>{one.Feature?.description} <Link
                                                    onClick={() => this.props.getFeature(one.Feature?._id)}
                                                    to={`/catalog/control/CreateFeature?id=${one.Feature?.id}`}>[{one.Feature?.id}]</Link>
                                                </td>
                                                <td>{one.ToFeature?.description} <Link
                                                    onClick={() => this.props.getFeature(one.ToFeature?._id)}
                                                    to={`/catalog/control/CreateFeature?id=${one.ToFeature?.id}`}>[{one.ToFeature?.id}]</Link>
                                                </td>
                                                <td>{one.relation}</td>
                                                <td>
                                                    <button>delete</button>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    <div className="nav-pager">
                                        <ul>
                                            <li className="nav-first-disabled">First</li>
                                            <li className="nav-previous-disabled">Previous</li>
                                            <li className="nav-page-select">
                                                Page
                                                <select name="page" size="1">
                                                    <option defaultValue value="0">
                                                        1
                                                    </option>
                                                </select>
                                            </li>
                                            <li className="nav-next-disabled">Next</li>
                                            <li className="nav-last-disabled">Last</li>
                                            <li className="nav-pagesize">
                                                <select name="pageSize" size="1">
                                                    <option value="20">20</option>
                                                    <option value="30">30</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option defaultValue value="200">
                                                        200
                                                    </option>
                                                </select>
                                                Items per page
                                            </li>
                                            <li className="nav-displaying">Displaying 1 - 20 of 20</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
        );
    }

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            toId: '',
            idFilterType: 'equals',
            descriptionFilterType: 'equals',
            relation: 'dependent feature',
        }
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.create(this.state);
    };

    onClickFind = (e) => {
        e.preventDefault();
        const {id, toId, idFilterType, descriptionFilterType} = this.state;
        let data = {};
        data = id ? {...data, id: id} : data;
        data = toId ? {...data, toId: toId} : data;
        data = idFilterType ? {...data, idFilterType: idFilterType} : data;
        data = descriptionFilterType ? {...data, descriptionFilterType: descriptionFilterType} : data;
        this.props.search(data);
    };


    componentDidMount() {
        this.props.getAll();

    }
}

const mapStateToProps = (state) => ({
    features: state?.catalog?.catalogs?.features,
    interactions: state?.catalog?.catalogs?.interactions,
    feature: state?.catalog?.catalogs?.feature,
});

const mapDispatchToProps = (dispatch, router) => ({
    getAll: (data) => dispatch(getAllFeatures(data, router)),
    create: (data) => dispatch(createInteraction(data, router)),
    search: (data) => dispatch(searchInteraction(data, router)),
    getFeature: (id, data) => dispatch(getOneFeature(id, data, router))
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(EditFeatureCategories)
);
