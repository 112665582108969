import React from "react";
import { Modal } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {updateMessage} from "../../../store/actions/orderActions";
import {connect} from "react-redux";

class EditGiftMessage extends React.Component{
    state={
        from:'',
        message:'',
        to:'',
        id:'',
    }
    getData
    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.cartData!==this.props.cartData){

            let {from,to,message,id}= this.props.cartData;

            this.setState({from,to,message,id});
        }
    }

    submit=()=>{
        const {id,message,to,from}=this.state;
        this.props.updateMessage({id,giftCard_message:message,giftCard_receiver:to,giftCard_sender:from,transection:'Gift Message Updated'});
        this.props.handleClose(true);
    }

    render() {
        return(
            <Modal
                open={this.props.modalOpen}
                onClose={this.props.handleClose}
                //   style={{ overflow: "scroll" }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div
                    style={{
                        height: "400px",
                        width: "400px",
                        backgroundColor: "white",
                        position: "absolute",
                        top: "50px",
                        border: "2px solid white",
                        left: "600px",
                        alignItems: "center",
                        display: "flex",
                        overflow: "scroll",
                        flexDirection: "column",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            backgroundColor: "#527894",
                            borderRadius: "5px",
                            width: "100%",
                            height: "30px",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        <CloseIcon
                            onClick={(e) => this.props.handleClose(e)}
                            style={{
                                color: "#6caad4",
                                marginRight: "5px",
                                backgroundColor: "#e3f1fc",
                                borderRadius: "5px",
                                cursor: "pointer",
                            }}
                        />
                    </div>
                    <div style={{width:'96%',display:'flex',flexDirection:'column'}}>
                        <label for={'from'}>To:</label>
                        <input className={'w-100 px-3'} type={'text'} id={'from'} value={this.state.to} onChange={(e)=>this.setState({to:e.target.value})}/>
                    </div>
                    <div style={{width: '96%', display: 'flex', flexDirection: 'column'}}>
                        <label htmlFor={'from'}>Message:</label>
                        <textarea className={'w-100 px-3'} rows="8" type={'text'} id={'from'} value={this.state.message}
                               onChange={(e) => this.setState({message: e.target.value})}/>
                    </div>
                    <div style={{width: '96%', display: 'flex', flexDirection: 'column'}}>
                        <label htmlFor={'from'}>From:</label>
                        <input className={'w-100 px-3'} type={'text'} id={'from'} value={this.state.from}
                               onChange={(e) => this.setState({from: e.target.value})}/>
                    </div>
                    <div style={{width: '96%', display: 'flex', alignItems:'center',justifyContent:'flex-start',marginTop:'10px'}}>
                        <button type={"button"} onClick={()=>this.submit()}>Submit</button>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch, router) => ({
    updateMessage: (data) => dispatch(updateMessage(data, router)),
});

export default connect(mapStateToProps,mapDispatchToProps)(EditGiftMessage)