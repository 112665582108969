import React, {Component} from 'react';
import {connect} from 'react-redux';
import CloseIcon from "@material-ui/icons/Close";
import {Modal} from "@material-ui/core";
import {actionGetDeliveryBoys,actionUpdateDeliveryBoy} from "../../../store/actions/orderActions";

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        getDeliveryBoys:(id)=>dispatch(actionGetDeliveryBoys(id)),
        update:(data)=>dispatch(actionUpdateDeliveryBoy(data)),
    };
}

class OutForDeliveryPopUp extends Component {
    state={
        flag:false,
        carts:[],
        deliveryBoys:[],
        selectedDeliveryBoy:'',
        subOrderIds:[]
    }
   async componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.carts!==this.props.carts){
            if(this.props.carts.length && this.props.carts[0].partyId){
                let ids = this.props.carts.map(cart=>cart?._id);
                let deliveryBoys=await this.props.getDeliveryBoys(this.props.carts[0].partyId);
                this.setState({carts:ids,flag:true,partyId:this.props.carts[0].partyId,deliveryBoys,subOrderIds:[this.props.carts[0].subOrderId]});
            }

        }
    }
    submit=async()=>{
        let allocatedDate= new Date();
        let deliveryBoyId = this.state.selectedDeliveryBoy;
        let subOrderIds = this.state.subOrderIds;
        let type="out";
        await this.props.update({allocatedDate,deliveryBoyId,subOrderIds,type});
        this.props.handleClose(true);
    }

    render() {
        return (
            <Modal
                open={this.props.modalOpen}
                onClose={this.props.handleClose}
                //   style={{ overflow: "scroll" }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div
                    style={{
                        height: "250px",
                        width: "400px",
                        backgroundColor: "white",
                        position: "absolute",
                        top: "50px",
                        border: "2px solid white",
                        left: "600px",
                        //alignItems: "center",
                        display: "flex",
                        overflow: "scroll",
                        flexDirection: "column",
                        alignItems:'center'
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            backgroundColor: "#527894",
                            borderRadius: "5px",
                            width: "100%",
                            height: "30px",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        <CloseIcon
                            onClick={(e) => this.props.handleClose(e)}
                            style={{
                                color: "#6caad4",
                                marginRight: "5px",
                                backgroundColor: "#e3f1fc",
                                borderRadius: "5px",
                                cursor: "pointer",
                            }}
                        />
                    </div>
                    {!this.state.flag && <p>This sub order has not been accepted by any vendor.</p>}
                    {this.state.flag && <div style={{width:'96%'}}>
                        <div style={{width: '96%', display: 'flex', flexDirection: 'column'}}>
                            <label htmlFor={'deliveryBoys'}>Party:</label>
                            <select
                                value={this.state.selectedDeliveryBoy}
                                id={'deliveryBoy'}
                                onChange={(e)=>this.setState({selectedDeliveryBoy:e.target.value})}
                            >
                                <option value={''}></option>
                                {this.state.deliveryBoys.map((boy)=>(
                                    <option value={boy._id}>{boy.name}</option>
                                ))}
                            </select>
                        </div>
                        <div style={{width: '96%', display: 'flex', alignItems:'center',justifyContent:'flex-start',marginTop:'10px'}}>
                            <button type={"button"} onClick={()=>this.submit()}>Allocate</button>
                        </div>
                    </div>}

                </div>
            </Modal>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OutForDeliveryPopUp);