import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EditProductNav from "./EditProductNav";
import queryString from "query-string";
import DataTable from "../../../components/DataTable";
import moment from "moment";
import {
  createProduct,
  allActiveCategories,
  getProduct,
  updateProduct,
  createContent,
  getAllActiveContents,
  updateContent,
} from "../../../../store/actions/catalogActions";
export class EditProductContent extends Component {
  state = {
    flag: false,
    fromDate: "",
    throughDate: "",
    sequenceNum: "",
    productDescription: "",
    localeString: "",
    text: "",
    type: "",
    contentId: "",
  };
  componentDidMount = async () => {
    const query = queryString.parse(this.props.location.search);
    const product = query.productId;
    await this.props.getActive(product);
  };
  handleUpdateSelect = (content) => {
    const {
      contentType,
      fromDate,
      throughDate,
      sequenceNum,
      productDescription,
      localeString,
      text,
      _id,
    } = content;
    this.setState({
      flag: true,
      type: contentType,
      fromDate: moment(fromDate).utc(true).format("Y-MM-DD"),
      throughDate: throughDate
        ? moment(fromDate).utc(true).format("Y-MM-DD")
        : "",
      sequenceNum,
      productDescription,
      localeString,
      text,
      contentId: _id,
    });
  };
  handleBack = () => {
    this.setState({
      flag: false,
      fromDate: "",
      throughDate: "",
      sequenceNum: "",
      productDescription: "",
      localeString: "",
      text: "",
      type: "",
      contentId: "",
    });
  };
  handleDelete = async (content) => {
    const query = queryString.parse(this.props.location.search);
    const product = query.productId;

    this.props.updateContent({
      productId: product,
      isActive: false,
      isDeleted: true,
      id: content._id,
    });
  };
  handleContentCreate = () => {
    const {
      fromDate,
      throughDate,
      sequenceNum,
      productDescription,
      localeString,
      text,
      type,
    } = this.state;
    let content = {};
    const query = queryString.parse(this.props.location.search);
    const product = query.productId;
    content = fromDate ? { ...content, fromDate } : { ...content };
    content = throughDate ? { ...content, throughDate } : { ...content };
    content = sequenceNum ? { ...content, sequenceNum } : { ...content };
    content = productDescription
      ? { ...content, productDescription }
      : { ...content };
    content = localeString ? { ...content, localeString } : { ...content };
    content = text ? { ...content, text } : { ...content };
    content = type ? { ...content, contentType: type } : { ...content };
    content.productId = product;
    if (this.state.contentId) {
      content.id = this.state.contentId;
      this.props.updateContent(content);
    } else {
      this.props.create(content);
    }

    this.setState({
      contentId: "",
      fromDate: "",
      throughDate: "",
      sequenceNum: "",
      productDescription: "",
      localeString: "",
      text: "",
      type: "",
      flag: false,
    });
  };
  render() {
    const query = queryString.parse(this.props.location.search);
    const product = query.productId;
    return (
      <React.Fragment>
        <div id="content-main-section" class="leftonly">
          <div id="centerdiv" class="no-clear">
            <EditProductNav product={product} />
            {this.state.flag && (
              <div>
                <h1>Content For:[ID:{product}]</h1>
                <div class="screenlet-title-bar">
                  <ul>
                    <li class="h3">Edit Product Content</li>
                  </ul>
                </div>
                <div id="screenlet_2_col" class="screenlet-body">
                  <table class="basic-table" cellspacing="0">
                    <tbody>
                      {this.state?.contentId && (
                        <tr>
                          <td class="label">Content Id</td>
                          <td>{this.state.contentId}</td>
                        </tr>
                      )}
                      <tr>
                        <td class="label">Product Content Type Id</td>
                        <td>{this.state.type}</td>
                      </tr>
                      <tr>
                        <td class="label">From Date</td>
                        <td>
                          <input
                            type="Date"
                            className="required"
                            value={this.state.fromDate}
                            onChange={(e) =>
                              this.setState({ fromDate: e.target.value })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="label">Through Date</td>
                        <td>
                          <input
                            type="Date"
                            value={this.state.throughDate}
                            onChange={(e) =>
                              this.setState({ throughDate: e.target.value })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="label">Sequence Number</td>
                        <td>
                          <input
                            value={this.state.sequenceNum}
                            type="text"
                            onChange={(e) =>
                              this.setState({ sequenceNum: e.target.value })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="label">Product Description</td>
                        <td>
                          <input
                            value={this.state.productDescription}
                            type="text"
                            onChange={(e) =>
                              this.setState({
                                productDescription: e.target.value,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="label">Locale String</td>
                        <td>
                          <input
                            type="text"
                            value={this.state.localeString}
                            onChange={(e) =>
                              this.setState({ localeString: e.target.value })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="label">Text*</td>
                        <td>
                          <textarea
                            name="text"
                            class="dojo-ResizableTextArea"
                            cols="80"
                            rows="20"
                            value={this.state.text}
                            onChange={(e) =>
                              this.setState({ text: e.target.value })
                            }
                            id="EditProductContentSimpleText_text"
                            spellcheck="false"
                          ></textarea>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          <button onClick={(e) => this.handleContentCreate()}>
                            {this.state.contentId ? "Update" : "Create"}
                          </button>
                          <button onClick={(e) => this.handleBack()}>
                            Go Back
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {!this.state.flag && (
              <div id="screenlet_1" class="screenlet">
                <div class="screenlet-title-bar">
                  <ul>
                    <li class="h3">Edit Product Content</li>
                  </ul>
                </div>
                <div id="screenlet_1_col" class="screenlet-body">
                  <DataTable>
                    <table class="basic-table" cellspacing="0">
                      <tbody>
                        <tr class="header-row">
                          <td>
                            <label
                              id="ListProductContentInfos_editProductContentInfo_title"
                              for="ListProductContentInfos_editProductContentInfo"
                            >
                              Content
                            </label>
                          </td>
                          <td>
                            <label
                              id="ListProductContentInfos_productContentTypeId_title"
                              for="ListProductContentInfos_productContentTypeId"
                            >
                              Type
                            </label>
                          </td>
                          <td>
                            <label
                              id="ListProductContentInfos_fromDate_title"
                              for="ListProductContentInfos_fromDate"
                            >
                              From Date
                            </label>
                          </td>
                          <td>
                            <label
                              id="ListProductContentInfos_thruDate_title"
                              for="ListProductContentInfos_thruDate"
                            >
                              Through Date
                            </label>
                          </td>
                          <td>
                            <label
                              id="ListProductContentInfos_purchaseFromDate_title"
                              for="ListProductContentInfos_purchaseFromDate"
                            >
                              Purchase From Date
                            </label>
                          </td>
                          <td>
                            <label
                              id="ListProductContentInfos_purchaseThruDate_title"
                              for="ListProductContentInfos_purchaseThruDate"
                            >
                              Purchase Through Date
                            </label>
                          </td>
                          <td>
                            <label
                              id="ListProductContentInfos_useCountLimit_title"
                              for="ListProductContentInfos_useCountLimit"
                            >
                              Use Count Limit
                            </label>
                          </td>
                          <td>
                            <label
                              id="ListProductContentInfos_useTime_title"
                              for="ListProductContentInfos_useTime"
                            >
                              Use Time
                            </label>
                          </td>
                          <td>
                            <label
                              id="ListProductContentInfos_useTimeUomId_title"
                              for="ListProductContentInfos_useTimeUomId"
                            >
                              Use Time Uom Id
                            </label>
                          </td>
                          <td>
                            <label
                              id="ListProductContentInfos_useRoleTypeId_title"
                              for="ListProductContentInfos_useRoleTypeId"
                            >
                              Use Role Type Id
                            </label>
                          </td>
                          <td>
                            <label
                              id="ListProductContentInfos_sequenceNum_title"
                              for="ListProductContentInfos_sequenceNum"
                            >
                              Sequence Num
                            </label>
                          </td>
                          <td>
                            <label
                              id="ListProductContentInfos_editContent_title"
                              for="ListProductContentInfos_editContent"
                            >
                              Edit Content
                            </label>
                          </td>
                          <td> </td>
                        </tr>
                        {this.props.content?.map((content) => (
                          <tr>
                            <td>
                              <button
                                onClick={(e) =>
                                  this.handleUpdateSelect(content)
                                }
                              >
                                {content._id}
                              </button>
                            </td>
                            <td>{content.contentType}</td>
                            <td>{content.fromDate}</td>
                            <td>{content.throughDate}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <button
                                onClick={() => this.handleDelete(content)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </DataTable>
                </div>
              </div>
            )}
            {!this.state.flag && (
              <div id="screenlet_2" class="screenlet">
                <div class="screenlet-title-bar">
                  <ul>
                    <li class="h3">Create New Product Content</li>
                  </ul>
                </div>
                <div id="screenlet_2_col" class="screenlet-body">
                  <form id="PrepareAddProductContentAssoc" class="basic-form">
                    <input
                      id="PrepareAddProductContentAssoc_productId"
                      name="productId"
                      type="hidden"
                      value="10000"
                    />
                    <table class="basic-table" cellspacing="0">
                      <tbody>
                        <tr>
                          <td class="label">
                            <label
                              id="PrepareAddProductContentAssoc_productContentTypeId_title"
                              for="PrepareAddProductContentAssoc_productContentTypeId"
                            >
                              Product Content Type Id
                            </label>
                          </td>
                          <td>
                            <span class="ui-widget">
                              <select
                                onChange={(e) =>
                                  this.setState({ type: e.target.value })
                                }
                                id="PrepareAddProductContentAssoc_productContentTypeId"
                                name="productContentTypeId"
                                size="1"
                              >
                                <option value="ADDTOCART_IMAGE">
                                  Add To Cart Image
                                </option>
                                <option value="ADDTOCART_LABEL">
                                  Add To Cart Label
                                </option>
                                <option value="ALTERNATIVE_URL">
                                  Alternative URL
                                </option>
                                <option value="CARE_INSTRUCTIONS">
                                  Care Instructions
                                </option>
                                <option value="Customer_Video_Url">
                                  Customer Video Url
                                </option>
                                <option value="DEFAULT_IMAGE">
                                  Default Image
                                </option>
                                <option value="DELIVERY_INFO">
                                  Delivery Info
                                </option>
                                <option value="DESCRIPTION">Description</option>
                                <option value="LONG_DESCRIPTION">
                                  Description - Long
                                </option>
                                <option value="EBAY_DESCRIPTION">
                                  Detail Page for eBay Auctions
                                </option>
                                <option value="DIGITAL_DOWNLOAD">
                                  Digital Download
                                </option>
                                <option value="DIRECTIONS">Directions</option>
                                <option value="FULFILLMENT_EMAIL">
                                  Fulfillment Email
                                </option>
                                <option value="FULFILLMENT_EXTASYNC">
                                  Fulfillment External (Async)
                                </option>
                                <option value="FULFILLMENT_EXTSYNC">
                                  Fulfillment External (Sync)
                                </option>
                                <option value="IMAGE">Image</option>
                                <option value="ADDITIONAL_IMAGE_1">
                                  Image - Additional View 1
                                </option>
                                <option value="XTRA_IMG_1_DETAIL">
                                  Image - Additional View 1 Detail
                                </option>
                                <option value="XTRA_IMG_1_LARGE">
                                  Image - Additional View 1 Large
                                </option>
                                <option value="XTRA_IMG_1_MEDIUM">
                                  Image - Additional View 1 Medium
                                </option>
                                <option value="XTRA_IMG_1_SMALL">
                                  Image - Additional View 1 Small
                                </option>
                                <option value="ADDITIONAL_IMAGE_2">
                                  Image - Additional View 2
                                </option>
                                <option value="XTRA_IMG_2_DETAIL">
                                  Image - Additional View 2 Detail
                                </option>
                                <option value="XTRA_IMG_2_LARGE">
                                  Image - Additional View 2 Large
                                </option>
                                <option value="XTRA_IMG_2_MEDIUM">
                                  Image - Additional View 2 Medium
                                </option>
                                <option value="XTRA_IMG_2_SMALL">
                                  Image - Additional View 2 Small
                                </option>
                                <option value="ADDITIONAL_IMAGE_3">
                                  Image - Additional View 3
                                </option>
                                <option value="XTRA_IMG_3_DETAIL">
                                  Image - Additional View 3 Detail
                                </option>
                                <option value="XTRA_IMG_3_LARGE">
                                  Image - Additional View 3 Large
                                </option>
                                <option value="XTRA_IMG_3_MEDIUM">
                                  Image - Additional View 3 Medium
                                </option>
                                <option value="XTRA_IMG_3_SMALL">
                                  Image - Additional View 3 Small
                                </option>
                                <option value="ADDITIONAL_IMAGE_4">
                                  Image - Additional View 4
                                </option>
                                <option value="XTRA_IMG_4_DETAIL">
                                  Image - Additional View 4 Detail
                                </option>
                                <option value="XTRA_IMG_4_LARGE">
                                  Image - Additional View 4 Large
                                </option>
                                <option value="XTRA_IMG_4_MEDIUM">
                                  Image - Additional View 4 Medium
                                </option>
                                <option value="XTRA_IMG_4_SMALL">
                                  Image - Additional View 4 Small
                                </option>
                                <option value="XTRA_IMG_L_DETAIL">
                                  Image - Additional View Detail
                                </option>
                                <option value="XTRA_IMG_L_LARGE">
                                  Image - Additional View Large
                                </option>
                                <option value="XTRA_IMG_L_MEDIUM">
                                  Image - Additional View Medium
                                </option>
                                <option value="XTRA_IMG_L_SMALL">
                                  Image - Additional View Small
                                </option>
                                <option value="DETAIL_IMAGE_URL">
                                  Image - Detail
                                </option>
                                <option value="LARGE_IMAGE_URL">
                                  Image - Large
                                </option>
                                <option value="MEDIUM_IMAGE_URL">
                                  Image - Medium
                                </option>
                                <option value="ORIGINAL_IMAGE_URL">
                                  Image - Original
                                </option>
                                <option value="SMALL_IMAGE_URL">
                                  Image - Small
                                </option>
                                <option value="DETAIL_IMAGE_ALT">
                                  Image Alt Text - Detail
                                </option>
                                <option value="LARGE_IMAGE_ALT">
                                  Image Alt Text - Large
                                </option>
                                <option value="MEDIUM_IMAGE_ALT">
                                  Image Alt Text - Medium
                                </option>
                                <option value="SMALL_IMAGE_ALT">
                                  Image Alt Text - Small
                                </option>
                                <option value="INGREDIENTS">Ingredients</option>
                                <option value="INSTALLATION">
                                  Installation
                                </option>
                                <option value="META_DESCRIPTION">
                                  Meta Description
                                </option>
                                <option value="META_KEYWORD">
                                  Meta Keyword
                                </option>
                                <option value="ONLINE_ACCESS">
                                  Online Access
                                </option>
                                <option value="PAGE_TITLE">Page Title</option>
                                <option value="PRICE_DETAIL_TEXT">
                                  Price Detail Text
                                </option>
                                <option value="PRODUCT_NAME">
                                  Product Name
                                </option>
                                <option value="SHORT_SALES_PITCH">
                                  Elevator Pitch
                                </option>
                                <option value="SPECIALINSTRUCTIONS">
                                  Special Instructions
                                </option>
                                <option value="SPECIFICATION">
                                  Specification
                                </option>
                                <option value="TERMS_AND_CONDS">
                                  Terms and Conditions
                                </option>
                                <option value="UNIQUE_INGREDIENTS">
                                  Unique Ingredients
                                </option>
                                <option value="Vendor_Video_Url">
                                  Vendor Video Url
                                </option>
                                <option value="WARNINGS">Warnings</option>
                                <option value="WARRANTY">Warranty</option>
                              </select>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="label"> </td>
                          <td colspan="4">
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ flag: true });
                              }}
                            >
                              Prepare Create
                            </button>
                            {/* <input
                              onClick={(e)=>{
                                this.setState({flage:true})
                              }}
                              class="smallSubmit"
                              name="submitButton"
                              type="submit"
                              value="Prepare Create"
                            /> */}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </form>
                </div>
              </div>
            )}

            {!this.state.flag && (
              <div id="screenlet_3" class="screenlet">
                <div class="screenlet-title-bar">
                  <ul>
                    <li class="h3">Add Content to Product</li>
                  </ul>
                </div>
                <div id="screenlet_3_col" class="screenlet-body">
                  <form id="AddProductContentAssoc" class="basic-form">
                    <input
                      id="AddProductContentAssoc_productId"
                      name="productId"
                      type="hidden"
                      value="10000"
                    />
                    <table class="basic-table" cellspacing="0">
                      <tbody>
                        <tr>
                          <td class="label">
                            <label
                              id="AddProductContentAssoc_contentId_title"
                              for="AddProductContentAssoc_contentId"
                            >
                              Content ID
                            </label>
                          </td>
                          <td>
                            <div id="3_lookupId_AddProductContentAssoc_contentId_auto">
                              {" "}
                            </div>
                            <span class="field-lookup">
                              <input
                                id="3_lookupId_AddProductContentAssoc_contentId"
                                class="ui-autocomplete-input"
                                autocomplete="off"
                                name="contentId"
                                size="25"
                                type="text"
                              />{" "}
                              <a id="3_lookupId_button" href>
                                {" "}
                              </a>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="AddProductContentAssoc_productContentTypeId_title"
                              for="AddProductContentAssoc_productContentTypeId"
                            >
                              Product Content Type Id
                            </label>
                          </td>
                          <td>
                            <span class="ui-widget">
                              <select
                                id="AddProductContentAssoc_productContentTypeId"
                                name="productContentTypeId"
                                size="1"
                              >
                                <option value="ADDTOCART_IMAGE">
                                  Add To Cart Image
                                </option>
                                <option value="ADDTOCART_LABEL">
                                  Add To Cart Label
                                </option>
                                <option value="ALTERNATIVE_URL">
                                  Alternative URL
                                </option>
                                <option value="CARE_INSTRUCTIONS">
                                  Care Instructions
                                </option>
                                <option value="Customer_Video_Url">
                                  Customer Video Url
                                </option>
                                <option value="DEFAULT_IMAGE">
                                  Default Image
                                </option>
                                <option value="DELIVERY_INFO">
                                  Delivery Info
                                </option>
                                <option value="DESCRIPTION">Description</option>
                                <option value="LONG_DESCRIPTION">
                                  Description - Long
                                </option>
                                <option value="EBAY_DESCRIPTION">
                                  Detail Page for eBay Auctions
                                </option>
                                <option value="DIGITAL_DOWNLOAD">
                                  Digital Download
                                </option>
                                <option value="DIRECTIONS">Directions</option>
                                <option value="FULFILLMENT_EMAIL">
                                  Fulfillment Email
                                </option>
                                <option value="FULFILLMENT_EXTASYNC">
                                  Fulfillment External (Async)
                                </option>
                                <option value="FULFILLMENT_EXTSYNC">
                                  Fulfillment External (Sync)
                                </option>
                                <option value="IMAGE">Image</option>
                                <option value="ADDITIONAL_IMAGE_1">
                                  Image - Additional View 1
                                </option>
                                <option value="XTRA_IMG_1_DETAIL">
                                  Image - Additional View 1 Detail
                                </option>
                                <option value="XTRA_IMG_1_LARGE">
                                  Image - Additional View 1 Large
                                </option>
                                <option value="XTRA_IMG_1_MEDIUM">
                                  Image - Additional View 1 Medium
                                </option>
                                <option value="XTRA_IMG_1_SMALL">
                                  Image - Additional View 1 Small
                                </option>
                                <option value="ADDITIONAL_IMAGE_2">
                                  Image - Additional View 2
                                </option>
                                <option value="XTRA_IMG_2_DETAIL">
                                  Image - Additional View 2 Detail
                                </option>
                                <option value="XTRA_IMG_2_LARGE">
                                  Image - Additional View 2 Large
                                </option>
                                <option value="XTRA_IMG_2_MEDIUM">
                                  Image - Additional View 2 Medium
                                </option>
                                <option value="XTRA_IMG_2_SMALL">
                                  Image - Additional View 2 Small
                                </option>
                                <option value="ADDITIONAL_IMAGE_3">
                                  Image - Additional View 3
                                </option>
                                <option value="XTRA_IMG_3_DETAIL">
                                  Image - Additional View 3 Detail
                                </option>
                                <option value="XTRA_IMG_3_LARGE">
                                  Image - Additional View 3 Large
                                </option>
                                <option value="XTRA_IMG_3_MEDIUM">
                                  Image - Additional View 3 Medium
                                </option>
                                <option value="XTRA_IMG_3_SMALL">
                                  Image - Additional View 3 Small
                                </option>
                                <option value="ADDITIONAL_IMAGE_4">
                                  Image - Additional View 4
                                </option>
                                <option value="XTRA_IMG_4_DETAIL">
                                  Image - Additional View 4 Detail
                                </option>
                                <option value="XTRA_IMG_4_LARGE">
                                  Image - Additional View 4 Large
                                </option>
                                <option value="XTRA_IMG_4_MEDIUM">
                                  Image - Additional View 4 Medium
                                </option>
                                <option value="XTRA_IMG_4_SMALL">
                                  Image - Additional View 4 Small
                                </option>
                                <option value="XTRA_IMG_L_DETAIL">
                                  Image - Additional View Detail
                                </option>
                                <option value="XTRA_IMG_L_LARGE">
                                  Image - Additional View Large
                                </option>
                                <option value="XTRA_IMG_L_MEDIUM">
                                  Image - Additional View Medium
                                </option>
                                <option value="XTRA_IMG_L_SMALL">
                                  Image - Additional View Small
                                </option>
                                <option value="DETAIL_IMAGE_URL">
                                  Image - Detail
                                </option>
                                <option value="LARGE_IMAGE_URL">
                                  Image - Large
                                </option>
                                <option value="MEDIUM_IMAGE_URL">
                                  Image - Medium
                                </option>
                                <option value="ORIGINAL_IMAGE_URL">
                                  Image - Original
                                </option>
                                <option value="SMALL_IMAGE_URL">
                                  Image - Small
                                </option>
                                <option value="DETAIL_IMAGE_ALT">
                                  Image Alt Text - Detail
                                </option>
                                <option value="LARGE_IMAGE_ALT">
                                  Image Alt Text - Large
                                </option>
                                <option value="MEDIUM_IMAGE_ALT">
                                  Image Alt Text - Medium
                                </option>
                                <option value="SMALL_IMAGE_ALT">
                                  Image Alt Text - Small
                                </option>
                                <option value="INGREDIENTS">Ingredients</option>
                                <option value="INSTALLATION">
                                  Installation
                                </option>
                                <option value="META_DESCRIPTION">
                                  Meta Description
                                </option>
                                <option value="META_KEYWORD">
                                  Meta Keyword
                                </option>
                                <option value="ONLINE_ACCESS">
                                  Online Access
                                </option>
                                <option value="PAGE_TITLE">Page Title</option>
                                <option value="PRICE_DETAIL_TEXT">
                                  Price Detail Text
                                </option>
                                <option value="PRODUCT_NAME">
                                  Product Name
                                </option>
                                <option value="SHORT_SALES_PITCH">
                                  Elevator Pitch
                                </option>
                                <option value="SPECIALINSTRUCTIONS">
                                  Special Instructions
                                </option>
                                <option value="SPECIFICATION">
                                  Specification
                                </option>
                                <option value="TERMS_AND_CONDS">
                                  Terms and Conditions
                                </option>
                                <option value="UNIQUE_INGREDIENTS">
                                  Unique Ingredients
                                </option>
                                <option value="Vendor_Video_Url">
                                  Vendor Video Url
                                </option>
                                <option value="WARNINGS">Warnings</option>
                                <option value="WARRANTY">Warranty</option>
                              </select>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="AddProductContentAssoc_fromDate_title"
                              for="AddProductContentAssoc_fromDate"
                            >
                              From Date
                            </label>
                          </td>
                          <td>
                            <span class="view-calendar">
                              {" "}
                              <input
                                id="AddProductContentAssoc_fromDate_i18n"
                                title="dd-MM-yyyy HH:mm:ss"
                                maxlength="30"
                                name="fromDate_i18n"
                                size="25"
                                type="text"
                              />{" "}
                              <input
                                id="AddProductContentAssoc_fromDate"
                                class="hasDatepicker datePickPC"
                                title="dd-MM-yyyy HH:mm:ss"
                                maxlength="30"
                                name="fromDate"
                                size="25"
                                type="text"
                              />
                              <button
                                class="ui-datepicker-trigger"
                                type="button"
                              ></button>{" "}
                              <input name="" type="hidden" value="Timestamp" />{" "}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="AddProductContentAssoc_thruDate_title"
                              for="AddProductContentAssoc_thruDate"
                            >
                              Through Date
                            </label>
                          </td>
                          <td>
                            <span class="view-calendar">
                              {" "}
                              <input
                                id="AddProductContentAssoc_thruDate_i18n"
                                title="dd-MM-yyyy HH:mm:ss"
                                maxlength="30"
                                name="thruDate_i18n"
                                size="25"
                                type="text"
                              />{" "}
                              <input
                                id="AddProductContentAssoc_thruDate"
                                class="hasDatepicker datePickPC"
                                title="dd-MM-yyyy HH:mm:ss"
                                maxlength="30"
                                name="thruDate"
                                size="25"
                                type="text"
                              />
                              <button
                                class="ui-datepicker-trigger"
                                type="button"
                              ></button>{" "}
                              <input name="" type="hidden" value="Timestamp" />{" "}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="AddProductContentAssoc_purchaseFromDate_title"
                              for="AddProductContentAssoc_purchaseFromDate"
                            >
                              Purchase From Date
                            </label>
                          </td>
                          <td>
                            <span class="view-calendar">
                              {" "}
                              <input
                                id="AddProductContentAssoc_purchaseFromDate_i18n"
                                title="dd-MM-yyyy HH:mm:ss"
                                maxlength="30"
                                name="purchaseFromDate_i18n"
                                size="25"
                                type="text"
                              />{" "}
                              <input
                                id="AddProductContentAssoc_purchaseFromDate"
                                class="hasDatepicker datePickPC"
                                title="dd-MM-yyyy HH:mm:ss"
                                maxlength="30"
                                name="purchaseFromDate"
                                size="25"
                                type="text"
                              />
                              <button
                                class="ui-datepicker-trigger"
                                type="button"
                              ></button>{" "}
                              <input name="" type="hidden" value="Timestamp" />{" "}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="AddProductContentAssoc_purchaseThruDate_title"
                              for="AddProductContentAssoc_purchaseThruDate"
                            >
                              Purchase Through Date
                            </label>
                          </td>
                          <td>
                            <span class="view-calendar">
                              {" "}
                              <input
                                id="AddProductContentAssoc_purchaseThruDate_i18n"
                                title="dd-MM-yyyy HH:mm:ss"
                                maxlength="30"
                                name="purchaseThruDate_i18n"
                                size="25"
                                type="text"
                              />{" "}
                              <input
                                id="AddProductContentAssoc_purchaseThruDate"
                                class="hasDatepicker datePickPC"
                                title="dd-MM-yyyy HH:mm:ss"
                                maxlength="30"
                                name="purchaseThruDate"
                                size="25"
                                type="text"
                              />
                              <button
                                class="ui-datepicker-trigger"
                                type="button"
                              ></button>{" "}
                              <input name="" type="hidden" value="Timestamp" />{" "}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="AddProductContentAssoc_useCountLimit_title"
                              for="AddProductContentAssoc_useCountLimit"
                            >
                              Use Count Limit
                            </label>
                          </td>
                          <td>
                            <input
                              id="AddProductContentAssoc_useCountLimit"
                              name="useCountLimit"
                              size="6"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="AddProductContentAssoc_useTime_title"
                              for="AddProductContentAssoc_useTime"
                            >
                              Use Time
                            </label>
                          </td>
                          <td>
                            <input
                              id="AddProductContentAssoc_useTime"
                              name="useTime"
                              size="6"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="AddProductContentAssoc_useTimeUomId_title"
                              for="AddProductContentAssoc_useTimeUomId"
                            >
                              Use Time Uom
                            </label>
                          </td>
                          <td>
                            <span class="ui-widget">
                              <select
                                id="AddProductContentAssoc_useTimeUomId"
                                name="useTimeUomId"
                                size="1"
                              >
                                <option value=""> </option>
                                <option value="TF_century">
                                  Time in Centuries
                                </option>
                                <option value="TF_day">Time in Days</option>
                                <option value="TF_decade">
                                  Time in Decades
                                </option>
                                <option value="TF_hr">Time in Hours</option>
                                <option value="TF_millenium">
                                  Time in Millenia
                                </option>
                                <option value="TF_ms">
                                  Time in Milli-Seconds
                                </option>
                                <option value="TF_min">Time in Minutes</option>
                                <option value="TF_mon">Time in Months</option>
                                <option value="TF_score">Time in Scores</option>
                                <option value="TF_s">Time in Seconds</option>
                                <option value="TF_wk">Time in Weeks</option>
                                <option value="TF_yr">Time in Years</option>
                              </select>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="AddProductContentAssoc_useRoleTypeId_title"
                              for="AddProductContentAssoc_useRoleTypeId"
                            >
                              Use Role
                            </label>
                          </td>
                          <td>
                            <span class="ui-widget">
                              <select
                                id="AddProductContentAssoc_useRoleTypeId"
                                name="useRoleTypeId"
                                size="1"
                              >
                                <option value=""> </option>
                                <option value="ACCOUNT">Account</option>
                                <option value="ACCOUNT_LEAD">
                                  Account Lead
                                </option>
                                <option value="ACCOUNTANT">Accountant</option>
                                <option value="ADDRESSEE">Addressee</option>
                                <option value="ADMIN">Administrator</option>
                                <option value="AFFILIATE">Affiliate</option>
                                <option value="AGENT">Agent</option>
                                <option value="APPROVER">Approver</option>
                                <option value="ASSOCIATION">Association</option>
                                <option value="AUTOMATED_AGENT_ROLE">
                                  Automated Agent
                                </option>
                                <option value="BILL_FROM_VENDOR">
                                  Bill-From-Vendor
                                </option>
                                <option value="BILL_TO_CUSTOMER">
                                  Bill-To Customer
                                </option>
                                <option value="BCC">Blind Copy</option>
                                <option value="BRANCH">Branch</option>
                                <option value="BULK_CUSTOMER">
                                  Bulk Customer
                                </option>
                                <option value="BUYER">Buyer</option>
                                <option value="CALENDAR_ROLE">Calendar</option>
                                <option value="CAL_ATTENDEE">
                                  Calendar Attendee
                                </option>
                                <option value="CAL_DELEGATE">
                                  Calendar Delegate
                                </option>
                                <option value="CAL_HOST">Calendar Host</option>
                                <option value="ICAL_MEMBER">
                                  Calendar Member
                                </option>
                                <option value="CAL_ORGANIZER">
                                  Calendar Organizer
                                </option>
                                <option value="CAL_OWNER">
                                  Calendar Owner
                                </option>
                                <option value="CC">Carbon Copy</option>
                                <option value="CARRIER">Carrier</option>
                                <option value="CASHIER">Cashier</option>
                                <option value="Agent">CC Agent</option>
                                <option value="CLIENT">Client</option>
                                <option value="CLIENT_ANALYST">
                                  Client Analyst
                                </option>
                                <option value="CLIENT_BILLING">
                                  Client Billing
                                </option>
                                <option value="CLIENT_MANAGER">
                                  Client Manager
                                </option>
                                <option value="COMMENTATOR">Commentator</option>
                                <option value="COMMEVENT_ROLE">
                                  Communication Participant
                                </option>
                                <option value="COMPETITOR">Competitor</option>
                                <option value="CONSUMER">Consumer</option>
                                <option value="CONTACT">Contact</option>
                                <option value="CONTENT_ADMIN">
                                  Content Administrator
                                </option>
                                <option value="CONTENT_AUTHOR">
                                  Content Author
                                </option>
                                <option value="CONTENT_EDITOR">
                                  Content Editor
                                </option>
                                <option value="CONTENT_GUEST">
                                  Content Guest
                                </option>
                                <option value="CONTENT">Content Member</option>
                                <option value="CONTENT_PUBLISHER">
                                  Content Publisher
                                </option>
                                <option value="CONTENT_USER">
                                  Content User
                                </option>
                                <option value="CONTRACTOR">Contractor</option>
                                <option value="CORPORATE_USER">
                                  Corporate User
                                </option>
                                <option value="CUSTOMER">Customer</option>
                                <option value="DELIVERY_BOY">
                                  Delivery Boy
                                </option>
                                <option value="DEPARTMENT">Department</option>
                                <option value="DISTRIBUTION_CHANNEL">
                                  Distribution Channel
                                </option>
                                <option value="DISTRIBUTOR">Distributor</option>
                                <option value="DIVISION">Division</option>
                                <option value="EMAIL_ADMIN">
                                  Email Administrator
                                </option>
                                <option value="EMPLOYEE">Employee</option>
                                <option value="END_USER_CUSTOMER">
                                  End-User Customer
                                </option>
                                <option value="EBAY_ACCOUNT">
                                  eBay Account
                                </option>
                                <option value="FAMILY_MEMBER">
                                  Family Member
                                </option>
                                <option value="FAM_ASSIGNEE">
                                  Fixed Asset Maint Assignee
                                </option>
                                <option value="HOSTING_SERVER">
                                  Hosting Server
                                </option>
                                <option value="HOUSEHOLD">Household</option>
                                <option value="IMAGEAPPROVER">
                                  Image Approver
                                </option>
                                <option value="INTERNAL_ORGANIZATIO">
                                  Internal Organization
                                </option>
                                <option value="ISP">ISP</option>
                                <option value="LEAD">Lead</option>
                                <option value="LTD_ADMIN">
                                  Limited Administrator
                                </option>
                                <option value="LOGGEDIN">Logged In</option>
                                <option value="MAIN_ROLE">Main Role</option>
                                <option value="FAM_MANAGER">
                                  Maintenance Manager or Supervisor
                                </option>
                                <option value="FAM_SUPPLIER">
                                  Maintenance Supplier or Service
                                </option>
                                <option value="FAM_WORKER">
                                  Maintenance Worker
                                </option>
                                <option value="MANAGER">Manager</option>
                                <option value="MANUFACTURER">
                                  Manufacturer
                                </option>
                                <option value="_NA_">Not Applicable</option>
                                <option value="ORDER_CLERK">Order Clerk</option>
                                <option value="ORGANIZATION_ROLE">
                                  Organization
                                </option>
                                <option value="ORGANIZATION_UNIT">
                                  Organization Unit
                                </option>
                                <option value="ORIGINATOR">Originator</option>
                                <option value="OTHER_INTERNAL_ORGAN">
                                  Other Internal
                                </option>
                                <option value="OTHER_ORGANIZATION_U">
                                  Other Organization Unit
                                </option>
                                <option value="OWNER">Owner</option>
                                <option value="PACKER">Packer</option>
                                <option value="PARENT_ORGANIZATION">
                                  Parent Organization
                                </option>
                                <option value="PARTNER">Partner</option>
                                <option value="PERSON_ROLE">Person</option>
                                <option value="PICKER">Picker</option>
                                <option value="PLACING_CUSTOMER">
                                  Placing Customer
                                </option>
                                <option value="PRODUCT_OWNER">
                                  Product Owner
                                </option>
                                <option value="PRODUCT_OWNER_COMP">
                                  Product Owner Company
                                </option>
                                <option value="PROJECT_TEAM">
                                  Project Team
                                </option>
                                <option value="PROSPECT">Prospect</option>
                                <option value="PROVIDER_ACCOUNTING">
                                  Provider Accounting
                                </option>
                                <option value="PROVIDER_ANALYST">
                                  Provider Analyst
                                </option>
                                <option value="PROVIDER_FUNCTIONAL">
                                  Provider Functional
                                </option>
                                <option value="PROVIDER_MANAGER">
                                  Provider Manager
                                </option>
                                <option value="PROVIDER_TESTER">
                                  Provider Tester
                                </option>
                                <option value="PROVIDER_VALIDATOR">
                                  Provider Validator
                                </option>
                                <option value="RECEIVER">Receiver</option>
                                <option value="REFERRER">Referrer</option>
                                <option value="REGULATORY_AGENCY">
                                  Regulatory Agency
                                </option>
                                <option value="REQ_MANAGER">
                                  Request Manager
                                </option>
                                <option value="REQ_RESPOND">
                                  Request Respondent
                                </option>
                                <option value="REQUEST_ROLE">
                                  Request Role
                                </option>
                                <option value="REQ_TAKER">Request Taker</option>
                                <option value="REQ_REQUESTER">
                                  Requesting Party
                                </option>
                                <option value="REVIEWER">Reviewer</option>
                                <option value="SFA_ROLE">
                                  Sales Force Autm.
                                </option>
                                <option value="SALES_REP">
                                  Sales Representative
                                </option>
                                <option value="SCRUM_MASTER">
                                  SCRUM Master
                                </option>
                                <option value="SCRUM_TEAM">SCRUM Team</option>
                                <option value="SCRUM_MEMBER">
                                  Scrum Member
                                </option>
                                <option value="SHAREHOLDER">Shareholder</option>
                                <option value="SHIP_FROM_VENDOR">
                                  Ship-From Vendor
                                </option>
                                <option value="SHIP_TO_CUSTOMER">
                                  Ship-To Customer
                                </option>
                                <option value="SHIPMENT_CLERK">
                                  Shipment Clerk
                                </option>
                                <option value="SPONSOR">Sponsor</option>
                                <option value="SPOUSE">Spouse</option>
                                <option value="STAKEHOLDER">Stakeholder</option>
                                <option value="STOCKER">Stocker</option>
                                <option value="SUBSCRIBER">Subscriber</option>
                                <option value="SUBSIDIARY">Subsidiary</option>
                                <option value="SUPPLIER">Supplier</option>
                                <option value="SUPPLIER_AGENT">
                                  Supplier Agent
                                </option>
                                <option value="TAX_AUTHORITY">
                                  Tax Authority
                                </option>
                                <option value="UNION">Union</option>
                                <option value="VENDOR">Vendor</option>
                                <option value="VISITOR">Visitor</option>
                                <option value="WEB_MASTER">Web Master</option>
                                <option value="WORKER">Worker</option>
                                <option value="WORKFLOW_ROLE">Workflow</option>
                                <option value="WF_OWNER">Workflow Owner</option>
                              </select>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="AddProductContentAssoc_sequenceNum_title"
                              for="AddProductContentAssoc_sequenceNum"
                            >
                              Sequence Num
                            </label>
                          </td>
                          <td>
                            <input
                              id="AddProductContentAssoc_sequenceNum"
                              name="sequenceNum"
                              size="6"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td class="label"> </td>
                          <td colspan="4">
                            <input
                              class="smallSubmit"
                              name="submitButton"
                              type="submit"
                              value="Add"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </form>
                </div>
              </div>
            )}
            {!this.state.flag && (
              <div id="screenlet_4" class="screenlet">
                <div class="screenlet-title-bar">
                  <ul>
                    <li class="h3">Override Simple Fields</li>
                  </ul>
                </div>
                <div id="screenlet_4_col" class="screenlet-body">
                  <form
                    style={{ margin: 0 }}
                    action="/catalog/control/updateProductContentWithCatalog"
                    method="post"
                    name="productForm"
                  >
                    <input name="productId" type="hidden" value="10000" />
                    <table class="basic-table" cellspacing="0">
                      <tbody>
                        <tr>
                          <td align="right" valign="top" width="20%">
                            <strong>Catalog</strong>
                          </td>
                          <td> </td>
                          <td colspan="4" valign="top" width="80%">
                            <select name="catalogId">
                              <option defaultValue>Catalogs</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td align="right" valign="top" width="20%">
                            <strong>Product Name</strong>
                          </td>
                          <td> </td>
                          <td colspan="4" valign="top" width="80%">
                            <input
                              maxlength="60"
                              name="productName"
                              size="30"
                              type="text"
                              value=""
                            />
                          </td>
                        </tr>
                        <tr>
                          <td align="right" valign="top" width="20%">
                            <strong>Product Description</strong>
                          </td>
                          <td> </td>
                          <td colspan="4" valign="top" width="80%">
                            <textarea
                              cols="60"
                              maxlength="255"
                              name="description"
                              rows="2"
                            ></textarea>
                          </td>
                        </tr>
                        <tr>
                          <td align="right" valign="top" width="20%">
                            <strong>Long Description</strong>
                          </td>
                          <td> </td>
                          <td colspan="4" valign="top" width="80%">
                            <textarea
                              class="dojo-ResizableTextArea"
                              cols="60"
                              name="longDescription"
                              rows="7"
                            ></textarea>
                          </td>
                        </tr>
                        <tr>
                          <td align="right" valign="top" width="20%">
                            <strong>Detail Screen</strong>
                          </td>
                          <td> </td>
                          <td colspan="4" valign="top" width="80%">
                            <input
                              maxlength="250"
                              name="detailScreen"
                              size="60"
                              type="text"
                              value=""
                            />{" "}
                            <br />
                            <span class="tooltip">
                              If not specified defaults to "productdetail", for
                              screens in other files use something like:
                              "component://ecommerce/widget/CatalogScreens.xml#productdetail"
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td align="right" valign="top" width="20%">
                            <div>
                              <strong>Small Image</strong>
                            </div>
                          </td>
                          <td> </td>
                          <td colspan="4" valign="top" width="80%">
                            <input
                              maxlength="255"
                              name="smallImageUrl"
                              size="60"
                              type="text"
                              value=""
                            />
                            <div>
                              Insert Default Image URL:{" "}
                              <a class="buttontext" href>
                                .jpg
                              </a>{" "}
                              <a class="buttontext" href>
                                .gif
                              </a>{" "}
                              <a class="buttontext" href>
                                Clear
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td align="right" valign="top" width="20%">
                            <div>
                              <strong>Medium Image</strong>
                            </div>
                          </td>
                          <td> </td>
                          <td colspan="4" valign="top" width="80%">
                            <input
                              maxlength="255"
                              name="mediumImageUrl"
                              size="60"
                              type="text"
                              value=""
                            />
                            <div>
                              Insert Default Image URL:{" "}
                              <a class="buttontext" href>
                                .jpg
                              </a>{" "}
                              <a class="buttontext" href>
                                .gif
                              </a>{" "}
                              <a class="buttontext" href>
                                Clear
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td align="right" valign="top" width="20%">
                            <div>
                              <strong>Large Image</strong>
                            </div>
                          </td>
                          <td> </td>
                          <td colspan="4" valign="top" width="80%">
                            <input
                              maxlength="255"
                              name="largeImageUrl"
                              size="60"
                              type="text"
                              value=""
                            />
                            <div>
                              Insert Default Image URL:{" "}
                              <a class="buttontext" href>
                                .jpg
                              </a>{" "}
                              <a class="buttontext" href>
                                .gif
                              </a>{" "}
                              <a class="buttontext" href>
                                Clear
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td align="right" valign="top" width="20%">
                            <div>
                              <strong>Detail Image</strong>
                            </div>
                          </td>
                          <td> </td>
                          <td colspan="4" valign="top" width="80%">
                            <input
                              maxlength="255"
                              name="detailImageUrl"
                              size="60"
                              type="text"
                              value=""
                            />
                            <div>
                              Insert Default Image URL:{" "}
                              <a class="buttontext" href>
                                .jpg
                              </a>{" "}
                              <a class="buttontext" href>
                                .gif
                              </a>{" "}
                              <a class="buttontext" href>
                                Clear
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td align="right" valign="top" width="20%">
                            <div>
                              <strong>Original Image</strong>
                            </div>
                          </td>
                          <td> </td>
                          <td colspan="4" valign="top" width="80%">
                            <input
                              maxlength="255"
                              name="originalImageUrl"
                              size="60"
                              type="text"
                              value=""
                            />
                            <div>
                              Insert Default Image URL:{" "}
                              <a class="buttontext" href>
                                .jpg
                              </a>{" "}
                              <a class="buttontext" href>
                                .gif
                              </a>{" "}
                              <a class="buttontext" href>
                                Clear
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2"> </td>
                          <td>
                            <input
                              class="smallSubmit"
                              name="Update"
                              type="submit"
                              value="Update"
                            />
                          </td>
                          <td colspan="3"> </td>
                        </tr>
                      </tbody>
                    </table>
                  </form>
                  <h3>Upload Image</h3>
                  <form
                    onSubmit={this.handleUpload}
                    enctype="multipart/form-data"
                  >
                    <table class="basic-table" cellspacing="0">
                      <tbody>
                        <tr>
                          <td align="right" valign="top" width="20%">
                            <input
                              name="fname"
                              size="50"
                              type="file"
                              ref={(ref) => {
                                this.uploadInput = ref;
                              }}
                            />
                          </td>
                          <td> </td>
                          <td colspan="4" valign="top" width="80%">
                            <input
                              name="upload_file_type_bogus"
                              type="radio"
                              value="small"
                            />
                            Small{" "}
                            <input
                              name="upload_file_type_bogus"
                              type="radio"
                              value="medium"
                            />
                            Medium{" "}
                            <input
                              name="upload_file_type_bogus"
                              type="radio"
                              value="large"
                            />
                            Large{" "}
                            <input
                              name="upload_file_type_bogus"
                              type="radio"
                              value="detail"
                            />
                            Detail{" "}
                            <input
                              checked="checked"
                              name="upload_file_type_bogus"
                              type="radio"
                              value="original"
                            />
                            Original{" "}
                            <input
                              class="smallSubmit"
                              type="submit"
                              value="Upload Image"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <span class="tooltip">
                      Automatic scale of the original image, related to the
                      configuration :
                      [ofbiz.home]/applications/product/config/ImageProperties.xml"
                    </span>
                  </form>
                </div>
              </div>
            )}
            {!this.state.flag && (
              <div id="screenlet_5" class="screenlet">
                <div class="screenlet-title-bar">
                  <ul>
                    <li class="h3">Add Additional Images</li>
                  </ul>
                </div>
                <div id="screenlet_5_col" class="screenlet-body">
                  <form id="addAdditionalImagesForm">
                    <input
                      id="additionalImageProductId"
                      name="productId"
                      type="hidden"
                      value="10000"
                    />
                    <table>
                      <tbody>
                        <tr>
                          <td> </td>
                          <td>
                            <input
                              id="additionalImageOne"
                              name="additionalImageOne"
                              size="20"
                              type="file"
                              ref={(ref) => {
                                this.ImageOne = ref;
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td> </td>
                          <td>
                            <input
                              name="additionalImageTwo"
                              size="20"
                              type="file"
                              ref={(ref) => {
                                this.ImageTwo = ref;
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td> </td>
                          <td>
                            <input
                              name="additionalImageThree"
                              size="20"
                              type="file"
                              ref={(ref) => {
                                this.ImageThree = ref;
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td> </td>
                          <td>
                            <input
                              name="additionalImageFour"
                              size="20"
                              type="file"
                              ref={(ref) => {
                                this.ImageFour = ref;
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td> </td>
                          <td>
                            <button onClick={(e) => this.handleUpload(e)}>
                              Upload
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
  handleUpload = (ev) => {
    ev.preventDefault();

    let formData = new FormData();
    const query = queryString.parse(this.props.location.search);
    const product = query.productId;
    let files = [];

    // files.push(this.uploadInput.files[0]);
    // files.push(this.ImageOne.files[0]);
    // files.push(this.ImageTwo.files[0]);

    formData.append("images", this.uploadInput.files[0]);
    formData.append("image_1", this.ImageOne.files[0]);
    formData.append("image_2", this.ImageTwo.files[0]);
    formData.append("image_3", this.ImageThree.files[0]);
    formData.append("image_4", this.ImageFour.files[0]);

    formData.append("_id", product);
    this.props.update(formData);
  };
}

const mapStateToProps = (state) => ({
  token: state?.auth?.token,
  submitted: state?.catalog?.submitted,
  categories: state?.catalog?.categories?.active,
  content: state?.catalog?.contentReducer?.content,
});

const mapDispatchToProps = (dispatch, router) => ({
  save: (data) => dispatch(createProduct(data, router)),
  update: (data) => dispatch(updateProduct(data, router)),
  allCategories: () => dispatch(allActiveCategories()),
  productDetails: (id) => dispatch(getProduct(id, router)),
  create: (data) => dispatch(createContent(data, router)),
  getActive: (id) => dispatch(getAllActiveContents(id, router)),
  updateContent: (data) => dispatch(updateContent(data, router)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditProductContent)
);
