import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import '../../styles.scss';

class EftAccount extends Component {

    render() {
        const {userById, apiAddresses} = this.props;
        const query = new URLSearchParams(this.props?.location?.search);
        const partyId = query.get('partyId');

        return (
            <div id="searchOptions" className="screenlet" style={{marginTop: '10px'}}>
                <div className="screenlet-title-bar">
                    <ul>
                        <li className="h3">Add New EFT Account</li>
                    </ul>
                </div>

                <div id="searchOptions_col" className="screenlet-body">
                    <div id="search-options">
                        <form>
                            <table cellSpacing="0" className='basic-table'>
                                <tbody>
                                <tr>
                                    <td colSpan={2}>
                                        <Link className="buttontext create"
                                              to={`viewprofile?partyId=${partyId}&_id=${userById?._id}`}>Cancel/Done</Link> {' '}
                                        <button type='submit' className='buttontext create'>
                                            Save
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='label'>
                                        <label>Name Account</label>
                                    </td>
                                    <td>
                                        <input type='text' className='custom-input-width'/>
                                        <span className='required-text'>Required</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='label'>
                                        <label>Company Name on Account</label>
                                    </td>
                                    <td>
                                        <input type='text' className='custom-input-width'/>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='label'>
                                        <label>Bank Name</label>
                                    </td>
                                    <td>
                                        <input type='text' className='custom-input-width'/>
                                        <span className='required-text'>Required</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='label'>
                                        <label>Routing Number</label>
                                    </td>
                                    <td>
                                        <input type='text'/>
                                        <span className='required-text'>Required</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='label'>
                                        <label>Account Type</label>
                                    </td>
                                    <td>
                                        <select>
                                            <option> --Select one--</option>
                                            <option value='checking'>Checking</option>
                                            <option value='saving'>Saving</option>
                                        </select>
                                        <span className='required-text'>Required</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='label'>
                                        <label>Account Number</label>
                                    </td>
                                    <td>
                                        <input type='text'/>
                                        <span className='required-text'>Required</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='label'>
                                        <label>Decription</label>
                                    </td>
                                    <td>
                                        <input type='text'/>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='label'>
                                        <label>Billing Address</label>
                                    </td>
                                    <td>
                                        {
                                            apiAddresses.map((addr) => {
                                                return (
                                                    <div>
                                                        <input type="radio" name="address" id={addr._id}
                                                               value={addr._id}/>
                                                        <label htmlFor={addr._id}>
                                                            <span style={{marginLeft: '6px'}}>
                                                            <span style={{fontWeight: 'bold'}}>Shipping Destination Address</span>
                                                            <p>{addr.name}
                                                                <br/> {addr.recipientAddress}, {addr.recipientCity}</p>
                                                            <p>{addr.phone}</p>
                                                            (Created: {new Date(addr.createdDate).toLocaleString()})
                                                            </span>
                                                        </label>
                                                    </div>
                                                );
                                            })
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Link className="buttontext create"
                                              to={`viewprofile?partyId=${partyId}&_id=${userById?._id}`}>Cancel/Done</Link> {' '}
                                        <button type='submit' className='buttontext create'>
                                            Save
                                        </button>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                </div>

            </div>
        );

    }

}


const mapStateToProps = (state) => ({
    userById: state.partyManager?.users?.userById,
    apiAddresses: state.partyManager?.users?.apiAddresses
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EftAccount);
