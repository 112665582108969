import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { persistor, store } from "../../../store/index";
import { Notify } from "../../../constants/utils";
import axios from "axios";
import { getSigned } from "../../../store/actions/authActions";
import DraggableComponent from "../../components/DraggableComponent";
import CustomModal from "../../Catalog/containers/BulkBlock/CustomModal";
import UserPopUp from "./UserPopUp";
let storeUrl = process.env.REACT_APP_STORE_URL;

export class OrderEntry extends Component {
  constructor(props) {
    super(props);
    // create new customer form controls
    this.state = {
      name: "",
      email: "",
      mobile: "",
      countryCode: "",
      registgerModal: false,
      id: "",

      modalOpen: false,
    };
  }
  handleUserSelect = (id) => {
    this.setState({ modalOpen: false, id: id });
  };
  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  changeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  registgerModalHandler = () =>
    this.setState({ registgerModal: !this.state.registgerModal });

  createNewCustomer = async (event) => {
    event.preventDefault();
    const { token } = this.props;
    const { name, email, mobile } = this.state;
    const data = { name, email, mobile, channelSource: "vendor" };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}users/signupvendor`,
        data,
        { headers: { "access-token": token } }
      );

      if (res) {
        const storeURL = storeUrl;
        // window.open(`${storeURL}?meta=${res.data.data.token}`, "_blank");
        window.open(`${storeURL}/?tkn=${res.data.data.token}`, "_blank");

        this.setState({
          name: "",
          email: "",
          mobile: "",
        });

        Notify("success", res.data.message, "Success");
      }
    } catch (err) {
      Notify("danger", err.message, "Error");
    }
  };

  orderRedirect = async () => {
    let token = "";
    const storeURL = storeUrl;
    if (this.state.id) {
      let user = await this.props.getSigned(this.state.id);
      if (user) {
        token = user.token;
        window.open(
          `${storeURL}?meta=${token}&medium=CAll_CENTER&channel=FNP_STAFF`,
          "_blank"
        );
      }
    } else {
      token = store.getState().auth.token;
      window.open(
        `${storeURL}?meta=${token}&medium=CAll_CENTER&channel=FNP_STAFF`,
        "_blank"
      );
    }
    this.setState({ id: "" });
  };

  render() {
    return (
      <div id="content-main-section">
        <div className="screenlet">
          <div className="screenlet-title-bar">
            <ul>
              <li className="h3">Place customer order</li>
            </ul>
          </div>
          <div className="screenlet-body">
            <form
              action="https://data.fnp.ae/control/initorderentry"
              method="post"
              name="salesentryform"
              target="_blank"
            >
              <input name="originOrderId" type="hidden" value="" />
              <input
                name="externalLoginKey"
                type="hidden"
                value="EL108280956653"
              />
              <input name="finalizeMode" type="hidden" value="type" />
              <input name="orderMode" type="hidden" value="SALES_ORDER" />
              <table border="0" width="100%" cellspacing="0" cellpadding="0">
                <tbody>
                  {/* <tr>
                    <td></td>
                    <td
                      align="right"
                      valign="middle"
                      nowrap="nowrap"
                      width="300"
                    >
                      <div className="tableheadtext">Product Store</div>
                    </td>
                    <td></td>
                    <td valign="middle">
                      <div className="tabletext">
                        <select name="productStoreId">
                          <option value="30000">GAB</option>
                          <option value="30000">----</option>
                          <option value="30000">GAB</option>
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td align="right" valign="middle" nowrap="nowrap">
                      <div className="tableheadtext">External Order Id</div>
                    </td>
                    <td></td>
                    <td valign="middle">
                      <input name="externalId" type="text" value="" />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4"></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td align="right" valign="middle" nowrap="nowrap">
                      <div className="tableheadtext">Sales Channel</div>
                    </td>
                    <td></td>
                    <td valign="middle">
                      <div className="tabletext">
                        <select
                          id="selectedSalesChannel"
                          name="salesChannelEnumId"
                          onchange="getTrackingDetails(this)"
                        >
                          <option value="AFFIL_SALES_CHANNEL">
                            Affiliate Channel
                          </option>
                          <option value="AFFIL_SALES_CHANNEL">----</option>
                          <option value="">No Channel</option>
                          <option value="AFFIL_SALES_CHANNEL">
                            Affiliate Channel
                          </option>
                          <option value="CORPORATE">Corporate</option>
                          <option value="SHOPHO">Shop HO</option>
                          <option value="BUSINESS_PROMOTION">
                            Business Promotion
                          </option>
                          <option value="COMPL_DELIVERY">
                            Complimentary Delivery
                          </option>
                          <option value="CALLCTR_ABC">
                            Call Center-abandoned cart
                          </option>
                          <option value="CALLCTR_CBL">Call Center-cbl</option>
                          <option value="CALLCTR_ODB">Call Center-odb</option>
                          <option value="CALLCTR_BMF_ORDERS">
                            Call Center-BMF Orders
                          </option>
                          <option value="CALLCTR_ORDERCALL">
                            Call Center-ordercall
                          </option>
                          <option value="CALLCTR_CHAT">Call Center-Chat</option>
                          <option value="CALLCTR_PNC">Call Center-pnc</option>
                          <option value="CALLCTR_PNI">Call Center-PNI</option>
                          <option value="CALLCTR_OTHERS">
                            Call Center-others
                          </option>
                          <option value="CALLCTR_FNP_STAFF">
                            Call Center-FNP Staff
                          </option>
                          <option value="FNP_STAFF">FNP Staff</option>
                          <option value="WEBSITES">WEBSITES</option>
                          <option value="SHOPHO_DIRECT_VENDOR">
                            SHOPHO DIRECT VENDOR
                          </option>
                          <option value="SHOPHO_FNP_COCO">
                            SHOPHO FNP-COCO
                          </option>
                          <option value="SHOPHO_FRANCHISEE">
                            SHOPHO FNP-Franchisee
                          </option>
                          <option value="SHOPHO_INTERNATIONAL">
                            SHOPHO INTERNATIONAL
                          </option>
                          <option value="SHOPHO_NON_FNP">SHOPHO NON FNP</option>
                        </select>
                      </div>
                      <strong className="salesChannelError">
                        Please Select Sales Channel
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4"></td>
                  </tr>
                  <tr id="trackingCodeDropDown">
                    <td></td>
                    <td
                      align="right"
                      valign="middle"
                      nowrap="nowrap"
                      width="300"
                    >
                      <div className="tableheadtext">Tracking Code</div>
                    </td>
                    <td></td>
                    <td valign="middle">
                      <div className="tabletext">
                        <select
                          id="trackingCodeId"
                          name="orderEntryTrackingCodeId"
                        ></select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4"></td>
                  </tr> */}
                  <tr>
                    <td></td>
                    <td align="right" valign="middle" nowrap="nowrap">
                      <div className="tableheadtext">Customer</div>
                    </td>
                    <td></td>
                    <td valign="middle">
                      <div className="tabletext">
                        <div id="0_lookupId_partyId_auto"></div>
                        <span className="field-lookup">
                          <input
                            id="0_lookupId_partyId"
                            className="ui-autocomplete-input"
                            autocomplete="off"
                            maxlength="20"
                            value={this.state.id}
                            name="partyId"
                            onChange={(e) =>
                              this.setState({ id: e.target.value })
                            }
                            size="20"
                            type="number"
                          />
                          <a
                            href
                            style={{ cursor: "pointer" }}
                            id="0_lookupId_button"
                            onClick={(e) => this.setState({ modalOpen: true })}
                          >
                            {" "}
                          </a>
                        </span>{" "}
                        <a
                          href
                          className="buttontext"
                          title="Create New Customer"
                          onClick={this.registgerModalHandler}
                        >
                          Create New Customer
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td align="right" valign="middle" nowrap="nowrap">
                      {" "}
                    </td>
                    <td></td>
                    <td valign="middle">
                      <input
                        type="button"
                        value="Continue"
                        onClick={this.orderRedirect}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
        <CustomModal
          active={this.state.registgerModal}
          modalController={this.registgerModalHandler}
          bgDisable={false}
        >
          {/* <DraggableComponent> */}
          <form onSubmit={this.createNewCustomer}>
            <input name="isBackendSignUp" type="hidden" value="Y" />
            <input name="productStoreId" type="hidden" value="10000" />
            <input id="hiddenSalesChannel" name="salesChannel" type="hidden" />
            <div>
              <label for="USER_FIRST_NAME">
                Name<span className="require">*</span>
              </label>
              <br />
              <input
                maxlength="65"
                name="USER_FIRST_NAME"
                required="true"
                type="text"
                name="name"
                onChange={this.changeHandler}
                value={this.state.name}
              />
            </div>
            <br />
            <div>
              <label for="USERNAME">
                Email ID<span className="require">*</span>
              </label>
              <br />
              <input
                maxlength="250"
                name="USERNAME"
                required="true"
                type="email"
                name="email"
                onChange={this.changeHandler}
                value={this.state.email}
              />
            </div>
            <br />
            <div>
              <label for="CUSTOMER_MOBILE_CONTACT">
                Phone number<span className="require">*</span>
              </label>
              <br />
              <input
                className="countryCode"
                maxlength="10"
                name="countryCode"
                type="text"
                value={this.state.countryCode}
                onChange={(e) => this.setState({ countryCode: e.target.value })}
                placeholder="+91"
              />{" "}
              <input
                className="phoneNumber"
                title="Numbers in between [0-9]"
                maxlength="13"
                name="CUSTOMER_MOBILE_CONTACT"
                pattern="^[0-9]*$"
                required=""
                type="text"
                onChange={this.changeHandler}
                value={this.state.mobile}
              />
            </div>
            <br />
            <div>
              <button type="submit">Create</button>
            </div>
          </form>
          {/* </DraggableComponent> */}
        </CustomModal>
        <UserPopUp
          modalOpen={this.state.modalOpen}
          handleClose={this.handleClose}
          handleUserSelect={this.handleUserSelect}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state?.auth?.token,
});

const mapDispatchToProps = (dispatch, router) => ({
  getSigned: (data) => dispatch(getSigned(data, router)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderEntry)
);
