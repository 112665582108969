import React from "react";
import {Modal, Collapse} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {editSender} from "../../store/actions/orderActions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {putRequest} from "../../store/services";

class RefundPopUp extends React.Component {
    state = {
        refundType: 'GC',
        remarks: "",
        bankDetails: '',
    }
    initiateRefund = async () => {
        let data = {};

        const query = new URLSearchParams(this.props.location.search);
        const queryOrderId = query.get("orderId");
        data.id=queryOrderId;
        data.refundType = this.state.refundType;
        if (this.state.refundType === 'RA') {
            if (this.state.bankDetails) {
                data.BankDetails = this.state.bankDetails;
            }
        }
        if (this.state.remarks) {
            data.remarks = this.state.remarks;
        }
        let notesObj={
            orderId: queryOrderId,
            text: this.state.remarks,
            createdBy: this.props?.user?.name
        };

        await this.props.updateRefundStatus(data);
        await putRequest('notes/create', notesObj, false);
        this.props.handleClose();
    }

    render() {
        return (
            <div>
                <Modal
                    open={this.props.modalOpen}
                    onClose={this.props.handleClose}
                    //   style={{ overflow: "scroll" }}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div
                        style={{
                            height: "300px",
                            width: "350px",
                            backgroundColor: "white",
                            position: "absolute",
                            top: "50px",
                            border: "2px solid white",
                            left: "600px",
                            //alignItems: "center",
                            display: "flex",
                            overflow: "scroll",
                            flexDirection: "column",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                backgroundColor: "#527894",
                                borderRadius: "5px",
                                width: "100%",
                                height: "30px",
                                justifyContent: "flex-end",
                                alignItems: "center",
                            }}
                        >
                            <CloseIcon
                                onClick={(e) => this.props.handleClose(e)}
                                style={{
                                    color: "#6caad4",
                                    marginRight: "5px",
                                    backgroundColor: "#e3f1fc",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                }}
                            />
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <p>Which way would you like to compensate?</p>
                            <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                                <input type="radio" id="GC" name="gender" checked={this.state.refundType === 'GC'}
                                       value="GC"
                                       style={{fontSize: '15px'}}
                                       onClick={(e) => this.setState({refundType: e.target.value})}/>
                                <label htmlFor="GC" style={{fontSize: '15px', fontWeight: 'bold'}}>Gift
                                    Certificate</label>
                                <input type="radio" id="RA" name="gender" value="RA"
                                       checked={this.state.refundType === 'RA'} style={{fontSize: '15px'}}
                                       onClick={(e) => this.setState({refundType: e.target.value})}/>
                                <label htmlFor="RA" style={{fontSize: '15px', fontWeight: 'bold'}}>Refund Amount</label>
                            </div>
                        </div>
                        <div style={{marginTop: '10px'}}>
                            <label htmlFor={"remarks"} style={{fontWeight: 'bold'}}>Remarks</label>
                            <textarea id="remarks" rows={4} cols={50}
                                      onChange={(e) => this.setState({remarks: e.target.value})}></textarea>
                        </div>
                        {this.state.refundType === 'RA' && <div style={{marginTop: '10px'}}>
                            <label htmlFor={"bankDetails"} style={{fontWeight: 'bold'}}>Bank Details</label>
                            <textarea id="bankDetails" rows={4} cols={50}
                                      onChange={(e) => this.setState({bankDetails: e.target.value})}></textarea>
                        </div>}
                        <div style={{marginTop: '10px'}}>
                            <button onClick={() => this.initiateRefund()}>Initiate Refund</button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user:state?.auth?.user,
});
const mapDispatchToProps = (dispatch, router) => ({
    updateRefundStatus: (data) => dispatch(editSender(data, router)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RefundPopUp));
