import React, { Component } from "react";
import EditProductNav from "./EditProductNav";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { Notify } from "../../../../constants/utils";
import { connect } from "react-redux";
import { EditProduct } from "./EditProduct";
import {
  createProductPrice,
  getAllFeatures,
  updateProduct,
  getProductPrice,
  updateProductPrice,
  getAllProductPrice,
  deleteProductPrice,
} from "../../../../store/actions/catalogActions";
import PriceList from "./updatePrice";

class Prices extends Component {
  render() {
    const { productPrice, productId, internalName } = this.state;
    const query = queryString.parse(this.props.location.search);
    const product = query.productId;
    return (
      <div id="content-main-section" className="leftonly">
        <div id="centerdiv" className="no-clear">
          <EditProductNav
            product={product}
            internalName={internalName}
            expandAbles={
              <React.Fragment>
                <li class="buttontext expand-all">
                  <a
                    href
                    onClick={(e) => this.toggleA(e, "expand")}
                    class="buttontext expand-all"
                  >
                    Expand all
                  </a>
                </li>
                <li class="buttontext collapse-all">
                  <a
                    href
                    onClick={(e) => this.toggleA(e, "collapse")}
                    class="buttontext collapse-all"
                  >
                    Collapse all
                  </a>
                </li>
              </React.Fragment>
            }
            // mode={mode}
          />
          <div className="button-bar tab-bar">
            <p>
              Prices For:{internalName}[ID:{productId}]
            </p>
          </div>
          <div id="screenlet_1" className="screenlet">
            <div className="screenlet-title-bar">
              <ul>
                <li className="h3">Edit Product Prices</li>
              </ul>
            </div>
            <div id="screenlet_1_col" className="screenlet-body">
              <div className="nav-pager">
                <ul>
                  <li className="nav-first-disabled">First</li>
                  <li className="nav-previous-disabled">Previous</li>
                  <li className="nav-page-select">
                    Page
                    <select
                      name="page"
                      size="1"
                      onChange="submitPagination(this, '/catalog/control/EditProductPrices?productId=EXP5317&amp;VIEW_SIZE_1=200&amp;VIEW_INDEX_1='+this.value)"
                    >
                      <option selected="selected" value="0">
                        1
                      </option>
                    </select>
                  </li>
                  <li className="nav-next-disabled">Next</li>
                  <li className="nav-last-disabled">Last</li>
                  <li className="nav-pagesize">
                    <select
                      name="pageSize"
                      size="1"
                      onChange="submitPagination(this, '/catalog/control/EditProductPrices?productId=EXP5317&amp;VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')"
                    >
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option selected="selected" value="200">
                        200
                      </option>
                    </select>
                    Items per page
                  </li>
                  <li className="nav-displaying">Displaying 1 - 2 of 2</li>
                </ul>
              </div>
              <table className="basic-table" cellSpacing="0">
                <tbody>
                  <tr className="header-row">
                    <td>
                      <label
                        id="UpdateProductPrice_productPriceTypeId_title"
                        htmlFor="UpdateProductPrice_productPriceTypeId"
                      >
                        Price Type
                      </label>
                    </td>
                    <td>
                      <label
                        id="UpdateProductPrice_productPricePurposeId_title"
                        htmlFor="UpdateProductPrice_productPricePurposeId"
                      >
                        Purpose
                      </label>
                    </td>
                    <td>
                      <label
                        id="UpdateProductPrice_currencyUomId_title"
                        htmlFor="UpdateProductPrice_currencyUomId"
                      >
                        Currency
                      </label>
                    </td>
                    <td>
                      <label
                        id="UpdateProductPrice_productStoreGroupId_title"
                        htmlFor="UpdateProductPrice_productStoreGroupId"
                      >
                        Product Store Group
                      </label>
                    </td>
                    <td>
                      <label
                        id="UpdateProductPrice_fromDate_title"
                        htmlFor="UpdateProductPrice_fromDate"
                      >
                        From Date Time
                      </label>
                    </td>
                    <td>
                      <label
                        id="UpdateProductPrice_thruDate_title"
                        htmlFor="UpdateProductPrice_thruDate"
                      >
                        Through Date
                      </label>{" "}
                      -{" "}
                      <label
                        id="UpdateProductPrice_price_title"
                        htmlFor="UpdateProductPrice_price"
                      >
                        Price
                      </label>{" "}
                      -{" "}
                      <label
                        id="UpdateProductPrice_termUomId_title"
                        htmlFor="UpdateProductPrice_termUomId"
                      >
                        termUomId
                      </label>{" "}
                      -{" "}
                      <label
                        id="UpdateProductPrice_customPriceCalcService_title"
                        htmlFor="UpdateProductPrice_customPriceCalcService"
                      >
                        Custom Price Calc Service
                      </label>{" "}
                      -{" "}
                      <label
                        id="UpdateProductPrice_taxPercentage_title"
                        htmlFor="UpdateProductPrice_taxPercentage"
                      >
                        Tax Percentage
                      </label>{" "}
                      -{" "}
                      <label
                        id="UpdateProductPrice_taxAuthPartyId_title"
                        htmlFor="UpdateProductPrice_taxAuthPartyId"
                      >
                        Tax Authority Party
                      </label>{" "}
                      -{" "}
                      <label
                        id="UpdateProductPrice_taxAuthGeoId_title"
                        htmlFor="UpdateProductPrice_taxAuthGeoId"
                      >
                        Tax Auth Geo ID
                      </label>{" "}
                      -{" "}
                      <label
                        id="UpdateProductPrice_taxInPrice_title"
                        htmlFor="UpdateProductPrice_taxInPrice"
                      >
                        Tax In Price
                      </label>{" "}
                      -{" "}
                      <label
                        id="UpdateProductPrice_submitButton_title"
                        htmlFor="UpdateProductPrice_submitButton"
                      >
                        Update
                      </label>
                    </td>
                    <td>
                      <label
                        id="UpdateProductPrice_lastUpdatedByText_title"
                        htmlFor="UpdateProductPrice_lastUpdatedByText"
                      >
                        Last Modified By:
                      </label>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  {productPrice && productPrice.length ? (
                    productPrice.map((price) => {
                      return [
                        <tr>
                          <td>{price.priceType}</td>
                          <td>{price.purpose}</td>
                          <td>{price.currencyUom}</td>
                          <td>{price.productStoreGroupId}</td>
                          <td>{price.fromDate}</td>
                          <td>
                            <PriceList price={price} />
                          </td>
                          <td>
                            <p>[{price.lastModifiedBy}]</p>
                            {price.createdDate}
                          </td>
                          <td> </td>
                          <td>
                            <form name="UpdateProductPrice_o_0_0_o_deleteLink">
                              <input
                                name="fromDate"
                                type="hidden"
                                value="03-05-2019 10:24:12"
                              />
                              <input
                                name="currencyUomId"
                                type="hidden"
                                value="AED"
                              />
                              <input
                                name="VIEW_SIZE_1"
                                type="hidden"
                                value="200"
                              />
                              <input
                                name="productId"
                                type="hidden"
                                value="EXP5317"
                              />
                              <input
                                name="VIEW_INDEX_1"
                                type="hidden"
                                value="0"
                              />
                              <input
                                name="productPricePurposeId"
                                type="hidden"
                                value="PURCHASE"
                              />
                              <input
                                name="productStoreGroupId"
                                type="hidden"
                                value="30000"
                              />
                              <input
                                name="productPriceTypeId"
                                type="hidden"
                                value="DEFAULT_PRICE"
                              />
                            </form>
                            <input
                              className="buttontext"
                              type="button"
                              onClick={(event) => {
                                this.handleDelete(event, price._id);
                              }}
                              value="Delete"
                            />
                          </td>
                        </tr>,
                      ];
                    })
                  ) : (
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <form
                          id="UpdateProductPrice_o_0"
                          className="basic-form"
                          action="/catalog/control/updateProductPrice"
                          method="post"
                          onSubmit={this.handleUpdateSubmit}
                          name="UpdateProductPrice_o_0"
                          noValidate="novalidate"
                        >
                          <input
                            id="UpdateProductPrice_productId_o_0"
                            name="productId"
                            type="hidden"
                            value=""
                          />{" "}
                          <input
                            id="UpdateProductPrice_productPriceTypeId_o_0"
                            name="productPriceTypeId"
                            type="hidden"
                            value="DEFAULT_PRICE"
                          />{" "}
                          <input
                            id="UpdateProductPrice_productPricePurposeId_o_0"
                            name="productPricePurposeId"
                            type="hidden"
                            value="PURCHASE"
                          />{" "}
                          <input
                            id="UpdateProductPrice_currencyUomId_o_0"
                            name="currencyUomId"
                            type="hidden"
                            value=""
                          />{" "}
                          <input
                            id="UpdateProductPrice_productStoreGroupId_o_0"
                            name="productStoreGroupId"
                            type="hidden"
                            value=""
                          />{" "}
                          <input
                            id="UpdateProductPrice_fromDate_o_0"
                            name="fromDate"
                            type="hidden"
                            value=""
                          />
                          <span className="view-calendar">
                            <input
                              id="UpdateProductPrice_thruDate_o_0_i18n"
                              title="dd-MM-yyyy HH:mm:ss"
                              maxLength="30"
                              name="updatedThroughDate"
                              value=""
                              size="25"
                              type="date"
                            />
                            <button
                              className="ui-datepicker-trigger"
                              type="button"
                            ></button>{" "}
                            <input name="" type="hidden" value="Timestamp" />{" "}
                          </span>
                          <input
                            id="UpdateProductPrice_price_o_0"
                            name="updatedPrice"
                            size="25"
                            type="text"
                            value=""
                          />{" "}
                          *
                          <span className="ui-widget">
                            <select
                              id="UpdateProductPrice_termUomId_o_0"
                              name="updatedTermUomId"
                              size="1"
                              value=""
                            >
                              <option value=""> </option>
                              <option value="AREA_A">Area: Acre (A)</option>
                              <option value="AREA_a">Area: Are (a)</option>
                              <option value="AREA_cm2">
                                Area: Square Centimeter (cm2)
                              </option>
                              <option value="AREA_ft2">
                                Area: Square Foot (ft2)
                              </option>
                              <option value="AREA_ha">
                                Area: Hectare (ha)
                              </option>
                              <option value="AREA_in2">
                                Area: Square Inch (in2)
                              </option>
                              <option value="AREA_km2">
                                Area: Square Kilometer (km2)
                              </option>
                              <option value="AREA_m2">
                                Area: Square Meter (m2)
                              </option>
                              <option value="AREA_mi2">
                                Area: Square Mile (mi2)
                              </option>
                              <option value="AREA_mm2">
                                Area: Square Millimeter (mm2)
                              </option>
                              <option value="AREA_rd2">
                                Area: Square Rod (rd2)
                              </option>
                              <option value="AREA_yd2">
                                Area: Square Yard (yd2)
                              </option>
                              <option value="DATA_B">
                                Data Size: Byte of Data (B)
                              </option>
                              <option value="DATA_b">
                                Data Size: Bit of Data (b)
                              </option>
                              <option value="DATA_GB">
                                Data Size: Gigabyte of Data (GB)
                              </option>
                              <option value="DATA_Gb">
                                Data Size: Gigabit of Data (Gb)
                              </option>
                              <option value="DATA_KB">
                                Data Size: Kilobyte of Data (KB)
                              </option>
                              <option value="DATA_Kb">
                                Data Size: Kilobit of Data (Kb)
                              </option>
                              <option value="DATA_MB">
                                Data Size: Megabyte of Data (MB)
                              </option>
                              <option value="DATA_Mb">
                                Data Size: Megabit of Data (Mb)
                              </option>
                              <option value="DATA_PB">
                                Data Size: Petabyte of Data (PB)
                              </option>
                              <option value="DATA_Pb">
                                Data Size: Petabit of Data (Pb)
                              </option>
                              <option value="DATA_TB">
                                Data Size: Terabyte of Data (TB)
                              </option>
                              <option value="DATA_Tb">
                                Data Size: Terabit of Data (Tb)
                              </option>
                              <option value="DATASPD_bps">
                                Data Speed: Bit-per-second of Data (bps)
                              </option>
                              <option value="DATASPD_Gbps">
                                Data Speed: Gigabit-per-second of Data (Gbps)
                              </option>
                              <option value="DATASPD_Kbps">
                                Data Speed: Kilobit-per-second of Data (Kbps)
                              </option>
                              <option value="DATASPD_Mbps">
                                Data Speed: Megabit-per-second of Data (Mbps)
                              </option>
                              <option value="DATASPD_Tbps">
                                Data Speed: Terabit-per-second of Data (Tbps)
                              </option>
                              <option value="VDRY_cm3">
                                Dry Volume: Cubic centimeter (cm3)
                              </option>
                              <option value="VDRY_ft3">
                                Dry Volume: Cubic foot (ft3)
                              </option>
                              <option value="VDRY_in3">
                                Dry Volume: Cubic inch (in3)
                              </option>
                              <option value="VDRY_m3">
                                Dry Volume: Cubic meter (m3)
                              </option>
                              <option value="VDRY_mm3">
                                Dry Volume: Cubic millimeter (mm3)
                              </option>
                              <option value="VDRY_ST">
                                Dry Volume: Stere (cubic meter) (ST)
                              </option>
                              <option value="VDRY_yd3">
                                Dry Volume: Cubic yard (yd3)
                              </option>
                              <option value="EN_Btu">
                                Energy: British Thermal Unit (Btu)
                              </option>
                              <option value="EN_cal15">
                                Energy: Calorie (@15.5c) (cal15)
                              </option>
                              <option value="EN_HP">
                                Energy: Horsepower (mechanical) (HP)
                              </option>
                              <option value="EN_J">
                                Energy: Joule (absolute) (J)
                              </option>
                              <option value="EN_Kw">
                                Energy: Kilowatt (Kw)
                              </option>
                              <option value="EN_w">Energy: Watt (w)</option>
                              <option value="LEN_A">
                                Length: Angstrom (A)
                              </option>
                              <option value="LEN_cb">Length: Cable (cb)</option>
                              <option value="LEN_chG">
                                Length: Chain (Gunter's/surveyor's) (chG)
                              </option>
                              <option value="LEN_chR">
                                Length: Chain (Ramden's/engineer's) (chR)
                              </option>
                              <option value="LEN_cm">
                                Length: Centimeter (cm)
                              </option>
                              <option value="LEN_dam">
                                Length: Dekameter (dam)
                              </option>
                              <option value="LEN_dm">
                                Length: Decimeter (dm)
                              </option>
                              <option value="LEN_fm">
                                Length: Fathom (fm)
                              </option>
                              <option value="LEN_ft">Length: Foot (ft)</option>
                              <option value="LEN_fur">
                                Length: Furlong (fur)
                              </option>
                              <option value="LEN_hand">
                                Length: Hand (horse's height) (hand)
                              </option>
                              <option value="LEN_in">Length: Inch (in)</option>
                              <option value="LEN_km">
                                Length: Kilometer (km)
                              </option>
                              <option value="LEN_league">
                                Length: League (league)
                              </option>
                              <option value="LEN_lnG">
                                Length: Link (Gunter's) (lnG)
                              </option>
                              <option value="LEN_lnR">
                                Length: Link (Ramden's) (lnR)
                              </option>
                              <option value="LEN_m">Length: Meter (m)</option>
                              <option value="LEN_mi">
                                Length: Mile (statute/land) (mi)
                              </option>
                              <option value="LEN_mil">
                                Length: Mil (Milli-inch) (mil)
                              </option>
                              <option value="LEN_mm">
                                Length: Millimeter (mm)
                              </option>
                              <option value="LEN_nmi">
                                Length: Mile (nautical/sea) (nmi)
                              </option>
                              <option value="LEN_pica">
                                Length: Pica (type size) (pica)
                              </option>
                              <option value="LEN_point">
                                Length: Point (type size) (point)
                              </option>
                              <option value="LEN_rd">Length: Rod (rd)</option>
                              <option value="LEN_u">
                                Length: Micrometer (Micron) (u)
                              </option>
                              <option value="LEN_yd">Length: Yard (yd)</option>
                              <option value="VLIQ_bbl">
                                Liquid Volume: Barrel (US) (bbl)
                              </option>
                              <option value="VLIQ_cup">
                                Liquid Volume: Cup (cup)
                              </option>
                              <option value="VLIQ_dr">
                                Liquid Volume: Dram (US) (dr)
                              </option>
                              <option value="VLIQ_galUK">
                                Liquid Volume: Gallon (UK) (gal)
                              </option>
                              <option value="VLIQ_galUS">
                                Liquid Volume: Gallon (US) (gal)
                              </option>
                              <option value="VLIQ_gi">
                                Liquid Volume: Gill (1/4 UK pint) (gi)
                              </option>
                              <option value="VLIQ_HL">
                                Liquid Volume: Hectoliter (hL)
                              </option>
                              <option value="VLIQ_L">
                                Liquid Volume: Liter (L)
                              </option>
                              <option value="VLIQ_M3">
                                Liquid Volume: Cubic meter (liquid) (m3)
                              </option>
                              <option value="VLIQ_ml">
                                Liquid Volume: Milliliter (ml)
                              </option>
                              <option value="VLIQ_ozUK">
                                Liquid Volume: Ounce, fluid (UK) (fl. oz (UK))
                              </option>
                              <option value="VLIQ_ozUS">
                                Liquid Volume: Ounce, fluid (US) (fl. oz (US))
                              </option>
                              <option value="VLIQ_ptUK">
                                Liquid Volume: Pint (UK) (pt (UK))
                              </option>
                              <option value="VLIQ_ptUS">
                                Liquid Volume: Pint (US) (pt (US))
                              </option>
                              <option value="VLIQ_qt">
                                Liquid Volume: Quart (qt)
                              </option>
                              <option value="VLIQ_Tbs">
                                Liquid Volume: Tablespoon (Tbs)
                              </option>
                              <option value="VLIQ_tsp">
                                Liquid Volume: Teaspoon (tsp)
                              </option>
                              <option value="OTH_A">
                                Other: Amphere - Electric current (A)
                              </option>
                              <option value="OTH_box">Other: Box (bx)</option>
                              <option value="OTH_cd">
                                Other: Candela - Luminosity (intensity of light)
                                (cd)
                              </option>
                              <option value="OTH_ea">Other: Each (ea)</option>
                              <option value="OTH_mol">
                                Other: Mole - Substance (molecule) (mol)
                              </option>
                              <option value="OTH_pk">
                                Other: Package (pk)
                              </option>
                              <option value="OTH_pp">
                                Other: Per Person (pp)
                              </option>
                              <option value="TEMP_C">
                                Temperature: Degrees Celsius (C)
                              </option>
                              <option value="TEMP_F">
                                Temperature: Degrees Fahrenheit (F)
                              </option>
                              <option value="TEMP_K">
                                Temperature: Kelvin (K)
                              </option>
                              <option value="TF_century">
                                Time/Frequency: Time in Centuries (century)
                              </option>
                              <option value="TF_day">
                                Time/Frequency: Time in Days (day)
                              </option>
                              <option value="TF_decade">
                                Time/Frequency: Time in Decades (decade)
                              </option>
                              <option value="TF_hr">
                                Time/Frequency: Time in Hours (hr)
                              </option>
                              <option value="TF_millenium">
                                Time/Frequency: Time in Millenia (millenium)
                              </option>
                              <option value="TF_min">
                                Time/Frequency: Time in Minutes (min)
                              </option>
                              <option value="TF_mon">
                                Time/Frequency: Time in Months (mon)
                              </option>
                              <option value="TF_ms">
                                Time/Frequency: Time in Milli-Seconds (ms)
                              </option>
                              <option value="TF_s">
                                Time/Frequency: Time in Seconds (s)
                              </option>
                              <option value="TF_score">
                                Time/Frequency: Time in Scores (score)
                              </option>
                              <option value="TF_wk">
                                Time/Frequency: Time in Weeks (wk)
                              </option>
                              <option value="TF_yr">
                                Time/Frequency: Time in Years (yr)
                              </option>
                              <option value="WT_dr_avdp">
                                Weight: Dram (avdp) (dr avdp)
                              </option>
                              <option value="WT_dwt">
                                Weight: Pennyweight (dwt)
                              </option>
                              <option value="WT_g">Weight: Gram (g)</option>
                              <option value="WT_gr">Weight: Grain (gr)</option>
                              <option value="WT_kg">
                                Weight: Kilogram (kg)
                              </option>
                              <option value="WT_lb">
                                Weight: Pound (avdp) (lb)
                              </option>
                              <option value="WT_lt">
                                Weight: Ton (long or British) (lt)
                              </option>
                              <option value="WT_mg">
                                Weight: Milligram (mg)
                              </option>
                              <option value="WT_mt">
                                Weight: Ton (metric) (mt)
                              </option>
                              <option value="WT_oz">
                                Weight: Ounce (avdp) (oz)
                              </option>
                              <option value="WT_oz_tr">
                                Weight: Ounce (troy) (oz tr)
                              </option>
                              <option value="WT_sh_t">
                                Weight: Ton (short) (sh t)
                              </option>
                              <option value="WT_st">Weight: Stone (st)</option>
                            </select>
                          </span>
                          <span className="ui-widget">
                            <select
                              id="UpdateProductPrice_customPriceCalcService_o_0"
                              name="updatedCustomPriceCalculation"
                              size="1"
                              value=""
                            >
                              <option value="">---</option>
                              <option value="Y">Y</option>
                              <option value="N">N</option>
                            </select>
                          </span>
                          <input
                            name="updatedTaxPersentage"
                            size="25"
                            type="text"
                            value=""
                            onChange=""
                          />{" "}
                          <input
                            id="UpdateProductPrice_taxAuthPartyId_o_0"
                            maxLength="20"
                            name="updatedTaxAuthorityParty"
                            size="20"
                            type="text"
                            value=""
                          />{" "}
                          <input
                            id="UpdateProductPrice_taxAuthGeoId_o_0"
                            maxLength="20"
                            name="updatedTaxAuthGeoId"
                            size="20"
                            type="text"
                            value=""
                          />
                          <span className="ui-widget">
                            <select
                              id="UpdateProductPrice_taxInPrice_o_0"
                              value=""
                              name="updatedTaxInPrice"
                              size="1"
                            >
                              <option value="">---</option>
                              <option value="Y">Y</option>
                              <option value="N">N</option>
                            </select>
                          </span>
                          <input
                            name="submitButton"
                            type="button"
                            value="Update"
                          />
                        </form>
                      </td>
                      <td></td>
                      <td></td>
                      <td> </td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="nav-pager">
                <ul>
                  <li className="nav-first-disabled">First</li>
                  <li className="nav-previous-disabled">Previous</li>
                  <li className="nav-page-select">
                    Page
                    <select
                      name="page"
                      size="1"
                      onChange="submitPagination(this, '/catalog/control/EditProductPrices?productId=EXP5317&amp;VIEW_SIZE_1=200&amp;VIEW_INDEX_1='+this.value)"
                    >
                      <option selected="selected" value="0">
                        1
                      </option>
                    </select>
                  </li>
                  <li className="nav-next-disabled">Next</li>
                  <li className="nav-last-disabled">Last</li>
                  <li className="nav-pagesize">
                    <select
                      name="pageSize"
                      size="1"
                      onChange="submitPagination(this, '/catalog/control/EditProductPrices?productId=EXP5317&amp;VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')"
                    >
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option selected="selected" value="200">
                        200
                      </option>
                    </select>
                    Items per page
                  </li>
                  <li className="nav-displaying">Displaying 1 - 2 of 2</li>
                </ul>
              </div>
            </div>
          </div>
          <div id="screenlet_2" className="screenlet">
            <div className="screenlet-title-bar">
              <ul>
                <li className="h3">Add Product Prices</li>
              </ul>
            </div>
            <div id="screenlet_2_col" className="screenlet-body">
              <h3>
                You need to set a least a Default Price of type Purchase/Initial
                for the price calculation to work
              </h3>
              <input
                id="AddProductPrice_productId"
                name="productId"
                type="hidden"
                value="EXP5317"
              />
              <table className="basic-table" cellSpacing="0">
                <tbody>
                  <tr>
                    <td className="label">
                      <label
                        id="AddProductPrice_productPriceTypeId_title"
                        htmlFor="AddProductPrice_productPriceTypeId"
                      >
                        Price Type
                      </label>
                    </td>
                    <td>
                      <span className="ui-widget">
                        <select
                          id="AddProductPrice_productPriceTypeId"
                          name="priceType"
                          size="1"
                          onChange={this.handleChange}
                        >
                          <option value="AVERAGE_COST">Average Cost</option>
                          <option value="BOX_PRICE">Box Price</option>
                          <option value="COMPETITIVE_PRICE">
                            Competitive Price
                          </option>
                          <option selected="selected" value="DEFAULT_PRICE">
                            Default Price
                          </option>
                          <option value="LIST_PRICE">List Price</option>
                          <option value="WEB_PRICE">Web Price</option>
                          <option value="MAXIMUM_PRICE">Maximum Price</option>
                          <option value="MINIMUM_ORDER_PRICE">
                            Minimum order Price
                          </option>
                          <option value="MINIMUM_PRICE">Minimum Price</option>
                          <option value="PROMO_PRICE">Promotional Price</option>
                          <option value="SPECIAL_PROMO_PRICE">
                            Special Promo Price
                          </option>
                          <option value="WHOLESALE_PRICE">
                            Wholesale Price
                          </option>
                        </select>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="label">
                      <label
                        id="AddProductPrice_productPricePurposeId_title"
                        htmlFor="AddProductPrice_productPricePurposeId"
                      >
                        Purpose
                      </label>
                    </td>
                    <td>
                      <span className="ui-widget">
                        <select
                          id="AddProductPrice_productPricePurposeId"
                          name="purpose"
                          size="1"
                          onChange={this.handleChange}
                        >
                          <option value="COMPONENT_PRICE">
                            Component Price
                          </option>
                          <option value="EBAY">eBay Auction</option>
                          <option selected="selected" value="PURCHASE">
                            Purchase/Initial
                          </option>
                          <option value="RECURRING_CHARGE">
                            Recurring Charge
                          </option>
                          <option value="USAGE_CHARGE">Usage Charge</option>
                        </select>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="label">
                      <label
                        id="AddProductPrice_currencyUomId_title"
                        htmlFor="AddProductPrice_currencyUomId"
                      >
                        Currency Uom Id
                      </label>
                    </td>
                    <td>
                      <span className="ui-widget">
                        <select
                          id="AddProductPrice_currencyUomId"
                          name="currencyUom"
                          size="1"
                          onChange={this.handleChange}
                        >
                          <option value="AFA">Afghani - AFA</option>
                          <option value="ALL">Albanian Lek - ALL</option>
                          <option value="DZD">Algerian Dinar - DZD</option>
                          <option value="ADP">Andoran peseta - ADP</option>
                          <option value="AOK">Angolan Kwanza - AOK</option>
                          <option value="ARS">Argentina Peso - ARS</option>
                          <option value="ARA">Argentinian Austral - ARA</option>
                          <option value="AMD">Armenian Dram - AMD</option>
                          <option value="AWG">Aruban Guilder - AWG</option>
                          <option value="AUD">Australian Dollar - AUD</option>
                          <option value="AZM">Azerbaijan Manat - AZM</option>
                          <option value="BSD">Bahaman Dollar - BSD</option>
                          <option value="BHD">Bahrain Dinar - BHD</option>
                          <option value="BDT">Bangladesh Taka - BDT</option>
                          <option value="BBD">Barbados Dollar - BBD</option>
                          <option value="BZD">Belize Dollar - BZD</option>
                          <option value="BYR">Belorussian Ruble - BYR</option>
                          <option value="XOF">Benin C.f.A. Franc - XOF</option>
                          <option value="BMD">Bermudan Dollar - BMD</option>
                          <option value="BOB">Bolivian Boliviano - BOB</option>
                          <option value="BAD">
                            Bosnia-Herzogovinian Dinar - BAD
                          </option>
                          <option value="BWP">Botswana Pula - BWP</option>
                          <option value="BRR">Brazil - BRR</option>
                          <option value="BRL">Brazilian Real - BRL</option>
                          <option value="GBP">British Pound - GBP</option>
                          <option value="BND">Brunei Dollar - BND</option>
                          <option value="BGN">Bulgarian Lev - BGN</option>
                          <option value="BIF">Burundi Franc - BIF</option>
                          <option value="KHR">Cambodian Riel - KHR</option>
                          <option value="CAD">Canadian Dollar - CAD</option>
                          <option value="CVE">Cape Verde Escudo - CVE</option>
                          <option value="KYD">Cayman Dollar - KYD</option>
                          <option value="CLP">Chilean Peso - CLP</option>
                          <option value="CNY">China - CNY</option>
                          <option value="COP">Colombian Peso - COP</option>
                          <option value="KMF">Comoros Franc - KMF</option>
                          <option value="CRC">Costa Rica Colon - CRC</option>
                          <option value="HRK">Croatian Kuna - HRK</option>
                          <option value="CUP">Cuban Peso - CUP</option>
                          <option value="CYP">Cyprus Pound - CYP</option>
                          <option value="CZK">Czech Krona - CZK</option>
                          <option value="DKK">Danish Krone - DKK</option>
                          <option value="DJF">Djibouti Franc - DJF</option>
                          <option value="DOP">Dominican Peso - DOP</option>
                          <option value="DRP">
                            Dominican Republic Peso - DRP
                          </option>
                          <option value="XCD">
                            East Carribean Dollar - XCD
                          </option>
                          <option value="ECS">Ecuador Sucre - ECS</option>
                          <option value="EGP">Egyptian Pound - EGP</option>
                          <option value="SVC">El Salvador Colon - SVC</option>
                          <option value="EEK">Estonian Krone - EEK</option>
                          <option value="ETB">Ethiopian Birr - ETB</option>
                          <option value="EUR">Euro - EUR</option>
                          <option value="FKP">Falkland Pound - FKP</option>
                          <option value="FJD">Fiji Dollar - FJD</option>
                          <option value="XAF">Gabon C.f.A Franc - XAF</option>
                          <option value="GMD">Gambian Dalasi - GMD</option>
                          <option value="GEK">Georgian Kupon - GEK</option>
                          <option value="GHC">Ghanian Cedi - GHC</option>
                          <option value="GIP">Gibraltar Pound - GIP</option>
                          <option value="GTQ">Guatemalan Quedzal - GTQ</option>
                          <option value="GNF">Guinea Franc - GNF</option>
                          <option value="GWP">Guinea Peso - GWP</option>
                          <option value="GYD">Guyanese Dollar - GYD</option>
                          <option value="HTG">Haitian Gourde - HTG</option>
                          <option value="HNL">Honduran Lempira - HNL</option>
                          <option value="HKD">Hong Kong Dollar - HKD</option>
                          <option value="HUF">Hungarian forint - HUF</option>
                          <option value="ISK">Iceland Krona - ISK</option>
                          <option value="IDR">Indonesian Rupiah - IDR</option>
                          <option value="INR">Indian Rupee - INR</option>
                          <option value="IRR">Iranian Rial - IRR</option>
                          <option value="IQD">Iraqui Dinar - IQD</option>
                          <option value="JMD">Jamaican Dollar - JMD</option>
                          <option value="JPY">Japanese Yen - JPY</option>
                          <option value="JOD">Jordanian Dinar - JOD</option>
                          <option value="KZT">Kazakhstani Tenge - KZT</option>
                          <option value="KES">Kenyan Shilling - KES</option>
                          <option value="KIS">Kirghizstan Som - KIS</option>
                          <option value="KWD">Kuwaiti Dinar - KWD</option>
                          <option value="LAK">Laotian Kip - LAK</option>
                          <option value="LVL">Latvian Lat - LVL</option>
                          <option value="LBP">Lebanese Pound - LBP</option>
                          <option value="SLL">Leone - SLL</option>
                          <option value="LSL">Lesotho Loti - LSL</option>
                          <option value="LRD">Liberian Dollar - LRD</option>
                          <option value="LYD">Libyan Dinar - LYD</option>
                          <option value="LTL">Lithuanian Lita - LTL</option>
                          <option value="MOP">Macao Pataca - MOP</option>
                          <option value="MGF">Madagascan Franc - MGF</option>
                          <option value="MWK">Malawi Kwacha - MWK</option>
                          <option value="MYR">Malaysian Ringgit - MYR</option>
                          <option value="MVR">Maldive Rufiyaa - MVR</option>
                          <option value="MTL">Maltese Lira - MTL</option>
                          <option value="MRO">Mauritanian Ouguiya - MRO</option>
                          <option value="MUR">Mauritius Rupee - MUR</option>
                          <option value="MXN">Mexican Peso (new) - MXN</option>
                          <option value="MXP">Mexican Peso (old) - MXP</option>
                          <option value="MDL">Moldavian Lei - MDL</option>
                          <option value="MNT">Mongolian Tugrik - MNT</option>
                          <option value="MAD">Moroccan Dirham - MAD</option>
                          <option value="MZM">Mozambique Metical - MZM</option>
                          <option value="NPR">Nepalese Rupee - NPR</option>
                          <option value="NIS">New Israeli Shekel - NIS</option>
                          <option value="TWD">New Taiwan Dollar - TWD</option>
                          <option value="NZD">New Zealand Dollar - NZD</option>
                          <option value="NIC">Nicaragua - NIC</option>
                          <option value="NIO">Nicaraguan Cordoba - NIO</option>
                          <option value="NGN">Nigerian Naira - NGN</option>
                          <option value="KPW">North Korean Won - KPW</option>
                          <option value="NOK">Norwegian Krone - NOK</option>
                          <option value="OMR">Omani Rial - OMR</option>
                          <option value="PKR">Pakistan Rupee - PKR</option>
                          <option value="PAB">Panamanian Balboa - PAB</option>
                          <option value="PGK">
                            Papua New Guinea Kina - PGK
                          </option>
                          <option value="PYG">Paraguayan Guarani - PYG</option>
                          <option value="SOL">Peru - SOL</option>
                          <option value="PEI">Peruvian Inti - PEI</option>
                          <option value="PES">Peruvian Sol - PES</option>
                          <option value="PEN">Peruvian Sol - New - PEN</option>
                          <option value="PHP">Philippino Peso - PHP</option>
                          <option value="PLZ">Poland - PLZ</option>
                          <option value="PLN">Polish Zloty - PLN</option>
                          <option value="QAR">Qatar Riyal - QAR</option>
                          <option value="ROL">Romanian Leu - ROL</option>
                          <option value="RUR">Russian Rouble - RUR</option>
                          <option value="SUR">Russian Ruble (old) - SUR</option>
                          <option value="RWF">Rwanda Franc - RWF</option>
                          <option value="WST">Samoan Tala - WST</option>
                          <option value="CDP">Santo Domiongo - CDP</option>
                          <option value="STD">
                            Sao Tome / Principe Dobra - STD
                          </option>
                          <option value="SAR">Saudi Riyal - SAR</option>
                          <option value="SCR">Seychelles Rupee - SCR</option>
                          <option value="SGD">Singapore Dollar - SGD</option>
                          <option value="SBD">
                            Solomon Islands Dollar - SBD
                          </option>
                          <option value="SOS">Somalian Shilling - SOS</option>
                          <option value="ZAR">South African Rand - ZAR</option>
                          <option value="KRW">South Korean Won - KRW</option>
                          <option value="LKR">Sri Lankan Rupee - LKR</option>
                          <option value="SHP">St.Helena Pound - SHP</option>
                          <option value="SDP">Sudanese Pound - SDP</option>
                          <option value="SRG">Surinam Guilder - SRG</option>
                          <option value="SZL">Swaziland Lilangeni - SZL</option>
                          <option value="SEK">Swedish Krona - SEK</option>
                          <option value="CHF">Swiss Franc - CHF</option>
                          <option value="SYP">Syrian Pound - SYP</option>
                          <option value="TJR">Tadzhikistani Ruble - TJR</option>
                          <option value="TZS">Tanzanian Shilling - TZS</option>
                          <option value="THB">Thailand Baht - THB</option>
                          <option value="TPE">Timor Escudo - TPE</option>
                          <option value="TOP">Tongan Pa'anga - TOP</option>
                          <option value="TTD">
                            Trinidad and Tobago Dollar - TTD
                          </option>
                          <option value="TND">Tunisian Dinar - TND</option>
                          <option value="TRY">Turkish Lira - TRY</option>
                          <option value="TMM">Turkmenistani Manat - TMM</option>
                          <option value="UGS">Ugandan Shilling - UGS</option>
                          <option value="UAH">Ukrainian Hryvnia - UAH</option>
                          <option selected="selected" value="AED">
                            United Arab Emirates Dirham - AED
                          </option>
                          <option value="USD">American Dollar - USD</option>
                          <option value="UYU">Uruguay - UYU</option>
                          <option value="UYP">Uruguayan New Peso - UYP</option>
                          <option value="VUV">Vanuatu Vatu - VUV</option>
                          <option value="VEB">Venezuelan Bolivar - VEB</option>
                          <option value="VND">Vietnamese Dong - VND</option>
                          <option value="ANG">West Indian Guilder - ANG</option>
                          <option value="YER">Yemeni Ryal - YER</option>
                          <option value="ZRZ">Zaire - ZRZ</option>
                          <option value="ZMK">Zambian Kwacha - ZMK</option>
                          <option value="ZWD">Zimbabwean Dollar - ZWD</option>
                        </select>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="label">
                      <label
                        id="AddProductPrice_productStoreGroupId_title"
                        htmlFor="AddProductPrice_productStoreGroupId"
                      >
                        Product Store Group Id
                      </label>
                    </td>
                    <td>
                      <span className="ui-widget">
                        <select
                          id="AddProductPrice_productStoreGroupId"
                          name="productStoreGroupId"
                          size="1"
                          onChange={this.handleChange}
                        >
                          <option value="30000">FNP</option>
                          <option value="10000">FNP (10000)</option>
                          <option selected="selected" value="_NA_">
                            Not Applicable
                          </option>
                        </select>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="label">
                      <label
                        id="AddProductPrice_fromDate_title"
                        htmlFor="AddProductPrice_fromDate"
                      >
                        From Date
                      </label>
                      {}*
                    </td>
                    <td>
                      <span>
                        {" "}
                        <input
                          id="AddProductPrice_fromDate_i18n"
                          title="dd-MM-yyyy HH:mm:ss"
                          maxLength="30"
                          name="fromDate"
                          size="25"
                          type="date"
                          onChange={this.handleChange}
                        />{" "}
                        {/* <input
                          id="AddProductPrice_fromDate"
                          className="hasDatepicker heightWith"
                          title="dd-MM-yyyy HH:mm:ss"
                          maxLength="30"
                          name="fromDate"
                          size="25"
                          type="text"
                        />
                        <button
                          className="ui-datepicker-trigger"
                          type="button"
                        ></button>{" "}
                        <input name="" type="hidden" value="Timestamp" />{" "} */}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="label">
                      <label
                        id="AddProductPrice_thruDate_title"
                        htmlFor="AddProductPrice_thruDate"
                      >
                        Through Date
                      </label>
                      {}*
                    </td>
                    <td>
                      <span>
                        {" "}
                        <input
                          id="AddProductPrice_thruDate_i18n"
                          title="dd-MM-yyyy HH:mm:ss"
                          maxLength="30"
                          name="throughDate"
                          size="25"
                          type="date"
                          onChange={this.handleChange}
                        />{" "}
                        {/* <input
                          id="AddProductPrice_thruDate"
                          className="hasDatepicker heaightWith"
                          title="dd-MM-yyyy HH:mm:ss"
                          maxLength="30"
                          name="thruDate"
                          size="25"
                          type="text"
                        />
                        <button
                          className="ui-datepicker-trigger"
                          type="button"
                        ></button>{" "}
                        <input name="" type="hidden" value="Timestamp" />{" "} */}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="label">
                      <label
                        id="AddProductPrice_price_title"
                        htmlFor="AddProductPrice_price"
                      >
                        Price
                      </label>
                      {}*
                    </td>
                    <td>
                      <input
                        id="AddProductPrice_price"
                        className="required"
                        name="price"
                        size="25"
                        type="text"
                        onChange={this.handleChange}
                      />{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className="label">
                      <label
                        id="AddProductPrice_price_title"
                        htmlFor="AddProductPrice_price"
                      >
                        FeatureId
                      </label>
                    </td>
                    <td>
                      <select
                        name="cars"
                        id="cars"
                        value={this.state.featureId}
                        onChange={(e) =>
                          this.setState({ featureId: e.target.value })
                        }
                      >
                        <option value=""></option>
                        {this.props?.features.map((feature) => (
                          <option value={feature._id}>{feature.id}</option>
                        ))}
                      </select>
                    </td>
                  </tr>

                  <tr>
                    <td className="label">
                      <label
                        id="AddProductPrice_termUomId_title"
                        htmlFor="AddProductPrice_termUomId"
                      >
                        termUomId
                      </label>
                    </td>
                    <td>
                      <span className="ui-widget">
                        <select
                          id="AddProductPrice_termUomId"
                          name="termUomId"
                          size="1"
                          onChange={this.handleChange}
                        >
                          <option value=""> </option>
                          <option value="AREA_A">Area: Acre (A)</option>
                          <option value="AREA_a">Area: Are (a)</option>
                          <option value="AREA_ha">Area: Hectare (ha)</option>
                          <option value="AREA_cm2">
                            Area: Square Centimeter (cm2)
                          </option>
                          <option value="AREA_ft2">
                            Area: Square Foot (ft2)
                          </option>
                          <option value="AREA_in2">
                            Area: Square Inch (in2)
                          </option>
                          <option value="AREA_km2">
                            Area: Square Kilometer (km2)
                          </option>
                          <option value="AREA_m2">
                            Area: Square Meter (m2)
                          </option>
                          <option value="AREA_mi2">
                            Area: Square Mile (mi2)
                          </option>
                          <option value="AREA_mm2">
                            Area: Square Millimeter (mm2)
                          </option>
                          <option value="AREA_rd2">
                            Area: Square Rod (rd2)
                          </option>
                          <option value="AREA_yd2">
                            Area: Square Yard (yd2)
                          </option>
                          <option value="DATA_b">
                            Data Size: Bit of Data (b)
                          </option>
                          <option value="DATA_B">
                            Data Size: Byte of Data (B)
                          </option>
                          <option value="DATA_Gb">
                            Data Size: Gigabit of Data (Gb)
                          </option>
                          <option value="DATA_GB">
                            Data Size: Gigabyte of Data (GB)
                          </option>
                          <option value="DATA_Kb">
                            Data Size: Kilobit of Data (Kb)
                          </option>
                          <option value="DATA_KB">
                            Data Size: Kilobyte of Data (KB)
                          </option>
                          <option value="DATA_Mb">
                            Data Size: Megabit of Data (Mb)
                          </option>
                          <option value="DATA_MB">
                            Data Size: Megabyte of Data (MB)
                          </option>
                          <option value="DATA_Pb">
                            Data Size: Petabit of Data (Pb)
                          </option>
                          <option value="DATA_PB">
                            Data Size: Petabyte of Data (PB)
                          </option>
                          <option value="DATA_Tb">
                            Data Size: Terabit of Data (Tb)
                          </option>
                          <option value="DATA_TB">
                            Data Size: Terabyte of Data (TB)
                          </option>
                          <option value="DATASPD_bps">
                            Data Speed: Bit-per-second of Data (bps)
                          </option>
                          <option value="DATASPD_Gbps">
                            Data Speed: Gigabit-per-second of Data (Gbps)
                          </option>
                          <option value="DATASPD_Kbps">
                            Data Speed: Kilobit-per-second of Data (Kbps)
                          </option>
                          <option value="DATASPD_Mbps">
                            Data Speed: Megabit-per-second of Data (Mbps)
                          </option>
                          <option value="DATASPD_Tbps">
                            Data Speed: Terabit-per-second of Data (Tbps)
                          </option>
                          <option value="VDRY_cm3">
                            Dry Volume: Cubic centimeter (cm3)
                          </option>
                          <option value="VDRY_ft3">
                            Dry Volume: Cubic foot (ft3)
                          </option>
                          <option value="VDRY_in3">
                            Dry Volume: Cubic inch (in3)
                          </option>
                          <option value="VDRY_m3">
                            Dry Volume: Cubic meter (m3)
                          </option>
                          <option value="VDRY_mm3">
                            Dry Volume: Cubic millimeter (mm3)
                          </option>
                          <option value="VDRY_yd3">
                            Dry Volume: Cubic yard (yd3)
                          </option>
                          <option value="VDRY_ST">
                            Dry Volume: Stere (cubic meter) (ST)
                          </option>
                          <option value="EN_Btu">
                            Energy: British Thermal Unit (Btu)
                          </option>
                          <option value="EN_cal15">
                            Energy: Calorie (@15.5c) (cal15)
                          </option>
                          <option value="EN_HP">
                            Energy: Horsepower (mechanical) (HP)
                          </option>
                          <option value="EN_J">
                            Energy: Joule (absolute) (J)
                          </option>
                          <option value="EN_Kw">Energy: Kilowatt (Kw)</option>
                          <option value="EN_w">Energy: Watt (w)</option>
                          <option value="LEN_A">Length: Angstrom (A)</option>
                          <option value="LEN_cb">Length: Cable (cb)</option>
                          <option value="LEN_cm">
                            Length: Centimeter (cm)
                          </option>
                          <option value="LEN_chG">
                            Length: Chain (Gunter's/surveyor's) (chG)
                          </option>
                          <option value="LEN_chR">
                            Length: Chain (Ramden's/engineer's) (chR)
                          </option>
                          <option value="LEN_dm">Length: Decimeter (dm)</option>
                          <option value="LEN_dam">
                            Length: Dekameter (dam)
                          </option>
                          <option value="LEN_fm">Length: Fathom (fm)</option>
                          <option value="LEN_ft">Length: Foot (ft)</option>
                          <option value="LEN_fur">Length: Furlong (fur)</option>
                          <option value="LEN_hand">
                            Length: Hand (horse's height) (hand)
                          </option>
                          <option value="LEN_in">Length: Inch (in)</option>
                          <option value="LEN_km">Length: Kilometer (km)</option>
                          <option value="LEN_league">
                            Length: League (league)
                          </option>
                          <option value="LEN_lnG">
                            Length: Link (Gunter's) (lnG)
                          </option>
                          <option value="LEN_lnR">
                            Length: Link (Ramden's) (lnR)
                          </option>
                          <option value="LEN_m">Length: Meter (m)</option>
                          <option value="LEN_u">
                            Length: Micrometer (Micron) (u)
                          </option>
                          <option value="LEN_mil">
                            Length: Mil (Milli-inch) (mil)
                          </option>
                          <option value="LEN_nmi">
                            Length: Mile (nautical/sea) (nmi)
                          </option>
                          <option value="LEN_mi">
                            Length: Mile (statute/land) (mi)
                          </option>
                          <option value="LEN_mm">
                            Length: Millimeter (mm)
                          </option>
                          <option value="LEN_pica">
                            Length: Pica (type size) (pica)
                          </option>
                          <option value="LEN_point">
                            Length: Point (type size) (point)
                          </option>
                          <option value="LEN_rd">Length: Rod (rd)</option>
                          <option value="LEN_yd">Length: Yard (yd)</option>
                          <option value="VLIQ_bbl">
                            Liquid Volume: Barrel (US) (bbl)
                          </option>
                          <option value="VLIQ_M3">
                            Liquid Volume: Cubic meter (liquid) (m3)
                          </option>
                          <option value="VLIQ_cup">
                            Liquid Volume: Cup (cup)
                          </option>
                          <option value="VLIQ_dr">
                            Liquid Volume: Dram (US) (dr)
                          </option>
                          <option value="VLIQ_galUK">
                            Liquid Volume: Gallon (UK) (gal)
                          </option>
                          <option value="VLIQ_galUS">
                            Liquid Volume: Gallon (US) (gal)
                          </option>
                          <option value="VLIQ_gi">
                            Liquid Volume: Gill (1/4 UK pint) (gi)
                          </option>
                          <option value="VLIQ_HL">
                            Liquid Volume: Hectoliter (hL)
                          </option>
                          <option value="VLIQ_L">
                            Liquid Volume: Liter (L)
                          </option>
                          <option value="VLIQ_ml">
                            Liquid Volume: Milliliter (ml)
                          </option>
                          <option value="VLIQ_ozUK">
                            Liquid Volume: Ounce, fluid (UK) (fl. oz (UK))
                          </option>
                          <option value="VLIQ_ozUS">
                            Liquid Volume: Ounce, fluid (US) (fl. oz (US))
                          </option>
                          <option value="VLIQ_ptUK">
                            Liquid Volume: Pint (UK) (pt (UK))
                          </option>
                          <option value="VLIQ_ptUS">
                            Liquid Volume: Pint (US) (pt (US))
                          </option>
                          <option value="VLIQ_qt">
                            Liquid Volume: Quart (qt)
                          </option>
                          <option value="VLIQ_Tbs">
                            Liquid Volume: Tablespoon (Tbs)
                          </option>
                          <option value="VLIQ_tsp">
                            Liquid Volume: Teaspoon (tsp)
                          </option>
                          <option value="OTH_A">
                            Other: Amphere - Electric current (A)
                          </option>
                          <option value="OTH_box">Other: Box (bx)</option>
                          <option value="OTH_cd">
                            Other: Candela - Luminosity (intensity of light)
                            (cd)
                          </option>
                          <option value="OTH_ea">Other: Each (ea)</option>
                          <option value="OTH_mol">
                            Other: Mole - Substance (molecule) (mol)
                          </option>
                          <option value="OTH_pk">Other: Package (pk)</option>
                          <option value="OTH_pp">Other: Per Person (pp)</option>
                          <option value="TEMP_C">
                            Temperature: Degrees Celsius (C)
                          </option>
                          <option value="TEMP_F">
                            Temperature: Degrees Fahrenheit (F)
                          </option>
                          <option value="TEMP_K">
                            Temperature: Kelvin (K)
                          </option>
                          <option value="TF_century">
                            Time/Frequency: Time in Centuries (century)
                          </option>
                          <option value="TF_day">
                            Time/Frequency: Time in Days (day)
                          </option>
                          <option value="TF_decade">
                            Time/Frequency: Time in Decades (decade)
                          </option>
                          <option value="TF_hr">
                            Time/Frequency: Time in Hours (hr)
                          </option>
                          <option value="TF_millenium">
                            Time/Frequency: Time in Millenia (millenium)
                          </option>
                          <option value="TF_ms">
                            Time/Frequency: Time in Milli-Seconds (ms)
                          </option>
                          <option value="TF_min">
                            Time/Frequency: Time in Minutes (min)
                          </option>
                          <option value="TF_mon">
                            Time/Frequency: Time in Months (mon)
                          </option>
                          <option value="TF_score">
                            Time/Frequency: Time in Scores (score)
                          </option>
                          <option value="TF_s">
                            Time/Frequency: Time in Seconds (s)
                          </option>
                          <option value="TF_wk">
                            Time/Frequency: Time in Weeks (wk)
                          </option>
                          <option value="TF_yr">
                            Time/Frequency: Time in Years (yr)
                          </option>
                          <option value="WT_dr_avdp">
                            Weight: Dram (avdp) (dr avdp)
                          </option>
                          <option value="WT_gr">Weight: Grain (gr)</option>
                          <option value="WT_g">Weight: Gram (g)</option>
                          <option value="WT_kg">Weight: Kilogram (kg)</option>
                          <option value="WT_mg">Weight: Milligram (mg)</option>
                          <option value="WT_oz">
                            Weight: Ounce (avdp) (oz)
                          </option>
                          <option value="WT_oz_tr">
                            Weight: Ounce (troy) (oz tr)
                          </option>
                          <option value="WT_dwt">
                            Weight: Pennyweight (dwt)
                          </option>
                          <option value="WT_lb">
                            Weight: Pound (avdp) (lb)
                          </option>
                          <option value="WT_st">Weight: Stone (st)</option>
                          <option value="WT_lt">
                            Weight: Ton (long or British) (lt)
                          </option>
                          <option value="WT_mt">
                            Weight: Ton (metric) (mt)
                          </option>
                          <option value="WT_sh_t">
                            Weight: Ton (short) (sh t)
                          </option>
                        </select>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="label">
                      <label
                        id="AddProductPrice_customPriceCalcService_title"
                        htmlFor="AddProductPrice_customPriceCalcService"
                      >
                        Custom Price Calc Service
                      </label>
                    </td>
                    <td>
                      <span className="ui-widget">
                        <select
                          id="AddProductPrice_customPriceCalcService"
                          name="customPriceCalculation"
                          size="1"
                          onChange={this.handleChange}
                        >
                          <option value="Y">Y </option>
                          <option value="N">N </option>
                        </select>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="label">
                      <label
                        id="AddProductPrice_taxPercentage_title"
                        htmlFor="AddProductPrice_taxPercentage"
                      >
                        Tax Percentage
                      </label>
                    </td>
                    <td>
                      <input
                        id="AddProductPrice_taxPercentage"
                        name="taxPersentage"
                        size="25"
                        type="text"
                        onChange={this.handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="label">
                      <label
                        id="AddProductPrice_taxAuthPartyId_title"
                        htmlFor="AddProductPrice_taxAuthPartyId"
                      >
                        Tax Authority Party
                      </label>
                    </td>
                    <td>
                      <input
                        id="AddProductPrice_taxAuthPartyId"
                        maxLength="20"
                        name="taxAuthorityParty"
                        size="20"
                        type="text"
                        onChange={this.handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="label">
                      <label
                        id="AddProductPrice_taxAuthGeoId_title"
                        htmlFor="AddProductPrice_taxAuthGeoId"
                      >
                        Tax Auth Geo ID
                      </label>
                    </td>
                    <td>
                      <input
                        id="AddProductPrice_taxAuthGeoId"
                        maxLength="20"
                        name="taxAuthGeoId"
                        size="20"
                        type="text"
                        onChange={this.handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="label">
                      <label
                        id="AddProductPrice_taxInPrice_title"
                        htmlFor="AddProductPrice_taxInPrice"
                      >
                        Tax In Price
                      </label>
                    </td>
                    <td>
                      <span className="ui-widget">
                        <select
                          id="AddProductPrice_taxInPrice"
                          name="taxInPrice"
                          size="1"
                          onChange={this.handleChange}
                        >
                          <option value="Y">Y</option>
                          <option value="N">N</option>
                        </select>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="label">
                      <label
                        id="AddProductPrice_taxAuthCombinedId_title"
                        htmlFor="AddProductPrice_taxAuthCombinedId"
                      >
                        Tax Auth Combined Id
                      </label>
                    </td>
                    <td>
                      <input
                        id="AddProductPrice_taxAuthCombinedId"
                        name="taxAuthCombinedId"
                        size="25"
                        type="text"
                        onChange={this.handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="label"></td>
                    <td colSpan="4">
                      <input
                        className="smallSubmit"
                        name="submitButton"
                        type="button"
                        value="Create"
                        onClick={this.handleSubmit}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      priceType: "Default Price",
      purpose: "Purchase/Initial",
      currencyUom: "AED",
      productStoreGroupId: "Not Applicable",
      fromDate: "",
      throughDate: "",
      price: "",
      termUomId: "",
      customPriceCalculation: true,
      taxPersentage: "",
      taxAuthorityParty: "",
      taxAuthGeoId: "",
      taxInPrice: true,
      taxAuthCombinedId: "",
      productPrice: [],
      productId: "",
      internalName: "",
      mode: "",
      featureId: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const url = new URLSearchParams(this.props?.location?.search);
    const productId = url?.get("productId");
    this.setState({ productId: productId });
    this.props.getAllFeatures();
    const response = await this.props.getProductPrice(productId);
    if (response.data) {
      this.setState({
        productPrice: response.data.Price,
        internalName: response.data.title,
      });
    }
  }

  handleChange(event) {
    const fieldName = event.target.name;
    let fieldValue = "";
    if (event.target.value === "Y") {
      fieldValue = true;
    } else if (event.target.value === "N") {
      fieldValue = false;
    } else {
      fieldValue = event.target.value;
    }
    this.setState({ [fieldName]: fieldValue });
  }
  async handleDelete(event, id) {
    const response = await this.props.deleteProductPrice(id);
  }
  async handleSubmit(event) {
    event.preventDefault();
    const {
      priceType,
      purpose,
      currencyUom,
      productStoreGroupId,
      fromDate,
      throughDate,
      price,
      termUomId,
      customPriceCalculation,
      taxPersentage,
      taxAuthorityParty,
      taxAuthGeoId,
      taxInPrice,
      featureId,
      taxAuthCombinedId,
    } = this.state;
    if (fromDate === "" || throughDate === "" || price === "") {
      Notify("danger", "Please fill all the fields marked *.", "Error");
      return;
    }
    const url = new URLSearchParams(this.props?.location?.search);
    const productId = url?.get("productId");
    let auth = JSON.parse(localStorage.getItem("persist:auth"));
    const user = JSON.parse(auth.auth);
    let totalPrice = null;
    const userName = user.user.email;
    //  if (taxPersentage) {
    //   const persentage =    (price * taxPersentage)/100
    //   totalPrice = price - persentage;
    //  }

    let data = {};
    data = userName ? { ...data, lastModifiedBy: userName } : data;
    data = productId ? { ...data, productId: productId } : data;
    data = priceType ? { ...data, priceType: priceType } : data;
    data = purpose ? { ...data, purpose: purpose } : data;
    data = currencyUom ? { ...data, currencyUom: currencyUom } : data;
    data = productStoreGroupId
      ? { ...data, productStoreGroupId: productStoreGroupId }
      : data;
    data = fromDate ? { ...data, fromDate: fromDate } : data;
    data = throughDate ? { ...data, throughDate: throughDate } : data;
    data = price ? { ...data, price: totalPrice ? totalPrice : price } : data;
    data = termUomId ? { ...data, termUomId: termUomId } : data;
    data = customPriceCalculation
      ? { ...data, customPriceCalculation: customPriceCalculation }
      : data;
    data = taxPersentage ? { ...data, taxPersentage: taxPersentage } : data;
    data = taxAuthorityParty
      ? { ...data, taxAuthorityParty: taxAuthorityParty }
      : data;
    data = taxAuthGeoId ? { ...data, taxAuthGeoId: taxAuthGeoId } : data;
    data = taxInPrice ? { ...data, taxInPrice: taxInPrice } : data;
    data = taxAuthCombinedId
      ? { ...data, taxAuthCombinedId: taxAuthCombinedId }
      : data;
    data = featureId ? { ...data, featureId } : { ...data };
    //
    const response = await this.props.createProductPrice(data);
  }
}
const mapStateToProps = (state) => ({
  submitted: state?.catalog?.products?.submitted,
  categories: state?.catalog?.categories?.active,
  features: state?.catalog?.catalogs?.features,
});

const mapDispatchToProps = (dispatch, router) => ({
  createProductPrice: (data) => dispatch(createProductPrice(data, router)),
  updateProduct: (data) => dispatch(updateProduct(data, router)),
  getProductPrice: (data) => dispatch(getProductPrice(data)),
  updateProductPrice: (data) => dispatch(updateProductPrice(data, router)),
  getAllProductPrice: (data) => dispatch(getAllProductPrice(data)),
  deleteProductPrice: (data) => dispatch(deleteProductPrice(data, router)),
  getAllFeatures: (data) => dispatch(getAllFeatures(data, router)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Prices));
