import React from "react";

export default function DataTable({ children }) {
  return (
    <React.Fragment>
      <div className="nav-pager">
        <ul>
          <li className="nav-first-disabled">First</li>
          <li className="nav-previous-disabled">Previous</li>
          <li className="nav-page-select">
            Page
            <select name="page" size="1">
              <option defaultValue value="0">
                1
              </option>
            </select>
          </li>
          <li className="nav-next-disabled">Next</li>
          <li className="nav-last-disabled">Last</li>
          <li className="nav-pagesize">
            <select name="pageSize" size="1">
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option defaultValue value="200">
                200
              </option>
            </select>
            Items per page
          </li>
          <li className="nav-displaying">Displaying 1 - 8 of 8</li>
        </ul>
      </div>
      {children}
      <div className="nav-pager">
        <ul>
          <li className="nav-first-disabled">First</li>
          <li className="nav-previous-disabled">Previous</li>
          <li className="nav-page-select">
            Page
            <select name="page" size="1">
              <option defaultValue value="0">
                1
              </option>
            </select>
          </li>
          <li className="nav-next-disabled">Next</li>
          <li className="nav-last-disabled">Last</li>
          <li className="nav-pagesize">
            <select name="pageSize" size="1">
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option defaultValue value="200">
                200
              </option>
            </select>
            Items per page
          </li>
          <li className="nav-displaying">Displaying 1 - 8 of 8</li>
        </ul>
      </div>
    </React.Fragment>
  );
}
