import React, { Component } from "react";
import { connect } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { Modal } from "@material-ui/core";
import {
  actionGetCities,
  getParties,
  actionCreateCart,
  updateProduct,
  cancel,
  getOrder,
} from "../../../store/actions/orderActions";

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    getOrder: (orderId) => dispatch(getOrder(orderId)),
    getCities: () => dispatch(actionGetCities()),
    getParties: (city) => dispatch(getParties(city)),
    create: (data) => dispatch(actionCreateCart(data)),
    updateCart: (data) => dispatch(updateProduct(data)),
    cancel: (cartId) => dispatch(cancel(cartId)),
  };
}

class EditItems extends Component {
  state = {
    id: "",
    deliveryDate: "",
    cities: [],
    selectedCity: "",
    selectedSlot: null,
    slots: [],
    address: "",
    productsArray: [],
  };
  componentDidMount = async () => {
    let res = await this.props.getCities();
    this.setState({ cities: res.data });
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.orders !== prevProps.orders) {
      let productsArray = [];
      for (let i = 0; i < this.props.orders.length; i++) {
        let obj = {};
        obj.cartId = this.props.orders[i]._id;
        obj.quantity = this.props.orders[i].quantity;
        obj.price = this.props.orders[i].amount;
        obj.image = this.props.orders[i]?.products[0].images?.[0];
        obj.shippingPrice = this.props.orders[i].shippingPrice;
        obj.priceType = "Product Price";
        productsArray.push(obj);
        if (this.props.orders[i].addons.length > 0) {
          for (let j = 0; j < this.props.orders[i].addons.length; j++) {
            let obj = {};
            obj.cartId = this.props.orders[i].addons[j]._id;
            obj.quantity = this.props.orders[i].addons[j].quantity;
            obj.price = this.props.orders[i].addons[j].amount;
            obj.image = this.props.orders[i]?.addons[j].products[0].images?.[0];
            obj.shippingPrice = this.props.orders[i].addons[j].shippingPrice;
            obj.priceType = "Product Price";
            productsArray.push(obj);
          }
        }
      }
      this.setState({ productsArray });
    }
  }

  submit = async () => {
    let slot = this.state.slots[this.state.selectedSlot];
    let city = this.state.selectedCity;
    let productId = this.state.id;
    let orderId = this.props.orderId;
    let address = this.state.address;
    let deliveryDate = this.state.deliveryDate;
    await this.props.create({
      slot,
      city,
      productId,
      orderId,
      address,
      deliveryDate,
    });
    await this.props.getOrder(orderId);
    this.props.handleClose();
  };
  handlePriceChange = (e, index) => {
    let productsArray = [...this.state.productsArray];
    if (productsArray[index].priceType === "Product Price") {
      productsArray[index].price = e.target.value;
    }
    if (productsArray[index].priceType === "Shipping Price") {
      productsArray[index].shippingPrice = e.target.value;
    }
    this.setState({ productsArray: productsArray });
  };
  handleQuantity = (type, index) => {
    if (type === "decrease") {
      if (this.state.productsArray[index].quantity > 1) {
        let productsArray = [...this.state.productsArray];
        productsArray[index].quantity = productsArray[index].quantity - 1;
        this.setState({ productsArray: productsArray });
      }
    }
    if (type === "increase") {
      let productsArray = [...this.state.productsArray];
      productsArray[index].quantity = productsArray[index].quantity + 1;
      this.setState({ productsArray: productsArray });
    }
  };
  citySelect = async (e) => {
    this.setState({ selectedCity: e.target.value });
    let parties = await this.props.getParties(e.target.value);
    let array = [];
    for (let i = 0; i < parties?.length; i++) {
      for (let j = 0; j < parties[i].carrierShipmentMethods?.length; j++) {
        for (
          let k = 0;
          k < parties[i].carrierShipmentMethods[j]?.timeSlots?.length;
          k++
        ) {
          let shippingType = parties[i].carrierShipmentMethods[j].methodType;
          let timeSlot =
            parties[i].carrierShipmentMethods[j].timeSlots[k].startTime +
            " - " +
            parties[i].carrierShipmentMethods[j].timeSlots[k].endTime +
            " hrs";
          let price =
            parties[i].carrierShipmentMethods[j].shipmentMethodsType.price;
          let dString = shippingType + " " + timeSlot + " " + "AED" + price;
          let partyId = parties[i]._id;
          let found = array.findIndex((arr) => arr.dString === dString);
          if (found !== -1) {
            let arrayObj = array[found];
            arrayObj.parties.push(partyId);
            array[found] = arrayObj;
          } else {
            array.push({
              shippingType,
              timeSlot,
              price,
              dString,
              parties: [partyId],
            });
          }
        }
      }
    }
    this.setState({ slots: array });
  };
  setPriceType = (e, ind) => {
    let productsArray = [...this.state.productsArray];
    productsArray[ind].priceType = e.target.value;
    this.setState({ productsArray });
  };

  handleSubmit = async (ind) => {
    let productArray = [...this.state.productsArray];
    let productObj = productArray[ind];
    let subOrderId = this.props.orders[0].subOrderId;
    let obj = {};
    obj.subOrderId = subOrderId;
    obj.productObj = productObj;
    await this.props.updateCart(obj);
    await this.props.getOrder(this.props.orderId);
    this.props.handleClose();
  };
  handleCancel = async (ind) => {
    let productArray = [...this.state.productsArray];
    let cartId = productArray[ind].cartId;
    await this.props.cancel(cartId);
    await this.props.getOrder(this.props.orderId);
    this.props.handleClose(true);
  };
  ProductRow = (product, ind) => {
    return (
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img
            height={"50px"}
            width={"50px"}
            src={product?.image}
            alt={"product image"}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <button
              type={"button"}
              onClick={() => this.handleQuantity("decrease", ind)}
            >
              -
            </button>
            {product?.quantity}
            <button
              type={"button"}
              onClick={() => this.handleQuantity("increase", ind)}
            >
              +
            </button>
          </div>
        </div>
        <div>
          <select
            value={this.state.productsArray[ind].priceType}
            onChange={(e) => this.setPriceType(e, ind)}
          >
            <option value={"Product Price"}>Product Price</option>
            <option value={"Shipping Price"}>Shipping Price</option>
          </select>
        </div>
        <div>
          <input
            type={"number"}
            value={
              this.state.productsArray[ind].priceType === "Product Price"
                ? product?.price
                : product?.shippingPrice
            }
            onChange={(e) => this.handlePriceChange(e, ind)}
            style={{ width: "100px" }}
          />
        </div>
        <div>
          <button type={"button"} onClick={() => this.handleSubmit(ind)}>
            Submit
          </button>
        </div>
        <div>
          <button type={"button"} onClick={() => this.handleCancel(ind)}>
            Cancel
          </button>
        </div>
      </div>
    );
  };
  render() {
    return (
      <Modal
        open={this.props.modalOpen}
        onClose={this.props.handleClose}
        //   style={{ overflow: "scroll" }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            height: "650px",
            width: "500px",
            backgroundColor: "white",
            position: "absolute",
            top: "50px",
            border: "2px solid white",
            left: "600px",
            //alignItems: "center",
            display: "flex",
            overflow: "scroll",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              backgroundColor: "#527894",
              borderRadius: "5px",
              width: "100%",
              height: "30px",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <CloseIcon
              onClick={(e) => this.props.handleClose(e)}
              style={{
                color: "#6caad4",
                marginRight: "5px",
                backgroundColor: "#e3f1fc",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            />
          </div>
          <h4>Add new product</h4>
          <div
            style={{ width: "96%", display: "flex", flexDirection: "column" }}
          >
            <label htmlFor={"message"}>Product Id:</label>
            <input
              id={"message"}
              type={"text"}
              onChange={(e) => this.setState({ id: e.target.value })}
              value={this.state.id}
            />
            <label htmlFor={"date"}>Delivery Date:</label>
            <input
              id={"date"}
              type={"date"}
              onChange={(e) => this.setState({ deliveryDate: e.target.value })}
              value={this.state.deliveryDate}
            />
            <label htmlFor={"address"}>Delivery Address:</label>
            <select
              id={"address"}
              value={this.state.address}
              onChange={(e) => this.setState({ address: e.target.value })}
            >
              <option value={""}></option>
              {this.props?.addresses?.map((address) => (
                <option value={address._id}>
                  {address?.recipientAddress},{address?.landmark},
                  {address?.recipientCity}
                </option>
              ))}
            </select>

            <label htmlFor={"address"}>City:</label>
            <select
              id={"address"}
              value={this.state.selectedCity}
              onChange={(e) => this.citySelect(e)}
            >
              <option value={""}></option>
              {this.state?.cities?.map((city) => (
                <option value={city.name}>{city?.name}</option>
              ))}
            </select>
            <p>ShippingDetails*</p>
            <select
              value={this.state.selectedSlot}
              onChange={(e) => {
                this.setState({ selectedSlot: e.target.value });
              }}
            >
              <option value={null}></option>
              {this.state?.slots?.map((slot, index) => (
                <option value={index}>{slot.dString}</option>
              ))}
            </select>
          </div>
          <div
            style={{
              width: "96%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginTop: "10px",
            }}
          >
            <button type={"button"} onClick={() => this.submit()}>
              Update
            </button>
          </div>
          <h4>Update Product</h4>
          <div
            style={{ width: "96%", display: "flex", flexDirection: "column" }}
          >
            {this.state.productsArray.map((order, ind) => {
              return this.ProductRow(order, ind);
            })}
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditItems);
