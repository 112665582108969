import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Collapse } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { withRouter, Link } from "react-router-dom";
import RemoveIcon from "@material-ui/icons/Remove";
import { searchCategories } from "../../../../store/actions/catalogActions";

export class categoryPopUp extends Component {
  state = {
    collapse: false,
    searchCategoryId: "",
    productCategoryFilterType: "contains",
    ignoreCategoryId: true,
    partyTypeId: "",
    categoryNameFilterType: "contains",
    categoryName: "",

    categoryType: "",
    primaryParentCategoryIdFilterType: "contains",
    searchPrimaryParentCategoryId: "",
    ignorePrimaryParentCategoryId: true,
    descriptionFilterType: "contains",
    searchDescription: "",
    ignoreDescription: true,
    longDescriptionFilterType: "contains",
    searchLongDescription: "",
    ignoreLongDescription: true,
    showInSelect: "NO",
    categoryImageUrlFilterType: "contains",
    searchCategoryImageUrl: "",
    ignoreCategoryImageUrl: true,
    linkOneImageUrlFilterType: "contains",
    searchLinkOneImageUrl: "",
    ignoreLinkOneImageUrl: true,
    linkTwoImageUrlFilterType: "contains",
    searchLinkTwoImageUrl: "",
    ignoreLinkTwoImageUrl: true,
    detailScreenFilterType: "contains",
    searchDetailScreen: "",
    ignoreDetailScreen: true,

    collapseInner: true,
    categories: [],
    pageNo: 1,
    limit: 10,
  };
  async componentDidMount() {
    await this.props.search({});
  }
  render() {
    return (
      <div>
        <Modal
          open={this.props.modalOpen}
          onClose={this.props.handleClose}
          //   style={{ overflow: "scroll" }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            style={{
              height: "600px",
              width: "800px",
              backgroundColor: "white",
              position: "absolute",
              top: "50px",
              border: "2px solid white",
              left: "600px",
              alignItems: "center",
              display: "flex",
              overflow: "scroll",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                backgroundColor: "#527894",
                borderRadius: "5px",
                width: "100%",
                height: "30px",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <CloseIcon
                onClick={(e) => this.props.handleClose(e)}
                style={{
                  color: "#6caad4",
                  marginRight: "5px",
                  backgroundColor: "#e3f1fc",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              />
            </div>
            {/* <div
              style={{
                display: "flex",
                width: "98%",
                height: "40px",
                backgroundColor: "#f5f5f5",
                marginTop: "10px",
                border: "1px solid #e0e0e0",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p style={{ fontSize: "15px", marginLeft: "10px" }}>
                Lookup Product Category
              </p>
              {this.state.collapse && (
                <AddIcon
                  onClick={() =>
                    this.setState({ collapse: !this.state.collapse })
                  }
                  style={{
                    fontSize: "small",
                    marginRight: "10px",
                    border: "1px solid #e0e0e0",
                  }}
                />
              )}
              {!this.state.collapse && (
                <RemoveIcon
                  onClick={() =>
                    this.setState({ collapse: !this.state.collapse })
                  }
                  style={{
                    fontSize: "small",
                    marginRight: "10px",
                    border: "1px solid #e0e0e0",
                  }}
                />
              )}
            </div>
            <Collapse
              in={!this.state.collapse}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "98%",
                border: "1px solid #e0e0e0",
                minHeight: "1vh !important",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: "10px",
                  marginTop: "20px",
                }}
              >
                <p>Product Category Id</p>
                <select
                  style={{ display: "flex", marginLeft: "10px" }}
                  id="EditProduct_productTypeId"
                  name="productTypeId"
                  value={this.state.productCategoryFilterType}
                  onChange={(e) =>
                    this.setState({
                      productCategoryFilterType: e.target.value,
                    })
                  }
                  size="1"
                >
                  <option value="equals">Equals</option>
                  <option value="beginWith">Begins With</option>
                  <option value="contains">Contains</option>
                  <option value="isEmpty">Is Empty</option>
                  <option value="notEqual">Not Equal</option>
                </select>
                <input
                  style={{ display: "flex", marginLeft: "5px" }}
                  onChange={(e) =>
                    this.setState({ searchCategoryId: e.target.value })
                  }
                  value={this.state.searchCategoryId}
                  type="text"
                />
                <input
                  checked="checked"
                  type="checkbox"
                  style={{ display: "flex", marginLeft: "5px" }}
                  checked={this.state.ignoreCategoryId}
                  onChange={(e) =>
                    this.setState((prevState) => ({
                      ignoreCategoryId: !prevState.ignoreCategoryId,
                    }))
                  }
                  value="Y"
                />
                Ignore Case
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: "32px",
                  marginTop: "10px",
                }}
              >
                <p>Category Name</p>
                <select
                  style={{ display: "flex", marginLeft: "10px" }}
                  id="EditProduct_productTypeId"
                  name="productTypeId"
                  value={this.state.categoryNameFilterType}
                  onChange={(e) =>
                    this.setState({
                      categoryNameFilterType: e.target.value,
                    })
                  }
                  size="1"
                >
                  <option value="equals">Equals</option>
                  <option value="beginsWith">Begins With</option>
                  <option value="contains">Contains</option>
                  <option value="isEmpty">Is Empty</option>
                  <option value="notEqual">Not Equal</option>
                </select>
                <input
                  style={{ display: "flex", marginLeft: "5px" }}
                  onChange={(e) =>
                    this.setState({ categoryName: e.target.value })
                  }
                  value={this.state.categoryName}
                  type="text"
                />
                <input
                  checked="checked"
                  type="checkbox"
                  style={{ display: "flex", marginLeft: "5px" }}
                  checked={this.state.ignoreCategoryName}
                  onChange={(e) =>
                    this.setState((prevState) => ({
                      ignoreCategoryName: !prevState.ignoreCategoryName,
                    }))
                  }
                  value="Y"
                />
                Ignore Case
              </div>
              <div
                style={{
                  display: "flex",
                  borderTop: "1px solid black",
                  allignItems: "center",
                  paddingTop: "5px",
                  marginTop: "5px",
                }}
              >
                {this.state.collapseInner && (
                  <AddIcon
                    onClick={() =>
                      this.setState({
                        collapseInner: !this.state.collapseInner,
                      })
                    }
                    style={{
                      fontSize: "small",
                      marginLeft: "10px",
                      border: "1px solid #e0e0e0",
                      cursor: "pointer",
                    }}
                  />
                )}
                {!this.state.collapseInner && (
                  <RemoveIcon
                    onClick={() =>
                      this.setState({
                        collapseInner: !this.state.collapseInner,
                      })
                    }
                    style={{
                      fontSize: "small",
                      marginLeft: "10px",
                      border: "1px solid #e0e0e0",
                      cursor: "pointer",
                    }}
                  />
                )}
                <p style={{ fontSize: "15px", marginLeft: "20px" }}>
                  Addvanced Search
                </p>
              </div>
              <Collapse in={!this.state.collapseInner}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: "40px",
                    marginTop: "10px",
                  }}
                >
                  <p>Product Category Type Id</p>
                  <select
                    style={{ display: "flex", marginLeft: "10px" }}
                    id="EditProduct_productTypeId"
                    name="productTypeId"
                    value={this.state.categoryType}
                    onChange={(e) =>
                      this.setState({
                        categoryType: e.target.value,
                      })
                    }
                    size="1"
                  >
                    <option>---Select one---</option>
                    <option value="ADDON_CATEGORY">Addon Category</option>
                    <option value="BEST_SELL_CATEGORY">Best Selling</option>
                    <option value="CATALOG_CATEGORY">Catalog</option>
                    <option value="CROSS_SELL_CATEGORY">Cross Sell</option>
                    <option value="EBAY_CATEGORY">eBay</option>
                    <option value="GIFT_CARD_CATEGORY">Gift Cards</option>
                    <option value="GOOGLE_BASE_CATEGORY">Google Base</option>
                    <option value="INDUSTRY_CATEGORY">Industry</option>
                    <option value="INTERNAL_CATEGORY">Internal</option>
                    <option value="MATERIALS_CATEGORY">Materials</option>
                    <option value="MIXMATCH_CATEGORY">Mix and Match</option>
                    <option value="PRODUCT_TYPE">Product Type</option>
                    <option value="QUICKADD_CATEGORY">Quick Add</option>
                    <option value="SEARCH_CATEGORY">Search</option>
                    <option value="TAX_CATEGORY">Tax</option>
                    <option value="USAGE_CATEGORY">Usage</option>
                  </select>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: "5px",
                    marginTop: "10px",
                  }}
                >
                  <p>Primary Parent Category Type Id</p>
                  <select
                    style={{ display: "flex", marginLeft: "10px" }}
                    id="EditProduct_productTypeId"
                    name="productTypeId"
                    value={this.state.primaryParentCategoryIdFilterType}
                    onChange={(e) =>
                      this.setState({
                        primaryParentCategoryIdFilterType: e.target.value,
                      })
                    }
                    size="1"
                  >
                    <option value="equals">Equals</option>
                    <option value="beginWith">Begins With</option>
                    <option value="contains">Contains</option>
                    <option value="isEmpty">Is Empty</option>
                    <option value="notEqual">Not Equal</option>
                  </select>
                  <input
                    style={{ display: "flex", marginLeft: "5px" }}
                    onChange={(e) =>
                      this.setState({
                        searchPrimaryParentCategoryId: e.target.value,
                      })
                    }
                    value={this.state.searchPrimaryParentCategoryId}
                    type="text"
                  />
                  <input
                    checked="checked"
                    type="checkbox"
                    style={{ display: "flex", marginLeft: "5px" }}
                    checked={this.state.ignorePrimaryParentCategoryId}
                    onChange={(e) =>
                      this.setState((prevState) => ({
                        ignorePrimaryParentCategoryId: !prevState.ignorePrimaryParentCategoryId,
                      }))
                    }
                    value="Y"
                  />
                  Ignore Case
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: "113px",
                    marginTop: "10px",
                  }}
                >
                  <p>Description</p>
                  <select
                    style={{ display: "flex", marginLeft: "10px" }}
                    id="EditProduct_productTypeId"
                    name="productTypeId"
                    value={this.state.descriptionFilterType}
                    onChange={(e) =>
                      this.setState({
                        descriptionFilterType: e.target.value,
                      })
                    }
                    size="1"
                  >
                    <option value="equals">Equals</option>
                    <option value="beginWith">Begins With</option>
                    <option value="contains">Contains</option>
                    <option value="isEmpty">Is Empty</option>
                    <option value="notEqual">Not Equal</option>
                  </select>
                  <input
                    style={{ display: "flex", marginLeft: "5px" }}
                    onChange={(e) =>
                      this.setState({
                        searchDescription: e.target.value,
                      })
                    }
                    value={this.state.searchDescription}
                    type="text"
                  />
                  <input
                    checked="checked"
                    type="checkbox"
                    style={{ display: "flex", marginLeft: "5px" }}
                    checked={this.state.ignoreDescription}
                    onChange={(e) =>
                      this.setState((prevState) => ({
                        ignoreDescription: !prevState.ignoreDescription,
                      }))
                    }
                    value="Y"
                  />
                  Ignore Case
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: "85px",
                    marginTop: "10px",
                  }}
                >
                  <p>Long Description</p>
                  <select
                    style={{ display: "flex", marginLeft: "10px" }}
                    id="EditProduct_productTypeId"
                    name="productTypeId"
                    value={this.state.longDescriptionFilterType}
                    onChange={(e) =>
                      this.setState({
                        longDescriptionFilterType: e.target.value,
                      })
                    }
                    size="1"
                  >
                    <option value="equals">Equals</option>
                    <option value="beginWith">Begins With</option>
                    <option value="contains">Contains</option>
                    <option value="isEmpty">Is Empty</option>
                    <option value="notEqual">Not Equal</option>
                  </select>
                  <input
                    style={{ display: "flex", marginLeft: "5px" }}
                    onChange={(e) =>
                      this.setState({
                        searchLongDescription: e.target.value,
                      })
                    }
                    value={this.state.searchLongDescription}
                    type="text"
                  />
                  <input
                    checked="checked"
                    type="checkbox"
                    style={{ display: "flex", marginLeft: "5px" }}
                    checked={this.state.ignoreLongDescription}
                    onChange={(e) =>
                      this.setState((prevState) => ({
                        ignoreLongDescription: !prevState.ignoreLongDescription,
                      }))
                    }
                    value="Y"
                  />
                  Ignore Case
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: "95px",
                    marginTop: "10px",
                  }}
                >
                  <p>Show in Select</p>
                  <select
                    style={{ display: "flex", marginLeft: "10px" }}
                    id="EditProduct_productTypeId"
                    name="productTypeId"
                    value={this.state.showInSelect}
                    onChange={(e) =>
                      this.setState({
                        showInSelect: e.target.value,
                      })
                    }
                    size="1"
                  >
                    <option value="NO">No</option>
                    <option value="YES">Yes</option>
                  </select>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: "70px",
                    marginTop: "10px",
                  }}
                >
                  <p>Category Image Url</p>
                  <select
                    style={{ display: "flex", marginLeft: "10px" }}
                    id="EditProduct_productTypeId"
                    name="productTypeId"
                    value={this.state.categoryImageUrlFilterType}
                    onChange={(e) =>
                      this.setState({
                        categoryImageUrlFilterType: e.target.value,
                      })
                    }
                    size="1"
                  >
                    <option value="equals">Equals</option>
                    <option value="beginWith">Begins With</option>
                    <option value="contains">Contains</option>
                    <option value="isEmpty">Is Empty</option>
                    <option value="notEqual">Not Equal</option>
                  </select>
                  <input
                    style={{ display: "flex", marginLeft: "5px" }}
                    onChange={(e) =>
                      this.setState({
                        searchCategoryImageUrl: e.target.value,
                      })
                    }
                    value={this.state.searchCategoryImageUrl}
                    type="text"
                  />
                  <input
                    checked="checked"
                    type="checkbox"
                    style={{ display: "flex", marginLeft: "5px" }}
                    checked={this.state.ignoreCategoryImageUrl}
                    onChange={(e) =>
                      this.setState((prevState) => ({
                        ignoreCategoryImageUrl: !prevState.ignoreCategoryImageUrl,
                      }))
                    }
                    value="Y"
                  />
                  Ignore Case
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: "70px",
                    marginTop: "10px",
                  }}
                >
                  <p>Link One Image Url</p>
                  <select
                    style={{ display: "flex", marginLeft: "10px" }}
                    id="EditProduct_productTypeId"
                    name="productTypeId"
                    value={this.state.LinkOneImageUrlFilterType}
                    onChange={(e) =>
                      this.setState({
                        LinkOneImageUrlFilterType: e.target.value,
                      })
                    }
                    size="1"
                  >
                    <option value="equals">Equals</option>
                    <option value="beginWith">Begins With</option>
                    <option value="contains">Contains</option>
                    <option value="isEmpty">Is Empty</option>
                    <option value="notEqual">Not Equal</option>
                  </select>
                  <input
                    style={{ display: "flex", marginLeft: "5px" }}
                    onChange={(e) =>
                      this.setState({
                        searchLinkOneImageUrl: e.target.value,
                      })
                    }
                    value={this.state.searchLinkOneImageUrl}
                    type="text"
                  />
                  <input
                    checked="checked"
                    type="checkbox"
                    style={{ display: "flex", marginLeft: "5px" }}
                    checked={this.state.ignoreLinkOneImageUrl}
                    onChange={(e) =>
                      this.setState((prevState) => ({
                        ignoreLinkOneImageUrl: !prevState.ignoreLinkOneImageUrl,
                      }))
                    }
                    value="Y"
                  />
                  Ignore Case
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: "70px",
                    marginTop: "10px",
                  }}
                >
                  <p>Link Two Image Url</p>
                  <select
                    style={{ display: "flex", marginLeft: "10px" }}
                    id="EditProduct_productTypeId"
                    name="productTypeId"
                    value={this.state.linkTwoImageUrlFilterType}
                    onChange={(e) =>
                      this.setState({
                        linkTwoImageUrlFilterType: e.target.value,
                      })
                    }
                    size="1"
                  >
                    <option value="equals">Equals</option>
                    <option value="beginWith">Begins With</option>
                    <option value="contains">Contains</option>
                    <option value="isEmpty">Is Empty</option>
                    <option value="notEqual">Not Equal</option>
                  </select>
                  <input
                    style={{ display: "flex", marginLeft: "5px" }}
                    onChange={(e) =>
                      this.setState({
                        searchLinkTwoImageUrl: e.target.value,
                      })
                    }
                    value={this.state.searchLinkTwoImageUrl}
                    type="text"
                  />
                  <input
                    checked="checked"
                    type="checkbox"
                    style={{ display: "flex", marginLeft: "5px" }}
                    checked={this.state.ignoreLinkTwoImageUrl}
                    onChange={(e) =>
                      this.setState((prevState) => ({
                        ignoreLinkTwoImageUrl: !prevState.ignoreLinkTwoImageUrl,
                      }))
                    }
                    value="Y"
                  />
                  Ignore Case
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: "100px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <p>Detail Screen</p>
                  <select
                    style={{ display: "flex", marginLeft: "10px" }}
                    id="EditProduct_productTypeId"
                    name="productTypeId"
                    value={this.state.detailScreenFilterType}
                    onChange={(e) =>
                      this.setState({
                        detailScreenFilterType: e.target.value,
                      })
                    }
                    size="1"
                  >
                    <option value="equals">Equals</option>
                    <option value="beginWith">Begins With</option>
                    <option value="contains">Contains</option>
                    <option value="isEmpty">Is Empty</option>
                    <option value="notEqual">Not Equal</option>
                  </select>
                  <input
                    style={{ display: "flex", marginLeft: "5px" }}
                    onChange={(e) =>
                      this.setState({
                        searchDetailScreen: e.target.value,
                      })
                    }
                    value={this.state.searchDetailScreen}
                    type="text"
                  />
                  <input
                    checked="checked"
                    type="checkbox"
                    style={{ display: "flex", marginLeft: "5px" }}
                    checked={this.state.ignoreDetailScreen}
                    onChange={(e) =>
                      this.setState((prevState) => ({
                        ignoreDetailScreen: !prevState.ignoreDetailScreen,
                      }))
                    }
                    value="Y"
                  />
                  Ignore Case
                </div>
              </Collapse>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: "5px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <p>Alternative Canonical</p>
                <select
                  style={{ display: "flex", marginLeft: "10px" }}
                  id="EditProduct_productTypeId"
                  name="productTypeId"
                  value={this.state.detailScreenFilterType}
                  onChange={(e) =>
                    this.setState({
                      detailScreenFilterType: e.target.value,
                    })
                  }
                  size="1"
                >
                  <option value="equals">Equals</option>
                  <option value="beginWith">Begins With</option>
                  <option value="contains">Contains</option>
                  <option value="isEmpty">Is Empty</option>
                  <option value="notEqual">Not Equal</option>
                </select>
                <input
                  style={{ display: "flex", marginLeft: "5px" }}
                  onChange={(e) =>
                    this.setState({
                      searchDetailScreen: e.target.value,
                    })
                  }
                  value={this.state.searchDetailScreen}
                  type="text"
                />
                <input
                  checked="checked"
                  type="checkbox"
                  style={{ display: "flex", marginLeft: "5px" }}
                  checked={this.state.ignoreDetailScreen}
                  onChange={(e) =>
                    this.setState((prevState) => ({
                      ignoreDetailScreen: !prevState.ignoreDetailScreen,
                    }))
                  }
                  value="Y"
                />
                Ignore Case
              </div>

              <div style={{ marginLeft: "120px", marginBottom: "10px" }}>
                <button>Find</button>
              </div>
            </Collapse> */}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "10px",
                width: "100%",
              }}
            >
              <button
                onClick={() => {
                  if (this.state.pageNo > 1) {
                    this.setState({ pageNo: this.state.pageNo - 1 });
                  }
                }}
                style={{
                  display: "flex",
                  marginLeft: "10px",
                }}
              >
                Previous
              </button>

              {/* <select
                style={{ display: "flex", marginLeft: "10px" }}
                id="EditProduct_productTypeId"
                name="page"
                value={this.state.pageNo}
                onChange={(e) => {
                  this.setState({
                    pageNo: e.target.value,
                  });
                }}
                size="1"
              >
                {this.state.pagesIndex.map((page, index) => (
                  <option value={index + 1}>{index + 1}</option>
                ))}
              </select> */}
              <button
                onClick={() => {
                  let pages = Math.ceil(
                    this.state.categories.length / this.state.limit
                  );
                  if (this.state.pageNo < pages) {
                    this.setState({ pageNo: this.state.pageNo + 1 });
                  }
                }}
                style={{
                  display: "flex",
                  marginRight: "10px",
                }}
              >
                next
              </button>
            </div>
            <div style={{ width: "100%" }}>
              <table class="basic-table" cellspacing="15">
                <tr>
                  <th width="10%"> Category Id</th>
                  <th width="10%">Product category Type Id</th>
                  <th width="10%">Primary Parent Category Id</th>
                  <th width="10%">Category Name</th>
                  <th width="40%">Description</th>
                  <th width="5%">Show In Select</th>
                  <th width="5%">Alternative Canonical</th>
                </tr>
                {this.state.categories.map(
                  (category, index) =>
                    (this.state.pageNo - 1) * this.state.limit <= index &&
                    this.state.pageNo * this.state.limit > index && (
                      <tr>
                        <td width="10%">
                          <button
                            onClick={() =>
                              this.props.handleCategorySelect(category._id)
                            }
                          >
                            {category._id}
                          </button>
                        </td>
                        <td width="10%">{category.type}</td>
                        <td width="10%">{category?.parentCategory?.name}</td>
                        <td width="10%">{category.name}</td>
                        <td width="40%">{category.description}</td>
                        <td width="5%"></td>
                        <td width="5%"></td>
                      </tr>
                    )
                )}
              </table>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
  componentDidUpdate(prevProps) {
    if (this.props.categories !== prevProps.categories) {
      this.setState({ categories: this.props.categories });
    }
  }
}
const mapStateToProps = (state) => ({
  categories: state.catalog?.categories?.categories_searched,
});

const mapDispatchToProps = (dispatch, router) => ({
  search: (data) => dispatch(searchCategories(data, router)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(categoryPopUp)
);
