import axios from "axios";
import { Notify } from "../../constants/utils";
import {
  postRequest,
  putRequest,
  getRequest,
  deleteRequest,
} from "../services";

// view profile
export const getUserById = (_id) => async (dispatch, getState) => {
  const token = getState().auth?.token;

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}users/get/${_id}`,
      {
        headers: { "access-token": token },
      }
    );

    if (res?.data?.data) {
      dispatch({ type: "USER_FETCHED_BY_ID", payload: res?.data?.data });
      Notify("success", "User is fetched successfully.", "Success");
    } else {
      Notify("danger", "Error while fetching user.", "Error");
    }
  } catch (err) {
    console.log(err);
    Notify("danger", err.message, "Error");
  }
};

// view profile - addresses
export const getAllActiveAddresses = () => async (dispatch, getState) => {
  const token = getState().auth?.token;

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}address/allActive`,
      {
        headers: { "access-token": token },
      }
    );

    if (res) {
      dispatch({ type: "FETCHED_ALL_ADDRESSES", payload: res?.data?.data });
      Notify("success", "Addresses are fetched successfully.", "Success");
    } else {
      Notify("danger", "Error while fetching addresses.", "Error");
    }
  } catch (err) {
    console.log(err);
    Notify("danger", err.message, "Error");
  }
};

export const createParty = (data, router) => async (dispatch, getState) => {
  const token = getState().auth?.token;

  try {
    console.log("this is data before create", data);
    const res = await postRequest("parties/create", data, true, {
      "access-token": token,
    });

    if (res) {
      dispatch({ type: "PARTIES_ADD", payload: res?.data });
      Notify("success", "Addresses are fetched successfully.", "Success");
    } else {
      Notify("danger", "Error while fetching addresses.", "Error");
    }
  } catch (err) {
    Notify(
      "danger",
      "User Already Exists, Please use another email Address to register."
    );
  }
};
export const allActiveParties = () => async (dispatch, getState) => {
  const token = getState().auth?.token;

  try {
    const res = await postRequest("parties/allActive", true, {
      "access-token": token,
    });

    if (res) {
      dispatch({ type: "PARTIES", payload: res?.data });
    } else {
      Notify("danger", "Error while fetching addresses.", "Error");
    }
  } catch (err) {
    console.log(err);
    Notify("danger", err.message, "Error");
  }
};
export const deleteParty = (_id) => async (dispatch, getState) => {
  const token = getState().auth?.token;

  try {
    const res = await putRequest("parties/delete/" + _id, true, {
      "access-token": token,
    });

    if (res) {
      dispatch({ type: "PARTIES", payload: res?.data });
    } else {
      Notify("danger", "Error while fetching addresses.", "Error");
    }
  } catch (err) {
    console.log(err);
    Notify("danger", err.message, "Error");
  }
};

export const searchParty = (data, router) => async (dispatch, getState) => {
  const token = getState().auth?.token;

  try {
    const res = await putRequest("parties/search", data, true, {
      "access-token": token,
    });

    if (res) {
      dispatch({ type: "PARTIES", payload: res?.data });
    } else {
      Notify("danger", "Error while fetching addresses.", "Error");
    }
  } catch (err) {
    console.log(err);
    Notify("danger", err.message, "Error");
  }
};

export const updateParty = (data, _id) => async (dispatch, getState) => {
  const token = getState().auth?.token;

  try {
    const res = await putRequest("parties/update/" + _id, data, true, {
      "access-token": token,
    });

    if (res) {
      console.log("this is response");
      dispatch({ type: "PARTIES_UPDATE", payload: { ...data, _id } });
    } else {
      Notify("danger", "Error while fetching addresses.", "Error");
    }
  } catch (err) {
    console.log(err);
    Notify("danger", err.message, "Error");
  }
};
export const createCountries = (data, router) => async (dispatch, getState) => {
  const token = getState().auth?.token;

  try {
    const res = await postRequest("countries/create", data, true, {
      "access-token": token,
    });

    if (res) {
      dispatch({ type: "COUNTRIES", payload: res?.data });
    } else {
      Notify("danger", "Error while fetching addresses.", "Error");
    }
  } catch (err) {
    console.log(err);
    Notify("danger", err.message, "Error");
  }
};

export const allActiveCountries = () => async (dispatch, getState) => {
  const token = getState().auth?.token;

  try {
    const res = await getRequest("countries/allActive", true, {
      "access-token": token,
    });

    if (res) {
      console.log("this is response of countries", res.data);
      dispatch({ type: "COUNTRIES", payload: res?.data });
      Notify("success", "Addresses are fetched successfully.", "Success");
    } else {
      Notify("danger", "Error while fetching addresses.", "Error");
    }
  } catch (err) {
    console.log(err);
    Notify("danger", err.message, "Error");
  }
};

export const createCities = (data, router) => async (dispatch, getState) => {
  const token = getState().auth?.token;

  try {
    const res = await postRequest("cities/create", data, true, {
      "access-token": token,
    });

    if (res) {
      dispatch({ type: "CITIES", payload: res?.data });
    } else {
      Notify("danger", "Error while fetching addresses.", "Error");
    }
  } catch (err) {
    console.log(err);
    Notify("danger", err.message, "Error");
  }
};

export const allActiveCities = () => async (dispatch, getState) => {
  const token = getState().auth?.token;

  try {
    const res = await postRequest("cities/allActive", true, {
      "access-token": token,
    });

    if (res) {
      dispatch({ type: "CITIES", payload: res?.data });
      Notify("success", "Addresses are fetched successfully.", "Success");
    } else {
      Notify("danger", "Error while fetching addresses.", "Error");
    }
  } catch (err) {
    console.log(err);
    Notify("danger", err.message, "Error");
  }
};
export const getCities = (_id) => async (dispatch, getState) => {
  const token = getState().auth?.token;

  try {
    const res = await getRequest("cities/get/" + _id, true, {
      "access-token": token,
    });

    if (res) {
      dispatch({ type: "CITIES", payload: res?.data });
      Notify("success", "Addresses are fetched successfully.", "Success");
    } else {
      Notify("danger", "Error while fetching addresses.", "Error");
    }
  } catch (err) {
    console.log(err);
    Notify("danger", err.message, "Error");
  }
};
