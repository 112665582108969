import React from "react";
import { Link } from "react-router-dom";
import { editCatalogNav } from "../../../../constants/strings";
export default function EditCatalogNav({ catalog }) {
  return (
    <div>
      <div className="button-bar tab-bar">
        <ul>
          <li>
            <ul>
              {editCatalogNav.map(({ text, route }, key) => {
                return (
                  // className="selected"
                  <li key={key}>
                    <Link to={route + catalog}>{text}</Link>
                  </li>
                );
              })}
            </ul>
          </li>
        </ul>
        <br className="clear" />
      </div>
      <div className="button-bar button-style-2">
        <ul>
          <li>
            <ul>
              <li className="buttontext create">
                <Link
                  className="buttontext create"
                  to="/catalog/control/EditProdCatalog"
                >
                  New Prod Catalog
                </Link>
              </li>
              <li>
                <a href>Create SEO category/products</a>
              </li>
            </ul>
          </li>
        </ul>
        <br className="clear" />
      </div>
      <h1>
        Catalog For "{catalog}" [{catalog}]
      </h1>
    </div>
  );
}
