const userState = {
  userById: {},
  apiAddresses: [],
};

export const users = (state = userState, action) => {
  switch (action.type) {
    case "USER_FETCHED_BY_ID":
      return { ...state, userById: action.payload };

    case "FETCHED_ALL_ADDRESSES":
      return { ...state, apiAddresses: action.payload };

    default:
      return state;
  }
};

const partiesState = {
  parties: [],
};
export const parties = (state = partiesState, action) => {
  switch (action.type) {
    case "PARTIES":
      return { ...state, parties: action.payload };
    case "PARTIES_ADD":
      return { ...state, parties: [...state.parties, action.payload] };
    case "PARTIES_UPDATE":
      console.log(state);
      console.log("payload", action.payload);
      const id = action.payload._id;

      const parties = state.parties.map((party) => {
        if (party._id == id) {
          return Object.assign({}, party, {
            userLoginId: action.payload.userLoginId,
            name: action.payload.name,
            userId: action.payload.userId,
            type: action.payload.type,
            mainRole: action.payload.mainRole,
            // lastModifiedDate: action.payload.lastModifiedDate,
            shippingAddress1: action.payload.shippingAddress1,
            shippingAddress2: action.payload.shippingAddress2,
            groupName: action.payload.groupName,
            shippingCity: action.payload.shippingCity,
            shippingStateProvinceGeoId:
              action.payload.shippingStateProvinceGeoId,
            shippingPostalCode: action.payload.shippingPostalCode,
            country: action.payload.country,
            isActive: action.payload.isActive,
            isDeleted: action.payload.isDeleted,
            // createdDate: action.payload.createdDate,
          });
        } else {
          return party;
        }
      });
      console.log("parties after: ", parties);
      return { ...state, parties: parties };

    default:
      return state;
  }
};

const countryState = {
  countries: [],
};
export const countries = (state = countryState, action) => {
  switch (action.type) {
    case "COUNTRIES":
      return { ...state, countries: action.payload };

    default:
      return state;
  }
};
const cityState = {
  cities: [],
};
export const cities = (state = cityState, action) => {
  switch (action.type) {
    case "CITIES":
      return { ...state, cities: action.payload };

    default:
      return state;
  }
};
