import React, { Component, useState } from "react";
import { pathParts } from "../../constants/utils";
import { withRouter, Link, useHistory } from "react-router-dom";

import { getRequest } from "../../store/services";
import { connect } from "react-redux";
import {
  advancesearchProduct,
  searchProduct,
} from "../../store/actions/catalogActions";

class Catalog extends Component {
  constructor() {
    super();
    this.state = {
      id: "",
      idFilterType: "",
      idOperator: "or",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    if (event.target.name === "idFilterType") {
      const filterType = event.target.checked ? "notEqual" : "";
      if (filterType) {
        this.setState({ idFilterType: filterType });
      } else {
        this.setState({ idFilterType: "" });
      }
    } else if (event.target.value === "or" || event.target.value === "and") {
      this.setState({ idOperator: event.target.value });
    } else {
      const fieldName = event.target.name;
      this.setState({ [fieldName]: event.target.value });
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.history.push(
      `/catalog/control/SearchView?categoryId=${this.state.id}&idFilterType=${this.state.idFilterType}&idOperator=${this.state.idOperator}`
    );
  }
  render() {
    const { id, idOperator } = this.state;
    return (
      <div className="left">
        <div className="screenlet" id="ProductKeywordsPanel">
          <div className="screenlet-title-bar">
            <ul>
              <li className="h3">Search Products</li>
              <li className="expanded">
                <a href="/" title="Collapse">
                  &nbsp;
                </a>
              </li>
            </ul>
            <br className="clear" />
          </div>
          <div id="ProductKeywordsPanel_col" className="screenlet-body">
            <form
              name="keywordsearchform"
              id="keywordSearchForm"
              onSubmit={this.handleSubmit}
            >
              <fieldset>
                <div>
                  <label htmlFor="keywordSearchString">Keywords:</label>
                  <input
                    type="text"
                    name="SEARCH_STRING"
                    id="keywordSearchString"
                    size="20"
                    maxLength="50"
                    value=""
                  />
                </div>
                <div>
                  <label htmlFor="keywordSearchCategoryId">Category ID:</label>
                  <span className="field-lookup">
                    <div id="0_lookupId_keywordSearchCategoryId_auto"></div>
                    <span
                      role="status"
                      aria-live="polite"
                      className="ui-helper-hidden-accessible"
                    ></span>
                    <input
                      type="text"
                      name="id"
                      size="20"
                      maxLength="20"
                      id="0_lookupId_keywordSearchCategoryId"
                      className="ui-autocomplete-input"
                      autoComplete="off"
                      value={id}
                      onChange={this.handleChange}
                    />
                    <a href="/" id="0_lookupId_button">
                      a
                    </a>
                  </span>
                </div>
                <div>
                  <label htmlFor="keywordSearchCointains">No Contains</label>
                  <input
                    type="checkbox"
                    name="idFilterType"
                    id="keywordSearchCointains"
                    onChange={this.handleChange}
                  />
                  <label htmlFor="keywordSearchOperatorOr">Any</label>
                  <input
                    type="radio"
                    name="SEARCH_OPERATOR"
                    id="keywordSearchOperatorOr"
                    value="or"
                    checked={idOperator === "or" ? true : false}
                    onChange={this.handleChange}
                  />
                  <label htmlFor="keywordSearchOperatorAnd">All</label>
                  <input
                    type="radio"
                    name="SEARCH_OPERATOR"
                    id="keywordSearchOperatorAnd"
                    value="and"
                    onChange={this.handleChange}
                  />
                </div>
                <div>
                  <input
                    type="submit"
                    name="find"
                    value="Find"
                    className="buttontext"
                  />
                </div>
              </fieldset>
            </form>
            <form
              name="advancedsearchform"
              id="advancedSearchForm"
              method="post"
              action="/catalog/control/advancedsearch"
            >
              <fieldset>
                <div>
                  <label htmlFor="searchCategoryId">Category ID:</label>
                  <span className="field-lookup">
                    <div id="1_lookupId_searchCategoryId_auto"></div>
                    <span
                      role="status"
                      aria-live="polite"
                      className="ui-helper-hidden-accessible"
                    ></span>
                    <input
                      type="text"
                      name="id"
                      size="20"
                      maxLength="20"
                      id="1_lookupId_searchCategoryId"
                      className="ui-autocomplete-input"
                      autoComplete="off"
                      value={id}
                      onChange={this.handleChange}
                    />
                    <a href="/" id="1_lookupId_button">
                      a
                    </a>
                  </span>
                </div>
                <div>
                  <Link
                    to={`/catalog/control/AdvancedSearch?categoryId=${id}`}
                    className="buttontext"
                  >
                    Advanced Search
                  </Link>
                </div>
              </fieldset>
            </form>
            <form
              name="productjumpform"
              id="productJumpForm"
              method="post"
              action="/catalog/control/EditProduct"
            >
              <fieldset>
                <input type="hidden" name="viewSize" value="20" />
                <input type="hidden" name="viewIndex" value="1" />
                <span className="field-lookup">
                  <div id="2_lookupId_productJumpFormProductId_auto"></div>
                  <span
                    role="status"
                    aria-live="polite"
                    className="ui-helper-hidden-accessible"
                  ></span>
                  <input
                    type="text"
                    name="productId"
                    size="20"
                    maxLength="20"
                    id="2_lookupId_productJumpFormProductId"
                    className="ui-autocomplete-input"
                    autoComplete="off"
                  />
                  <a href="/" id="2_lookupId_button">
                    a
                  </a>
                </span>

                <select name="DUMMYPAGE" id="dummyPage">
                  <option value="/catalog/control/EditProduct">
                    -Product Jump-
                  </option>
                  <option value="/catalog/control/EditProductQuickAdmin">
                    Quick Admin
                  </option>
                  <option value="/catalog/control/EditProduct">Product</option>
                  <option value="/catalog/control/EditProductPrices">
                    Prices
                  </option>
                  <option value="/catalog/control/EditProductContent">
                    Content
                  </option>
                  <option value="/catalog/control/EditProductGeos">Geos</option>
                  <option value="/catalog/control/EditProductGoodIdentifications">
                    IDs
                  </option>
                  <option value="/catalog/control/EditProductCategories">
                    Categories
                  </option>
                  <option value="/catalog/control/EditProductKeyword">
                    Keywords
                  </option>
                  <option value="/catalog/control/EditProductAssoc">
                    Associations
                  </option>
                  <option value="/catalog/control/ViewProductManufacturing">
                    Manufacturing
                  </option>
                  <option value="/catalog/control/EditProductCosts">
                    Costs
                  </option>
                  <option value="/catalog/control/EditProductAttributes">
                    Attributes
                  </option>
                  <option value="/catalog/control/EditProductFeatures">
                    Features
                  </option>
                  <option value="/catalog/control/EditProductFacilities">
                    Facilities
                  </option>
                  <option value="/catalog/control/EditProductFacilityLocations">
                    Locations
                  </option>
                  <option value="/catalog/control/EditProductInventoryItems">
                    Inventory
                  </option>
                  <option value="/catalog/control/EditProductSuppliers">
                    Suppliers
                  </option>
                  <option value="/catalog/control/ViewProductAgreements">
                    Agreements
                  </option>
                  <option value="/catalog/control/EditProductGlAccounts">
                    Accounts
                  </option>
                  <option value="/catalog/control/EditProductPaymentMethodTypes">
                    Payment Types
                  </option>
                  <option value="/catalog/control/EditProductMaints">
                    Maintenance
                  </option>
                  <option value="/catalog/control/EditProductConfig">
                    Configurations
                  </option>
                  <option value="/catalog/control/EditProductMeters">
                    Meters
                  </option>
                  <option value="/catalog/control/EditProductSubscriptionResources">
                    Subscription Resources
                  </option>
                  <option value="/catalog/control/QuickAddVariants">
                    Variants
                  </option>
                  <option value="/catalog/control/EditProductConfigs">
                    Configurations
                  </option>
                  <option value="/catalog/control/viewProductOrder">
                    OrderOrders
                  </option>
                  <option value="/catalog/control/EditProductCommunicationEvents">
                    Communications
                  </option>
                </select>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
const Orders = ({ match, location }) => {
  var parts = pathParts(location.pathname);
  switch (parts?.[3]) {
    case "main":
      return <RenderOrderSide location={location} />;
    case "findorders":
      return <RenderOrderSide location={location} />;
    case "pnc":
      return <RenderOrderSide location={location} />;
    case "pni":
      return <RenderOrderSide location={location} />;
    case "abc":
      return <RenderOrderSide location={location} />;
    case "OrderNotPaid":
      return <RenderOrderSide location={location} />;
    case "TestPaymentGateways":
      return <RenderOrderSide location={location} />;
    case "SupervisorDashboard":
    case "ListOrderSource":
      return <RenderOrderSide location={location} />;
    case "UploadOrdersInBulk":
      return <RenderOrderSide location={location} />;
    case "createNewNote":
      return <RenderOrderSide location={location}/>
    case "refundOrders":
      return <RenderOrderSide location={location}/>
    case "onHold":
      return <RenderOrderSide location={location}/>
    case "allocated":
      return <RenderOrderSide location={location}/>
    case "notAllocated":
      return <RenderOrderSide location={location}/>
    case "accepted":
      return <RenderOrderSide location={location}/>
    default:
      return "";
  }
};
const RenderOrderSide = () => {
  const [orderId, setOrderId] = useState(null);
  const [subOrderId, setSubOrderId] = useState(null);
  const [partyId, setPartyId] = useState(null);
  const history = useHistory();
  const handlePartySubmit = (e) => {
    e.preventDefault();
    history.push({
      pathname: "/partymgr/control/main",
      search: "?id=" + partyId,
    });
  };
  const getOrderView = async (e) => {
    e.preventDefault();
    let activeElement = document.activeElement;
    let ord = document.getElementById("orderId");
    let sub = document.getElementById("subOrderId");
    let ordr = "";
    if (activeElement === ord) {
      ordr = orderId;
    } else if (activeElement === sub) {
      let temp = subOrderId.substring(0, subOrderId.length - 2);
      ordr = temp;
    }

    history.push({
      pathname: "/ordermgr/control/orderview",
      search: "?orderId=" + ordr,
    });
  };
  return (
    <div class="left">
      <div class="OrderLists">
        <div id="OrderQuickSearchOptionsSL" class="screenlet">
          <div class="screenlet-title-bar">
            <ul>
              <li class="h3">Quick Search</li>
              <li class="expanded">
                <a title="Collapse" href>
                  {" "}
                </a>
              </li>
            </ul>
          </div>
          <div id="OrderQuickSearchOptionsSL_col" class="screenlet-body">
            <div id="OrderQuickSearchOptions">
              <div className="ordersSideB">
                Quick Search:
                <form
                  // method="post"
                  name="ordersearch"
                  onSubmit={(e) => getOrderView(e)}
                >
                  <input name="lookupFlag" type="hidden" value="Y" />{" "}
                  <input name="hideFields" type="hidden" value="Y" />
                  <ul class="ordersearchoptions">
                    <li class="h3">
                      <input
                        max="9999999"
                        maxlength="7"
                        min="1000"
                        name="orderId"
                        pattern="\d{5,7}"
                        value={orderId}
                        onChange={(e) => setOrderId(e.target.value)}
                        step="1"
                        type="number"
                        id="orderId"
                        placeholder="Order Id"
                      />
                    </li>
                    <li class="h3">
                      <input
                        max="999999999"
                        maxlength="9"
                        min="10000"
                        name="subOrderId"
                        pattern="\d{7,9}"
                        value={subOrderId}
                        id="subOrderId"
                        onChange={(e) => setSubOrderId(e.target.value)}
                        step="1"
                        type="number"
                        placeholder="Suborder Id"
                      />
                    </li>
                    {/* <li class="h3">
                      <input
                        name="externalId"
                        type="text"
                        placeholder="External Order Id"
                      />
                    </li> */}
                  </ul>
                  <input class="hidden" type="submit" value="Find" />
                </form>
                <form
                  onSubmit={(e) => handlePartySubmit(e)}
                  name="usersearch"
                  target="_blank"
                >
                  <ul class="ordersearchoptions">
                    <li class="h3">
                      <div id="0_lookupId_partyId_auto"> </div>
                      <span class="field-lookup">
                        <input
                          id="0_lookupId_partyId"
                          class="ui-autocomplete-input"
                          autocomplete="off"
                          maxlength="30"
                          value={partyId}
                          onChange={(e) => setPartyId(e.target.value)}
                          name="partyId"
                          size="20"
                          type="text"
                          placeholder="Customer Id/Name/Email/Phone"
                        />{" "}
                        <a href id="0_lookupId_button">
                          {" "}
                        </a>
                      </span>
                    </li>
                  </ul>
                  <input class="hidden" type="submit" value="Find" />
                </form>
              </div>
            </div>
          </div>
        </div>
        <div id="OrderExpressSL" class="screenlet">
          <div class="screenlet-title-bar">
            <ul>
              <li class="h3">Order Express</li>
              <li class="collapsed">
                <a title="Expand" href>
                  {" "}
                </a>
              </li>
            </ul>
          </div>
          <div id="OrderExpressSL_col" class="screenlet-body">
            <div id="OrderExpress">
              <ul>
                <li>
                  <a href="/ordermgr/control/allocated">
                    Allocated
                  </a>
                </li>
                <li>
                  <a href="/ordermgr/control/accepted">
                    Accepted
                  </a>
                </li>
                <li>
                  <a href="/ordermgr/control/notAllocated">
                    Non Allocated Orders
                  </a>
                </li>
                <li>
                  <a href="/ordermgr/control/onHold">
                    Order on Hold
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div id="CourierOrdersSL" class="screenlet">
          <div class="screenlet-title-bar">
            <ul>
              <li class="h3">Courier Orders</li>
              <li class="expanded">
                <a title="Collapse" href>
                  {" "}
                </a>
              </li>
            </ul>
          </div>
          <div id="CourierOrdersSL_col" class="screenlet-body">
            <div id="CourierOrders">
              <ul>
                <li>
                  <a href="/ordermgr/control/courierOrders?&amp;orderTypeId=SALES_ORDER&amp;primaryProductCategoryId=COURIER,PERSONALIZED&amp;productFilterOperator=IS&amp;sortField=estimatedDeliveryDate&amp;shippingStatus=TO_BE_SHIPPED&amp;redisKey=OMSGRIDS:COURIER:TO_BE_SHIPPED:SO&amp;isAllCancelledOrders=false&amp;includeCreated=false&amp;includeCancelled=false&amp;includeHold=false">
                    To be Shipped
                  </a>
                </li>
                <li>
                  <a href="/ordermgr/control/courierOrders?&amp;orderTypeId=SALES_ORDER&amp;primaryProductCategoryId=COURIER,PERSONALIZED&amp;productFilterOperator=IS&amp;sortField=estimatedDeliveryDate&amp;estimatedShipDate=03-06-2020 19:21:46&amp;todayShipping=Y&amp;shippingStatus=TO_BE_SHIPPED&amp;redisKey=OMSGRIDS:COURIER:TO_BE_SHIPPED:SO:ESD&amp;isAllCancelledOrders=false&amp;includeCreated=false&amp;includeCancelled=false&amp;includeHold=false">
                    Today To be Shipped
                  </a>
                </li>
                <li>
                  <a href="/ordermgr/control/courierOrders?shippingStatus=SHIPMENT_INPUT&amp;orderTypeId=SALES_ORDER&amp;primaryProductCategoryId=COURIER,PERSONALIZED&amp;productFilterOperator=IS&amp;sortField=estimatedDeliveryDate&amp;redisKey=OMSGRIDS:COURIER:PRINTED:SO&amp;isAllCancelledOrders=false&amp;includeCreated=false&amp;includeCancelled=false&amp;includeHold=false">
                    Printed
                  </a>
                </li>
                <li>
                  <a href="/ordermgr/control/courierOrders?shippingStatus=SHIPMENT_PACKED&amp;orderTypeId=SALES_ORDER&amp;primaryProductCategoryId=COURIER,PERSONALIZED&amp;productFilterOperator=IS&amp;sortField=estimatedDeliveryDate&amp;redisKey=OMSGRIDS:COURIER:PACKED:SO&amp;isAllCancelledOrders=false&amp;includeCreated=false&amp;includeCancelled=false&amp;includeHold=false">
                    Packed
                  </a>
                </li>
                <li>
                  <a href="/ordermgr/control/courierOrders?shippingStatus=SHIPMENT_PICKED,SHIPMENT_IN_TRANSIT&amp;orderTypeId=SALES_ORDER&amp;primaryProductCategoryId=COURIER,PERSONALIZED&amp;productFilterOperator=IS&amp;sortField=estimatedDeliveryDate&amp;redisKey=OMSGRIDS:COURIER:PICKED:SO&amp;isAllCancelledOrders=false&amp;includeCreated=false&amp;includeCancelled=false&amp;includeHold=false">
                    Dispatched
                  </a>
                </li>
                <li>
                  <a href="/ordermgr/control/courierOrders?shippingStatus=SHIPMENT_LST_TRANSIT&amp;orderTypeId=SALES_ORDER&amp;primaryProductCategoryId=COURIER,PERSONALIZED&amp;productFilterOperator=IS&amp;sortField=estimatedDeliveryDate&amp;redisKey=OMSGRIDS:COURIER:LOSTINTRANSIT:SO&amp;isAllCancelledOrders=false&amp;includeCreated=false&amp;includeCancelled=false&amp;includeHold=false">
                    Lost In Transit
                  </a>
                </li>
                <li>
                  <a href="/ordermgr/control/courierOrders?shippingStatus=SHIPMENT_DELIVERED,SHIPMENT_DLVRY_ATMPT&amp;orderTypeId=SALES_ORDER&amp;primaryProductCategoryId=COURIER,PERSONALIZED&amp;productFilterOperator=IS&amp;sortField=estimatedDeliveryDate&amp;redisKey=OMSGRIDS:COURIER:DELIVERED:SO&amp;isAllCancelledOrders=false&amp;includeCreated=false&amp;includeCancelled=false&amp;includeHold=false">
                    Delivered
                  </a>
                </li>
                <li>
                  <a href="/ordermgr/control/courierOrders?shippingStatus=SHIPMENT_RETURNED&amp;orderTypeId=SALES_ORDER&amp;primaryProductCategoryId=COURIER,PERSONALIZED&amp;productFilterOperator=IS&amp;sortField=estimatedDeliveryDate&amp;redisKey=OMSGRIDS:COURIER:RETURNED:SO&amp;isAllCancelledOrders=false&amp;includeCreated=false&amp;includeCancelled=false&amp;includeHold=false">
                    RTO
                  </a>
                </li>
                <li>
                  <a href="/ordermgr/control/courierOrders?orderTypeId=SALES_ORDER&amp;productFilterOperator=IS&amp;primaryProductCategoryId=COURIER,PERSONALIZED&amp;subOrderStatus=HOLD">
                    Order on Hold
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div id="InternationalSL" class="screenlet">
          <div class="screenlet-title-bar">
            <ul>
              <li class="h3">International</li>
              <li class="expanded">
                <a title="Collapse" href>
                  {" "}
                </a>
              </li>
            </ul>
          </div>
          <div id="InternationalSL_col" class="screenlet-body">
            <div id="International">
              <ul>
                <li>
                  <a href="/ordermgr/control/orders?allocationStatus=SUPPLIER_ALLOCATED&amp;orderTypeId=SALES_ORDER&amp;productFilterOperator=IS&amp;primaryProductCategoryId=INTERNATIONAL&amp;sortField=estimatedDeliveryDate&amp;redisKey=OMSGRIDS:INTERNATIONAL:ALLOCATED:SO&amp;isAllCancelledOrders=false&amp;includeCreated=false&amp;includeCancelled=false&amp;includeHold=false">
                    Allocated
                  </a>
                </li>
                <li>
                  <a href="/ordermgr/control/orders?allocationStatus=SUPPLIER_ACCEPTED&amp;orderTypeId=SALES_ORDER&amp;productFilterOperator=IS&amp;primaryProductCategoryId=INTERNATIONAL&amp;sortField=estimatedDeliveryDate&amp;redisKey=OMSGRIDS:INTERNATIONAL:ACCEPTED:SO&amp;isAllCancelledOrders=false&amp;includeCreated=false&amp;includeCancelled=false&amp;includeHold=false">
                    Accepted
                  </a>
                </li>
                <li>
                  <a href="/ordermgr/control/orders?allocationStatus=SUPPLIER_UNALLOCATED,SUPPLIER_REJECTED,SUPPLIER_EXPIRED&amp;orderTypeId=SALES_ORDER&amp;productFilterOperator=IS&amp;primaryProductCategoryId=INTERNATIONAL&amp;sortField=estimatedDeliveryDate&amp;redisKey=OMSGRIDS:INTERNATIONAL:NONALLOCATED:SO&amp;isAllCancelledOrders=false&amp;includeCreated=false&amp;includeCancelled=false&amp;includeHold=false">
                    Non Allocated Orders
                  </a>
                </li>
                <li>
                  <a href="/ordermgr/control/orders?&amp;orderTypeId=SALES_ORDER&amp;productFilterOperator=IS&amp;primaryProductCategoryId=INTERNATIONAL&amp;sortField=estimatedDeliveryDate&amp;subOrderStatus=HOLD">
                    Order on Hold
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SideBars = { Catalog, Orders };
export default withRouter(SideBars);
