import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { Notify } from "../../../../constants/utils";
import { getRequest, putRequest } from "../../../../store/services";
export class PNC extends Component {
  constructor() {
    super();
    this.state = {
      startDate: "",
      endDate: "",
      pncOrders: [],
      collapseState: [],
      collapseStateSub: [],
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount = async () => {
    let newNow = moment();
    var startDate = newNow.startOf("day").toDate();
    var endDate = newNow.endOf("day").toDate();

    const response = await getRequest(
      `orders/allpnc?start=${startDate}&end=${endDate}`,
      null,
      true,
      { headers: { "access-token": this.props.token } }
    );
    this.setState({ pncOrders: response.data });
    if (response.data.length > 0) {
      let a = new Array(response.data.length);
      for (let i = 0; i < a.length; i++) {
        a[i] = false;
      }
      this.setState({ collapseState: a, collapseStateSub: a });
    }
  };
  handleDelete = async (e, _id) => {
    e.preventDefault();
    const { startDate, endDate } = this.state;
    let newNow = moment(startDate);
    let newEnd = moment(endDate);
    var start = newNow.startOf("day").toDate();
    var end = newEnd.endOf("day").toDate();
    const response = await putRequest(
      `orders/delete/${_id}?start=${start}&end=${end}`,
      null,
      true,
      { headers: { "access-token": this.props.token } }
    );
    this.setState({ pncOrders: response.data });
    if (response.data.length > 0) {
      let a = new Array(response.data.length);
      for (let i = 0; i < a.length; i++) {
        a[i] = false;
      }
      this.setState({ collapseState: a, collapseStateSub: a });
    }
  };

  handleDateChange = (event) => {
    const fieldName = event.target.name;
    this.setState({ [fieldName]: event.target.value });
  };
  async handleSubmit(e) {
    e.preventDefault();
    const { startDate, endDate } = this.state;
    if (startDate === "" || endDate === "") {
      Notify("danger", "Please enter start and end date.", "Error");
      return;
    }
    let newNow = moment(startDate);
    let newEnd = moment(endDate);
    var start = newNow.startOf("day").toDate();
    var end = newEnd.endOf("day").toDate();

    const response = await getRequest(
      `orders/allpnc?start=${start}&end=${end}`,
      null,
      true,
      { headers: { "access-token": this.props.token } }
    );
    console.log("response from pnc", response.data);
    this.setState({ pncOrders: response.data });
    if (response.data.length > 0) {
      let a = new Array(response.data.length);
      for (let i = 0; i < a.length; i++) {
        a[i] = false;
      }
      this.setState({ collapseState: a, collapseStateSub: a });
    }
  }
  toggle = (index) => {
    let array = [...this.state.collapseState];
    array[index] = !array[index];
    this.setState({ collapseState: array });
  };
  toggleSub = (index) => {
    let array = [...this.state.collapseStateSub];
    array[index] = !array[index];
    this.setState({ collapseStateSub: array });
  };
  render() {
    const { startDate, endDate, pncOrders } = this.state;
    return (
      <div id="content-main-section" className="leftonly">
        <div id="centerdiv" className="no-clear">
          <input id="userLoginId" type="hidden" value="mohan@fnp.uae" />{" "}
          <input id="dialerskill" type="hidden" value="OBOUT" />{" "}
          <input id="dialerlistid" type="hidden" value="5" />
          <form
            id="lookuporder"
            action="/ordermgr/control/abc"
            method="post"
            name="lookuporder"
          >
            <div id="findOrders" className="screenlet">
              <div className="screenlet-body">
                <table className="basic-table" cellspacing="0">
                  <tbody>
                    <tr>
                      <td align="right" width="25%">
                        <span className="label">Order Date From</span>
                      </td>
                      <td width="5%"> </td>
                      <td align="left">
                        <input
                          type="date"
                          name="startDate"
                          value={startDate}
                          onChange={this.handleDateChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td align="right" width="25%">
                        <span className="label">Order Date Through</span>
                      </td>
                      <td width="5%"> </td>
                      <td align="left">
                        <input
                          type="date"
                          name="endDate"
                          value={endDate}
                          onChange={this.handleDateChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td align="right" width="25%">
                        {" "}
                      </td>
                      <td>
                        <input
                          type="submit"
                          onClick={this.handleSubmit}
                          value="Find"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </form>
          <div id="findOrdersList" className="screenlet productDetailsStyle">
            <div className="screenlet-title-bar">
              <ul>
                <li className="h3">Abandoned Order List</li>
                <li className="h3">SearchResult:1445</li>
              </ul>
            </div>
            <div className="screenlet-body">
              <table className="basic-table hover-bar subtable" cellspacing="0">
                <tbody>
                  <tr className="header-row">
                    <th width="7%">Order Date</th>
                    <th width="7%">OrderId</th>
                    <th width="7%">Customer Id</th>
                    <th width="10%">Customer Name</th>
                    <th width="10%">Customer Phone</th>
                    <th width="10%">Sender Phone</th>
                    <th width="7%">Payment Method</th>
                    <th width="7%">Order Status</th>
                    <th width="10%">SubOrder Details</th>
                    <th width="10%">Order Details</th>
                    <th width="10%">Grand Total</th>
                    <th width="10%">Dispose</th>
                    <th width="10%">Created Stamp</th>
                  </tr>
                  {pncOrders && pncOrders.length ? (
                    pncOrders.map((order, index) => {
                      return [
                        <tr>
                          <td>{order?.createdDate}</td>
                          <td>
                            <Link
                              to={`/ordermgr/control/orderview?orderId=${order._id}`}
                            >
                              {order?._id}
                            </Link>
                          </td>
                          <td>{order?.customer}</td>
                          <td>{order?.user.name}</td>
                          <td></td>
                          <td>{order?.senderPhone}</td>
                          <td>{order?.paymentMethod}</td>
                          <td></td>
                          <td>
                            <p>
                              <button
                                class="btn btn-primary"
                                type="button"
                                onClick={(e) => this.toggleSub(index)}
                              >
                                {this.state.collapseStateSub[index] ? "-" : "+"}
                              </button>
                            </p>
                            <div
                              style={{
                                display: this.state.collapseStateSub[index]
                                  ? "block"
                                  : "none",
                              }}
                            >
                              <div class="card card-body">
                                <table>
                                  <tr>
                                    <th>SubOrder Id</th>
                                    <th>Sub Order Status</th>
                                  </tr>

                                  {/* {order.Cart.map((cart) => {
                                    return (
                                      <tr>
                                        <td>{cart?.products[0]?.productId}</td>
                                        <td>{cart?.amount}</td>
                                        <td>{cart?.address?.name}</td>
                                      </tr>
                                    );
                                  })} */}
                                  <tr>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p>
                              <button
                                class="btn btn-primary"
                                type="button"
                                onClick={(e) => this.toggle(index)}
                              >
                                {this.state.collapseState[index] ? "-" : "+"}
                              </button>
                            </p>
                            <div
                              style={{
                                display: this.state.collapseState[index]
                                  ? "block"
                                  : "none",
                              }}
                            >
                              <div class="card card-body">
                                <table>
                                  <tr>
                                    <th>Product Id</th>
                                    <th>Sub Total</th>
                                    <th>Receipient Name</th>
                                  </tr>

                                  {order.Cart.map((cart) => {
                                    return (
                                      <tr>
                                        <td>{cart?.products[0]?.productId}</td>
                                        <td>{cart?.amount}</td>
                                        <td>{cart?.address?.name}</td>
                                      </tr>
                                    );
                                  })}
                                </table>
                              </div>
                            </div>
                          </td>
                          <td>{order.amount}</td>
                          <td>
                            <Link
                              to=""
                              onClick={(e) => this.handleDelete(e, order?._id)}
                            >
                              dispose
                            </Link>
                          </td>
                          <td>{order.createdDate}</td>
                        </tr>,
                      ];
                    })
                  ) : (
                    <tr>
                      <td colSpan="9">
                        <h2>!Record Not Found</h2>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="clear"> </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ token: state.auth.token });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PNC);
