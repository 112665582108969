import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import DataTable from "../../../components/DataTable";
import {
  getAllShipmentMethodsTypes,
  getAllCities,
  getAllProductCitiesList,
  createCityShipment,
  deleteCityShipment,
  updateCityShipment,
} from "../../../../store/actions/catalogActions";
import EditProductNav from "./EditProductNav";
import { Notify } from "../../../../constants/utils";

export default function ProductWiseCitiesList() {
  const dispatch = useDispatch();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const productId = query.productId;
  const {
    catalog: {
      citiesReducer: { cities },
      shipmentMethodTypesReducer: { shipment_method_types },
      productCitiesReducer: { data, isSubmitting },
    },
  } = useSelector((state) => state);
  const [cityId, setCityId] = useState("");
  const [shippmentMethods, setShippmentMethods] = useState([]);
  const [updateInfo, setUpdateInfo] = useState(null);

  useEffect(() => {
    const init = async () => {
      await Promise.all([
        dispatch(getAllProductCitiesList(productId)),
        dispatch(getAllCities()),
        dispatch(getAllShipmentMethodsTypes()),
      ]);
    };
    init();
  }, [dispatch, productId]);

  useEffect(() => {
    if (updateInfo) {
      setCityId(updateInfo?.city?._id);
      setShippmentMethods(updateInfo?.shippmentMethod.map((i) => i._id));
    }
  }, [updateInfo]);

  const getCityShipmentList = () => {
    setCityId("");
    setShippmentMethods([]);
    if (updateInfo) {
      setUpdateInfo(null);
    }
    dispatch(getAllProductCitiesList(productId));
  };

  const createProductCity = (e) => {
    e.preventDefault();
    if (cityId === "" || !shippmentMethods.length) {
      Notify("danger", "Please Fill All the required Fields", "Error");
      return;
    }
    const payload = {
      _id: updateInfo ? updateInfo?._id : undefined,
      productId,
      cityId,
      shipmentId: shippmentMethods,
    };
    if (updateInfo) {
      dispatch(updateCityShipment(payload, () => getCityShipmentList()));
    } else {
      dispatch(createCityShipment(payload, () => getCityShipmentList()));
    }
  };

  const updateCity = (item) => {
    setUpdateInfo(item);
  };

  const deleteCity = (item) => {
    const payload = {
      productId,
      cityId: item?.city?._id,
    };
    dispatch(deleteCityShipment(payload, () => getCityShipmentList()));
  };

  const handleShipmentTypeChange = (id) => {
    setShippmentMethods((prevState) => {
      if (prevState.indexOf(id) !== -1) {
        return prevState.filter((ids) => ids !== id);
      } else {
        return [...prevState, id];
      }
    });
  };

  return (
    <React.Fragment>
      <div id="content-main-section" class="leftonly">
        <div id="centerdiv" class="no-clear">
          <EditProductNav product={productId} />
          <div id="screenlet_1" class="screenlet">
            <div class="screenlet-title-bar">
              <ul>
                <li class="h3">Edit Product Cities</li>
              </ul>
            </div>
            <div id="screenlet_1_col" class="screenlet-body">
              <DataTable>
                <table class="basic-table" cellspacing="0">
                  <tbody>
                    <tr class="header-row">
                      <td>
                        <label
                          id="ProductWiseCitiesList_productCityName_title"
                          for="ProductWiseCitiesList_productCityName"
                        >
                          City
                        </label>
                      </td>
                      <td>
                        <label
                          id="ProductWiseCitiesList_productDelIds_title"
                          for="ProductWiseCitiesList_productDelIds"
                        >
                          Shipment Types
                        </label>
                      </td>
                      <td></td>
                    </tr>
                    {data?.map((item, index) => (
                      <tr key={index}>
                        <td>{item?.city?.name}</td>
                        <td>
                          {item?.shippmentMethod
                            ?.map((shipType) => shipType?.name)
                            .join(" , ")}
                        </td>
                        <td>
                          <button onClick={() => updateCity(item)}>
                            Update
                          </button>
                          <button
                            onClick={() => deleteCity(item)}
                            style={{ marginLeft: 5 }}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </DataTable>
            </div>
            <div class="screenlet-title-bar">
              <ul>
                <li class="h3">
                  {updateInfo ? "Update" : "Add"} Product Cities With Shipment
                  Method {updateInfo ? `#${updateInfo?.city?._id}` : ""}
                </li>
              </ul>
            </div>
            <div id="screenlet_1_col" class="screenlet-body">
              <table class="basic-table" cellspacing="0">
                <tbody>
                  <tr>
                    <td class="label">
                      <label
                        id="AddProductCitiesWithShipmentTypesStatus_productCityName_title"
                        for="AddProductCitiesWithShipmentTypesStatus_productCityName"
                      >
                        City Name*
                      </label>
                    </td>

                    <td>
                      <select
                        value={cityId}
                        onChange={(e) => setCityId(e.target.value)}
                        id="AddProductCitiesWithShipmentTypesStatus_productCityName"
                        name="productCityName"
                        size="1"
                        disabled={Boolean(updateInfo)}
                      >
                        <option value="" disabled>
                          Select City Name
                        </option>
                        {cities.map((city) => (
                          <option value={city?._id} key={city?._id}>
                            {city?.name}
                          </option>
                        ))}
                      </select>
                      *
                    </td>
                  </tr>

                  <tr>
                    <td class="label">
                      <label
                        id="AddProductCitiesWithShipmentTypesStatus_shipmentType_title"
                        for="AddProductCitiesWithShipmentTypesStatus_shipmentType"
                      >
                        Shipment Types*
                      </label>
                    </td>
                    <td style={{ display: "flex", flexWrap: "wrap" }}>
                      {shipment_method_types?.map((shipment, index) => {
                        return (
                          <div
                            onClick={() =>
                              handleShipmentTypeChange(shipment._id)
                            }
                            key={index}
                          >
                            <td>
                              <input
                                type="checkbox"
                                checked={
                                  shippmentMethods.indexOf(shipment._id) !== -1
                                }
                              />
                            </td>
                            <td className="label" style={{ paddingLeft: 5 }}>
                              {shipment.name}
                            </td>
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <button
                        onClick={createProductCity}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {updateInfo ? "Update" : "Create"}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
