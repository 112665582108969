import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {getAllActiveAddresses, getUserById} from '../../../../store/actions/partyManagerActions';

class ViewProfile extends Component {

    componentDidMount() {
        const query_user = new URLSearchParams(this.props?.location?.search);
        this.props.getUserById(query_user.get('_id'));
        this.props.getAllAddresses();
    }

    render() {
        const {userById, apiAddresses} = this.props;
        const query = new URLSearchParams(this.props?.location?.search);
        const partyId = query.get('partyId');

        return (
            <div id="content-main-section">
                <div id="centerdiv" className="no-clear">

                    <div className="button-bar button-style-2">
                        <ul>
                            <li>
                                <ul>
                                    <li className="buttontext create">
                                        <a
                                            className="buttontext create"
                                            href
                                        >
                                            Billing Account
                                        </a>
                                    </li>
                                    <li className="buttontext create">
                                        <a
                                            className="buttontext create"
                                            href
                                        >
                                            Financial Account
                                        </a>
                                    </li>
                                    <li className="buttontext create">
                                        <a
                                            className="buttontext create"
                                            href
                                        >
                                            Communications
                                        </a>
                                    </li>
                                    <li className="buttontext create">
                                        <a
                                            className="buttontext create"
                                            href
                                        >
                                            Requests
                                        </a>
                                    </li>
                                    <li className="buttontext create">
                                        <a
                                            className="buttontext create"
                                            href
                                        >
                                            Quotes
                                        </a>
                                    </li>
                                    <li className="buttontext create">
                                        <a
                                            className="buttontext create"
                                            href
                                        >
                                            Orders
                                        </a>
                                    </li>
                                    <li className="buttontext create">
                                        <a
                                            className="buttontext create"
                                            href
                                        >
                                            New quote
                                        </a>
                                    </li>
                                    <li className="buttontext create">
                                        <a
                                            className="buttontext create"
                                            href
                                        >
                                            New order
                                        </a>
                                    </li>
                                    <li className="buttontext create">
                                        <a
                                            className="buttontext create"
                                            href
                                        >
                                            ABC
                                        </a>
                                    </li>
                                    <li className="buttontext create">
                                        <a
                                            className="buttontext create"
                                            href
                                        >
                                            PNI
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <p className='page-title'>The Profile of {userById.name} [{partyId}]</p>

                    <div className='button-style-2'>
                        <a
                            className="buttontext create"
                            href
                        >
                            Show Old
                        </a>
                    </div>

                    <div className='flex-container'>

                        {/*left side*/}
                        <div style={{width: '57%', marginRight: '4px'}}>

                            {/*personal info*/}
                            <div id="searchOptions" className="screenlet"
                                 style={{marginTop: '10px'}}>
                                <div className="screenlet-title-bar">
                                    <ul>
                                        <li className="h3">Personal Information</li>
                                        <li>
                                            <Link to={`/partymgr/control/editperson?partyId=${partyId}`}>Update</Link>
                                        </li>
                                    </ul>
                                </div>

                                <div id="searchOptions_col" className="screenlet-body">
                                    <div id="search-options">
                                        <table className="basic-table" cellSpacing="0">
                                            <tbody>
                                            <tr>
                                                <td className="label">
                                                    <label>
                                                        Party Id
                                                    </label>
                                                </td>
                                                <td>
                                                    {partyId}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="label">
                                                    <label>
                                                        Name
                                                    </label>
                                                </td>
                                                <td>
                                                    {userById?.name}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="label">
                                                    <label>
                                                        Comments
                                                    </label>
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="label">
                                                    <label>
                                                        External Id
                                                    </label>
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="label">
                                                    <label>
                                                        Status Id
                                                    </label>
                                                </td>
                                                <td>
                                                    Enabled
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div id="screenlet_1" className="screenlet">
                                    <div id="screenlet_1_col" className="screenlet-body no-padding">
                                        <div id="search-results">
                                            <div className="nav-pager">
                                                <ul>
                                                    <li className="nav-first-disabled">First</li>
                                                    <li className="nav-previous-disabled">Previous</li>
                                                    <li className="nav-page-select">
                                                        Page
                                                        <select
                                                            name="page"
                                                            size="1"
                                                            onChange="submitPagination(this, '/catalog/control/FindCatalog?noConditionFind=Y&amp;VIEW_SIZE_1=200&amp;VIEW_INDEX_1='+this.value)"
                                                        >
                                                            <option selected="selected" value="0">
                                                                1
                                                            </option>
                                                        </select>
                                                    </li>
                                                    <li className="nav-next-disabled">Next</li>
                                                    <li className="nav-last-disabled">Last</li>
                                                    <li className="nav-pagesize">
                                                        <select
                                                            name="pageSize"
                                                            size="1"
                                                            onChange="submitPagination(this, '/catalog/control/FindCatalog?noConditionFind=Y&amp;VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')"
                                                        >
                                                            <option value="20">20</option>
                                                            <option value="30">30</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                            <option selected="selected" value="200">
                                                                200
                                                            </option>
                                                        </select>
                                                        Items per page
                                                    </li>
                                                    <li className="nav-displaying">Displaying 1 - 20 of 20</li>
                                                </ul>
                                            </div>
                                            <table className="basic-table hover-bar" cellSpacing="0">
                                                <tbody>
                                                <tr>
                                                    <td style={{borderBottom: '1px solid black'}}>
                                                        <a href className="sort-order">Was</a>
                                                    </td>
                                                    <td style={{borderBottom: '1px solid black'}}>
                                                        <a href>Changed</a>
                                                    </td>
                                                </tr>
                                                {/*<tr>*/}
                                                {/*    <td>*/}
                                                {/*        <p className="sort-order">abc</p>*/}
                                                {/*    </td>*/}
                                                {/*    <td>*/}
                                                {/*        <p> 13-07-2017 11:52:49</p>*/}
                                                {/*    </td>*/}
                                                {/*</tr>*/}
                                                </tbody>
                                            </table>
                                            <div className="nav-pager">
                                                <ul>
                                                    <li className="nav-first-disabled">First</li>
                                                    <li className="nav-previous-disabled">Previous</li>
                                                    <li className="nav-page-select">
                                                        Page
                                                        <select name="page" size="1">
                                                            <option selected="selected" value="0">
                                                                1
                                                            </option>
                                                        </select>
                                                    </li>
                                                    <li className="nav-next-disabled">Next</li>
                                                    <li className="nav-last-disabled">Last</li>
                                                    <li className="nav-pagesize">
                                                        <select name="pageSize" size="1">
                                                            <option value="20">20</option>
                                                            <option value="30">30</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                            <option selected="selected" value="200">
                                                                200
                                                            </option>
                                                        </select>
                                                        Items per page
                                                    </li>
                                                    <li className="nav-displaying">Displaying 1 - 20 of 20</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/*personal info ends here*/}

                            {/*accounts*/}
                            <div id="searchOptions" className="screenlet"
                                 style={{marginTop: '10px'}}>
                                <div className="screenlet-title-bar">
                                    <ul>
                                        <li className="h3">List Related Accounts</li>
                                        <li>
                                            <a href>Create New</a>
                                        </li>
                                    </ul>
                                </div>

                                <div id="screenlet_1" className="screenlet">
                                    <div id="screenlet_1_col" className="screenlet-body no-padding">
                                        <div id="search-results">
                                            <div className="nav-pager">
                                                <ul>
                                                    <li className="nav-first-disabled">First</li>
                                                    <li className="nav-previous-disabled">Previous</li>
                                                    <li className="nav-page-select">
                                                        Page
                                                        <select
                                                            name="page"
                                                            size="1"
                                                            onChange="submitPagination(this, '/catalog/control/FindCatalog?noConditionFind=Y&amp;VIEW_SIZE_1=200&amp;VIEW_INDEX_1='+this.value)"
                                                        >
                                                            <option selected="selected" value="0">
                                                                1
                                                            </option>
                                                        </select>
                                                    </li>
                                                    <li className="nav-next-disabled">Next</li>
                                                    <li className="nav-last-disabled">Last</li>
                                                    <li className="nav-pagesize">
                                                        <select
                                                            name="pageSize"
                                                            size="1"
                                                            onChange="submitPagination(this, '/catalog/control/FindCatalog?noConditionFind=Y&amp;VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')"
                                                        >
                                                            <option value="20">20</option>
                                                            <option value="30">30</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                            <option selected="selected" value="200">
                                                                200
                                                            </option>
                                                        </select>
                                                        Items per page
                                                    </li>
                                                    <li className="nav-displaying">Displaying 1 - 20 of 20</li>
                                                </ul>
                                            </div>
                                            <table className="basic-table hover-bar" cellSpacing="0">
                                                <tbody>
                                                <tr>
                                                    <td style={{borderBottom: '1px solid black'}}>
                                                        <a href className="sort-order">From Party ID</a>
                                                    </td>
                                                    <td style={{borderBottom: '1px solid black'}}>
                                                        <a href>Type</a>
                                                    </td>
                                                    <td style={{borderBottom: '1px solid black'}}>
                                                        <a href>Comments</a>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <div className="nav-pager">
                                                <ul>
                                                    <li className="nav-first-disabled">First</li>
                                                    <li className="nav-previous-disabled">Previous</li>
                                                    <li className="nav-page-select">
                                                        Page
                                                        <select name="page" size="1">
                                                            <option selected="selected" value="0">
                                                                1
                                                            </option>
                                                        </select>
                                                    </li>
                                                    <li className="nav-next-disabled">Next</li>
                                                    <li className="nav-last-disabled">Last</li>
                                                    <li className="nav-pagesize">
                                                        <select name="pageSize" size="1">
                                                            <option value="20">20</option>
                                                            <option value="30">30</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                            <option selected="selected" value="200">
                                                                200
                                                            </option>
                                                        </select>
                                                        Items per page
                                                    </li>
                                                    <li className="nav-displaying">Displaying 1 - 20 of 20</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/*accounts end here*/}

                            {/*contact info*/}
                            <div id="searchOptions" className="screenlet" style={{marginTop: '10px'}}>
                                <div className="screenlet-title-bar">
                                    <ul>
                                        <li className="h3">Contact Information</li>
                                        <li>
                                            <a href> Create New </a>
                                        </li>
                                    </ul>
                                </div>

                                <div id="searchOptions_col" className="screenlet-body">
                                    <div id="search-options">
                                        <table className='basic-table' cellSpacing="0">
                                            <tbody>
                                            <tr>
                                                <th className="" style={{borderBottom: '1px solid black'}}>
                                                    Contact Type
                                                </th>
                                                <th className="" style={{borderBottom: '1px solid black'}}>
                                                    Contact Information
                                                </th>
                                                <th className="" style={{borderBottom: '1px solid black'}}>
                                                    Soliciting OK?
                                                </th>
                                                <td className="label" style={{borderBottom: '1px solid black'}}>
                                                </td>
                                            </tr>

                                            {
                                                apiAddresses.map((addr) => {
                                                    return (
                                                        (addr.user._id === userById._id) &&
                                                        <tr>
                                                            <td className='contact-info-td' style={{fontWeight: 'bold'}}>
                                                                Postal Address
                                                            </td>
                                                            <td className="contact-info-td" style={{width: '50%'}}>
                                                                <span style={{fontWeight: 'bold'}}>Shipping Destination Address</span>
                                                                <p>{addr.name} <br/> {addr.recipientAddress}, {addr.recipientCity}</p>
                                                                <p>{addr.phone}</p>
                                                                (Created: {new Date(addr.createdDate).toLocaleString()})
                                                            </td>
                                                            <td className="contact-info-td">
                                                                ()
                                                            </td>
                                                            <td className="label contact-info-td">
                                                                <div className='button-style-2'>
                                                                    <a className='buttontext' href>Update</a>
                                                                </div>

                                                                <div className='button-style-2'>
                                                                    <a className='buttontext' href>Expire</a>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                            {/*contact info end here*/}

                            {/*Loyalty points*/}
                            <div id="searchOptions" className="screenlet" style={{marginTop: '10px'}}>
                                <div className="screenlet-title-bar">
                                    <ul>
                                        <li className="h3">Loyalty Points</li>
                                    </ul>
                                </div>

                                <div id="searchOptions_col" className="screenlet-body">
                                    <div id="search-options">
                                        <table cellSpacing="0">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    You have 0 points from 0 order(s) in the last 12 Months.
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                            {/*Loyalty points end here*/}

                            {/*Payment method*/}
                            <div id="searchOptions" className="screenlet" style={{marginTop: '10px'}}>
                                <div className="screenlet-title-bar">
                                    <ul>
                                        <li className="h3">Payment Method Information</li>
                                        <li>
                                            <Link to={`editcreditcard?partyId=${partyId}`}> Create New Credit Card </Link>
                                        </li>
                                        <li>
                                            <Link to={`editgiftcard?partyId=${partyId}`}> Create New Gift Card </Link>
                                        </li>
                                        <li>
                                            <Link to={`editeftaccount?partyId=${partyId}`}> Create New EFT Account </Link>
                                        </li>
                                        <li>
                                            <Link to={`EditBillingAccount?partyId=${partyId}`}> Create Billing Account </Link>
                                        </li>
                                    </ul>
                                </div>

                                <div id="searchOptions_col" className="screenlet-body">
                                    <div id="search-options">
                                        <table cellSpacing="0">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    No payment method information on file.
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                            {/*Payment method end here*/}

                            {/*identification*/}
                            <div id="searchOptions" className="screenlet"
                                 style={{marginTop: '10px'}}>
                                <div className="screenlet-title-bar">
                                    <ul>
                                        <li className="h3">Identification Numbers</li>
                                        <li>
                                            <a href>New</a>
                                        </li>
                                    </ul>
                                </div>

                                <div id="screenlet_1" className="screenlet">
                                    <div id="screenlet_1_col" className="screenlet-body no-padding">
                                        <div id="search-results">
                                            <div className="nav-pager">
                                                <ul>
                                                    <li className="nav-first-disabled">First</li>
                                                    <li className="nav-previous-disabled">Previous</li>
                                                    <li className="nav-page-select">
                                                        Page
                                                        <select
                                                            name="page"
                                                            size="1"
                                                            onChange="submitPagination(this, '/catalog/control/FindCatalog?noConditionFind=Y&amp;VIEW_SIZE_1=200&amp;VIEW_INDEX_1='+this.value)"
                                                        >
                                                            <option selected="selected" value="0">
                                                                1
                                                            </option>
                                                        </select>
                                                    </li>
                                                    <li className="nav-next-disabled">Next</li>
                                                    <li className="nav-last-disabled">Last</li>
                                                    <li className="nav-pagesize">
                                                        <select
                                                            name="pageSize"
                                                            size="1"
                                                            onChange="submitPagination(this, '/catalog/control/FindCatalog?noConditionFind=Y&amp;VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')"
                                                        >
                                                            <option value="20">20</option>
                                                            <option value="30">30</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                            <option selected="selected" value="200">
                                                                200
                                                            </option>
                                                        </select>
                                                        Items per page
                                                    </li>
                                                    <li className="nav-displaying">Displaying 1 - 20 of 20</li>
                                                </ul>
                                            </div>
                                            <table className="basic-table hover-bar" cellSpacing="0">
                                                <tbody>
                                                <tr>
                                                    <td style={{borderBottom: '1px solid black'}}>
                                                        <a href className="sort-order">Identification Type</a>
                                                    </td>
                                                    <td style={{borderBottom: '1px solid black'}}>
                                                        <a href>Id Value</a>
                                                    </td>
                                                    <td style={{borderBottom: '1px solid black'}}>
                                                        <a href>Delete</a>
                                                    </td>
                                                    <td style={{borderBottom: '1px solid black'}}>
                                                        <a href>Update</a>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <div className="nav-pager">
                                                <ul>
                                                    <li className="nav-first-disabled">First</li>
                                                    <li className="nav-previous-disabled">Previous</li>
                                                    <li className="nav-page-select">
                                                        Page
                                                        <select name="page" size="1">
                                                            <option selected="selected" value="0">
                                                                1
                                                            </option>
                                                        </select>
                                                    </li>
                                                    <li className="nav-next-disabled">Next</li>
                                                    <li className="nav-last-disabled">Last</li>
                                                    <li className="nav-pagesize">
                                                        <select name="pageSize" size="1">
                                                            <option value="20">20</option>
                                                            <option value="30">30</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                            <option selected="selected" value="200">
                                                                200
                                                            </option>
                                                        </select>
                                                        Items per page
                                                    </li>
                                                    <li className="nav-displaying">Displaying 1 - 20 of 20</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/*identification end here*/}

                        </div>
                        {/*left side ends here*/}

                        {/*right side*/}
                        <div style={{width: '43%'}}>

                            {/*user names*/}
                            <div id="searchOptions" className="screenlet" style={{marginTop: '10px'}}>
                                <div className="screenlet-title-bar">
                                    <ul>
                                        <li className="h3">User Name(s)</li>
                                        <li>
                                            <a href>Create New</a>
                                        </li>
                                    </ul>
                                </div>

                                <div id="searchOptions_col" className="screenlet-body">
                                    <div id="search-options">
                                        <table cellSpacing="0">
                                            <tbody>
                                            <tr>
                                                <td className="label">
                                                    <label>
                                                        User Login
                                                    </label>
                                                </td>
                                                <td>
                                                    {userById.email}
                                                </td>
                                                <td>
                                                    ENABLED
                                                </td>
                                                <td>
                                                    <div className='button-style-2'>
                                                        <a className='buttontext' href>Edit</a>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                            {/*user names end here*/}

                            {/*party attributes*/}
                            <div id="searchOptions" className="screenlet" style={{marginTop: '10px'}}>
                                <div className="screenlet-title-bar">
                                    <ul>
                                        <li className="h3">Party Attribute(s)</li>
                                        <li>
                                            <a href>Create New</a>
                                        </li>
                                    </ul>
                                </div>

                                <div id="searchOptions_col" className="screenlet-body">
                                    <div id="search-options">
                                        <table cellSpacing="0">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    No party attributes found.
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                            {/*party attributes end here*/}

                            {/*fraud screening*/}
                            <div id="searchOptions" className="screenlet" style={{marginTop: '10px'}}>
                                <div className="screenlet-title-bar">
                                    <ul>
                                        <li className="h3">AVS Override (CC Fraud Screening)</li>
                                    </ul>
                                </div>

                                <div id="searchOptions_col" className="screenlet-body">
                                    <div id="search-options">
                                        <table cellSpacing="0">
                                            <tbody>
                                            <tr>
                                                <td className="label">
                                                    <label>
                                                        AVS String
                                                    </label>
                                                </td>
                                                <td>
                                                    <div className='flex-container'>
                                                        <span className='button-style-2'>Global
                                                            {' '} <a className='buttontext' href>Edit</a>
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                            {/*fraud screening end here*/}

                            {/*visits*/}
                            <div id="searchOptions" className="screenlet" style={{marginTop: '10px'}}>
                                <div className="screenlet-title-bar">
                                    <ul>
                                        <li className="h3">Visits</li>
                                        <li>
                                            <a href>List All</a>
                                        </li>
                                    </ul>
                                </div>

                                <div id="searchOptions_col" className="screenlet-body">
                                    <div id="search-options">
                                        <table cellSpacing="0">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    Click on List All to view the visit list
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                            {/*visits end here*/}

                            {/*party content*/}
                            <div id="searchOptions" className="screenlet" style={{marginTop: '10px'}}>
                                <div className="screenlet-title-bar">
                                    <ul>
                                        <li className="h3">Party Content</li>
                                    </ul>
                                </div>

                                <div id="searchOptions_col" className="screenlet-body">
                                    <div id="search-options">

                                        <p style={{borderBottom: '1px solid black'}}>No Content</p>

                                        <div style={{marginTop: '6px'}}>

                                            <p style={{fontWeight: 'bold'}}>Attach Content</p>

                                            <div>
                                                <input type='file' className='back-red'/>
                                            </div>

                                            <div>
                                                <select className='back-red'>
                                                    <option>Select purpose</option>
                                                    <option value="int">Internal Content</option>
                                                    <option value="logoimg">Logo Image URL</option>
                                                    <option value="usrdefine">User Defined Content</option>
                                                    <option value="vendorship">Vendor Shipping Info</option>
                                                </select>
                                            </div>

                                            <p style={{fontWeight: 'bold'}}>Is Public</p>

                                            <div className='flex-container'>

                                                <div>
                                                    <select>
                                                        <option value='no'>No</option>
                                                        <option value='yes'>Yes</option>
                                                    </select>
                                                </div>

                                                <div>
                                                    <select className='custom-select'>
                                                        <option value='no'>Not Applicable</option>
                                                        <option value='no'>Not Applicable</option>
                                                        <option value='no'>Not Applicable</option>
                                                        <option value='no'>Not Applicable</option>
                                                    </select>
                                                </div>

                                                <div className='button-style-2' style={{marginLeft: '6px'}}>
                                                    <a className='buttontext' href>Upload</a>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                            {/*parry content end here*/}

                            {/*notes*/}
                            <div id="searchOptions" className="screenlet" style={{marginTop: '10px'}}>
                                <div className="screenlet-title-bar">
                                    <ul>
                                        <li className="h3">Notes</li>
                                        <li>
                                            <a href>Create New</a>
                                        </li>
                                    </ul>
                                </div>

                                <div id="searchOptions_col" className="screenlet-body">
                                    <div id="search-options">
                                        <table cellSpacing="0">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    No notes for this party.
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                            {/*notes end here*/}

                            {/*stores*/}
                            <div id="searchOptions" className="screenlet" style={{marginTop: '10px'}}>
                                <div className="screenlet-title-bar">
                                    <ul>
                                        <li className="h3">Stores</li>
                                    </ul>
                                </div>

                                <div id="searchOptions_col" className="screenlet-body">
                                    <div id="search-options">
                                        <table className='basic-table' cellSpacing="0">
                                            <tbody>
                                            <tr>
                                                <td className="label" style={{borderBottom: '1px solid black'}}>
                                                    <label>
                                                        Store Name [ID]
                                                    </label>
                                                </td>
                                                <td style={{borderBottom: '1px solid black', textAlign: 'center'}}>
                                                    Role Type
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className='button-style-2'>
                                                        <a className='buttontext' href>FNPAE(30000)</a>
                                                    </span>
                                                </td>
                                                <td style={{textAlign: 'center'}}>
                                                    Customer
                                                </td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                            {/*stores end here*/}

                        </div>
                        {/*right side ends here*/}

                    </div>

                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    userById: state.partyManager?.users?.userById,
    apiAddresses: state.partyManager?.users?.apiAddresses
});

const mapDispatchToProps = (dispatch) => ({
    getUserById: (userId) => dispatch(getUserById(userId)),
    getAllAddresses: () => dispatch(getAllActiveAddresses())
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewProfile);
