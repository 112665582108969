import React, { Component } from "react";
import { connect } from "react-redux";

export class FinRequirements extends Component {
  render() {
    return (
      <div id="content-main-section">
        <div class="button-bar tab-bar">
          <ul>
            <li>
              <ul>
                <li class="selected">
                  <a href="/ordermgr/control/FindRequirements">Requirements</a>
                </li>
                <li>
                  <a href="/ordermgr/control/ApproveRequirements">
                    Approve Requirements
                  </a>
                </li>
                <li>
                  <a href="/ordermgr/control/ApprovedProductRequirementsByVendor">
                    Find Approved Requirements By Supplier
                  </a>
                </li>
                <li>
                  <a href="/ordermgr/control/ApprovedProductRequirements">
                    Approved Product Requirements
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div>
          <a class="buttontext" href="/ordermgr/control/EditRequirement">
            {" "}
            New Requirement{" "}
          </a>
        </div>
        <div class="page-title">Find Requirements</div>
        <div class="clear"> </div>
        <div id="searchOptions" class="screenlet">
          <div class="screenlet-title-bar">
            <ul>
              <li class="h3">Search Options</li>
              <li class="expanded">
                <a href title="Collapse">
                  {" "}
                </a>
              </li>
            </ul>
          </div>
          <div id="searchOptions_col" class="screenlet-body">
            <div id="search-options">
              <form
                id="FindRequirements"
                class="basic-form"
                action="/ordermgr/control/FindRequirements"
                method="post"
                name="FindRequirements"
              >
                <input
                  id="FindRequirements_noConditionFind"
                  name="noConditionFind"
                  type="hidden"
                  value="Y"
                />
                <table class="basic-table" cellspacing="0">
                  <tbody>
                    <tr>
                      <td class="label">
                        <label
                          id="FindRequirements_requirementId_title"
                          for="FindRequirements_requirementId"
                        >
                          Requirement Id
                        </label>
                      </td>
                      <td>
                        <select class="selectBox" name="requirementId_op">
                          <option value="equals">Equals</option>
                          <option value="like">Begins With</option>
                          <option selected="selected" value="contains">
                            Contains
                          </option>
                          <option value="empty">Is Empty</option>
                          <option value="notEqual">Not Equal</option>
                        </select>
                        <input name="requirementId" size="25" type="text" />
                        <input
                          checked="checked"
                          name="requirementId_ic"
                          type="checkbox"
                          value="Y"
                        />
                        Ignore Case
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="FindRequirements_requirementTypeId_title"
                          for="FindRequirements_requirementTypeId"
                        >
                          Requirement Type Id
                        </label>
                      </td>
                      <td>
                        <span class="ui-widget">
                          <select
                            id="FindRequirements_requirementTypeId"
                            name="requirementTypeId"
                            size="1"
                          >
                            <option value=""> </option>
                            <option value="CUSTOMER_REQUIREMENT">
                              Customer Requirement
                            </option>
                            <option value="INTERNAL_REQUIREMENT">
                              Internal Requirement
                            </option>
                            <option value="PRODUCT_REQUIREMENT">
                              Product Requirement
                            </option>
                            <option value="WORK_REQUIREMENT">
                              Work Requirement
                            </option>
                          </select>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="FindRequirements_statusId_title"
                          for="FindRequirements_statusId"
                        >
                          Status ID
                        </label>
                      </td>
                      <td>
                        <span class="ui-widget">
                          <select
                            id="FindRequirements_statusId"
                            name="statusId"
                            size="1"
                          >
                            <option value=""> </option>
                            <option value="REQ_CREATED">Created</option>
                            <option value="REQ_PROPOSED">Proposed</option>
                            <option value="REQ_APPROVED">Approved</option>
                            <option value="REQ_ORDERED">Ordered</option>
                            <option value="REQ_REJECTED">Rejected</option>
                          </select>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="FindRequirements_facilityId_title"
                          for="FindRequirements_facilityId"
                        >
                          Facility Id
                        </label>
                      </td>
                      <td>
                        <span class="ui-widget">
                          <select
                            id="FindRequirements_facilityId"
                            name="facilityId"
                            size="1"
                          >
                            <option value=""> </option>
                            <option value="10000">10000</option>
                            <option value="30000">30000</option>
                            <option value="MyRetailStore">MyRetailStore</option>
                            <option value="WebStoreWarehouse">
                              WebStoreWarehouse
                            </option>
                          </select>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="FindRequirements_productId_title"
                          for="FindRequirements_productId"
                        >
                          Product Id
                        </label>
                      </td>
                      <td>
                        <div id="0_lookupId_FindRequirements_productId_auto">
                          {" "}
                        </div>
                        <span class="field-lookup">
                          <input
                            id="0_lookupId_FindRequirements_productId"
                            class="ui-autocomplete-input"
                            autocomplete="off"
                            name="productId"
                            size="25"
                            type="text"
                          />
                          <a href id="0_lookupId_button">
                            {" "}
                          </a>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="FindRequirements_description_title"
                          for="FindRequirements_description"
                        >
                          Description
                        </label>
                      </td>
                      <td>
                        <select class="selectBox" name="description_op">
                          <option value="equals">Equals</option>
                          <option value="like">Begins With</option>
                          <option selected="selected" value="contains">
                            Contains
                          </option>
                          <option value="empty">Is Empty</option>
                          <option value="notEqual">Not Equal</option>
                        </select>
                        <input name="description" size="25" type="text" />
                        <input
                          checked="checked"
                          name="description_ic"
                          type="checkbox"
                          value="Y"
                        />
                        Ignore Case
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="FindRequirements_requirementStartDate_title"
                          for="FindRequirements_requirementStartDate"
                        >
                          Manufacturing Requirement Start Date
                        </label>
                      </td>
                      <td>
                        <span class="view-calendar">
                          <input
                            id="requirementStartDate_fld0_value"
                            class="hasDatepicker"
                            title="dd-MM-yyyy HH:mm:ss"
                            maxlength="10"
                            name="requirementStartDate_fld0_value"
                            size="10"
                            type="text"
                          />
                          <button
                            class="ui-datepicker-trigger"
                            type="button"
                          ></button>
                          <select
                            class="selectBox"
                            name="requirementStartDate_fld0_op"
                          >
                            <option selected="selected" value="equals">
                              Equals
                            </option>
                            <option value="sameDay">Same Day</option>
                            <option value="greaterThanFromDayStart">
                              Greater Than From Day Start
                            </option>
                            <option value="greaterThan">Greater Than</option>
                          </select>
                          <input
                            id="requirementStartDate_fld1_value"
                            class="hasDatepicker"
                            title="dd-MM-yyyy HH:mm:ss"
                            maxlength="10"
                            name="requirementStartDate_fld1_value"
                            size="10"
                            type="text"
                          />
                          <button
                            class="ui-datepicker-trigger"
                            type="button"
                          ></button>
                          <select
                            class="selectBox"
                            name="requirementStartDate_fld1_op"
                          >
                            <option value="opLessThan">Less Than</option>
                            <option value="upToDay">Up To Day</option>
                            <option value="upThruDay">Up Through Day</option>
                            <option value="empty">Is Empty</option>
                          </select>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="FindRequirements_requiredByDate_title"
                          for="FindRequirements_requiredByDate"
                        >
                          Manufacturing Requirement By Date
                        </label>
                      </td>
                      <td>
                        <span class="view-calendar">
                          <input
                            id="requiredByDate_fld0_value"
                            class="hasDatepicker"
                            title="dd-MM-yyyy HH:mm:ss"
                            maxlength="10"
                            name="requiredByDate_fld0_value"
                            size="10"
                            type="text"
                          />
                          <button
                            class="ui-datepicker-trigger"
                            type="button"
                          ></button>
                          <select
                            class="selectBox"
                            name="requiredByDate_fld0_op"
                          >
                            <option selected="selected" value="equals">
                              Equals
                            </option>
                            <option value="sameDay">Same Day</option>
                            <option value="greaterThanFromDayStart">
                              Greater Than From Day Start
                            </option>
                            <option value="greaterThan">Greater Than</option>
                          </select>
                          <input
                            id="requiredByDate_fld1_value"
                            class="hasDatepicker"
                            title="dd-MM-yyyy HH:mm:ss"
                            maxlength="10"
                            name="requiredByDate_fld1_value"
                            size="10"
                            type="text"
                          />
                          <button
                            class="ui-datepicker-trigger"
                            type="button"
                          ></button>
                          <select
                            class="selectBox"
                            name="requiredByDate_fld1_op"
                          >
                            <option value="opLessThan">Less Than</option>
                            <option value="upToDay">Up To Day</option>
                            <option value="upThruDay">Up Through Day</option>
                            <option value="empty">Is Empty</option>
                          </select>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td class="label"> </td>
                      <td colspan="4">
                        <input
                          class="smallSubmit"
                          name="submitButton"
                          type="submit"
                          value="Find"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
        <div id="screenlet_1" class="screenlet">
          <div id="screenlet_1_col" class="screenlet-body no-padding">
            <h3>Search Results</h3>
            <div id="search-results">
              <div class="nav-pager">
                <ul>
                  <li class="nav-first-disabled">First</li>
                  <li class="nav-previous-disabled">Previous</li>
                  <li class="nav-next-disabled">Next</li>
                  <li class="nav-last-disabled">Last</li>
                  <li class="nav-pagesize">
                    <select
                      name="pageSize"
                      size="1"
                      onchange="submitPagination(this, '')"
                    >
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option selected="selected" value="200">
                        200
                      </option>
                    </select>
                    Items per page
                  </li>
                  <li class="nav-displaying">Displaying 1 - 0 of 0</li>
                </ul>
              </div>
              <table class="basic-table hover-bar" cellspacing="0">
                <tbody>
                  <tr class="header-row">
                    <td>
                      <label
                        id="ListRequirements_requirementId_title"
                        for="ListRequirements_requirementId"
                      >
                        Requirement Id
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListRequirements_statusId_title"
                        for="ListRequirements_statusId"
                      >
                        Status ID
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListRequirements_requirementTypeId_title"
                        for="ListRequirements_requirementTypeId"
                      >
                        Requirement Type Id
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListRequirements_facilityId_title"
                        for="ListRequirements_facilityId"
                      >
                        Facility Id
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListRequirements_productId_title"
                        for="ListRequirements_productId"
                      >
                        Product Id
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListRequirements_requirementStartDate_title"
                        for="ListRequirements_requirementStartDate"
                      >
                        Requirement Start Date
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListRequirements_requiredByDate_title"
                        for="ListRequirements_requiredByDate"
                      >
                        Required By Date
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListRequirements_quantity_title"
                        for="ListRequirements_quantity"
                      >
                        Quantity
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListRequirements_facilityQuantityOnHandTotal_title"
                        for="ListRequirements_facilityQuantityOnHandTotal"
                      >
                        Facility Quantity On Hand Total
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListRequirements_quantityOnHandTotal_title"
                        for="ListRequirements_quantityOnHandTotal"
                      >
                        Quantity On Hand Total
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListRequirements_requestsLink_title"
                        for="ListRequirements_requestsLink"
                      >
                        Requests Link
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListRequirements_ordersLink_title"
                        for="ListRequirements_ordersLink"
                      >
                        Orders Link
                      </label>
                    </td>
                    <td> </td>
                  </tr>
                </tbody>
              </table>
              <div class="nav-pager">
                <ul>
                  <li class="nav-first-disabled">First</li>
                  <li class="nav-previous-disabled">Previous</li>
                  <li class="nav-next-disabled">Next</li>
                  <li class="nav-last-disabled">Last</li>
                  <li class="nav-pagesize">
                    <select
                      name="pageSize"
                      size="1"
                      onchange="submitPagination(this, '')"
                    >
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option selected="selected" value="200">
                        200
                      </option>
                    </select>
                    Items per page
                  </li>
                  <li class="nav-displaying">Displaying 1 - 0 of 0</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FinRequirements);
