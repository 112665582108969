// import React, { Component } from "react";
// import { connect } from "react-redux";
// import {
//   createCategories,
//   getCategory,
//   allActiveCatalogs,
//   updateCategory,
//   getProductsByCategory,
//   updateProductPrecedence,
// } from "../../../../store/actions/catalogActions";
// import {
//   reorder,
// } from "react-reorder";
// import Reorder from "react-reorder";
// import SequenceModal from "./SequenceModal";
// export class ProductSequencing extends Component {
//   state={
//     modalOpen:false,
//     presentIndex:null,
//   }
//   handleClose=()=>{
//     this.setState({modalOpen:false})
//   }
//   submit=(newIndex)=>{
//     this.onReorder(null,this.state.presentIndex,newIndex);
//     this.setState({modalOpen:false})
//   }
//   render() {
//     const { myList } = this.state;
//     return (
//       <div id="content-main-section" className="leftonly">
//         <SequenceModal
//         modalOpen={this.state.modalOpen}
//         handleClose={this.handleClose}
//         onSubmit={this.submit}
//         />
//         <div id="centerdiv" className="no-clear">
//           <div className="screenlet">
//             <div class="button-bar tab-bar">
//               <ul>
//                 <li>
//                   <ul>
//                     <li>
//                       <a
//                         href={
//                           "/catalog/control/EditCategory?productCategoryId=" +
//                           this.state._id
//                         }
//                       >
//                         Category
//                       </a>
//                     </li>
//                     <li>
//                       <a
//                         href={
//                           "/catalog/control/ProductSequencing?productCategoryId=" +
//                           this.state._id
//                         }
//                       >
//                         Product Sequencing
//                       </a>
//                     </li>
//                       <li>
//                         <a
//                             href={
//                               "/catalog/control/Contents?productCategoryId=" +
//                               this.state._id
//                             }
//                         >
//                           Contents
//                         </a>
//                       </li>

//                   </ul>
//                 </li>
//               </ul>
//             </div>
//             <div className="screenlet-title-bar">
//               <ul>
//                 <li className="h3">Edit Category Products</li>
//               </ul>
//             </div>

//             <div
//               className="screenlet-body"
//               style={{ display: "flex", flexDirection: "row" }}
//             >
//               <Reorder
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   flexWrap: "wrap",
//                 }}
//                 reorderId="my-list" // Unique ID that is used internally to track this list (required)
//                 reorderGroup="reorder-group" // A group ID that allows items to be dragged between lists of the same group (optional)
//                 // getRef={this.storeRef.bind(this)} // Function that is passed a reference to the root node when mounted (optional)
//                 component="ul" // Tag name or Component to be used for the wrapping element (optional), defaults to 'div'
//                 placeholderClassName="placeholder" // Class name to be applied to placeholder elements (optional), defaults to 'placeholder'
//                 draggedClassName="dragged" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
//                 // lock="vertical" // Lock the dragging direction (optional): vertical, horizontal (do not use with groups)
//                 holdTime={0} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
//                 touchHoldTime={0} // Hold time before dragging begins on touch devices (optional), defaults to holdTime
//                 mouseHoldTime={0} // Hold time before dragging begins with mouse (optional), defaults to holdTime
//                 onReorder={this.onReorder.bind(this)} // Callback when an item is dropped (you will need this to update your state)
//                 autoScroll={false} // Enable auto-scrolling when the pointer is close to the edge of the Reorder component (optional), defaults to true
//                 disabled={false} // Disable reordering (optional), defaults to false
//                 disableContextMenus={false} // Disable context menus when holding on touch devices (optional), defaults to true
//                 placeholder={
//                   <div className="custom-placeholder" /> // Custom placeholder element (optional), defaults to clone of dragged element
//                 }
//               >
//                 {
//                   this.state.myList.map((item,index) => (
//                     <li
//                       key={item.precedence}
//                       onContextMenu={(e)=> {
//                         e.preventDefault();
//                         this.setState({modalOpen:true,presentIndex:index});
//                       }}
//                       style={{ marginRight: "10px", cursor: "move",width:'22%'}}
//                     >
//                       <img
//                         src={item?.images ? item.images[0] : ""}
//                         alt=""
//                         height="auto"
//                         width="100%"
//                       />
//                       <p style={{ fontSize: 15 }}>{item?.title}</p>
//                       <p style={{ fontSize: 12, marginTop: "15px" }}>
//                         Price:{item?.Price ? item?.Price[0]?.price : ""}
//                         ,sold:{item?.purchaseCount},Product Id:{item?._id}
//                       </p>
//                     </li>
//                   ))
//                   // .toArray()
//                   // .toArray()
//                   /*
//                     Note this example is an ImmutableJS List so we must convert it to an array.
//                     I've left this up to you to covert to an array, as react-reorder updates a lot,
//                     and if I called this internally it could get rather slow,
//                     whereas you have greater control over your component updates.*/
//                 }
//               </Reorder>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }

//   constructor(props) {
//     super(props);
//     this.state = {
//       apiCatalogs: [],
//       editId: null,
//       // form
//       _id: "",
//       type: "",
//       catalog: "",
//       name: "",
//       description: "",
//       imageURL: "",
//       linkOneImageURL: "",
//       alternativeName: "",
//       alternativeCanonical: "",
//       detailScreen: "",
//       parentCategory: null,
//       mode: "add",
//       myList: [],
//     };
//   }

//   async onReorder(event, previousIndex, nextIndex, fromId, toId) {
//     let reOrderdList = reorder(this.state.myList, previousIndex, nextIndex);
//     reOrderdList.map((item, index) => {
//       item.precedence = index + 1;
//     });
//     await this.setState({
//       myList: reOrderdList,
//     });
//     const url = new URLSearchParams(this.props?.location?.search);
//     const category = url?.get("productCategoryId");
//     await this.props.updateList({list:this.state.myList,catId:category});
//   }

//   // onReorderGroup(event, previousIndex, nextIndex, fromId, toId) {
//   //   if (fromId === toId) {
//   //     const list = reorderImmutable(
//   //       this.state[fromId],
//   //       previousIndex,
//   //       nextIndex
//   //     );

//   //     this.setState({
//   //       [fromId]: list,
//   //     });
//   //   } else {
//   //     const lists = reorderFromToImmutable(
//   //       {
//   //         from: this.state[fromId],
//   //         to: this.state[toId],
//   //       },
//   //       previousIndex,
//   //       nextIndex
//   //     );

//   //     this.setState({
//   //       [fromId]: lists.from,
//   //       [toId]: lists.to,
//   //     });
//   //   }
//   // }

//   async componentDidMount() {
//     this.props.allCatalogs();
//     const url = new URLSearchParams(this.props?.location?.search);
//     const category = url?.get("productCategoryId");
//     let response = await this.props.getProducts(category);
//     this.setState({ myList: response });
//     if (category) {
//       this.setState({ editId: category });
//       var data = await this.props.categoryDetails(category);

//       if (data?.data) {
//         const {
//           _id,
//           type,
//           catalog,
//           name,
//           description,
//           imageURL,
//           linkOneImageURL,
//           alternativeName,
//           alternativeCanonical,
//           detailScreen,
//           parentCategory,
//         } = data?.data;

//         this.setState({
//           _id,
//           type,
//           catalog: catalog?._id,
//           name,
//           description,
//           imageURL,
//           linkOneImageURL,
//           alternativeName,
//           alternativeCanonical,
//           detailScreen,
//           parentCategory: parentCategory?._id,
//           mode: "edit",
//         });
//       }
//     }
//   }

//   handleChange = (event) => {
//     this.setState({
//       [event.target.name]: event.target.value,
//     });
//   };

//   submitForm = async (event) => {
//     const data = this.state;
//     const mode = this.state.mode;
//     if (!this.state.parentCategory) {
//       delete data.parentCategory;
//     }
//     delete data.apiCatalogs;
//     delete data.editId;
//     delete data.mode;
//     event.preventDefault();
//     if (mode === "edit") {
//       this.props.update(data);
//     } else {
//       delete data._id;
//       this.props.save(data);
//     }
//   };
// }

// const mapStateToProps = (state) => ({
//   token: state?.auth?.token,
//   submitted: state.catalog?.categories?.submitted,
//   catalogs: state.catalog?.catalogs?.active,
// });

// const mapDispatchToProps = (dispatch, router) => ({
//   save: (data) => dispatch(createCategories(data, router)),
//   categoryDetails: (id) => dispatch(getCategory(id, router)),
//   allCatalogs: () => dispatch(allActiveCatalogs()),
//   update: (data) => dispatch(updateCategory(data, router, true)),
//   getProducts: (data) => dispatch(getProductsByCategory(data, router)),
//   updateList: (data) => dispatch(updateProductPrecedence(data, router)),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(ProductSequencing);

import React, { useEffect, useRef, useState, createRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  createCategories,
  getCategory,
  allActiveCatalogs,
  updateCategory,
  getProductsByCategory,
  updateProductPrecedence,
} from "../../../../store/actions/catalogActions";
import SequenceModal from "./SequenceModal";
function ProductSequencing(props) {
  const dispatch = useDispatch();

  const [apiCatalogs, setApiCatalog] = useState([]);
  const [editId, setEditId] = useState(null);
  const [_id, setId] = useState("");
  const [type, setType] = useState("");
  const [catalog, setCatalog] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [linkOneImageURL, setLinkOneImageURL] = useState("");
  const [alternativeName, setAlternativeName] = useState("");
  const [alternativeCanonical, setAlternativeCanonical] = useState("");
  const [detailScreen, setDetailScreen] = useState("");
  const [parentCategory, setParentCategory] = useState(null);
  const [mode, setmode] = useState("add");
  const [myList, setmyList] = useState([]);
  const [presentIndex, setPresentIndex] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [dragging, setDragging] = useState(false);
  const dragItem = useRef();
  const dragItemNode = useRef();
  const [rowcol, setRowCol] = useState({ row: "", col: "", selectIndex: "" });
  const getStyles = (item) => {
    if (dragItem.current === item) {
      return "dnd-item current";
    }
    return "dnd-item";
  };
  let tid = 1;
  let cid = 0;
  useEffect(() => {
    // this.props.allCatalogs();
    dispatch(allActiveCatalogs());
    const url = new URLSearchParams(props?.location?.search);
    const category = url?.get("productCategoryId");
    dispatch(
      getProductsByCategory(category, (response) => {
        setmyList(response);
      })
    );
    if (category) {
      setEditId(category);
      dispatch(
        getCategory(category, (res) => {
          if (res) {
            const {
              _id,
              type,
              catalog,
              name,
              description,
              imageURL,
              linkOneImageURL,
              alternativeName,
              alternativeCanonical,
              detailScreen,
              parentCategory,
            } = res;
            setId(_id);
            setType(type);
            setCatalog(catalog?._id);
            setDescription(description);
            setImageURL(imageURL);
            setLinkOneImageURL(linkOneImageURL);
            setAlternativeCanonical(alternativeName);
            setAlternativeCanonical(alternativeCanonical);
            setDetailScreen(detailScreen);
            setParentCategory(parentCategory?._id);
            setmode("edit");
          }
        })
      );
    }
  }, [dispatch]);
  useEffect(() => {}, []);
  const handleClose = () => {
    setModalOpen(false);
  };
  const handleUpdateSequence = () => {
    console.log("called");
    console.log("MyLoist", myList);
    const url = new URLSearchParams(props?.location?.search);
    const category = url?.get("productCategoryId");
    dispatch(updateProductPrecedence({ list: myList, catId: category }));
  };
  const submit = (newIndex, row, col) => {
    let tempList = [...myList];
    let roCol = `row-${row}-${col}`;
    let filterRef = elRefs?.filter((e1) => e1.current.id === roCol);
    let selectidx = filterRef[0].current.attributes.ariavaluetext.value;
    let filteDataIdx = tempList.findIndex(
      (ite, idx) => idx === Number(selectidx)
    );
    var f = tempList.splice(newIndex, 1)[0];
    tempList.splice(filteDataIdx, 0, f);
    tempList.map((item, index) => {
      item.precedence = index + 1;
    });
    setmyList(tempList);
    setModalOpen(false);
  };

  // const handleChange = (event) => {
  //   this.setState({
  //     [event.target.name]: event.target.value,
  //   });
  // };

  // const submitForm = async (event) => {
  //   const data = this.state;
  //   const mode = this.state.mode;
  //   if (!this.state.parentCategory) {
  //     delete data.parentCategory;
  //   }
  //   delete data.apiCatalogs;
  //   delete data.editId;
  //   delete data.mode;
  //   event.preventDefault();
  //   if (mode === "edit") {
  //     this.props.update(data);
  //   } else {
  //     delete data._id;
  //     this.props.save(data);
  //   }
  // };

  const handletDragStart = (e, item) => {
    console.log("Starting to drag", item);

    dragItemNode.current = e.target;
    dragItemNode.current.addEventListener("dragend", handleDragEnd);
    dragItem.current = item;
    setTimeout(() => {
      setDragging(true);
    }, 100);
  };
  const handleDragEnter = (e, targetItem) => {
    console.log("Entering a drag target", targetItem);
    console.log("dragItem.current", dragItem.current);
    if (dragItemNode.current !== e.target) {
      console.log("Target is NOT the same as dragged item");

      setmyList((oldList) => {
        let newList = [...oldList];
        newList.splice(targetItem, 0, newList.splice(dragItem.current, 1)[0]);
        // dragItem.current = targetItem;
        newList.map((item, index) => {
          item.precedence = index + 1;
        });
        return newList;
      });
    }
  };
  const handleDragEnd = (e) => {
    setDragging(false);
    dragItem.current = null;
    dragItemNode.current.removeEventListener("dragend", handleDragEnd);
    dragItemNode.current = null;
  };
  const [elRefs, setElRefs] = React.useState([]);
  React.useEffect(() => {
    // add or remove refs
    setElRefs((elRefs) =>
      Array(myList?.length)
        .fill()
        .map((_, i) => elRefs[i] || createRef())
    );
  }, [myList]);
  return (
    <div id="content-main-section" className="leftonly">
      {modalOpen && (
        <SequenceModal
          modalOpen={modalOpen}
          presentIndex={presentIndex}
          handleClose={handleClose}
          onSubmit={submit}
          rowCol={rowcol}
          dataLength={myList?.length}
        />
      )}

      <div id="centerdiv" className="no-clear">
        <div className="screenlet">
          <div class="button-bar tab-bar">
            <ul>
              <li>
                <ul>
                  <li>
                    <a
                      href={
                        "/catalog/control/EditCategory?productCategoryId=" + _id
                      }
                    >
                      Category
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        "/catalog/control/ProductSequencing?productCategoryId=" +
                        _id
                      }
                    >
                      Product Sequencing
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        "/catalog/control/Contents?productCategoryId=" + _id
                      }
                    >
                      Contents
                    </a>
                  </li>
                  <li style={{ float: "right" }}>
                    <button type="button" onClick={handleUpdateSequence}>
                      Update
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="screenlet-title-bar">
            <ul>
              <li className="h3">Edit Category Products</li>
            </ul>
          </div>

          <div
            className="screenlet-body"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <ul
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {myList?.map((item, index) => {
                if (index === 0) {
                  tid = 1;
                  cid = 1;
                } else if (index % 4 === 0) {
                  tid++;
                  cid = 1;
                } else {
                  cid++;
                  // tid = tid;
                }
                return (
                  <li
                    ref={elRefs[index]}
                    draggable
                    onDragStart={(e) => handletDragStart(e, index)}
                    onDragEnter={
                      dragging
                        ? (e) => {
                            handleDragEnter(e, index);
                          }
                        : null
                    }
                    value={index}
                    ariaValueText={index}
                    id={`row-${tid + "-" + cid}`}
                    data-id={`row-${tid + "-" + cid}`}
                    className={dragging ? getStyles(index) : "dnd-item"}
                    key={item._id}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setModalOpen(true);
                      let temp = elRefs[index].current.id;
                      let filterTemp = temp?.split("-");
                      setRowCol({
                        row: Number(filterTemp[1]),
                        col: Number(filterTemp[2]),
                      });
                      setPresentIndex(index);
                    }}
                    style={
                      dragging
                        ? {
                            backgroundColor: "#282c34",
                            marginRight: "10px",
                            cursor: "move",
                            width: "22%",
                          }
                        : {
                            marginRight: "10px",
                            cursor: "move",
                            width: "22%",
                          }
                    }
                  >
                    <img
                      src={item?.images ? item.images[0] : ""}
                      alt=""
                      height="auto"
                      width="100%"
                    />
                    <p style={{ fontSize: 15 }}>{item?.title}</p>
                    <p style={{ fontSize: 12, marginTop: "15px" }}>
                      Price:
                      {item?.Price
                        ? item?.Price?.length > 1
                          ? item?.Price[2]?.price
                          : item?.Price[0]?.price
                        : ""}
                      ,sold:{item?.purchaseCount},Product Id:{item?._id}
                    </p>
                  </li>
                );
              })}
            </ul>
            {/* </Reorder> */}
          </div>
          {/* <button type="button" onClick={handleUpdateSequence}>
            Update
          </button> */}
        </div>
      </div>
    </div>
  );
}

export default ProductSequencing;
