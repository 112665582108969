import React, { Component } from "react";
import { pathParts } from "../../constants/utils";
import SideBars from "./AllSideBars";
import { withRouter } from "react-router-dom";
class SideBar extends Component {
  render() {
    const { location } = this.props;
    const parts = pathParts(location.pathname);
    return <div>{this.container(parts?.[1], this.props)}</div>;
  }
  container = (container, props) => {
    switch (container) {
      case "catalog":
        return <SideBars.Catalog {...props} />;
      case "ordermgr":
        return <SideBars.Orders {...props} />;
      default:
        return "";
    }
  };
}
export default withRouter(SideBar);
