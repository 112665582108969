const initialState = {
  searchedOrders: [],
  orderView: {},
  items: [],
  refundOrders: [],
  orderSource: [],
  subOrderComponentArray: [],
  otherOrders: [],
  orderHistory: [],
  customers: [],
};

export default function orderReducer(state = initialState, action) {
  switch (action.type) {
    case "ORDER_VIEW":
      return {
        ...state,
        orderView: action.payload,
      };
    case "GET_ORDERS":
      return { ...state, searchedOrders: action.payload };
    case "ITEMS_DATA":
      return { ...state, items: action.payload };
    case "REFUND_ORDERS":
      return { ...state, refundOrders: action.payload };
    case "ORDER_SOURCE":
      return { ...state, orderSource: action.payload };
    case "SAVE_SUB_ORDER":
      return { ...state, subOrderComponentArray: action.payload };
    case "OTHER_ORDERS":
      return { ...state, otherOrders: action.payload };
    case "SAVE_ORDER_HISTORY":
      return { ...state, orderHistory: action.payload };
    case "GET_CUSTOMER":
      return { ...state, customers: action.payload };
    default:
      return state;
  }
}
