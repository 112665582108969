import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import queryString from "query-string";
import EditCatalogNav from "./EditCatalogNav";
import AssignCatalog from "./AssignCatalog";
import DataTable from "../../../components/DataTable";
import { getCatalogCategories } from "../../../../store/actions/catalogActions";
export class EditProdCatalogCategories extends Component {
  render() {
    const { categories } = this.props;
    const query = queryString.parse(this.props.location.search);
    const catalog = query.prodCatalogId;
    return (
      <div id="content-main-section" className="leftonly">
        <div id="centerdiv" className="no-clear">
          {catalog && (
            <React.Fragment>
              <EditCatalogNav catalog={catalog} active="categories" />
            </React.Fragment>
          )}
          <div id="screenlet_1" className="screenlet">
            <div className="screenlet-title-bar">
              <ul>
                <li className="h3">Edit Product Catalog Categories</li>
              </ul>
            </div>
            <div id="screenlet_1_col" className="screenlet-body">
              <DataTable>
                <table className="basic-table" cellSpacing="0">
                  <tbody>
                    <tr className="header-row">
                      <td>
                        <label
                          id="EditProdCatalogCategories_productCategoryId_title"
                          htmlFor="EditProdCatalogCategories_productCategoryId"
                        >
                          Category ID
                        </label>
                      </td>
                      <td>
                        <label
                          id="EditProdCatalogCategories_prodCatalogCategoryTypeId_title"
                          htmlFor="EditProdCatalogCategories_prodCatalogCategoryTypeId"
                        >
                          Product Catalog Category Type
                        </label>
                      </td>
                      <td>
                        <label
                          id="EditProdCatalogCategories_fromDate_title"
                          htmlFor="EditProdCatalogCategories_fromDate"
                        >
                          From Date Time
                        </label>
                      </td>
                      <td>
                        <label
                          id="EditProdCatalogCategories_thruDate_title"
                          htmlFor="EditProdCatalogCategories_thruDate"
                        >
                          Through Date Time
                        </label>
                        -
                        <label
                          id="EditProdCatalogCategories_sequenceNum_title"
                          htmlFor="EditProdCatalogCategories_sequenceNum"
                        >
                          Sequence Num
                        </label>
                        -
                        <label
                          id="EditProdCatalogCategories_submitButton_title"
                          htmlFor="EditProdCatalogCategories_submitButton"
                        >
                          Update
                        </label>
                      </td>
                      <td> </td>
                      <td> </td>
                    </tr>
                    {categories &&
                      categories.map((category, key) => {
                        var categoryUrl =
                          category?.catalog?.name + "/" + category?.name;
                        return (
                          <tr key={key}>
                            <td>
                              {categoryUrl}{" "}
                              <Link
                                className="buttontext"
                                title="Category ID"
                                to={
                                  "/catalog/control/EditCategory?productCategoryId=" +
                                  categoryUrl
                                }
                              >
                                {categoryUrl}
                              </Link>
                            </td>
                            <td>{category?.type}</td>
                            <td>{category?.createdDate}</td>
                            <td>
                              <form
                                id="EditProdCatalogCategories_o_0"
                                className="basic-form"
                                action="/catalog/control/updateProductCategoryToProdCatalog"
                                method="post"
                                name="EditProdCatalogCategories_o_0"
                              >
                                <span className="view-calendar">
                                  <input
                                    id="EditProdCatalogCategories_thruDate_o_0_i18n"
                                    title="dd-MM-yyyy HH:mm:ss"
                                    maxLength="30"
                                    name="thruDate_i18n"
                                    size="25"
                                    type="text"
                                  />
                                  <input
                                    id="EditProdCatalogCategories_thruDate_o_0"
                                    className="hasDatepicker"
                                    style={{
                                      height: "1px",
                                      width: "1px",
                                      border: "none",
                                      backgroundColor: "transparent",
                                    }}
                                    title="dd-MM-yyyy HH:mm:ss"
                                    maxLength="30"
                                    name="thruDate"
                                    size="25"
                                    type="text"
                                  />
                                  <button
                                    className="ui-datepicker-trigger"
                                    type="button"
                                  ></button>
                                </span>
                                <input
                                  id="EditProdCatalogCategories_sequenceNum_o_0"
                                  name="sequenceNum"
                                  size="6"
                                  type="text"
                                />
                                <input
                                  className="smallSubmit"
                                  name="submitButton"
                                  type="submit"
                                  value="Update"
                                />
                              </form>
                            </td>
                            <td>
                              <a
                                href
                                className="buttontext"
                                onClick={(e) =>
                                  this.deleteCategory(e, category?._id)
                                }
                              >
                                Delete
                              </a>
                            </td>
                            <td>
                              <a
                                className="buttontext"
                                title=" "
                                href="/catalog/control/EditCategory?productCategoryId=bahrain%2Fcakes&amp;CATALOG_TOP_CATEGORY=bahrain%2Fcakes"
                              >
                                MakeTop
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </DataTable>
            </div>
          </div>
          <AssignCatalog catalog={catalog} />
        </div>
      </div>
    );
  }
  constructor(props) {
    super(props);
    this.state = {};
  }
  async componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    const catalog = query.prodCatalogId;
    this.props.search({ catalog });
  }
  handleChange = (e) => {
    var value = e.target.value;
    var key = e.target.name;
    this.setState({ [key]: value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
  };
  deleteCategory = (e) => {
    e.preventDefault();
  };
}

const mapStateToProps = (state) => ({
  categories: state?.catalog?.catalogs?.catalog_categories,
});

const mapDispatchToProps = (dispatch, router) => {
  return {
    search: (data) => dispatch(getCatalogCategories(data, router)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditProdCatalogCategories)
);
