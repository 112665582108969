import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {
    createProductPromo,
    updatePromo,
    getPromoByID
} from "../../../../store/actions/catalogActions";


class CreateProductPromo extends Component {
    render() {
        let {
            name,
            promoText,
            userEntered,
            showToCustomer,
            reqCode,
            useLimitPerOrder,
            useLimitPerCustomer,
            useLimitPerPromo,
            bilbackFactor,
            overrideOrgPartyId
        } = this.state;
        const url = new URLSearchParams(this.props?.location?.search);
        const promoId = url?.get("promoId");

        return (
            <div id="content-main-section" className="leftonly">
                <div id="centerdiv" className="no-clear">
                    <div id="screenlet_1" className="screenlet">
                        <div className="screenlet-title-bar">
                            <ul>
                                <li className="h3">Edit Product Promos</li>
                            </ul>
                        </div>
                        <div id="screenlet_1_col" className="screenlet-body">
                            <form id="EditProductPromo" className="basic-form"
                                  onSubmit={this.submitForm}
                                  noValidate="novalidate">
                                <table className="basic-table" cellSpacing="0">
                                    <tbody>
                                    <tr>
                                        <td className="label">
                                            <label id="EditProductPromo_promoName_title"
                                                   htmlFor="EditProductPromo_promoName">
                                                Promo Name
                                            </label>
                                        </td>
                                        <td>
                                            <input value={name} onChange={this.handleChange} maxLength="60" name="name"
                                                   size="40" type="text"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label">
                                            <label id="EditProductPromo_promoText_title"
                                                   htmlFor="EditProductPromo_promoText">
                                                Promo Text
                                            </label>
                                        </td>
                                        <td>
                                            <textarea name="promoText"
                                                      value={promoText} onChange={this.handleChange}
                                                      cols="70" name="promoText"
                                                      rows="5">

                                            </textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label">
                                            <label id="EditProductPromo_userEntered_title"
                                                   htmlFor="EditProductPromo_userEntered">
                                                User Entered
                                            </label>
                                        </td>
                                        <td>
                                            <span className="ui-widget">
                                                <select id="EditProductPromo_userEntered" name="userEntered" size="1"
                                                        name="userEntered" value={userEntered}
                                                        onChange={this.handleChange}>
                                                    <option selected="selected" value={true}>Y</option>
                                                    <option value={false}>N</option>
                                                </select>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label">
                                            <label id="EditProductPromo_showToCustomer_title"
                                                   htmlFor="EditProductPromo_showToCustomer">
                                                Promo Show To Customer
                                            </label>
                                        </td>
                                        <td>
                                            <span className="ui-widget">
                                                <select id="EditProductPromo_showToCustomer" name="showToCustomer"
                                                        value={showToCustomer} onChange={this.handleChange} size="1">
                                                    <option value={true}>Y</option>
                                                    <option value={false}>N</option>
                                                </select>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label">
                                            <label id="EditProductPromo_requireCode_title"
                                                   htmlFor="EditProductPromo_requireCode">
                                                Req. Code
                                            </label>
                                        </td>
                                        <td>
                                            <span className="ui-widget">
                                                <select id="EditProductPromo_requireCode"
                                                        name="reqCode"
                                                        value={reqCode} onChange={this.handleChange}
                                                        size="1">
                                                    <option value={false}>N</option>
                                                    <option value={true}>Y</option>
                                                </select>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label">
                                            <label id="EditProductPromo_useLimitPerOrder_title" htmlFor="EditProductPromo_useLimitPerOrder">
                                                Use Limit Per Order
                                            </label>
                                        </td>
                                        <td>
                                            <input id="EditProductPromo_useLimitPerOrder"
                                                   name="useLimitPerOrder"
                                                   value={useLimitPerOrder} onChange={this.handleChange}
                                                   size="6" type="text"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label">
                                            <label id="EditProductPromo_useLimitPerCustomer_title" htmlFor="EditProductPromo_useLimitPerCustomer">Use Limit Per Customer
                                            </label>
                                        </td>
                                        <td>
                                            <input id="EditProductPromo_useLimitPerCustomer"
                                                   name="useLimitPerCustomer"
                                                   value={useLimitPerCustomer} onChange={this.handleChange}
                                                   size="6" type="text"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label">
                                            <label id="EditProductPromo_useLimitPerPromotion_title" htmlFor="EditProductPromo_useLimitPerPromotion">
                                                Use Limit Per Promotion
                                            </label>
                                        </td>
                                        <td>
                                            <input id="EditProductPromo_useLimitPerPromotion"
                                                   name="useLimitPerPromo"
                                                   value={useLimitPerPromo} onChange={this.handleChange}
                                                   size="6" type="text"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label">
                                            <label id="EditProductPromo_billbackFactor_title" htmlFor="EditProductPromo_billbackFactor">
                                                Billback Factor
                                            </label>
                                        </td>
                                        <td>
                                            <input id="EditProductPromo_billbackFactor"
                                                   name="bilbackFactor"
                                                   value={bilbackFactor} onChange={this.handleChange}
                                                   size="25"
                                                   type="text"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label">
                                            <label id="EditProductPromo_overrideOrgPartyId_title" htmlFor="EditProductPromo_overrideOrgPartyId">
                                                Override Org Party Id
                                            </label>
                                        </td>
                                        <td>
                                            <div id="3_lookupId_EditProductPromo_overrideOrgPartyId_auto"></div>
                                            <span className="field-lookup">
                                                <input
                                                id="3_lookupId_EditProductPromo_overrideOrgPartyId"
                                                className="ui-autocomplete-input" autoComplete="off"
                                                name="overrideOrgPartyId"
                                                value={overrideOrgPartyId} onChange={this.handleChange}
                                                size="25" type="text"/>
                                                <a></a>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label"></td>
                                        <td>
                                            <input className="smallSubmit" type="submit" value={promoId ? 'Update' : 'Create'}/></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }
    submitForm = (e) => {
        e.preventDefault();
        const url = new URLSearchParams(this.props?.location?.search);
        const promoId = url?.get("promoId");
        if (promoId) {
            let data = this.state;
            data._id = promoId
            this.props.updatePromo(data)
        } else {
            console.log('this is state before promo create',this.state);
            this.props.createProductPromo(this.state);
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            promoText: '',
            userEntered: false,
            showToCustomer: false,
            reqCode: false,
            useLimitPerOrder: '',
            useLimitPerCustomer: '',
            useLimitPerPromo: '',
            bilbackFactor: '',
            overrideOrgPartyId: '',
        }
    }

    async componentDidMount() {
        const url = new URLSearchParams(this.props?.location?.search);
        const promoId = url?.get("promoId");
        if (promoId) {
            const data = await this.props.getPromoById(promoId);
            if (data.data) {
                this.setState({
                    name: data.data.name,
                    promoText: data.data.promoText,
                    userEntered: data.data.userEntered,
                    showToCustomer: data.data.showToCustomer,
                    reqCode: data.data.reqCode,
                    useLimitPerOrder: data.data.useLimitPerOrder,
                    useLimitPerCustomer: data.data.useLimitPerCustomer,
                    useLimitPerPromo: data.data.useLimitPerPromo,
                    bilbackFactor: data.data.bilbackFactor,
                    overrideOrgPartyId: data.data.overrideOrgPartyId,
                });
            }
        }
    }
}

const mapStateToProps = (state) => (
    () => {
        return {
            submitted: state?.catalog?.promos?.submitted,
            promos: state?.catalog?.promos,
        }
    });

const mapDispatchToProps = (dispatch, router) => ({
    createProductPromo: (data) => dispatch(createProductPromo(data, router)),
    // updatePromo: (data)=>dispatch(updatePromo(data, router)),
    // getPromoById: (id)=>dispatch(getPromoByID(id))
});
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CreateProductPromo)
);
