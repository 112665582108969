import React, { Component } from "react";
import { connect } from "react-redux";
import {
  createProduct,
  allActiveCategories,
  getProduct,
  updateProduct,
  allActiveCarrierShipmentMethodsTypes,
} from "../../../../store/actions/catalogActions";
import { getRequest, putRequest } from "../../../../store/services";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import EditProductNav from "./EditProductNav";
import DuplicateProduct from "./DuplicateProduct";
import { Notify } from "../../../../constants/utils";
export class EditProduct extends Component {
  render() {
    const {
      _G49_,
      _G51_,
      _G53_,
      _G55_,
      _G57_,
      _G59_,
      _G61_,
      _G63_,
      _G65_,
      _G67_,
      _G69_,
      title,
      brandName,
      OEMPartyId,
      comments,
      introductionDate,
      releaseDate,
      internalName,
      supportThroughDate,
      salesDiscontinuationThroughDate,
      amount,
      isVirtual,
      isVariant,
      returnable,
      includeInPromotions,
      taxable,
      autoCreateKeywords,
      description,
      relevance,
      CategoriesArray,
      bestSellingType,
    } = this.state;
    const { mode } = this.props;
    const query = queryString.parse(this.props.location.search);
    const product = query.productId;
    return (
      <div id="content-main-section" className="leftonly">
        <div id="centerdiv" className="no-clear">
          <EditProductNav
            product={product}
            expandAbles={
              <React.Fragment>
                <li className="buttontext expand-all">
                  <a
                    href
                    onClick={(e) => this.toggleA(e, "expand")}
                    className="buttontext expand-all"
                  >
                    Expand all
                  </a>
                </li>
                <li className="buttontext collapse-all">
                  <a
                    href
                    onClick={(e) => this.toggleA(e, "collapse")}
                    className="buttontext collapse-all"
                  >
                    Collapse all
                  </a>
                </li>
              </React.Fragment>
            }
            mode={mode}
          />
          <div id="screenlet_1" className="screenlet">
            <div className="screenlet-title-bar">
              <ul>
                <li className="h3">Edit Product</li>
              </ul>
            </div>
            <div id="screenlet_1_col" className="screenlet-body">
              <form
                id="EditProduct"
                className="basic-form"
                onSubmit={(e) => this.handleSubmit(e)}
              >
                <div id="_G47_">
                  <div className="fieldgroup-title-bar"> </div>
                  <div id="_G47__body" className="fieldgroup-body">
                    <table className="basic-table" cellspacing="0">
                      <tbody>
                        <tr>
                          {/*<td className="label">*/}
                          {/*  <label*/}
                          {/*    id="EditProduct_productId_title"*/}
                          {/*    for="EditProduct_productId"*/}
                          {/*  >*/}
                          {/*    Product ID*/}
                          {/*  </label>*/}
                          {/*</td>*/}
                          {/*<td>*/}
                          {/*  <input*/}
                          {/*    id="EditProduct_productId"*/}
                          {/*    maxlength="20"*/}
                          {/*    name="productId"*/}
                          {/*    value={productId}*/}
                          {/*    onChange={(e) => this.handleChange(e)}*/}
                          {/*    size="20"*/}
                          {/*    type="text"*/}
                          {/*  />*/}
                          {/*</td>*/}
                          <td className="label">
                            <label
                              id="EditProduct_productTypeId_title"
                              for="EditProduct_productTypeId"
                            >
                              Product Type
                            </label>
                          </td>
                          <td colspan="7">
                            <span className="ui-widget">
                              <select
                                id="EditProduct_productTypeId"
                                name="productTypeId"
                                size="1"
                              >
                                <option defaultValue value="AGGREGATED">
                                  Configurable Good
                                </option>
                                <option value="AGGREGATED_CONF">
                                  Configurable Good Configuration
                                </option>
                                <option value="AGGREGATEDSERV_CONF">
                                  Configurable Service Configuration
                                </option>
                                <option value="AGGREGATED_SERVICE">
                                  Configurable Service using inventory
                                </option>
                                <option value="DIGITAL_GOOD">
                                  Digital Good
                                </option>
                                <option value="FINISHED_GOOD">
                                  Finished Good
                                </option>
                                <option value="FINDIG_GOOD">
                                  Finished/Digital Good
                                </option>
                                <option value="ASSET_USAGE">
                                  (Fixed) Asset Usage
                                </option>
                                <option value="ASSET_USAGE_OUT_IN">
                                  Asset Rental
                                </option>
                                <option value="GOOD">Good</option>
                                <option value="MARKETING_PKG">
                                  Marketing Package
                                </option>
                                <option value="MARKETING_PKG_AUTO">
                                  Marketing Package: Auto Manufactured
                                </option>
                                <option value="MARKETING_PKG_PICK">
                                  Marketing Package: Pick Assembly
                                </option>
                                <option value="EBAY_ITEM">
                                  Product From eBay
                                </option>
                                <option value="SCRUM_ITEM">
                                  Product From Scrum
                                </option>
                                <option value="RAW_MATERIAL">
                                  Raw Material
                                </option>
                                <option value="SERVICE">Service</option>
                                <option value="SERVICE_PRODUCT">
                                  Service a product using inventory
                                </option>
                                <option value="SUBASSEMBLY">Subassembly</option>
                                <option value="WIP">Work In Process</option>
                              </select>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="_G49_" className={"fieldgroup " + _G49_}>
                  <div className="fieldgroup-title-bar">
                    <ul>
                      <li className={_G49_}>
                        <a onClick={(e) => this.toggleA(e, "_G49_")} href>
                          {" "}
                          Wording And Comment
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div id="_G49__body" className="fieldgroup-body">
                    <table className="basic-table" cellspacing="0">
                      <tbody>
                        <tr>
                          <td className="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              Internal Name
                            </label>
                          </td>
                          <td>
                            <input
                              id="EditProduct_internalName"
                              className="required"
                              maxlength="255"
                              name="internalName"
                              value={internalName}
                              onChange={(e) => this.handleChange(e)}
                              // onChange={(e) =>
                              //   console.log(e.target.name, e.target.value)
                              // }
                              size="30"
                              type="text"
                              required
                            />{" "}
                            *
                          </td>
                          <td className="label">
                            <label
                              id="EditProduct_brandName_title"
                              for="EditProduct_brandName"
                            >
                              Brand Name
                            </label>
                          </td>
                          <td colspan="7">
                            <input
                              id="EditProduct_brandName"
                              maxlength="60"
                              name="brandName"
                              value={brandName}
                              onChange={(e) => this.handleChange(e)}
                              size="30"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="label">
                            <label
                              id="EditProduct_internalName_title"
                              htmlFor="EditProduct_internalName"
                            >
                              Title
                            </label>
                          </td>
                          <td>
                            <input
                              id="EditProduct_internalName"
                              className="required"
                              maxlength="255"
                              name="title"
                              value={title}
                              onChange={(e) => this.handleChange(e)}
                              // onChange={(e) =>
                              //   console.log(e.target.name, e.target.value)
                              // }
                              size="30"
                              type="text"
                              required
                            />{" "}
                            *
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <div>
                              <td className="label">
                                <label
                                  id="EditProduct_Message"
                                  htmlFor="EditProduct_internalName"
                                >
                                  Message
                                </label>
                                <input
                                  id="EditProduct_internalName"
                                  name="title"
                                  //value={title}
                                  checked={this.state.messageChecked}
                                  onClick={(e) =>
                                    this.setState((prevState) => ({
                                      messageChecked: !prevState.messageChecked,
                                    }))
                                  }
                                  // onChange={(e) =>
                                  //   console.log(e.target.name, e.target.value)
                                  // }
                                  size="30"
                                  type="checkbox"
                                />
                              </td>
                              <td className="label">
                                <label
                                  id="EditProduct_internalName_title"
                                  htmlFor="EditProduct_internalName"
                                >
                                  Photo
                                </label>

                                <input
                                  id="EditProduct_photo"
                                  name="title"
                                  //value={title}
                                  checked={this.state.photoChecked}
                                  onClick={(e) =>
                                    this.setState((prevState) => ({
                                      photoChecked: !prevState.photoChecked,
                                    }))
                                  }
                                  // onChange={(e) =>
                                  //   console.log(e.target.name, e.target.value)
                                  // }
                                  size="30"
                                  type="checkbox"
                                />
                              </td>
                              <td className="label">
                                <label
                                  id="EditProduct_internalName_title"
                                  htmlFor="EditProduct_internalName"
                                >
                                  Best Selling Product
                                </label>

                                <input
                                  id="EditProduct_photo"
                                  name="title"
                                  //value={title}
                                  checked={this.state.bestSelling}
                                  onClick={(e) =>
                                    this.setState((prevState) => ({
                                      bestSelling: !prevState.bestSelling,
                                    }))
                                  }
                                  // onChange={(e) =>
                                  //   console.log(e.target.name, e.target.value)
                                  // }
                                  size="30"
                                  type="checkbox"
                                />
                              </td>
                              {this.state.bestSelling && (
                                <td className="label">
                                  <label
                                    id="EditProduct_bestSellingType"
                                    htmlFor="EditProduct_bestSellingType"
                                  >
                                    Best Selling Product Type&nbsp;
                                  </label>
                                  <span className="ui-widget">
                                    <select
                                      id="EditProduct_bestSellingType"
                                      name="bestSellingType"
                                      size="1"
                                      value={bestSellingType}
                                      onChange={(e) => this.handleChange(e)}
                                    >
                                      <option defaultValue value="gifts">
                                        Gifts
                                      </option>
                                      <option value="birthday">Birthday</option>
                                      <option value="combos">Combos</option>
                                      <option value="cakes">Cakes</option>
                                      <option value="flowers">Flowers</option>
                                    </select>
                                  </span>
                                </td>
                              )}
                              <td className="label">Product Description</td>
                              <td>
                                <input
                                  id="EditProduct_internalName"
                                  maxlength="255"
                                  name="description"
                                  value={description}
                                  onChange={(e) => this.handleChange(e)}
                                  // onChange={(e) =>
                                  //   console.log(e.target.name, e.target.value)
                                  // }
                                  size="30"
                                  type="text"
                                />
                              </td>
                              <td className="label">Relevance</td>
                              <td>
                                <input
                                  id="EditProduct_internalName"
                                  maxlength="255"
                                  name="relevance"
                                  value={relevance}
                                  onChange={(e) => this.handleChange(e)}
                                  // onChange={(e) =>
                                  //   console.log(e.target.name, e.target.value)
                                  // }
                                  size="30"
                                  type="text"
                                />
                              </td>
                              {/* <td className="label">
                                <label
                                  id="EditProduct_internalName_title"
                                  htmlFor="EditProduct_internalName"
                                >
                                  Varients
                                </label>

                                <input
                                  id="EditProduct_internalName"
                                  className="required"
                                  name="title"
                                  //value={title}
                                  checked={this.state.varientsChecked}
                                  onClick={(e) =>
                                    this.setState((prevState) => ({
                                      varientsChecked: !prevState.varientsChecked,
                                    }))
                                  }
                                  // onChange={(e) =>
                                  //   console.log(e.target.name, e.target.value)
                                  // }
                                  size="30"
                                  type="checkbox"
                                  required
                                />
                              </td> */}
                            </div>
                          </td>

                          {/* <td>
                            <input
                              id="EditProduct_internalName"
                              className="required"
                              maxlength="255"
                              name="title"
                              value={this.state.varientValue}
                              // onChange={(e) => this.handleChange(e)}
                              onChange={(e) =>
                                this.setState({ varientValue: e.target.value })
                              }
                              disabled={!this.state.varientsChecked}
                              size="30"
                              type="text"
                              required
                            />
                          </td>
                          <td>
                            <button
                              type="button"
                              disabled={!this.state.varientsChecked}
                            >
                              add
                            </button>
                          </td> */}
                        </tr>
                        <tr>
                          <td className="label">Sub Category</td>
                          <td>
                            <input
                              type={"text"}
                              value={this.state.subCategory}
                              onChange={(e) =>
                                this.setState({ subCategory: e.target.value })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <div>
                              <label>Attach to Cateogry</label>
                              <input
                                type={"text"}
                                value={this.state.categoryToAdd}
                                onChange={(e) =>
                                  this.setState({
                                    categoryToAdd: e.target.value,
                                  })
                                }
                              />
                              <button
                                type={"button"}
                                onClick={() => this.addCategory()}
                              >
                                add
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            {this.props.shipmentMethodTypes?.map((type) => {
                              return (
                                <div>
                                  <td className="label">{type.name}</td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={
                                        this.state.shippmentMethods.indexOf(
                                          type._id
                                        ) !== -1
                                      }
                                      onClick={() => {
                                        console.log(
                                          this.state.shippmentMethods
                                        );

                                        let shippmentMethods = [];
                                        let i =
                                          this.state.shippmentMethods.indexOf(
                                            type._id
                                          );
                                        if (i > -1) {
                                          shippmentMethods =
                                            this.state.shippmentMethods.filter(
                                              (a) => a !== type._id
                                            );
                                        } else {
                                          shippmentMethods =
                                            this.state.shippmentMethods;
                                          shippmentMethods.push(type._id);
                                        }
                                        this.setState({ shippmentMethods });
                                      }}
                                    />
                                  </td>
                                </div>
                              );
                            })}
                          </td>
                        </tr>
                        {/* <tr>
                          <td></td>
                          <td>
                            <td className="label">One Hour delivery</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={this.state.oneHourDelivery}
                                onClick={() =>
                                  this.setState({
                                    oneHourDelivery: !this.state
                                      .oneHourDelivery,
                                  })
                                }
                              />
                            </td>
                            <td className="label">Two Hour delivery</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={this.state.twoHoursDelivery}
                                onClick={() =>
                                  this.setState({
                                    twoHoursDelivery: !this.state
                                      .twoHoursDelivery,
                                  })
                                }
                              />
                            </td>
                          </td>
                        </tr> */}
                        <tr>
                          <td className="label">
                            <label
                              id="EditProduct_manufacturerPartyId_title"
                              for="EditProduct_manufacturerPartyId"
                            >
                              OEM Party ID
                            </label>
                          </td>
                          <td colspan="10">
                            <input
                              id="EditProduct_manufacturerPartyId"
                              maxlength="20"
                              name="OEMPartyId"
                              value={OEMPartyId}
                              onChange={(e) => this.handleChange(e)}
                              size="20"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="label">
                            <label
                              id="EditProduct_comments_title"
                              for="EditProduct_comments"
                            >
                              Comments
                            </label>
                          </td>
                          <td colspan="10">
                            <input
                              id="EditProduct_comments"
                              maxlength="250"
                              name="comments"
                              value={comments}
                              onChange={(e) => this.handleChange(e)}
                              size="60"
                              type="text"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="_G51_" className={"fieldgroup " + _G51_}>
                  <div className="fieldgroup-title-bar">
                    <ul>
                      <li className={_G51_}>
                        <a onClick={(e) => this.toggleA(e, "_G51_")} href>
                          {" "}
                          Virtual Product
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div id="_G51__body" className="fieldgroup-body">
                    <table className="basic-table" cellspacing="0">
                      <tbody>
                        <tr>
                          <td className="label">
                            <label
                              id="EditProduct_isVirtual_title"
                              for="EditProduct_isVirtual"
                            >
                              Is VIRTUAL Product ?
                            </label>
                          </td>
                          <td>
                            <span className="ui-widget">
                              <select
                                id="EditProduct_isVirtual"
                                name="isVirtual"
                                onChange={(e) => this.handleChange(e)}
                                value={isVirtual}
                                size="1"
                              >
                                <option value="false">N</option>
                                <option value="true">Y</option>
                              </select>
                            </span>
                          </td>
                          <td className="label">
                            <label
                              id="EditProduct_isVariant_title"
                              for="EditProduct_isVariant"
                            >
                              Is VARIANT Product ?
                            </label>
                          </td>
                          <td>
                            <span className="ui-widget">
                              <select
                                id="EditProduct_isVariant"
                                name="isVariant"
                                onChange={(e) => this.handleChange(e)}
                                value={isVariant}
                                size="1"
                              >
                                <option value="false">N</option>
                                <option value="true">Y</option>
                              </select>
                            </span>
                          </td>
                          <td className="label">
                            <label
                              id="EditProduct_virtualVariantMethodEnum_title"
                              for="EditProduct_virtualVariantMethodEnum"
                            >
                              Virtual Variant Method
                            </label>
                          </td>
                          <td colspan="4">
                            <span className="ui-widget">
                              <select
                                id="EditProduct_virtualVariantMethodEnum"
                                name="virtualVariantMethodEnum"
                                size="1"
                              >
                                <option value="false"> </option>
                                <option value="VV_FEATURETREE">
                                  Feature tree Generation
                                </option>
                                <option value="VV_VARIANTTREE">
                                  Variant Tree generation
                                </option>
                              </select>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="_G53_" className={"fieldgroup " + _G53_}>
                  <div className="fieldgroup-title-bar">
                    <ul>
                      <li className={_G53_}>
                        <a onClick={(e) => this.toggleA(e, "_G53_")} href>
                          {" "}
                          Primary Categories
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div id="_G53__body" className="fieldgroup-body">
                    <table className="basic-table" cellspacing="0">
                      <tbody>
                        <tr>
                          <td className="label">
                            <label
                              id="EditProduct_primaryProductCategoryId_title"
                              for="EditProduct_primaryProductCategoryId"
                            >
                              Primary Categories
                            </label>
                          </td>
                          <td colspan="10">
                            {/*<span className="ui-widget">*/}
                            {/*  <select*/}
                            {/*    id="EditProduct_primaryProductCategoryId"*/}
                            {/*    className="required"*/}
                            {/*    name="category"*/}
                            {/*    onChange={(e) => this.handleChange(e)}*/}
                            {/*    value={this.state.category}*/}
                            {/*    size="1"*/}
                            {/*    required*/}
                            {/*  >*/}
                            {/*    <option defaultValue value=""></option>*/}
                            {/*    {categories &&*/}
                            {/*      categories.map((category, key) => {*/}
                            {/*        return (*/}
                            {/*          <option key={key} value={category?._id}>*/}
                            {/*            {category?.name}*/}
                            {/*          </option>*/}
                            {/*        );*/}
                            {/*      })}*/}
                            {/*  </select>*/}
                            {/*</span>*/}
                            <ul>
                              {CategoriesArray.map((cat) => (
                                <li>
                                  <span>{cat._id}</span>
                                  <span style={{ marginLeft: "20px" }}>
                                    {cat.name}
                                  </span>
                                  <button
                                    type={"button"}
                                    onClick={() => this.deleteCat(cat._id)}
                                    style={{ marginLeft: "40px" }}
                                  >
                                    delete
                                  </button>
                                </li>
                              ))}
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="_G55_" className={"fieldgroup " + _G55_}>
                  <div className="fieldgroup-title-bar">
                    <ul>
                      <li className={_G55_}>
                        <a onClick={(e) => this.toggleA(e, "_G55_")} href>
                          {" "}
                          Dates
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div id="_G55__body" className="fieldgroup-body">
                    <table className="basic-table" cellspacing="0">
                      <tbody>
                        <tr>
                          <td className="label">
                            <label
                              id="EditProduct_introductionDate_title"
                              for="EditProduct_introductionDate"
                            >
                              Introduction Date
                            </label>
                          </td>
                          <td>
                            <span className="view-calendar">
                              {" "}
                              <input
                                id="EditProduct_introductionDate_i18n"
                                title="dd-MM-yyyy HH:mm:ss"
                                maxlength="30"
                                name="introductionDate"
                                value={introductionDate}
                                onChange={(e) => this.handleChange(e)}
                                size="25"
                                type="date"
                              />{" "}
                              <button
                                className="ui-datepicker-trigger"
                                type="button"
                              ></button>{" "}
                            </span>
                          </td>
                          <td className="label">
                            <label
                              id="EditProduct_releaseDate_title"
                              for="EditProduct_releaseDate"
                            >
                              Release Date
                            </label>
                          </td>
                          <td>
                            <span className="view-calendar">
                              {" "}
                              <input
                                id="EditProduct_releaseDate_i18n"
                                title="dd-MM-yyyy HH:mm:ss"
                                maxlength="30"
                                name="releaseDate"
                                value={releaseDate}
                                onChange={(e) => this.handleChange(e)}
                                size="25"
                                type="date"
                              />{" "}
                              <button
                                className="ui-datepicker-trigger"
                                type="button"
                              ></button>{" "}
                            </span>
                          </td>
                          <td className="label">
                            <label
                              id="EditProduct_salesDiscontinuationDate_title"
                              for="EditProduct_salesDiscontinuationDate"
                            >
                              Sales Discontinuation Through Date
                            </label>
                          </td>
                          <td>
                            <span className="view-calendar">
                              {" "}
                              <input
                                id="EditProduct_salesDiscontinuationDate_i18n"
                                title="dd-MM-yyyy HH:mm:ss"
                                maxlength="30"
                                name="salesDiscontinuationThroughDate"
                                value={salesDiscontinuationThroughDate}
                                onChange={(e) => this.handleChange(e)}
                                size="25"
                                type="date"
                              />{" "}
                              <button
                                className="ui-datepicker-trigger"
                                type="button"
                              ></button>{" "}
                            </span>
                          </td>
                          <td className="label">
                            <label
                              id="EditProduct_supportDiscontinuationDate_title"
                              for="EditProduct_supportDiscontinuationDate"
                            >
                              Support Through Date
                            </label>
                          </td>
                          <td>
                            <span className="view-calendar">
                              {" "}
                              <input
                                id="EditProduct_supportDiscontinuationDate_i18n"
                                title="dd-MM-yyyy HH:mm:ss"
                                maxlength="30"
                                name="supportThroughDate"
                                value={supportThroughDate}
                                onChange={(e) => this.handleChange(e)}
                                size="25"
                                type="text"
                              />{" "}
                              <button
                                className="ui-datepicker-trigger"
                                type="button"
                              ></button>{" "}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="_G57_" className={"fieldgroup " + _G57_}>
                  <div className="fieldgroup-title-bar">
                    <ul>
                      <li className={_G57_}>
                        <a onClick={(e) => this.toggleA(e, "_G57_")} href>
                          {" "}
                          Inventory
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div id="_G57__body" className="fieldgroup-body">
                    <table className="basic-table" cellspacing="0">
                      <tbody>
                        <tr>
                          <td className="label">
                            <label
                              id="EditProduct_salesDiscWhenNotAvail_title"
                              for="EditProduct_salesDiscWhenNotAvail"
                            >
                              Disc. When Inv. Not Avail?
                            </label>
                          </td>
                          <td colspan="10">
                            <span className="ui-widget">
                              <select
                                id="EditProduct_salesDiscWhenNotAvail"
                                name="salesDiscWhenNotAvail"
                                size="1"
                              >
                                <option value="false"> </option>
                                <option value="true">Y</option>
                                <option value="false">N</option>
                              </select>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td className="label">
                            <label
                              id="EditProduct_requirementMethodEnumId_title"
                              for="EditProduct_requirementMethodEnumId"
                            >
                              Requirement Method Enum Id
                            </label>
                          </td>
                          <td colspan="10">
                            <span className="ui-widget">
                              <select
                                id="EditProduct_requirementMethodEnumId"
                                name="requirementMethodEnumId"
                                size="1"
                              >
                                <option value="false"> </option>
                                <option value="PRODRQM_NONE">
                                  No Requirement Created
                                </option>
                                <option value="PRODRQM_AUTO">
                                  Automatic For Every Sales Order
                                </option>
                                <option value="PRODRQM_STOCK">
                                  When QOH Reaches Minimum Stock for
                                  Product-Facility
                                </option>
                                <option value="PRODRQM_STOCK_ATP">
                                  When ATP Reaches Minimum Stock for
                                  Product-Facility
                                </option>
                                <option value="PRODRQM_ATP">
                                  Requirement for order when ATP Reaches Minimum
                                  Stock for Product-Facility
                                </option>
                                <option value="PRODRQM_DS">
                                  Drop-ship only
                                </option>
                                <option value="PRODRQM_DSATP">
                                  Auto drop-ship on low quantity
                                </option>
                              </select>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td className="label">
                            <label
                              id="EditProduct_requireInventory_title"
                              for="EditProduct_requireInventory"
                            >
                              Require Inventory
                            </label>
                          </td>
                          <td colspan="10">
                            <span className="ui-widget">
                              <select
                                id="EditProduct_requireInventory"
                                name="requireInventory"
                                size="1"
                              >
                                <option value="false"> </option>
                                <option value="true">Y</option>
                                <option value="false">N</option>
                              </select>
                            </span>
                            <span className="tooltip">
                              Should inventory be required to purchase this
                              product? If not specified defaults to Store
                              setting.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td className="label">
                            <label
                              id="EditProduct_inventoryMessage_title"
                              for="EditProduct_inventoryMessage"
                            >
                              Inventory Message
                            </label>
                          </td>
                          <td colspan="10">
                            <input
                              id="EditProduct_inventoryMessage"
                              maxlength="255"
                              name="inventoryMessage"
                              size="20"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="label">
                            <label
                              id="EditProduct_lotIdFilledIn_title"
                              for="EditProduct_lotIdFilledIn"
                            >
                              Lot Id
                            </label>
                          </td>
                          <td colspan="10">
                            <span className="ui-widget">
                              <select
                                id="EditProduct_lotIdFilledIn"
                                name="lotIdFilledIn"
                                size="1"
                              >
                                <option value="Allowed">Allowed</option>
                                <option value="Mandatory">Mandatory</option>
                                <option value="Forbidden">Forbidden</option>
                              </select>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="_G59_" className={"fieldgroup " + _G59_}>
                  <div className="fieldgroup-title-bar">
                    <ul>
                      <li className={_G59_}>
                        <a onClick={(e) => this.toggleA(e, "_G59_")} href>
                          {" "}
                          Rate
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div id="_G59__body" className="fieldgroup-body">
                    <table className="basic-table" cellspacing="0">
                      <tbody>
                        <tr>
                          <td className="label">
                            <label
                              id="EditProduct_ratingTypeEnum_title"
                              for="EditProduct_ratingTypeEnum"
                            >
                              Rating Type Enum
                            </label>
                          </td>
                          <td>
                            <span className="ui-widget">
                              <select
                                id="EditProduct_ratingTypeEnum"
                                name="ratingTypeEnum"
                                size="1"
                              >
                                <option value="false"> </option>
                                <option value="PRDR_MIN">Min Rating</option>
                                <option value="PRDR_MAX">Max Rating</option>
                                <option value="PRDR_FLAT">
                                  Rating Override
                                </option>
                              </select>
                            </span>
                          </td>
                          <td className="label">
                            <label
                              id="EditProduct_productRating_title"
                              for="EditProduct_productRating"
                            >
                              Rating
                            </label>
                          </td>
                          <td colspan="7">
                            <input
                              id="EditProduct_productRating"
                              maxlength="20"
                              name="productRating"
                              size="10"
                              type="text"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="_G61_" className={"fieldgroup " + _G61_}>
                  <div className="fieldgroup-title-bar">
                    <ul>
                      <li className={_G61_}>
                        <a onClick={(e) => this.toggleA(e, "_G61_")} href>
                          {" "}
                          Amount
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div id="_G61__body" className="fieldgroup-body">
                    <table className="basic-table" cellspacing="0">
                      <tbody>
                        <tr>
                          <td className="label">
                            <label
                              id="EditProduct_requireAmount_title"
                              for="EditProduct_requireAmount"
                            >
                              Require Amount
                            </label>
                          </td>
                          <td>
                            <input
                              id="EditProduct_productRating"
                              maxlength="20"
                              name="amount"
                              value={amount}
                              onChange={(e) => this.handleChange(e)}
                              size="10"
                              type="number"
                            />
                          </td>
                          <td className="label">
                            <label
                              id="EditProduct_amountUomTypeId_title"
                              for="EditProduct_amountUomTypeId"
                            >
                              Amount Uom Type Id
                            </label>
                          </td>
                          <td colspan="7">
                            <span className="ui-widget">
                              <select
                                id="EditProduct_amountUomTypeId"
                                name="amountUomTypeId"
                                size="1"
                              >
                                <option value="false"> </option>
                                <option value="AREA_MEASURE">Area</option>
                                <option value="CURRENCY_MEASURE">
                                  Currency
                                </option>
                                <option value="DATA_MEASURE">Data Size</option>
                                <option value="DATASPD_MEASURE">
                                  Data Speed
                                </option>
                                <option value="VOLUME_DRY_MEASURE">
                                  Dry Volume
                                </option>
                                <option value="ENERGY_MEASURE">Energy</option>
                                <option value="LENGTH_MEASURE">Length</option>
                                <option value="VOLUME_LIQ_MEASURE">
                                  Liquid Volume
                                </option>
                                <option value="OTHER_MEASURE">Other</option>
                                <option value="TEMP_MEASURE">
                                  Temperature
                                </option>
                                <option value="TIME_FREQ_MEASURE">
                                  Time/Frequency
                                </option>
                                <option value="WEIGHT_MEASURE">Weight</option>
                              </select>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="_G63_" className={"fieldgroup " + _G63_}>
                  <div className="fieldgroup-title-bar">
                    <ul>
                      <li className={_G63_}>
                        <a onClick={(e) => this.toggleA(e, "_G63_")} href>
                          {" "}
                          Measures
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div id="_G63__body" className="fieldgroup-body">
                    <table className="basic-table" cellspacing="0">
                      <tbody>
                        <tr>
                          <td className="label">
                            <label
                              id="EditProduct_productHeight_title"
                              for="EditProduct_productHeight"
                            >
                              Product Height
                            </label>
                          </td>
                          <td>
                            <input
                              id="EditProduct_productHeight"
                              maxlength="20"
                              name="productHeight"
                              size="10"
                              type="text"
                            />
                          </td>
                          <td className="label">
                            <label
                              id="EditProduct_heightUomId_title"
                              for="EditProduct_heightUomId"
                            >
                              Height Uom Id
                            </label>
                          </td>
                          <td>
                            <span className="ui-widget">
                              <select
                                id="EditProduct_heightUomId"
                                name="heightUomId"
                                size="1"
                              >
                                <option value="false"> </option>
                                <option value="LEN_A">Angstrom [A]</option>
                                <option value="LEN_cb">Cable [cb]</option>
                                <option value="LEN_cm">Centimeter [cm]</option>
                                <option value="LEN_chG">
                                  Chain (Gunter's/surveyor's) [chG]
                                </option>
                                <option value="LEN_chR">
                                  Chain (Ramden's/engineer's) [chR]
                                </option>
                                <option value="LEN_dm">Decimeter [dm]</option>
                                <option value="LEN_dam">Dekameter [dam]</option>
                                <option value="LEN_fm">Fathom [fm]</option>
                                <option value="LEN_ft">Foot [ft]</option>
                                <option value="LEN_fur">Furlong [fur]</option>
                                <option value="LEN_hand">
                                  Hand (horse's height) [hand]
                                </option>
                                <option value="LEN_in">Inch [in]</option>
                                <option value="LEN_km">Kilometer [km]</option>
                                <option value="LEN_league">
                                  League [league]
                                </option>
                                <option value="LEN_lnG">
                                  Link (Gunter's) [lnG]
                                </option>
                                <option value="LEN_lnR">
                                  Link (Ramden's) [lnR]
                                </option>
                                <option value="LEN_m">Meter [m]</option>
                                <option value="LEN_u">
                                  Micrometer (Micron) [u]
                                </option>
                                <option value="LEN_mil">
                                  Mil (Milli-inch) [mil]
                                </option>
                                <option value="LEN_nmi">
                                  Mile (nautical/sea) [nmi]
                                </option>
                                <option value="LEN_mi">
                                  Mile (statute/land) [mi]
                                </option>
                                <option value="LEN_mm">Millimeter [mm]</option>
                                <option value="LEN_pica">
                                  Pica (type size) [pica]
                                </option>
                                <option value="LEN_point">
                                  Point (type size) [point]
                                </option>
                                <option value="LEN_rd">Rod [rd]</option>
                                <option value="LEN_yd">Yard [yd]</option>
                              </select>
                            </span>
                          </td>
                          <td className="label">
                            <label
                              id="EditProduct_shippingHeight_title"
                              for="EditProduct_shippingHeight"
                            >
                              Shipping Height
                            </label>
                          </td>
                          <td colspan="4">
                            <input
                              id="EditProduct_shippingHeight"
                              maxlength="20"
                              name="shippingHeight"
                              size="10"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="label">
                            <label
                              id="EditProduct_productWidth_title"
                              for="EditProduct_productWidth"
                            >
                              Product Width
                            </label>
                          </td>
                          <td>
                            <input
                              id="EditProduct_productWidth"
                              maxlength="20"
                              name="productWidth"
                              size="10"
                              type="text"
                            />
                          </td>
                          <td className="label">
                            <label
                              id="EditProduct_widthUomId_title"
                              for="EditProduct_widthUomId"
                            >
                              Width Uom Id
                            </label>
                          </td>
                          <td>
                            <span className="ui-widget">
                              <select
                                id="EditProduct_widthUomId"
                                name="widthUomId"
                                size="1"
                              >
                                <option value="false"> </option>
                                <option value="LEN_A">Angstrom [A]</option>
                                <option value="LEN_cb">Cable [cb]</option>
                                <option value="LEN_cm">Centimeter [cm]</option>
                                <option value="LEN_chG">
                                  Chain (Gunter's/surveyor's) [chG]
                                </option>
                                <option value="LEN_chR">
                                  Chain (Ramden's/engineer's) [chR]
                                </option>
                                <option value="LEN_dm">Decimeter [dm]</option>
                                <option value="LEN_dam">Dekameter [dam]</option>
                                <option value="LEN_fm">Fathom [fm]</option>
                                <option value="LEN_ft">Foot [ft]</option>
                                <option value="LEN_fur">Furlong [fur]</option>
                                <option value="LEN_hand">
                                  Hand (horse's height) [hand]
                                </option>
                                <option value="LEN_in">Inch [in]</option>
                                <option value="LEN_km">Kilometer [km]</option>
                                <option value="LEN_league">
                                  League [league]
                                </option>
                                <option value="LEN_lnG">
                                  Link (Gunter's) [lnG]
                                </option>
                                <option value="LEN_lnR">
                                  Link (Ramden's) [lnR]
                                </option>
                                <option value="LEN_m">Meter [m]</option>
                                <option value="LEN_u">
                                  Micrometer (Micron) [u]
                                </option>
                                <option value="LEN_mil">
                                  Mil (Milli-inch) [mil]
                                </option>
                                <option value="LEN_nmi">
                                  Mile (nautical/sea) [nmi]
                                </option>
                                <option value="LEN_mi">
                                  Mile (statute/land) [mi]
                                </option>
                                <option value="LEN_mm">Millimeter [mm]</option>
                                <option value="LEN_pica">
                                  Pica (type size) [pica]
                                </option>
                                <option value="LEN_point">
                                  Point (type size) [point]
                                </option>
                                <option value="LEN_rd">Rod [rd]</option>
                                <option value="LEN_yd">Yard [yd]</option>
                              </select>
                            </span>
                          </td>
                          <td className="label">
                            <label
                              id="EditProduct_shippingWidth_title"
                              for="EditProduct_shippingWidth"
                            >
                              Shipping Width
                            </label>
                          </td>
                          <td colspan="4">
                            <input
                              id="EditProduct_shippingWidth"
                              maxlength="20"
                              name="shippingWidth"
                              size="10"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="label">
                            <label
                              id="EditProduct_productDepth_title"
                              for="EditProduct_productDepth"
                            >
                              Product Depth
                            </label>
                          </td>
                          <td>
                            <input
                              id="EditProduct_productDepth"
                              maxlength="20"
                              name="productDepth"
                              size="10"
                              type="text"
                            />
                          </td>
                          <td className="label">
                            <label
                              id="EditProduct_depthUomId_title"
                              for="EditProduct_depthUomId"
                            >
                              Depth Uom Id
                            </label>
                          </td>
                          <td>
                            <span className="ui-widget">
                              <select
                                id="EditProduct_depthUomId"
                                name="depthUomId"
                                size="1"
                              >
                                <option value="false"> </option>
                                <option value="LEN_A">Angstrom [A]</option>
                                <option value="LEN_cb">Cable [cb]</option>
                                <option value="LEN_cm">Centimeter [cm]</option>
                                <option value="LEN_chG">
                                  Chain (Gunter's/surveyor's) [chG]
                                </option>
                                <option value="LEN_chR">
                                  Chain (Ramden's/engineer's) [chR]
                                </option>
                                <option value="LEN_dm">Decimeter [dm]</option>
                                <option value="LEN_dam">Dekameter [dam]</option>
                                <option value="LEN_fm">Fathom [fm]</option>
                                <option value="LEN_ft">Foot [ft]</option>
                                <option value="LEN_fur">Furlong [fur]</option>
                                <option value="LEN_hand">
                                  Hand (horse's height) [hand]
                                </option>
                                <option value="LEN_in">Inch [in]</option>
                                <option value="LEN_km">Kilometer [km]</option>
                                <option value="LEN_league">
                                  League [league]
                                </option>
                                <option value="LEN_lnG">
                                  Link (Gunter's) [lnG]
                                </option>
                                <option value="LEN_lnR">
                                  Link (Ramden's) [lnR]
                                </option>
                                <option value="LEN_m">Meter [m]</option>
                                <option value="LEN_u">
                                  Micrometer (Micron) [u]
                                </option>
                                <option value="LEN_mil">
                                  Mil (Milli-inch) [mil]
                                </option>
                                <option value="LEN_nmi">
                                  Mile (nautical/sea) [nmi]
                                </option>
                                <option value="LEN_mi">
                                  Mile (statute/land) [mi]
                                </option>
                                <option value="LEN_mm">Millimeter [mm]</option>
                                <option value="LEN_pica">
                                  Pica (type size) [pica]
                                </option>
                                <option value="LEN_point">
                                  Point (type size) [point]
                                </option>
                                <option value="LEN_rd">Rod [rd]</option>
                                <option value="LEN_yd">Yard [yd]</option>
                              </select>
                            </span>
                          </td>
                          <td className="label">
                            <label
                              id="EditProduct_shippingDepth_title"
                              for="EditProduct_shippingDepth"
                            >
                              Shipping Depth
                            </label>
                          </td>
                          <td colspan="4">
                            <input
                              id="EditProduct_shippingDepth"
                              maxlength="20"
                              name="shippingDepth"
                              size="10"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="label">
                            <label
                              id="EditProduct_productDiameter_title"
                              for="EditProduct_productDiameter"
                            >
                              Product Diameter
                            </label>
                          </td>
                          <td>
                            <input
                              id="EditProduct_productDiameter"
                              maxlength="20"
                              name="productDiameter"
                              size="10"
                              type="text"
                            />
                          </td>
                          <td className="label">
                            <label
                              id="EditProduct_diameterUomId_title"
                              for="EditProduct_diameterUomId"
                            >
                              Diameter Uom Id
                            </label>
                          </td>
                          <td colspan="7">
                            <span className="ui-widget">
                              <select
                                id="EditProduct_diameterUomId"
                                name="diameterUomId"
                                size="1"
                              >
                                <option value="false"> </option>
                                <option value="LEN_A">Angstrom [A]</option>
                                <option value="LEN_cb">Cable [cb]</option>
                                <option value="LEN_cm">Centimeter [cm]</option>
                                <option value="LEN_chG">
                                  Chain (Gunter's/surveyor's) [chG]
                                </option>
                                <option value="LEN_chR">
                                  Chain (Ramden's/engineer's) [chR]
                                </option>
                                <option value="LEN_dm">Decimeter [dm]</option>
                                <option value="LEN_dam">Dekameter [dam]</option>
                                <option value="LEN_fm">Fathom [fm]</option>
                                <option value="LEN_ft">Foot [ft]</option>
                                <option value="LEN_fur">Furlong [fur]</option>
                                <option value="LEN_hand">
                                  Hand (horse's height) [hand]
                                </option>
                                <option value="LEN_in">Inch [in]</option>
                                <option value="LEN_km">Kilometer [km]</option>
                                <option value="LEN_league">
                                  League [league]
                                </option>
                                <option value="LEN_lnG">
                                  Link (Gunter's) [lnG]
                                </option>
                                <option value="LEN_lnR">
                                  Link (Ramden's) [lnR]
                                </option>
                                <option value="LEN_m">Meter [m]</option>
                                <option value="LEN_u">
                                  Micrometer (Micron) [u]
                                </option>
                                <option value="LEN_mil">
                                  Mil (Milli-inch) [mil]
                                </option>
                                <option value="LEN_nmi">
                                  Mile (nautical/sea) [nmi]
                                </option>
                                <option value="LEN_mi">
                                  Mile (statute/land) [mi]
                                </option>
                                <option value="LEN_mm">Millimeter [mm]</option>
                                <option value="LEN_pica">
                                  Pica (type size) [pica]
                                </option>
                                <option value="LEN_point">
                                  Point (type size) [point]
                                </option>
                                <option value="LEN_rd">Rod [rd]</option>
                                <option value="LEN_yd">Yard [yd]</option>
                              </select>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td className="label">
                            <label
                              id="EditProduct_productWeight_title"
                              for="EditProduct_productWeight"
                            >
                              Product Weight
                            </label>
                          </td>
                          <td>
                            <input
                              id="EditProduct_productWeight"
                              maxlength="20"
                              name="productWeight"
                              size="10"
                              type="text"
                            />
                          </td>
                          <td className="label">
                            <label
                              id="EditProduct_weightUomId_title"
                              for="EditProduct_weightUomId"
                            >
                              Weight Uom Id
                            </label>
                          </td>
                          <td>
                            <span className="ui-widget">
                              <select
                                id="EditProduct_weightUomId"
                                name="weightUomId"
                                size="1"
                              >
                                <option value="false"> </option>
                                <option value="WT_dr_avdp">
                                  Dram (avdp) [dr avdp]
                                </option>
                                <option value="WT_gr">Grain [gr]</option>
                                <option value="WT_g">Gram [g]</option>
                                <option value="WT_kg">Kilogram [kg]</option>
                                <option value="WT_mg">Milligram [mg]</option>
                                <option value="WT_oz">Ounce (avdp) [oz]</option>
                                <option value="WT_oz_tr">
                                  Ounce (troy) [oz tr]
                                </option>
                                <option value="WT_dwt">
                                  Pennyweight [dwt]
                                </option>
                                <option value="WT_lb">Pound (avdp) [lb]</option>
                                <option value="WT_st">Stone [st]</option>
                                <option value="WT_lt">
                                  Ton (long or British) [lt]
                                </option>
                                <option value="WT_mt">Ton (metric) [mt]</option>
                                <option value="WT_sh_t">
                                  Ton (short) [sh t]
                                </option>
                              </select>
                            </span>
                          </td>
                          <td className="label">
                            <label
                              id="EditProduct_weight_title"
                              for="EditProduct_weight"
                            >
                              Weight
                            </label>
                          </td>
                          <td colspan="4">
                            <input
                              id="EditProduct_weight"
                              maxlength="20"
                              name="weight"
                              size="10"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="label">
                            <label
                              id="EditProduct_quantityIncluded_title"
                              for="EditProduct_quantityIncluded"
                            >
                              Quantity Included
                            </label>
                          </td>
                          <td>
                            <input
                              id="EditProduct_quantityIncluded"
                              maxlength="20"
                              name="quantityIncluded"
                              size="10"
                              type="text"
                            />
                          </td>
                          <td className="label">
                            <label
                              id="EditProduct_quantityUomId_title"
                              for="EditProduct_quantityUomId"
                            >
                              Quantity UomId
                            </label>
                          </td>
                          <td colspan="7">
                            <span className="ui-widget">
                              <select
                                id="EditProduct_quantityUomId"
                                name="quantityUomId"
                                size="1"
                              >
                                <option value="false"> </option>
                                <option value="AREA_A">Acre - A</option>
                                <option value="OTH_A">
                                  Amphere - Electric current - A
                                </option>
                                <option value="LEN_A">Angstrom - A</option>
                                <option value="AREA_a">Are - a</option>
                                <option value="VLIQ_bbl">
                                  Barrel (US) - bbl
                                </option>
                                <option value="DATA_b">Bit of Data - b</option>
                                <option value="DATASPD_bps">
                                  Bit-per-second of Data - bps
                                </option>
                                <option value="OTH_box">Box - bx</option>
                                <option value="EN_Btu">
                                  British Thermal Unit - Btu
                                </option>
                                <option value="DATA_B">Byte of Data - B</option>
                                <option value="LEN_cb">Cable - cb</option>
                                <option value="EN_cal15">
                                  Calorie (@15.5c) - cal15
                                </option>
                                <option value="OTH_cd">
                                  Candela - Luminosity (intensity of light) - cd
                                </option>
                                <option value="LEN_cm">Centimeter - cm</option>
                                <option value="LEN_chG">
                                  Chain (Gunter's/surveyor's) - chG
                                </option>
                                <option value="LEN_chR">
                                  Chain (Ramden's/engineer's) - chR
                                </option>
                                <option value="VDRY_cm3">
                                  Cubic centimeter - cm3
                                </option>
                                <option value="VDRY_ft3">
                                  Cubic foot - ft3
                                </option>
                                <option value="VDRY_in3">
                                  Cubic inch - in3
                                </option>
                                <option value="VDRY_m3">
                                  Cubic meter - m3
                                </option>
                                <option value="VLIQ_M3">
                                  Cubic meter (liquid) - m3
                                </option>
                                <option value="VDRY_mm3">
                                  Cubic millimeter - mm3
                                </option>
                                <option value="VDRY_yd3">
                                  Cubic yard - yd3
                                </option>
                                <option value="VLIQ_cup">Cup - cup</option>
                                <option value="LEN_dm">Decimeter - dm</option>
                                <option value="TEMP_C">
                                  Degrees Celsius - C
                                </option>
                                <option value="TEMP_F">
                                  Degrees Fahrenheit - F
                                </option>
                                <option value="LEN_dam">Dekameter - dam</option>
                                <option value="WT_dr_avdp">
                                  Dram (avdp) - dr avdp
                                </option>
                                <option value="VLIQ_dr">Dram (US) - dr</option>
                                <option value="OTH_ea">Each - ea</option>
                                <option value="LEN_fm">Fathom - fm</option>
                                <option value="LEN_ft">Foot - ft</option>
                                <option value="LEN_fur">Furlong - fur</option>
                                <option value="VLIQ_galUK">
                                  Gallon (UK) - gal
                                </option>
                                <option value="VLIQ_galUS">
                                  Gallon (US) - gal
                                </option>
                                <option value="DATA_Gb">
                                  Gigabit of Data - Gb
                                </option>
                                <option value="DATASPD_Gbps">
                                  Gigabit-per-second of Data - Gbps
                                </option>
                                <option value="DATA_GB">
                                  Gigabyte of Data - GB
                                </option>
                                <option value="VLIQ_gi">
                                  Gill (1/4 UK pint) - gi
                                </option>
                                <option value="WT_gr">Grain - gr</option>
                                <option value="WT_g">Gram - g</option>
                                <option value="LEN_hand">
                                  Hand (horse's height) - hand
                                </option>
                                <option value="AREA_ha">Hectare - ha</option>
                                <option value="VLIQ_HL">Hectoliter - hL</option>
                                <option value="EN_HP">
                                  Horsepower (mechanical) - HP
                                </option>
                                <option value="LEN_in">Inch - in</option>
                                <option value="EN_J">
                                  Joule (absolute) - J
                                </option>
                                <option value="TEMP_K">Kelvin - K</option>
                                <option value="DATA_Kb">
                                  Kilobit of Data - Kb
                                </option>
                                <option value="DATASPD_Kbps">
                                  Kilobit-per-second of Data - Kbps
                                </option>
                                <option value="DATA_KB">
                                  Kilobyte of Data - KB
                                </option>
                                <option value="WT_kg">Kilogram - kg</option>
                                <option value="LEN_km">Kilometer - km</option>
                                <option value="EN_Kw">Kilowatt - Kw</option>
                                <option value="LEN_league">
                                  League - league
                                </option>
                                <option value="LEN_lnG">
                                  Link (Gunter's) - lnG
                                </option>
                                <option value="LEN_lnR">
                                  Link (Ramden's) - lnR
                                </option>
                                <option value="VLIQ_L">Liter - L</option>
                                <option value="DATA_Mb">
                                  Megabit of Data - Mb
                                </option>
                                <option value="DATASPD_Mbps">
                                  Megabit-per-second of Data - Mbps
                                </option>
                                <option value="DATA_MB">
                                  Megabyte of Data - MB
                                </option>
                                <option value="LEN_m">Meter - m</option>
                                <option value="LEN_u">
                                  Micrometer (Micron) - u
                                </option>
                                <option value="LEN_mil">
                                  Mil (Milli-inch) - mil
                                </option>
                                <option value="LEN_nmi">
                                  Mile (nautical/sea) - nmi
                                </option>
                                <option value="LEN_mi">
                                  Mile (statute/land) - mi
                                </option>
                                <option value="WT_mg">Milligram - mg</option>
                                <option value="VLIQ_ml">Milliliter - ml</option>
                                <option value="LEN_mm">Millimeter - mm</option>
                                <option value="OTH_mol">
                                  Mole - Substance (molecule) - mol
                                </option>
                                <option value="WT_oz">Ounce (avdp) - oz</option>
                                <option value="WT_oz_tr">
                                  Ounce (troy) - oz tr
                                </option>
                                <option value="VLIQ_ozUK">
                                  Ounce, fluid (UK) - fl. oz (UK)
                                </option>
                                <option value="VLIQ_ozUS">
                                  Ounce, fluid (US) - fl. oz (US)
                                </option>
                                <option value="OTH_pk">Package - pk</option>
                                <option value="WT_dwt">
                                  Pennyweight - dwt
                                </option>
                                <option value="OTH_pp">Per Person - pp</option>
                                <option value="DATA_Pb">
                                  Petabit of Data - Pb
                                </option>
                                <option value="DATA_PB">
                                  Petabyte of Data - PB
                                </option>
                                <option value="LEN_pica">
                                  Pica (type size) - pica
                                </option>
                                <option value="VLIQ_ptUK">
                                  Pint (UK) - pt (UK)
                                </option>
                                <option value="VLIQ_ptUS">
                                  Pint (US) - pt (US)
                                </option>
                                <option value="LEN_point">
                                  Point (type size) - point
                                </option>
                                <option value="WT_lb">Pound (avdp) - lb</option>
                                <option value="VLIQ_qt">Quart - qt</option>
                                <option value="LEN_rd">Rod - rd</option>
                                <option value="AREA_cm2">
                                  Square Centimeter - cm2
                                </option>
                                <option value="AREA_ft2">
                                  Square Foot - ft2
                                </option>
                                <option value="AREA_in2">
                                  Square Inch - in2
                                </option>
                                <option value="AREA_km2">
                                  Square Kilometer - km2
                                </option>
                                <option value="AREA_m2">
                                  Square Meter - m2
                                </option>
                                <option value="AREA_mi2">
                                  Square Mile - mi2
                                </option>
                                <option value="AREA_mm2">
                                  Square Millimeter - mm2
                                </option>
                                <option value="AREA_rd2">
                                  Square Rod - rd2
                                </option>
                                <option value="AREA_yd2">
                                  Square Yard - yd2
                                </option>
                                <option value="VDRY_ST">
                                  Stere (cubic meter) - ST
                                </option>
                                <option value="WT_st">Stone - st</option>
                                <option value="VLIQ_Tbs">
                                  Tablespoon - Tbs
                                </option>
                                <option value="VLIQ_tsp">Teaspoon - tsp</option>
                                <option value="DATA_Tb">
                                  Terabit of Data - Tb
                                </option>
                                <option value="DATASPD_Tbps">
                                  Terabit-per-second of Data - Tbps
                                </option>
                                <option value="DATA_TB">
                                  Terabyte of Data - TB
                                </option>
                                <option value="TF_century">
                                  Time in Centuries - century
                                </option>
                                <option value="TF_day">
                                  Time in Days - day
                                </option>
                                <option value="TF_decade">
                                  Time in Decades - decade
                                </option>
                                <option value="TF_hr">
                                  Time in Hours - hr
                                </option>
                                <option value="TF_millenium">
                                  Time in Millenia - millenium
                                </option>
                                <option value="TF_ms">
                                  Time in Milli-Seconds - ms
                                </option>
                                <option value="TF_min">
                                  Time in Minutes - min
                                </option>
                                <option value="TF_mon">
                                  Time in Months - mon
                                </option>
                                <option value="TF_score">
                                  Time in Scores - score
                                </option>
                                <option value="TF_s">
                                  Time in Seconds - s
                                </option>
                                <option value="TF_wk">
                                  Time in Weeks - wk
                                </option>
                                <option value="TF_yr">
                                  Time in Years - yr
                                </option>
                                <option value="WT_lt">
                                  Ton (long or British) - lt
                                </option>
                                <option value="WT_mt">Ton (metric) - mt</option>
                                <option value="WT_sh_t">
                                  Ton (short) - sh t
                                </option>
                                <option value="EN_w">Watt - w</option>
                                <option value="LEN_yd">Yard - yd</option>
                              </select>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="_G65_" className={"fieldgroup " + _G65_}>
                  <div className="fieldgroup-title-bar">
                    <ul>
                      <li className={_G65_}>
                        <a onClick={(e) => this.toggleA(e, "_G65_")} href>
                          {" "}
                          Shipping
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div id="_G65__body" className="fieldgroup-body">
                    <table className="basic-table" cellspacing="0">
                      <tbody>
                        <tr>
                          <td className="label">
                            <label
                              id="EditProduct_piecesIncluded_title"
                              for="EditProduct_piecesIncluded"
                            >
                              Pieces Included
                            </label>
                          </td>
                          <td>
                            <input
                              id="EditProduct_piecesIncluded"
                              maxlength="20"
                              name="piecesIncluded"
                              size="10"
                              type="text"
                            />
                          </td>
                          <td className="label">
                            <label
                              id="EditProduct_inShippingBox_title"
                              for="EditProduct_inShippingBox"
                            >
                              In Shipping Box ?
                            </label>
                          </td>
                          <td>
                            <span className="ui-widget">
                              <select
                                id="EditProduct_inShippingBox"
                                name="inShippingBox"
                                size="1"
                              >
                                <option value="false">N</option>
                                <option value="true">Y</option>
                              </select>
                            </span>
                          </td>
                          <td className="label">
                            <label
                              id="EditProduct_defaultShipmentBoxTypeId_title"
                              for="EditProduct_defaultShipmentBoxTypeId"
                            >
                              Default Shipment Box Type Id
                            </label>
                          </td>
                          <td>
                            <span className="ui-widget">
                              <select
                                id="EditProduct_defaultShipmentBoxTypeId"
                                name="defaultShipmentBoxTypeId"
                                size="1"
                              >
                                <option value="false"> </option>
                                <option value="BOX10KGBOX">10KG Box</option>
                                <option value="BOX25KGBOX">25KG Box</option>
                                <option value="YOURPACKNG">
                                  Your Packaging
                                </option>
                              </select>
                            </span>
                          </td>
                          <td className="label">
                            <label
                              id="EditProduct_chargeShipping_title"
                              for="EditProduct_chargeShipping"
                            >
                              Charge Shipping
                            </label>
                          </td>
                          <td>
                            <span className="ui-widget">
                              <select
                                id="EditProduct_chargeShipping"
                                name="chargeShipping"
                                size="1"
                              >
                                <option value="false"> </option>
                                <option value="true">Y</option>
                                <option value="false">N</option>
                              </select>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="_G67_" className={"fieldgroup " + _G67_}>
                  <div className="fieldgroup-title-bar">
                    <ul>
                      <li className={_G67_}>
                        <a onClick={(e) => this.toggleA(e, "_G67_")} href>
                          {" "}
                          ShoppingCart
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div id="_G67__body" className="fieldgroup-body">
                    <table className="basic-table" cellspacing="0">
                      <tbody>
                        <tr>
                          <td className="label">
                            <label
                              id="EditProduct_orderDecimalQuantity_title"
                              for="EditProduct_orderDecimalQuantity"
                            >
                              Allow order decimal quantity
                            </label>
                          </td>
                          <td colspan="10">
                            <span className="ui-widget">
                              <select
                                id="EditProduct_orderDecimalQuantity"
                                name="orderDecimalQuantity"
                                size="1"
                              >
                                <option value="false"> </option>
                                <option value="true">Y</option>
                                <option value="false">N</option>
                              </select>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="_G69_" className={"fieldgroup " + _G69_}>
                  <div className="fieldgroup-title-bar">
                    <ul>
                      <li className={_G69_}>
                        <a onClick={(e) => this.toggleA(e, "_G69_")} href>
                          {" "}
                          Miscellaneous
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div id="_G69__body" className="fieldgroup-body">
                    <table className="basic-table" cellspacing="0">
                      <tbody>
                        <tr>
                          <td className="label">
                            <label
                              id="EditProduct_returnable_title"
                              for="EditProduct_returnable"
                            >
                              Returnable
                            </label>
                          </td>
                          <td>
                            <span className="ui-widget">
                              <select
                                id="EditProduct_returnable"
                                name="returnable"
                                onChange={(e) => this.handleChange(e)}
                                value={returnable}
                                size="1"
                              >
                                <option value="false"> </option>
                                <option value="true">Y</option>
                                <option value="false">N</option>
                              </select>
                            </span>
                          </td>
                          <td className="label">
                            <label
                              id="EditProduct_includeInPromotions_title"
                              for="EditProduct_includeInPromotions"
                            >
                              Include In Promotions
                            </label>
                          </td>
                          <td>
                            <span className="ui-widget">
                              <select
                                id="EditProduct_includeInPromotions"
                                name="includeInPromotions"
                                size="1"
                                onChange={(e) => this.handleChange(e)}
                                value={includeInPromotions}
                              >
                                <option value="false"> </option>
                                <option value="true">Y</option>
                                <option value="false">N</option>
                              </select>
                            </span>
                          </td>
                          <td className="label">
                            <label
                              id="EditProduct_taxable_title"
                              for="EditProduct_taxable"
                            >
                              Taxable
                            </label>
                          </td>
                          <td>
                            <span className="ui-widget">
                              <select
                                id="EditProduct_taxable"
                                name="taxable"
                                size="1"
                                onChange={(e) => this.handleChange(e)}
                                value={taxable}
                              >
                                <option value="false"> </option>
                                <option value="true">Y</option>
                                <option value="false">N</option>
                              </select>
                            </span>
                          </td>
                          <td className="label">
                            <label
                              id="EditProduct_autoCreateKeywords_title"
                              for="EditProduct_autoCreateKeywords"
                            >
                              AutoCreateKeywords
                            </label>
                          </td>
                          <td>
                            <span className="ui-widget">
                              <select
                                id="EditProduct_autoCreateKeywords"
                                name="autoCreateKeywords"
                                size="1"
                                onChange={(e) => this.handleChange(e)}
                                value={autoCreateKeywords}
                              >
                                <option value="false"> </option>
                                <option value="true">Y</option>
                                <option value="false">N</option>
                              </select>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <table className="basic-table" cellspacing="0">
                  <tbody>
                    <tr>
                      <td className="label">
                        <label
                          id="EditProduct_contentInfoText_title"
                          for="EditProduct_contentInfoText"
                        >
                          Content Info Text
                        </label>
                      </td>
                      <td colspan="10">
                        <span
                          id="cc_EditProduct_contentInfoText"
                          className="tooltip"
                        >
                          NOTE: For content options, use the Content tab.
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="label"> </td>
                      <td colspan="10">
                        <input
                          className="smallSubmit"
                          name="submitButton"
                          type="submit"
                          value={
                            (mode === "add" ? "Create" : "Update") + " Product"
                          }
                          // disabled={submitted}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
          {mode === "edit" && <DuplicateProduct product={product} />}
        </div>
      </div>
    );
  }
  constructor(props) {
    super(props);
    this.state = {
      _G49_: "expanded",
      _G51_: "collapsed",
      _G53_: "expanded",
      _G55_: "expanded",
      _G57_: "collapsed",
      _G59_: "collapsed",
      _G61_: "expanded",
      _G63_: "collapsed",
      _G65_: "collapsed",
      _G67_: "collapsed",
      _G69_: "collapsed",
      productId: "",
      title: "",
      internalName: "",
      brandName: "",
      OEMPartyId: "",
      comments: "",
      isVirtual: false,
      isVariant: false,
      introductionDate: null,
      releaseDate: null,
      supportThroughDate: null,
      salesDiscontinuationThroughDate: null,
      amount: null,
      returnable: false,
      includeInPromotions: false,
      taxable: false,
      autoCreateKeywords: false,
      description: "",
      deliveryInfo: "",
      careInfo: "",
      category: "",
      images: [],
      isActive: true,
      isDeleted: false,
      createdDate: new Date(),
      messageChecked: false,
      egglessChecked: false,
      photoChecked: false,
      varientsChecked: false,
      bestSelling: false,
      relevance: "",
      oneHourDelivery: false,
      twoHoursDelivery: false,
      shippmentMethods: [],
      CategoriesArray: [],
      categoryToAdd: "",
      subCategory: "",
      bestSellingType: "gifts",
    };
  }
  async componentDidMount() {
    this.props.allActive();
    this.props.allCategories();
    const { mode } = this.props;
    if (mode === "edit") {
      const query = queryString.parse(this.props.location.search);
      const product = query.productId;
      const data = await getRequest(
        "products/get/" + product + "/true?isAdmin=true"
      );
      // const fetchData = data.data;
      // const productData = fetchData.product;
      // console.log("this is data", data);
      const {
        _id,
        productId,
        title,
        internalName,
        brandName,
        OEMPartyId,
        comments,
        isVirtual,
        isVariant,
        introductionDate,
        releaseDate,
        supportThroughDate,
        salesDiscontinuationThroughDate,
        amount,
        returnable,
        includeInPromotions,
        taxable,
        autoCreateKeywords,
        description,
        deliveryInfo,
        careInfo,
        category,
        images,
        isActive,
        isDeleted,
        createdDate,
        messageOption,
        eggOption,
        photoOption,
        bestSelling,
        relevance,
        oneHourDelivery,
        twoHoursDelivery,
        shippmentMethodIds,
        CategoriesArray,
        subCategory,
        bestSellingType,
      } = data?.data.product;
      this.setState({
        productId,
        title,
        internalName,
        brandName,
        OEMPartyId,
        comments,
        isVirtual,
        isVariant,
        introductionDate,
        releaseDate,
        supportThroughDate,
        amount,
        returnable,
        includeInPromotions,
        taxable,
        autoCreateKeywords,
        description,
        deliveryInfo,
        careInfo,
        category,
        images,
        isActive,
        isDeleted,
        createdDate,
        messageChecked: messageOption,
        egglessChecked: eggOption,
        photoChecked: photoOption,
        _id,
        bestSelling,
        relevance,
        oneHourDelivery,
        twoHoursDelivery,
        CategoriesArray,
        subCategory,
        shippmentMethods: shippmentMethodIds.split(";").map((x) => +x),
        bestSellingType,
      });

      if (salesDiscontinuationThroughDate) {
        this.setState({
          salesDiscontinuationThroughDate:
            salesDiscontinuationThroughDate.split("T")[0],
        });
      }
    }
  }
  handleChange = (e) => {
    var value = e.target.value;
    var key = e.target.name;
    this.setState({ [key]: value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { mode } = this.props;
    const {
      productId,
      title,
      internalName,
      brandName,
      OEMPartyId,
      comments,
      isVirtual,
      isVariant,
      introductionDate,
      releaseDate,
      supportThroughDate,
      salesDiscontinuationThroughDate,
      amount,
      returnable,
      includeInPromotions,
      taxable,
      autoCreateKeywords,
      description,
      deliveryInfo,
      careInfo,
      category,
      // images,
      isActive,
      isDeleted,
      createdDate,
      messageChecked,
      egglessChecked,
      photoChecked,
      bestSelling,
      relevance,
      _id,
      oneHourDelivery,
      twoHoursDelivery,
      shippmentMethods,
      subCategory,
      bestSellingType,
    } = this.state;
    const data = {
      productId,
      title,
      internalName,
      brandName,
      OEMPartyId,
      comments,
      isVirtual,
      isVariant,
      introductionDate,
      releaseDate,
      supportThroughDate,
      salesDiscontinuationThroughDate,
      amount,
      returnable,
      includeInPromotions,
      taxable,
      autoCreateKeywords,
      description,
      deliveryInfo,
      careInfo,
      category: category,
      // images,
      isActive,
      isDeleted,
      createdDate,
      messageOption: messageChecked,
      eggOption: egglessChecked,
      photoOption: photoChecked,
      bestSelling,
      relevance,
      oneHourDelivery,
      twoHoursDelivery,
      shippmentMethodIds: shippmentMethods,
      subCategory,
      bestSellingType: bestSelling ? bestSellingType : undefined,
    };
    console.log("data==>", data);
    mode === "add"
      ? this.props.save(data)
      : this.props.update({ ...data, _id });
  };
  deleteCat = async (catId) => {
    const query = queryString.parse(this.props.location.search);
    const product = query.productId;
    let data = { categoryId: catId, productId: product };
    let response = await putRequest("products/deleteCategory", data, false);
    this.setState({ CategoriesArray: response.data });
  };
  addCategory = async () => {
    let categorySlug = this.state.categoryToAdd?.trim();
    const query = queryString.parse(this.props.location.search);
    const product = query.productId;
    if (!categorySlug) {
      Notify("danger", "Please add a CategorySlug", "Error");
      return;
    }
    let response = await putRequest(
      "products/addCategoryBySlug",
      { categorySlug, productId: product },
      false
    );
    this.setState({ CategoriesArray: response.data, categoryToAdd: "" });
    //make request
  };
  toggleA = (e, key) => {
    e.preventDefault();
    const old = this.state[key];
    switch (key) {
      case "expand":
        this.setAllToggle("expanded");
        break;
      case "collapse":
        this.setAllToggle("collapsed");
        break;
      default:
        this.setState({ [key]: old === "expanded" ? "collapsed" : "expanded" });
        break;
    }
  };
  setAllToggle = (state) => {
    this.setState({
      _G49_: state,
      _G51_: state,
      _G53_: state,
      _G55_: state,
      _G57_: state,
      _G59_: state,
      _G61_: state,
      _G63_: state,
      _G65_: state,
      _G67_: state,
      _G69_: state,
    });
  };
}

const mapStateToProps = (state) => ({
  submitted: state?.catalog?.products?.submitted,
  categories: state?.catalog?.categories?.active,
  shipmentMethodTypes:
    state.catalog?.shipmentMethodTypesReducer?.shipment_method_types,
});

const mapDispatchToProps = (dispatch, router) => ({
  save: (data) => dispatch(createProduct(data, router)),
  update: (data) => dispatch(updateProduct(data, router)),
  allCategories: () => dispatch(allActiveCategories()),
  productDetails: (id) => dispatch(getProduct(id, router)),
  allActive: () => dispatch(allActiveCarrierShipmentMethodsTypes()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditProduct)
);
