import React, { Component } from "react";
import { connect } from "react-redux";
import { searchCatalog } from "../../../../store/actions/catalogActions";
import { withRouter, Link } from "react-router-dom";

export class FindCatalog extends Component {
  render() {
    const { name, _id } = this.state;
    const { submitted, catalogs } = this.props;
    return (
      <div id="content-main-section" className="leftonly">
        <div id="centerdiv" className="no-clear">
          <div className="button-bar button-style-2">
            <ul>
              <li>
                <ul>
                  <li className="buttontext create">
                    <Link
                      className="buttontext create"
                      to="/catalog/control/EditProdCatalog"
                    >
                      New Prod Catalog
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="page-title">Find Catalog</div>
          <div className="clear"> </div>
          <div id="searchOptions" className="screenlet">
            <div className="screenlet-title-bar">
              <ul>
                <li className="h3">Search Options</li>
                <li className="expanded">
                  <a title="Collapse" href>
                    {" "}
                  </a>
                </li>
              </ul>
            </div>
            <div id="searchOptions_col" className="screenlet-body">
              <div id="search-options">
                <form
                  id="FindCatalog"
                  className="basic-form"
                  onSubmit={(e) => this.handleSubmit(e)}
                >
                  <table className="basic-table" cellSpacing="0">
                    <tbody>
                      <tr>
                        <td className="label">
                          <label
                            id="FindCatalog_prodCatalogId_title"
                            htmlFor="FindCatalog_prodCatalogId"
                          >
                            ProdCatalogId
                          </label>
                        </td>
                        <td>
                          <select className="selectBox" name="idFilterType" value={this.state.idFilterType} onChange={(e) => this.handleChange(e)}>
                            <option value="equals">Equals</option>
                            <option value="beginWith">Begins With</option>
                            <option defaultValue value="contains">
                              Contains
                            </option>
                            <option value="empty">Is Empty</option>
                            <option value="notEqual">Not Equal</option>
                          </select>
                          <input
                            name="_id"
                            onChange={(e) => this.handleChange(e)}
                            value={_id}
                            size="25"
                            type="text"
                          />{" "}
                          <input checked="checked" type="checkbox" value="Y" />{" "}
                          Ignore Case
                        </td>
                      </tr>
                      <tr>
                        <td className="label">
                          <label
                            id="FindCatalog_catalogName_title"
                            htmlFor="FindCatalog_catalogName"
                          >
                            Catalog Name
                          </label>
                        </td>
                        <td>
                          <select className="selectBox" name="nameFilterType" value={this.state.nameFilterType} onChange={(e) => this.handleChange(e)}>
                            <option value="equals">Equals</option>
                            <option value="beginWith">Begins With</option>
                            <option  value="contains">
                              Contains
                            </option>
                            <option value="empty">Is Empty</option>
                            <option value="notEqual">Not Equal</option>
                          </select>
                          <input
                            name="name"
                            size="25"
                            type="text"
                            value={name}
                            onChange={(e) => this.handleChange(e)}
                          />{" "}
                          <input
                            checked="checked"
                            name="catalogName_ic"
                            type="checkbox"
                            value="Y"
                          />{" "}
                          Ignore Case
                        </td>
                      </tr>
                      <tr>
                        <td className="label"> </td>
                        <td colSpan="4">
                          <input
                            className="smallSubmit"
                            name="submitButton"
                            type="submit"
                            value="Find"
                            //disabled={submitted}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
          </div>
          <div id="screenlet_1" className="screenlet">
            <div id="screenlet_1_col" className="screenlet-body no-padding">
              <h3>Search Results</h3>
              <div id="search-results">
                <div className="nav-pager">
                  <ul>
                    <li className="nav-first-disabled">First</li>
                    <li className="nav-previous-disabled">Previous</li>
                    <li className="nav-page-select">
                      Page
                      <select
                        name="page"
                        size="1"
                        onChange="submitPagination(this, '/catalog/control/FindCatalog?noConditionFind=Y&amp;VIEW_SIZE_1=200&amp;VIEW_INDEX_1='+this.value)"
                      >
                        <option defaultValue value="0">
                          1
                        </option>
                      </select>
                    </li>
                    <li className="nav-next-disabled">Next</li>
                    <li className="nav-last-disabled">Last</li>
                    <li className="nav-pagesize">
                      <select
                        name="pageSize"
                        size="1"
                        onChange="submitPagination(this, '/catalog/control/FindCatalog?noConditionFind=Y&amp;VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')"
                      >
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option defaultValue value="200">
                          200
                        </option>
                      </select>
                      Items per page
                    </li>
                    <li className="nav-displaying">Displaying 1 - 20 of 20</li>
                  </ul>
                </div>
                <table className="basic-table hover-bar" cellSpacing="0">
                  <tbody>
                    <tr className="header-row-2">
                      <td>
                        <a
                          className="sort-order"
                          href="/catalog/control/FindCatalog?sortField=prodCatalogId&amp;noConditionFind=Y"
                        >
                          Prod Catalog Id
                        </a>
                      </td>
                      <td>
                        <a
                          className="sort-order"
                          href="/catalog/control/FindCatalog?sortField=catalogName&amp;noConditionFind=Y"
                        >
                          Catalog Name
                        </a>
                      </td>
                      <td>
                        <a
                          className="sort-order"
                          href="/catalog/control/FindCatalog?sortField=useQuickAdd&amp;noConditionFind=Y"
                        >
                          Use Quick Add
                        </a>
                      </td>
                    </tr>
                    {catalogs &&
                      catalogs.map(({ name, _id, useQuickAdd }) => {
                        return (
                          <tr>
                            <td>
                              <Link
                                to={
                                  "/catalog/control/EditProdCatalog?prodCatalogId=" +
                                  _id
                                }
                              >
                                {" "}
                                {_id}
                              </Link>
                            </td>
                            <td>{name}</td>
                            <td>{useQuickAdd}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="nav-pager">
                  <ul>
                    <li className="nav-first-disabled">First</li>
                    <li className="nav-previous-disabled">Previous</li>
                    <li className="nav-page-select">
                      Page
                      <select name="page" size="1">
                        <option defaultValue value="0">
                          1
                        </option>
                      </select>
                    </li>
                    <li className="nav-next-disabled">Next</li>
                    <li className="nav-last-disabled">Last</li>
                    <li className="nav-pagesize">
                      <select name="pageSize" size="1">
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option defaultValue value="200">
                          200
                        </option>
                      </select>
                      Items per page
                    </li>
                    <li className="nav-displaying">Displaying 1 - 20 of 20</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  componentDidMount() {
    this.props.search();
  }

  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      name: "",
      idFilterType: 'contains',
      nameFilterType: 'contains'
    };
  }
  handleChange = (e) => {
    var value = e.target.value;
    var key = e.target.name;
    this.setState({ [key]: value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { _id, name, idFilterType, nameFilterType } = this.state;
    let data = {};
    data = _id ? { ...data, _id: _id } : data;
    data = name ? { ...data, name: name } : data;
    data = idFilterType ? { ...data, idFilterType: idFilterType } : data;
    data = nameFilterType ? { ...data, nameFilterType: nameFilterType } : data;
    this.props.search(data);
  };
}

const mapStateToProps = (state) => {
  return {
    submitted: state.catalog?.catalogs?.submitted,
    catalogs: state.catalog?.catalogs?.catalogs_searched,
  };
};

const mapDispatchToProps = (dispatch, router) => ({
  search: (data) => dispatch(searchCatalog(data, router)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FindCatalog)
);
