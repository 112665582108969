import React, {Component} from "react";
import '../../styles.scss';

export default class EditFeatureCategories extends Component {
    render() {
        return (
            <div>
                <div>
                    <ul style={{display: "flex", listStyle: "none"}}>
                        <a href='/catalog/control/EditFeatureTypes'
                           style={{textDecoration: 'none'}}>
                            <li className="featureLi">Feature Type</li>
                        </a>
                        <a href='/catalog/control/EditFeatureCategories'
                           style={{textDecoration: 'none'}}>
                            <li className="featureLi">Feature Category</li>
                        </a>
                        <a href='/catalog/control/EditFeatureGroups'
                           style={{textDecoration: 'none'}}>
                            <li className="featureLi">Feature Group</li>
                        </a>
                        <a href='/catalog/control/EditFeatureInterActions'
                           style={{textDecoration: 'none'}}>
                            <li className="featureLi">Feature interAction</li>
                        </a>
                    </ul>
                </div>

                <div style={{marginTop: "1em"}} className="screenlet-title-bar">
                    <ul>
                        <li className="h3">Edit Feature Groups</li>
                    </ul>
                </div>
                <div style={{backgroundColor: "white"}}>

                    <table style={{paddingTop: '2em'}}>
                        <thead>
                        <tr>
                            <td style={{width: "70%", fontWeight: "bolder"}}>ID</td>
                            <td style={{fontWeight: "bolder"}}>Description</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div style={{marginTop: "1em"}} className="screenlet-title-bar">
                    <ul>
                        <li className="h3">Create a Product Feature Group</li>
                    </ul>
                </div>

                <div style={{backgroundColor: "white"}}>
                    <div style={{padding: '2em 0 1em 0'}}>
                        <label style={{fontWeight: "bolder", marginRight: "2em"}}>Description:</label><input
                        type="text"/>
                    </div>

                    <div style={{padding: '1em 0 2em 0'}}>
                        <button className="h3">Create</button>
                    </div>

                </div>

            </div>
        )
    }
}
