import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {actionOtherOrders} from "../../../store/actions/orderActions";
import {withRouter} from "react-router";

function mapStateToProps(state) {
    return {
        orders:state?.orderReducer?.otherOrders,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        get:(user)=>dispatch(actionOtherOrders(user)),
    };
}

class OtherOrders extends Component {
    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        const user = query.get("user");
        this.props.get(user);
    }

    render() {
        return (
            <div>
                <div id="findOrdersList" className="screenlet">
                    <div className="screenlet-title-bar">
                        <ul>
                            <li className="h3">Order List</li>
                        </ul>
                    </div>
                    <div className="screenlet-body">
                        <table className="basic-table hover-bar" cellSpacing="0">
                            <tbody>
                            <tr className="header-row">
                                <td width="10%">Date</td>
                                <td width="10%">Order Nbr</td>
                                <td width="15%">Customer Name</td>
                                <td width="10%">Customer Mobile</td>
                                <td width="10%">Source</td>
                                <td width="10%">Grand Total</td>
                            </tr>
                            {this.props.orders &&
                            this.props.orders.map((order) => {
                                return (
                                    <tr>
                                        <td>{order?.createdDate}</td>
                                        <td>
                                            <Link
                                                to={`/ordermgr/control/orderview?orderId=${order._id}`}
                                            >
                                                {order?._id}
                                            </Link>
                                        </td>
                                        <td>{order?.senderName}</td>
                                        <td>{order?.senderPhone}</td>
                                        <td></td>
                                        <td>{order?.amount}</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                        <table className="basic-table" cellSpacing="0">
                            <tbody>
                            <tr>
                                <td></td>
                                <td align="right"></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(OtherOrders));