import React from "react";
import { logo } from "../../constants/images";
import siteLogo from "../../assets/images/siteLogo.jpg";
import { app_navigation, main_navigation } from "../../constants/strings";
import { connect } from "react-redux";
import { logoutNow } from "../../store/actions/authActions";
import { withRouter, Link } from "react-router-dom";
import { pathParts, activeByURI } from "../../constants/utils";
import { generateSitemap } from "../../store/actions/catalogActions";
function TopNav({ token, logout, location, user ,handleSitemap}) {
  const parts = pathParts(location.pathname);
  const mainNavP = parts?.[1];
  const appNavP = parts?.[3];
  return (
    <React.Fragment>
      <div id="masthead">
        <ul>
          <li className="org-logo-area">
            <Link to="/catalog/control/main">
              <img alt="OFBiz: Catalog Manager" src={siteLogo} />
            </Link>
          </li>
          <li className="preference-area">
            <ul>
              {token ? (
                <React.Fragment>
                  <li>
                    Welcome {user.name}
                    <Link to="/partymgr/control/viewprofile?partyId=AE-23&amp;externalLoginKey=EL547713889500">
                      [{user.email}]
                    </Link>
                  </li>
                  <li>Default Organization : Your Company Name Here [GAB]</li>
                  <li>
                    <Link
                      to="/login"
                      onClick={(e) => {
                        e.preventDefault();
                        logout();
                      }}
                    >
                      Logout
                    </Link>
                  </li>
                </React.Fragment>
              ) : (
                <li>
                  Welcome! <Link to="/catalog/control/checkLogin">Login</Link>
                </li>
              )}
              <li>
                <Link to="/">Help</Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      {token && (
        <React.Fragment>
          <div id="main-navigation">
            <ul>
              {main_navigation.map(({ text, title, route, uri }, key) => {
                return (
                  <li
                    className={
                      activeByURI(mainNavP, uri) + " " + (!key ? "first" : "")
                    }
                    key={key}
                  >
                    <Link to={route} title={title}>
                      {text}
                    </Link>
                  </li>
                );
              })}
              <li style={{
                padding: ' 0 1em 0 0.5em', borderLeft: '0.1em solid #800080',
                borderRight: '0.1em solid #800080'
              }} onClick={handleSitemap}> <Link to="#">SITEMAP</Link> </li>
              <li className="last"></li>
            </ul>
          </div>
          <div id="app-navigation">
            <h2>{app_navigation?.[mainNavP]?.title}</h2>
            <ul>
              <li>
                <ul>
                  {app_navigation?.[mainNavP]?.items.map(
                    ({ text, route, uri }, key) => {
                      return (
                        <li className={activeByURI(appNavP, uri)} key={key}>
                          <Link to={route}>{text}</Link>
                        </li>
                      );
                    }
                  )}
                </ul>
              </li>
            </ul>
            <br className="clear" />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  token: state?.auth?.token,
  user: state?.auth?.user,
});
const mapDispatchToProps = (dispatch, router) => ({
  logout: () => dispatch(logoutNow(router)),
  handleSitemap:()=>dispatch(generateSitemap())
  
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopNav));
