import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  allActiveCategories,
  updateCategory,
} from "../../../../store/actions/catalogActions";

export class AssignCatalog extends Component {
  render() {
    const { categories, submitted } = this.props;
    return (
      <div id="screenlet_2" className="screenlet">
        <div className="screenlet-title-bar">
          <ul>
            <li className="h3">
              Add a Category to this Catalog (select Category and Type, then
              enter optional From Date)
            </li>
          </ul>
        </div>
        <div id="screenlet_2_col" className="screenlet-body">
          <form
            id="addProductCategoryToProdCatalog"
            className="basic-form"
            onSubmit={(e) => this.handleSubmit(e)}
          >
            <table className="basic-table" cellSpacing="0">
              <tbody>
                <tr>
                  {/* <td className="label">
                    <label
                      id="addProductCategoryToProdCatalog_productCategoryId_title"
                      htmlFor="addProductCategoryToProdCatalog_productCategoryId"
                    >
                      Category ID
                    </label>
                  </td> */}

                  <td className="label">
                    <label
                      id="addProductCategoryToProdCatalog_prodCatalogCategoryTypeId_title"
                      htmlFor="addProductCategoryToProdCatalog_prodCatalogCategoryTypeId"
                    >
                      Select Product
                    </label>
                  </td>
                  <td>
                    <span className="ui-widget">
                      <select
                        id="addProductCategoryToProdCatalog_prodCatalogCategoryTypeId"
                        name="category_id"
                        size="1"
                        onChange={(e) => this.handleChange(e)}
                      >
                        <option defaultValue></option>
                        {categories &&
                          categories.map((category, key) => {
                            return (
                              <option key={key} value={category?._id}>
                                {category?.name}
                              </option>
                            );
                          })}
                      </select>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="label">
                    <label
                      id="addProductCategoryToProdCatalog_prodCatalogCategoryTypeId_title"
                      htmlFor="addProductCategoryToProdCatalog_prodCatalogCategoryTypeId"
                    >
                      Product Catalog Category Type
                    </label>
                  </td>
                  <td>
                    <span className="ui-widget">
                      <select
                        id="addProductCategoryToProdCatalog_prodCatalogCategoryTypeId"
                        name="prodCatalogCategoryTypeId"
                        size="1"
                      >
                        <option value="PCCT_ADMIN_ALLW">
                          Admin Allow (One)
                        </option>
                        <option value="PCCT_BEST_SELL">
                          Best Selling (One)
                        </option>
                        <option value="PCCT_BROWSE_ROOT">
                          Browse Root (One)
                        </option>
                        <option value="PCCT_SEARCH">
                          Default Search (One)
                        </option>
                        <option value="PCCT_EBAY_ROOT">Ebay Root (One)</option>
                        <option value="PCCT_MOST_POPULAR">
                          Most Popular (One)
                        </option>
                        <option value="PCCT_OTHER_SEARCH">
                          Other Search (Many)
                        </option>
                        <option value="PCCT_PROMOTIONS">
                          Promotions (One)
                        </option>
                        <option value="PCCT_PURCH_ALLW">
                          Purchase Allow (One)
                        </option>
                        <option value="PCCT_QUICK_ADD">Quick Add (Many)</option>
                        <option value="PCCT_VIEW_ALLW">View Allow (One)</option>
                        <option value="PCCT_WHATS_NEW">
                          What's New (One per catalog)
                        </option>
                      </select>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="label">
                    <label
                      id="addProductCategoryToProdCatalog_fromDate_title"
                      htmlFor="addProductCategoryToProdCatalog_fromDate"
                    >
                      From Date Time
                    </label>
                  </td>
                  <td>
                    <span className="view-calendar">
                      <input
                        id="addProductCategoryToProdCatalog_fromDate_i18n"
                        title="dd-MM-yyyy HH:mm:ss"
                        maxLength="30"
                        name="fromDate_i18n"
                        size="25"
                        type="text"
                      />
                      <input
                        id="addProductCategoryToProdCatalog_fromDate"
                        className="hasDatepicker"
                        style={{
                          height: "1px",
                          width: "1px",
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                        title="dd-MM-yyyy HH:mm:ss"
                        maxLength="30"
                        name="fromDate"
                        size="25"
                        type="text"
                      />
                      <button
                        className="ui-datepicker-trigger"
                        type="button"
                      ></button>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="label">
                    <label
                      id="addProductCategoryToProdCatalog_thruDate_title"
                      htmlFor="addProductCategoryToProdCatalog_thruDate"
                    >
                      Through Date Time
                    </label>
                  </td>
                  <td>
                    <span className="view-calendar">
                      <input
                        id="addProductCategoryToProdCatalog_thruDate_i18n"
                        title="dd-MM-yyyy HH:mm:ss"
                        maxLength="30"
                        name="thruDate_i18n"
                        size="25"
                        type="text"
                      />
                      <input
                        id="addProductCategoryToProdCatalog_thruDate"
                        className="hasDatepicker"
                        style={{
                          height: "1px",
                          width: "1px",
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                        title="dd-MM-yyyy HH:mm:ss"
                        maxLength="30"
                        name="thruDate"
                        size="25"
                        type="text"
                      />
                      <button
                        className="ui-datepicker-trigger"
                        type="button"
                      ></button>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="label">
                    <label
                      id="addProductCategoryToProdCatalog_sequenceNum_title"
                      htmlFor="addProductCategoryToProdCatalog_sequenceNum"
                    >
                      Sequence Num
                    </label>
                  </td>
                  <td>
                    <input
                      id="addProductCategoryToProdCatalog_sequenceNum"
                      name="sequenceNum"
                      size="6"
                      type="text"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="label"> </td>
                  <td colSpan="4">
                    <input
                      className="smallSubmit"
                      name="submitButton"
                      type="submit"
                      value="Add"
                      disabled={submitted}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    );
  }
  constructor(props) {
    super(props);
    this.state = { category_id: "" };
  }

  componentDidMount() {
    this.props.allCategories();
  }
  handleChange = (e) => {
    var value = e.target.value;
    var key = e.target.name;
    this.setState({ [key]: value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    var data = { catalog: this.props.catalog, _id: this.state.category_id };
    this.props.updateCategory(data);
  };
}

const mapStateToProps = (state) => ({
  categories: state?.catalog?.categories?.active,
  submitted: state?.catalog?.categories?.submitted,
});

const mapDispatchToProps = (dispatch, router) => ({
  allCategories: () => dispatch(allActiveCategories()),
  updateCategory: (data) => dispatch(updateCategory(data, router)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AssignCatalog)
);
