import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  updateCarrierAllocation,
  createCarrierAllocation,
  allActiveCarrierAllocation,
} from "../../../../store/actions/catalogActions";
import { allActiveParties } from "../../../../store/actions/partyManagerActions";
import { TextField } from "@material-ui/core";
export class ListCarrierAllocation extends Component {
  componentDidMount() {
    console.log('mounted');
    this.props.allActive();
    this.props.allActiveParties();
    const parties = Array.from(this.props.parties);
    console.log('these are props',this.props);
    if(parties){
      this.setState({carrierPartyId:parties[0]?._id});
    }
    if(this.props.location.search){
      const id=this.props.location.search.split("=")[1];
      const carrierAllocation = Array.from(this.props.carrierAllocation);
      var carrierAllocations= carrierAllocation.filter((carrierAllocations)=>{
        return carrierAllocations._id==id;
      })
      const {
          _id,
          carrierPartyId,
          allocationType,
          allocation,
          fromDate,
          throughDate,
          geoType,
          geoId,
          priority,
      } = carrierAllocations[0];
      this.setState({
        carrierPartyId,
        allocationType,
        allocation,
        fromDate: fromDate && fromDate.split(".")[0],
        throughDate: throughDate && throughDate.split(".")[0],
        geoType,
        geoId,
        priority,
        isDisabled: true,
        _id,
      });

    }

    this.setState({
      data: [],
    });
    // this.props.create({
    //   timeSlot: 10000,
    //   roleType: "Carrier",
    //   sequence: 1,
    // });
    // console.log(this.props.get(3));
    //this.props.allActive();
    //this.props.search({ name: "Night_time_shipment891" });
    // this.props.update({
    //   name: "Night_time_shipment891",
    //   description: "changed to night time delivery deep in",
    // });
    //this.props.delete({ isActive: false, isDeleted: true });
  }
  state = {
    carrierPartyId: "",
    allocationType: "",
    allocation: "",
    fromDate: "",
    throughDate: "",
    geoType: "",
    geoId: "",
    priority: "",
    data: [],
    isDisabled: false,
  };

  handleChange = (e) => {
    var value = e.target.value;
    var key = e.target.name;
    this.setState({ [key]: value });
  };

  render() {
    const carrierAllocation = Array.from(this.props.carrierAllocation);
    const parties = Array.from(this.props.parties);
    return (
      <div id="content-main-section" class="leftonly">
        <div id="centerdiv" class="no-clear">
          <div class="button-bar tab-bar">
            <ul>
              <li>
                <ul>
                  <li>
                    <a href="/catalog/control/ListShipmentMethodTypes">
                      Shipment Method Types
                    </a>
                  </li>
                  <li>
                    <a href="/catalog/control/ListQuantityBreaks">
                      Quantity Breaks
                    </a>
                  </li>
                  <li>
                    <a href="/catalog/control/ListCarrierShipmentMethods">
                      Carrier Shipment Methods
                    </a>
                  </li>
                  <li>
                    <a href="/catalog/control/ListTimeSlots">
                      Product Time Slots
                    </a>
                  </li>
                  <li class="selected">
                    <a href="/catalog/control/ListCarrierAllocation">
                      Carrier Allocation
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div> </div>
          <div id="screenlet_1" class="screenlet">
            <div class="screenlet-title-bar">
              <ul>
                <li class="h3">List Carrier Allocation</li>
              </ul>
            </div>
            <div id="screenlet_1_col" class="screenlet-body">
              <div class="nav-pager">
                <ul>
                  <li class="nav-first-disabled">First</li>
                  <li class="nav-previous-disabled">Previous</li>
                  <li class="nav-page-select">
                    Page
                    <select
                      name="page"
                      size="1"
                      onchange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1=200&amp;VIEW_INDEX_1='+this.value)"
                    >
                      <option selected="selected" value="0">
                        1
                      </option>
                    </select>
                  </li>
                  <li class="nav-next-disabled">Next</li>
                  <li class="nav-last-disabled">Last</li>
                  <li class="nav-pagesize">
                    <select
                      name="pageSize"
                      size="1"
                      onchange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')"
                    >
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option selected="selected" value="200">
                        200
                      </option>
                    </select>
                    Items per page
                  </li>
                  <li class="nav-displaying">Displaying 1 - 23 of 23</li>
                </ul>
              </div>
              <table class="basic-table" cellspacing="0">
                <tbody>
                  <tr class="header-row">
                    <td>
                      <label
                        id="ListTimeSlots_timeSlotTypeId_title"
                        for="ListTimeSlots_timeSlotTypeId"
                      >
                        Carrier Party Id
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListTimeSlots_startTime_title"
                        for="ListTimeSlots_startTime"
                      >
                        Allocation type
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListTimeSlots_endTime_title"
                        for="ListTimeSlots_endTime"
                      >
                        From Date
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListTimeSlots_vendorStartTime_title"
                        for="ListTimeSlots_vendorStartTime"
                      >
                        Through Date
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListTimeSlots_vendorEndTime_title"
                        for="ListTimeSlots_vendorEndTime"
                      >
                        Allocation
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListTimeSlots_isDynamic_title"
                        for="ListTimeSlots_isDynamic"
                      >
                        Geo Id
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListTimeSlots_leadTime_title"
                        for="ListTimeSlots_leadTime"
                      >
                        Priority
                      </label>
                    </td>
                    <td> </td>
                    <td> </td>
                  </tr>
                  {carrierAllocation &&
                    carrierAllocation.map((slot) => {
                      return (
                        <tr>
                          <td>{slot.parties?.userLoginId}</td>

                          <td>{slot?.allocationType}</td>
                          <td>
                            {slot.fromDate && slot.fromDate.split(".")[0]}
                          </td>
                          <td>
                            {slot.throughDate && slot.throughDate.split(".")[0]}
                          </td>
                          <td>{slot?.allocation}</td>
                          <td>{slot?.geoId}</td>
                          <td>{slot?.priority}</td>
                          <td>
                            <form
                              action="/catalog/control/EditCarrierAllocation"
                              method="post"
                              name="ListTimeSlots_o_0_0_o_editLink"
                            >
                              <input
                                name="VIEW_SIZE_1"
                                type="hidden"
                                value="200"
                              />
                              <input
                                name="VIEW_INDEX_1"
                                type="hidden"
                                value="0"
                              />
                              <input
                                name="carrierShipmentMethodTypeId"
                                type="hidden"
                                value="COUR_DELIVERY"
                              />
                            </form>
                            <a
                              class="buttontext"
                              href={
                                "/catalog/control/ListCarrierAllocation?carrierAllocationId=" +
                                slot._id
                              }
                              onClick={(e) => this.handleEdit(e, slot)}
                            >
                              Edit
                            </a>
                          </td>
                          <td>
                            <form
                              action="/catalog/control/deleteCarrierShipmentMethod"
                              method="post"
                              name="ListTimeSlots_o_0_0_o_deleteLink"
                            >
                              <input
                                name="VIEW_SIZE_1"
                                type="hidden"
                                value="200"
                              />
                              <input
                                name="VIEW_INDEX_1"
                                type="hidden"
                                value="0"
                              />
                              <input
                                name="carrierShipmentMethodTypeId"
                                type="hidden"
                                value="COUR_DELIVERY"
                              />
                            </form>
                            <a
                              class="buttontext"
                              href
                              onClick={(e) => this.handleRemove(e, slot._id)}
                            >
                              Remove
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <div class="nav-pager">
                <ul>
                  <li class="nav-first-disabled">First</li>
                  <li class="nav-previous-disabled">Previous</li>
                  <li class="nav-page-select">
                    Page
                    <select
                      name="page"
                      size="1"
                      onchange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1=200&amp;VIEW_INDEX_1='+this.value)"
                    >
                      <option selected="selected" value="0">
                        1
                      </option>
                    </select>
                  </li>
                  <li class="nav-next-disabled">Next</li>
                  <li class="nav-last-disabled">Last</li>
                  <li class="nav-pagesize">
                    <select
                      name="pageSize"
                      size="1"
                      onchange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')"
                    >
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option selected="selected" value="200">
                        200
                      </option>
                    </select>
                    Items per page
                  </li>
                  <li class="nav-displaying">Displaying 1 - 23 of 23</li>
                </ul>
              </div>
            </div>
          </div>
          <div id="screenlet_2" class="screenlet">
            <div class="screenlet-title-bar">
              <ul>
                <li class="h3">Edit Carrier Shipment Methods</li>
              </ul>
            </div>
            <div id="screenlet_2_col" class="screenlet-body">
              <form
                id="EditCarrierAllocation"
                class="basic-form"
                action="/catalog/control/createCarrierAllocation"
                method="post"
                name="EditCarrierAllocation"
                novalidate="novalidate"
              >
                <table class="basic-table" cellspacing="0">
                  <tbody>
                    <tr>
                      <td class="label">
                        <label
                          id="EditCarrierAllocation_carrierPartyId_title"
                          for="EditCarrierAllocation_carrierPartyId"
                        >
                          Carrier Party Id
                        </label>
                      </td>
                      <td>
                        <select
                          id="EditProduct_productTypeId"
                          name="productTypeId"
                          value={this.state.shipmentMehtodTypeId}
                          disabled={this.state.isDisabled}
                          onChange={(e) =>
                            this.setState({
                              carrierPartyId: e.target.value,
                            })
                          }
                          size="1"
                        >
                          <option value=''>Please Select a Party.....</option>
                          {parties &&
                            parties.map((party) => {
                              return (
                                <option value={party._id}>
                                  {party.userLoginId}
                                </option>
                              );
                            })}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="EditCarrierAllocation_shipmentMethodTypeId_title"
                          for="EditCarrierAllocation_shipmentMethodTypeId"
                        >
                          Allocation Type
                        </label>
                      </td>
                      <td>
                        <select
                          id="EditProduct_productTypeId"
                          name="productTypeId"
                          value={this.state.allocationType}
                          disabled={this.state.isDisabled}
                          onClick={(e) =>
                            this.setState({
                              allocationType: e.target.value,
                            })
                          }
                          size="1"
                        >
                          <option value="ORDER_PERCENTAGE">
                            Order Percentage
                          </option>
                        </select>
                      </td>
                    </tr>

                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr style={{ marginTop: "20px" }}>
                      <td class="label">
                        <label
                          id="EditTimeSlots_leadTimeId_title"
                          for="EditTimeSlots_leadTime"
                        >
                          Allocation
                        </label>
                      </td>
                      <td>
                        <input
                          id="EditTimeSlots_leadTimeId_input"
                          maxlength="250"
                          onChange={(e) => this.handleChange(e)}
                          name="allocation"
                          value={this.state.allocation}
                          size="60"
                          type="text"
                        />
                      </td>
                    </tr>
                    <tr style={{ marginTop: "20px" }}>
                      <td class="label">
                        <label
                          id="EditTimeSlots_leadTimeId_title"
                          for="EditTimeSlots_leadTime"
                        >
                          From Date
                        </label>
                      </td>
                      <td>
                        <TextField
                          id="date"
                          label=""
                          type="datetime-local"
                          defaultValue="2017-05-24"
                          value={this.state.fromDate}
                          onChange={(e) => {
                            this.setState({ fromDate: e.target.value });
                            console.log(e.target.value);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </td>
                    </tr>
                    <tr style={{ marginTop: "20px" }}>
                      <td class="label">
                        <label
                          id="EditTimeSlots_leadTimeId_title"
                          for="EditTimeSlots_leadTime"
                        >
                          Through Date
                        </label>
                      </td>
                      <td>
                        <TextField
                          id="date"
                          label=""
                          type="datetime-local"
                          value={this.state.throughDate}
                          onChange={(e) => {
                            this.setState({ throughDate: e.target.value });
                          }}
                          defaultValue="2017-05-24"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="EditCarrierAllocation_shipmentMethodTypeId_title"
                          for="EditCarrierAllocation_shipmentMethodTypeId"
                        >
                          Geo Type
                        </label>
                      </td>
                      <td>
                        <select
                          id="EditProduct_productTypeId"
                          name="productTypeId"
                          value={this.state.shipmentMehtodTypeId}
                          disabled={this.state.isDisabled}
                          onChange={(e) =>
                            this.setState({
                              geoType: e.target.value,
                            })
                          }
                          size="1"
                        >
                          <option value="1">Delivery Area</option>
                          <option value="2">Locality</option>
                          <option value="3">Postal Code</option>
                        </select>
                      </td>
                    </tr>
                    <tr style={{ marginTop: "20px" }}>
                      <td class="label">
                        <label
                          id="EditTimeSlots_leadTimeId_title"
                          for="EditTimeSlots_leadTime"
                        >
                          Geo Id
                        </label>
                      </td>
                      <td>
                        <input
                          id="EditTimeSlots_leadTimeId_input"
                          class="required"
                          maxlength="250"
                          onChange={(e) => this.handleChange(e)}
                          disabled={this.state.isDisabled}
                          name="geoId"
                          value={this.state.geoId}
                          size="60"
                          type="text"
                        />
                      </td>
                    </tr>
                    <tr style={{ marginTop: "20px" }}>
                      <td class="label">
                        <label
                          id="EditTimeSlots_leadTimeId_title"
                          for="EditTimeSlots_leadTime"
                        >
                          Proiority
                        </label>
                      </td>
                      <td>
                        <input
                          id="EditTimeSlots_leadTimeId_input"
                          class="required"
                          maxlength="250"
                          onChange={(e) => this.handleChange(e)}
                          name="priority"
                          value={this.state.priority}
                          size="60"
                          type="text"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="label"> </td>
                      <td colspan="4">
                        <input
                          class="smallSubmit"
                          name="submitButton"
                          type="submit"
                          onClick={(e) => this.handleSubmit(e)}
                          value="Submit"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
  handleRemove = (e, _id) => {
    this.setState({
      carrierPartyId: "",
      allocationType: "",
      allocation: "",
      fromDate: "",
      throughDate: "",
      geoType: "",
      geoId: "",
      priority: "",
      isDisabled: false,
      data: [],
      _id: "",
    });
    this.props.update({ _id: _id, isActive: false, isDeleted: true });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      _id,
      carrierPartyId,
      allocationType,
      allocation,
      fromDate,
      throughDate,
      geoType,
      geoId,
      priority,
    } = this.state;
    let data = {};
    data = carrierPartyId ? { ...data, carrierPartyId: carrierPartyId } : data;
    data = allocationType ? { ...data, allocationType: allocationType } : data;
    data = allocation ? { ...data, allocation: allocation } : data;
    data = fromDate ? { ...data, fromDate: fromDate } : data;
    data = throughDate ? { ...data, throughDate: throughDate } : data;
    data = geoType ? { ...data, geoType: geoType } : data;
    data = geoId ? { ...data, geoId: geoId } : data;
    data = priority ? { ...data, priority: priority } : data;

    this.setState({
      carrierPartyId: "",
      allocationType: "",
      allocation: "",
      fromDate: "",
      throughDate: "",
      geoType: "",
      geoId: "",
      priority: "",
      isDisabled: false,
    });
    if (_id) {
      data = _id ? { ...data, _id: _id } : data;
      this.setState({ _id: "" });

      this.props.update(data);
      this.props.history.push('/catalog/control/ListCarrierAllocation');
    } else {
      // console.log("this is data before create", data);
      this.props.create(data);
    }
  };
}

const mapStateToProps = (state) => ({
  submitted: state.catalog?.carrierAllocaitonReducer?.submitted,
  carrierAllocation:
    state.catalog?.carrierAllocaitonReducer?.carrier_allocations,
  parties: state.partyManager?.parties?.parties,
});

const mapDispatchToProps = (dispatch, router) => ({
  create: (data) => dispatch(createCarrierAllocation(data, router)),
  allActive: () => dispatch(allActiveCarrierAllocation()),
  update: (data) => dispatch(updateCarrierAllocation(data, router)),
  allActiveParties: () => dispatch(allActiveParties()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListCarrierAllocation)
);
