import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Collapse } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { withRouter, Link } from "react-router-dom";
import RemoveIcon from "@material-ui/icons/Remove";
import { allActiveCountries } from "../../../store/actions/orderActions";

export class senderPopUp extends Component {
  state = {
    email: "",
    name: "",
    phone: "",
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.senderInfo !== this.props.senderInfo) {
      let { name, email, phone } = this.props.senderInfo;
      this.setState({ email, name, phone });
    }
  }
  async componentDidMount() {}
  render() {
    return (
      <div>
        <Modal
          open={this.props.modalOpen}
          onClose={this.props.handleClose}
          //   style={{ overflow: "scroll" }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            style={{
              height: "250px",
              width: "300px",
              backgroundColor: "white",
              position: "absolute",
              top: "50px",
              border: "2px solid white",
              left: "600px",
              //alignItems: "center",
              display: "flex",
              overflow: "scroll",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                backgroundColor: "#527894",
                borderRadius: "5px",
                width: "100%",
                height: "30px",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <CloseIcon
                onClick={(e) => this.props.handleClose(e)}
                style={{
                  color: "#6caad4",
                  marginRight: "5px",
                  backgroundColor: "#e3f1fc",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <span
                style={{
                  display: "flex",
                  marginTop: "10px",
                  marginLeft: "10px",
                  flexDirection: "column",
                }}
              >
                <p>Sender Name*</p>
                <input
                  type="text"
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </span>
              <span
                style={{
                  display: "flex",
                  marginTop: "10px",
                  marginLeft: "10px",
                  flexDirection: "column",
                }}
              >
                <p>Sender email*</p>
                <input
                  type="text"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </span>
              <span
                style={{
                  display: "flex",
                  marginTop: "10px",
                  marginLeft: "10px",
                  flexDirection: "column",
                }}
              >
                <p>Sender Phone*</p>
                <input
                  type="text"
                  value={this.state.phone}
                  onChange={(e) => this.setState({ phone: e.target.value })}
                />
              </span>
              <span
                style={{
                  display: "flex",
                  marginTop: "10px",
                  marginLeft: "10px",
                  flexDirection: "column",
                }}
              >
                <input
                  type="button"
                  value="Submit"
                  onClick={(e) =>
                    this.props.handleSenderSubmit({
                      name: this.state.name,
                      email: this.state.email,
                      phone: this.state.phone,
                    })
                  }
                />
              </span>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch, router) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(senderPopUp)
);
