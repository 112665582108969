import React, {Component} from 'react';
import {connect} from 'react-redux';
import CloseIcon from "@material-ui/icons/Close";
import {Modal} from "@material-ui/core";
import {updateMessage} from "../../../store/actions/orderActions";


function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        updateMessage: (data) => dispatch(updateMessage(data,)),
    };
}

class PersonalizedTextPopUp extends Component {
    state={
        message:'',
        cartId:'',
    }
    submit=()=>{
        this.props.updateMessage({id:this.state.cartId,personalizedText:this.state.message,transection:'Personalized Text Updated'});
        this.props.handleClose(true);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.cart!==this.props.cart){
            this.setState({message:this.props.message,cartId:this.props.cart})
        }
    }

    render() {
        return (
            <Modal
                open={this.props.modalOpen}
                onClose={this.props.handleClose}
                //   style={{ overflow: "scroll" }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div
                    style={{
                        height: "250px",
                        width: "400px",
                        backgroundColor: "white",
                        position: "absolute",
                        top: "50px",
                        border: "2px solid white",
                        left: "600px",
                        //alignItems: "center",
                        display: "flex",
                        overflow: "scroll",
                        flexDirection: "column",
                        alignItems:'center'
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            backgroundColor: "#527894",
                            borderRadius: "5px",
                            width: "100%",
                            height: "30px",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        <CloseIcon
                            onClick={(e) => this.props.handleClose(e)}
                            style={{
                                color: "#6caad4",
                                marginRight: "5px",
                                backgroundColor: "#e3f1fc",
                                borderRadius: "5px",
                                cursor: "pointer",
                            }}
                        />
                    </div>
                    <div style={{width: '96%', display: 'flex', flexDirection: 'column'}}>
                        <label htmlFor={'message'}>Personalized Text:</label>
                        <input
                            id={'message'}
                            type={'text'}
                            maxLength={255}
                            onChange={(e)=>this.setState({message:e.target.value})}
                            value={this.state.message}
                        />
                    </div>
                    <div style={{width: '96%', display: 'flex', alignItems:'center',justifyContent:'flex-start',marginTop:'10px'}}>
                        <button type={"button"} onClick={()=>this.submit()}>Update</button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PersonalizedTextPopUp);