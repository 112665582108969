import React, { Component } from "react";
import { connect } from "react-redux";
import {Link} from "react-router-dom";
import Dispose from "../Dispose";
import {getRequest,putRequest} from "../../../store/services";
import moment from 'moment';

export class OrderNotPaid extends Component {
  constructor() {
    super();
    this.state = {
      orderId:'',
      pncOrders: [],
      pickUpArray:[],
      modalOpen:false
    }
    this.getallpnc = this.getallpnc.bind(this);
  }
  disposeOrder= async(data)=>{
    let dOrder = await putRequest('cart/dispose',data,false);
    this.setState({modalOpen:false});
    this.getallpnc();
  }
  componentDidMount() {
    this.getallpnc();
  }
  async getallpnc() {
    const response = await getRequest(`orders/getallpnc`,null, true,
        { headers: {'access-token': this.props.token}} );
    let pickUpArray=new Array(response.data.length).fill(false);
    this.setState({pncOrders: response.data,pickUpArray})
  }
  disposeClicked=async (orderId)=>{
  await this.setState({orderId:orderId,modalOpen:true})
  }
  onClose=()=>{
    this.setState({modalOpen:false})
  }
  render() {
    const {pncOrders} = this.state;
    return (
      <div id="content-main-section" class="leftonly">
        <Dispose modalOpen={this.state.modalOpen} handleClose={this.onClose} disposeOrder={this.disposeOrder} orderId={this.state.orderId} />
        <div id="centerdiv" class="no-clear">
          <input id="userLoginId" type="hidden" value="mohan@fnp.uae" />{" "}
          <input id="dialerskill" type="hidden" value="OBOUT" />{" "}
          <input id="dialerlistid" type="hidden" value="5" />{" "}
          <input id="leadtype" type="hidden" value="PNC" />{" "}
          <input id="leadName" type="hidden" value="ORDER-NOT-PAID" />{" "}
          <input id="commid" type="hidden" value="ORDERNOTPAID" />{" "}
          <input id="agentID" type="hidden" value="AE-23" />{" "}
          <input id="dialerIp" type="hidden" value="" />{" "}
          <input id="responseUrl" type="hidden" value="" />
          <div id="findOrdersList" class="screenlet orderDetailsStyle">
            <div class="screenlet-title-bar">
              <ul>
                <li class="h3">Payment Not Confirmed List</li>
                <li class="h3">SearchResult:2</li>
              </ul>
            </div>
            <div class="screenlet-body">
              <table class="basic-table hover-bar" cellspacing="0">
                <tbody>
                  <tr class="header-row-2">
                    <td width="5%">Order Date</td>
                    <td width="7%">Order Id</td>
                    <td width="7%">Customer Id</td>
                    <td width="8%">Customer Name</td>
                    <td width="7%">Customer Phone</td>
                    <td width="7%">Sender Phone</td>
                    <td width="5%">Grand Total</td>
                    <td width="5%">Dispose</td>
                    <td width="5%">AgentName</td>
                    <td width="5%">DisposedReason</td>
                    <td width="5%">
                      <a
                        class="sort-order"
                        href="OrderNotPaid?sortField=createdStamp"
                      >
                        CurrentTAT
                      </a>
                    </td>
                    <td width="5%">Created Stamp</td>
                    <td width="5%">LinkSentTime</td>
                  </tr>
                  {pncOrders && pncOrders.length ? pncOrders.map((order,index) => {
                    return [
                      <tr>
                        <td>{moment(order.createdDate).utc(true).format("DD.MM.YYYY HH:mm")}</td>
                        {this.state.pickUpArray[index]?<td><Link to={`/ordermgr/control/orderview?orderId=${order._id}`}>{order._id}</Link></td>:<td></td>}
                        {this.state.pickUpArray[index]?<td>{order.user._id}</td>:<td><button onClick={()=>{
                        let pickUpArray=this.state.pickUpArray
                          pickUpArray[index]=true;
                        this.setState({pickUpArray})
                        }
                        }>Pick up</button></td>}
                        <td>{order.user.name}</td>
                        {this.state.pickUpArray[index]?<td><button onClick={()=>navigator.clipboard.writeText(order.user.mobile)}>{order.user.mobile}</button></td>:<td></td>}
                        {this.state.pickUpArray[index]?<td><button onClick={()=>navigator.clipboard.writeText(order.senderPhone)}>{order.senderPhone}</button></td>:<td></td>}
                        <td>{order.amount}</td>
                        {this.state.pickUpArray[index]?<td><button onClick={()=>this.disposeClicked(order._id)}>Dispose</button></td>:<td></td>}
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{moment(order.createdDate).utc(true).format('DD.MM.YYYY HH:mm')}</td>
                        <td>{moment(order.createdDate).utc(true).format('DD.MM.YYYY HH:mm')}</td>
                      </tr>
                    ];
                  }): (
                      <tr>

                        <td colSpan="13"><h2>!Record Not Found</h2></td>

                      </tr>
                  )
                  }
                </tbody>
              </table>
              <table class="basic-table" cellspacing="0">
                <tbody>
                  <tr>
                    <td> </td>
                    <td align="right"> </td>
                  </tr>
                </tbody>
              </table>
              <div id="responseDialog" title="Notification">
                {" "}
              </div>
            </div>
          </div>
        </div>
        <div class="clear"> </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({token:state.auth.token});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrderNotPaid);
