import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { orderSourceOrders } from "../../../store/actions/orderActions";

const orderStatusList = ["All", "Waiting", "Processing", "Confirmed", "Held"];

export class OrderSource extends Component {
  componentDidMount=async() =>{
   await this.props.orderSourceOrders();
  }

  onSubmitFindOrders = (e) => {
    // e.preventDefault();
  };
  render() {
    const { orders } = this.props;
    return (
      <div id="content-main-section" className="leftonly">
        <div id="centerdiv" className="no-clear">
          <div id="findOrdersList" className="screenlet">
            <div className="screenlet-title-bar">
              <ul>
                <li className="h3">Order Sources List</li>
              </ul>
            </div>
            <div className="screenlet-body">
              <table className="basic-table hover-bar" cellspacing="0">
                <tbody>
                  <tr className="header-row">
                    <td width="10%">Order Source Id</td>
                    <td width="10%">Medium</td>
                    <td width="15%">Channel</td>
                    <td width="10%">Source</td>
                  </tr>
                  { orders &&
                    orders.map((order) => {
                      return (
                        <tr>
                          <td></td>
                          <td>{order.medium}</td>
                          <td>{order.channel}</td>
                          <td>{order.source}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <table className="basic-table" cellspacing="0">
                <tbody>
                  <tr>
                    <td></td>
                    <td align="right"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  orders:state?.orderReducer?.orderSource
});

export default withRouter(
  connect(mapStateToProps, { orderSourceOrders })(OrderSource)
);
