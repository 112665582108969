import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";
import {getProduct, getAllProductPromo, getPromoByID} from "../../../../store/actions/catalogActions";
import {Notify} from "../../../../constants/utils";


class FindProductPromo extends Component {
    render() {
        let {promos = []} = this.props;
        const {id} = this.state;
        return (
            <>
                <div id="screenlet_1" className="screenlet">
                    <div className="screenlet-title-bar">
                        <ul>
                            <li className="h3">Edit Product Promotion Code</li>
                        </ul>
                    </div>
                    <div id="screenlet_1_col" className="screenlet-body">
                        <div className="button-bar">
                            <Link className="buttontext create" to="/catalog/control/NewProductPromo"> New Product Promo </Link>
                        </div>
                        <div>
                            <span className="label">View</span>
                            <Link className="buttontext" to="/catalog/control/FindProductPromo"> All </Link>
                            <Link className="buttontext" to={`/catalog/control/FindProductPromo?userEntered=Y`}> User Entered Only</Link>
                            <Link className="buttontext" to={`/catalog/control/FindProductPromo?userEntered=N`}> Imported Only </Link>
                        </div>
                        <form  className="basic-form" onSubmit={this.onSubmit} name="GoToProductPromoCode">
                            <table className="basic-table" cellSpacing="0">
                                <tbody>
                                <tr>
                                    <td className="label">
                                        <label id="GoToProductPromoCode_productPromoCodeId_title" htmlFor="GoToProductPromoCode_productPromoCodeId">
                                            Product Promo Code Id
                                        </label>
                                    </td>
                                    <td>
                                        <input name="id"
                                               onChange={this.handleChange}
                                               value={id}
                                                type="text"/>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="label"></td>
                                    <td colSpan="4"><input name="submitButton" type="submit" onClick={this.submitForm} value="Edit"/></td>
                                </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                </div>
                <div id="screenlet_2" className="screenlet">
                    <div className="screenlet-title-bar">
                        <ul>
                            <li className="h3">Product Promotions List</li>
                        </ul>
                    </div>
                    <div id="screenlet_2_col" className="screenlet-body">
                        <div className="nav-pager">

                            <ul>
                                <li className="nav-first-disabled">First</li>
                                <li className="nav-previous-disabled">Previous</li>
                                <li className="nav-next-disabled">Next</li>
                                <li className="nav-last-disabled">Last</li>
                                <li className="nav-pagesize">
                                    <select name="pageSize" size="1" onChange="submitPagination(this, '')">
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option selected="selected" value="200">200</option>
                                    </select>
                                    Items per page
                                </li>
                                <li className="nav-displaying">Displaying 1 - 0 of 0</li>
                            </ul>
                        </div>
                        <table className="basic-table" cellSpacing="0">
                            <tbody>
                            <tr className="header-row">
                                <td><label id="ListProductPromos_productPromoId_title" htmlFor="ListProductPromos_productPromoId">Product Promo Id</label></td>
                                <td><label id="ListProductPromos_promoName_title" htmlFor="ListProductPromos_promoName">Promo Name</label></td>
                                <td><label id="ListProductPromos_promoText_title" htmlFor="ListProductPromos_promoText">Promo Text</label></td>
                                <td><label id="ListProductPromos_requireCode_title" htmlFor="ListProductPromos_requireCode">Require Code</label></td>
                                <td><label id="ListProductPromos_createdDate_title" htmlFor="ListProductPromos_createdDate">Created Date</label></td>
                            </tr>
                            {this.renderPromos()}
                            </tbody>
                        </table>
                        <div className="nav-pager">
                            <ul>
                                <li className="nav-first-disabled">First</li>
                                <li className="nav-previous-disabled">Previous</li>
                                <li className="nav-next-disabled">Next</li>
                                <li className="nav-last-disabled">Last</li>
                                <li className="nav-pagesize">
                                    <select name="pageSize" size="1" onChange="submitPagination(this, '')">
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option selected="selected" value="200">200</option>
                                    </select>
                                    Items per page
                                </li>
                                <li className="nav-displaying">Displaying 1 - 0 of 0</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <p>Browse HTML articles <a href="https://html-online.com/articles/">here</a> to get great tips on web
                    editing
                    and other tech-related topics!</p>
            </>
        )
    }

    constructor() {
        super();
        this.state= {
            id:''
        }
    }
    handleChange = (event) => {
        this.setState({id: event.target.value})
    }
    submitForm = async (e) => {
        e.preventDefault();
        if(this.state.id) {
            const data = await this.props.getPromoById(this.state.id);
            if (data.data) {
                this.props.history.push(`/catalog/control/EditProductPromo?promoId=${this.state.id}`);
            }else{
                Notify("danger", "Requested Resource no Found");
            }
        }
    }
    componentDidMount() {
        const url = new URLSearchParams(this.props?.location?.search);
        let params = url?.get("userEntered");
        if (params) {
            this.props.getAllProductPromo(params);
        } else {
            this.props.getAllProductPromo();
        }
    }
    componentWillReceiveProps(nextProps, nextContext) {
    }
    renderPromos= ()=>{
    let {promos = []} = this.props;
    let list = [];
    promos.forEach((item)=>{
        list.push(
            <tr>
                <td><Link to={`/catalog/control/EditProductPromo?promoId=${item._id}`}><input type="button"value={item._id}/></Link></td>
                <td>{item.name}</td>
                <td>{item.promoText}</td>
                <td>{item.reqCode? 'Y' : 'N'}</td>
                <td>{item.createdDate}</td>

            </tr>
        )
    })
        return list;
    }
}

const mapStateToProps = (state) => ({
        submitted: state?.catalog?.promos?.submitted,
        promos: state?.catalog?.promos.promosList,
    });

const mapDispatchToProps = (dispatch, router) => ({
    getAllProductPromo: (params)=>dispatch(getAllProductPromo(params)),
    getPromoById: (id)=>dispatch(getPromoByID(id))
});
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(FindProductPromo)
);

