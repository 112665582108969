import React, { Component } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import {logoutNow} from "../store/actions/authActions";
import { connect } from "react-redux";
// Layout
import TopNav from "./components/TopNav";
import SideBar from "./components/SideBar";
import Footer from "./components/Footer";
// Layout End
// Modules
import Login from "./Login";
import Catalog from "./Catalog";
import Orders from "./Orders";
import PartyManager from "./PartyManager";
import Geo from "./Geo";

class Layout extends Component {
    componentDidMount() {

        let temp = this.getCookie('valid');
        if(temp!==""){

        }
        else{
            localStorage.setItem("route",window.location.pathname+window.location.search);
            this.props.logoutNow()
        }
    }
        componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log(this.porps.history);
        if(prevProps.location.pathname!==this.props.location.pathname && this.props.location.pathname!=="/"){
            let temp = this.getCookie('valid');
            if(temp!==""){

            }
            else{
                localStorage.setItem("route",window.location.pathname+window.location.search);
                this.props.logoutNow()
            }
        }
    }

    setCookie=(cname, cvalue,exdays )=> {
         var d = new Date();
         d.setTime(d.getTime() + (exdays*24*60*60*1000));
         var expires = "expires="+ d.toUTCString();
         document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    getCookie = (cname)=>{
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    render() {
    const { token } = this.props;
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          token !== null ? <Component {...props} /> : <Redirect to="/login" />
        }
      />
    );
    return (
      <div className="page-container">
        <TopNav />
        <div className="contentarea">
          <div id="column-container">
            {token && <SideBar />}
            <Route path="/" component={Login} exact={true} />
            <Route path="/login" component={Login} exact={true} />
            <PrivateRoute
              path="/catalog/:main/:sub"
              component={Catalog}
              exact={true}
            />
            <PrivateRoute
              path="/partymgr/:main/:sub"
              component={PartyManager}
              exact={true}
            />
            <PrivateRoute
              path="/ordermgr/:main/:sub"
              component={Orders}
              exact={true}
            />
            <PrivateRoute
              path="/geomgr/:main/:sub"
              component={Geo}
              exact={true}
            />
          </div>
        </div>
        <div className="clear"></div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state?.auth?.token,
});
const mapDispatchToProps = (dispatch,router)=>({
    logoutNow:()=>dispatch(logoutNow(router))
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
