import React, { Component } from "react";
import Main from "./containers/Main";
import FindRequest from "./containers/FindRequest";
import OrderEntry from "./containers/OrderEntry";
import FindOrders from "./containers/FindOrders";
import FindReturns from "./containers/Returns/FindReturns";
import NewReturn from "./containers/Returns/NewReturn";
import FindRequirements from "./containers/Requirements/FindRequirements";
import EditRequirement from "./containers/Requirements/EditRequirement";
import OrderPurchaseReportOptions from "./containers/Reports/OrderPurchaseReportOptions";
import PNC_PNI_ABC from "./containers/PNC_PNI_ABC";
import OrderNotPaid from "./containers/OrderNotPaid";
import OrderSource from "./containers/OrderSource";
import OrderView from "./containers/OrderView";
import BulkUploadOrders from "./containers/BulkUploadOrders";
import TestPaymentGateways from "./containers/TestPaymentGateways";
import SupervisorDashboard from "./containers/SupervisorDashboard";
import OpenOrderItemsReport from "./containers/Reports/OpenOrderItemsReport";
import CreateNewNote from "./containers/CreateNewNote";
import SubOrderComponent from "./containers/SubOrderComponent";
import PNC from "./containers/PNC_PNI_ABC/PNC";
import RefundOrders from "./containers/RefundOrders";
import OtherOrders from "./containers/OtherOrders";
import OrderHistory from "./containers/OrderHistory";

export default class index extends Component {
  render() {
    const { match } = this.props;
    const { params } = match;
    return <div>{this.container(params.sub)}</div>;
  }

  container = (container) => {
    switch (container) {
      case "main":
        return <Main />;
      case "FindRequest":
        return <FindRequest />;
      case "request":
        return <OrderEntry />;
      case "findorders":
        return <FindOrders />;
      case "orderentry":
        return <OrderEntry />;
      case "findreturn":
        return <FindReturns />;
      case "returnMain":
        return <NewReturn />;
      case "FindRequirements":
        return <FindRequirements />;
      case "EditRequirement":
        return <EditRequirement />;
      case "OrderPurchaseReportOptions":
        return <OrderPurchaseReportOptions />;
      case "pnc":
        return <PNC />;
      case "pni":
        return <PNC_PNI_ABC type={"pni"} />;
      case "abc":
        return <PNC_PNI_ABC type={"abc"} />;
      case "OrderNotPaid":
        return <OrderNotPaid />;
      case "orderview":
        return <OrderView />;
      case "TestPaymentGateways":
        return <TestPaymentGateways />;
      case "ListOrderSource":
        return <OrderSource />;
      case "UploadOrdersInBulk":
        return <BulkUploadOrders />;
      case "SupervisorDashboard":
        return <SupervisorDashboard />;
      case "OpenOrderItemsReport":
        return <OpenOrderItemsReport/>;
      case "createNewNote":
        return <CreateNewNote/>;
      case "refundOrders":
        return <RefundOrders/>
      case "onHold":
        return <SubOrderComponent/>;
      case "allocated":
        return <SubOrderComponent/>;
      case "notAllocated":
        return <SubOrderComponent/>;
      case "accepted":
        return <SubOrderComponent/>;
      case "other-orders":
        return <OtherOrders/>
      case "order-history":
        return <OrderHistory/>;
      default:
        return (
          <div>
            <h2>Not Found ( Container Mismatch )</h2>
          </div>
        );
    }
  };
}
