import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { Notify } from "../../../../constants/utils";
import { useHistory, useLocation } from "react-router";
import {
  createCity,
  updateCity,
} from "../../../../store/actions/catalogActions";

function CreateCity({ mode }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const query = queryString.parse(location.search);
  const cityId = query.cityId;
  const {
    catalog: {
      citiesReducer: { cities, isSubmit },
    },
  } = useSelector((state) => state);
  const [countryId, setCountryId] = useState("");
  const [cityName, setCityName] = useState("");

  useEffect(() => {
    if (cityId) {
      const city = cities.find((city) => city._id === Number(cityId));
      const { name, countryId } = city;
      setCityName(name);
      setCountryId(countryId);
    }
  }, [cityId, cities]);

  const redirectToCityList = () => {
    history.push("/catalog/control/Cities");
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (cityName === "" || countryId === "") {
      Notify("danger", "Please Fill All the required Fields", "Error");
      return;
    }
    const payload = {
      _id: cityId || undefined,
      name: cityName,
      countryId: Number(countryId),
    };
    if (cityId) {
      dispatch(updateCity(payload, () => redirectToCityList()));
    } else {
      dispatch(createCity(payload, () => redirectToCityList()));
    }
  };

  return (
    <div id="content-main-section" className="leftonly">
      <div id="centerdiv" className="no-clear">
        <div className="screenlet">
          <div className="screenlet-title-bar">
            <ul>
              <li className="h3">
                {mode === "add" ? "Create New" : "Update"} City &nbsp;
                {mode === "edit" && `#${cityId}`}
              </li>
            </ul>
          </div>
          <div className="screenlet-body">
            <form onSubmit={submitForm}>
              <table className="basic-table" cellSpacing="0">
                <tbody>
                  <tr>
                    <td className="label" align="right" width="26%">
                      Country Id*
                    </td>
                    <td></td>
                    <td width="74%">
                      <input
                        maxLength="60"
                        name="countryId"
                        size="60"
                        className="required"
                        type="number"
                        value={countryId}
                        onChange={(e) => setCountryId(e.target.value)}
                        min="0"
                      />
                      *
                    </td>
                  </tr>
                  <tr>
                    <td className="label" align="right" width="26%">
                      City Name*
                    </td>
                    <td></td>
                    <td width="74%">
                      <input
                        maxLength="60"
                        name="cityName"
                        size="60"
                        className="required"
                        type="text"
                        value={cityName}
                        onChange={(e) => setCityName(e.target.value)}
                      />
                      *
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2"></td>
                    <td>
                      <input
                        name="Create"
                        type="submit"
                        value={mode === "add" ? "Create" : "Update"}
                        disabled={isSubmit}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateCity;
