import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Notify } from "../../../../constants/utils";
import {
  createMeta,
  updateMeta,
  getMeta,
} from "../../../../store/actions/catalogActions";
function EditMeta(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [mode, setMode] = useState("add");
  const [id, setId] = useState();
  const [fieldName, setFieldName] = useState({
    uniqueKey: "",
    title: "",
    description: "",
  });
  useEffect(() => {
    const url = new URLSearchParams(location?.search);
    const metaId = url?.get("metaId");
    if (metaId) {
      dispatch(getMeta(metaId, getMetaSuccessCb));
    }
  }, [dispatch, location]);

  const getMetaSuccessCb = (data) => {
    setFieldName({
      uniqueKey: data?.uniqueKey,
      title: data?.title,
      description: data?.description,
    });
    setId(data?._id);
    setMode("edit");
  };
  const submitForm = (e) => {
    e.preventDefault();
    const data = fieldName;
    delete data._id;
    Object.keys(data).map((k) => (data[k] = data[k].trim()));
    if (
      data?.title === "" ||
      data?.description === "" ||
      data?.uniqueKey === ""
    ) {
      Notify("danger", "Please Fill All the required Fields", "Error");
      return;
    }
    if (mode === "add") {
      dispatch(createMeta(data, successCb));
    } else {
      data._id = id;
      dispatch(updateMeta(data, successCb));
    }
  };
  const successCb = () => {
    history.push("/catalog/control/FindMeta");
  };
  return (
    <div id="content-main-section" className="leftonly">
      <div id="centerdiv" className="no-clear">
        <div className="screenlet">
          <div className="screenlet-title-bar">
            <ul>
              <li className="h3">{mode === "add" ? "Create" : "Edit"} Meta</li>
            </ul>
          </div>
          <div className="screenlet-body">
            <form onSubmit={submitForm}>
              <table className="basic-table" cellSpacing="0">
                <tbody>
                  <tr>
                    <td className="label" align="right" width="26%">
                      <label>Title*</label>
                    </td>
                    <td width="74%">
                      <input
                        name="title"
                        className="required"
                        type="text"
                        value={fieldName?.title}
                        onChange={(e) =>
                          setFieldName({
                            ...fieldName,
                            title: e.target.value,
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="label" align="right" width="26%">
                      <label>Description*</label>
                    </td>
                    <td width="74%">
                      <textarea
                        name="description"
                        rows="4"
                        cols="25"
                        className="required"
                        value={fieldName?.description}
                        onChange={(e) =>
                          setFieldName({
                            ...fieldName,
                            description: e.target.value,
                          })
                        }
                      >
                        {fieldName?.description}
                      </textarea>
                    </td>
                  </tr>
                  <tr>
                    <td className="label" align="right" width="26%">
                      <label>Unique Key*</label>
                    </td>
                    <td width="74%">
                      <input
                        name="uniqueKey"
                        className="required"
                        type="text"
                        value={fieldName?.uniqueKey}
                        onChange={(e) =>
                          setFieldName({
                            ...fieldName,
                            uniqueKey: e.target.value,
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="1"></td>
                    <td>
                      <input
                        name="Create"
                        type="submit"
                        value={mode === "add" ? "Create" : "Update"}
                        // disabled={submitted}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditMeta;
