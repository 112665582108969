import React, { Component } from "react";
import Main from "./containers/Main";
// Catalog
import FindCatalog from "./containers/catalogs/FindCatalog";
import EditProdCatalog from "./containers/catalogs/EditProdCatalog";
import EditProdCatalogCategories from "./containers/catalogs/EditProdCatalogCategories";
// Category
import FindCategory from "./containers/categories/FindCategory";
import EditCategory from "./containers/categories/EditCategory";
import ProductSequencing from "./containers/categories/ProductSequencing";
import Contents from "./containers/categories/Contents";
// Products
import FindProduct from "./containers/products/FindProduct";
import EditProduct from "./containers/products/EditProduct";
import EditProductContent from "./containers/products/EditProductContent";
import EditProductGoodIdentifications from "./containers/products/EditProductGoodIdentifications";
import EditProductConfig from "./containers/products/EditProductConfig";
import ProductBulkUpload from "./containers/products/ProductBulkUpload";
import FindProductById from "./containers/products/FindProductById";
import SearchView from "./containers/KeywordSearch/SearchView";
import AdvancedSearch from "./containers/KeywordSearch/AdvacedSearch";
import Prices from "./containers/products/Prices";
import EditProductCategories from "./containers/products/EditProductCategories";
import EditProductAssociations from "./containers/products/EditProductAssociations";
import ProductWiseCitiesList from "./containers/products/ProductWiseCitiesList";
//Navigation
import FindNavigation from "./containers/Navigation/FindNavigation";
import EditNavigation from "./containers/Navigation/EditNavigation";
//Meta
import FindMeta from "./containers/Meta/FindMeta";
import EditMeta from "./containers/Meta/EditMeta";
//promo
import FindProductPromo from "./containers/Promo/FindProductPromo";
import EditProductPromo from "./containers/Promo/EditProductPromo";
import ListShipmentMethodTypes from "./containers/shipping/ListShipmentMethodTypes";
import ListCarrierShipmentMethods from "./containers/shipping/ListCarrierShipmentMethods";
import ListTimeSlots from "./containers/shipping/ListTimeSlots";
import ListCarrierAllocation from "./containers/shipping/ListCarrierAllocation";
import NewProductPromo from "./containers/Promo/CreateProductPromo";
import PromoRules from "./containers/Promo/PromoRules";
import PromoStores from "./containers/Promo/PromoStores";
import PromotionCodeEdit from "./containers/Promo/PromotionCodeEdit";
import Content from "./containers/Promo/Content";
import PromoCodes from "./containers/Promo/PromoCodes";

// feature
import EditFeatureType from "./containers/features/EditFeatureTypes";
import EditFeatureCategories from "./containers/features/EditFeatureCategories";
import EditFeatureGroups from "./containers/features/EditFeatureGroups";
import EditFeatureInterActions from "./containers/features/EditFeatureInterActions";
import CreateNewFeature from "./containers/features/CreateNewFeature";
import BulkBlock from "./containers/BulkBlock";
import BlockList from "./containers/BulkBlock/BlockList";
import Feature from "./containers/products/Feature";
import OccasionMessages from "./containers/GiftMessages";

//City
import Cities from "./containers/cities/Cities";
import CreateCity from "./containers/cities/CreateCity";

export default class index extends Component {
  render() {
    const { match } = this.props;
    const { params } = match;
    return <div>{this.container(params.sub)}</div>;
  }

  container = (container) => {
    switch (container) {
      case "main":
        return <Main />;
      case "FindCatalog":
        return <FindCatalog />;
      case "EditProdCatalog":
        return <EditProdCatalog />;
      case "EditProdCatalogCategories":
        return <EditProdCatalogCategories />;
      case "EditProductGoodIdentifications":
        return <EditProductGoodIdentifications />;
      case "EditProductConfig":
        return <EditProductConfig />;
      case "EditProductAssoc":
        return <EditProductAssociations />;
      case "FindCategory":
        return <FindCategory />;
      case "EditCategory":
        return <EditCategory {...this.props} />;
      case "ProductSequencing":
        return <ProductSequencing {...this.props} />;
      case "Contents":
        return <Contents {...this.props} />;
      case "FindProduct":
        return <FindProduct />;
      case "EditProduct":
        return <EditProduct mode={"add"} />;
      case "EditFnpProduct":
        return <EditProduct mode={"edit"} />;
      case "FindProductById":
        return <FindProductById />;
      case "FindProductPromo":
        return <FindProductPromo />;
      case "EditProductPromo":
        return <EditProductPromo />;
      case "EditProductContent":
        return <EditProductContent />;
      case "ProductWiseCitiesList":
        return <ProductWiseCitiesList />;
      case "ProductBulkUpload":
        return <ProductBulkUpload />;
      case "FindNavigation":
        return <FindNavigation />;
      case "AddNavigation":
        return <EditNavigation mode="add" />;
      case "EditNavigation":
        return <EditNavigation mode="edit" />;
      case "FindMeta":
        return <FindMeta />;
      case "EditMeta":
        return <EditMeta />;
      case "SearchView":
        return <SearchView />;
      case "AdvancedSearch":
        return <AdvancedSearch />;
      case "ListShipmentMethodTypes":
        return <ListShipmentMethodTypes />;
      case "Cities":
        return <Cities />;
      case "AddCity":
        return <CreateCity mode="add" />;
      case "EditCity":
        return <CreateCity mode="edit" />;
      case "ListCarrierShipmentMethods":
        return <ListCarrierShipmentMethods />;
      case "EditFeatureCategories":
        return <EditFeatureCategories new={false} />;
      case "CreateProductFeature":
        return <EditFeatureCategories new={true} />;
      case "EditFeatureTypes":
        return <EditFeatureType new={false} />;
      case "EditFeatureType":
        return <EditFeatureType new={true} />;
      case "EditFeatureGroups":
        return <EditFeatureGroups />;
      case "EditFeatureInterActions":
        return <EditFeatureInterActions new={false} />;
      case "EditFeatureInterAction":
        return <EditFeatureInterActions new={true} />;
      case "EditFeatureCategoryFeatures":
        return <CreateNewFeature new={false} />;
      case "CreateFeature":
        return <CreateNewFeature new={true} />;
      case "Prices":
        return <Prices />;
      case "EditProductCategories":
        return <EditProductCategories />;
      case "EditProductFeatures":
        return <Feature />;
      case "ListTimeSlots":
        return <ListTimeSlots />;
      case "BulkBlock":
        return <BulkBlock />;
      case "BlockList":
        return <BlockList />;
      case "ListCarrierAllocation":
        return <ListCarrierAllocation />;
      case "NewProductPromo":
        return <NewProductPromo />;
      case "PromoRules":
        return <PromoRules />;
      case "PromoStores":
        return <PromoStores />;
      case "PromotionCodeEdit":
        return <PromotionCodeEdit />;
      case "Content":
        return <Content />;
      case "PromoCodes":
        return <PromoCodes />;
      case "OccasionMessages":
        return <OccasionMessages />;
      default:
        return (
          <div>
            <h2>Not Found ( Container Mismatch )</h2>
          </div>
        );
    }
  };
}
