import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Modal} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {getAllocateParties,allocateParty} from "../../../store/actions/orderActions";
import {Notify} from "../../../constants/utils";

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        get:(data)=>dispatch(getAllocateParties(data)),
        allocate:data=>dispatch(allocateParty(data))
    };
}

class AllocatePartyPopUp extends Component {
    state={
        parties:[],
        selectedParty:null
    }
    submit=()=>{
        if(!this.state.selectedParty){
            Notify('danger','Please select a party','Error');
            return;
        }
        this.setState({selectedParty:null});
        this.props.allocate({party:this.state.selectedParty,ids:this.props.ids});
        this.props.handleClose(true);
    }
   async componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.ids!==this.props.ids){
            let data = await this.props.get(this.props.ids);
            this.setState({parties:data});
        }
    }

    render() {
        return (
            <Modal
                open={this.props.modalOpen}
                onClose={this.props.handleClose}
                //   style={{ overflow: "scroll" }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div
                    style={{
                        height: "250px",
                        width: "400px",
                        backgroundColor: "white",
                        position: "absolute",
                        top: "50px",
                        border: "2px solid white",
                        left: "600px",
                        //alignItems: "center",
                        display: "flex",
                        overflow: "scroll",
                        flexDirection: "column",
                        alignItems:'center'
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            backgroundColor: "#527894",
                            borderRadius: "5px",
                            width: "100%",
                            height: "30px",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        <CloseIcon
                            onClick={(e) => this.props.handleClose(e)}
                            style={{
                                color: "#6caad4",
                                marginRight: "5px",
                                backgroundColor: "#e3f1fc",
                                borderRadius: "5px",
                                cursor: "pointer",
                            }}
                        />
                    </div>
                    <div style={{width: '96%', display: 'flex', flexDirection: 'column'}}>
                        <label htmlFor={'party'}>Party:</label>
                        <select
                            value={this.state.selectedParty}
                            id={'party'}
                            onChange={(e)=>this.setState({selectedParty:e.target.value})}
                        >
                            <option value={''}></option>
                            {this.state.parties.map((party)=>(
                                <option value={party._id}>{party.name}</option>
                            ))}
                        </select>
                    </div>
                    <div style={{width: '96%', display: 'flex', alignItems:'center',justifyContent:'flex-start',marginTop:'10px'}}>
                        <button type={"button"} onClick={()=>this.submit()}>Allocate</button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default connect(
    mapStateToProps,mapDispatchToProps
)(AllocatePartyPopUp);