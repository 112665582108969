import { getRequest, postRequest, putRequest } from "../services";
import { Notify } from "../../constants/utils";
import { orderReducer } from "../reducers/orderReducer";

export const getOrdersList = (filters) => async (dispatch) => {
  const response = await putRequest("orders/search");
  dispatch({ type: "GET_ORDERS", payload: response.data });
  return response;
};

export const getOrder = (id) => async (dispatch) => {
  const response = await getRequest("orders/get/" + id, {}, false);
  dispatch({ type: "ORDER_VIEW", payload: response.data });
  return response;
};

export const uploadBulkOrders = (data) => async (dispatch, getState) => {
  var token = getState().auth?.token;
  const response = await putRequest("orders/bulkUpload/", data, true, {
    "access-token": token,
  });
  // dispatch({type: 'ORDER_VIEW', payload: response.data});
  return response;
};

export const createOrders = (data) => async (dispatch, getState) => {
  var token = getState().auth?.token;
  console.log(data);

  const response = await postRequest("paymentGateways/create", data, true, {
    "access-token": token,
  });
};

export const updateAddress = (data) => async (dispatch, getState) => {
  var token = getState().auth?.token;
  let user = getState()?.auth?.user;
  data.userId = user._id;

  const response = await putRequest("cart/updateAddressBulk/", data, true, {
    "access-token": token,
  });
  // dispatch({type: 'ORDER_VIEW', payload: response.data});
  return response;
};
export const allActiveCountries = () => async (dispatch, getState) => {
  const token = getState().auth?.token;

  try {
    const res = await getRequest("countries/allActive", true, {
      "access-token": token,
    });

    if (res) {
      return res.data;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    Notify("danger", err.message, "Error");
  }
};

export const getCities = (_id) => async (dispatch, getState) => {
  const token = getState().auth?.token;

  try {
    const res = await getRequest("cities/get/" + _id, true, {
      "access-token": token,
    });

    if (res) {
      return res?.data;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    Notify("danger", err.message, "Error");
  }
};
export const editAddress = (data) => async (dispatch, getState) => {
  var user = getState()?.auth?.user;
  var token = getState().auth?.token;
  data.userId = user._id;
  const response = await putRequest("address/update/" + data.id, data, true, {
    "access-token": token,
  });
  // dispatch({type: 'ORDER_VIEW', payload: response.data});
  console.log(response.data);
};
export const editSender = (data) => async (dispatch, getState) => {
  var token = getState().auth?.token;
  var user = getState().auth?.user;
  data.userId = user?._id;
  const response = await putRequest("orders/update/" + data.id, data, true, {
    "access-token": token,
  });
  // dispatch({type: 'ORDER_VIEW', payload: response.data});
  console.log(response.data);
};
export const getParties = (data) => async (dispatch, getState) => {
  var token = getState().auth?.token;
  const response = await putRequest("parties/getParties/" + data, data, false, {
    "access-token": token,
  });
  // dispatch({type: 'ORDER_VIEW', payload: response.data});
  return response.data;
};

export const setCartsAndParties = (data) => async (dispatch, getState) => {
  var token = getState().auth?.token;
  let user = getState()?.auth?.user;
  data.userId = user._id;
  const response = await putRequest("cart/setCartsAndParties/", data, true, {
    "access-token": token,
  });
  // dispatch({type: 'ORDER_VIEW', payload: response.data});
};
export const setPartyId = (data) => async (dispatch, getState) => {
  var token = getState().auth?.token;
  let user = getState()?.auth?.user;
  data.userId = user._id;
  const response = await putRequest("cart/setPartyId", data, true, {
    "access-token": token,
  });
  // dispatch({type: 'ORDER_VIEW', payload: response.data});
};

export const setOrderPrice = (data) => async (dispatch, getState) => {
  var token = getState().auth?.token;
  const response = await putRequest("orders/updatePrice", data, true, {
    "access-token": token,
  });
  // dispatch({type: 'ORDER_VIEW', payload: response.data});
};
export const getAllRefundOrders = () => async (dispatch, getState) => {
  const response = await putRequest("orders/refundOrders", {}, false);
  if (response.data) {
    dispatch({ type: "REFUND_ORDERS", payload: response.data });
  }
};
export const refundOrder = (_id) => async (dispatch, getState) => {
  const response = await putRequest("orders/refundOrder/" + _id, {}, false);
};
export const cancelOrder = (orderId) => async (dispatch, getState) => {
  const response = await putRequest("orders/cancelOrder/" + orderId, {}, false);
};
export const rejectRefund = (orderId) => async (dispatch, getState) => {
  const response = await putRequest(
    "orders/rejectRefund/" + orderId,
    {},
    false
  );
};
export const orderSourceOrders = () => async (dispatch, getState) => {
  let response = await getRequest("orderSources/allActive", {}, false);
  dispatch({ type: "ORDER_SOURCE", payload: response.data });
};
export const changeHoldStatus =
  (subOrderId, data) => async (dispatch, getState) => {
    let user = getState()?.auth?.user;
    data.userId = user._id;
    const res = await putRequest(
      "orders/changeHoldStatus/" + subOrderId,
      data,
      false
    );
  };
export const getOrdersOnHold = () => async (dispatch) => {
  const res = await getRequest("orders/getOrdersOnHold", {}, false);
  dispatch({ type: "SAVE_SUB_ORDER", payload: res.data });
};
export const getAllocatedOrders = () => async (dispatch) => {
  const res = await getRequest("orders/getAllocated", {}, false);
  dispatch({ type: "SAVE_SUB_ORDER", payload: res.data });
};
export const getAcceptedOrders = () => async (dispatch) => {
  const res = await getRequest("orders/getAccepted", {}, false);
  dispatch({ type: "SAVE_SUB_ORDER", payload: res.data });
};
export const getNotAllocatedOrders = () => async (dispatch) => {
  const res = await getRequest("orders/getOrdersNotAllocated", {}, false);
  dispatch({ type: "SAVE_SUB_ORDER", payload: res.data });
};
export const updateMessage = (data) => async (dispatch, getState) => {
  let user = getState()?.auth?.user;
  data.userId = user._id;
  const res = await putRequest(
    "cart/updateMessageInfo/" + data.id,
    data,
    false
  );
};
export const getImage = (data, router) => async (dispatch) => {
  return putRequest("orders/getImage/", data, false)
    .then((response) => {
      return response.data;
    })
    .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};
export const getAllocateParties = (data) => async (dispatch) => {
  return putRequest("parties/getAllocateParties", data, false).then((res) => {
    return res.data;
  });
};
export const allocateParty = (data) => async (dispatch, getState) => {
  let user = getState().auth?.user;
  data.userId = user?._id;
  await putRequest("parties/allocateParty", data, false);
};
export const actionOtherOrders = (user) => (dispatch, getState) => {
  getRequest("orders/otherOrders/" + user, {}, false)
    .then((res) => {
      dispatch({ type: "OTHER_ORDERS", payload: res.data });
    })
    .catch((error) => {
      Notify("danger", error.message);
    });
};
export const actionSendPaymentLink = (payload) => (dispatch) => {
  putRequest("orders/sendEmail", payload, true)
    .then((res) => {})
    .catch((e) => {
      Notify("danger", e.message);
    });
};
export const actionGetDeliveryBoys = (partyId) => async (dispatch) => {
  let res = await getRequest("deliveryBoys/get/" + partyId, {}, false);
  return res.data;
};
export const actionUpdateDeliveryBoy = (data) => async (dispatch) => {
  let res = await putRequest("orders/updateDeliveryBoy", data, false);
};
export const actionOrderHistory = (sub) => async (dispatch) => {
  let res = await getRequest("orders/orderHistory/" + sub, {}, false);
  dispatch({ type: "SAVE_ORDER_HISTORY", payload: res.data });
};
export const actionGetCities = () => async (disptach) => {
  let cities = await getRequest("cities/get/2", {}, false);
  return cities;
};
export const actionCreateCart = (data) => async (dispatch) => {
  let res = await postRequest("cart/createCartFromAdminPanel", data, false);
};
export const updateProduct = (data) => async (dispatch) => {
  let res = await putRequest("cart/productUpdate", data, false);
};
export const receivePayment = (data) => async (dispatch, getState) => {
  let createdBy = getState()?.auth?.user?.email;
  data.createdBy = createdBy;
  let res = await postRequest("cart/receivePayment", data, false);
};
export const cancel = (cartId) => async (dispatch) => {
  let res = await putRequest("cart/cancel/" + cartId, {}, false);
};

export const searchCustomer = (query) => async (dispatch, getState) => {
  const token = getState().auth?.token;
  try {
    const res = await getRequest(
      "users/searchUser?search_query=" + query,
      null,
      false,
      {
        "access-token": token,
      }
    );
    dispatch({ type: "GET_CUSTOMER", payload: res?.data || [] });
  } catch (err) {
    console.log(err);
    Notify("danger", err.message, "Error");
  }
};
