import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../services";

export const getNavigationList = () => async (dispatch) => {
  dispatch({ type: "GET_NAVIGATION_LIST_REQUEST" });
  return getRequest("navigation/admin")
    .then((response) => {
      console.log("getNavigationList", response);
      dispatch({
        type: "GET_NAVIGATION_LIST_SUCCESS",
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: "GET_NAVIGATION_LIST_FAILURE",
        payload: "Something Went Wrong",
      });
      throw error;
    });
};

export const addNavigationDetails =
  (data, callback) => (dispatch, getState) => {
    const token = getState().auth?.token;
    dispatch({ type: "ADD_NAVIGATION_REQUEST" });
    return postRequest("navigation/admin", data, true, {
      "access-token": token,
    })
      .then((response) => {
        dispatch({
          type: "ADD_NAVIGATION_SUCCESS",
          payload: response.data,
        });
        callback();
      })
      .catch((error) => {
        dispatch({
          type: "ADD_NAVIGATION_FAILURE",
          payload: "Something Went Wrong",
        });
        throw error;
      });
  };

export const updateNavigationDetails = (id, data, callback) => (dispatch) => {
  dispatch({ type: "UPDATE_NAVIGATION_DETAILS_REQUEST" });
  return putRequest(`navigation/admin/${id}`, data, true)
    .then((response) => {
      dispatch({
        type: "UPDATE_NAVIGATION_DETAILS_SUCCESS",
        payload: response.data,
      });
      callback();
    })
    .catch((error) => {
      dispatch({
        type: "UPDATE_NAVIGATION_DETAILS_FAILURE",
        payload: "Something Went Wrong",
      });
      throw error;
    });
};

export const deleteNavigation = (id) => (dispatch, getState) => {
  const token = getState().auth?.token;
  dispatch({ type: "DELETE_NAVIGATION_REQUEST" });
  return deleteRequest(`navigation/admin/${id}`, null, true, {
    "access-token": token,
  })
    .then((response) => {
      dispatch(getNavigationList());
      dispatch({
        type: "DELETE_NAVIGATION_SUCCESS",
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: "DELETE_NAVIGATION_FAILURE",
        payload: "Something Went Wrong",
      });
      throw error;
    });
};
