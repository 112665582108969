import React, { Component } from "react";
import { connect } from "react-redux";
import { loginNow } from "../../store/actions/authActions";

export class index extends Component {
  componentDidMount() {
    const { token } = this.props;
    token && this.props.history.push("/catalog/control/main");
  }
  render() {
    const { email, password } = this.state;
    const { submitted } = this.props;
    return (
      <div id="content-main-section">
        <center>
          <div className="screenlet login-screenlet">
            <div className="screenlet-title-bar">
              <h3>Registered User</h3>
            </div>
            <div className="screenlet-body">
              <form onSubmit={(e) => this.handleSubmit(e)}>
                <table className="basic-table" cellSpacing="0">
                  <tbody>
                    <tr>
                      <td className="label">User Name</td>
                      <td>
                        <input
                          onChange={(e) => this.handleChange(e)}
                          type="text"
                          value={email}
                          size="20"
                          name="email"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">Password</td>
                      <td>
                        <input
                          onChange={(e) => this.handleChange(e)}
                          type="password"
                          value={password}
                          size="20"
                          name="password"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" align="center">
                        <input
                          type="submit"
                          value="Login"
                          // disabled={submitted}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <input type="hidden" name="JavaScriptEnabled" value="Y" />
                <br />
                <a href="/catalog/control/forgotPassword">
                  Forgot Your Password?
                </a>
              </form>
            </div>
          </div>
        </center>

        <script language="JavaScript" type="text/javascript">
          document.loginform.JavaScriptEnabled.value = "Y";
          document.loginform.PASSWORD.focus();
        </script>
      </div>
    );
  }
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
  }
  handleChange = (e) => {
    var value = e.target.value;
    var key = e.target.name;
    this.setState({ [key]: value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.loginNow(this.state);
  };
}

const mapStateToProps = (state) => ({
  submitted: state.auth?.submitted,
  token: state.auth?.token,
});

const mapDispatchToProps = (dispatch, router) => {
  return {
    loginNow: (data) => dispatch(loginNow(data, router)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
