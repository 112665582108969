import React from "react";
import EditProductNav from "./EditProductNav";
import queryString from "query-string";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getAssociation, createAssociation, updateAssociation,getAssociationToThis,updateAssociationTo} from "../../../../store/actions/catalogActions";
import moment from "moment";

class EditProductAssociations extends React.Component {

    componentDidMount() {
        const query = queryString.parse(this.props.location.search);
        const product = query.productId;
        this.props.getAssociation(product);
        this.props.getAssociationToThis(product);
    }

    state = {
        productIdTo: null,
        associationTypeId: 'Accessory',
        from: null,
        to: null,
        sequence: null,
        reason: null,
        instruction: null,
        quantity: null,
        mode: 'create'
    }
    editAss = (index) => {
        let ass = this.props.associations[index];
        let productIdTo = ass._id;
        let associationTypeId = ass.associations.associationType;
        let {from,to,sequence,reason,instruction,quantity} = ass.associations;
        from = moment(
            from
        )
            .utc(true)
            .format("Y-MM-DD");
        to=moment(
            to
        )
            .utc(true)
            .format("Y-MM-DD");
        this.setState({productIdTo,associationTypeId,from,to,sequence,reason,instruction,quantity,mode:'edit'})
    }
    delete=async (index)=>{
        const query = queryString.parse(this.props.location.search);
        const product = query.productId;
        let obj={isActive:false,isDeleted:true,virtualProduct:product,variantProduct:this.props.associations[index]._id};
        await this.props.updateAssociation(obj)
    }
    deleteTo=async(index)=> {
       let a =  this.props.associationsTo[index];
       let {variantProduct,virtualProduct} = a.associations;
        let obj={isActive:false,isDeleted:true,virtualProduct:virtualProduct,variantProduct:variantProduct};
        await this.props.updateAssociationTo(obj)
    }

    submitForm = async (e) => {
        const query = queryString.parse(this.props.location.search);
        const product = query.productId;
        e.preventDefault();
        let obj = {};
        obj.variantProduct = this.state.productIdTo;
        obj.virtualProduct = parseInt(product);
        obj.sequence = this.state.sequence;
        obj.from = this.state.from;
        obj.to = this.state.to;
        obj.instruction = this.state.instruction;
        obj.quantity = this.state.quantity;
        obj.associationType = this.state.associationTypeId;
        obj.reason = this.state.reason;
        if (this.state.mode === 'edit') {
            await this.props.updateAssociation(obj);
            this.setState({
                productIdTo: null,
                associationTypeId: 'Accessory',
                from: null,
                to: null,
                sequence: null,
                reason: null,
                instruction: null,
                quantity: null,
                mode: 'create'
            })
        } else {
            await this.props.createAssociation(obj);
        }
    }

    render() {
        const query = queryString.parse(this.props.location.search);
        const product = query.productId;
        return (
            <React.Fragment>
                <div id="content-main-section" class="leftonly">
                    <div id="centerdiv" class="no-clear">
                        <EditProductNav product={product}/>
                        <div id="screenlet_1" class="screenlet">
                            <div class="screenlet-title-bar">
                                <ul>
                                    <li class="h3">Edit Product Associations</li>
                                </ul>
                            </div>
                            <div id="screenlet_1_col" className="screenlet-body">
                                <table className="basic-table" cellSpacing="0">
                                    <tbody>
                                    <tr>
                                        <td className="label">
                                            <label
                                                id="AddProductContentAssoc_contentId_title"
                                                htmlFor="AddProductContentAssoc_contentId"
                                            >
                                                Product Id To
                                            </label>
                                        </td>

                                        <td>
                                            <input
                                                id="3_lookupId_AddProductContentAssoc_contentId"
                                                className="ui-autocomplete-input"
                                                autoComplete="off"
                                                className="required"
                                                name="productIdTo"
                                                value={this.state.productIdTo}
                                                onChange={(e) => this.setState({productIdTo: e.target.value})}
                                                size="25"
                                                type="text"
                                                disabled={this.state.mode==="edit"?true:false}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="label">
                                            <label
                                                id="AddProductContentAssoc_contentId_title"
                                                htmlFor="AddProductContentAssoc_contentId"
                                            >
                                                Association Type Id
                                            </label>
                                        </td>

                                        <td>
                                            <select
                                                className="required"
                                                name="associationTypeId"
                                                disabled={this.state.mode==="edit"?true:false}
                                                value={this.state.associationTypeId}
                                                onChange={(e) => this.setState({associationTypeId: e.target.value})}
                                            >
                                                <option value={'Accessory'}>Accessory</option>
                                                <option value={'Actual Product Component'}>Actual Product Component
                                                </option>
                                                <option value={'Also Bought'}>Also Bought</option>
                                                <option value={'Alternative Packaging'}>Alternative Packaging</option>
                                                <option value={'Auto Reorder'}>Auto Reorder (needs recurrenceInfold)
                                                </option>
                                                <option value={'Complementary or Cross-Sell'}>Complementary or
                                                    Cross-Sell
                                                </option>
                                                <option value={'Configurable product instance'}>Configurable product
                                                    instance
                                                </option>
                                                <option value={'Engineering Bill of Materials'}>Engineering Bill of
                                                    Materials
                                                </option>
                                                <option value={'Equivalent or Substitute'}>Equivalent or Substitute
                                                </option>
                                                <option value={'Incompatable'}>Incompatable</option>
                                                <option value={'Manufacturing Bill of Materials'}>Manufacturing Bill of
                                                    Materials
                                                </option>
                                                <option value={'New Version, Replacement'}>New Version, Replacement
                                                </option>
                                                <option value={'Product Bundle & Product Combo'}>Product Bundle &
                                                    Product Combo
                                                </option>
                                                <option value={'Product Manufactured As'}>Product Manufactured As
                                                </option>
                                                <option value={'Product Variant'}>Product Variant</option>
                                                <option value={'Refurbished Equivalent'}>Refurbished Equivalent</option>
                                                <option value={'Repair Service'}>Repair Service</option>
                                                <option value={'Revision'}>Revision</option>
                                                <option value={'Unique Item'}>Unique Item</option>
                                                <option value={'Upgrade or Up-Sell'}>Upgrade or Up-Sell</option>
                                            </select>
                                        </td>
                                    </tr>


                                    <tr>
                                        <td className="label">
                                            <label
                                                id="AddProductContentAssoc_contentId_title"
                                                htmlFor="AddProductContentAssoc_contentId"
                                            >
                                                From Date
                                            </label>
                                        </td>

                                        <td>
                                            <input
                                                id="3_lookupId_AddProductContentAssoc_contentId"
                                                className="ui-autocomplete-input"
                                                autoComplete="off"
                                                className="required"
                                                name="from"
                                                size="25"
                                                type="date"
                                                disabled={this.state.mode==="edit"?true:false}
                                                value={this.state.from}
                                                onChange={(e) => this.setState({from: e.target.value})}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="label">
                                            <label
                                                id="AddProductContentAssoc_contentId_title"
                                                htmlFor="AddProductContentAssoc_contentId"
                                            >
                                                To Date
                                            </label>
                                        </td>

                                        <td>
                                            <input
                                                id="3_lookupId_AddProductContentAssoc_contentId"
                                                className="ui-autocomplete-input"
                                                autoComplete="off"
                                                className="required"
                                                name="contentId"
                                                size="25"
                                                type="date"
                                                value={this.state.to}
                                                onChange={(e) => this.setState({to: e.target.value})}

                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="label">
                                            <label
                                                id="AddProductContentAssoc_contentId_title"
                                                htmlFor="AddProductContentAssoc_contentId"
                                            >
                                                Sequence Num
                                            </label>
                                        </td>

                                        <td>
                                            <input
                                                id="3_lookupId_AddProductContentAssoc_contentId"
                                                className="ui-autocomplete-input"
                                                autoComplete="off"
                                                className="required"
                                                name="contentId"
                                                size="25"
                                                type="text"
                                                value={this.state.sequence}
                                                onChange={(e) => this.setState({sequence: e.target.value})}

                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="label">
                                            <label
                                                id="AddProductContentAssoc_contentId_title"
                                                htmlFor="AddProductContentAssoc_contentId"
                                            >
                                                Reason
                                            </label>
                                        </td>

                                        <td>
                                            <input
                                                id="3_lookupId_AddProductContentAssoc_contentId"
                                                className="ui-autocomplete-input"
                                                autoComplete="off"
                                                className="required"
                                                name="contentId"
                                                size="25"
                                                type="text"
                                                value={this.state.reason}
                                                onChange={(e) => this.setState({reason: e.target.value})}

                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="label">
                                            <label
                                                id="AddProductContentAssoc_contentId_title"
                                                htmlFor="AddProductContentAssoc_contentId"
                                            >
                                                Instruction
                                            </label>
                                        </td>

                                        <td>
                                            <input
                                                id="3_lookupId_AddProductContentAssoc_contentId"
                                                className="ui-autocomplete-input"
                                                autoComplete="off"
                                                className="required"
                                                name="contentId"
                                                size="25"
                                                type="text"
                                                value={this.state.instruction}
                                                onChange={(e) => this.setState({instruction: e.target.value})}

                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="label">
                                            <label
                                                id="AddProductContentAssoc_contentId_title"
                                                htmlFor="AddProductContentAssoc_contentId"
                                            >
                                                Quantity
                                            </label>
                                        </td>

                                        <td>
                                            <input
                                                id="3_lookupId_AddProductContentAssoc_contentId"
                                                className="ui-autocomplete-input"
                                                autoComplete="off"
                                                className="required"
                                                name="contentId"
                                                size="25"
                                                type="number"
                                                value={this.state.quantity}
                                                onChange={(e) => this.setState({quantity: e.target.value})}

                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            <button onClick={(e) => this.submitForm(e)}>
                                                {this.state.mode === "create" ? "Create" : "Save"}
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="screenlet-title-bar">
                                <ul>
                                    <li className="h3">Associations from this product to</li>
                                </ul>
                            </div>
                            <div id="screenlet_1_col" className="screenlet-body">
                                <table className="basic-table" cellSpacing="0">
                                    <thead>
                                    <th>ProductId</th>
                                    <th width={'20%'}>Name</th>
                                    <th>From Date</th>
                                    <th>Through Date</th>
                                    <th>Seq. Num</th>
                                    <th>Quantity</th>
                                    <th>Association Type</th>
                                    <th></th>
                                    <th></th>
                                    </thead>
                                    <tbody>
                                    {this.props.associations.map((ass, index) => (
                                        <tr>
                                            <td>
                                                <button type={'button'}><a
                                                    href={'/catalog/control/EditFnpProduct?productId=' + ass._id}>{ass._id}</a>
                                                </button>
                                            </td>
                                            <td>
                                                <button type={'button'}><a
                                                    href={'/catalog/control/EditFnpProduct?productId=' + ass._id}>{ass.title}</a>
                                                </button>
                                            </td>
                                            <td>{ass.associations.from}</td>
                                            <td>{ass.associations.to}</td>
                                            <td>{ass.associations.sequence}</td>
                                            <td>{ass.associations.quantity}</td>
                                            <td>{ass.associations.associationType}</td>
                                            <td>
                                                <button type={'button'} onClick={(e) => this.editAss(index)}>Edit
                                                </button>
                                            </td>
                                            <td>
                                                <button type={"button"} onClick={(e) => this.delete(index)}>Delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))}

                                    </tbody>
                                </table>
                            </div>
                            <div className="screenlet-title-bar">
                                <ul>
                                    <li className="h3">Associations To this product From</li>
                                </ul>
                            </div>
                            <div id="screenlet_1_col" className="screenlet-body">
                                <table className="basic-table" cellSpacing="0">
                                    <thead>
                                    <th>ProductId</th>
                                    <th width={'20%'}>Name</th>
                                    <th>From Date</th>
                                    <th>Through Date</th>
                                    <th>Association Type</th>
                                    <th></th>
                                    </thead>
                                    <tbody>
                                    {this.props.associationsTo.map((ass,index)=>(
                                        <tr>
                                            <td>
                                                <button type={'button'}><a
                                                    href={'/catalog/control/EditFnpProduct?productId=' + ass._id}>{ass._id}</a>
                                                </button>
                                            </td>
                                            <td>
                                                <button type={'button'}><a
                                                    href={'/catalog/control/EditFnpProduct?productId=' + ass._id}>{ass.title}</a>
                                                </button>
                                            </td>
                                            <td>{ass.associations.from}</td>
                                            <td>{ass.associations.to}</td>
                                            <td>{ass.associations.associationType}</td>
                                            <td>
                                                <button type={"button"} onClick={(e) => this.deleteTo(index)}>Delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    associations: state?.catalog?.associationsReducer?.associations,
    associationsTo: state?.catalog?.associationsReducer?.associationsTo,
});

const mapDispatchToProps = (dispatch, router) => ({
    getAssociation: (id) => dispatch(getAssociation(id, router)),
    createAssociation: (data) => dispatch(createAssociation(data, router)),
    updateAssociation: (data) => dispatch(updateAssociation(data, router)),
    getAssociationToThis:(id)=>dispatch(getAssociationToThis(id,router)),
    updateAssociationTo:(data)=>dispatch(updateAssociationTo(data,router))
    // empty:()=>dispatch(dispatch({type:'ALL_ASSOCIATIONS',payload:[]})),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(EditProductAssociations)
);
