import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {getRequest} from "../../../store/services";
import EditGiftMessage from "./EditGiftMessage";
import moment from "moment";
import "../styles.scss";
import JsBarcode from "jsbarcode";
import {createCanvas} from "canvas";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {postRequest} from "../../../store/services";
import imag from "../../../assets/images/siteLogo.jpg";
import {
    getOrder,
    updateAddress,
    editAddress,
    editSender,
    setCartsAndParties,
    getParties,
    setPartyId,
    setOrderPrice,
    getImage,
    updateMessage
} from "../../../store/actions/orderActions";
import {
    allActiveCountries,
    getCities,
    cancelOrder,
    changeHoldStatus,
    actionSendPaymentLink
} from "../../../store/actions/orderActions";
import RefundPopUp from "../RefundPopUp";

import PopUp from "./AddressPopUp";
import SenderPopUp from "./senderPopUp";
import DeliverySlotPopUp from "./DeliverySlotPopUp";
import AllocatePartyPopUp from "./AllocatePartyPopUp";
import CakeMessagePopUp from "./CakeMessagePopUp";
import PersonalizedTextPopUp from "./PersonalizedTextPopUp";
import OutForDeliveryPopUp from "./OutForDeliveryPopUp";
import EditItemsPopUp from "./EditItems";
import ReceivePayment from "./ReceivePayment";
import {Notify} from "../../../constants/utils";
var message = [
    {
        fillColor: "#f0f8ff",

        columns: [
            {text: ""},
            {
                margin: [20, 20],
                table: {
                    heights: [20, 150, 10, 20],
                    body: [
                        ["barney"],
                        [
                            "\nWishing you a speedy and full recovery <3 Hope you feel much better very soon. P.S it's super boring without you!",
                        ],
                        ["From"],
                        ["ABC"],
                    ],
                },
                layout: "noBorders",
            },
        ],
    },
];
var logoRow = [
    {
        columns: [
            {
                margin: [20, 20],
                image: "sampleImage.jpg",
                width: 100,
                height: 40,
            },
            {text: ""},
            {
                margin: [0, 20, 20, 20],
                image: "sampleImage.jpg",
                width: 100,
                height: 40,
            },
        ],
    },
];
var addressRow = [
    {
        columns: [
            {
                margin: [30, 20, 20, 20],
                text: [
                    {text: "Deliver To", bold: true},
                    {text: "\nTo: "},
                    {text: "Barny Shaw"},
                    {text: "\nApartment 102, 55 al ramith,"},
                    {text: "\nremraam Dubai uae"},
                    {text: "\nDubai"},
                    {text: "\n03075861606"},
                ],
            },
            {
                margin: [50, 20, 0, 20],
                text: [
                    {text: "\nOrder#: ", bold: true},
                    {text: "66287101"},
                    {text: "\nOrder Date: ", bold: true},
                    {text: "25-11-2020"},
                    {text: "\nDelivery Date: ", bold: true},
                    {text: "25-11-2020"},
                    {text: "\nShipping: ", bold: true},
                    {text: "Standard_Delivery"},
                    {text: "\nTime: ", bold: true},
                    {text: "15:00:00 - 19:00:00"},
                ],
            },
        ],
    },
];
var productRow = [
    {
        table: {
            widths: [120, 100, 150, "*", 50],
            body: [
                [
                    {text: "Product Image", bold: true},
                    {text: "Product", bold: true},
                    {text: "Product Description", bold: true},
                    {text: "Uploaded Image", bold: true},
                    {text: "Quantity", bold: true},
                ],
            ],
        },
        layout: {
            hLineWidth: function (i, node) {
                return i === 0 || i === node.table.body.length ? 1 : 1;
            },
            vLineWidth: function (i, node) {
                return i === 0 || i === node.table.widths.length ? 0 : 1;
            },
            hLineColor: function (i, node) {
                return i === 0 || i === node.table.body.length ? "black" : "black";
            },
            vLineColor: function (i, node) {
                return i === 0 || i === node.table.widths.length ? "black" : "black";
            },
            // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            // paddingLeft: function(i, node) { return 4; },
            // paddingRight: function(i, node) { return 4; },
            // paddingTop: function(i, node) { return 2; },
            // paddingBottom: function(i, node) { return 2; },
            // fillColor: function (rowIndex, node, columnIndex) { return null; }
        },
    },
];
var senderRow = [
    {
        margin: [30, 20],
        text: [{text: "Sender: "}, {text: "Nagla"}],
    },
];
var external = {
    hLineWidth: function (i, node) {
        //	return (i !== 1 || i===2   ) ? 0 : 1;
        return i === 1 || i === 2 ? 1 : 0;
    },
    vLineWidth: function (i, node) {
        return i === 0 || i === node.table.widths.length ? 0 : 1;
    },
    hLineColor: function (i, node) {
        return "no";
    },
    vLineColor: function (i, node) {
        return "black";
    },
    hLineStyle: function (i, node) {
        // if (i !== 1) {
        //     return null;
        // }
        // return {dash: {length: 5, space: 4}};
    },
    vLineStyle: function (i, node) {
        if (i === 0 || i === node.table.widths.length) {
            return null;
        }
        return {dash: {length: 4}};
    },
};
var internal = {
    hLineWidth: function (i, node) {
        return i === 0 || i === node.table.body.length ? 1 : 0;
    },
    vLineWidth: function (i, node) {
        return i === 0 || i === node.table.widths.length ? 0 : 1;
    },
    hLineColor: function (i, node) {
        return i === 0 || i === node.table.body.length ? "black" : "black";
    },
    vLineColor: function (i, node) {
        return i === 0 || i === node.table.widths.length ? "black" : "black";
    },
};
var cc = {
    pageMargins: [0, 0, 0, 0],
    content: [],
    styles: {
        header: {
            fillColor: "gray",
        },
    },
};
let contentObj = {
    table: {
        // heights: [250],
        widths:['*'],
        body: [],
    },
    layout: {
        hLineWidth: function (i, node) {
            //	return (i !== 1 || i===2   ) ? 0 : 1;
            return i === 1 || i === 2 ? 1 : 0;
        },
        vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 0 : 1;
        },
        hLineColor: function (i, node) {
            return "no";
        },
        vLineColor: function (i, node) {
            return "black";
        },
        hLineStyle: function (i, node) {
            if (i !== 1) {
                return null;
            }
            return {dash: {length: 5, space: 4}};
        },
        vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
                return null;
            }
            return {dash: {length: 4}};
        },
        // paddingLeft: function(i, node) { return 4; },
        // paddingRight: function(i, node) { return 4; },
        // paddingTop: function(i, node) { return 2; },
        // paddingBottom: function(i, node) { return 2; },
        // fillColor: function (i, node) { return null; }
    },
};
var watermark = {
    watermark: { text: 'COD', color: 'red', opacity: 0.3, bold: true, italics: false },
}
var Amount = [
    { text: "Amount: ", bold: true },
    { text: "100",
        bold:true,
        color:'#FF0000',
        fontSize:20},
]

class OrderView extends Component {
    quantityObj(quantity) {
        let obj = {};
        obj.margin = [10, 40];
        obj.alignment = "center";
        obj.text = quantity;
        obj.fontSize = 20;
        obj.bold = true;
        obj.color='#FF0000'
        return obj;
    }

    async imageObj(url) {
        let obj = {};
        obj.width = 100;
        obj.height = 60;
        obj.margin = [10, 20];
        if (url) {
            let d = await this.convertUrl(url);
            obj.image = d;
            return obj;
        } else {
            obj.text = "";
            return obj;
        }
    }

    description(des) {
        let obj = {};
        obj.margin = [0, 20];
        obj.fontSize = 10;
        if (des) {
            obj.text = des;
            return obj;
        } else {
            obj.text = "";
            return obj;
        }
    }

    productName(name) {
        let obj = {};
        obj.margin = [0, 20];
        obj.fontSize = 10;
        if (name) {
            obj.text = name;
            return obj;
        } else {
            obj.text = "";
            return obj;
        }
    }

    async productImg(url) {
        let obj = {};
        obj.width = 120;
        obj.height = 100;
        // obj.margin = [10, 20];
        if (url) {
            let d = await this.convertUrl(url);
            obj.image = d;
            return obj;
        } else {
            obj.text = "";
            return obj;
        }
    }

    async makeRow(productUrl, name, desc, imageUrl, quantity) {
        let array = [];
        let productObj = await this.productImg(productUrl);
        let productName = this.productName(name);
        let des = this.description(desc);
        let imageObj = await this.imageObj(imageUrl);
        let qty = this.quantityObj(quantity);
        array.push(productObj);
        array.push(productName);
        array.push(des);
        array.push(imageObj);
        array.push(qty);
        return array;
    }
    printChallan = async (dataObj) => {
        let lRow = JSON.parse(JSON.stringify(logoRow));
        let addRow = JSON.parse(JSON.stringify(addressRow));
        let pRow = JSON.parse(JSON.stringify(productRow));
        let sendRow = JSON.parse(JSON.stringify(senderRow));

        let content = JSON.parse(JSON.stringify(contentObj));

        const canvas = createCanvas(500, 500);
        JsBarcode(canvas, dataObj.orderId);
        content.layout = external;
        lRow[0].columns[2].image = canvas.toDataURL();
        //populating siteLogo
        let data = await this.convert(imag);
        lRow[0].columns[0].image = data;

        //populating orderDetails
        addRow[0].columns[1].text[1].text = dataObj.orderId;
        addRow[0].columns[1].text[3].text = moment(dataObj.orderDate)
            .utc(true)
            .format("Y-MM-DD");
        addRow[0].columns[1].text[5].text = moment(dataObj.shippingDate)
            .utc(true)
            .format("Y-MM-DD");
        addRow[0].columns[1].text[7].text = dataObj.shippingType;
        addRow[0].columns[1].text[9].text = dataObj.shippingTime;
        //populating delivery detail
        addRow[0].columns[0].text[2].text = dataObj.deliveryinfo?.name;
        addRow[0].columns[0].text[3].text =
            "\n" + dataObj.deliveryinfo?.recipientAddress;
        addRow[0].columns[0].text[4].text = "\n" + dataObj.deliveryinfo?.landmark;
        addRow[0].columns[0].text[5].text =
            "\n" + dataObj.deliveryinfo?.recipientCity;
        addRow[0].columns[0].text[6].text = "\n" + dataObj.deliveryinfo?.phone;

        pRow[0].layout = internal;
        sendRow[0].text[1].text = dataObj.senderName;
        let mRow = {};
        //populating productInfo
        return new Promise(async (resolve, reject) => {
            for (let i = 0; i < dataObj.products.length; i++) {
                mRow = JSON.parse(JSON.stringify(message));
                let qty = dataObj.products[i].quantity;
                let pImg = dataObj.products[i].pImg;
                let pName = dataObj.products[i].name;
                let des = dataObj.products[i].description;
                let uImg = dataObj.products[i].image;
                if (dataObj.products[i].giftCard) {
                    mRow[0].columns[1].table.body[0][0] =
                        dataObj.products[i].giftCard_receiver;
                    mRow[0].columns[1].table.body[1][0] =
                        dataObj.products[i].giftCard_message;
                    mRow[0].columns[1].table.body[3][0] =
                        dataObj.products[i].giftCard_sender;
                    // content.table.body.push(mRow);
                }
                let arr = await this.makeRow(pImg, pName, des, uImg, qty);
                pRow[0].table.body.push(arr);
            }
            if (content.table.body.length < 1) {
                mRow[0].columns[1].table.body[0][0] = "";
                mRow[0].columns[1].table.body[1][0] = "";
                mRow[0].columns[1].table.body[3][0] = "";
                // content.table.body.push(mRow);
            }
            if(dataObj.amount+dataObj.shipping>dataObj.paidAmount){
                let AmountRow = JSON.parse(JSON.stringify(Amount));
                AmountRow[1].text = dataObj.amount+dataObj.shipping-dataObj.paidAmount;
                addRow[0].columns[1].text.unshift(AmountRow[1]);
                addRow[0].columns[1].text.unshift(AmountRow[0]);
            }
            content.table.body.push(lRow);
            content.table.body.push(addRow);
            content.table.body.push(pRow);
            // content.table.body.push(sendRow);
            resolve(content);
        });
    };
    convertUrl = async (url) => {
        let res = await this.props.getImage({url: url});
        let newD = res;
        return newD;
    };
    convert = (image) => {
        return new Promise((resolve, reject) => {
            var img = new Image();
            img.src = image;
            img.onload = function () {
                var canvas = document.createElement("canvas"),
                    context = canvas.getContext("2d");
                canvas.width = img.width;
                canvas.height = img.height;
                context.drawImage(img, 0, 0, img.width, img.height);
                const data = canvas.toDataURL("image/png");
                resolve(data);
            };
        });
    };
    getTextSort=(direction, v1, v2)=> {
        if (v1 !== undefined && v2 !== undefined) {
            if (typeof v1 === "string" && typeof v2 === "string") {
                v1 = v1.trim();
                v2 = v2.trim();
                v1 = v1.toLowerCase();
                v2 = v2.toLowerCase();
                v1 = v1.length === 0 ? " " : v1;
                v2 = v2.length === 0 ? " " : v2;
            }
            if (v1 > v2) return direction;
            if (v1 < v2) return -direction;
        }
        return 0;
    }
    componentDidMount = async () => {
        let ress = await getRequest("currencies/getCurrency",{},false);
        if (ress.data?.currencyRates) {
            this.setState({currencyRates: ress.data.currencyRates});
        }
        const id = this.props.location.search.split("=")[1];
        await this.props.getOrder(id);
        if (this.props.orderView.length > 0) {
            let array = Array(this.props.orderView.length).fill(false);
            let partyArray = Array(this.props.orderView.length).fill(false);
            let selectedPartyArray = [];
            let pArray = [];
            for (let i = 0; i < this.props.orderView.length; i++) {
                let c = this.props.orderView[i][0];
                let addressId = c.address._id;
                let address = c.user.Addresses.filter((add) => add._id === addressId);
                let city = address[0].recipientCity;
                let parties = await this.props.getParties(city);
                let shippingTime = c.shippingTime;
                let shippingOrignal = c.shippingOrignal;
                let partyId = c.partyId;
                if (partyId) {
                    selectedPartyArray.push(partyId);
                } else {
                    selectedPartyArray.push(null);
                }
                let shippingPrice = c.shippingPrice;
                let shippingType = c.shippingType;
                let dString =
                    shippingType + " " + shippingTime + " " + "AED" + shippingOrignal;
                let partiesArray = this.getPartyStrings(parties);
                let index = partiesArray.findIndex(
                    (party) => party.dString === dString
                );
                let showParties = [];
                if (index !== -1) {
                    showParties = partiesArray[index].parties.map((p) => {
                        return parties.find((pa) => pa._id === p);
                    });
                }

                pArray.push(showParties);
            }
            this.setState({
                orders: this.props.orderView,
                slotMessageArray: array,
                partyMessageArray: partyArray,
                arrayOfPartiesArray: pArray,
                selectedPartyArray: selectedPartyArray,
                render: true,
            });
        }
    };

    getPartyStrings(parties) {
        let array = [];
        for (let i = 0; i < parties?.length; i++) {
            for (let j = 0; j < parties[i].carrierShipmentMethods?.length; j++) {
                for (
                    let k = 0;
                    k < parties[i].carrierShipmentMethods[j]?.timeSlots?.length;
                    k++
                ) {
                    let shippingType = parties[i].carrierShipmentMethods[j].methodType;
                    let timeSlot =
                        parties[i].carrierShipmentMethods[j].timeSlots[k].startTime +
                        " - " +
                        parties[i].carrierShipmentMethods[j].timeSlots[k].endTime +
                        " hrs";
                    let price =
                        parties[i].carrierShipmentMethods[j].shipmentMethodsType.price;
                    let dString = shippingType + " " + timeSlot + " " + "AED" + price;
                    let partyId = parties[i]._id;
                    let found = array.findIndex((arr) => arr.dString === dString);
                    if (found !== -1) {
                        let arrayObj = array[found];
                        arrayObj.parties.push(partyId);
                        array[found] = arrayObj;
                    } else {
                        array.push({
                            shippingType,
                            timeSlot,
                            price,
                            dString,
                            parties: [partyId],
                        });
                    }
                }
            }
        }
        return array;
    }

    state = {
        modalOpen: false,
        shippingAddress: {},
        cities: [],
        senderInfo: [],
        senderModalOpen: false,
        deliveryModalOpen: false,
        deliveryInfo: [],
        orders: [],
        orderInd: "",
        arrayOfPartiesArray: [],
        slotMessageArray: [],
        deliveryIndex: "",
        partyMessageArray: [],
        render: false,
        selectedPartyArray: [],
        currencyRates: {},
        refundOrder:false,
        messageModalOpen:false,
        messageCart:'',
        partyAllocateModalPopUp:false,
        partAllocateCartIds:[],
        cakeMessageModalOpen:false,
        cakeMessageCart:null,
        cakeMessage:'',
        personalizedModalOpen:false,
        personalizedCart:null,
        personalizedMessage:'',
        image:'',
        outForDeliveryModalOpen:false,
        outForDeliveryCarts:[],
        indexOfSenderDetail:'',
        editItemsModalOpen:false,
        receivePaymentModalOpen:false,
        subOrderForEdit:[],

    };
    componentDidUpdate = async (prevProps) => {
        if (prevProps.orderView !== this.props.orderView) {
            let array = Array(this.props.orderView.length).fill(false);
            let partyArray = Array(this.props.orderView.length).fill(false);
            let selectedPartyArray = [];
            let pArray = [];
            for (let i = 0; i < this.props.orderView.length; i++) {
                let c = this.props.orderView[i][0];
                let addressId = c.address._id;
                let address = c.user.Addresses.filter((add) => add._id === addressId);
                let city = address[0].recipientCity;
                let parties = await this.props.getParties(city);
                let shippingTime = c.shippingTime;
                let shippingOrignal = c.shippingOrignal;
                let partyId = c.partyId;
                if (partyId) {
                    selectedPartyArray.push(partyId);
                } else {
                    selectedPartyArray.push(null);
                }
                let shippingPrice = c.shippingPrice;
                let shippingType = c.shippingType;
                let dString =
                    shippingType + " " + shippingTime + " " + "AED" + shippingOrignal;
                let partiesArray = this.getPartyStrings(parties);
                let index = partiesArray.findIndex(
                    (party) => party.dString === dString
                );
                let showParties = [];
                if (index !== -1) {
                    showParties = partiesArray[index].parties.map((p) => {
                        return parties.find((pa) => pa._id === p);
                    });
                }

                pArray.push(showParties);
            }
            this.setState({
                orders: this.props.orderView,
                arrayOfPartiesArray: pArray,
                selectedPartyArray: selectedPartyArray,
                render: true,
            });
        }
    };
    handleDeliveryClick = (carts, addresses, ind) => {
        let shippingType = carts[0].shippingType;
        let shippingTime = carts[0].shippingTime;
        let shippingPrice = carts[0].shippingOrignal;
        let addressId = carts[0].deliveryAddress;
        let shippingDate = carts[0].shippingDate;
        this.setState({
            deliveryInfo: {
                shippingType,
                shippingTime,
                shippingPrice,
                shippingDate,
                addressId,
                addresses,
                orderInd: ind,
            },
            deliveryModalOpen: true,
        });
    };
    handleDeliveryClose = () => {
        this.setState({deliveryModalOpen: false});
    };
    handleMessageClose=async(update=false)=>{
        this.setState({messageModalOpen:false})
        if(update){
            const id = this.props.location.search.split("=")[1];
            await this.props.getOrder(id);
        }
    }
    handleDeliverySubmit = async (slot, ind) => {
        let prevAmount = 0;
        let newAmount = 0;
        let cartIds = this.state.orders[ind].map((cart) => {
            if (prevAmount < cart.shippingOrignal) {
                prevAmount = cart.shippingOrignal;
            }
            return cart._id;
        });
        newAmount = slot.price;
        prevAmount = parseFloat(
            (
                (prevAmount / this.state.currencyRates["AED"]) *
                this.state.currencyRates[this.props.orderView[0][0].order.currency]
            ).toFixed(2)
        );
        newAmount = parseFloat(
            (
                (newAmount / this.state.currencyRates["AED"]) *
                this.state.currencyRates[this.props.orderView[0][0].order.currency]
            ).toFixed(2)
        );
        let orignalOrderAmount = parseFloat(
            (
                (this.props.orderView[0][0].order.amount /
                    this.state.currencyRates["AED"]) *
                this.state.currencyRates[this.props.orderView[0][0].order.currency]
            ).toFixed(2)
        );
        let amount = orignalOrderAmount + newAmount - prevAmount;

        await this.props.setCartsAndParties({
            slot,
            cartIds,
            amount,
            orderId: this.props.orderView[0][0]?.order._id,
            subOrderId:this.props.orderView[ind][0]?.subOrderId
        });
        const id = this.props.location.search.split("=")[1];
        await this.props.getOrder(id);
        let slotMessageArray = this.state.slotMessageArray;
        slotMessageArray[ind] = false;
        let partyMessageArray = this.state.partyMessageArray;
        partyMessageArray[ind] = true;
        this.setState({
            deliveryModalOpen: false,
            slotMessageArray,
            partyMessageArray,
        });
    };
    handleAddressUpdate = async (id, carts, ind) => {
        let cartIds = carts.map((cart) => {
            return cart._id;
        });
        let copy = this.state.slotMessageArray;
        copy[ind] = true;
        this.setState({slotMessageArray: copy});
        await this.props.updateAddress({deliveryAddress: id, cartIds});
        const orderId = this.props.location.search.split("=")[1];
        this.props.getOrder(orderId);
    };
    handleSenderClick = (ind) => {
        let name = this.props.orderView[0][0].order.senderName;
        let email = this.props.orderView[0][0].order.senderEmail;
        let phone = this.props.orderView[0][0].order.senderPhone;
        this.setState({
            senderModalOpen: true,
            senderInfo: {name, email, phone},
            indexOfSenderDetail:ind
        });
    };
    handleClose = () => {
        this.setState({modalOpen: false});
    };
    handleSenderSubmit = async ({name, email, phone}) => {
        let order = this.props.orderView[this.state.indexOfSenderDetail][0].subOrderId;
        this.setState({senderModalOpen: false});
        const oId = this.props.location.search.split("=")[1];
        await this.props.editSender({
            subOrderId:order,
            id:oId,
            senderName: name,
            senderEmail: email,
            senderPhone: phone,
        });
        // const oId = this.props.location.search.split("=")[1];
        await this.props.getOrder(oId);
    };
    handleSenderClose = () => {
        this.setState({senderModalOpen: false});
    };
    handlePartyChange = async (carts, ind) => {
        let cartIds = carts.map((cart) => {
            return cart._id;
        });
        let partyId = this.state.selectedPartyArray[ind];
        let partyMessageArray = this.state.partyMessageArray;
        partyMessageArray[ind] = false;
        this.setState({partyMessageArray});
        let subOrderId = this.props.orderView[ind][0].subOrderId;
        await this.props.setPartyId({partyId, cartIds,subOrderId});
        const oId = this.props.location.search.split("=")[1];
        await this.props.getOrder(oId);
    };
    closeAllocatePartyPopUp=async(flag=false)=>{
        this.setState({partyAllocateModalPopUp:false})
        if(flag){
            const id = this.props.location.search.split("=")[1];
            await this.props.getOrder(id);
        }
    }
    inputHandler = async (event,id) => {
        let ref = this[`image-${id}`];
        const file = event.target.files[0];
        const fileType = file.type;
        this.setState({show: false});

        if (fileType !== "image/jpeg" && fileType !== "image/png") {
            this.setState({file: "",});
            Notify('danger',"Only jpg and png files are allowed");
            ref.value='';
        } else {
            this.setState({file});
            let formData = new FormData();
            formData.append("image", file);
            let image = await postRequest("cart/uploadImage", formData);
            let location = image.data;

            if (location) {
                this.setState({image: {cartId:id,location:location}});
            }
        }
    };
    updateImage= async()=>{
        await this.props.updateMessage({id:this.state.image.cartId,image:this.state.image.location,transection:'Custom Image Updated'});
        const orderId = this.props.location.search.split("=")[1];
        await this.props.getOrder(orderId);
        let ref = this[`image-${this.state.image.cartId}`];
        ref.value='';

    }
    setCartsForAllocate=(orders)=>{
        let ids=[];
        for (let i = 0; i < orders.length; i++) {
            ids.push(orders[i]._id);
        }
        this.setState({partyAllocateModalPopUp:true,partAllocateCartIds:ids});
    }
    editCakeMessage=(id,message)=>{
        this.setState({
            cakeMessageModalOpen:true,
            cakeMessageCart:id,
            cakeMessage:message,
        })
    }
    editPersonalizedMessage=(id,message)=>{
        this.setState({
            personalizedModalOpen:true,
            personalizedCart:id,
            personalizedMessage:message,
        })

    }
    closeCakeMessagePopUp=async (flag=false)=>{
        this.setState({
            cakeMessageModalOpen:false,
            personalizedCart:null,
            personalizedMessage:"",
        })
        if(flag){
            const id = this.props.location.search.split("=")[1];
            await this.props.getOrder(id);
        }
    }
    closePersonalizedMessagePopUp=async (flag=false)=>{
        this.setState({
            personalizedModalOpen:false,
            personalizedCart:null,
            personalizedMessage:'',
        })
        if(flag){
            const id = this.props.location.search.split("=")[1];
            await this.props.getOrder(id);
        }
    }
    setCartsForOutForDelivery=(carts)=>{
        this.setState({outForDeliveryModalOpen:true,outForDeliveryCarts:carts});
    }
    closeOutForDeliveryPopUp=async(flag=false)=>{
        this.setState({outForDeliveryModalOpen:false,outForDeliveryCarts:[]});
        if(flag){
            const id = this.props.location.search.split("=")[1];
            await this.props.getOrder(id);
        }
    }
    openEditItemsPopUp=(orders)=>{
        // let filtered = orders.filter(a=>a.cartStatus===true);
        this.setState({editItemsModalOpen:true,subOrderForEdit:orders});
    }
    closeEditItemsPopUp=()=>{
        this.setState({editItemsModalOpen:false});
    }
    receivePaymentHandler=()=>{
        this.setState({receivePaymentModalOpen:true})
    }
    receivePaymentModelClose=async (flag=false)=>{
        this.setState({receivePaymentModalOpen:false});
        if(flag){
            const id = this.props.location.search.split("=")[1];
            await this.props.getOrder(id);
        }
    }



    renderRow = (cart) => (
        <tbody>
        <tr>
            <td
                colSpan={8}
                style={{
                    border: "1px solid black",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                }}
            >
                {cart.products[0]._id}
            </td>
        </tr>

        <tr>
            <td className="customizedTd centerAlign">
                <img
                    src={cart.products[0].images?.[0]}
                    height="210px"
                    width="210px"
                />
            </td>
            {cart?.order?.orderStatus!=="CANCELED" && cart?.cartStatus !== false ?<td className="customizedTd centerAlign">
                {cart.products[0].title} [{cart.products[0].description}] (
                {cart.products[0].brandName})
            </td>:<td className="customizedTd centerAlign">
                <strike>{cart.products[0].title} [{cart.products[0].description}] (
                    {cart.products[0].brandName})</strike>
            </td>
            }
            <td style={{border: "1px solid black"}}>
                <div>
                    <p style={{fontWeight: "bold"}}>Product Details : </p>
                    <p>{cart.products[0].description}</p>

                    <p style={{fontWeight: "bold"}}>Flower Trivia : </p>
                    <p></p>

                    <p style={{fontWeight: "bold"}}>Please Note : </p>
                    <p>{cart.products[0].careInfo}</p>
                </div>

                <div style={{marginTop: "20px"}} className="customizedDiv">
                    <p style={{fontWeight: "bold"}}>
                        Gift Message : To {cart?.giftCard_receiver}
                    </p>
                    <p>{cart?.giftCard_message}</p>
                    <p>From</p>
                    <p>{cart?.giftCard_sender}</p>
                </div>

                <div className="customizedDiv">
                    <p style={{fontWeight: "bold"}}>
                        Cake Message :{cart?.cakeMessage}
                    </p>
                    <button type={'button'} onClick={()=>this.editCakeMessage(cart?._id,cart?.cakeMessage)}>Edit</button>
                </div>

                <div className="customizedDiv">
                    <p style={{fontWeight: "bold"}}>Instructions : </p>
                </div>

                <div className="customizedDiv">
                    <p style={{fontWeight: "bold"}}>Personalized Text :{cart?.personalizedText} </p>
                    <button type={"button"} onClick={()=>this.editPersonalizedMessage(cart?._id,cart?.personalizedText)}>Edit</button>
                </div>
                <div className="customizedDiv">
                    <p style={{fontWeight: "bold"}}>Size :{cart?.Feature?.id} </p>
                </div>
                <div className="customizedDiv">
                    <p style={{fontWeight: "bold"}}>Type :{cart?.cake?.id} </p>
                </div>
                <div className="customizedDiv">
                    <p style={{fontWeight: "bold"}}>Varient :{cart?.egg?.id} </p>
                </div>

                <div className="customizedDiv">
                    <p style={{fontWeight: "bold"}}>Personalized Image : </p>
                    {cart?.image ? (
                        <img src={cart?.image} alt="" height="70px" width="70px"/>
                    ) : (
                        ""
                    )}
                    <input
                        accept=""
                        // className={classes.input}
                        onChange={(e) => this.inputHandler(e,cart?._id)}
                        
                        id="raised-button-file"
                        ref={(ref) => {
                            this[`image-${cart?._id}`] = ref;
                        }}
                        type="file"
                    />
                    {this.state.image.cartId===cart?._id && <button type={'button'} onClick={() => this.updateImage()}>Update</button>}
                </div>

                <div className="customizedDiv">
                    <p>
                        <span style={{fontWeight: "bold"}}>Occasion :</span> _NA_
                    </p>
                </div>

                <div
                    style={{
                        textAlign: "right",
                        marginRight: "8px",
                    }}
                >
                    <a className="buttontext create" onClick={()=>this.setState({messageModalOpen:true,messageCart: {id:cart?._id,from:cart?.giftCard_sender,to:cart?.giftCard_receiver,message:cart?.giftCard_message}})}>
                        Edit Message
                    </a>
                </div>
            </td>
            <td className="customizedTd centerAlign">{cart.quantity}</td>
            <td className="customizedTd centerAlign">{cart.amount}</td>
            <td className="customizedTd centerAlign"></td>
            <td className="customizedTd centerAlign"></td>
            <td className="customizedTd centerAlign">
                {cart?.quantity * cart.amount}
                <div>
                    {cart?.quantity * cart.amount>cart?.paidAmount?<p
                    style={{fontSize:'20px',color:'#FF0000',fontWeigth:'bold'}}
                    >COD:{cart?.quantity * cart.amount-cart?.paidAmount}</p>:""}
                </div>
            </td>
        </tr>
        </tbody>
    );
    renderCarts = (carts) => {
        let list = [];
        carts.map((cart) => {
            list.push(this.renderRow(cart));
            if (cart.addons) {
                cart.addons.map((addon) => {
                    list.push(this.renderRow(addon));
                });
            }
        });
        return list;
    };
    handleAddressEdit = async (id, shippingaddresses, carts, ind) => {
        let shippingAddress = shippingaddresses.find(
            (address) => address._id === id
        );
        let country = carts[0].products[0]?.CategoriesArray?.[0]?.Catalog.name;
        let countries = await this.props.allActiveCountries();
        let countryObj = {};
        if (countries) {
            countryObj = countries.find(
                (c) => c.name.toLowerCase() === country.toLowerCase()

            );
        }

        if (countryObj) {
            let cities = await this.props.getCities(countryObj._id);
            this.setState({
                modalOpen: true,
                shippingAddress,
                country,
                cities,
                deliveryIndex: ind,
            });
        }
    };
    handleSubmit = async (
        {
            selectedCity,
            name,
            address1,
            address2,
            phoneNo,
            recipientEmail,
            addressId,
        },
        changeFlag
    ) => {
        this.setState({modalOpen: false});
       let subOrderId = this.props.orderView[this.state.deliveryIndex][0].subOrderId;
        await this.props.editAddress({
            id: addressId,
            email: recipientEmail,
            phone: phoneNo,
            recipientAddress: address1,
            landmark: address2,
            name: name,
            recipientCity: selectedCity,
            subOrderId
        });

        if (changeFlag) {
            let copy = this.state.slotMessageArray;
            copy[this.state.deliveryIndex] = !copy[this.state.deliveryIndex];
            this.setState({slotMessageArray: copy});
        }
        const oId = this.props.location.search.split("=")[1];
        await this.props.getOrder(oId);
        // console.log("props", this.props);
    };
    holdSubOrder=async(subOrderId)=>{
        let data = {deliveryStatus:'HOLD',transection:'Order Status changed to HOLD'}
        await this.props.changeHoldStatus(subOrderId,data);
        const id = this.props.location.search.split("=")[1];
        await this.props.getOrder(id);
    }
    unHoldSubOrder=async(subOrderId)=>{
        let data = {deliveryStatus:null,transection:'Order Unhold'}
        await this.props.changeHoldStatus(subOrderId,data);
        const id = this.props.location.search.split("=")[1];
        await this.props.getOrder(id);
    }
    makeDataForPrint=async(order)=>{
        let obj  = {};
        obj.deliveryinfo=order[0].address;
        let products=[];
        let amount = 0;
        let paidAmount = 0;
        let shipping = 0;
        for(let i = 0;i<order.length;i++){
            let pObj = {};
            let product = order[i].products[0];
            let addons = order[i].addons;
            pObj.description = product.description;
            pObj.giftCard= product.giftCard;
            pObj.giftCard_occassion = product.giftCard_occassion;
            pObj.giftCard_message = product.giftCard_message;
            pObj.giftCard_receiver = product.giftCard_receiver;
            pObj.giftCard_sender = product.giftCard_sender
            pObj.pImg = product.images[0];
            pObj.quantity= order[i].quantity;
            pObj.image = order[i].image;
            pObj.name = product.title;

            paidAmount = paidAmount + order[i].paidAmount;
            amount = amount + order[i].amount;
            shipping =shipping + order[i].shippingPrice;
            products.push(pObj);
            for(let j=0;j<addons.length;j++){
                let newPObj = {}
                let addon = addons[j];
                let addonProduct = addon.products[0];
                newPObj.description = addonProduct.description;
                newPObj.giftCard= addonProduct.giftCard;
                newPObj.giftCard_occassion = addonProduct.giftCard_occassion;
                newPObj.giftCard_message = addonProduct.giftCard_message;
                newPObj.giftCard_receiver = addonProduct.giftCard_receiver;
                newPObj.giftCard_sender = addonProduct.giftCard_sender
                newPObj.pImg = addonProduct.images[0];
                newPObj.quantity= addon.quantity;
                newPObj.image = addon.image;
                newPObj.name = addonProduct.title;

                amount = amount + addon.amount;
                paidAmount = paidAmount + addon.paidAmount;

                products.push(newPObj);
            }
        }
        obj.amount = amount;
        obj.paidAmount = paidAmount;
        obj.shipping = shipping;
        obj.orderDate=order[0].order.createdDate
        obj.senderName = order[0].order.senderName;
        obj.shippingDate = order[0].shippingDate;
        obj.shippingTime = order[0].shippingTime;
        obj.shippingType = order[0].shippingType;
        obj.orderId = order[0].subOrderId;
        obj.products=products;
        let contentObj = await this.printChallan(obj);
        let dd = JSON.parse(JSON.stringify(cc));
        if(obj.amount+obj.shipping>obj.paidAmount){
            dd.watermark = watermark.watermark;
        }
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        dd.content.push(contentObj);
        console.log(dd);
        pdfMake.createPdf(dd).open();
    }
    editItems=()=>{

    }
    subOrder = (order, ind) => {
        let status='';

        if(order[0].order?.paymentMethod==='cod'){
            if(order[0].deliveryStatus==='NOT_ALLOCATED'){
                status = 'Not Allocated';
            }
            if(order[0].deliveryStatus===null && order[0]?.partyId===null ){
                status='Allocated'
            }
            if(order[0]?.partyId){
                status='Accepted'
            }
            if(order[0].deliveryStatus!==null && order[0].deliveryStatus!=='NOT_ALLOCATED'){
                status=order[0].deliveryStatus;
            }
        }
        else{
            status='Waiting';
            if(order[0]?.order?.paymentStatus){
                if(order[0].deliveryStatus==='NOT_ALLOCATED'){
                    status = 'Not Allocated';
                }
                if(order[0].deliveryStatus===null && order[0]?.partyId===null ){
                    status='Allocated'
                }
                if(order[0]?.partyId){
                    status='Accepted'
                }
                if(order[0].deliveryStatus!==null && order[0].deliveryStatus!=='NOT_ALLOCATED'){
                    status=order[0].deliveryStatus;
                }
            }
        }

        return (
            <div>
                <div
                    id="searchOptions"
                    className="screenlet"
                    style={{marginTop: "10px"}}
                >
                    <div
                        className="screenlet-title-bar"
                        style={{paddingBottom: "12px"}}
                    >
                        <ul>
                            <li className="h3">
                                <a href="#">
                                    Sub Order Number{" "}
                                    <span style={{marginLeft: "10px"}}>
                    {this.props.orderView[ind][0]?.subOrderId}
                  </span>
                                </a>
                            </li>
                            {this.props.orderView[ind][0]?.deliveryStatus!=="HOLD" && <li>
                                <a
                                    onClick={() => this.holdSubOrder(this.props.orderView[ind][0]?.subOrderId)}
                                    className="buttontext create"
                                    href="#"
                                    style={{marginLeft: "6px"}}
                                >
                                    Hold Sub Order
                                </a>
                            </li>}
                            {this.props.orderView[ind][0]?.deliveryStatus==="HOLD" && <li>
                                <a
                                    onClick={() => this.unHoldSubOrder(this.props.orderView[ind][0]?.subOrderId)}
                                    className="buttontext create"
                                    href="#"
                                    style={{marginLeft: "6px"}}
                                >
                                    Unhold Sub Order
                                </a>
                            </li>}
                            <li>
                                <a
                                    className="buttontext create"
                                    onClick={()=>this.openEditItemsPopUp(order)}
                                    style={{marginLeft: "6px"}}
                                >
                                    Edit Items
                                </a>
                            </li>
                            <li>
                                <a
                                    className="buttontext create"
                                    href={"/ordermgr/control/createNewNote?orderId=" + this.props?.orderView?.[0]?.[0]?.order._id}
                                    style={{marginLeft: "6px"}}
                                >
                                    Create Feedback
                                </a>
                            </li>
                            <li>
                                <a
                                    className="buttontext create"
                                    href="#"
                                    style={{marginLeft: "6px"}}
                                >
                                    Purchase Order : 494026
                                </a>
                            </li>
                            <li>
                                <a
                                    className="buttontext create"
                                    onClick={()=>this.setCartsForOutForDelivery(order)}
                                    style={{marginLeft: "6px"}}

                                >
                                    Out For Delivery
                                </a>
                            </li>
                            <li>
                                <a
                                    className="buttontext create"
                                    href={"/ordermgr/control/order-history?orderId="+order[0].subOrderId}
                                    style={{marginLeft: "6px"}}
                                >
                                    Order History
                                </a>
                            </li>
                            <li>
                                <a
                                    className="buttontext create"
                                    href="#"
                                    style={{marginLeft: "6px"}}
                                >
                                    Older Order History
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div id="searchOptions_col" className="screenlet-body">
                        {/*shipping info*/}
                        <div>
                            <div className="screenlet-title-bar" style={{margin: "-4px"}}>
                                <ul>
                                    <li className="h3">
                                        <a href="#">Shipping Information</a>
                                    </li>
                                </ul>
                            </div>

                            <div id="search-options" className="flex-container">
                                {/*left side*/}
                                <div style={{width: "50%"}}>
                                    <div style={{marginRight: "20px"}}>
                                        <p
                                            style={{
                                                borderBottom: "1px solid black",
                                                paddingBottom: "10px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Recipient Details
                                        </p>

                                        <div style={{marginTop: "20px"}}>
                                            <p style={{fontWeight: "bold"}}>
                                                Recipient Name:{order[0]?.address?.name}
                                                {/* <span style={{ fontSize: "14px" }}>
                          {order?.[0]?.user?.name}
                        </span> */}
                                            </p>
                                            <p style={{marginTop: "10px"}}>
                        <span style={{fontWeight: "bold"}}>
                          Recipient Address:
                        </span>
                                                {order[0]?.address?.recipientAddress},
                                                {order[0]?.address?.landmark}
                                                {order[0]?.address?.recipientCity}
                                            </p>
                                            <p style={{fontWeight: "bold", marginTop: "10px"}}>
                                                Phone Number:{order[0]?.address?.phone}
                                                {/* <span style={{ fontSize: "14px" }}>
                          {order[0]?.user?.mobile}
                        </span> */}
                                            </p>
                                            <p style={{fontWeight: "bold", marginTop: "10px"}}>
                                                Email:{order[0]?.address?.email}
                                            </p>
                                            {/* <PopUp
                        handleSubmit={this.handleSubmit}
                        handleClose={this.handleClose}
                        modalOpen={this.state.modalOpen}
                      /> */}
                                            <button
                                                style={{marginTop: "20px", padding: "10px"}}
                                                onClick={() =>
                                                    this.handleAddressEdit(
                                                        order[0]?.address?._id,
                                                        order[0]?.user?.Addresses,
                                                        order,
                                                        ind
                                                    )
                                                }
                                            >
                                                Edit
                                            </button>

                                            <p style={{marginTop: "30px"}}>
                                                <span style={{fontWeight: "bold"}}>Address</span>
                                                <select
                                                    style={{marginLeft: "10px"}}
                                                    value={order[0]?.address?._id}
                                                    onChange={(e) => {
                                                        //shippingAddressId = e.target.value;
                                                        this.handleAddressUpdate(
                                                            e.target.value,
                                                            order,
                                                            ind
                                                        );
                                                    }}
                                                >
                                                    {order[0].user?.Addresses.map((address) => (
                                                        <option value={address._id}>
                                                            {address?.recipientAddress},{address?.landmark},
                                                            {address?.recipientCity}
                                                        </option>
                                                    ))}
                                                </select>
                                                {/* <button
                          style={{ marginLeft: "10px" }}
                          onClick={() => this.handleAddressUpdate()}
                        >
                          Update
                        </button> */}
                                            </p>

                                            <div
                                                style={{
                                                    width: "50%",
                                                    marginTop: "50px",
                                                    borderBottom: "1px solid lightgray",
                                                }}
                                            ></div>
                                        </div>
                                    </div>

                                    <div style={{marginRight: "20px", marginTop: "20px"}}>
                                        <p style={{fontWeight: "bold"}}>
                                            <u>Sender Details</u>
                                        </p>

                                        <div style={{marginTop: "20px"}}>
                                            <p style={{fontWeight: "bold"}}>
                                                Sender Name:{" "}
                                                <span style={{fontSize: "14px"}}>
                          {this.props.orderView?.[0]?.[0]?.order?.senderName}
                        </span>
                                            </p>
                                            <p style={{fontWeight: "bold", marginTop: "10px"}}>
                                                Sender Phone:{" "}
                                                <span style={{fontSize: "14px"}}>
                          {this.props.orderView?.[0]?.[0]?.order?.senderPhone}
                        </span>
                                            </p>
                                            <p style={{fontWeight: "bold", marginTop: "10px"}}>
                                                Sender Email:{" "}
                                                <span style={{fontSize: "14px"}}>
                          {this.props.orderView?.[0]?.[0]?.order?.senderEmail}
                        </span>
                                            </p>

                                            <button
                                                style={{marginTop: "20px", padding: "10px"}}
                                                onClick={() => this.handleSenderClick(ind)}
                                            >
                                                Edit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/*left side ends here*/}

                                {/*right side*/}
                                <div style={{width: "50%"}}>
                                    <div style={{marginRight: "20px"}}>
                                        <p
                                            style={{
                                                borderBottom: "1px solid black",
                                                paddingBottom: "10px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Shipping Info
                                        </p>

                                        <div style={{marginTop: "20px"}}>
                                            <p style={{marginTop: "10px"}}>
                        <span style={{fontWeight: "bold"}}>
                          Current Vendor Allocated :{" "}
                        </span>
                                                {/* {partyInfo?.[0]?.name} */}
                                            </p>
                                            <p style={{marginTop: "10px"}}>
                        <span style={{fontWeight: "bold"}}>
                          Current Status :{" "}
                        </span>
                                                {status} {status==='Not Allocated' && <button type={'button'} onClick={()=>this.setCartsForAllocate(order)}>Allocate</button>}
                                            </p>
                                            <p style={{marginTop: "10px"}}>
                        <span style={{fontWeight: "bold"}}>
                          Preferred Time Slot :{" "}
                        </span>
                                                {order[0]?.shippingTime}
                                            </p>
                                            <p style={{marginTop: "10px"}}>
                        <span style={{fontWeight: "bold"}}>
                          Vendor Time Slot :{" "}
                        </span>
                                            </p>
                                            <p style={{marginTop: "10px"}}>
                        <span style={{fontWeight: "bold"}}>
                          Delivery Mode :{" "}
                        </span>
                                                {order[0]?.shippingType}{" "}
                                                <button
                                                    style={{padding: "10px"}}
                                                    onClick={() =>
                                                        this.handleDeliveryClick(
                                                            order,
                                                            order[0].user.Addresses,
                                                            ind
                                                        )
                                                    }
                                                >
                                                    Edit
                                                </button>
                                            </p>
                                            {this.state.slotMessageArray[ind] && (
                                                <p style={{color: "red", fontWeight: "bold"}}>
                                                    Please Select a New Delivery Slot
                                                </p>
                                            )}
                                            <p style={{marginTop: "10px"}}>
                                                <span style={{fontWeight: "bold"}}>Remarks : </span>
                                            </p>
                                            <p style={{marginTop: "10px"}}>
                        <span style={{fontWeight: "bold"}}>
                          Delivery Boy Name :{" "}
                        </span>
                                                No Name
                                            </p>
                                            <p style={{marginTop: "10px"}}>
                        <span style={{fontWeight: "bold"}}>
                          Delivery Boy Phone :{" "}
                        </span>
                                                No Mobile
                                            </p>
                                            <p style={{marginTop: "10px"}}>
                        <span style={{fontWeight: "bold"}}>
                          Date Of Delivery :{" "}
                        </span>
                                                <span
                                                    style={{
                                                        fontWeight: "bold",
                                                        fontSize: "14px",
                                                    }}
                                                >
                          {order[0]?.shippingDate}
                        </span>{" "}
                                                00:00:00
                                            </p>
                                            <p style={{marginTop: "10px"}}>
                        <span style={{fontWeight: "bold"}}>
                          Delivered Date :{" "}
                        </span>
                                            </p>
                                            <p style={{marginTop: "10px"}}>
                        <span style={{fontWeight: "bold"}}>
                          Tracking Number :{" "}
                        </span>
                                                ID Code: [Not Yet Known]
                                            </p>

                                            <p style={{marginTop: "30px"}}>
                                                <span style={{fontWeight: "bold"}}>Supplier : </span>
                                                <select
                                                    style={{marginLeft: "10px", width: "200px"}}
                                                    onChange={(e) => {
                                                        let partyArray = this.state.selectedPartyArray;
                                                        partyArray[ind] = e.target.value;
                                                        this.setState({selectedPartyArray: partyArray});
                                                    }}
                                                    value={
                                                        this.state.render
                                                            ? this.state.selectedPartyArray[ind]
                                                            : ""
                                                    }
                                                >
                                                    <option value=""></option>
                                                    {this.state.render &&
                                                    this.state.arrayOfPartiesArray[ind].map((party) => (
                                                        <option value={party._id}>{party.name}</option>
                                                    ))}
                                                </select>
                                                <button
                                                    style={{marginLeft: "10px"}}
                                                    onClick={() => this.handlePartyChange(order, ind)}
                                                >
                                                    Allocate
                                                </button>
                                            </p>
                                            {this.state.partyMessageArray[ind] && (
                                                <p style={{color: "red", fontWeight: "bold"}}>
                                                    Please Select a New Party
                                                </p>
                                            )}

                                            <div
                                                style={{
                                                    width: "50%",
                                                    marginTop: "30px",
                                                    borderBottom: "1px solid lightgray",
                                                }}
                                            ></div>
                                        </div>
                                    </div>

                                    <div style={{marginRight: "20px", marginTop: "20px"}}>
                                        <p style={{fontWeight: "bold"}}>
                                            <u>Shipments</u>
                                        </p>

                                        <div style={{marginTop: "20px"}}>
                                            <p>
                                                Nbr
                                                <button style={{padding: "10px", marginLeft: "10px"}}>
                                                    183676
                                                </button>
                                                <button type={'button'} style={{padding: "10px", marginLeft: "10px"}} onClick={()=>this.makeDataForPrint(order)}>
                                                    Print Challan
                                                </button>
                                            </p>

                                            <p style={{marginTop: "10px"}}>
                        <span style={{fontWeight: "bold"}}>
                          ShipmentStatus
                        </span>{" "}
                                                : Input
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/*right side ends here*/}
                            </div>
                        </div>
                        {/*shipping info ends here*/}

                        {/*product details*/}

                        <div style={{marginTop: "20px"}}>
                            <div className="screenlet-title-bar" style={{margin: "-4px"}}>
                                <ul>
                                    <li className="h3">
                                        <a href="#">Product Details</a>
                                    </li>
                                </ul>
                            </div>
                            <div id="search-options">
                                <table className="customizedProductTable" cellSpacing={0}>
                                    <thead>
                                    <tr>
                                        <th width="20%" style={{textAlign: "center"}}>
                                            {" "}
                                            Product Image
                                        </th>
                                        <th width="15%" style={{textAlign: "center"}}>
                                            {" "}
                                            Product Name SKU Code
                                        </th>
                                        <th width="30%" style={{textAlign: "center"}}>
                                            {" "}
                                            Product Description
                                        </th>
                                        <th width="7%" style={{textAlign: "center"}}>
                                            {" "}
                                            Qty
                                        </th>
                                        <th width="7%" style={{textAlign: "center"}}>
                                            {" "}
                                            WP (₹)
                                        </th>
                                        <th width="7%" style={{textAlign: "center"}}>
                                            {" "}
                                            PP (₹)
                                        </th>
                                        <th width="7%" style={{textAlign: "center"}}>
                                            {" "}
                                            Discount (₹)
                                        </th>
                                        <th width="7%" style={{textAlign: "center"}}>
                                            {" "}
                                            Total Amount(₹)
                                        </th>
                                    </tr>
                                    </thead>
                                    {this.renderCarts(order)}
                                </table>
                            </div>
                        </div>

                        {/*product details end here*/}

                        {/* order feedback*/}
                        {/* <div style={{ marginTop: "20px" }}>
                    <div
                      className="screenlet-title-bar"
                      style={{ margin: "-4px" }}
                    >
                      <ul>
                        <li className="h3">
                          <a href="#">Order Feedback</a>
                        </li>
                      </ul>
                    </div>

                    <div
                      className="centerAlign"
                      style={{
                        color: "#557996",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      NO Order Feedback TO DISPLAY IN SUBORDER {queryOrderId}01
                    </div>
                  </div> */}
                        {/*order feedback end here */}
                    </div>
                </div>
            </div>
        );
    };
    getSubOrder = (order) => {
        let ORDER = order;
        let count = 1;
        //let amount = order?.amount;
        let id = order?._id;
        let index;
        let totalShipping = 0;
        let totalProductPrice = 0;
        let list = [];
        let carts = order?.carts && JSON.parse(JSON.stringify(order.carts));
        let arrayDates = [];
        if (carts) {
            for (let i = 0; i < carts.length; i++) {
                let filetered = arrayDates.filter(
                    (date) =>
                        date === moment(carts[i].shippingDate).utc(true).format("Y-MM-DD")
                );
                if (filetered.length > 0) {
                } else {
                    arrayDates.push(
                        moment(carts[i].shippingDate).utc(true).format("Y-MM-DD")
                    );
                }
            }
        }
        if (arrayDates.length > 0) {
            for (let j = 0; j < arrayDates.length; j++) {
                let dateCarts = carts.filter(
                    (cart) =>
                        moment(cart.shippingDate).utc(true).format("Y-MM-DD") ===
                        arrayDates[j]
                );
                let addressArray = [];
                for (let k = 0; k < dateCarts.length; k++) {
                    let addressCarts = addressArray.filter(
                        (address) => address === dateCarts[k].address._id
                    );
                    if (addressCarts.length > 0) {
                    } else {
                        console.log("date Carts", dateCarts[k]);
                        addressArray.push(dateCarts[k].address._id);
                    }
                }
                for (let m = 0; m < addressArray.length; m++) {
                    let addressCarts = dateCarts.filter(
                        (dateCart) => dateCart.address._id === addressArray[m]
                    );
                    let timeSlotsArray = [];
                    for (let n = 0; n < addressCarts.length; n++) {
                        let timeCarts = timeSlotsArray.filter(
                            (timeSlot) => timeSlot === addressCarts[n].shippingTime
                        );
                        if (timeCarts.length > 0) {
                        } else {
                            timeSlotsArray.push(addressCarts[n].shippingTime);
                        }
                    }

                    for (let l = 0; l < timeSlotsArray.length; l++) {
                        let timeCarts = addressCarts.filter(
                            (addressCart) => addressCart.shippingTime === timeSlotsArray[l]
                        );
                        let amount = 0;
                        let ordShipping = 0;
                        let productIds = "";
                        let productDescription = "";
                        for (let r = 0; r < timeCarts.length; r++) {
                            if (ordShipping < timeCarts[r].shippingPrice) {
                                ordShipping = timeCarts[r].shippingPrice;
                            }
                            amount = amount + timeCarts[r].amount * timeCarts[r].quantity;
                            productIds = productIds + timeCarts[r].products[0]._id + ",";
                            productDescription =
                                productDescription + timeCarts[r].products[0].description;
                            if (timeCarts[r].addons.length > 0) {
                                for (let s = 0; s < timeCarts[r].addons.length; s++) {
                                    amount =
                                        amount +
                                        timeCarts[r].addons[s].amount *
                                        timeCarts[r].addons[s].quantity;
                                    productIds =
                                        productIds + timeCarts[r].addons[s].products[0]._id + ",";
                                    productDescription =
                                        productDescription +
                                        timeCarts[r].addons[s].products[0].description;
                                }
                            }
                        }
                        totalShipping = totalShipping + ordShipping;
                        totalProductPrice = totalProductPrice + amount;
                        let orderId = ORDER._id;
                        let party = null;
                        if (timeCarts[0].partyId) {
                            let partyId = timeCarts[0].partyId;
                            party = timeCarts[0].parties.filter(
                                (party) => party._id === partyId
                            );
                        }
                        let data = {
                            orderId,
                            carts: timeCarts,
                            senderInfo: ORDER.user,
                            partyInfo: party,
                            orderObj: ORDER,
                            addresses: ORDER.user.Addresses,
                            addressId: timeCarts[0].address._id,
                        };
                        list.push(data);
                    }
                }
            }
        }
        return {list, totalProductPrice, totalShipping};
    };

    cancelOrder=async ()=>{
        const query = new URLSearchParams(this.props.location.search);
        const queryOrderId = query.get("orderId");
        await this.props.cancelOrder(queryOrderId);
        await this.props.getOrder(queryOrderId);
    }
    initiateRefund=(e)=>{
        e.preventDefault();
    this.setState({refundOrder:true})
    }
    handleRefundClose=()=>{
        this.setState({refundOrder:false});
    }
    routeToOtherOrders=()=>{
        let user = this.props.orderView[0][0].customer;
        this.props.history.push('/ordermgr/control/other-orders?user='+user);
    }
    sendPaymentLink=()=>{
        const query = new URLSearchParams(this.props.location.search);
        const queryOrderId = query.get("orderId");
        const payload={
            orderId:queryOrderId,
            paymentButtonFlag:true
        }
        this.props.actionSendPaymentLink(payload);
    }
    rednerInitiateRefund=()=>{
        if(this.props.orderView?.[0]?.[0]?.order
            ?.orderStatus!=='CANCELED' || !this.props.orderView?.[0]?.[0]?.order
            ?.paymentStatus){
            return(
                ""
            )
        }
       else if(this.props.orderView?.[0]?.[0]?.order
            ?.paymentStatus && this.props.orderView?.[0]?.[0]?.order
            ?.orderStatus=='CANCELED'&& !this.props.orderView?.[0]?.[0]?.order
            ?.refundType )
        {
            return(
                <button onClick={(e) => this.initiateRefund(e)}>Initiate Refund</button>
            )
        }
       else{
           return(<p>Refund Initiated</p>);
        }
    }


    render() {
        console.log("this.props",this.props)
        const query = new URLSearchParams(this.props.location.search);
        const queryOrderId = query.get("orderId");
        return (
            <div>
                {/*1st row*/}
                <div className="flex-container">
                    <RefundPopUp modalOpen={this.state.refundOrder} handleClose={this.handleRefundClose}/>
                    <div style={{width: "50%"}}>
                        <div
                            id="searchOptions"
                            className="screenlet"
                            style={{marginTop: "10px", marginRight: "20px"}}
                        >
                            <div className="screenlet-title-bar">
                                <ul>
                                    <li className="h3">
                                        <a > Sales Order Nbr {queryOrderId} [ PDF ] </a>
                                    </li>
                                    {this.props.orderView?.[0]?.[0]?.order
                                        ?.orderStatus!=='CANCELED' && <li>
                                        <a onClick={() => this.cancelOrder()}> Cancel Order </a>
                                    </li>}
                                </ul>
                            </div>

                            <div id="searchOptions_col" className="screenlet-body">
                                <div id="search-options">
                                    <form href={'abc'}>
                                        <table cellSpacing="0" className="basic-table">
                                            <tbody>
                                            <tr>
                                                <td></td>
                                                <td className="label">
                                                    <label>Order Status</label>
                                                </td>
                                                <td>
                                                    <p>
                                                        Current Status:{this.props.orderView?.[0]?.[0]?.order?.orderStatus}
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="label">
                                                    <label>Order Date</label>
                                                </td>
                                                <td>
                                                    <p>
                                                        {
                                                            this.props.orderView?.[0]?.[0]?.order
                                                                ?.createdDate
                                                        }
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="label">
                                                    <label>Order Total Amount</label>
                                                </td>
                                                <td
                                                    style={{
                                                        color: "#0f9d58",
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    <p>
                                                        AED:{" "}
                                                        {this.props.orderView?.[0]?.[0]?.order?.amount}
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="label">
                                                    <label>Order Discount</label>
                                                </td>
                                                <td>
                                                    <p>
                                                        AED
                                                        {/* {this.props.orderView?.discount} */}
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="label">
                                                    <label>Order Commission(Price Rules)</label>
                                                </td>
                                                <td>
                                                    <p></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="label">
                                                    <label>Donation</label>
                                                </td>
                                                <td
                                                    style={{
                                                        color: "#FFA500",
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    <p></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="label">
                                                    <label>Paid Amount</label>
                                                </td>
                                                <td
                                                    style={{
                                                        color: "#0f639d",
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    <p>
                                                        {this.props.orderView?.[0]?.[0]?.order.paidAmount}
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="label">
                                                    <label>Payment Mode</label>
                                                </td>
                                                <td>
                                                    <p
                                                        style={{
                                                            color: `${
                                                                this.props.orderView?.[0]?.[0]?.order
                                                                    ?.paymentMethod == "cod"
                                                                    ? "red"
                                                                    : ""
                                                            }`,
                                                            fontWeight: `${
                                                                this.props.orderView?.[0]?.[0]?.order
                                                                    ?.paymentMethod == "cod"
                                                                    ? "bold "
                                                                    : ""
                                                            }`,
                                                            fontSize: "15px",
                                                        }}
                                                    >
                                                        {
                                                            this.props.orderView?.[0]?.[0]?.order
                                                                ?.paymentMethod
                                                        }
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="label">
                                                    <label>Pending Amount</label>
                                                </td>
                                                <td className="button-style-2">
                            <span
                                style={{
                                    color: "#9d0f0f",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                }}
                            >{this.props.orderView?.[0]?.[0]?.order.pendingAmount}</span>

                                                    <a
                                                        className="buttontext create"
                                                        style={{marginLeft: "6px"}}
                                                        onClick={()=>this.receivePaymentHandler()}

                                                    >
                                                        Receive Payment
                                                    </a>

                                                    <a
                                                        className="buttontext create"
                                                    onClick={()=>this.sendPaymentLink()}
                                                        style={{marginLeft: "6px"}}
                                                    >
                                                        Send Payment Link
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="label">
                                                    <label>Refund Amount(Raise Refund)</label>
                                                </td>
                                                <td>
                                                    <p
                                                    style={{color:'orange',fontSize:'20px'}}
                                                    >{this.props?.orderView?.[0]?.[0]?.order?.amountToRefund}</p>
                                                </td>
                                                <td>
                                                    {this.rednerInitiateRefund()}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="label">
                                                    <label>No. Of SubOrder</label>
                                                </td>
                                                <td>
                                                    <p>{this.props.orderView?.length}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="label">
                                                    <label>Pending Amount</label>
                                                </td>
                                                <td className="button-style-2">
                                                    <a
                                                        className="buttontext create"

                                                        style={{marginLeft: "6px"}}
                                                    >
                                                        {this.props.orderView?.[0]?.[0]?.user?.email}
                                                    </a>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PopUp
                        handleSubmit={this.handleSubmit}
                        cities={this.state.cities}
                        shippingAddress={this.state.shippingAddress}
                        country={this.state.country}
                        handleClose={this.handleClose}
                        modalOpen={this.state.modalOpen}
                    />
                    <SenderPopUp
                        senderInfo={this.state.senderInfo}
                        modalOpen={this.state.senderModalOpen}
                        handleClose={this.handleSenderClose}
                        handleSenderSubmit={this.handleSenderSubmit}
                    />
                    <DeliverySlotPopUp
                        handleClose={this.handleDeliveryClose}
                        handleSubmit={this.handleDeliverySubmit}
                        modalOpen={this.state.deliveryModalOpen}
                        deliveryInfo={this.state.deliveryInfo}
                    />
                    <EditGiftMessage
                    modalOpen={this.state.messageModalOpen}
                    handleClose={this.handleMessageClose}
                    cartData={this.state.messageCart}
                    />
                    <AllocatePartyPopUp
                    modalOpen={this.state.partyAllocateModalPopUp}
                    handleClose={this.closeAllocatePartyPopUp}
                    ids={this.state.partAllocateCartIds}
                    />
                    <CakeMessagePopUp
                    modalOpen={this.state.cakeMessageModalOpen}
                    handleClose={this.closeCakeMessagePopUp}
                    cart={this.state.cakeMessageCart}
                    message={this.state.cakeMessage}
                    />
                    <PersonalizedTextPopUp
                        modalOpen={this.state.personalizedModalOpen}
                        handleClose={this.closePersonalizedMessagePopUp}
                        cart={this.state.personalizedCart}
                        message={this.state.personalizedMessage}
                    />
                    <OutForDeliveryPopUp
                    modalOpen={this.state.outForDeliveryModalOpen}
                    handleClose={this.closeOutForDeliveryPopUp}
                    carts={this.state.outForDeliveryCarts}
                    />
                    <EditItemsPopUp
                    modalOpen={this.state.editItemsModalOpen}
                    handleClose={this.closeEditItemsPopUp}
                    addresses={this.props?.orderView?.[0]?.[0]?.user?.Addresses}
                    orderId = {this.props?.orderView?.[0]?.[0]?.orderId}
                    orders = {this.state.subOrderForEdit}
                    />
                    <ReceivePayment
                    modalOpen={this.state.receivePaymentModalOpen}
                    handleClose={this.receivePaymentModelClose}
                    orderId={this.props?.orderView?.[0]?.[0]?.order?._id}
                    />

                    <div style={{width: "50%"}}>
                        <div
                            id="searchOptions"
                            className="screenlet"
                            style={{marginTop: "10px"}}
                        >
                            <div className="screenlet-title-bar">
                                <ul>
                                    <li className="h3">
                                        <a href="#"> Billing Details </a>
                                    </li>
                                </ul>
                            </div>

                            <div id="searchOptions_col" className="screenlet-body">
                                <div id="search-options">
                                    <form>
                                        <table cellSpacing="0" className="basic-table">
                                            <tbody>
                                            <tr>
                                                <td></td>
                                                <td className="label">
                                                    <label>Name</label>
                                                </td>
                                                <td className="button-style-2">
                                                    <a
                                                        className="buttontext create"
                                                        style={{marginLeft: "6px"}}
                                                        href="#"
                                                    >
                                                        {/* {this.props.orderView?.senderName} */}
                                                    </a>

                                                    <a
                                                        className="buttontext create"
                                                        href="#"
                                                        style={{marginLeft: "6px"}}
                                                    >
                                                        New order
                                                    </a>

                                                    <a
                                                        className="buttontext create"
                                                        onClick={()=>this.routeToOtherOrders()}
                                                        style={{marginLeft: "6px"}}
                                                    >
                                                        Other order
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="label">
                                                    <label>Email</label>
                                                </td>
                                                <td>
                                                    <p
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: "13px",
                                                        }}
                                                    >
                                                        {
                                                            this.props.orderView?.[0]?.[0]?.order
                                                                ?.senderEmail
                                                        }
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="label">
                                                    <label>Phone/Cell Phone </label>
                                                </td>
                                                <td>
                                                    <p
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: "13px",
                                                        }}
                                                    >
                                                        {
                                                            this.props.orderView?.[0]?.[0]?.order
                                                                ?.senderPhone
                                                        }
                                                    </p>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div
                            id="searchOptions"
                            className="screenlet"
                            style={{marginTop: "10px"}}
                        >
                            <div className="screenlet-title-bar">
                                <ul>
                                    <li className="h3">
                                        <a href="#"> Customer Details </a>
                                    </li>
                                </ul>
                            </div>

                            <div id="searchOptions_col" className="screenlet-body">
                                <div id="search-options">
                                    <form>
                                        <table cellSpacing="0" className="basic-table">
                                            <tbody>
                                            <tr>
                                                <td></td>
                                                <td className="label">
                                                    <label>Customer Id</label>
                                                </td>
                                                <td>
                                                    <p>{this.props.orderView?.[0]?.[0]?.user?._id}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="label">
                                                    <label>Created By</label>
                                                </td>
                                                <td className="button-style-2">
                                                    <a
                                                        className="buttontext create"
                                                        style={{marginLeft: "6px"}}
                                                        href="#"
                                                    >
                                                        {this.props.orderView?.[0]?.[0]?.user?.name}
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="label">
                                                    <label>Source</label>
                                                </td>
                                                <td>
                                                    <p>
                                                        {
                                                            this.props.orderView?.[0]?.[0]?.user
                                                                ?.channelSource
                                                        }
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="label">
                                                    <label>Sub Source</label>
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="label">
                                                    <label>Campaign</label>
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="label">
                                                    <label>Tracking Code</label>
                                                </td>
                                                <td></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div
                            id="searchOptions"
                            className="screenlet"
                            style={{marginTop: "10px"}}
                        >
                            <div className="screenlet-title-bar">
                                <ul>
                                    <li className="h3">
                                        <a href="#"> Payment Details </a>
                                    </li>
                                </ul>
                            </div>

                            <div id="searchOptions_col" className="screenlet-body">
                                <div id="search-options">
                                    <table className="paymentTable">
                                        <thead className="makeHeaderBgBlue">
                                        <tr>
                                            <th width="20%">Payment Mode</th>
                                            <th width="15%">Paid Amount</th>
                                            <th width="15%">Transaction Id</th>
                                            <th width="15%">Payment Date</th>
                                            <th width="10%">Transaction Result</th>
                                            <th width="10%">Code</th>
                                            <th width="20%">Created By</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {/* <tr>
                        <td width="20%">Promotion Coupon</td>
                        <td width="15%"></td>
                        <td width="15%"></td>
                        <td width="15%"></td>
                        <td width="15%"></td>
                        <td width="10%"></td>
                        <td width="25%"></td>
                      </tr> */}
                                        {/*<tr>*/}
                                        {/*    <td width="20%">*/}
                                        {/*        {this.props.orderView?.[0]?.[0]?.order?.paymentMethod}*/}
                                        {/*    </td>*/}
                                        {/*    <td width="15%">*/}
                                        {/*        {this.props.orderView?.[0]?.[0]?.order?.paymentMethod*/}
                                        {/*            ? this.props.orderView?.[0]?.[0]?.order?.paidAmount*/}
                                        {/*            : ""}*/}
                                        {/*    </td>*/}
                                        {/*    <td width="15%">*/}
                                        {/*        {this.props.orderView?.[0]?.[0]?.order?.transactionId}*/}
                                        {/*    </td>*/}
                                        {/*    <td width="15%">*/}
                                        {/*        {this.props.orderView?.[0]?.[0]?.order?.paymentDate*/}
                                        {/*            ? moment(*/}
                                        {/*                this.props.orderView?.[0]?.[0]?.order*/}
                                        {/*                    ?.paymentDate*/}
                                        {/*            )*/}
                                        {/*                .utc(true)*/}
                                        {/*                .format("Y-MM-DD hh:mm:ss")*/}
                                        {/*            : ""}*/}
                                        {/*    </td>*/}
                                        {/*    <td width="15%">*/}
                                        {/*        {this.props.orderView?.[0]?.[0]?.order?.paymentStatus*/}
                                        {/*            ? "Received"*/}
                                        {/*            : "Not Received"}*/}
                                        {/*    </td>*/}
                                        {/*    <td width="10%"></td>*/}
                                        {/*    <td width="25%">*/}
                                        {/*        {*/}
                                        {/*            this.props.orderView?.[0]?.[0]?.order*/}
                                        {/*                ?.transactionCreatedBy*/}
                                        {/*        }*/}
                                        {/*    </td>*/}
                                        {/*</tr>*/}
                                        {this.props.orderView?.[0]?.[0]?.order?.orderPayments?.map((payment)=>{
                                            return(
                                                <tr>
                                                    <td width="20%">
                                                        {payment.paymentMode}
                                                    </td>
                                                    <td width="15%">
                                                        {payment?.paidAmount}
                                                    </td>
                                                    <td width="15%">
                                                        {payment?.transactionId}
                                                    </td>
                                                    <td width="15%">
                                                        {payment?.paymentDate}
                                                    </td>
                                                    <td width="15%">
                                                        {"Received"
                                                            }
                                                    </td>
                                                    <td width="10%"></td>
                                                    <td width="25%">
                                                        {
                                                            payment?.createdBy
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*1st row ends here*/}

                {/*2nd row*/}
                {/*{this.props.orderView?.carts?.map(cart => (*/}
                {/*    */}
                {/*))}*/}
                {/* {this.getSubOrder(this.props.orderView)} */}
                {this.state.orders.map((order, ind) => {
                    return this.subOrder(order, ind);
                })}
                <div>
                    <div
                        id="searchOptions"
                        className="screenlet"
                        style={{marginTop: "10px"}}
                    >
                        <div className="screenlet-title-bar">
                            <ul>
                                <li className="h3">
                                    <a href="#">Notes</a>
                                </li>
                                <li>
                                    <a href={"/ordermgr/control/createNewNote?orderId=" + this.props?.orderView?.[0]?.[0]?.order._id}>Create
                                        New</a>
                                </li>
                            </ul>
                        </div>

                        <div id="searchOptions_col" className="screenlet-body">
                            <table class={'basic-table'} cellSpacing={"0"}>
                                <tbody>
                                {this.props?.orderView?.[0]?.[0]?.order?.notes?.sort((a,b)=>this.getTextSort(-1,a._id,b._id)).map((note)=>(
                                    <tr width={"100%"}>
                                        <td width={"33%"}>
                                            <div style={{display:'flex',flexDirection:'row'}}>
                                                <span>By: {" "}</span>
                                                <span>{note?.createdBy}</span>
                                            </div>
                                            <div style={{display:'flex',flexDirection:'row'}}>
                                                <span>At:{" "}</span>
                                                <span>{moment(note.createdDate).utc(true).format("DD-MM-YYYY HH:mm")}</span>
                                            </div>
                                        </td>
                                        <td width={"45%"}>{note.text}</td>
                                        <td width={"22%"} style={{textAlign:'right'}}><button>make public</button></td>
                                    </tr>
                                ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {/*3rd row ends here*/}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    orderView: state.orderReducer.orderView,
});

export default withRouter(
    connect(mapStateToProps, {
        getOrder,
        updateAddress,
        allActiveCountries,
        getCities,
        editAddress,
        editSender,
        setCartsAndParties,
        getParties,
        setPartyId,
        setOrderPrice,
        cancelOrder,
        changeHoldStatus,
        getImage,
        actionSendPaymentLink,
        updateMessage
    })(OrderView)
);
