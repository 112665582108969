import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Collapse } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { withRouter, Link } from "react-router-dom";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  allActiveParties,
  searchParty,
} from "../../../../store/actions/partyManagerActions.js";

export class partyPopUp extends Component {
  state = {
    collapse: false,
    searchPartyId: "",
    partyFilterType: "contains",
    ignorePartyId: true,
    partyTypeId: "",
    firstNameFilterType: "contains",
    firstName: "",
    ignoreFirstName: true,
    lastNameFilterType: "contains",
    lastName: "",
    ignoreLastName: true,
    groupNameFilterType: "contains",
    groupName: "",
    ignoreGroupName: true,
    parties: [],
    pageNo: 1,
    limit: 15,
    pagesIndex: [],
  };
  async componentDidMount() {
    await this.props.allActive();
  }
  render() {
    return (
      <div>
        <Modal
          open={this.props.modalOpen}
          onClose={this.props.handleClose}
          //   style={{ overflow: "scroll" }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            style={{
              height: "600px",
              width: "600px",
              backgroundColor: "white",
              position: "absolute",
              top: "50px",
              border: "2px solid white",
              left: "600px",
              alignItems: "center",
              display: "flex",
              overflow: "scroll",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                backgroundColor: "#527894",
                borderRadius: "5px",
                width: "100%",
                height: "30px",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <CloseIcon
                onClick={(e) => this.props.handleClose(e)}
                style={{
                  color: "#6caad4",
                  marginRight: "5px",
                  backgroundColor: "#e3f1fc",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              />
            </div>
            {/* <div
              style={{
                display: "flex",
                width: "98%",
                height: "40px",
                backgroundColor: "#f5f5f5",
                marginTop: "10px",
                border: "1px solid #e0e0e0",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p style={{ fontSize: "15px", marginLeft: "10px" }}>
                Look up Party
              </p>
              {this.state.collapse && (
                <AddIcon
                  onClick={() =>
                    this.setState({ collapse: !this.state.collapse })
                  }
                  style={{
                    fontSize: "small",
                    marginRight: "10px",
                    border: "1px solid #e0e0e0",
                  }}
                />
              )}
              {!this.state.collapse && (
                <RemoveIcon
                  onClick={() =>
                    this.setState({ collapse: !this.state.collapse })
                  }
                  style={{
                    fontSize: "small",
                    marginRight: "10px",
                    border: "1px solid #e0e0e0",
                  }}
                />
              )}
            </div>
            <Collapse
              in={!this.state.collapse}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "98%",
                border: "1px solid #e0e0e0",
                minHeight: "1vh !important",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: "36px",
                  marginTop: "20px",
                }}
              >
                <p>PartyId</p>
                <select
                  style={{ display: "flex", marginLeft: "10px" }}
                  id="EditProduct_productTypeId"
                  name="productTypeId"
                  value={this.state.partyFilterType}
                  onChange={(e) =>
                    this.setState({
                      partyFilterType: e.target.value,
                    })
                  }
                  size="1"
                >
                  <option value="equals">Equals</option>
                  <option value="beginWith">Begins With</option>
                  <option value="contains">Contains</option>
                  <option value="isEmpty">Is Empty</option>
                  <option value="notEqual">Not Equal</option>
                </select>
                <input
                  style={{ display: "flex", marginLeft: "5px" }}
                  onChange={(e) =>
                    this.setState({ searchPartyId: e.target.value })
                  }
                  value={this.state.searchPartyId}
                  type="text"
                />
                <input
                  checked="checked"
                  type="checkbox"
                  style={{ display: "flex", marginLeft: "5px" }}
                  checked={this.state.ignorePartyId}
                  onChange={(e) =>
                    this.setState((prevState) => ({
                      ignorePartyId: !prevState.ignorePartyId,
                    }))
                  }
                  value="Y"
                />
                Ignore Case
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "5px",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <p>Party Type Id</p>
                <select
                  style={{ display: "flex", marginLeft: "10px" }}
                  value={this.state.partyTypeId}
                  onChange={(e) =>
                    this.setState({
                      partyTypeId: e.target.value,
                    })
                  }
                  size="1"
                >
                  <option value=""></option>
                  <option value="Automated_Agent">Automated agent</option>
                  <option value="Corporation">Corporation</option>
                  <option value="Family">Family</option>
                  <option value="GovernMentAgency">Government Agency</option>
                  <option value="InformalGroup">Informal Group</option>
                  <option value="LegalOrgnization">Legal Orgnization</option>
                  <option value="PartyGroup">Party Group</option>
                  <option value="Person">Person</option>
                  <option value="team">Team</option>
                </select>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: "17px",
                  marginTop: "10px",
                }}
              >
                <p>First Name</p>
                <select
                  style={{ display: "flex", marginLeft: "10px" }}
                  id="EditProduct_productTypeId"
                  name="productTypeId"
                  value={this.state.firstNameFilterType}
                  onChange={(e) =>
                    this.setState({
                      firstNameFilterType: e.target.value,
                    })
                  }
                  size="1"
                >
                  <option value="equals">Equals</option>
                  <option value="beginsWith">Begins With</option>
                  <option value="contains">Contains</option>
                  <option value="isEmpty">Is Empty</option>
                  <option value="notEqual">Not Equal</option>
                </select>
                <input
                  style={{ display: "flex", marginLeft: "5px" }}
                  onChange={(e) => this.setState({ firstName: e.target.value })}
                  value={this.state.firstName}
                  type="text"
                />
                <input
                  checked="checked"
                  type="checkbox"
                  style={{ display: "flex", marginLeft: "5px" }}
                  checked={this.state.ignoreFirstName}
                  onChange={(e) =>
                    this.setState((prevState) => ({
                      ignoreFirstName: !prevState.ignoreFirstName,
                    }))
                  }
                  value="Y"
                />
                Ignore Case
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: "19px",
                  marginTop: "10px",
                }}
              >
                <p>Last Name</p>
                <select
                  style={{ display: "flex", marginLeft: "10px" }}
                  id="EditProduct_productTypeId"
                  name="productTypeId"
                  value={this.state.lastNameFilterType}
                  onChange={(e) =>
                    this.setState({
                      lastNameFilterType: e.target.value,
                    })
                  }
                  size="1"
                >
                  <option value="equals">Equals</option>
                  <option value="beginsWith">Begins With</option>
                  <option value="contains">Contains</option>
                  <option value="isEmpty">Is Empty</option>
                  <option value="notEqual">Not Equal</option>
                </select>
                <input
                  style={{ display: "flex", marginLeft: "5px" }}
                  onChange={(e) => this.setState({ lastName: e.target.value })}
                  value={this.state.lastName}
                  type="text"
                />
                <input
                  checked="checked"
                  type="checkbox"
                  style={{ display: "flex", marginLeft: "5px" }}
                  checked={this.state.ignoreLastName}
                  onChange={(e) =>
                    this.setState((prevState) => ({
                      ignoreLastName: !prevState.ignoreLastName,
                    }))
                  }
                  value="Y"
                />
                Ignore Case
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: "9px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <p>Group Name</p>
                <select
                  style={{ display: "flex", marginLeft: "10px" }}
                  id="EditProduct_productTypeId"
                  name="productTypeId"
                  value={this.state.groupNameFilterType}
                  onChange={(e) =>
                    this.setState({
                      groupNameFilterType: e.target.value,
                    })
                  }
                  size="1"
                >
                  <option value="equals">Equals</option>
                  <option value="beginsWith">Begins With</option>
                  <option value="contains">Contains</option>
                  <option value="isEmpty">Is Empty</option>
                  <option value="notEqual">Not Equal</option>
                </select>
                <input
                  style={{ display: "flex", marginLeft: "5px" }}
                  onChange={(e) => this.setState({ groupName: e.target.value })}
                  value={this.state.groupName}
                  type="text"
                />
                <input
                  checked="checked"
                  type="checkbox"
                  style={{ display: "flex", marginLeft: "5px" }}
                  checked={this.state.ignoreGroupName}
                  onChange={(e) =>
                    this.setState((prevState) => ({
                      ignoreGroupName: !prevState.ignoreGroupName,
                    }))
                  }
                  value="Y"
                />
                Ignore Case
              </div>
              <div style={{ marginLeft: "80px", marginBottom: "10px" }}>
                <button>Find</button>
              </div>
            </Collapse> */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "10px",
                width: "100%",
              }}
            >
              <button
                onClick={() => {
                  if (this.state.pageNo > 1) {
                    this.setState({ pageNo: this.state.pageNo - 1 });
                  }
                }}
                style={{
                  display: "flex",
                  marginLeft: "10px",
                }}
              >
                Previous
              </button>

              {/* <select
                style={{ display: "flex", marginLeft: "10px" }}
                id="EditProduct_productTypeId"
                name="page"
                value={this.state.pageNo}
                onChange={(e) => {
                  this.setState({
                    pageNo: e.target.value,
                  });
                }}
                size="1"
              >
                {this.state.pagesIndex.map((page, index) => (
                  <option value={index + 1}>{index + 1}</option>
                ))}
              </select> */}
              <button
                onClick={() => {
                  let pages = Math.ceil(
                    this.state.parties.length / this.state.limit
                  );
                  if (this.state.pageNo < pages) {
                    this.setState({ pageNo: this.state.pageNo + 1 });
                  }
                }}
                style={{
                  display: "flex",
                  marginRight: "10px",
                }}
              >
                next
              </button>
            </div>
            <div style={{ width: "100%" }}>
              <table class="basic-table" cellspacing="15">
                <tr>
                  <th> Party Id</th>
                  <th>Email</th>
                  <th>Party Type Id</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Group Name</th>
                </tr>
                {this.state.parties.map(
                  (party, index) =>
                    (this.state.pageNo - 1) * this.state.limit <= index &&
                    this.state.pageNo * this.state.limit > index && (
                      <tr>
                        <td>
                          <button
                            onClick={() => this.props.partySelect(party._id)}
                          >
                            {party._id}
                          </button>{" "}
                        </td>
                        <td>{party.userLoginId}</td>
                        <td>{party.type}</td>
                        <td>{party.name.split(",")?.[1]}</td>
                        <td>{party.name.split(",")?.[0]}</td>
                        <td>{party.groupName}</td>
                      </tr>
                    )
                )}
              </table>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
  componentDidUpdate(prevProps) {
    if (this.props.parties !== prevProps.parties) {
      this.setState({ parties: this.props.parties });
    }
  }
}
const mapStateToProps = (state) => ({
  parties: state.partyManager?.parties?.parties,
});

const mapDispatchToProps = (dispatch, router) => ({
  allActive: () => dispatch(allActiveParties()),
  search: (data) => dispatch(searchParty(data, router)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(partyPopUp)
);
