import {postRequest, putRequest, getRequest} from "../services";
import {redirect, Notify} from "../../constants/utils";

export const addToBlock = (data, router) => async (dispatch, getState) => {
    var token = getState().auth?.token;
    // dispatch({type: "CATALOG_SUBMIT", payload: true});
    await postRequest("bulkCreate/create", data, true, {
        "access-token": token,
    }).then((response) => {
        console.log(response);
    });
    // dispatch({type: "CATALOG_SUBMIT", payload: false});
};

export const editToBlock = (data, router) => async (dispatch, getState) => {
    var token = getState().auth?.token;
    const id = data.id;
    delete data.id;
    // dispatch({type: "CATALOG_SUBMIT", payload: true});
    await putRequest("bulkCreate/update/" + id, data, true, {
        "access-token": token,
    }).then((response) => {
        console.log(response);
    });
    // dispatch({type: "CATALOG_SUBMIT", payload: false});
};