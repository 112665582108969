export const main_navigation = [
  {
    active: false,
    uri: "accounting",
    route: "/accounting/control/main",
    text: "Accounting",
    title: "Accounting",
  },
  {
    active: false,
    uri: "ap",
    route: "/ap/control/main",
    text: "Accounting - AP",
    title: "Accounting - AP",
  },
  {
    active: false,
    uri: "ar",
    route: "/ar/control/main",
    text: "Accounting - AR",
    title: "Accounting - AR",
  },
  {
    active: false,
    uri: "catalog",
    route: "/catalog/control/main",
    text: "Catalog",
    title:
      "Product Catalog management, Categories, Product, Price rules, Promotions, Product Store",
  },
  {
    active: false,
    uri: "ordermgr",
    route: "/ordermgr/control/main",
    text: "Order",
    title:
      "Sales Order management, Purchase order, Customer request, Requirement, Quote, Statistics",
  },
  {
    active: false,
    uri: "partymgr",
    route: "/partymgr/control/main",
    text: "Party",
    title:
      "Customers - Suppliers - Employee Management, Communications, Classifications",
  },
  {
    active: false,
    uri: "geomgr",
    route: "/geomgr/control/main",
    text: "Geo",
    title: "Geo - ",
  },
];
const ct_base = "/catalog/control/";
const partymgr_base = "/partymgr/control/";
const ordermgr_base = "/ordermgr/control/";
const geomgr_base = "/geomgr/control/";
export const app_navigation = {
  catalog: {
    title: "Catalog Manager",
    items: [
      {
        uri: "main",
        route: ct_base + "main",
        text: "Main",
      },
      {
        uri: "FindCatalog",
        route: ct_base + "FindCatalog",
        text: "Catalogs",
      },
      {
        uri: "FindCategory",
        route: ct_base + "FindCategory",
        text: "Categories",
      },
      {
        uri: "FindProduct",
        route: ct_base + "FindProduct",
        text: "Products",
      },
      {
        uri: "FindNavigation",
        route: ct_base + "FindNavigation",
        text: "Navigation",
      },
      {
        uri: "FindMeta",
        route: ct_base + "FindMeta",
        text: "Meta",
      },
      {
        uri: "EditFeatureCategories",
        route: ct_base + "EditFeatureCategories",
        text: "Features",
      },
      {
        uri: "FindProductPromo",
        route: ct_base + "FindProductPromo",
        text: "Promos",
      },
      // {
      //   uri: "FindProductPriceRules",
      //   route: ct_base + "FindProductPriceRules",
      //   text: "Price Rules",
      // },
      // {
      //   uri: "FindProductStore",
      //   route: ct_base + "FindProductStore",
      //   text: "Stores",
      // },
      // {
      //   uri: "ListParentProductStoreGroup",
      //   route: ct_base + "ListParentProductStoreGroup",
      //   text: "Product Store Groups",
      // },
      // {
      //   uri: "editKeywordThesaurus",
      //   route: ct_base + "editKeywordThesaurus",
      //   text: "Thesaurus",
      // },
      // {
      //   uri: "FindReviews",
      //   route: ct_base + "FindReviews",
      //   text: "Reviews",
      // },
      // {
      //   uri: "FindProductConfigItems",
      //   route: ct_base + "FindProductConfigItems",
      //   text: "Configurations",
      // },
      // {
      //   uri: "FindSubscription",
      //   route: ct_base + "FindSubscription",
      //   text: "Subscriptions",
      // },
      {
        uri: "ListShipmentMethodTypes",
        route: ct_base + "ListShipmentMethodTypes",
        text: "Shipping",
      },
      {
        uri: "Cities",
        route: ct_base + "Cities",
        text: "Cities",
      },
      // {
      //   uri: "Imagemanagement",
      //   route: ct_base + "Imagemanagement",
      //   text: "Image Management",
      // },
      // {
      //   uri: "viewCategoryTags",
      //   route: ct_base + "viewCategoryTags",
      //   text: "Category Tags",
      // },
      {
        uri: "ProductBulkUpload",
        route: ct_base + "ProductBulkUpload",
        text: "Product Bulk Upload",
      },
      // {
      //   uri: "PopulateProductToCategories",
      //   route: ct_base + "PopulateProductToCategories",
      //   text: "Populate Product Categories",
      // },
      {
        uri: "BulkBlock",
        route: ct_base + "BulkBlock",
        text: "Bulk Block",
      },
      {
        uri: "OccasionMessages",
        route: ct_base + "OccasionMessages",
        text: "Occasion Messages",
      },
      // {
      //   uri: "commisionsetup",
      //   route: ct_base + "commisionsetup",
      //   text: "Commission Setup",
      // },
      // {
      //   uri: "solrImport",
      //   route: ct_base + "solrImport",
      //   text: "Solr Import",
      // },
    ],
  },
  partymgr: {
    title: "Party Manager",
    items: [
      {
        uri: "main",
        route: partymgr_base + "main",
        text: "Main",
      },
      // {
      //   uri: "findparty",
      //   route: partymgr_base + "findparty",
      //   text: "Parties",
      // },
      // {
      //   uri: "MyCommunicationEvents",
      //   route: partymgr_base + "MyCommunicationEvents",
      //   text: "My Communications",
      // },
      // {
      //   uri: "FindCommunicationEvents",
      //   route: partymgr_base + "FindCommunicationEvents",
      //   text: "Communications",
      // },
      // {
      //   uri: "findVisits",
      //   route: partymgr_base + "findVisits",
      //   text: "Visits",
      // },
      // {
      //   uri: "listLoggedInUsers",
      //   route: partymgr_base + "listLoggedInUsers",
      //   text: "Logged-in Users",
      // },
      // {
      //   uri: "showclassgroups",
      //   route: partymgr_base + "showclassgroups",
      //   text: "Classifications",
      // },
      // {
      //   uri: "FindSecurityGroup",
      //   route: partymgr_base + "FindSecurityGroup",
      //   text: "Security",
      // },
      // {
      //   uri: "addressMatchMap",
      //   route: partymgr_base + "addressMatchMap",
      //   text: "Address Match Map",
      // },
      // {
      //   uri: "partyInvitation",
      //   route: partymgr_base + "partyInvitation",
      //   text: "Invitation",
      // },
      // {
      //   uri: "ImportExport",
      //   route: partymgr_base + "ImportExport",
      //   text: "Import/Export",
      // },
    ],
  },
  ordermgr: {
    title: "ORDER MANAGER",
    items: [
      {
        uri: "main",
        route: ordermgr_base + "main",
        text: "Main",
      },
      // {
      //   uri: "FindRequest",
      //   route: ordermgr_base + "FindRequest",
      //   text: "Requests",
      // },
      // {
      //   uri: "FindQuote",
      //   route: ordermgr_base + "FindQuote",
      //   text: "Quotes",
      // },
      {
        uri: "findorders",
        route: ordermgr_base + "findorders",
        text: "Find Orders",
      },
      {
        uri: "orderentry",
        route: ordermgr_base + "orderentry",
        text: "Order Entry",
      },
      // {
      //   uri: "findreturn",
      //   route: ordermgr_base + "findreturn",
      //   text: "Returns",
      // },
      // {
      //   uri: "FindRequirements",
      //   route: ordermgr_base + "FindRequirements",
      //   text: "Requirements",
      // },
      {
        uri: "OrderPurchaseReportOptions",
        route: ordermgr_base + "OrderPurchaseReportOptions",
        text: "Reports",
      },
      {
        uri: "pnc",
        route: ordermgr_base + "pnc",
        text: "PNC",
      },
      {
        uri: "pni",
        route: ordermgr_base + "pni",
        text: "PNI",
      },
      {
        uri: "abc",
        route: ordermgr_base + "abc",
        text: "ABC",
      },
      {
        uri: "OrderNotPaid",
        route: ordermgr_base + "OrderNotPaid",
        text: "ORDER-NOT-PAID",
      },
      // {
      //   uri: "releaseBlockAgentView",
      //   route: ordermgr_base + "releaseBlockAgentView",
      //   text: "Release Agents",
      // },
      // {
      //   uri: "TestPaymentGateways",
      //   route: ordermgr_base + "TestPaymentGateways",
      //   text: "Test Payment Gateways",
      // },
      {
        uri: "SupervisorDashboard?viewhold=Y&amp;changeStatusAndTypeState=Y",
        route:
          ordermgr_base +
          "SupervisorDashboard?viewhold=Y&amp;changeStatusAndTypeState=Y",
        text: "Supervisor Dashboard",
      },
      {
        uri: "refundOrders",
        route: ordermgr_base + "refundOrders",
        text: "Refund Orders",
      },
      // {
      //   uri: "ShipmentManifestReport",
      //   route: ordermgr_base + "ShipmentManifestReport",
      //   text: "Shipment Manifest Report",
      // },
      {
        uri: "UploadOrdersInBulk",
        route: ordermgr_base + "UploadOrdersInBulk",
        text: "Upload Orders in bulk",
      },
      // {
      //   uri: "FindTrackingCode",
      //   route: ordermgr_base + "FindTrackingCode",
      //   text: "Tracking",
      // },
      {
        uri: "ListOrderSource",
        route: ordermgr_base + "ListOrderSource",
        text: "Order Source",
      },
      // {
      //   uri: "considerInSlaOrders",
      //   route: ordermgr_base + "considerInSlaOrders",
      //   text: "Consider In SLA Orders",
      // },
    ],
  },
  geomgr: {
    title: "GEO MANAGER",
    items: [
      {
        uri: "main",
        route: geomgr_base + "main",
        text: "Main",
      },
      {
        uri: "types",
        route: geomgr_base + "types",
        text: "Types",
      },
    ],
  },
};
export const editCatalogNav = [
  {
    active: false,
    text: "Catalog",
    route: ct_base + "EditProdCatalog?prodCatalogId=",
  },
  {
    active: false,
    text: "Stores",
    route: ct_base + "EditProdCatalogStores?prodCatalogId=",
  },
  {
    active: false,
    text: "Parties",
    route: ct_base + "EditProdCatalogParties?prodCatalogId=",
  },
  {
    active: false,
    text: "Categories",
    route: ct_base + "EditProdCatalogCategories?prodCatalogId=",
  },
];
export const editProductNav = [
  {
    active: false,
    text: "Product",
    route: ct_base + "EditFnpProduct?productId=",
  },
  {
    active: false,
    text: "Prices",
    route: ct_base + "Prices?productId=",
  },
  {
    active: false,
    text: "Content",
    route: ct_base + "EditProductContent?productId=",
  },
  {
    active: false,
    text: "IDs",
    route: ct_base + "EditProductGoodIdentifications?productId=",
  },
  {
    active: false,
    text: "Geos",
    route: ct_base + "EditProductGeos?productId=",
  },
  {
    active: false,
    text: "Categories",
    route: ct_base + "EditProductCategories?productId=",
  },
  {
    active: false,
    text: "Keywords",
    route: ct_base + "EditProductKeyword?productId=",
  },
  {
    active: false,
    text: "Associations",
    route: ct_base + "EditProductAssoc?productId=",
  },
  {
    active: false,
    text: "Manufacturing",
    route: ct_base + "EditProductPrices?productId=",
  },
  {
    active: false,
    text: "Costs",
    route: ct_base + "EditProductCosts?productId=",
  },
  {
    active: false,
    text: "Attributes",
    route: ct_base + "EditProductAttributes?productId=",
  },
  {
    active: false,
    text: "Features",
    route: ct_base + "EditProductFeatures?productId=",
  },
  {
    active: false,
    text: "Facilities",
    route: ct_base + "EditProductFacilities?productId=",
  },
  {
    active: false,
    text: "Locations",
    route: ct_base + "EditProductLocations?productId=",
  },
  {
    active: false,
    text: "Inventory",
    route: ct_base + "EditProductInventoryItems?productId=",
  },
  {
    active: false,
    text: "Suppliers",
    route: ct_base + "EditProductSuppliers?productId=",
  },
  {
    active: false,
    text: "Agreements",
    route: ct_base + "EditProductAgreements?productId=",
  },
  {
    active: false,
    text: "Accounts",
    route: ct_base + "EditProductGlAccounts?productId=",
  },
  {
    active: false,
    text: "Payment Types",
    route: ct_base + "EditProductPaymentMethodTypes?productId=",
  },
  {
    active: false,
    text: "Maintenance",
    route: ct_base + "EditProductMaintenance?productId=",
  },

  {
    active: false,
    text: "Meters",
    route: ct_base + "EditProductMeters?productId=",
  },
  {
    active: false,
    text: "Subscription Resources",
    route: ct_base + "EditProductSubscriptionResources?productId=",
  },
  {
    active: false,
    text: "Quick Admin",
    route: ct_base + "EditProductQuickAdmin?productId=",
  },
  {
    active: false,
    text: "Vendor",
    route: ct_base + "EditVendorProduct?productId=",
  },
  {
    active: false,
    text: "Configurations",
    route: ct_base + "EditProductConfig?productId=",
  },
  {
    active: false,
    text: "Work Effort",
    route: ct_base + "EditProductWorkEfforts?productId=",
  },
  {
    active: false,
    text: "Parties",
    route: ct_base + "EditProductParties?productId=",
  },
  {
    active: false,
    text: "Orders",
    route: ct_base + "EditProductOrders?productId=",
  },
  {
    active: false,
    text: "Communications",
    route: ct_base + "EditProductCommunicationEvents?productId=",
  },
  {
    active: false,
    text: "Group Order",
    route: ct_base + "ViewProductGroupOrder?productId=",
  },
  {
    active: false,
    text: "Cities",
    route: ct_base + "ProductWiseCitiesList?productId=",
  },
];
