import React, { Component } from "react";
import { connect } from "react-redux";
import { searchProduct,deleteProduct } from "../../../../store/actions/catalogActions";
import { withRouter, Link } from "react-router-dom";
// import DataTable from "../../../components/DataTable";

export class FindCatalog extends Component {
  handleDeleteProduct=(id)=>{
    this.props.deleteProduct(id,this.callback)
  }
  callback=()=>{
    let data={}
    this.props.search(data,this.state.pageSize,this.state.pageNumber)
  }
  render() {
    const { name, _id } = this.state;
    const { submitted, products } = this.props;
    return (
      <div id="content-main-section" class="leftonly">
        <div id="centerdiv" class="no-clear">
          <div class="button-bar button-style-2">
            <ul>
              <li>
                <ul>
                  <li class="buttontext create">
                    <Link
                      class="buttontext create"
                      to="/catalog/control/EditProduct"
                    >
                      New Product
                    </Link>
                  </li>
                  <li>
                    <Link to="/catalog/control/EditProductTag">Tags</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="page-title">Find Product</div>
          <div class="clear"> </div>
          <div id="searchOptions" class="screenlet">
            <div class="screenlet-title-bar">
              <ul>
                <li class="h3">Search Options</li>
                <li class="expanded">
                  <a href title="Collapse">
                    {" "}
                  </a>
                </li>
              </ul>
            </div>
            <div id="searchOptions_col" class="screenlet-body">
              <div id="search-options">
                <form
                  id="FindProduct"
                  class="basic-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.handleSubmit()
                  }}
                >
                  <table class="basic-table" cellspacing="0">
                    <tbody>
                      <tr>
                        <td class="label">
                          <label
                            id="FindProduct_productId_title"
                            for="FindProduct_productId"
                          >
                            Product ID
                          </label>
                        </td>
                        <td>
                          <select
                            class="selectBox"
                            name="idFilterType"
                            value={this.state.idFilterType}
                            onChange={(e) => this.handleChange(e)}
                          >
                            <option value="equals">Equals</option>
                            <option value="beginWith">Begins With</option>
                            <option selected="selected" value="contains">
                              Contains
                            </option>
                            <option value="empty">Is Empty</option>
                            <option value="notEqual">Not Equal</option>
                          </select>
                          <input
                            name="_id"
                            value={_id}
                            onChange={(e) => this.handleChange(e)}
                            size="25"
                            type="text"
                          />
                          <input
                            checked="checked"
                            name="productId_ic"
                            type="checkbox"
                            checked={this.state.productId_ic}
                            onChange={(e) =>
                              this.setState((prevState) => ({
                                productId_ic: !prevState.productId_ic,
                              }))
                            }
                            value="Y"
                          />
                          Ignore Case
                        </td>
                      </tr>
                      <tr>
                        <td class="label">
                          <label
                            id="FindProduct_internalName_title"
                            for="FindProduct_internalName"
                          >
                            Internal Name
                          </label>
                        </td>
                        <td>
                          <select
                            class="selectBox"
                            name="nameFilterType"
                            value={this.state.nameFilterType}
                            onChange={(e) => this.handleChange(e)}
                          >
                            <option value="equals">Equals</option>
                            <option value="beginWith">Begins With</option>
                            <option selected="selected" value="contains">
                              Contains
                            </option>
                            <option value="empty">Is Empty</option>
                            <option value="notEqual">Not Equal</option>
                          </select>
                          <input
                            name="name"
                            value={name}
                            onChange={(e) => this.handleChange(e)}
                            size="25"
                            type="text"
                          />
                          <input
                            checked="checked"
                            checked={this.state.internalName_ic}
                            onChange={(e) =>
                              this.setState((prevState) => ({
                                internalName_ic: !prevState.internalName_ic,
                              }))
                            }
                            name="internalName_ic"
                            type="checkbox"
                            value="Y"
                          />
                          Ignore Case
                        </td>
                      </tr>
                      <tr>
                        <td class="label">
                          <label
                            id="FindProduct_idValue_title"
                            for="FindProduct_idValue"
                          >
                            SKU
                          </label>
                        </td>
                        <td>
                          <select class="selectBox" name="skuFilterType" value={this.state.skuFilterType} onChange={(e)=>this.setState({skuFilterType:e.target.value})}>
                            <option value="equals">Equals</option>
                            <option value="like">Begins With</option>
                            <option selected="selected" value="contains">
                              Contains
                            </option>
                            <option value="empty">Is Empty</option>
                            <option value="notEqual">Not Equal</option>
                          </select>
                          <input name="sku" size="25" type="text" value={this.state.sku} onChange={(e)=>this.handleChange(e)} />{" "}
                          <input
                            checked="checked"
                            name="idValue_ic"
                            checked={this.state.sku_ic}
                            onChange={(e) =>
                              this.setState((prevState) => ({
                                sku_ic: !prevState.sku_ic,
                              }))
                            }
                            type="checkbox"
                            value="Y"
                          />{" "}
                          Ignore Case
                        </td>
                      </tr>
                      <tr>
                        <td className="label">
                          <label
                              id="FindProduct_internalName_title"
                              htmlFor="FindProduct_internalName"
                          >
                            Sub Category
                          </label>
                        </td>
                        <td>
                          <select
                              className="selectBox"
                              name="subCategoryFilterType"
                              value={this.state.subCategoryFilterType}
                              onChange={(e) => this.handleChange(e)}
                          >
                            <option value="equals">Equals</option>
                            <option value="beginWith">Begins With</option>
                            <option selected="selected" value="contains">
                              Contains
                            </option>
                            <option value="empty">Is Empty</option>
                            <option value="notEqual">Not Equal</option>
                          </select>
                          <input
                              name="subCategory"
                              value={this.state.subCategory}
                              onChange={(e) => this.handleChange(e)}
                              size="25"
                              type="text"
                          />
                          <input
                              checked="checked"
                              checked={this.state.subCategoryCheck}
                              onChange={(e) =>
                                  this.setState((prevState) => ({
                                    subCategoryCheck: !prevState.subCategoryCheck,
                                  }))
                              }
                              name="internalName_ic"
                              type="checkbox"
                              value="Y"
                          />
                          Ignore Case
                        </td>
                      </tr>
                      <tr>
                        <td class="label"> </td>
                        <td colspan="4">
                          <input
                            class="smallSubmit"
                            name="submitButton"
                            type="submit"
                            value="Find"
                            submitted={submitted}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
          </div>
          <div id="screenlet_1" class="screenlet">
            <div id="screenlet_1_col" class="screenlet-body no-padding">
              <h3>Search Results</h3>
              <div id="search-results">
                {/*<DataTable>*/}
                <React.Fragment>
                  <div className="nav-pager">
                    <ul>
                      <li className="nav-first-disabled">First</li>
                      <li className="nav-previous-disabled">Previous</li>
                      <li className="nav-page-select">
                        Page
                        <select name="page" size="1" value={this.state.pageNumber} onChange={async(e)=> {
                          await this.setState({pageNumber: e.target.value})
                          this.handleSubmit()
                        }}>
                          {Array(this.state.noOfPages).fill(0).map((val,i)=>(
                                  <option value={i}>
                                    {i + 1}
                                  </option>
                              )
                          )}

                        </select>
                      </li>
                      <li className="nav-next-disabled">Next</li>
                      <li className="nav-last-disabled">Last</li>
                      <li className="nav-pagesize">
                        <select name="pageSize" size="1" value={this.state.pageSize} onChange={async(e)=>{
                          await this.setState({pageSize:e.target.value,pageNumber:0});
                          this.handleSubmit()
                        }}>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option defaultValue value="200">
                            200
                          </option>
                        </select>
                        Items per page
                      </li>
                      <li className="nav-displaying">Displaying 1 - 8 of 8</li>
                    </ul>
                  </div>
                  <table class="basic-table hover-bar" cellspacing="0">
                    <tbody>
                      <tr class="header-row-2">
                        <td>
                          <a
                            class="sort-order"
                            href="/catalog/control/FindProduct?sortField=productId&amp;noConditionFind=N"
                            onClick={async (e) => {
                              e.preventDefault();
                              if (
                                this.state.orderString[0] == "productId" &&
                                this.state.orderString[1] == "DESC"
                              ) {
                                await this.setState({
                                  orderString: ["productId", "ASC"],
                                });
                              } else {
                                await this.setState({
                                  orderString: ["productId", "DESC"],
                                });
                              }

                              this.handleSubmit();
                            }}
                          >
                            Product Id
                          </a>
                        </td>
                        <td>
                          <Link
                            class="sort-order"
                            to="/catalog/control/FindProduct?sortField=idValue&amp;noConditionFind=N"
                          >
                            SKU
                          </Link>
                        </td>
                        <td>
                          <Link
                            class="sort-order"
                            to="/catalog/control/FindProduct?sortField=productTypeId&amp;noConditionFind=N"
                          >
                            Product Type Id
                          </Link>
                        </td>
                        <td>
                          <Link
                            class="sort-order"
                            to="/catalog/control/FindProduct?sortField=internalName&amp;noConditionFind=N"
                            onClick={async (e) => {
                              e.preventDefault();

                              if (
                                this.state.orderString[0] == "internalName" &&
                                this.state.orderString[1] == "DESC"
                              ) {
                                await this.setState({
                                  orderString: ["internalName", "ASC"],
                                });
                              } else {
                                await this.setState({
                                  orderString: ["internalName", "DESC"],
                                });
                              }

                              this.handleSubmit();
                            }}
                          >
                            Internal Name
                          </Link>
                        </td>
                        <td>
                          <Link
                            class="sort-order"
                            to="/catalog/control/FindProduct?sortField=brandName&amp;noConditionFind=N"
                            onClick={async (e) => {
                              e.preventDefault();
                              if (
                                this.state.orderString[0] == "brandName" &&
                                this.state.orderString[1] == "DESC"
                              ) {
                                await this.setState({
                                  orderString: ["brandName", "ASC"],
                                });
                              } else {
                                await this.setState({
                                  orderString: ["brandName", "DESC"],
                                });
                              }
                              this.handleSubmit();
                            }}
                          >
                            Brand Name
                          </Link>
                        </td>
                        <td>
                          <Link
                            class="sort-order"
                            to="/catalog/control/FindProduct?sortField=productName&amp;noConditionFind=N"
                            onClick={async (e) => {
                              e.preventDefault();
                              if (
                                this.state.orderString[0] == "title" &&
                                this.state.orderString[1] == "DESC"
                              ) {
                                await this.setState({
                                  orderString: ["title", "ASC"],
                                });
                              } else {
                                await this.setState({
                                  orderString: ["title", "DESC"],
                                });
                              }

                              this.handleSubmit();
                            }}
                          >
                            Product Name
                          </Link>
                        </td>
                        <td>
                          <Link
                            class="sort-order"
                            to="/catalog/control/FindProduct?sortField=slug&amp;noConditionFind=N"
                          >
                            Slug
                          </Link>
                        </td>
                        <td>
                          <Link
                            class="sort-order"
                            to="/catalog/control/FindProduct?sortField=description&amp;noConditionFind=N"
                          >
                            Description
                          </Link>
                        </td>
                        <td>Action</td>
                      </tr>
                      {products &&
                        products.map((product) => {
                          return (
                            <tr>
                              <td>
                                <Link
                                  to={
                                    "/catalog/control/EditFnpProduct?productId=" +
                                    product._id
                                  }
                                  title="Product Id"
                                >
                                  {product.productId}
                                </Link>
                              </td>
                              <td>SKU</td>
                              <td>-</td>
                              <td>{product?.internalName}</td>
                              <td>{product?.brandName}</td>
                              <td>{product?.title}</td>
                              <td>{product?.slug}</td>
                              <td>{product?.description}</td>
                              <td><button onClick={()=>this.handleDeleteProduct(product?._id)}>Delete</button></td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <div className="nav-pager">
                    <ul>
                      <li className="nav-first-disabled">First</li>
                      <li className="nav-previous-disabled">Previous</li>
                      <li className="nav-page-select">
                        Page
                        <select name="page" size="1" value={this.state.pageNumber} onChange={async(e)=> {
                         await this.setState({pageNumber: e.target.value})
                          this.handleSubmit()
                        }}>
                          {Array(this.state.noOfPages).fill(0).map((val,i)=>(
                              <option value={i}>
                                {i + 1}
                              </option>
                              )
                          )}

                        </select>
                      </li>
                      <li className="nav-next-disabled">Next</li>
                      <li className="nav-last-disabled">Last</li>
                      <li className="nav-pagesize">
                        <select name="pageSize" size="1" value={this.state.pageSize} onChange={async(e)=>{
                          await this.setState({pageSize:e.target.value,pageNumber:0});
                          this.handleSubmit()
                        }}>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option defaultValue value="200">
                            200
                          </option>
                        </select>
                        Items per page
                      </li>
                      <li className="nav-displaying">Displaying 1 - 8 of 8</li>
                    </ul>
                  </div>
                </React.Fragment>
                {/*</DataTable>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      name: "",
      productId_ic: true,
      internalName_ic: true,
      idValue_ic: true,
      idFilterType: "contains",
      nameFilterType: "contains",
      productIdSearch: true,
      internalNameSearch: true,
      brandNameSearch: true,
      productNameSearch: true,
      orderString: [],
      subCategoryFilterType:'contains',
      subCategory:'',
      subCategoryCheck:true,
      skuFilterType:'contains',
      sku:'',
      sku_ic:true,
      pageNumber:0,
      pageSize:20,
      noOfPages:1,
    };
  }
  componentDidMount() {
    let data = {};
    this.props.search(data,this.state.pageSize,this.state.pageNumber);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.otherData!==this.props.otherData){
      console.log('no Of Pages----------',this.props?.otherData?.noPages)
      this.setState({totalProducts:this.props?.otherData?.totalProducts,noOfPages:this.props?.otherData?.noPages})
    }
  }

  handleChange = (e) => {
    var value = e.target.value;
    var key = e.target.name;
    this.setState({ [key]: value });
  };
  handleSubmit = (e) => {
    const {
      _id,
      name,
      idFilterType,
      nameFilterType,
      productId_ic,
      internalName_ic,
      orderString,
        skuFilterType,
        sku,
        sku_ic,
        subCategoryFilterType,
        subCategory,
      subCategoryCheck
    } = this.state;
    let data = {};
    data = _id ? { ...data, _id: _id } : data;
    data = name ? { ...data, name: name } : data;
    data = sku?{...data,sku:sku}:data;
    data = subCategory?{...data,subCategory:subCategory}:data;
    data =
      idFilterType && !productId_ic
        ? { ...data, idFilterType: idFilterType }
        : data;
    data =
      nameFilterType && !internalName_ic
        ? { ...data, nameFilterType: nameFilterType }
        : data;

    data =
        skuFilterType && !sku_ic
            ? { ...data, skuFilterType: skuFilterType }
            : data;
    data =
        subCategoryFilterType && !subCategoryCheck
            ? { ...data, subCategoryFilterType: subCategoryFilterType }
            : data;
    data = orderString.length > 0 ? { ...data, orderString } : data;
    this.props.search(data,this.state.pageSize,this.state.pageNumber);
  };
}

const mapStateToProps = (state) => {
  return {
    submitted: state.catalog?.products?.submitted,
    products: state.catalog?.products?.products_searched,
    otherData: state.catalog?.products?.productOtherData,
  };
};

const mapDispatchToProps = (dispatch, router) => ({
  search: (data,pageSize,offset) => dispatch(searchProduct(data, router,pageSize,offset)),
  deleteProduct: (data,callback) => dispatch(deleteProduct(data,callback))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FindCatalog)
);
