import React from 'react';
import Draggable, {DraggableCore} from 'react-draggable';

function DraggableComponent(props) {
    return (
        <Draggable
            axis="both"
            handle=".handle"
            // defaultPosition={{x: 0, y: 0}}
            position={null}
            grid={[25, 25]}
            bounds={{left: 0, top: 0, right: 500, bottom: 0}}
            scale={1}>
            <div className='handle'>
                {props.children}
            </div>
        </Draggable>
    );
}

export default DraggableComponent;
