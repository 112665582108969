import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Notify } from "../../../../constants/utils";
import EditProductNav from "./EditProductNav";
import queryString from "query-string";
import DataTable from "../../../components/DataTable";
import moment from "moment";
import {
  getAddonProducts,
  createConfigs,
  getConfigs,
  updateConfigs,
} from "../../../../store/actions/catalogActions";
export class EditProductConfig extends Component {
  state = {
    flag: false,
    configItem: "",
    sequenceNum: "",
    fromDate: "",
    throughDate: "",
    description: "",
    longDescription: "",
    configType: "",
    configOption: "",
    isMandatory: false,
    productConfigs: [],
  };
  componentDidMount = async () => {
    const query = queryString.parse(this.props.location.search);
    const product = parseInt(query.productId);
    await this.props.getAddonProducts({ currentProduct: product });
    await this.props.getConfigs(product);
  };
  handleUpdateSelect = (content) => {};
  handleBack = () => {};
  handleDelete = async (content) => {};
  handleContentCreate = () => {};
  render() {
    const query = queryString.parse(this.props.location.search);
    const product = query.productId;
    return (
      <React.Fragment>
        <div id="content-main-section" class="leftonly">
          <div id="centerdiv" class="no-clear">
            <EditProductNav product={product} />
            <div id="screenlet_1" class="screenlet">
              <div class="screenlet-title-bar">
                <ul>
                  <li class="h3">Edit Product Configs</li>
                </ul>
              </div>
              <div id="screenlet_1_col" class="screenlet-body">
                <DataTable>
                  <table class="basic-table" cellspacing="0">
                    <tbody>
                      <tr class="header-row">
                        <td>
                          <label
                            id="ListProductContentInfos_editProductContentInfo_title"
                            for="ListProductContentInfos_editProductContentInfo"
                          >
                            Config Item
                          </label>
                        </td>
                        <td>
                          <label
                            id="ListProductContentInfos_productContentTypeId_title"
                            for="ListProductContentInfos_productContentTypeId"
                          >
                            Sequence Num
                          </label>
                        </td>
                        <td>
                          <label
                            id="ListProductContentInfos_fromDate_title"
                            for="ListProductContentInfos_fromDate"
                          >
                            From Date Time
                          </label>
                        </td>
                        <td>
                          <span>Description</span>
                          <span>Long Description</span>
                          <span>Config Type Id</span>
                          <span>Default Config Option Id</span>
                          <span>Throught Date</span>
                          <span>Is Mandatory</span>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      {/* //  {this.props.content?.map((content) => ( */}
                      {this.props.configs?.map((id, index) => (
                        <tr>
                          <td>{id.title}</td>
                          <td>{id.productConfigs.sequenceNum}</td>
                          <td>{id.productConfigs?.fromDate}</td>
                          <td>
                            <input
                              type="text"
                              value={
                                this.state.productConfigs?.[index]?.description
                              }
                              onChange={(e) => {
                                let object = this.state.productConfigs;
                                object[index].description = e.target.value;
                                this.setState({ productConfigs: object });
                              }}
                            />
                            <textarea
                              value={
                                this.state.productConfigs?.[index]
                                  ?.longDescription
                              }
                              onChange={(e) => {
                                let object = this.state.productConfigs;
                                object[index].longDescription = e.target.value;
                                this.setState({ productConfigs: object });
                              }}
                            ></textarea>
                            <select
                              value={
                                this.state.productConfigs?.[index]?.configType
                              }
                              onChange={(e) => {
                                let object = this.state.productConfigs;
                                object[index].configType = e.target.value;
                                this.setState({ productConfigs: object });
                              }}
                            >
                              <option value="STANDARD">Standard</option>
                              <option value="QUESTION">Question</option>
                            </select>
                            <input
                              type="text"
                              value={
                                this.state.productConfigs?.[index]?.configOption
                              }
                              onChange={(e) => {
                                let object = this.state.productConfigs;
                                object[index].configOption = e.target.value;
                                this.setState({ productConfigs: object });
                              }}
                            />
                            <input
                              type="date"
                              value={moment(
                                this.state.productConfigs?.[index]?.throughDate
                              )
                                .utc(true)
                                .format("Y-MM-DD")}
                              onChange={(e) => {
                                let object = this.state.productConfigs;
                                object[index].throughDate = e.target.value;
                                this.setState({ productConfigs: object });
                              }}
                            />
                            <select
                              value={
                                this.state.productConfigs?.[index]?.isMandatory
                              }
                              onChange={(e) => {
                                let object = this.state.productConfigs;
                                object[index].isMandatory = e.target.value;
                                this.setState({ productConfigs: object });
                              }}
                            >
                              <option value={true}>Y</option>
                              <option value={false}>Y</option>
                            </select>
                          </td>
                          <td>
                            <button onClick={(e) => this.update(index, e)}>
                              update
                            </button>
                          </td>
                          <td>
                            <button onClick={(e) => this.delete(index, e)}>
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}

                      {/* ))} */}
                    </tbody>
                  </table>
                </DataTable>
              </div>
              <div class="screenlet-title-bar">
                <ul>
                  <li class="h3">Add Product Configs</li>
                </ul>
              </div>
              <div id="screenlet_1_col" class="screenlet-body">
                <table class="basic-table" cellspacing="0">
                  <tbody>
                    <tr>
                      <td class="label">
                        <label
                          id="AddProductContentAssoc_contentId_title"
                          for="AddProductContentAssoc_contentId"
                        >
                          Config Item*
                        </label>
                      </td>

                      <td>
                        <select
                          value={this.state.configItem}
                          onChange={(e) =>
                            this.setState({ configItem: e.target.value })
                          }
                          id="AddProductContentAssoc_productContentTypeId"
                          name="productContentTypeId"
                          size="1"
                        >
                          <option value=""></option>
                          {this.props.addons?.map((addon) => (
                            <option value={addon._id}>{addon.title}</option>
                          ))}
                        </select>
                      </td>
                    </tr>

                    <tr>
                      <td class="label">
                        <label
                          id="AddProductContentAssoc_contentId_title"
                          for="AddProductContentAssoc_contentId"
                        >
                          Sequence Num*
                        </label>
                      </td>

                      <td>
                        <input
                          id="3_lookupId_AddProductContentAssoc_contentId"
                          class="ui-autocomplete-input"
                          autocomplete="off"
                          className="required"
                          name="contentId"
                          value={this.state.sequenceNum}
                          onChange={(e) =>
                            this.setState({ sequenceNum: e.target.value })
                          }
                          size="25"
                          type="text"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="AddProductContentAssoc_contentId_title"
                          for="AddProductContentAssoc_contentId"
                        >
                          From Date
                        </label>
                      </td>

                      <td>
                        <input
                          value={this.state.fromDate}
                          onChange={(e) =>
                            this.setState({ fromDate: e.target.value })
                          }
                          size="25"
                          type="date"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="AddProductContentAssoc_contentId_title"
                          for="AddProductContentAssoc_contentId"
                        >
                          Description
                        </label>
                      </td>

                      <td>
                        <input
                          value={this.state.description}
                          onChange={(e) =>
                            this.setState({ description: e.target.value })
                          }
                          size="25"
                          type="text"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="AddProductContentAssoc_contentId_title"
                          for="AddProductContentAssoc_contentId"
                        >
                          Long Description
                        </label>
                      </td>

                      <td>
                        <textarea
                          name="contentId"
                          value={this.state.longDescription}
                          onChange={(e) =>
                            this.setState({ longDescription: e.target.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="AddProductContentAssoc_contentId_title"
                          for="AddProductContentAssoc_contentId"
                        >
                          Config Type Id
                        </label>
                      </td>

                      <td>
                        <select
                          value={this.state.configType}
                          onChange={(e) =>
                            this.setState({ configType: e.target.value })
                          }
                          id="AddProductContentAssoc_productContentTypeId"
                          name="productContentTypeId"
                          size="1"
                        >
                          <option value="STANDARD">Standard</option>
                          <option value="QUESTION">Question</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="AddProductContentAssoc_contentId_title"
                          for="AddProductContentAssoc_contentId"
                        >
                          Default Config Option Id
                        </label>
                      </td>

                      <td>
                        <input
                          value={this.state.configOption}
                          onChange={(e) =>
                            this.setState({ configOption: e.target.value })
                          }
                          size="25"
                          type="text"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="AddProductContentAssoc_contentId_title"
                          for="AddProductContentAssoc_contentId"
                        >
                          Through Date*
                        </label>
                      </td>

                      <td>
                        <input
                          value={this.state.throughDate}
                          onChange={(e) =>
                            this.setState({ throughDate: e.target.value })
                          }
                          size="25"
                          type="date"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="AddProductContentAssoc_contentId_title"
                          for="AddProductContentAssoc_contentId"
                        >
                          Is Mandatory
                        </label>
                      </td>

                      <td>
                        <select
                          value={this.state.isMandatory}
                          onChange={(e) =>
                            this.setState({ isMandatory: e.target.value })
                          }
                          id="AddProductContentAssoc_productContentTypeId"
                          name="productContentTypeId"
                          size="1"
                        >
                          <option value={true}>Y</option>
                          <option value={false}>N</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <button onClick={(e) => this.createIds()}>
                          Create
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  componentDidUpdate(prevProps) {
    if (prevProps.configs.length !== this.props.configs.length) {
      console.log("sommmmmmmm", this.props.configs);
      let configArray = [];
      for (let i = 0; i < this.props?.configs.length; i++) {
        configArray.push(this.props.configs[i].productConfigs);
      }
      this.setState({ productConfigs: configArray });
    }
  }

  createIds = () => {
    let {
      configItem,
      sequenceNum,
      fromDate,
      throughDate,
      description,
      longDescription,
      configType,
      configOption,
      isMandatory,
    } = this.state;
    const query = queryString.parse(this.props.location.search);
    const productId = query.productId;
    if (sequenceNum === "" || configItem === "") {
      Notify("danger", "please fill all the required fields.", "Success");
      return;
    }
    let dataObj = { addonId: configItem, sequenceNum, productId };
    dataObj = fromDate ? { ...dataObj, fromDate } : dataObj;
    dataObj = throughDate ? { ...dataObj, throughDate } : dataObj;
    dataObj = description ? { ...dataObj, description } : dataObj;
    dataObj = longDescription ? { ...dataObj, longDescription } : dataObj;
    dataObj = configType ? { ...dataObj, configType } : dataObj;
    dataObj = configOption ? { ...dataObj, configOption } : dataObj;
    dataObj = isMandatory ? { ...dataObj, isMandatory } : dataObj;
    this.props.create(dataObj);
    this.setState({ idType: "", idValue: "" });
  };
  update = (index, e) => {
    e.preventDefault();
    let {
      _id,
      throughDate,
      description,
      longDescription,
      configType,
      configOption,
      isMandatory,
      productId,
    } = this.state.productConfigs[index];
    let obj = {
      throughDate,
      description,
      longDescription,
      configType,
      configOption,
      isMandatory,
      productId,
      id: _id,
    };
    this.props.updateConfigs(obj);
  };
  delete = (index, e) => {
    e.preventDefault();
    let { _id, productId } = this.state.productConfigs[index];
    let id = _id;
    let obj = { id, isActive: false, isDeleted: true, productId };
    this.props.updateConfigs(obj);
  };
}

const mapStateToProps = (state) => ({
  token: state?.auth?.token,
  configs: state?.catalog?.configReducer.content,
  addons: state?.catalog?.configReducer.addons,
});

const mapDispatchToProps = (dispatch, router) => ({
  getAddonProducts: (data) => dispatch(getAddonProducts(data)),
  create: (data) => dispatch(createConfigs(data)),
  getConfigs: (id) => dispatch(getConfigs(id)),
  updateConfigs: (data) => dispatch(updateConfigs(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditProductConfig)
);
