import React from "react";
import { Link } from "react-router-dom";
import { editProductNav } from "../../../../constants/strings";
export default function EditProductNav({ expandAbles, product, internalName }) {
  return (
    <React.Fragment>
      <div className="button-bar tab-bar">
        <ul>
          <li>
            <ul>
              {editProductNav &&
                editProductNav.map(({ text, route }) => {
                  return (
                    <li>
                      <Link to={route + (product ? product : "")}>{text}</Link>
                    </li>
                  );
                })}
            </ul>
          </li>
        </ul>
      </div>
      <div className="button-bar button-style-2">
        <ul>
          <li>
            <ul>
              <li className="buttontext create">
                <Link
                  className="buttontext create"
                  to="/catalog/control/EditProduct"
                >
                  New Product
                </Link>
              </li>
              <li>
                <Link to="/catalog/control/EditProductTag">Tags</Link>
              </li>
              {expandAbles}
            </ul>
          </li>
        </ul>
      </div>
      <h1>
        Product For: {internalName ? internalName : ""}[ID:
        {product ? product : ""}]
      </h1>
    </React.Fragment>
  );
}
