import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Collapse } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { withRouter, Link } from "react-router-dom";
import RemoveIcon from "@material-ui/icons/Remove";
import { allActiveCountries } from "../../../store/actions/orderActions";

export class AddressPopUp extends Component {
  state = {
    name: "",
    address1: "",
    address2: "",
    city: "",
    phoneNo: "",
    recipientEmail: "",
    country: "",
    selectedCity: "",
    cities: [],
    addressId: "",
    changeFlag: false,
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.shippingAddress !== this.props.shippingAddress) {
      let { country, cities, shippingAddress, carts } = this.props;
      let city = cities.find((c) => c.name === shippingAddress.recipientCity);
      this.setState({
        country,
        recipientEmail: shippingAddress.email,
        phoneNo: shippingAddress.phone,
        city: city.name,
        address1: shippingAddress.recipientAddress,
        address2: shippingAddress.landmark,
        name: shippingAddress.name,
        addressId: shippingAddress._id,
        selectedCity: city._id,
        cities: cities,
      });
    }
  }
  async componentDidMount() {}
  render() {
    return (
      <div>
        <Modal
          open={this.props.modalOpen}
          onClose={this.props.handleClose}
          //   style={{ overflow: "scroll" }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            style={{
              height: "500px",
              width: "350px",
              backgroundColor: "white",
              position: "absolute",
              top: "50px",
              border: "2px solid white",
              left: "600px",
              //alignItems: "center",
              display: "flex",
              overflow: "scroll",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                backgroundColor: "#527894",
                borderRadius: "5px",
                width: "100%",
                height: "30px",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <CloseIcon
                onClick={(e) => this.props.handleClose(e)}
                style={{
                  color: "#6caad4",
                  marginRight: "5px",
                  backgroundColor: "#e3f1fc",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <span
                style={{
                  display: "flex",
                  marginTop: "10px",
                  marginLeft: "10px",
                  flexDirection: "column",
                }}
              >
                <p>Recipient Name*</p>
                <input
                  type="text"
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </span>
              <span
                style={{
                  display: "flex",
                  marginTop: "10px",
                  marginLeft: "10px",
                  flexDirection: "column",
                }}
              >
                <p>Address Line1*</p>
                <input
                  type="text"
                  value={this.state.address1}
                  onChange={(e) => this.setState({ address1: e.target.value })}
                />
              </span>
              <span
                style={{
                  display: "flex",
                  marginTop: "10px",
                  marginLeft: "10px",
                  flexDirection: "column",
                }}
              >
                <p>Address Line2</p>
                <input
                  type="text"
                  value={this.state.address2}
                  onChange={(e) => this.setState({ address2: e.target.value })}
                />
              </span>
              <span
                style={{
                  display: "flex",
                  marginTop: "10px",
                  marginLeft: "10px",
                  flexDirection: "column",
                }}
              >
                <p>City*</p>
                <input
                  type="text"
                  value={this.state.city}
                  onChange={(e) => this.setState({ city: e.target.value })}
                />
              </span>
              <span
                style={{
                  display: "flex",
                  marginTop: "10px",
                  marginLeft: "10px",
                  flexDirection: "column",
                }}
              >
                <p>Phone Number*</p>
                <input
                  type="text"
                  value={this.state.phoneNo}
                  onChange={(e) => this.setState({ phoneNo: e.target.value })}
                />
              </span>
              <span
                style={{
                  display: "flex",
                  marginTop: "10px",
                  marginLeft: "10px",
                  flexDirection: "column",
                }}
              >
                <p>Recipient Email*</p>
                <input
                  type="text"
                  value={this.state.recipientEmail}
                  onChange={(e) =>
                    this.setState({ recipientEmail: e.target.value })
                  }
                />
              </span>
              <span
                style={{
                  display: "flex",
                  marginTop: "10px",
                  marginLeft: "10px",
                  flexDirection: "column",
                }}
              >
                <p> City*</p>
                <select
                  value={this.state.selectedCity}
                  onChange={(e) => {
                    let value = parseInt(e.target.value);
                    let city = this.state.cities.find((c) => c._id === value);
                    this.setState({
                      selectedCity: value,
                      city: city.name,
                      changeFlag: true,
                    });
                  }}
                >
                  {this.state.cities.map((city) => (
                    <option value={city._id}>{city.name}</option>
                  ))}
                </select>
              </span>
              <span
                style={{
                  display: "flex",
                  marginTop: "10px",
                  marginLeft: "10px",
                  flexDirection: "column",
                }}
              >
                <p>Country*</p>
                <input type="text" value={this.state.country} disabled={true} />
              </span>
              <span
                style={{
                  display: "flex",
                  marginTop: "10px",
                  marginLeft: "10px",
                  flexDirection: "column",
                }}
              >
                <input
                  type="button"
                  value="Submit"
                  onClick={() =>
                    this.props.handleSubmit(
                      {
                        selectedCity: this.state.city,
                        name: this.state.name,
                        address1: this.state.address1,
                        address2: this.state.address2,
                        phoneNo: this.state.phoneNo,
                        recipientEmail: this.state.recipientEmail,
                        addressId: this.state.addressId,
                      },
                      this.state.changeFlag
                    )
                  }
                />
              </span>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch, router) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddressPopUp)
);
