const navigationState = {
  data: [],
  normalizeData: [],
  loading: false,
  message: "",
};

const navigationReducer = (state = navigationState, action) => {
  switch (action.type) {
    case "GET_NAVIGATION_LIST_REQUEST":
    case "UPDATE_NAVIGATION_DETAILS_REQUEST":
    case "ADD_NAVIGATION_REQUEST":
      return { ...state, loading: true };
    case "GET_NAVIGATION_LIST_SUCCESS":
      return {
        ...state,
        data: [...action.payload.navigation],
        normalizeData: [...action.payload.normalizeData],
        loading: false,
      };
    case "GET_NAVIGATION_LIST_FAILURE":
    case "UPDATE_NAVIGATION_DETAILS_FAILURE":
    case "ADD_NAVIGATION_FAILURE":
      return { ...state, loading: false, message: action.payload };
    case "UPDATE_NAVIGATION_DETAILS_SUCCESS":
    case "ADD_NAVIGATION_SUCCESS":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default navigationReducer;
