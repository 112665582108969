import React, { Component } from "react";
import { connect } from "react-redux";
import { searchCategories,deleteCategories} from "../../../../store/actions/catalogActions";
import { Link } from "react-router-dom";
import DataTable from "../../../components/DataTable";
export class FindCategory extends Component {
  callback=()=>{
    this.props.search();
  }
  handleDelete=(id)=>{
    this.props.deleteCategories(id,this.callback);
  }
  render() {
    const { submitted, categories } = this.props;
    return (
      <div id="content-main-section" className="leftonly">
        <div id="centerdiv" className="no-clear">
          <div className="button-bar button-style-2">
            <ul>
              <li>
                <ul>
                  <li className="buttontext create">
                    <Link
                      className="buttontext create"
                      to="/catalog/control/EditCategory"
                    >
                      New Category
                    </Link>
                  </li>
                  <li className="buttontext create">
                    <Link
                      className="buttontext create"
                      to="/catalog/control/NewUrlCategory"
                    >
                      New Url Category
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="page-title">Find Category</div>
          <div className="clear"></div>
          <div id="searchOptions" className="screenlet">
            <div className="screenlet-title-bar">
              <ul>
                <li className="h3">Search Options</li>
                <li className="expanded">
                  <a title="Collapse" href>
                    {" "}
                  </a>
                </li>
              </ul>
            </div>
            <div id="searchOptions_col" className="screenlet-body">
              <div id="search-options">
                <form className="basic-form" onSubmit={this.handleSubmit}>
                  <input
                    id="FindCategory_noConditionFind"
                    name="noConditionFind"
                    type="hidden"
                    value="Y"
                  />
                  <table className="basic-table" cellSpacing="0">
                    <tbody>
                      <tr>
                        <td className="label">
                          <label
                            id="FindCategory_productCategoryId_title"
                            htmlFor="FindCategory_productCategoryId"
                          >
                            Category ID
                          </label>
                        </td>
                        <td>
                          <select
                            className="selectBox"
                            name="idFilterType"
                            value={this.state.idFilterType}
                            onChange={(e) => this.handleChange(e)}
                          >
                            <option value="equals">Equals</option>
                            <option value="beginWith">Begins With</option>
                            <option  value="contains">
                              Contains
                            </option>
                            <option value="empty">Is Empty</option>
                            <option value="notEqual">Not Equal</option>
                          </select>
                          <input
                            size="25"
                            type="text"
                            name="_id"
                            value={this.state._id}
                            onChange={(e) => this.handleChange(e)}
                          />{" "}
                          <input
                            checked={this.state.categoryId}
                            name="productCategoryId_ic"
                            type="checkbox"
                            onChange={(e)=>this.setState(prevState=>({categoryId:!prevState.categoryId}))}
                            value="Y"
                          />{" "}
                          Ignore Case
                        </td>
                      </tr>
                      <tr>
                        <td className="label">
                          <label
                            id="FindCategory_categoryName_title"
                            htmlFor="FindCategory_categoryName"
                          >
                            Category Name
                          </label>
                        </td>
                        <td>
                          <select className="selectBox" name="nameFilterType" onChange={(e) => this.handleChange(e)} value={this.state.nameFilterType}>
                            <option value="equals">Equals</option>
                            <option value="beginWith">Begins With</option>
                            <option value= "contains">
                              Contains
                            </option>
                            <option value="empty">Is Empty</option>
                            <option value="notEqual">Not Equal</option>
                          </select>
                          <input
                            size="25"
                            type="text"
                            name="name"
                            value={this.state.name}
                            onChange={(e) => this.handleChange(e)}
                          />{" "}
                          <input
                            checked={this.state.categoryName}
                            name="categoryName_ic"
                            type="checkbox"
                            onChange={(e)=>this.setState(prevState=>({categoryName:!prevState.categoryName}))}
                            value="Y"
                          />{" "}
                          Ignore Case
                        </td>
                      </tr>
                      <tr>
                        <td className="label"></td>
                        <td colSpan="4">
                          <input
                            className="smallSubmit"
                            name="submitButton"
                            type="submit"
                            value="Find"
                            // disabled={submitted}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
          </div>
          <div id="screenlet_1" className="screenlet">
            <div id="screenlet_1_col" className="screenlet-body no-padding">
              <h3>Search Results</h3>
              <div id="search-results">
              {this.props.deleteLoading? "Loading...": 
                <DataTable>
                  <table className="basic-table hover-bar" cellSpacing="0">
                    <tbody>
                      <tr className="header-row-2">
                        <td>
                          <Link
                            className="sort-order"
                            to="/catalog/control/FindCategory?sortField=productCategoryId&amp;noConditionFind=N"
                          >
                            Product Category Id
                          </Link>
                        </td>
                        <td>
                          <Link
                            className="sort-order"
                            tp="/catalog/control/FindCategory?sortField=productCategoryTypeId&amp;noConditionFind=N"
                          >
                            Product Category Type
                          </Link>
                        </td>
                        <td>
                          <Link
                            className="sort-order"
                            tp="/catalog/control/FindCategory?sortField=primaryParentCategoryId&amp;noConditionFind=N"
                          >
                            Parent Category
                          </Link>
                        </td>
                        <td>
                          <Link
                            className="sort-order"
                            tp="/catalog/control/FindCategory?sortField=categoryName&amp;noConditionFind=N"
                          >
                            Category Name
                          </Link>
                        </td>
                        <td>
                          <Link
                            className="sort-order"
                            tp="/catalog/control/FindCategory?sortField=slug&amp;noConditionFind=N"
                          >
                            Slug
                          </Link>
                        </td>
                        <td>
                          <Link
                            className="sort-order"
                            tp="/catalog/control/FindCategory?sortField=description&amp;noConditionFind=N"
                          >
                            Description
                          </Link>
                        </td>
                        <td>Action</td>
                      </tr>

                      {categories?.map((cat) => {
                        return (
                          <tr>
                            <td>
                              <Link
                                className="sort-order"
                                to={`/catalog/control/EditCategory?productCategoryId=${cat._id}`}
                              >
                                {cat?._id}
                              </Link>
                            </td>
                            <td>{cat?.type?.toUpperCase()}</td>
                            <td>
                              {cat.parentCategory
                                ? cat.parentCategory.name.toUpperCase()
                                : "-"}
                            </td>
                            <td>{cat?.name}</td>
                            <td>{cat?.slug}</td>
                            <td>{cat?.description}</td>
                            <td><button onClick={()=>this.handleDelete(cat?._id)}>Delete</button></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </DataTable>
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  componentDidMount() {
    this.props.search();
  }

  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      name: "",
      categoryId:true,
      categoryName:true,
      idFilterType: 'contains',
      nameFilterType: 'contains'
    };
  }

  handleChange = (e) => {
    var value = e.target.value;
    var key = e.target.name;
    this.setState({ [key]: value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { _id, name, idFilterType, nameFilterType,categoryId,categoryName } = this.state;
    let data = {};
    data = _id ? { ...data, _id: _id } : data;
    data = name ? { ...data, name: name } : data;
    data = idFilterType && !categoryId ? {...data, idFilterType: idFilterType } : data;
    data = nameFilterType && !categoryName ? {...data, nameFilterType: nameFilterType} : data;
    this.props.search(data);
  };
}

const mapStateToProps = (state) => ({
  submitted: state.catalog?.categories?.submitted,
  categories: state.catalog?.categories?.categories_searched,
  deleteLoading:state.catalog?.categories?.deleteLoading
});

const mapDispatchToProps = (dispatch, router) => ({
  search: (data) => dispatch(searchCategories(data, router)),
  deleteCategories: (data,callback) => dispatch(deleteCategories(data,callback ,router)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FindCategory);
