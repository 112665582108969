import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import "suneditor/dist/css/suneditor.min.css";
import "./assets/styles/main.css";
import "./assets/styles/App.scss";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Containers from "./Containers";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";


function App(props) {
  return (
    <div>
      <ReactNotification />
      <BrowserRouter>
        <Switch>
          <Route path="/" component={Containers} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
