import React, {Component} from "react";
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import {advancesearchProduct, searchProduct} from "../../../../store/actions/catalogActions";

class AdvancedSearch extends Component {
    constructor() {
        super();
        this.state = {
            title: '',
            internalName: ''
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleChange(event) {
        const fieldName = event.target.name;
        this.setState({[fieldName]: event.target.value});
    }
    handleSubmit(e) {
        e.preventDefault();
const {title, internalName} = this.state;
let data = {};
data = title? {...data, title: title}: data;
data = internalName? {...data, internalName: internalName}: data;
this.props.advancesearchProduct(data);
    }
    render() {
        const {title, internalName} = this.state;
        const url = new URLSearchParams(this.props?.location?.search);
        let categoryId = url?.get("categoryId");
        return(
            <>
            <div id="content-main-section" className="leftonly">
                <div id="centerdiv" className="no-clear">
                    <div className="screenlet">
                        <div className="screenlet-title-bar">
                            <h3>Advanced Search in Category</h3>
                        </div>
                        <div className="screenlet-body, marginzero">
                            <form  action="/catalog/control/keywordsearch" method="post"
                                  name="advtokeywordsearchform">
                                <input name="VIEW_SIZE" type="hidden" value="25"/> <input name="PAGING" type="hidden"
                                                                                          value="Y"/> <input
                                name="noConditionFind" type="hidden" value="Y"/> <input name="SEARCH_CATEGORY_ID"
                                                                                        type="hidden" value="100"/>
                                <table className="basic-table" cellSpacing="0">
                                    <tbody>
                                    <tr>
                                        <td className="label" align="right" valign="middle">Category:</td>
                                        <td valign="middle">
                                            <div><strong>""{categoryId}</strong> Include sub-categories? Yes<input
                                                checked="checked" name="SEARCH_SUB_CATEGORIES" type="radio"
                                                value="Y"/> No<input name="SEARCH_SUB_CATEGORIES" type="radio"
                                                                     value="N"/></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label" align="right" valign="top">Product Name:</td>
                                        <td valign="middle">
                                            <div><input name="title" size="20"
                                                        type="text" value={title}
                                                        onChange={this.handleChange}
                                            />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label" align="right" valign="top">Internal Name:</td>
                                        <td valign="middle">
                                            <div><input name="internalName" size="20"
                                                        type="text"
                                                        value={internalName}
                                                        onChange={this.handleChange}
                                             /></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label" align="right" valign="top">Keywords:</td>
                                        <td valign="middle">
                                            <div><input name="SEARCH_STRING" size="40" type="text" value=""/> Any<input
                                                checked="checked" name="SEARCH_OPERATOR" type="radio"
                                                value="OR"/> All<input name="SEARCH_OPERATOR" type="radio" value="AND"/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label" align="right" valign="top">Feature Category IDs:</td>
                                        <td valign="middle">
                                            <div><input name="SEARCH_PROD_FEAT_CAT1" size="15" type="text"
                                                        value=""/> Include<input checked="checked"
                                                                                 name="SEARCH_PROD_FEAT_CAT_EXC1"
                                                                                 type="radio" value=""/> Exclude<input
                                                name="SEARCH_PROD_FEAT_CAT_EXC1" type="radio" value="Y"/> Always Include<input
                                                name="SEARCH_PROD_FEAT_CAT_EXC1" type="radio" value="N"/></div>
                                            <div><input name="SEARCH_PROD_FEAT_CAT2" size="15" type="text"
                                                        value=""/> Include<input checked="checked"
                                                                                 name="SEARCH_PROD_FEAT_CAT_EXC2"
                                                                                 type="radio" value=""/> Exclude<input
                                                name="SEARCH_PROD_FEAT_CAT_EXC2" type="radio" value="Y"/> Always Include<input
                                                name="SEARCH_PROD_FEAT_CAT_EXC2" type="radio" value="N"/></div>
                                            <div><input name="SEARCH_PROD_FEAT_CAT3" size="15" type="text"
                                                        value=""/> Include<input checked="checked"
                                                                                 name="SEARCH_PROD_FEAT_CAT_EXC3"
                                                                                 type="radio" value=""/> Exclude<input
                                                name="SEARCH_PROD_FEAT_CAT_EXC3" type="radio" value="Y"/> Always Include<input
                                                name="SEARCH_PROD_FEAT_CAT_EXC3" type="radio" value="N"/></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label" align="right" valign="top">Feature Group IDs:</td>
                                        <td valign="middle">
                                            <div><input name="SEARCH_PROD_FEAT_GRP1" size="15" type="text"
                                                        value=""/> Include<input checked="checked"
                                                                                 name="SEARCH_PROD_FEAT_GRP_EXC1"
                                                                                 type="radio" value=""/> Exclude<input
                                                name="SEARCH_PROD_FEAT_GRP_EXC1" type="radio" value="Y"/> Always Include<input
                                                name="SEARCH_PROD_FEAT_GRP_EXC1" type="radio" value="N"/></div>
                                            <div><input name="SEARCH_PROD_FEAT_GRP2" size="15" type="text"
                                                        value=""/> Include<input checked="checked"
                                                                                 name="SEARCH_PROD_FEAT_GRP_EXC2"
                                                                                 type="radio" value=""/> Exclude<input
                                                name="SEARCH_PROD_FEAT_GRP_EXC2" type="radio" value="Y"/> Always Include<input
                                                name="SEARCH_PROD_FEAT_GRP_EXC2" type="radio" value="N"/></div>
                                            <div><input name="SEARCH_PROD_FEAT_GRP3" size="15" type="text"
                                                        value=""/> Include<input checked="checked"
                                                                                 name="SEARCH_PROD_FEAT_GRP_EXC3"
                                                                                 type="radio" value=""/> Exclude<input
                                                name="SEARCH_PROD_FEAT_GRP_EXC3" type="radio" value="Y"/> Always Include<input
                                                name="SEARCH_PROD_FEAT_GRP_EXC3" type="radio" value="N"/></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label" align="right" valign="top">Features IDs:</td>
                                        <td valign="middle">
                                            <div><input name="SEARCH_FEAT1" size="15" type="text"
                                                        value=""/> Include<input checked="checked"
                                                                                 name="SEARCH_FEAT_EXC1" type="radio"
                                                                                 value=""/> Exclude<input
                                                name="SEARCH_FEAT_EXC1" type="radio" value="Y"/> Always Include<input
                                                name="SEARCH_FEAT_EXC1" type="radio" value="N"/></div>
                                            <div><input name="SEARCH_FEAT2" size="15" type="text"
                                                        value=""/> Include<input checked="checked"
                                                                                 name="SEARCH_FEAT_EXC2" type="radio"
                                                                                 value=""/> Exclude<input
                                                name="SEARCH_FEAT_EXC2" type="radio" value="Y"/> Always Include<input
                                                name="SEARCH_FEAT_EXC2" type="radio" value="N"/></div>
                                            <div><input name="SEARCH_FEAT3" size="15" type="text"
                                                        value=""/> Include<input checked="checked"
                                                                                 name="SEARCH_FEAT_EXC3" type="radio"
                                                                                 value=""/> Exclude<input
                                                name="SEARCH_FEAT_EXC3" type="radio" value="Y"/> Always Include<input
                                                name="SEARCH_FEAT_EXC3" type="radio" value="N"/></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label" align="right" valign="top">List Price Range:</td>
                                        <td valign="middle">
                                            <div><input name="LIST_PRICE_LOW" size="8" type="text" value=""/> <input
                                                name="LIST_PRICE_HIGH" size="8" type="text" value=""/></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label" align="right" valign="middle">Supplier:</td>
                                        <td valign="middle">
                                            <div>
                                                <select name="SEARCH_SUPPLIER_ID">
                                                    <option value="">- Any -</option>
                                                    <option value="AEV-N1261216_FDRK">AE FNP Free Delivery for Rakhi .
                                                        [AEV-N1261216_FDRK]
                                                    </option>
                                                    <option value="AEV-N1261216">AE- FNP RAJESH . [AEV-N1261216]
                                                    </option>
                                                    <option value="AEV-N1261216_CNDD">AE- FNP RAJESH CNDD
                                                        [AEV-N1261216_CNDD]
                                                    </option>
                                                    <option value="AEV-N1261216_2DD">AE- FNP RAJESH 2DD
                                                        [AEV-N1261216_2DD]
                                                    </option>
                                                    <option value="AEV-N1261216_ED">AE- FNP RAJESH COURIER Next Day .
                                                        [AEV-N1261216_ED]
                                                    </option>
                                                    <option value="AEV-N1261216_FD">AE- FNP RAJESH Free
                                                        [AEV-N1261216_FD]
                                                    </option>
                                                    <option value="AEV-N1010418">AE-Abu-Dhabi-FNP RAJESH
                                                        [AEV-N1010418]
                                                    </option>
                                                    <option value="AEV-N1010418_2DD">AE-Abu-Dhabi-FNP RAJESH 2DD
                                                        [AEV-N1010418_2DD]
                                                    </option>
                                                    <option value="AEV-N1010418_ENDD">AE-Abu-Dhabi-FNP RAJESH Exp-Next
                                                        Day [AEV-N1010418_ENDD]
                                                    </option>
                                                    <option value="AEV-N1010418-BD">AE-Abu-Dhabi-FNP_RAJESH-BD
                                                        [AEV-N1010418-BD]
                                                    </option>
                                                    <option value="AEV-N1010418_VP">AE-Abu-Dhabi-Valetine_Priority_VP
                                                        [AEV-N1010418_VP]
                                                    </option>
                                                    <option value="AEV-N1010418_1HR">AE-Abu-Dhabi_1HR
                                                        [AEV-N1010418_1HR]
                                                    </option>
                                                    <option value="AEV-N1261216_1HR">AE-FNP RAJESH 1HR
                                                        [AEV-N1261216_1HR]
                                                    </option>
                                                    <option value="AEV-N1261216_ENDD">AE-FNP RAJESH Exp-Next Day ENDD
                                                        [AEV-N1261216_ENDD]
                                                    </option>
                                                    <option value="AEV-N1261216_VP">AE-FNP RAJESH_VALENTINE_VP ,
                                                        [AEV-N1261216_VP]
                                                    </option>
                                                    <option value="AEV-N1261216-GT">AE-FNP-GUITAEIST-SERVICE_RAJESH-GT
                                                        [AEV-N1261216-GT]
                                                    </option>
                                                    <option value="AEV-N1261216_EG2DD">AE-FNP_RAJESH-Exp-gifts_2DD
                                                        [AEV-N1261216_EG2DD]
                                                    </option>
                                                    <option value="AEV-N1160620">AE-Sharjah-FNP_RAJESH null
                                                        [AEV-N1160620]
                                                    </option>
                                                    <option value="AEV-N1160620_2DD">AE-Sharjah-FNP_RAJESH_2DD
                                                        [AEV-N1160620_2DD]
                                                    </option>
                                                    <option value="AEV-N1160620_ENDD">AE-Sharjah-FNP_RAJESH_ENDD
                                                        [AEV-N1160620_ENDD]
                                                    </option>
                                                    <option value="AEV-N1160620_VP">AE-Sharjah-Valetine_Priority_VP
                                                        [AEV-N1160620_VP]
                                                    </option>
                                                    <option value="AEV-N1160620_1HR">AE-Sharjah_1HR [AEV-N1160620_1HR]
                                                    </option>
                                                    <option value="AEV-N4120219-3P">AE_Gearfour [AEV-N4120219-3P]
                                                    </option>
                                                    <option value="AEV-N5120219-3P">AE_IQCreative . [AEV-N5120219-3P]
                                                    </option>
                                                    <option value="AEV-N3120219-3P">AE_NJD [AEV-N3120219-3P]</option>
                                                    <option value="AEV-N1120219-3P">AE_POArts [AEV-N1120219-3P]</option>
                                                    <option value="AEV-N2120219-3P">AE_Thebakery [AEV-N2120219-3P]
                                                    </option>
                                                    <option value="externaluser">External User [externaluser]</option>
                                                    <option value="INT-41290419">INT-Bahrain [INT-41290419]</option>
                                                    <option value="INT-1200720">INT-Bakemart-Qatar [INT-1200720]
                                                    </option>
                                                    <option value="INT-3260819">INT-China [INT-3260819]</option>
                                                    <option value="INT-21290419">INT-Egypt [INT-21290419]</option>
                                                    <option value="INT-1130719">INT-FNP-Singapore [INT-1130719]</option>
                                                    <option value="INT-1260819">INT-India [INT-1260819]</option>
                                                    <option value="INT-51290419">INT-Jordan [INT-51290419]</option>
                                                    <option value="INT-1060619">INT-Kuwait [INT-1060619]</option>
                                                    <option value="INT-71290419">INT-Lebanon [INT-71290419]</option>
                                                    <option value="INT-120419">INT-OMAN null [INT-120419]</option>
                                                    <option value="INT-5260819">INT-Pakistan [INT-5260819]</option>
                                                    <option value="INT-61290419">INT-Philippines [INT-61290419]</option>
                                                    <option value="INT-31290419">INT-Qatar [INT-31290419]</option>
                                                    <option value="INT-1150620">INT-Riyadh-Saudi-Arabia [INT-1150620]
                                                    </option>
                                                    <option value="INT-11290419">INT-Saudi-Arabia [INT-11290419]
                                                    </option>
                                                    <option value="INT-2150620">INT-Saudi-Cakes-Boutique [INT-2150620]
                                                    </option>
                                                    <option value="INT-4260819">INT-Sri-lanka [INT-4260819]</option>
                                                    <option value="INT-2260819">INT-UK [INT-2260819]</option>
                                                    <option value="INT-568612C_3DD">INT_GBOS_INTernational_SAU_3DD
                                                        [INT-568612C_3DD]
                                                    </option>
                                                    <option value="TST_COURSUP">Test COURSUP [TST_COURSUP]</option>
                                                    <option value="TST_SUP13">Test sup13 [TST_SUP13]</option>
                                                    <option value="TST_SUP14">Test sup14 [TST_SUP14]</option>
                                                    <option value="TST_SUP15">Test sup15 [TST_SUP15]</option>
                                                    <option value="TST_SUP12">TST_SUP12_FN TST_SUP12_LN [TST_SUP12]
                                                    </option>
                                                    <option value="AcctBigSupplier">Acct Big Supplier
                                                        [AcctBigSupplier]
                                                    </option>
                                                </select>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label" align="right" valign="middle">Sorted By:</td>
                                        <td valign="middle">
                                            <div>
                                                <select name="sortOrder">
                                                    <option value="SortKeywordRelevancy">Keyword Relevancy</option>
                                                    <option value="SortProductField:productName">Product Name</option>
                                                    <option value="SortProductField:internalName">Internal Name</option>
                                                    <option value="SortProductField:totalQuantityOrdered">Popularity by
                                                        Orders
                                                    </option>
                                                    <option value="SortProductField:totalTimesViewed">Popularity by
                                                        Views
                                                    </option>
                                                    <option value="SortProductField:averageCustomerRating">Customer
                                                        Rating
                                                    </option>
                                                    <option value="SortProductPrice:LIST_PRICE">List Price</option>
                                                    <option value="SortProductPrice:DEFAULT_PRICE">Default Price
                                                    </option>
                                                    <option value="SortProductPrice:AVERAGE_COST">Average Cost</option>
                                                    <option value="SortProductPrice:MINIMUM_PRICE">Minimum Price
                                                    </option>
                                                    <option value="SortProductPrice:MAXIMUM_PRICE">Maximum Price
                                                    </option>
                                                </select>
                                                Low to High<input checked="checked" name="sortAscending" type="radio"
                                                                  value="Y"/> High to Low<input name="sortAscending"
                                                                                                type="radio" value="N"/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label" align="right" valign="middle">Prioritize Products in
                                            Category:
                                        </td>
                                        <td valign="middle">
                                            <div id="3_lookupId_PRIORITIZE_CATEGORY_ID_auto"></div>
                                            <span className="field-lookup"><input id="3_lookupId_PRIORITIZE_CATEGORY_ID"
                                                                                  className="ui-autocomplete-input"
                                                                                  autoComplete="off" maxLength="20"
                                                                                  name="PRIORITIZE_CATEGORY_ID"
                                                                                  size="20" type="text"/>
                                    </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label">Good Identification Type:</td>
                                        <td>
                                            <select name="SEARCH_GOOD_IDENTIFICATION_TYPE">
                                                <option value="">- Any -</option>
                                                <option value="EAN">EAN</option>
                                                <option value="GOOGLE_ID">Google Id</option>
                                                <option value="GOOGLE_ID_de">Google Id de</option>
                                                <option value="GOOGLE_ID_en_GB">Google Id en_GB</option>
                                                <option value="GOOGLE_ID_en_US">Google Id en_US</option>
                                                <option value="HS_CODE">Harmonized System Codes (HS Code)</option>
                                                <option value="ISBN">ISBN</option>
                                                <option value="LOC">Library of Congress</option>
                                                <option value="MANUFACTURER_ID_NO">Manufacturer (Model) Number</option>
                                                <option value="MODEL_YEAR">Model Year</option>
                                                <option value="OTHER_ID">Other</option>
                                                <option value="INVOICE_EXPORT">replacement value for partyId in the
                                                    invoice export function in accounting
                                                </option>
                                                <option value="SKU">SKU</option>
                                                <option value="UPCA">UPCA</option>
                                                <option value="UPCE">UPCE</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label">Good Identification Value:</td>
                                        <td><input maxLength="60" name="SEARCH_GOOD_IDENTIFICATION_VALUE" size="60"
                                                   type="text" value=""/> Include<input checked="checked"
                                                                                        name="SEARCH_GOOD_IDENTIFICATION_INCL"
                                                                                        type="radio" value="Y"/> Exclude<input
                                            name="SEARCH_GOOD_IDENTIFICATION_INCL" type="radio" value="N"/></td>
                                    </tr>
                                    <tr>
                                        <td className="label" align="right" valign="top">Last Search</td>
                                        <td valign="top">
                                            <div> - Category: Gizmos (Include All Sub Categories)</div>
                                            <div> - Keywords</div>
                                            <span className="label">Sorted By:</span>Keyword Relevancy
                                            <div>New Search<input checked="checked" name="clearSearch" type="radio"
                                                                  value="Y"/> Refine Search<input name="clearSearch"
                                                                                                  type="radio"
                                                                                                  value="N"/></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" align="center">
                                            <hr/>
                                            <input type="button" className="buttontext" value="Find" onClick={this.handleSubmit}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        <p>Always use the <a href="https://html-cleaner.com/" rel="nofollow noopener">Online HTML Beautifier</a> to
            compose the content for your website easily.</p>
                </>
        );
    }
}
const mapStateToProps = (state) => (
    ()=> {
        return {
            products_searched: state?.catalog?.products?.products_searched,
        }});
const mapDispatchToProps = (dispatch, router) => ({
    advancesearchProduct: (data) => dispatch(advancesearchProduct(data, router)),

});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdvancedSearch)
);
