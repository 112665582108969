import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import { allActiveCatalogs, createGeo, getAllGeo } from "../../../store/actions/catalogActions";
import { allActiveCountries } from "../../../store/actions/partyManagerActions";

import DataTable from "../../components/DataTable";

class Main extends Component {
    state = {
        geoType: '',
        catalog: '',
        catalogId: '',
        countryId: '',
        stateId: ''
    }

    componentWillMount() {
        this.props.allCatalogs();
        this.props.getAllGeo();
        this.props.allActiveCountries();
    }

    geoCreateHandler = () => {
        const finded = this.props.countries.filter(country => country._id === parseInt(this.state.countryId));

        let geoId = finded[0].name.toLowerCase();

        switch (this.state.geoType) {
            case 'country':
                geoId = geoId
                break;
            case 'locality':
                geoId = geoId + '_' + this.state.stateId + '_' + this.state.geoType
                break;
            default:
                break;
        }

        this.props.createGeo({
            geoType: this.state.geoType,
            catalogId: this.state.catalogId,
            geoId: geoId,
            countryId: finded[0]._id,
        });
    }

    render() {
        return (
            <div id="content-main-section" className="leftonly">
                <div id="centerdiv" className="no-clear">
                    <div className="screenlet" id="screenlet_1">
                        <div className="screenlet-title-bar">
                            <ul>
                                <li className="h3">Geo Administration Main Page</li>
                            </ul>
                            <br className="clear" />
                        </div>
                        <div id="screenlet_1_col" className="screenlet-body">
                            <br />
                            <form
                                id="EditProdCatalog"
                                className="basic-form"
                                // novalidate="novalidate"
                                onSubmit={(e) => e.preventDefault()}
                            >
                                <table className="basic-table" cellSpacing="0">
                                    <tbody>
                                        <tr>
                                            <td className="label">
                                                <label
                                                    id="EditProdCatalog_useQuickAdd_title"
                                                    htmlFor="EditProdCatalog_useQuickAdd"
                                                >
                                                    Geo Type:
                                                </label>
                                            </td>
                                            <td>
                                                <span className="ui-widget">
                                                    <select
                                                        id="EditProdCatalog_useQuickAdd"
                                                        className="valid"
                                                        name="useQuickAdd"
                                                        size="1"
                                                        onChange={(e) => this.setState({ geoType: e.target.value })}
                                                    >
                                                        <option value="">-----Select One-----</option>
                                                        <option value="country">Country</option>
                                                        <option value="locality">Locality</option>
                                                        <option value="delivery_area">Delivery Area</option>
                                                        <option value="delivery_area_group">Delivery Area Group</option>
                                                        <option value="postal_code">Postal Code</option>
                                                    </select>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="label">
                                                <label
                                                    id="EditProdCatalog_useQuickAdd_title"
                                                    htmlFor="EditProdCatalog_useQuickAdd"
                                                >
                                                    Catalog:
                                                </label>
                                            </td>
                                            <td>
                                                <span className="ui-widget">
                                                    <select
                                                        id="EditProdCatalog_useQuickAdd"
                                                        className="valid"
                                                        name="useQuickAdd"
                                                        size="1"
                                                        onChange={(e) => this.setState({ catalogId: e.target.value })}
                                                    >
                                                        <option value="">-----Select One-----</option>
                                                        {this.props.catalogs.map(catalog => {
                                                            return <option value={catalog._id} key={catalog._id}>{catalog.name}</option>
                                                        })}
                                                    </select>
                                                </span>
                                            </td>
                                        </tr>
                                        {(this.state.geoType === 'country' || this.state.geoType === 'locality') ? (
                                            <tr>
                                                <td className="label">
                                                    <label
                                                        id="EditProdCatalog_useQuickAdd_title"
                                                        htmlFor="EditProdCatalog_useQuickAdd"
                                                    >
                                                        Country:
                                                </label>
                                                </td>
                                                <td>
                                                    <span className="ui-widget">
                                                        <select
                                                            id="EditProdCatalog_useQuickAdd"
                                                            className="valid"
                                                            name="useQuickAdd"
                                                            size="1"
                                                            onChange={(e) => this.setState({ countryId: e.target.value })}
                                                        >
                                                            <option value="">-----Select One-----</option>
                                                            {this.props.countries.map(country => {
                                                                return <option value={country._id}>{country.name}</option>
                                                            })}
                                                            {/* <option value="uae">UAE</option>
                                                            <option value="pak">Pakistan</option>
                                                            <option value="ind">India</option> */}
                                                        </select>
                                                    </span>
                                                </td>
                                            </tr>
                                        ) : ('')}
                                        {(this.state.geoType === 'locality') ? (
                                            <tr>
                                                <td className="label">
                                                    <label
                                                        id="EditProdCatalog_useQuickAdd_title"
                                                        htmlFor="EditProdCatalog_useQuickAdd1"
                                                    >
                                                        State:
                                                </label>
                                                </td>
                                                <td>
                                                    <span className="ui-widget">
                                                        <select
                                                            id="EditProdCatalog_useQuickAdd1"
                                                            className="valid"
                                                            name="useQuickAdd"
                                                            size="1"
                                                            onChange={(e) => this.setState({ stateId: e.target.value })}
                                                        >
                                                            <option value="">-----Select One-----</option>
                                                            <option value="dubai">Dubai</option>
                                                            <option value="ajman">Ajman</option>
                                                            <option value="fujairah">Fujairah</option>
                                                        </select>
                                                    </span>
                                                </td>
                                            </tr>
                                        ) : ('')}
                                        {/* <tr>
                                            <td className="label">
                                                <label
                                                    id="EditProdCatalog_catalogName_title"
                                                    title="This will be shown to your customers, so think of image when giving your catalog a name."
                                                    htmlFor="EditProdCatalog_catalogName"
                                                >
                                                    Catalog Name
                                                    </label>
                                            </td>
                                            <td>
                                                <input
                                                    //   onChange={(e) => this.handleChange(e)}
                                                    id="EditProdCatalog_catalogName"
                                                    maxLength="60"
                                                    name="name"
                                                    //   value={name}
                                                    size="30"
                                                    type="text"
                                                />
                                            </td>
                                        </tr> */}
                                        <tr>
                                            <td className="label"> </td>
                                            <td colSpan="4">
                                                <input
                                                    className="smallSubmit"
                                                    name="submitButton"
                                                    type="submit"
                                                    value="Create"
                                                    onClick={this.geoCreateHandler}
                                                //   disabled={submitted}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                    <div id="screenlet_1" className="screenlet">
                        <div id="screenlet_1_col" className="screenlet-body no-padding">
                            <h3>Search Results</h3>
                            <div id="search-results">
                                <DataTable>
                                    <table className="basic-table hover-bar" cellSpacing="0">
                                        <tbody>
                                            <tr className="header-row-2">
                                                <td>
                                                    <Link
                                                        className="sort-order"
                                                        to="/catalog/control/FindCategory?sortField=productCategoryId&amp;noConditionFind=N"
                                                    >
                                                        Geo Id
                                                    </Link>
                                                </td>
                                                <td>
                                                    <Link
                                                        className="sort-order"
                                                        tp="/catalog/control/FindCategory?sortField=productCategoryTypeId&amp;noConditionFind=N"
                                                    >
                                                        Geo Id String
                                                    </Link>
                                                </td>
                                                {/* <td>
                                                    <Link
                                                        className="sort-order"
                                                        tp="/catalog/control/FindCategory?sortField=primaryParentCategoryId&amp;noConditionFind=N"
                                                    >
                                                        Parent Category
                                                    </Link>
                                                </td>
                                                <td>
                                                    <Link
                                                        className="sort-order"
                                                        tp="/catalog/control/FindCategory?sortField=categoryName&amp;noConditionFind=N"
                                                    >
                                                        Category Name
                                                    </Link>
                                                </td>
                                                <td>
                                                    <Link
                                                        className="sort-order"
                                                        tp="/catalog/control/FindCategory?sortField=description&amp;noConditionFind=N"
                                                    >
                                                        Description
                                                    </Link>
                                                </td> */}
                                            </tr>

                                            {this.props.allGeos.map(geo => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            {/* <Link
                                                                className="sort-order"
                                                                to={`/catalog/control/EditCategory?productCategoryId=${cat._id}`}
                                                            > */}
                                                                {geo._id}
                                                            {/* </Link> */}
                                                        </td>
                                                        <td>{geo.geoId}</td>
                                                        {/* <td>
                                                            {cat.parentCategory
                                                                ? cat.parentCategory.name.toUpperCase()
                                                                : "-"}
                                                        </td>
                                                        <td>{cat?.name}</td>
                                                        <td>{cat?.description}</td> */}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    token: state.auth.token,
    allGeos: state.catalog?.geo.allGeos,
    catalogs: state.catalog?.catalogs?.active,
    countries: state.partyManager?.countries.countries
});

const mapDispatchToProps = (dispatch, router) => ({
    allCatalogs: (data) => dispatch(allActiveCatalogs(data, router)),
    createGeo: (data) => dispatch(createGeo(data, router)),
    getAllGeo: (data) => dispatch(getAllGeo(data, router)),
    allActiveCountries: (data) => dispatch(allActiveCountries(data, router)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Main)
);