import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Notify } from "../../../../constants/utils";
import {
  updateCarrierShipmentMethodsTypes,
  createCarrierShipmentMethodsTypes,
  allActiveCarrierShipmentMethodsTypes,
  getAllShipmentMethodsTypes,
} from "../../../../store/actions/catalogActions";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import slugify from "slugify";
import PopUp from "./partyPopUp";
export class ListCarrierShipmentMethods extends Component {
  async componentDidMount() {
    this.props.allActive();
    this.setState({
      data: [],
    });
    const carrierShipmentMethodTypes = Array.from(
      this.props.carrierShipmentMethodTypes
    );
    if (this.props.location.search) {
      const id = this.props.location.search.split("=")[1];
      var carrierShipmentMethodType = carrierShipmentMethodTypes.filter(
        (carrierShipmentMethodType) => {
          return this.slugifyText(carrierShipmentMethodType.methodType) == id;
        }
      );
      const {
        _id,
        methodType,
        roleType,
        sequence,
        party,
        carrierServiceCode,
      } = carrierShipmentMethodType[0];
      await this.setState({
        shipmentMehtodTypeId: methodType,
        roleTypeId: roleType,
        seqNo: sequence,
        party,
        carrierServiceCode,
        isDisabled: true,
        _id,
      });
    }

    // this.props.create({
    //   methodType: "1_HR_DELIVERY [1 Hour Delivery]",
    //   roleType: "Carrier",
    //   sequence: 1,
    // });
    // console.log(this.props.get(3));
    //this.props.allActive();
    //this.props.search({ name: "Night_time_shipment891" });
    // this.props.update({
    //   name: "Night_time_shipment891",
    //   description: "changed to night time delivery deep in",
    // });
    //this.props.delete({ isActive: false, isDeleted: true });
  }
  state = {
    roleTypeId: "CARRIER",
    seqNo: "",
    data: [],
    isDisabled: false,
    carrierServiceCode: "",
    party: "",
    shipmentMehtodTypeId: "",
    modalOpen: false,
  };
  partySelect = (id) => {
    this.setState({ party: id, modalOpen: false });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };
  handleChange = (e) => {
    var value = e.target.value;
    var key = e.target.name;
    this.setState({ [key]: value });
  };

  render() {
    const carrierShipmentMethodTypes = Array.from(
      this.props.carrierShipmentMethodTypes
    );
    const shipmentMethodTypes = Array.from(this.props.shipmentMethodTypes);
    return (
      <div id="content-main-section" class="leftonly">
        <div id="centerdiv" class="no-clear">
          <div class="button-bar tab-bar">
            <ul>
              <li>
                <ul>
                  <li>
                    <a href="/catalog/control/ListShipmentMethodTypes">
                      Shipment Method Types
                    </a>
                  </li>
                  <li>
                    <a href="/catalog/control/ListQuantityBreaks">
                      Quantity Breaks
                    </a>
                  </li>
                  <li class="selected">
                    <a href="/catalog/control/ListCarrierShipmentMethods">
                      Carrier Shipment Methods
                    </a>
                  </li>
                  <li>
                    <a href="/catalog/control/ListTimeSlots">
                      Product Time Slots
                    </a>
                  </li>
                  <li>
                    <a href="/catalog/control/ListCarrierAllocation">
                      Carrier Allocation
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div> </div>
          <div id="screenlet_1" class="screenlet">
            <div class="screenlet-title-bar">
              <ul>
                <li class="h3">List Shipment Method Types</li>
              </ul>
            </div>
            <div id="screenlet_1_col" class="screenlet-body">
              <div class="nav-pager">
                <ul>
                  <li class="nav-first-disabled">First</li>
                  <li class="nav-previous-disabled">Previous</li>
                  <li class="nav-page-select">
                    Page
                    <select
                      name="page"
                      size="1"
                      onchange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1=200&amp;VIEW_INDEX_1='+this.value)"
                    >
                      <option selected="selected" value="0">
                        1
                      </option>
                    </select>
                  </li>
                  <li class="nav-next-disabled">Next</li>
                  <li class="nav-last-disabled">Last</li>
                  <li class="nav-pagesize">
                    <select
                      name="pageSize"
                      size="1"
                      onchange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')"
                    >
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option selected="selected" value="200">
                        200
                      </option>
                    </select>
                    Items per page
                  </li>
                  <li class="nav-displaying">Displaying 1 - 23 of 23</li>
                </ul>
              </div>
              <table class="basic-table" cellspacing="0">
                <tbody>
                  <tr class="header-row">
                    <td>
                      <label
                          id="ListCarrierShipmentMethod_shipmentMethodTypeId_title"
                          htmlFor="ListCarrierShipmentMethod_shipmentMethodTypeId"
                      >
                        ID
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListCarrierShipmentMethod_shipmentMethodTypeId_title"
                        for="ListCarrierShipmentMethod_shipmentMethodTypeId"
                      >
                        Shipment Method Type Id
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListCarrierShipmentMethod_partyId_title"
                        for="ListCarrierShipmentMethod_partyId"
                      >
                        Party Id
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListCarrierShipmentMethod_roleTypeId_title"
                        for="ListCarrierShipmentMethod_roleTypeId"
                      >
                        Role Type Id
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListCarrierShipmentMethod_seqNum_title"
                        for="ListCarrierShipmentMethod_seqNum"
                      >
                        Seq. Num
                      </label>
                    </td>
                    <td>
                      <label
                        id="ListCarrierShipmentMethod_carrierServiceCode_title"
                        for="ListCarrierShipmentMethod_carrierServiceCode"
                      >
                        Carrier Service Code
                      </label>
                    </td>
                    <td> </td>
                    <td> </td>
                  </tr>
                  {carrierShipmentMethodTypes &&
                    carrierShipmentMethodTypes.map((method) => {
                      let txt = this.slugifyText(method.methodType);
                      return (
                        <tr>
                          <td>{method._id}</td>
                          <td>{method.methodType}</td>
                          <td>{method?.parties?.userLoginId}</td>
                          <td>{method.roleType}</td>
                          <td>{method.sequence}</td>
                          <td></td>
                          <td>
                            <form
                              action="/catalog/control/editCarrierShipmentMethod"
                              method="post"
                              name="ListCarrierShipmentMethod_o_0_0_o_editLink"
                            >
                              <input
                                name="VIEW_SIZE_1"
                                type="hidden"
                                value="200"
                              />
                              <input
                                name="VIEW_INDEX_1"
                                type="hidden"
                                value="0"
                              />
                              <input
                                name="carrierShipmentMethodTypeId"
                                type="hidden"
                                value="COUR_DELIVERY"
                              />
                            </form>
                            <a
                              class="buttontext"
                              href={
                                "/catalog/control/ListCarrierShipmentMethods?carrierShipmentMethodTypeId=" +
                                txt
                              }
                            >
                              Edit
                            </a>
                          </td>
                          <td>
                            <form
                              action="/catalog/control/deleteCarrierShipmentMethod"
                              method="post"
                              name="ListCarrierShipmentMethod_o_0_0_o_deleteLink"
                            >
                              <input
                                name="VIEW_SIZE_1"
                                type="hidden"
                                value="200"
                              />
                              <input
                                name="VIEW_INDEX_1"
                                type="hidden"
                                value="0"
                              />
                              <input
                                name="carrierShipmentMethodTypeId"
                                type="hidden"
                                value="COUR_DELIVERY"
                              />
                            </form>
                            <a
                              class="buttontext"
                              href
                              onClick={(e) => this.handleRemove(e, method._id)}
                            >
                              Remove
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <div class="nav-pager">
                <ul>
                  <li class="nav-first-disabled">First</li>
                  <li class="nav-previous-disabled">Previous</li>
                  <li class="nav-page-select">
                    Page
                    <select
                      name="page"
                      size="1"
                      onchange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1=200&amp;VIEW_INDEX_1='+this.value)"
                    >
                      <option selected="selected" value="0">
                        1
                      </option>
                    </select>
                  </li>
                  <li class="nav-next-disabled">Next</li>
                  <li class="nav-last-disabled">Last</li>
                  <li class="nav-pagesize">
                    <select
                      name="pageSize"
                      size="1"
                      onchange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')"
                    >
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option selected="selected" value="200">
                        200
                      </option>
                    </select>
                    Items per page
                  </li>
                  <li class="nav-displaying">Displaying 1 - 23 of 23</li>
                </ul>
              </div>
            </div>
          </div>
          <PopUp
            partySelect={this.partySelect}
            handleClose={this.handleClose}
            modalOpen={this.state.modalOpen}
          />
          <div id="screenlet_2" class="screenlet">
            <div class="screenlet-title-bar">
              <ul>
                <li class="h3">Edit Carrier Shipment Methods</li>
              </ul>
            </div>
            <div id="screenlet_2_col" class="screenlet-body">
              <form
                id="EditShipmentMethodType"
                class="basic-form"
                action="/catalog/control/createShipmentMethodType"
                method="post"
                name="EditShipmentMethodType"
                novalidate="novalidate"
              >
                <table class="basic-table" cellspacing="0">
                  <tbody>
                    <tr>
                      <td class="label">
                        <label
                          id="EditCarrierShipmentMethod_shipmentMethodTypeId_title"
                          for="EditCarrierShipmentMethod_shipmentMethodTypeId"
                        >
                          Shipment Method Type Id
                        </label>
                        {}*
                      </td>
                      <td>
                        <select
                          id="EditProduct_productTypeId"
                          name="productTypeId"
                          value={this.state.shipmentMehtodTypeId}
                          disabled={this.state.isDisabled}
                          onChange={(e) =>
                            this.setState({
                              shipmentMehtodTypeId: e.target.value,
                            })
                          }
                          size="1"
                        >
                          <option></option>
                          {shipmentMethodTypes &&
                            shipmentMethodTypes.map((shipmentMethod) => {
                              return (
                                <option value={shipmentMethod.name}>
                                  {shipmentMethod.name}
                                </option>
                              );
                            })}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="EditCarrierShipmentMethod_partyId_title"
                          for="EditCarrierShipmentMethod_partyId"
                        >
                          Party Id{}*
                        </label>
                      </td>
                      <td style={{ alignItems: "center", display: "flex" }}>
                        <input
                          id="EditCarrierShipmentMethod_partyId"
                          class="required"
                          maxlength="250"
                          disabled={this.state.isDisabled}
                          onChange={(e) =>
                            this.setState({ party: e.target.value })
                          }
                          name="partyId"
                          value={this.state.party}
                          size="60"
                          type="text"
                        />
                        <FindInPageIcon
                          onClick={() => this.setState({ modalOpen: true })}
                          style={{ display: "flex", cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="EditCarrierShipmentMethod_roleTypeId_title"
                          for="EditCarrierShipmentMethod_roleTypeId"
                        >
                          Role Type Id{}*
                        </label>
                      </td>
                      <td>
                        <select
                          id="EditProduct_productTypeId"
                          name="productTypeId"
                          value={this.state.roleTypeId}
                          disabled={this.state.isDisabled}
                          onChange={(e) =>
                            this.setState({
                              roleTypeId: e.target.value,
                            })
                          }
                          size="1"
                        >
                          <option value="ACCOUNT">Account</option>
                          <option value="ACCOUNT_LEAD">Account Lead</option>
                          <option value="ACCOUNTANT">Accountant</option>
                          <option value="ADDRESSEE">Addressee</option>
                          <option value="ADMINISTRATOR">Administrator</option>
                          <option value="AFFILIATE">Affiliate</option>
                          <option value="AGENT">Agent</option>
                          <option value="APPROVER">Approver</option>
                          <option value="ACCOCIATION">Accociation</option>
                          <option value="AUTOMATED_AGENT">
                            Automated Agent
                          </option>
                          <option value="BILL_FROM_VENDOR">
                            Bill From Vendor
                          </option>
                          <option value="BILL_TO_CUSTOMER">
                            Bill to Customer
                          </option>
                          <option value="BLIND_COPY">Blind Copy</option>
                          <option value="BRANCH">Branch</option>
                          <option value="BULK_CUSTOMER">Bulk Customer</option>
                          <option value="BUYER">Buyer</option>
                          <option value="CALENDAR">Calendar</option>
                          <option value="CALENDAR_ATTENDEE">
                            Calendar Attendee
                          </option>
                          <option value="CALENDAR_DELEGATE">
                            Calendar Delegate
                          </option>
                          <option value="CALENDAR_HOST">Calendar Host</option>
                          <option value="CALENDAR_MEMBER">
                            Calendar Member
                          </option>
                          <option value="CALENDAR_ORGANIZER">
                            Calendar Organizer
                          </option>
                          <option value="CALENDAR_OWNER">Calendar Owner</option>
                          <option value="CARBON_COPY">Carbon Copy</option>
                          <option value="CARRIER">Carrier</option>
                          <option value="CASHIER">Cashier</option>
                          <option value="CC_Agent">CC Agent</option>
                          <option value="CLIENT">Client</option>
                          <option value="CLIENT_ANALYST">Client Analyst</option>
                          <option value="CLIENT_BILLING">Client Billing</option>
                          <option value="CLIENT_MANAGER">Client Manager</option>
                          <option value="COMMENTATOR">Commentator</option>
                          <option value="COMMUNICATION_PARTICIPANT">
                            Communication Participant
                          </option>
                          <option value="COMPETITOR">Competitor</option>
                          <option value="CONSUMER">Consumer</option>
                          <option value="CONTENT_ADMINISTRATOR">
                            Content Administrator
                          </option>
                          <option value="CONTENT_AUTHOR">Content Author</option>
                          <option value="CONTENT_EDITOR">Content Editor</option>
                          <option value="CONTENT_GUEST">Content Guest</option>
                          <option value="CONTENT_MEMBER">Content Member</option>
                          <option value="CONTENT_PUBLISHER">
                            Content Publisher
                          </option>
                          <option value="CONTENT_USER">Content User</option>
                          <option value="CONTENT_CONTRACTOR">
                            Content Contractor
                          </option>
                          <option value="CORPORATE_USER ">
                            Corporate User
                          </option>
                          <option value="CUSTOMER">Customer</option>
                          <option value="DELIVERY_BOY">Delivery Boy</option>
                          <option value="DEPARTMENT">Department</option>
                          <option value="DISTRIBUTION_CHENNEL">
                            Distribution Chennel
                          </option>
                          <option value="DISTRIBUTER">Distributer</option>
                          <option value="DEVISION">Devision</option>
                          <option value="EMAIL_ADMINISTRATOR">
                            Email Administrator
                          </option>
                          <option value="EMPLOYEE">Employee</option>
                          <option value="END-USER-CUSTORMER">
                            End-User Customer
                          </option>
                          <option value="EBAY_ACCOUNT">ebay Account</option>
                          <option value="FAMILY_MEMBER">Family Member</option>
                          <option value="FIXED_ASSET_MAINT_ASSIGNEE">
                            Fixed Asset Maint Assignee
                          </option>
                          <option value="HOSTING_SERVER">Hosting Server</option>
                          <option value="HOUSEHOLD">Household</option>
                          <option value="IMAGE_APPROVER">Image Approver</option>
                          <option value="INTERNAL_ORGNIZATION">
                            Internal Orgnization
                          </option>
                          <option value="ISP">ISP</option>
                          <option value="LEAD">Lead</option>
                          <option value="LIMITED_ADMINISTRATOR">
                            Limited Administrator
                          </option>
                          <option value="LOGGED_IN">Logged In</option>
                          <option value="MAIN_ROLE">Main Role</option>
                          <option value="MAINTEINANCE_MANAGER_OR_SUPERVISOR">
                            Mainteinance Manager or Supervisor
                          </option>
                          <option value="MAINTEINANCE_SUPPLIER_OR_SERVICE">
                            Mainteinance Supplier or Service
                          </option>
                          <option value="MAINTEINANCE_WORKER">
                            Mainteinance Worker
                          </option>
                          <option value="MANAGER">Manager</option>
                          <option value="MANUFECTURER">Manufecturer</option>
                          <option value="NOT_APPLICABLE">Not Applicable</option>
                          <option value="ORDER_CLERK">Order Clerk</option>
                          <option value="ORGNIZATION">Orgnization</option>
                          <option value="ORGNIZATION_UNIT">
                            Orgnization Unit
                          </option>
                          <option value="ORIGINATOR">Originator</option>
                          <option value="OTHER_INTERNAL">Other Internal</option>
                          <option value="OTHER_ORGNIZATION_UNIT">
                            Other Orgnization Unit
                          </option>
                          <option value="OWNER">Owner</option>
                          <option value="PACKER">Packer</option>
                          <option value="PARENT_ORGNIZTION">
                            Parent Orgnization
                          </option>
                          <option value="PARTNER">Partner</option>
                          <option value="PERSON">Person</option>
                          <option value="PICKER">Picker</option>
                          <option value="PLACING_CUSTORMER">
                            Placing Customer
                          </option>
                          <option value="PRODUCT_OWNER">Product Owner</option>
                          <option value="PRODUCT_OWNER_COMPANY">
                            Product Owner Company
                          </option>
                          <option value="PROJECT_TEAM">Project Team</option>
                          <option value="PROSPECT">Prospect</option>
                          <option value="PROVIDER_ACCOUNTING">
                            Provider Accounting
                          </option>
                          <option value="PROVIDER_ANALYST">
                            Provider Analyst
                          </option>
                          <option value="PROVIDER_FUNCTIONAL">
                            Provider Functional
                          </option>
                          <option value="PROVIDER_MANAGER">
                            Provider Manager
                          </option>
                          <option value="PROVIDER_TESTER">
                            Provider Tester
                          </option>
                          <option value="PROVIDER_VALIDATOR">
                            Provider Validator
                          </option>
                          <option value="RECEIVER">Receiver</option>
                          <option value="REFERRER">Referrer</option>
                          <option value="REGULATORY_AGENCY">
                            Regulatory Agency
                          </option>
                          <option value="REQUEST_MANAGER">
                            Request Manager
                          </option>
                          <option value="REQUEST_RESPONDENT">
                            Request Respondent
                          </option>
                          <option value="REQUEST_ROLE">Request Role</option>
                          <option value="REQUEST_TAKER">Request Taker</option>
                          <option value="REQUESTING_PARTY">
                            Requesting Party
                          </option>
                          <option value="REVIEWER">Reviewer</option>
                          <option value="SALES_FORCE_AUTM">
                            Sales Force Autm
                          </option>
                          <option value="SALES_REPRESENTATIVE">
                            Sales Representative
                          </option>
                          <option value="SCRUM_MASTER">Scrum_Master</option>
                          <option value="SCRUM_TEAM">Scrum Team</option>
                          <option value="SCRUM_MEMBER">Scrum Member</option>
                          <option value="SHAREHOLDER">Shareholder</option>
                          <option value="SHIP_FROM_VENDOR">
                            Ship From Vendor
                          </option>
                          <option value="SHIP_TO_CUSTOMER">
                            Ship To Customer
                          </option>
                          <option value="SHIPMENT_CLERK">Shipment Clerk</option>
                          <option value="SPONSOR">Sponsor</option>
                          <option value="SPOUSE">Spouse</option>
                          <option value="STAKEHOLDER">Stakeholder</option>
                          <option value="STOCKER">Stocker</option>
                          <option value="SUBSCRIBER">Subscriber</option>
                          <option value="SUBSIDIARY">Subsidiary</option>
                          <option value="SUPPLIER">Supplier</option>
                          <option value="SUPPLIER_AGENT">Supplier Agent</option>
                          <option value="TAX_AUTHORITY">Tax Authority</option>
                          <option value="UNION">Union</option>
                          <option value="VENDOR">Vendor</option>
                          <option value="vISITOR">Visitor</option>
                          <option value="WEB_MASTER">Web Master</option>
                          <option value="WORKER">Worker</option>
                          <option value="WORKFLOW">Workflow</option>
                          <option value="WORKFLOW_OWNER">Workflow Owner</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="EditCarrierShipmentMethod_seqNo_title"
                          for="EditCarrierShipmentMethod_seqNo"
                        >
                          Seq. Num
                        </label>
                      </td>
                      <td>
                        <input
                          id="EditCarrierShipmentMethod_seqNo"
                          class="required"
                          maxlength="250"
                          onChange={(e) =>
                            this.setState({ seqNo: e.target.value })
                          }
                          name="seqNo"
                          value={this.state.seqNo}
                          size="60"
                          type="text"
                        />{" "}
                      </td>
                    </tr>
                    <tr>
                      <td class="label">
                        <label
                          id="EditCarrierShipmentMethod_carrierServiceCode_title"
                          for="EditCarrierShipmentMethod_carrierServiceCode"
                        >
                          Carrier Service Code
                        </label>
                      </td>
                      <td>
                        <input
                          id="EditCarrierShipmentMethod_carrierServiceCode"
                          class="required"
                          maxlength="250"
                          onChange={(e) => this.handleChange(e)}
                          value={this.state.carrierServiceCode}
                          name="carrierServiceCode"
                          size="60"
                          type="text"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="label"> </td>
                      <td colspan="4">
                        <input
                          class="smallSubmit"
                          name="submitButton"
                          type="submit"
                          onClick={(e) => this.handleSubmit(e)}
                          value="Submit"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
  handleRemove = (e, _id) => {
    this.setState({
      shipmentMehtodTypeId: "",
      roleTypeId: "CARRIER",
      seqNo: "",
      carrierServiceCode: "",
      party: "",
      isDisabled: false,
      _id: "",
    });
    this.props.update({ _id: _id, isActive: false, isDeleted: true });
  };
  slugifyText=(text, replacement = "-") =>{
    if (text) {
      return slugify(text, {
        replacement: replacement,
        lower: true,
        strict: true,
      });
    }
    return;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      _id,
      shipmentMehtodTypeId,
      roleTypeId,
      seqNo,
      party,
      carrierServiceCode,
    } = this.state;
    if (
      this.state.shipmentMehtodTypeId === "" ||
      party === "" ||
      roleTypeId === ""
    ) {
      Notify("danger", "Please fill all the required fields", "Required");
      return;
    }
    let data = {};
    data = shipmentMehtodTypeId
      ? { ...data, methodType: shipmentMehtodTypeId }
      : data;
    data = roleTypeId ? { ...data, roleType: roleTypeId } : data;
    data = seqNo ? { ...data, sequence: seqNo } : data;
    data = carrierServiceCode
      ? { ...data, serviceCode: carrierServiceCode }
      : data;
    data = party ? { ...data, party: party } : data;
    if (shipmentMehtodTypeId) {
      let filtered = this.props.shipmentMethodTypes.filter(
        (type) => type.name === shipmentMehtodTypeId
      );

      if (filtered.length > 0) {
        data.shipmentMethodTypeId = filtered[0]._id;
      }
    }
    this.setState({
      shipmentMehtodTypeId: "",
      roleTypeId: "CARRIER",
      seqNo: "",
      carrierServiceCode: "",
      party: "",
      isDisabled: false,
    });
    if (_id) {
      data = _id ? { ...data, _id: _id } : data;
      this.setState({ _id: "" });
      console.log("this is data before update", data);
      this.props.update(data);
      this.props.history.push("/catalog/control/ListCarrierShipmentMethods");
    } else {
      this.props.create(data);
    }
  };
}

const mapStateToProps = (state) => ({
  submitted: state.catalog?.carrierShipmentMethodTypesReducer?.submitted,
  carrierShipmentMethodTypes:
    state.catalog?.carrierShipmentMethodTypesReducer?.shipment_method_types,
  shipmentMethodTypes:
    state.catalog?.shipmentMethodTypesReducer?.shipment_method_types,
});

const mapDispatchToProps = (dispatch, router) => ({
  create: (data) => dispatch(createCarrierShipmentMethodsTypes(data, router)),
  allActive: () => dispatch(allActiveCarrierShipmentMethodsTypes()),
  update: (data) => dispatch(updateCarrierShipmentMethodsTypes(data, router)),
  all: () => dispatch(getAllShipmentMethodsTypes()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListCarrierShipmentMethods)
);
