import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { Notify } from "../../../../constants/utils";
import { useHistory, useLocation } from "react-router";
import {
  addNavigationDetails,
  updateNavigationDetails,
} from "../../../../store/actions/navigationAction";
function EditNavigation({ mode }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const state = location.state;
  const query = queryString.parse(location.search);
  const navigationId = query.navigationId;
  const {
    navigations: { data, normalizeData, loading },
  } = useSelector((state) => state);
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [sequence, setSequence] = useState("");
  const [mainMenu, setMainMenu] = useState("");
  const [childMenu, setChildMenu] = useState("");
  const [subMenuOrder, setSubMenuOrder] = useState("");

  useEffect(() => {
    if (navigationId) {
      const navigation = data.find((nav) => nav.id === Number(navigationId));
      const { name, slug, sequence, mainMenu, subMenuOrder } = navigation;
      setName(name);
      setSlug(slug);
      setSequence(sequence);
      if (state?.isSubMenu) {
        setMainMenu(mainMenu?.id);
        setSubMenuOrder(subMenuOrder);
      }
    }
  }, [navigationId, data]);

  const redirectToNavList = () => {
    history.push("/catalog/control/FindNavigation");
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (name === "" || slug === "" || sequence === "") {
      Notify("danger", "Please Fill All the required Fields", "Error");
      return;
    }
    if (
      state?.isSubMenu &&
      ((mainMenu === "" && childMenu === "") || subMenuOrder === "")
    ) {
      Notify("danger", "Please Fill All the required Fields", "Error");
      return;
    }
    const payload = {
      name,
      slug,
      sequence: sequence ? Number(sequence) : "",
      mainMenu: mainMenu ? Number(mainMenu) : undefined,
      childMenu: childMenu ? Number(childMenu) : undefined,
      subMenuOrder: subMenuOrder ? Number(subMenuOrder) : undefined,
    };
    if (navigationId) {
      dispatch(
        updateNavigationDetails(navigationId, payload, () =>
          redirectToNavList()
        )
      );
    } else {
      dispatch(addNavigationDetails(payload, () => redirectToNavList()));
    }
  };

  const childMenus = normalizeData.find((menu) => menu.id === Number(mainMenu));

  return (
    <div id="content-main-section" className="leftonly">
      <div id="centerdiv" className="no-clear">
        <div className="screenlet">
          <div className="screenlet-title-bar">
            <ul>
              <li className="h3">
                {mode === "add" ? "Create" : "Update"}{" "}
                {state?.isSubMenu && `Sub`} Nevigation Menu &nbsp;
                {mode === "edit" && `#${navigationId}`}
              </li>
            </ul>
          </div>
          <div className="screenlet-body">
            <form onSubmit={submitForm}>
              <table className="basic-table" cellSpacing="0">
                <tbody>
                  <tr>
                    <td className="label" align="right" width="26%">
                      Navigation Name*
                    </td>
                    <td></td>
                    <td width="74%">
                      <input
                        maxLength="60"
                        name="name"
                        size="60"
                        class="required"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="label" align="right" width="26%">
                      Navigation Slug*
                    </td>
                    <td></td>
                    <td width="74%">
                      <input
                        maxLength="60"
                        name="slug"
                        size="60"
                        class="required"
                        type="text"
                        value={slug}
                        onChange={(e) => setSlug(e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="label" align="right" width="26%">
                      Navigation Sequence*
                    </td>
                    <td></td>
                    <td width="74%">
                      <input
                        maxLength="60"
                        name="sequence"
                        size="60"
                        class="required"
                        type="number"
                        value={sequence}
                        onChange={(e) => setSequence(e.target.value)}
                      />
                    </td>
                  </tr>
                  {state?.isSubMenu && (
                    <>
                      <tr>
                        <td className="label" align="right" width="26%">
                          Navigation Sub Menu
                        </td>
                        <td></td>
                        <td width="74%">
                          <select
                            name="mainMenu"
                            size="1"
                            value={mainMenu}
                            onChange={(e) => setMainMenu(e.target.value)}
                            disabled={mode === "edit"}
                          >
                            <option value="" disabled="true">
                              Select Sub Menu
                            </option>
                            {normalizeData.map((menu) => (
                              <option value={menu?.id}>{menu?.name}</option>
                            ))}
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td className="label" align="right" width="26%">
                          Navigation Sub Menu Column Order*
                        </td>
                        <td></td>
                        <td width="74%">
                          <input
                            maxLength="60"
                            name="subMenuOrder"
                            size="60"
                            class="required"
                            type="number"
                            value={subMenuOrder}
                            onChange={(e) => setSubMenuOrder(e.target.value)}
                          />
                        </td>
                      </tr>
                      {mode !== "edit" && (
                        <tr>
                          <td className="label" align="right" width="26%">
                            Navigation Child Menu
                          </td>
                          <td></td>
                          <td width="74%">
                            <select
                              name="childMenu"
                              size="1"
                              value={childMenu}
                              onChange={(e) => setChildMenu(e.target.value)}
                            >
                              <option value="" disabled="true">
                                Select Child Menu
                              </option>
                              {childMenus?.menu?.map((child) => (
                                <option value={child?.id}>{child?.name}</option>
                              ))}
                            </select>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                  <tr>
                    <td colSpan="2"></td>
                    <td>
                      <input
                        name="Create"
                        type="submit"
                        value={mode === "add" ? "Create" : "Update"}
                        disabled={loading}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditNavigation;
