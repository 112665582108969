import React, { Component } from "react";
import { connect } from "react-redux";
import {
  createCatalog,
  getCatalog,
  updateCatalog,
} from "../../../../store/actions/catalogActions";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import EditCatalogNav from "./EditCatalogNav";
import { Notify } from "../../../../constants/utils";
import { getRequest, putRequest } from "../../../../store/services";
export class EditProdCatalog extends Component {
  render() {
    const {
      _id,
      name,
      sytleSheet,
      headerLogo,
      contentPathPrefix,
      templatePathPrefix,
    } = this.state;
    const { submitted } = this.props;
    const query = queryString.parse(this.props.location.search);
    const catalog = query.prodCatalogId;
    return (
      <div id="content-main-section" className="leftonly">
        <div id="centerdiv" className="no-clear">
          {catalog && (
            <React.Fragment>
              <EditCatalogNav catalog={catalog} active="catalog" />
            </React.Fragment>
          )}
          <div id="screenlet_1" className="screenlet">
            <div className="screenlet-title-bar">
              <ul>
                <li className="h3">Edit Product Catalog</li>
              </ul>
            </div>
            <div id="screenlet_1_col" className="screenlet-body">
              <h3>
                If you don't populate a Catalog with Categories, it will not be
                shown in the Browse Catalogs/Categories tree
              </h3>
              <form
                id="EditProdCatalog"
                className="basic-form"
                novalidate="novalidate"
                onSubmit={(e) => this.handleSubmit(e)}
              >
                <table className="basic-table" cellSpacing="0">
                  <tbody>
                    {/* <tr>
                      <td className="label">
                        <label
                          id="EditProdCatalog_prodCatalogId_title"
                          title="This cannot be changed without re-creating the Product Catalog."
                          htmlFor="EditProdCatalog_prodCatalogId"
                        >
                          Catalog [ID]
                        </label>
                      </td>
                      <td>
                        <input
                          onChange={(e) => this.handleChange(e)}
                          id="EditProdCatalog_prodCatalogId"
                          maxLength="20"
                          name="_id"
                          value={_id}
                          size="20"
                          type="text"
                          disabled={true}
                        />
                        <span className="tooltip">
                          Could not Find Product Catalog with Id []
                        </span>
                      </td>
                    </tr> */}
                    <tr>
                      <td className="label">
                        <label
                          id="EditProdCatalog_catalogName_title"
                          title="This will be shown to your customers, so think of image when giving your catalog a name."
                          htmlFor="EditProdCatalog_catalogName"
                        >
                          Catalog Name
                        </label>
                      </td>
                      <td>
                        <input
                          onChange={(e) => this.handleChange(e)}
                          id="EditProdCatalog_catalogName"
                          maxLength="60"
                          name="name"
                          value={name}
                          class="required"
                          size="30"
                          type="text"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        <label
                          id="EditProdCatalog_useQuickAdd_title"
                          title="Set this to Y if you want to give users the option to quick add products."
                          htmlFor="EditProdCatalog_useQuickAdd"
                        >
                          Use Quick Add
                        </label>
                      </td>
                      <td>
                        <span className="ui-widget">
                          <select
                            id="EditProdCatalog_useQuickAdd"
                            className="valid"
                            name="useQuickAdd"
                            size="1"
                            onChange={(e) => this.handleChange(e)}
                          >
                            <option defaultValue value="true">
                              Y
                            </option>
                            <option value="false">N</option>
                          </select>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        <label
                          id="EditProdCatalog_styleSheet_title"
                          title="The path to your distinctive style sheet which will govern the appearance of your catalog goes here."
                          htmlFor="EditProdCatalog_styleSheet"
                        >
                          Style Sheet
                        </label>
                      </td>
                      <td>
                        <input
                          onChange={(e) => this.handleChange(e)}
                          id="EditProdCatalog_styleSheet"
                          maxLength="250"
                          size="60"
                          type="text"
                          name="sytleSheet"
                          value={sytleSheet}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        <label
                          id="EditProdCatalog_headerLogo_title"
                          title="This will be seen by your customers. Maintain your image and message with the logo presented."
                          htmlFor="EditProdCatalog_headerLogo"
                        >
                          Header Logo
                        </label>
                      </td>
                      <td>
                        <input
                          onChange={(e) => this.handleChange(e)}
                          id="EditProdCatalog_headerLogo"
                          maxLength="250"
                          name="headerLogo"
                          value={headerLogo}
                          size="60"
                          type="text"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        <label
                          id="EditProdCatalog_contentPathPrefix_title"
                          title="If specified will be prepended to image and other content paths. Should start with a slash but not end with one."
                          htmlFor="EditProdCatalog_contentPathPrefix"
                        >
                          Content Path Prefix
                        </label>
                      </td>
                      <td>
                        <input
                          onChange={(e) => this.handleChange(e)}
                          id="EditProdCatalog_contentPathPrefix"
                          maxLength="250"
                          name="contentPathPrefix"
                          value={contentPathPrefix}
                          size="60"
                          type="text"
                        />
                        <span className="tooltip">
                          Define the prefix of the content placement file .
                          Start up by a / and finish without /
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        <label
                          id="EditProdCatalog_templatePathPrefix_title"
                          title="If specified will be prepended to template paths. Should start with a slash but not end with one."
                          htmlFor="EditProdCatalog_templatePathPrefix"
                        >
                          Template Path Prefix
                        </label>
                      </td>
                      <td>
                        <input
                          onChange={(e) => this.handleChange(e)}
                          id="EditProdCatalog_templatePathPrefix"
                          maxLength="250"
                          name="templatePathPrefix"
                          value={templatePathPrefix}
                          size="60"
                          type="text"
                        />
                        <span className="tooltip">
                          Define the skeleton of the content placement file .
                          Start up by a / and finish without /
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        <label
                          id="EditProdCatalog_viewAllowPermReqd_title"
                          title="If Y, administrators can only add products to 'View Allow' categories if they have the permission CATALOG_VIEW_ALLOW"
                          htmlFor="EditProdCatalog_viewAllowPermReqd"
                        >
                          Permission required to add product to "ViewAllow"
                          category
                        </label>
                      </td>
                      <td>
                        <span className="ui-widget">
                          <select
                            id="EditProdCatalog_viewAllowPermReqd"
                            className="valid"
                            name="viewAllow"
                            size="1"
                            onChange={(e) => this.handleChange(e)}
                          >
                            <option value="true">Y</option>
                            <option defaultValue value="false">
                              N
                            </option>
                          </select>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        <label
                          id="EditProdCatalog_purchaseAllowPermReqd_title"
                          title="If Y, administrators can only add products to 'Purchase Allow' categories if they have the permission CATALOG_VIEW_ALLOW"
                          htmlFor="EditProdCatalog_purchaseAllowPermReqd"
                        >
                          Permission required to add product to "PurchaseAllow"
                          category
                        </label>
                      </td>
                      <td>
                        <span className="ui-widget">
                          <select
                            id="EditProdCatalog_purchaseAllowPermReqd"
                            name="purchaseAllow"
                            size="1"
                            onChange={(e) => this.handleChange(e)}
                          >
                            <option value="true">Y</option>
                            <option defaultValue value="false">
                              N
                            </option>
                          </select>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="label"> </td>
                      <td colSpan="4">
                        <input
                          className="smallSubmit"
                          name="submitButton"
                          type="submit"
                          value={this.state.mode === "add" ? "Add" : "Update"}
                          disabled={submitted}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      name: "",
      useQuickAdd: false,
      sytleSheet: "",
      headerLogo: "",
      contentPathPrefix: "",
      templatePathPrefix: "",
      viewAllow: false,
      purchaseAllow: false,
      mode: "add",
    };
  }
  async componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    const catalog = query.prodCatalogId;
    var data = await this.props.catalogDetails(catalog);
    if (data?.data) {
      const {
        _id,
        name,
        sytleSheet,
        headerLogo,
        contentPathPrefix,
        templatePathPrefix,
        viewAllow,
        purchaseAllow,
      } = data?.data;
      this.setState({
        _id,
        name,
        sytleSheet,
        headerLogo,
        contentPathPrefix,
        templatePathPrefix,
        viewAllow,
        purchaseAllow,
        mode: "edit",
      });
    }
  }
  handleChange = (e) => {
    var value = e.target.value;
    var key = e.target.name;
    this.setState({ [key]: value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { mode, name } = this.state;
    if (name === "") {
      Notify("danger", "Please Fill All the required Fields", "Error");
      return;
    }
    var data = this.state;
    if (mode === "add") {
      delete data?._id;
      this.props.save(data);
    } else {
      this.props.update(data);
    }
  };
}

const mapStateToProps = (state) => ({
  submitted: state?.catalog?.submitted,
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch, router) => ({
  save: (data) => dispatch(createCatalog(data, router)),
  update: (data) => dispatch(updateCatalog(data, router)),
  catalogDetails: (id) => dispatch(getCatalog(id, router)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditProdCatalog)
);
