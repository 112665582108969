import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Collapse } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { withRouter, Link } from "react-router-dom";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  allActiveCountries,
  getParties,
} from "../../../store/actions/orderActions";
import moment from "moment";

export class DeliverySlotPopUp extends Component {
  state = {
    shippingType: "",
    shippingTime: "",
    shippingPrice: "",
    addressId: "",
    addresses: [],
    selectedSlot: null,
    slots: [],
    orderInd: "",
  };
  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps.deliveryInfo !== this.props.deliveryInfo) {
      let {
        shippingType,
        shippingTime,
        shippingPrice,
        addressId,
        addresses,
        shippingDate,
        orderInd,
      } = this.props.deliveryInfo;
      let address = addresses.find((add) => add._id === addressId);
      let parties = await this.props.getParties(address.recipientCity);
      let array = [];
      for (let i = 0; i < parties?.length; i++) {
        for (let j = 0; j < parties[i].carrierShipmentMethods?.length; j++) {
          for (
            let k = 0;
            k < parties[i].carrierShipmentMethods[j]?.timeSlots?.length;
            k++
          ) {
            let shippingType = parties[i].carrierShipmentMethods[j].methodType;
            let timeSlot =
              parties[i].carrierShipmentMethods[j].timeSlots[k].startTime +
              " - " +
              parties[i].carrierShipmentMethods[j].timeSlots[k].endTime +
              " hrs";
            let price =
              parties[i].carrierShipmentMethods[j].shipmentMethodsType.price;
            let dString = shippingType + " " + timeSlot + " " + "AED" + price;
            let partyId = parties[i]._id;
            let found = array.findIndex((arr) => arr.dString === dString);
            if (found !== -1) {
              let arrayObj = array[found];
              arrayObj.parties.push(partyId);
              array[found] = arrayObj;
            } else {
              array.push({
                shippingType,
                timeSlot,
                price,
                dString,
                parties: [partyId],
              });
            }
          }
        }
      }
      let slotString =
        shippingType + " " + shippingTime + " " + "AED" + shippingPrice;
      let index = array.findIndex((arr) => arr.dString === slotString);
      this.setState({
        shippingType,
        shippingTime,
        shippingPrice,
        shippingDate: moment(shippingDate).utc(true).format("Y-MM-DD"),
        addressId,
        addresses,
        selectedSlot: index !== -1 ? index : "",
        slots: array,
        orderInd,
      });
    }
  };
  async componentDidMount() {}
  render() {
    return (
      <div>
        <Modal
          open={this.props.modalOpen}
          onClose={this.props.handleClose}
          //   style={{ overflow: "scroll" }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            style={{
              height: "250px",
              width: "400px",
              backgroundColor: "white",
              position: "absolute",
              top: "50px",
              border: "2px solid white",
              left: "600px",
              //alignItems: "center",
              display: "flex",
              overflow: "scroll",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                backgroundColor: "#527894",
                borderRadius: "5px",
                width: "100%",
                height: "30px",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <CloseIcon
                onClick={(e) => this.props.handleClose(e)}
                style={{
                  color: "#6caad4",
                  marginRight: "5px",
                  backgroundColor: "#e3f1fc",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              />
            </div>
            <span
              style={{
                display: "flex",
                marginTop: "10px",
                marginLeft: "10px",
                flexDirection: "column",
              }}
            >
              <p>Delivery Dates*</p>
              <input
                type="date"
                style={{ width: "200px" }}
                value={this.state.shippingDate}
                onChange={(e) => {
                  console.log("date", e.target.value);
                  this.setState({ shippingDate: e.target.value });
                }}
              />
            </span>
            <span
              style={{
                display: "flex",
                marginTop: "10px",
                marginLeft: "10px",
                flexDirection: "column",
              }}
            >
              <p>ShippingDetails*</p>
              <select
                value={this.state.selectedSlot}
                onChange={(e) => {
                  this.setState({ selectedSlot: e.target.value });
                }}
              >
                {this.state.slots.map((slot, index) => (
                  <option value={index}>{slot.dString}</option>
                ))}
              </select>
            </span>
            <span
              style={{
                display: "flex",
                marginTop: "10px",
                marginLeft: "10px",
                flexDirection: "row",
              }}
            >
              <input
                type="button"
                value="Submit"
                onClick={() => {
                  this.state.slots[
                    this.state.selectedSlot
                  ].shippingDate = this.state.shippingDate;
                  this.props.handleSubmit(
                    this.state.slots[this.state.selectedSlot],
                    this.state.orderInd
                  );
                }}
              />
              <input
                type="button"
                value="Close"
                style={{ marginLeft: "10px" }}
                onClick={() => this.props.handleClose}
              />
            </span>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch, router) => ({
  getParties: (data) => dispatch(getParties(data, router)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeliverySlotPopUp)
);
