import React, { Component } from "react";
import { connect } from "react-redux";
import XLSX from "xlsx";
import {
  createBulkProduct,
  uploadBulkImages,
} from "../../../../store/actions/catalogActions";
import { withRouter } from "react-router-dom";
import { Notify } from "../../../../constants/utils";
class ProductBulkUpload extends Component {
  state = {
    file: "",
    images: [],
    inputRef: React.createRef(),
    imageRef: React.createRef(),
  };

  inputHandler = (event) => {
    const file = event.target.files[0];
    const fileType = file.type;

    if (
      fileType !== ".csv" &&
      fileType !== ".xlsx" &&
      fileType !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      fileType !== "application/vnd.ms-excel"
    ) {
      Notify("danger", "File format is not supported", "Error");
      this.setState({ file: "" });
    } else {
      this.setState({ file });
    }
  };

  uploadProducts = () => {
    if (this.state.file) {
      var name = this.state.file.name;
      const reader = new FileReader();
      reader.onload = (evt) => {
        // evt = on_file_select event
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

        //removing the title row from rows of products
        let nameRow = data.shift();
        const dataPrice = [];
        let features = [];
        //mapping values to keys and creating an array of arrays
        for (var i = 0; i < data.length; i++) {
          //checking for the three required values of product and three required values of prices
          if (data[i].length > 0) {
            let arr=[0,1,25,26,29,30,27,49,50,17,18]
            let imageString = "";
            if (
              data[i][0] &&
              data[i][1] &&
              data[i][25] &&
              data[i][26] &&
              data[i][29] &&
              data[i][30] &&
              data[i][27] &&
              data[i][31] &&
              data[i][49] &&
              data[i][50] &&
              data[i][17] &&
              data[i][18]
            ) {
              if (data[i][19]) {
                let amazonString =
                  "https://giftabliss-prod.s3.us-east-1.amazonaws.com/";
                let images = data[i][20].split(";");

                for (let s = 0; s < images.length; s++) {
                  if (imageString === "") {
                    imageString = imageString + amazonString + images[s];
                  } else {
                    imageString = imageString + ";" + amazonString + images[s];
                  }
                }
              }
              let shipmentMethods = [];
              let shipmentMethodString = data[i][49];
              if (shipmentMethodString) {
                if (shipmentMethodString.length > 1) {
                  shipmentMethods = shipmentMethodString.split("-");
                } else {
                  shipmentMethods.push(data[i][49]);
                }
              }
              if(data[i][46]){
                let idsLen = data[i][46].trim().split('-').length;
                if(!data[i][47] || !data[i][47].trim()){
                  Notify(
                      "danger",
                      'Missing Sequence in product "' + data[i][1] + '"',
                      "Error"
                  );
                  break;
                }
                else if(!data[i][47] || data[i][47].trim().split('-').length!==idsLen){
                  Notify(
                      "danger",
                      'The length of sequence should be equal to number of productIds. Error in "' + data[i][1] + '"',
                      "Error"
                  );
                  break;
                }
              }


              // let shippmentMethods = data[i][49].split("-");
              const dataChunck = {
                id: data[i][0],
                title: data[i][1],
                internalName: data[i][2],
                brandName: data[i][3],
                OEMPartyId: data[i][4],
                comments: data[i][5],
                isVirtual: data[i][6],
                introductionDate: data[i][7],
                releaseDate: data[i][8],
                supportThroughDate: data[i][9],
                salesDiscontinuationThroughDate: data[i][10],
                amount: data[i][11],
                returnable: data[i][12],
                includeInPromotions: data[i][13],
                taxable: data[i][14],
                autoCreateKeywords: data[i][15],
                description: data[i][16],
                deliveryInfo: data[i][17],
                careInfo: data[i][18],
                category: data[i][19],
                images: imageString,
                isActive: data[i][21],
                isDeleted: data[i][22],
                createdDate: data[i][23],
                sku: data[i][41],
                photoOption: data[i][42],
                messageOption: data[i][43],
                productIds: data[i][46],
                sequenceNum: data[i][47],
                relevance: data[i][48],
                shippmentMethodIds: shipmentMethods,
                fromDate: data[i][29],
                longDescription: data[i][50],
                subCategory:data[i][51]
              };
              const dataChunckPrice = {
                lastModifiedBy: data[i][24],
                priceType: data[i][25],
                purpose: data[i][26],
                currencyUom: data[i][27],
                productStoreGroupId: data[i][28],
                fromDate: data[i][29],
                throughDate: data[i][30],
                price: data[i][31],
                termUomId: data[i][32],
                customPriceCalculation: data[i][33],
                taxPersentage: data[i][34],
                taxAuthorityParty: data[i][35],
                taxAuthGeoId: data[i][36],
                taxInPrice: data[i][37],
                taxAuthCombinedId: data[i][38],
                featurePrices: data[i][40] ? data[i][40] : "",
                listPrice: data[i][44] ? data[i][44] : "",
                webPrice: data[i][45] ? data[i][45] : "",
              };

              const feature = { featureId: data[i][39] ? data[i][39] : "" };
              features.push(feature);
              data[i] = dataChunck;
              dataPrice.push(dataChunckPrice);
              //if all the products have required values
            } else {
              for(let ind=0;ind<arr.length;ind++){
                if(!data[i][arr[ind]]){
                  Notify(
                      "danger",
                      'Missing data in column '+nameRow[arr[ind]]+' in product "' + data[i][1] + '"',
                      "Error"
                  );
                  break;
                }
              }
              break;

            }
          }

          if (data.length - 1 == i) {
            this.props.save({
              data: data.filter((dt) => dt.length !== 0),
              dataPrice: dataPrice,
              features: features,
            });
            this.setState({ file: "" });
            this.state.inputRef.current.value = "";
          }
        }
      };
      reader.readAsBinaryString(this.state.file);
    } else {
      this.setState({ file: "" });
      this.state.inputRef.current.value = "";
      Notify("danger", "Please select a file", "Error");
    }
  };

  multipleImageHandler = (e) => {
    this.setState({ images: [...e.target.files] });
  };

  multipleImageUpload = () => {
    let data = new FormData();

    for (let index = 0; index < this.state.images.length; index++) {
      const element = this.state.images[index];
      data.append(`${element.name.split(".")[0]}`, element);
    }

    this.props.uploadBulkImages(data);
    this.setState({ images: [] });
    this.state.imageRef.current.value = "";
  };

  render() {
    return (
      <div>
        <input
          type="file"
          accept=""
          onChange={this.inputHandler}
          ref={this.state.inputRef}
        />

        <button type="button" onClick={this.uploadProducts}>
          Upload
        </button>

        <div style={{ marginTop: "10px" }}>
          <h4 style={{ marginBottom: "5px" }}>Images: </h4>
          <input
            type="file"
            name="images"
            id="images"
            ref={this.state.imageRef}
            multiple
            onChange={this.multipleImageHandler}
          />

          <button type="button" onClick={this.multipleImageUpload}>
            Upload Images
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch, router) => ({
  save: (data) => dispatch(createBulkProduct(data, router)),
  uploadBulkImages: (data) => dispatch(uploadBulkImages(data, router)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductBulkUpload);
