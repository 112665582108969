import React, { Component } from "react";
import "./modal.scss";

class CustomModal extends Component {
  componentDidMount() {
    if (this.props.bgDisable) {
      const body = document.querySelector("body");
      const div = document.createElement("div");

      div.classList = "bg-disable";
      body.appendChild(div);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.bgDisable) {
      if (!this.props.active) {
        const div = document.querySelector(".bg-disable");
        if (div) {
          div.parentNode.removeChild(div);
        }
      } else {
        const body = document.querySelector("body");
        const div = document.createElement("div");

        div.classList = "bg-disable";
        body.appendChild(div);
      }
    }
  }

  render() {
    return (
      <div
        className={`custom-modal ${this.props.active ? "modal-active" : null}`}
      >
        {/* <div className="bg-disable"></div> */}
        <div className="modal-bar">
          <div className="modal-close" onClick={this.props.modalController}>
            <p
              style={{
                fontWeight: "700",
                fontSize: "12px",
                marginRight: "1px",
                color: "#257ac4",
              }}
            >
              &#9747;
            </p>
          </div>
        </div>

        <div className="modal-content">{this.props.children}</div>
      </div>
    );
  }
}

export default CustomModal;
