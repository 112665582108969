import { postRequest, putRequest } from "../services";
import { redirect } from "../../constants/utils";
import queryString from "query-string";

export const loginNow = (data, router) => async (dispatch) => {
  dispatch({ type: "SUBMITTED", payload: true });
  postRequest("auth/login", data)
    .then((response) => {
      dispatch(storeUser(response?.data, router));
      setCookie("valid", "validation", 1);
      let route = "/catalog/control/main";
      let prevRoute = localStorage.getItem("route");
      if (prevRoute) {
        route = prevRoute;
        localStorage.removeItem("route");
      }
      redirect(router, route);
    })
    .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};

const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  // d.setTime(d.getTime() + (10*1000));

  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};
export const storeUser =
  (data, router, navigate = true) =>
  (dispatch) => {
    let route = "/catalog/control/main";
    var prevRoute = queryString.parse(router?.location.search)?.redirect;
    route = prevRoute ? prevRoute + router?.location?.hash : route;
    dispatch({ type: "STORE_USER", payload: data });
    // if (navigate) {
    //   redirect(router, route);
    // }
  };
export const changeCurrency = (currency) => (dispatch, getState) => {
  let user = getState().auth?.user;
  if (user?._id) {
    dispatch(updateUser({ _id: user?._id, currency: currency }));
  } else {
    dispatch({ type: "UPDATE_CURRENCY", payload: currency });
  }
};
export const updateUser = (data) => (dispatch, getState) => {
  var token = getState().auth?.user?.token;
  putRequest("users/update/" + data?._id, data, true, {
    "access-token": token,
  })
    .then((response) => {
      var user = { ...response?.data?.[0], token: token };
      dispatch(storeUser(user, null, false));
    })
    .catch((error) => console.error(error));
};
export const logoutNow = (router) => (dispatch) => {
  dispatch({ type: "LOGOUT", payload: {} });
  redirect(router, "/");
};

export const getSigned = (data, router) => async (dispatch) => {
  return postRequest("auth/getSigned/" + data)
    .then((response) => {
      return response.data;
    })
    .catch((reason) => console.log("reason", reason.message));
};

export const getUsers = (data, router) => async (dispatch) => {
  return putRequest("auth/getUser")
    .then((response) => {
      return response.data;
    })
    .catch((reason) => console.log("reason", reason.message));
};
