import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  allActiveMeta,
  deleteMeta,
} from "../../../../store/actions/catalogActions";

function FindMeta() {
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState();
  const [noOfPages, setNoOfPages] = useState();
  const [pageSize, setPageSize] = useState();
  const [orderString, setOrderString] = useState([]);
  const metaData = useSelector((state) => state.catalog?.meta?.active);
  const handleSubmit = () => {};
  useEffect(() => {
    dispatch(allActiveMeta());
  }, [dispatch]);
  const handleDelete = (id) => {
    dispatch(deleteMeta(id, callback));
  };
  const callback = () => {
    dispatch(allActiveMeta());
  };
  return (
    <div id="content-main-section" class="leftonly">
      <div id="centerdiv" class="no-clear">
        <div class="button-bar button-style-2">
          <ul>
            <li>
              <ul>
                <li class="buttontext create">
                  <Link
                    class="buttontext create"
                    to="/catalog/control/EditMeta"
                  >
                    New Meta
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div id="screenlet_1" class="screenlet">
          <div id="screenlet_1_col" class="screenlet-body no-padding">
            <h3>Search Results</h3>
            <div id="search-results">
              {/*<DataTable>*/}
              <React.Fragment>
                <div className="nav-pager">
                  <ul>
                    <li className="nav-first-disabled">First</li>
                    <li className="nav-previous-disabled">Previous</li>
                    <li className="nav-page-select">
                      Page
                      <select
                        name="page"
                        size="1"
                        value={pageNumber}
                        onChange={async (e) => {
                          //   await setState({pageNumber: e.target.value})
                          await setPageNumber(e.target.value);
                          handleSubmit();
                        }}
                      >
                        {Array(noOfPages)
                          .fill(0)
                          .map((val, i) => (
                            <option value={i}>{i + 1}</option>
                          ))}
                      </select>
                    </li>
                    <li className="nav-next-disabled">Next</li>
                    <li className="nav-last-disabled">Last</li>
                    <li className="nav-pagesize">
                      <select
                        name="pageSize"
                        size="1"
                        value={pageSize}
                        onChange={async (e) => {
                          await setPageSize(e.target.value);
                          await setPageNumber(0);
                          handleSubmit();
                        }}
                      >
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option defaultValue value="200">
                          200
                        </option>
                      </select>
                      Items per page
                    </li>
                    <li className="nav-displaying">Displaying 1 - 8 of 8</li>
                  </ul>
                </div>
                <table class="basic-table hover-bar" cellspacing="0">
                  <tbody>
                    <tr class="header-row-2">
                      <td>
                        <a
                          class="sort-order"
                          href="/catalog/control/FindNavigation?sortField=productId&amp;noConditionFind=N"
                          onClick={async (e) => {
                            e.preventDefault();
                            if (
                              orderString[0] == "productId" &&
                              orderString[1] == "DESC"
                            ) {
                              //   await setState({
                              //     orderString: ["productId", "ASC"],
                              //   });
                              await setOrderString(["productId", "ASC"]);
                            } else {
                              //   await setState({
                              // orderString: ["productId", "DESC"],
                              //   });
                              await setOrderString(["productId", "DESC"]);
                            }

                            handleSubmit();
                          }}
                        >
                          Id
                        </a>
                      </td>
                      <td>
                        <Link
                          class="sort-order"
                          to="/catalog/control/FindNavigation?sortField=idValue&amp;noConditionFind=N"
                        >
                          Unique Key
                        </Link>
                      </td>
                      <td>
                        <Link
                          class="sort-order"
                          to="/catalog/control/FindNavigation?sortField=productTypeId&amp;noConditionFind=N"
                        >
                          Title
                        </Link>
                      </td>
                      <td>
                        <Link
                          class="sort-order"
                          to="/catalog/control/FindNavigation?sortField=productTypeId&amp;noConditionFind=N"
                        >
                          Description
                        </Link>
                      </td>
                      <td>Action</td>
                    </tr>
                    {metaData?.map((meta) => {
                      return (
                        <tr>
                          <td>
                            <Link
                              to={
                                "/catalog/control/EditMeta?metaId=" +
                                meta.uniqueKey
                              }
                              title="Meta Id"
                            >
                              {meta._id}
                            </Link>
                          </td>
                          <td>{meta?.uniqueKey}</td>
                          <td>{meta?.title}</td>
                          <td>{meta?.description}</td>
                          <td>
                            <button
                              onClick={() => handleDelete(meta?._id)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="nav-pager">
                  <ul>
                    <li className="nav-first-disabled">First</li>
                    <li className="nav-previous-disabled">Previous</li>
                    <li className="nav-page-select">
                      Page
                      <select
                        name="page"
                        size="1"
                        value={pageNumber}
                        onChange={async (e) => {
                          await setPageNumber(e.target.value);
                          //   await setState({ pageNumber: e.target.value });
                          handleSubmit();
                        }}
                      >
                        {Array(noOfPages)
                          .fill(0)
                          .map((val, i) => (
                            <option value={i}>{i + 1}</option>
                          ))}
                      </select>
                    </li>
                    <li className="nav-next-disabled">Next</li>
                    <li className="nav-last-disabled">Last</li>
                    <li className="nav-pagesize">
                      <select
                        name="pageSize"
                        size="1"
                        value={pageSize}
                        onChange={async (e) => {
                          //   await setState({
                          //     pageSize: e.target.value,
                          //     pageNumber: 0,
                          //   });
                          await setPageSize(e.target.value);
                          await setPageNumber(0);
                          handleSubmit();
                        }}
                      >
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option defaultValue value="200">
                          200
                        </option>
                      </select>
                      Items per page
                    </li>
                    <li className="nav-displaying">Displaying 1 - 8 of 8</li>
                  </ul>
                </div>
              </React.Fragment>
              {/*</DataTable>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FindMeta;
