import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import '../../styles.scss';

class BillingAccount extends Component {

    render() {
        const {userById} = this.props;
        const query = new URLSearchParams(this.props?.location?.search);
        const partyId = query.get('partyId');

        return (
            <div id="searchOptions" className="screenlet" style={{marginTop: '10px'}}>
                <div className="screenlet-title-bar">
                    <ul>
                        <li className="h3">Edit Billing Account</li>
                    </ul>
                </div>

                <div id="searchOptions_col" className="screenlet-body">
                    <div id="search-options">
                        <form>
                            <table cellSpacing="0" className='basic-table'>
                                <tbody>
                                <tr>
                                    <td className='label'>
                                        <label>Account Limit</label>
                                    </td>
                                    <td>
                                        <input type='text'/>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='label'>
                                        <label>Account Currency Uom Id</label>
                                    </td>
                                    <td>
                                        <select>
                                            <option> --Select one---</option>
                                            <option value="AFA">Afghani - AFA</option>
                                            <option value="ALL">Albanian Lek - ALL</option>
                                            <option value="DZD">Algerian Dinar - DZD</option>
                                            <option value="ADP">Andoran peseta - ADP</option>
                                            <option value="AOK">Angolan Kwanza - AOK</option>
                                            <option value="ARS">Argentina Peso - ARS</option>
                                            <option value="ARA">Argentinian Austral - ARA</option>
                                            <option value="AMD">Armenian Dram - AMD</option>
                                            <option value="AWG">Aruban Guilder - AWG</option>
                                            <option value="AUD">Australian Dollar - AUD</option>
                                            <option value="AZM">Azerbaijan Manat - AZM</option>
                                            <option value="BSD">Bahaman Dollar - BSD</option>
                                            <option value="BHD">Bahrain Dinar - BHD</option>
                                            <option value="BDT">Bangladesh Taka - BDT</option>
                                            <option value="BBD">Barbados Dollar - BBD</option>
                                            <option value="BZD">Belize Dollar - BZD</option>
                                            <option value="BYR">Belorussian Ruble - BYR</option>
                                            <option value="XOF">Benin C.f.A. Franc - XOF</option>
                                            <option value="BMD">Bermudan Dollar - BMD</option>
                                            <option value="BOB">Bolivian Boliviano - BOB</option>
                                            <option value="BAD">Bosnia-Herzogovinian Dinar - BAD</option>
                                            <option value="BWP">Botswana Pula - BWP</option>
                                            <option value="BRR">Brazil - BRR</option>
                                            <option value="BRL">Brazilian Real - BRL</option>
                                            <option value="GBP">British Pound - GBP</option>
                                            <option value="BND">Brunei Dollar - BND</option>
                                            <option value="BGN">Bulgarian Lev - BGN</option>
                                            <option value="BIF">Burundi Franc - BIF</option>
                                            <option value="KHR">Cambodian Riel - KHR</option>
                                            <option value="CAD">Canadian Dollar - CAD</option>
                                            <option value="CVE">Cape Verde Escudo - CVE</option>
                                            <option value="KYD">Cayman Dollar - KYD</option>
                                            <option value="CLP">Chilean Peso - CLP</option>
                                            <option value="CNY">China - CNY</option>
                                            <option value="COP">Colombian Peso - COP</option>
                                            <option value="KMF">Comoros Franc - KMF</option>
                                            <option value="CRC">Costa Rica Colon - CRC</option>
                                            <option value="HRK">Croatian Kuna - HRK</option>
                                            <option value="CUP">Cuban Peso - CUP</option>
                                            <option value="CYP">Cyprus Pound - CYP</option>
                                            <option value="CZK">Czech Krona - CZK</option>
                                            <option value="DKK">Danish Krone - DKK</option>
                                            <option value="DJF">Djibouti Franc - DJF</option>
                                            <option value="DOP">Dominican Peso - DOP</option>
                                            <option value="DRP">Dominican Republic Peso - DRP</option>
                                            <option value="XCD">East Carribean Dollar - XCD</option>
                                            <option value="ECS">Ecuador Sucre - ECS</option>
                                            <option value="EGP">Egyptian Pound - EGP</option>
                                            <option value="SVC">El Salvador Colon - SVC</option>
                                            <option value="EEK">Estonian Krone - EEK</option>
                                            <option value="ETB">Ethiopian Birr - ETB</option>
                                            <option value="EUR">Euro - EUR</option>
                                            <option value="FKP">Falkland Pound - FKP</option>
                                            <option value="FJD">Fiji Dollar - FJD</option>
                                            <option value="XAF">Gabon C.f.A Franc - XAF</option>
                                            <option value="GMD">Gambian Dalasi - GMD</option>
                                            <option value="GEK">Georgian Kupon - GEK</option>
                                            <option value="GHC">Ghanian Cedi - GHC</option>
                                            <option value="GIP">Gibraltar Pound - GIP</option>
                                            <option value="GTQ">Guatemalan Quedzal - GTQ</option>
                                            <option value="GNF">Guinea Franc - GNF</option>
                                            <option value="GWP">Guinea Peso - GWP</option>
                                            <option value="GYD">Guyanese Dollar - GYD</option>
                                            <option value="HTG">Haitian Gourde - HTG</option>
                                            <option value="HNL">Honduran Lempira - HNL</option>
                                            <option value="HKD">Hong Kong Dollar - HKD</option>
                                            <option value="HUF">Hungarian forint - HUF</option>
                                            <option value="ISK">Iceland Krona - ISK</option>
                                            <option value="IDR">Indonesian Rupiah - IDR</option>
                                            <option value="INR">Indian Rupee - INR</option>
                                            <option value="IRR">Iranian Rial - IRR</option>
                                            <option value="IQD">Iraqui Dinar - IQD</option>
                                            <option value="JMD">Jamaican Dollar - JMD</option>
                                            <option value="JPY">Japanese Yen - JPY</option>
                                            <option value="JOD">Jordanian Dinar - JOD</option>
                                            <option value="KZT">Kazakhstani Tenge - KZT</option>
                                            <option value="KES">Kenyan Shilling - KES</option>
                                            <option value="KIS">Kirghizstan Som - KIS</option>
                                            <option value="KWD">Kuwaiti Dinar - KWD</option>
                                            <option value="LAK">Laotian Kip - LAK</option>
                                            <option value="LVL">Latvian Lat - LVL</option>
                                            <option value="LBP">Lebanese Pound - LBP</option>
                                            <option value="SLL">Leone - SLL</option>
                                            <option value="LSL">Lesotho Loti - LSL</option>
                                            <option value="LRD">Liberian Dollar - LRD</option>
                                            <option value="LYD">Libyan Dinar - LYD</option>
                                            <option value="LTL">Lithuanian Lita - LTL</option>
                                            <option value="MOP">Macao Pataca - MOP</option>
                                            <option value="MGF">Madagascan Franc - MGF</option>
                                            <option value="MWK">Malawi Kwacha - MWK</option>
                                            <option value="MYR">Malaysian Ringgit - MYR</option>
                                            <option value="MVR">Maldive Rufiyaa - MVR</option>
                                            <option value="MTL">Maltese Lira - MTL</option>
                                            <option value="MRO">Mauritanian Ouguiya - MRO</option>
                                            <option value="MUR">Mauritius Rupee - MUR</option>
                                            <option value="MXN">Mexican Peso (new) - MXN</option>
                                            <option value="MXP">Mexican Peso (old) - MXP</option>
                                            <option value="MDL">Moldavian Lei - MDL</option>
                                            <option value="MNT">Mongolian Tugrik - MNT</option>
                                            <option value="MAD">Moroccan Dirham - MAD</option>
                                            <option value="MZM">Mozambique Metical - MZM</option>
                                            <option value="NPR">Nepalese Rupee - NPR</option>
                                            <option value="NIS">New Israeli Shekel - NIS</option>
                                            <option value="TWD">New Taiwan Dollar - TWD</option>
                                            <option value="NZD">New Zealand Dollar - NZD</option>
                                            <option value="NIC">Nicaragua - NIC</option>
                                            <option value="NIO">Nicaraguan Cordoba - NIO</option>
                                            <option value="NGN">Nigerian Naira - NGN</option>
                                            <option value="KPW">North Korean Won - KPW</option>
                                            <option value="NOK">Norwegian Krone - NOK</option>
                                            <option value="OMR">Omani Rial - OMR</option>
                                            <option value="PKR">Pakistan Rupee - PKR</option>
                                            <option value="PAB">Panamanian Balboa - PAB</option>
                                            <option value="PGK">Papua New Guinea Kina - PGK</option>
                                            <option value="PYG">Paraguayan Guarani - PYG</option>
                                            <option value="SOL">Peru - SOL</option>
                                            <option value="PEI">Peruvian Inti - PEI</option>
                                            <option value="PES">Peruvian Sol - PES</option>
                                            <option value="PEN">Peruvian Sol - New - PEN</option>
                                            <option value="PHP">Philippino Peso - PHP</option>
                                            <option value="PLZ">Poland - PLZ</option>
                                            <option value="PLN">Polish Zloty - PLN</option>
                                            <option value="QAR">Qatar Riyal - QAR</option>
                                            <option value="ROL">Romanian Leu - ROL</option>
                                            <option value="RUR">Russian Rouble - RUR</option>
                                            <option value="SUR">Russian Ruble (old) - SUR</option>
                                            <option value="RWF">Rwanda Franc - RWF</option>
                                            <option value="WST">Samoan Tala - WST</option>
                                            <option value="CDP">Santo Domiongo - CDP</option>
                                            <option value="STD">Sao Tome / Principe Dobra - STD</option>
                                            <option value="SAR">Saudi Riyal - SAR</option>
                                            <option value="SCR">Seychelles Rupee - SCR</option>
                                            <option value="SGD">Singapore Dollar - SGD</option>
                                            <option value="SBD">Solomon Islands Dollar - SBD</option>
                                            <option value="SOS">Somalian Shilling - SOS</option>
                                            <option value="ZAR">South African Rand - ZAR</option>
                                            <option value="KRW">South Korean Won - KRW</option>
                                            <option value="LKR">Sri Lankan Rupee - LKR</option>
                                            <option value="SHP">St.Helena Pound - SHP</option>
                                            <option value="SDP">Sudanese Pound - SDP</option>
                                            <option value="SRG">Surinam Guilder - SRG</option>
                                            <option value="SZL">Swaziland Lilangeni - SZL</option>
                                            <option value="SEK">Swedish Krona - SEK</option>
                                            <option value="CHF">Swiss Franc - CHF</option>
                                            <option value="SYP">Syrian Pound - SYP</option>
                                            <option value="TJR">Tadzhikistani Ruble - TJR</option>
                                            <option value="TZS">Tanzanian Shilling - TZS</option>
                                            <option value="THB">Thailand Baht - THB</option>
                                            <option value="TPE">Timor Escudo - TPE</option>
                                            <option value="TOP">Tongan Pa'anga - TOP</option>
                                            <option value="TTD">Trinidad and Tobago Dollar - TTD</option>
                                            <option value="TND">Tunisian Dinar - TND</option>
                                            <option value="TRY">Turkish Lira - TRY</option>
                                            <option value="TMM">Turkmenistani Manat - TMM</option>
                                            <option value="UGS">Ugandan Shilling - UGS</option>
                                            <option value="UAH">Ukrainian Hryvnia - UAH</option>
                                            <option value="AED">United Arab Emirates Dirham - AED</option>
                                            <option value="USD">American Dollar - USD</option>
                                            <option value="UYU">Uruguay - UYU</option>
                                            <option value="UYP">Uruguayan New Peso - UYP</option>
                                            <option value="VUV">Vanuatu Vatu - VUV</option>
                                            <option value="VEB">Venezuelan Bolivar - VEB</option>
                                            <option value="VND">Vietnamese Dong - VND</option>
                                            <option value="ANG">West Indian Guilder - ANG</option>
                                            <option value="YER">Yemeni Ryal - YER</option>
                                            <option value="ZRZ">Zaire - ZRZ</option>
                                            <option value="ZMK">Zambian Kwacha - ZMK</option>
                                            <option value="ZWD">Zimbabwean Dollar - ZWD</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='label'>
                                        <label>Contact Mech Id</label>
                                    </td>
                                    <td>
                                        <input type='text'/>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='label'>
                                        <label>From Date</label>
                                    </td>
                                    <td>
                                        <input type='date'/>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='label'>
                                        <label>Through Date</label>
                                    </td>
                                    <td>
                                        <input type='date'/>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='label'>
                                        <label>Description</label>
                                    </td>
                                    <td>
                                        <input type='text' className='custom-input-width'/>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='label'>
                                        <label>External Account Id</label>
                                    </td>
                                    <td>
                                        <input type='text'/>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='label'>
                                        <label>Party Billed To</label>
                                    </td>
                                    <td>
                                        <input type='text' value={partyId}/>
                                        <span className='required-text'> {userById.name} * </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='label'>
                                        <label>Available Balance</label>
                                    </td>
                                    <td>
                                        <p>$0.00 This is the amount which can be used for new orders.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <div>
                                            <button type='submit' className='buttontext create'>
                                                Save
                                            </button>
                                        </div>
                                        <div>
                                            <Link className="buttontext create"
                                                  to={`viewprofile?partyId=${partyId}&_id=${userById?._id}`}>Cancel/Done</Link> {' '}
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                </div>

            </div>
        );

    }

}


const mapStateToProps = (state) => ({
    userById: state.partyManager?.users?.userById,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BillingAccount);
