import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

export class Main extends Component {
  constructor() {
    super();
    this.state = {
      catalogId: "",
      productCategoryId: "",
      productId: "",
      idValue: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    const key = event.target.name;
    this.setState({ [key]: event.target.value });
  }
  handleSubmit(event) {
    const { catalogId, productCategoryId, productId, idValue } = this.state;
    if (catalogId) {
      this.props.history.push(
        `/catalog/control/EditProdCatalog?prodCatalogId=${catalogId}`
      );
    } else if (productCategoryId) {
      this.props.history.push(
        `/catalog/control/EditCategory?prodCategoryId=${productCategoryId}`
      );
    } else if (productId) {
      this.props.history.push(
        `/catalog/control/EditFnpProduct?productId=${productId}`
      );
    } else if (idValue) {
      this.props.history.push(
        `/catalog/control/FindProductById?productId=${idValue}`
      );
    }
  }
  render() {
    return (
      <div id="content-main-section" className="leftonly">
        <div id="centerdiv" className="no-clear">
          <div className="screenlet" id="screenlet_1">
            <div className="screenlet-title-bar">
              <ul>
                <li className="h3">Catalog Administration Main Page</li>
              </ul>
              <br className="clear" />
            </div>
            <div id="screenlet_1_col" className="screenlet-body">
              <br />
              <div className="label">Edit Catalog with Catalog ID:</div>
              <input
                type="text"
                size="20"
                maxLength="20"
                name="catalogId"
                onChange={this.handleChange}
              />
              <input
                type="submit"
                value=" Edit Catalog"
                className="smallSubmit"
                onClick={this.handleSubmit}
              />
              <div className="label">
                OR:{" "}
                <a
                  href="/catalog/control/EditProdCatalog"
                  className="buttontext"
                >
                  Create New Catalog
                </a>
              </div>
              <br />
              <div className="label">Edit Category with Category ID:</div>

              <span className="field-lookup">
                <div id="3_lookupId_productCategoryId_auto"></div>
                <span
                  role="status"
                  aria-live="polite"
                  className="ui-helper-hidden-accessible"
                ></span>
                <input
                  type="text"
                  name="productCategoryId"
                  size="20"
                  maxLength="20"
                  id="3_lookupId_productCategoryId"
                  className="ui-autocomplete-input"
                  autoComplete="off"
                  onChange={this.handleChange}
                />
                <a href="/" id="3_lookupId_button">
                  {" "}
                </a>
              </span>

              <input
                type="submit"
                value="Edit Category"
                className="smallSubmit"
                onClick={this.handleSubmit}
              />
              <br />
              <div className="label">
                OR:{" "}
                <a href="/catalog/control/EditCategory" className="buttontext">
                  Create New Category
                </a>
              </div>
              <br />
              <div className="label">Edit Product with Product ID:</div>
              <span className="field-lookup">
                <div id="4_lookupId_productId_auto"></div>
                <span
                  role="status"
                  aria-live="polite"
                  className="ui-helper-hidden-accessible"
                ></span>
                <input
                  type="text"
                  name="productId"
                  size="20"
                  maxLength="20"
                  id="4_lookupId_productId"
                  className="ui-autocomplete-input"
                  autoComplete="off"
                  onChange={this.handleChange}
                />

                <a href="/" id="4_lookupId_button">
                  {" "}
                </a>
              </span>

              <input
                type="submit"
                value=" Edit Product"
                className="smallSubmit"
                onClick={this.handleSubmit}
              />
              <br />
              <div className="label">
                OR:{" "}
                <a href="/catalog/control/EditProduct" className="buttontext">
                  Create New Product
                </a>
              </div>
              <br />
              <div className="label">
                OR:{" "}
                <a
                  href="/catalog/control/CreateVirtualWithVariantsForm"
                  className="buttontext"
                >
                  Quick Create Virtual from Variants
                </a>
              </div>
              <br />
              <div className="label">Find Product with ID Value:</div>

              <input
                type="text"
                size="20"
                maxLength="20"
                name="idValue"
                onChange={this.handleChange}
              />
              <input
                type="submit"
                value=" Find Product"
                className="smallSubmit"
                onClick={this.handleSubmit}
              />
              <br />
              <div>
                <a
                  href="/catalog/control/UpdateAllKeywords"
                  className="buttontext"
                >
                  {" "}
                  Auto Create Keywords for All Products
                </a>
              </div>
              <div>
                <a href="/catalog/control/FastLoadCache" className="buttontext">
                  {" "}
                  Fast Load Catalog into Cache
                </a>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
