import React from "react";
import {withRouter} from "react-router-dom";
import {pathParts} from "../../../constants/utils";
import {connect} from "react-redux"
import {getOrdersOnHold,getAllocatedOrders,getAcceptedOrders,getNotAllocatedOrders} from "../../../store/actions/orderActions";

class SubOrderComponent extends React.Component{
     componentDidMount() {
         let path = pathParts(this.props.history.location.pathname);
         if(path?.[3]==='onHold'){
            this.props.getOrdersOnHold();
         }
         else if(path?.[3]==='allocated'){
             this.props.getAllocatedOrders();
         }
         else if(path?.[3]==='notAllocated'){
            this.props.getNotAllocatedOrders();
         }
         else{
             this.props.getAcceptedOrders();
         }
     }


     render() {
        return(
            <div id="findOrdersList" className="screenlet">
                <div className="screenlet-title-bar">
                    <ul>
                        <li className="h3">Order List</li>
                    </ul>
                </div>
                <div className="screenlet-body">
                    <table className="basic-table hover-bar" cellSpacing="0">
                        <thead>
                        <tr className="header-row">
                            <td width="10%">Date</td>
                            <td width="10%">Order Nbr</td>
                            <td width="15%">Customer Name</td>
                            <td width="10%">Customer Mobile</td>
                            <td width="10%">Source</td>
                            <td width="10%">Grand Total</td>
                        </tr>
                        </thead>
                        <tbody>
                        {this.props?.subOrders?.map((sub)=>{
                            return(
                                <tr>
                                    <td width="10%">Date</td>
                                    <td width="10%">{sub?.subOrderId}</td>
                                    <td width="15%">{sub["user.name"]}</td>
                                    <td width="10%">{sub["user.countryCode"]?sub["user.countryCode"]:''}{sub["user.mobile"]?sub["user.mobile"]:''}</td>
                                    <td width="10%">Source</td>
                                    <td width="10%">{sub?.currency} {sub?.sum}</td>
                                </tr>
                            )
                        })}

                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

}
const mapStateToProps = (state) => ({
   subOrders:state?.orderReducer?.subOrderComponentArray,
});

const mapDispatchToProps = (dispatch, router) => ({
    getOrdersOnHold:()=>dispatch(getOrdersOnHold()),
    getAcceptedOrders:()=>dispatch(getAcceptedOrders()),
    getAllocatedOrders:()=>dispatch(getAllocatedOrders()),
    getNotAllocatedOrders:()=>dispatch(getNotAllocatedOrders()),
});
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SubOrderComponent)
);