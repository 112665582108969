import { combineReducers } from "redux";
import authReducer from "./authReducer";
import * as catalog from "./catalogReducer";
import * as partyManager from "./partyManagerReducer";
import orderReducer from "./orderReducer";
import navigationReducer from "./navigationReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  catalog: combineReducers({
    ...catalog,
  }),
  partyManager: combineReducers({
    ...partyManager,
  }),
  orderReducer,
  navigations: navigationReducer,
});

export default rootReducer;
