import React, { Component } from "react";
import { connect } from "react-redux";
import XLSX from "xlsx";
import { uploadBulkOrders } from "../../../store/actions/orderActions";
import { uploadBulkImages } from "../../../store/actions/catalogActions";
import { withRouter } from "react-router-dom";
import { Notify } from "../../../constants/utils";
class BulkUploadOrders extends Component {
  state = {
    file: "",
    images: [],
    inputRef: React.createRef(),
  };

  inputHandler = (event) => {
    const file = event.target.files[0];
    const fileType = file.type;

    if (
      fileType !== ".csv" &&
      fileType !== ".xlsx" &&
      fileType !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      fileType !== "application/vnd.ms-excel"
    ) {
      Notify("danger", "File format is not supported", "Error");
      this.setState({ file: "" });
    } else {
      this.setState({ file });
    }
  };

  multipleImageHandler = (e) => {
    this.setState({ images: [...e.target.files] });
  };
  multipleImageUpload = () => {
    let data = new FormData();

    for (let index = 0; index < this.state.images.length; index++) {
      const element = this.state.images[index];
      data.append(`${element.name.split(".")[0]}`, element);
    }
    this.props.uploadBulkImages(data);
  };

  uploadProducts = () => {
    if (this.state.file) {
      var name = this.state.file.name;
      const reader = new FileReader();
      reader.onload = (evt) => {
        // evt = on_file_select event
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

        //removing the title row from rows of products
        data.shift();
        const dataPrice = [];
        let features = [];
        //mapping values to keys and creating an array of arrays
        for (var i = 0; i < data.length; i++) {
          //checking for required values
          if (data[i].length > 0) {
            if (
              data[i][0] &&
              data[i][1] &&
              data[i][2] &&
              data[i][4] &&
              data[i][10] &&
              data[i][11] &&
              data[i][14] &&
              data[i][15] &&
              data[i][16] &&
              data[i][17] &&
              data[i][18] &&
              data[i][19] &&
              true
            ) {
              const dataChunck = {
                id: data[i][0],
                cartId: data[i][1],
                quantity: data[i][2],
                orderSource: data[i][3],
                paymentMethod: data[i][4],
                paymentLink: data[i][5],
                ccEmail: data[i][6],
                transactionId: data[i][7],
                paymentStatus: data[i][8],
                senderEmail: data[i][9],
                senderName: data[i][10],
                senderPhone: data[i][11],
                deliveryStatus: data[i][12],
                paymentVarified: data[i][13],
                customer: data[i][14],
                city: data[i][15],
                orderStatus: data[i][16],
                shippingType: data[i][17],
                shippingTime: data[i][18],
                shippingDate: data[i][19],
                parentCartId: data[i][20],
                name: data[i][21],
                recipientCity: data[i][22],
                receipientAddress: data[i][23],
                phone: data[i][24],
                landmark: data[i][25],
                addressType: data[i][26],
                phoneAlt: data[i][27],
                email: data[i][28],
                giftCard: data[i][29],
                giftCard_occassion: data[i][30],
                giftCard_message: data[i][31],
                giftCard_receiver: data[i][32],
                giftCard_fromText: data[i][33],
                giftCard_sender: data[i][34],
                productId: data[i][35],
                featureId: data[i][36],
                cakeType: data[i][37],
                eggOption: data[i][38],
                cakeMessage: data[i][39],
                image: data[i][40]
                  ? `https://giftabliss-prod.s3.us-east-1.amazonaws.com/${data[i][40]}`
                  : null,
              };
              data[i] = dataChunck;
              //if all the products have required values
            } else {
              Notify("danger", "Missing data in some field", "Error");
              this.setState({ file: "" });
              this.state.inputRef.current.value = "";
              break;
            }
          }

          if (data.length - 1 == i) {
            this.props.upload(data);
            this.setState({ file: "" });
            this.state.inputRef.current.value = "";
          }
        }
      };
      reader.readAsBinaryString(this.state.file);
    } else {
      this.setState({ file: "" });
      this.state.inputRef.current.value = "";
      Notify("danger", "Please select a file", "Error");
    }
  };

  render() {
    return (
      <div>
        <input
          type="file"
          accept=""
          ref={this.state.inputRef}
          onChange={this.inputHandler}
        />

        <button type="button" onClick={this.uploadProducts}>
          Upload
        </button>
        <h4 style={{ marginBottom: "5px" }}>Images: </h4>
        <input
          type="file"
          name="images"
          id="images"
          multiple
          onChange={this.multipleImageHandler}
        />

        <button type="button" onClick={this.multipleImageUpload}>
          Upload Images
        </button>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch, router) => ({
  upload: (data) => dispatch(uploadBulkOrders(data, router)),
  uploadBulkImages: (data) => dispatch(uploadBulkImages(data, router)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkUploadOrders);
