import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import moment from "moment"
import {actionOrderHistory} from "../../../store/actions/orderActions";
import {withRouter} from "react-router";

function mapStateToProps(state) {
    return {
        orders:state.orderReducer.orderHistory
    };
}

function mapDispatchToProps(dispatch) {
    return {
        get:(sub)=>dispatch(actionOrderHistory(sub)),
    };
}

class OrderHistory extends Component {
    componentDidMount() {
        let search = this.props.history.location.search;
        let parms = new URLSearchParams(search);
        let sub = parms.get('orderId');
        this.props.get(sub);
    }
    getTextSort=(direction, v1, v2)=> {
        if (v1 !== undefined && v2 !== undefined) {
            if (typeof v1 === "string" && typeof v2 === "string") {
                v1 = v1.trim();
                v2 = v2.trim();
                v1 = v1.toLowerCase();
                v2 = v2.toLowerCase();
                v1 = v1.length === 0 ? " " : v1;
                v2 = v2.length === 0 ? " " : v2;
            }
            if (v1 > v2) return direction;
            if (v1 < v2) return -direction;
        }
        return 0;
    }

    render() {
        return (
            <div>
                <div id="findOrdersList" className="screenlet">
                    <div className="screenlet-title-bar">
                        <ul>
                            <li className="h3">Order History</li>
                        </ul>
                    </div>
                    <div className="screenlet-body">
                        <table className="basic-table hover-bar" cellSpacing="0">
                            <tbody>
                            <tr className="header-row">
                                <td width="10%">Activity</td>
                                <td width="10%">By</td>
                                <td width="15%">Date</td>
                            </tr>
                            {this.props.orders &&
                            this.props.orders?.sort((a,b)=>this.getTextSort(-1,a.id,b.id)).map((order) => {
                                return (
                                    <tr>
                                        <td>{order?.transection}</td>
                                        <td>
                                            {order?.user?.name}
                                        </td>
                                        <td>{moment(order?.createdDate).utc(true).format("LLLL")}</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        );
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderHistory));