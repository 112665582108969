import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Notify, redirect } from "../../../constants/utils";
import DataTable from "../../components/DataTable";
import queryString from "query-string";
//import EditProductNav from "./EditProductNav";
import {
  createParty,
  allActiveParties,
  deleteParty,
  searchParty,
  updateParty,
  createCountries,
  createCities,
  allActiveCountries,
  getCities,
} from "../../../store/actions/partyManagerActions";
import { parties } from "../../../store/reducers/partyManagerReducer";
//import DuplicateProduct from "./DuplicateProduct";

export class Main extends Component {
  render() {
    const parties = Array.from(this.props.parties);
    const countries = Array.from(this.props.countries);
    let cities = Array.from(this.props.cities);
    console.log("this is party State", this.state);
    const { _G49_, _G51_, _G53_, _G55_, _G57_, _G59_ } = this.state;
    //const query = queryString.parse(this.props.location.search);
    // const product = query.productId;
    return (
      <div id="content-main-section">
        <div id="centerdiv" class="no-clear">
          <div id="screenlet_1" class="screenlet">
            <div class="screenlet-title-bar">
              <ul>
                <li class="h3">Edit Product</li>
                <li>
                  <a href="#">Create New Party Group</a>
                </li>
                <li>
                  <a href="#">Create New Person</a>
                </li>
                <li>
                  <a href="#">Create Customer</a>
                </li>
                <li>
                  <a href="#">Create Prospect</a>
                </li>
                <li>
                  <a href="#">Create Employee</a>
                </li>
              </ul>
            </div>
            <div id="screenlet_1_col" class="screenlet-body">
              <form
                id="EditProduct"
                class="basic-form"
                onSubmit={(e) => this.handleSubmit(e)}
              >
                <div id="_G47_">
                  <div class="fieldgroup-title-bar"> </div>
                  <div id="_G47__body" class="fieldgroup-body">
                    <table class="basic-table" cellspacing="0">
                      <tbody>
                        <tr>
                          <td class="label">
                            <label
                              id="Contact_information"
                              for="EditProduct_productId"
                            >
                              Contact Information
                            </label>
                          </td>
                          <td>
                            <input
                              id="None"
                              maxlength="20"
                              name="Contact_information"
                              value="None"
                              onChange={(e) =>
                                this.setState({
                                  contactInformation: e.target.value,
                                })
                              }
                              type="radio"
                            />
                            <label for="None"> None </label>
                            <input
                              id="Postal"
                              maxlength="20"
                              name="Contact_information"
                              value="Postal"
                              onChange={(e) =>
                                this.setState({
                                  contactInformation: e.target.value,
                                })
                              }
                              type="radio"
                            />
                            <label for="Postal"> Postal </label>
                            <input
                              id="Telecome"
                              maxlength="20"
                              name="Contact_information"
                              value="Telecome"
                              onChange={(e) =>
                                this.setState({
                                  contactInformation: e.target.value,
                                })
                              }
                              type="radio"
                            />
                            <label for="Telecome"> Telecome </label>
                            <input
                              id="Other"
                              name="Contact_information"
                              value="Other"
                              onChange={(e) =>
                                this.setState({
                                  contactInformation: e.target.value,
                                })
                              }
                              type="radio"
                            />
                            <label for="Other"> Other </label>
                            <br></br>
                          </td>
                        </tr>
                        {/* <tr>
                          <td class="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              Party Id
                            </label>
                          </td>
                          <td>
                            <input
                              id="partyId"
                              class="required"
                              maxlength="255"
                              name="partyId"
                              disabled={this.state.isDisabled}
                              value={this.state.partyId}
                              onChange={(e) =>
                                this.setState({ partyId: e.target.value })
                              }
                              // onChange={(e) =>
                              //   console.log(e.target.name, e.target.value)
                              // }
                              size="30"
                              type="text"
                            />
                          </td>
                        </tr> */}
                        <tr>
                          <td class="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              Email Address
                            </label>
                            {}*
                          </td>
                          <td>
                            <input
                              id="userLoginId"
                              class="required"
                              maxlength="255"
                              name="userLoginId"
                              value={this.state.userLoginId}
                              onChange={(e) =>
                                this.setState({ userLoginId: e.target.value })
                              }
                              // onChange={(e) =>
                              //   console.log(e.target.name, e.target.value)
                              // }
                              size="30"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              Last Name
                            </label>
                            {}*
                          </td>
                          <td>
                            <input
                              id="lastName"
                              class="required"
                              maxlength="255"
                              name="lastName"
                              value={this.state.lastName}
                              onChange={(e) =>
                                this.setState({ lastName: e.target.value })
                              }
                              // onChange={(e) =>
                              //   console.log(e.target.name, e.target.value)
                              // }
                              size="30"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              First Name
                            </label>
                            {}*
                          </td>
                          <td>
                            <input
                              id="firstName"
                              class="required"
                              maxlength="255"
                              name="firstName"
                              value={this.state.firstName}
                              onChange={(e) =>
                                this.setState({ firstName: e.target.value })
                              }
                              // onChange={(e) =>
                              //   console.log(e.target.name, e.target.value)
                              // }
                              size="30"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              Group Name
                            </label>
                          </td>
                          <td>
                            <input
                              id="groupName"
                              maxlength="255"
                              name="groupName"
                              value={this.state.groupName}
                              onChange={(e) =>
                                this.setState({ groupName: e.target.value })
                              }
                              // onChange={(e) =>
                              //   console.log(e.target.name, e.target.value)
                              // }
                              size="30"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              Role Type Id
                            </label>
                            {}*
                          </td>
                          <td>
                            <select
                              name="roleTypeId"
                              size="1"
                              class="required"
                              value={this.state.roleTypeId}
                              onChange={(e) =>
                                this.setState({ roleTypeId: e.target.value })
                              }
                              // onchange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')"
                            >
                              <option value=""></option>
                              <option value="Account">Account</option>
                              <option value="Accountant">Accountant</option>
                              <option value="Account_Lead">Account lead</option>
                              <option value="">Any Role Type</option>

                              <option value="ADDRESSEE">Addressee</option>
                              <option value="ADMIN">Administrator</option>
                              <option value="AFFILIATE">Affiliate</option>
                              <option value="AGENT">Agent</option>
                              <option value="APPROVER">Approver</option>
                              <option value="ASSOCIATION">Association</option>
                              <option value="AUTOMATED_AGENT_ROLE">
                                Automated Agent
                              </option>
                              <option value="BILL_FROM_VENDOR">
                                Bill-From-Vendor
                              </option>
                              <option value="BILL_TO_CUSTOMER">
                                Bill-To Customer
                              </option>
                              <option value="BCC">Blind Copy</option>
                              <option value="BRANCH">Branch</option>
                              <option value="BULK_CUSTOMER">
                                Bulk Customer
                              </option>
                              <option value="BUYER">Buyer</option>
                              <option value="CALENDAR_ROLE">Calendar</option>
                              <option value="CAL_ATTENDEE">
                                Calendar Attendee
                              </option>
                              <option value="CAL_DELEGATE">
                                Calendar Delegate
                              </option>
                              <option value="CAL_HOST">Calendar Host</option>
                              <option value="ICAL_MEMBER">
                                Calendar Member
                              </option>
                              <option value="CAL_ORGANIZER">
                                Calendar Organizer
                              </option>
                              <option value="CAL_OWNER">Calendar Owner</option>
                              <option value="CC">Carbon Copy</option>
                              <option value="CARRIER">Carrier</option>
                              <option value="CASHIER">Cashier</option>
                              <option value="Agent">CC Agent</option>
                              <option value="CLIENT">Client</option>
                              <option value="CLIENT_ANALYST">
                                Client Analyst
                              </option>
                              <option value="CLIENT_BILLING">
                                Client Billing
                              </option>
                              <option value="CLIENT_MANAGER">
                                Client Manager
                              </option>
                              <option value="COMMENTATOR">Commentator</option>
                              <option value="COMMEVENT_ROLE">
                                Communication Participant
                              </option>
                              <option value="COMPETITOR">Competitor</option>
                              <option value="CONSUMER">Consumer</option>
                              <option value="CONTACT">Contact</option>
                              <option value="CONTENT_ADMIN">
                                Content Administrator
                              </option>
                              <option value="CONTENT_AUTHOR">
                                Content Author
                              </option>
                              <option value="CONTENT_EDITOR">
                                Content Editor
                              </option>
                              <option value="CONTENT_GUEST">
                                Content Guest
                              </option>
                              <option value="CONTENT">Content Member</option>
                              <option value="CONTENT_PUBLISHER">
                                Content Publisher
                              </option>
                              <option value="CONTENT_USER">Content User</option>
                              <option value="CONTRACTOR">Contractor</option>
                              <option value="CORPORATE_USER">
                                Corporate User
                              </option>
                              <option value="CUSTOMER">Customer</option>
                              <option value="DELIVERY_BOY">Delivery Boy</option>
                              <option value="DEPARTMENT">Department</option>
                              <option value="DISTRIBUTION_CHANNEL">
                                Distribution Channel
                              </option>
                              <option value="DISTRIBUTOR">Distributor</option>
                              <option value="DIVISION">Division</option>
                              <option value="EMAIL_ADMIN">
                                Email Administrator
                              </option>
                              <option value="EMPLOYEE">Employee</option>
                              <option value="END_USER_CUSTOMER">
                                End-User Customer
                              </option>
                              <option value="EBAY_ACCOUNT">eBay Account</option>
                              <option value="FAMILY_MEMBER">
                                Family Member
                              </option>
                              <option value="FAM_ASSIGNEE">
                                Fixed Asset Maint Assignee
                              </option>
                              <option value="HOSTING_SERVER">
                                Hosting Server
                              </option>
                              <option value="HOUSEHOLD">Household</option>
                              <option value="IMAGEAPPROVER">
                                Image Approver
                              </option>
                              <option value="INTERNAL_ORGANIZATIO">
                                Internal Organization
                              </option>
                              <option value="ISP">ISP</option>
                              <option value="LEAD">Lead</option>
                              <option value="LTD_ADMIN">
                                Limited Administrator
                              </option>
                              <option value="LOGGEDIN">Logged In</option>
                              <option value="MAIN_ROLE">Main Role</option>
                              <option value="FAM_MANAGER">
                                Maintenance Manager or Supervisor
                              </option>
                              <option value="FAM_SUPPLIER">
                                Maintenance Supplier or Service
                              </option>
                              <option value="FAM_WORKER">
                                Maintenance Worker
                              </option>
                              <option value="MANAGER">Manager</option>
                              <option value="MANUFACTURER">Manufacturer</option>
                              <option value="_NA_">Not Applicable</option>
                              <option value="ORDER_CLERK">Order Clerk</option>
                              <option value="ORGANIZATION_ROLE">
                                Organization
                              </option>
                              <option value="ORGANIZATION_UNIT">
                                Organization Unit
                              </option>
                              <option value="ORIGINATOR">Originator</option>
                              <option value="OTHER_INTERNAL_ORGAN">
                                Other Internal
                              </option>
                              <option value="OTHER_ORGANIZATION_U">
                                Other Organization Unit
                              </option>
                              <option value="OWNER">Owner</option>
                              <option value="PACKER">Packer</option>
                              <option value="PARENT_ORGANIZATION">
                                Parent Organization
                              </option>
                              <option value="PARTNER">Partner</option>
                              <option value="PERSON_ROLE">Person</option>
                              <option value="PICKER">Picker</option>
                              <option value="PLACING_CUSTOMER">
                                Placing Customer
                              </option>
                              <option value="PRODUCT_OWNER">
                                Product Owner
                              </option>
                              <option value="PRODUCT_OWNER_COMP">
                                Product Owner Company
                              </option>
                              <option value="PROJECT_TEAM">Project Team</option>
                              <option value="PROSPECT">Prospect</option>
                              <option value="PROVIDER_ACCOUNTING">
                                Provider Accounting
                              </option>
                              <option value="PROVIDER_ANALYST">
                                Provider Analyst
                              </option>
                              <option value="PROVIDER_FUNCTIONAL">
                                Provider Functional
                              </option>
                              <option value="PROVIDER_MANAGER">
                                Provider Manager
                              </option>
                              <option value="PROVIDER_TESTER">
                                Provider Tester
                              </option>
                              <option value="PROVIDER_VALIDATOR">
                                Provider Validator
                              </option>
                              <option value="RECEIVER">Receiver</option>
                              <option value="REFERRER">Referrer</option>
                              <option value="REGULATORY_AGENCY">
                                Regulatory Agency
                              </option>
                              <option value="REQ_MANAGER">
                                Request Manager
                              </option>
                              <option value="REQ_RESPOND">
                                Request Respondent
                              </option>
                              <option value="REQUEST_ROLE">Request Role</option>
                              <option value="REQ_TAKER">Request Taker</option>
                              <option value="REQ_REQUESTER">
                                Requesting Party
                              </option>
                              <option value="REVIEWER">Reviewer</option>
                              <option value="SFA_ROLE">
                                Sales Force Autm.
                              </option>
                              <option value="SALES_REP">
                                Sales Representative
                              </option>
                              <option value="SCRUM_MASTER">SCRUM Master</option>
                              <option value="SCRUM_TEAM">SCRUM Team</option>
                              <option value="SCRUM_MEMBER">Scrum Member</option>
                              <option value="SHAREHOLDER">Shareholder</option>
                              <option value="SHIP_FROM_VENDOR">
                                Ship-From Vendor
                              </option>
                              <option value="SHIP_TO_CUSTOMER">
                                Ship-To Customer
                              </option>
                              <option value="SHIPMENT_CLERK">
                                Shipment Clerk
                              </option>
                              <option value="SPONSOR">Sponsor</option>
                              <option value="SPOUSE">Spouse</option>
                              <option value="STAKEHOLDER">Stakeholder</option>
                              <option value="STOCKER">Stocker</option>
                              <option value="SUBSCRIBER">Subscriber</option>
                              <option value="SUBSIDIARY">Subsidiary</option>
                              <option value="SUPPLIER">Supplier</option>
                              <option value="SUPPLIER_AGENT">
                                Supplier Agent
                              </option>
                              <option value="TAX_AUTHORITY">
                                Tax Authority
                              </option>
                              <option value="UNION">Union</option>
                              <option value="VENDOR">Vendor</option>
                              <option value="VISITOR">Visitor</option>
                              <option value="WEB_MASTER">Web Master</option>
                              <option value="WORKER">Worker</option>
                              <option value="WORKFLOW_ROLE">Workflow</option>
                              <option value="WF_OWNER">Workflow Owner</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              Type
                            </label>
                            {}*
                          </td>
                          <td>
                            <select
                              name="type"
                              size="1"
                              class="required"
                              value={this.state.type}
                              // onFocus={this.setState({type:""})}
                              onChange={(e) =>
                                this.setState({ type: e.target.value })
                              }
                              // onchange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')"
                            >
                              <option value=""></option>
                              <option value="Automated_Agent">
                                Automated agent
                              </option>
                              <option value="Corporation">Corporation</option>
                              <option value="Family">Family</option>
                              <option value="GovernMentAgency">
                                Government Agency
                              </option>
                              <option value="InformalGroup">
                                Informal Group
                              </option>
                              <option value="LegalOrgnization">
                                Legal Orgnization
                              </option>
                              <option value="PartyGroup">Party Group</option>
                              <option value="Person">Person</option>
                              <option value="team">Team</option>
                            </select>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="_G59_" class={"fieldgroup " + _G59_}>
                  <div class="fieldgroup-title-bar">
                    <ul>
                      <li class={_G59_}>
                        <a onClick={(e) => this.toggleA(e, "_G59_")} href>
                          {" "}
                          Mailing/Shipping Address
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div id="_G59__body" class="fieldgroup-body">
                    <table class="basic-table" cellspacing="0">
                      <tbody>
                        <tr>
                          <td class="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              Address1
                            </label>
                          </td>
                          <td>
                            <input
                              id="shippingAddress1"
                              maxlength="255"
                              name="shippingAddress1"
                              value={this.state.shippingAddress1}
                              onChange={(e) => this.handleChange(e)}
                              // onChange={(e) =>
                              //   console.log(e.target.name, e.target.value)
                              // }
                              size="30"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              Address2
                            </label>
                          </td>
                          <td>
                            <input
                              id="shippingAddress2"
                              maxlength="255"
                              name="shippingAddress2"
                              value={this.state.shippingAddress2}
                              onChange={(e) => this.handleChange(e)}
                              // onChange={(e) =>
                              //   console.log(e.target.name, e.target.value)
                              // }
                              size="30"
                              type="text"
                            />
                          </td>
                        </tr>
                        {/*<tr>*/}
                        {/*  <td class="label">*/}
                        {/*    <label*/}
                        {/*      id="EditProduct_internalName_title"*/}
                        {/*      for="EditProduct_internalName"*/}
                        {/*    >*/}
                        {/*      City*/}
                        {/*    </label>*/}
                        {/*  </td>*/}
                        {/*  <td>*/}
                        {/*    <input*/}
                        {/*      id="shippingCity"*/}
                        {/*      class="required"*/}
                        {/*      maxlength="255"*/}
                        {/*      name="shippingCity"*/}
                        {/*      value={this.state.shippingCity}*/}
                        {/*      onChange={(e) => this.handleChange(e)}*/}
                        {/*      // onChange={(e) =>*/}
                        {/*      //   console.log(e.target.name, e.target.value)*/}
                        {/*      // }*/}
                        {/*      size="30"*/}
                        {/*      type="text"*/}
                        {/*    />*/}
                        {/*  </td>*/}
                        {/*</tr>*/}
                        <tr>
                          <td class="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              Country
                            </label>
                            {}*
                          </td>
                          <td>
                            <select
                              name="country"
                              size="1"
                              class="required"
                              value={this.state.country}
                              onChange={(e) => {
                                if (e.target.value !== "") {
                                  this.setState({ country: e.target.value });
                                  this.getCities(e);
                                } else {
                                  this.setState({
                                    country: e.target.value,
                                    shippingStateProvinceGeoId: "",
                                    cities: [],
                                  });
                                }
                              }}
                              // onchange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')"
                            >
                              <option value=""></option>
                              {countries &&
                                countries.map((country) => {
                                  return (
                                    <option value={country._id}>
                                      {country.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              State Province Geo Id
                            </label>
                            {}*
                          </td>
                          <td>
                            <select
                              name="shippingStateProvinceGeoId"
                              size="1"
                              class="required"
                              value={this.state.shippingStateProvinceGeoId}
                              onChange={(e) => this.handleChange(e)}
                              // onchange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')"
                            >
                              <option value=""></option>
                              {this.state.cities &&
                                this.state?.cities.map((city) => {
                                  return (
                                    <option value={city._id}>
                                      {city.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              Postal Code
                            </label>
                          </td>
                          <td>
                            <input
                              id="shippingPostalCode"
                              maxlength="255"
                              name="shippingPostalCode"
                              value={this.state.shippingPostalCode}
                              onChange={(e) => this.handleChange(e)}
                              // onChange={(e) =>
                              //   console.log(e.target.name, e.target.value)
                              // }
                              size="30"
                              type="text"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="_G49_" class={"fieldgroup " + _G49_}>
                  <div class="fieldgroup-title-bar">
                    <ul>
                      <li className={_G49_}>
                        <a onClick={(e) => this.toggleA(e, "_G49_")} href>
                          {" "}
                          Identification Number
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div id="_G49__body" class="fieldgroup-body">
                    <table class="basic-table" cellspacing="0">
                      <tbody>
                        <tr>
                          <td class="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              Id Value
                            </label>
                          </td>
                          <td>
                            <input
                              id="idValue"
                              class="required"
                              maxlength="255"
                              name="idValuje"
                              value={this.state.idValue}
                              onChange={(e) => this.handleChange(e)}
                              // onChange={(e) =>
                              //   console.log(e.target.name, e.target.value)
                              // }
                              size="30"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              Identification Type
                            </label>
                          </td>
                          <td>
                            <select
                              name="identificationType"
                              size="1"
                              onchange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')"
                            >
                              <option value=""></option>
                              <option value="Auto_login">
                                Alternate authentication token for vendor auto
                                login
                              </option>
                              <option value="Replacement">
                                Replacement value for partyId in the invoice
                                export function in accounting
                              </option>
                              <option value="SVN_login">SVN login name</option>
                            </select>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="_G51_" class={"fieldgroup " + _G51_}>
                  <div class="fieldgroup-title-bar">
                    <ul>
                      <li class={_G51_}>
                        <a onClick={(e) => this.toggleA(e, "_G51_")} href>
                          {" "}
                          Postal
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div id="_G51__body" class="fieldgroup-body">
                    <table class="basic-table" cellspacing="0">
                      <tbody>
                        <tr>
                          <td class="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              Address1
                            </label>
                          </td>
                          <td>
                            <input
                              id="address1"
                              maxlength="255"
                              name="address1"
                              value={this.state.address1}
                              onChange={(e) => this.handleChange(e)}
                              // onChange={(e) =>
                              //   console.log(e.target.name, e.target.value)
                              // }
                              size="30"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              Address2
                            </label>
                          </td>
                          <td>
                            <input
                              id="address2"
                              maxlength="255"
                              name="address2"
                              value={this.state.address2}
                              onChange={(e) => this.handleChange(e)}
                              // onChange={(e) =>
                              //   console.log(e.target.name, e.target.value)
                              // }
                              size="30"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              City
                            </label>
                          </td>
                          <td>
                            <input
                              id="city"
                              class="required"
                              maxlength="255"
                              name="city"
                              value={this.state.city}
                              onChange={(e) => this.handleChange(e)}
                              // onChange={(e) =>
                              //   console.log(e.target.name, e.target.value)
                              // }
                              size="30"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              State Province Geo Id
                            </label>
                          </td>
                          <td>
                            <select
                              name="stateProvinceGeoId"
                              size="1"
                              onChange={(e) => this.handleChange(e)}
                              // onchange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')"
                            >
                              <option value=""></option>
                              <option value="Alabama">Alabama</option>
                              <option value="Alaska">Alaska</option>
                              <option value="Alberta">Alberta</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              Postal Code
                            </label>
                          </td>
                          <td>
                            <input
                              id="postalCode"
                              class="required"
                              maxlength="255"
                              name="postalCode"
                              value={this.state.postalCode}
                              onChange={(e) => this.handleChange(e)}
                              // onChange={(e) =>
                              //   console.log(e.target.name, e.target.value)
                              // }
                              size="30"
                              type="text"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="_G53_" class={"fieldgroup " + _G53_}>
                  <div class="fieldgroup-title-bar">
                    <ul>
                      <li className={_G53_}>
                        <a onClick={(e) => this.toggleA(e, "_G53_")} href>
                          {" "}
                          Telecome
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div id="_G53__body" class="fieldgroup-body">
                    <table class="basic-table" cellspacing="0">
                      <tbody>
                        <tr>
                          <td class="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              Country Code
                            </label>
                          </td>
                          <td>
                            <input
                              id="countryCode"
                              class="required"
                              maxlength="255"
                              name="countryCode"
                              value={this.state.countryCode}
                              onChange={(e) => this.handleChange(e)}
                              // onChange={(e) =>
                              //   console.log(e.target.name, e.target.value)
                              // }
                              size="30"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              Area Code
                            </label>
                          </td>
                          <td>
                            <input
                              id="areaCode"
                              class="required"
                              maxlength="255"
                              name="areaCode"
                              value={this.state.areaCode}
                              onChange={(e) => this.handleChange(e)}
                              // onChange={(e) =>
                              //   console.log(e.target.name, e.target.value)
                              // }
                              size="30"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              Contact Number
                            </label>
                          </td>
                          <td>
                            <input
                              id="contactNumber"
                              class="required"
                              maxlength="255"
                              name="contactNumber"
                              value={this.state.contactNumber}
                              onChange={(e) => this.handleChange(e)}
                              // onChange={(e) =>
                              //   console.log(e.target.name, e.target.value)
                              // }
                              size="30"
                              type="text"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="_G55_" class={"fieldgroup " + _G55_}>
                  <div class="fieldgroup-title-bar">
                    <ul>
                      <li class={_G55_}>
                        <a onClick={(e) => this.toggleA(e, "_G55_")} href>
                          {" "}
                          Other Contact Information
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div id="_G55__body" class="fieldgroup-body">
                    <table class="basic-table" cellspacing="0">
                      <tbody>
                        <tr>
                          <td class="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              Contact Information
                            </label>
                          </td>
                          <td>
                            <input
                              id="contactInformation"
                              class="required"
                              maxlength="255"
                              name="contactInformation"
                              value={this.state.contactInformation}
                              onChange={(e) => this.handleChange(e)}
                              // onChange={(e) =>
                              //   console.log(e.target.name, e.target.value)
                              // }
                              size="30"
                              type="text"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="_G57_" class={"fieldgroup " + _G57_}>
                  <div class="fieldgroup-title-bar">
                    <ul>
                      <li class={_G57_}>
                        <a onClick={(e) => this.toggleA(e, "_G57_")} href>
                          {" "}
                          Product
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div id="_G57__body" class="fieldgroup-body">
                    <table class="basic-table" cellspacing="0">
                      <tbody>
                        <tr>
                          <td class="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              Inventory Item Id
                            </label>
                          </td>
                          <td>
                            <input
                              id="inventoryItemId"
                              class="required"
                              maxlength="255"
                              name="inventoryItemId"
                              value={this.state.inventoryItemId}
                              onChange={(e) => this.handleChange(e)}
                              // onChange={(e) =>
                              //   console.log(e.target.name, e.target.value)
                              // }
                              size="30"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              Serial Number
                            </label>
                          </td>
                          <td>
                            <input
                              id="serialNumber"
                              class="required"
                              maxlength="255"
                              name="serialNumber"
                              value={this.state.serialNumber}
                              onChange={(e) => this.handleChange(e)}
                              // onChange={(e) =>
                              //   console.log(e.target.name, e.target.value)
                              // }
                              size="30"
                              type="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td class="label">
                            <label
                              id="EditProduct_internalName_title"
                              for="EditProduct_internalName"
                            >
                              Soft Identifier
                            </label>
                          </td>
                          <td>
                            <input
                              id="softIdentifier"
                              class="required"
                              maxlength="255"
                              name="softIdentifier"
                              value={this.state.softIdentifier}
                              onChange={(e) => this.handleChange(e)}
                              // onChange={(e) =>
                              //   console.log(e.target.name, e.target.value)
                              // }
                              size="30"
                              type="text"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <table class="basic-table" cellspacing="0">
                  <tbody>
                    <tr>
                      <td class="label"> </td>
                      <td colspan="10">
                        <input
                          class="smallSubmit"
                          name="submitButton"
                          onClick={(e) => this.handleSubmit(e)}
                          type="submit"
                          value="Search"
                          // disabled={submitted}
                        />
                        <input
                          type="hidden"
                          disabled="true"
                          name="disabledButton"
                        />
                        <input
                          class="smallSubmit"
                          name="submitButton"
                          onClick={(e) => this.handleSubmit(e)}
                          type="submit"
                          value={this.state.partyId ? "Update" : "Create"}
                          // disabled={submitted}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
          {
            //mode === "edit" && <DuplicateProduct product={product} />
          }
        </div>
        <DataTable>
          <table class="basic-table hover-bar" cellspacing="0">
            <tbody>
              <tr class="header-row-2">
                <td>
                  <a
                    class="sort-order"
                    href="/catalog/control/FindProduct?sortField=productId&amp;noConditionFind=N"
                  >
                    Party Id
                  </a>
                </td>
                <td>
                  <Link
                    class="sort-order"
                    to="/catalog/control/FindProduct?sortField=idValue&amp;noConditionFind=N"
                  >
                    User Login Id
                  </Link>
                </td>
                <td>
                  <Link
                    class="sort-order"
                    to="/catalog/control/FindProduct?sortField=productTypeId&amp;noConditionFind=N"
                  >
                    Name
                  </Link>
                </td>
                <td>
                  <Link
                    class="sort-order"
                    to="/catalog/control/FindProduct?sortField=internalName&amp;noConditionFind=N"
                  >
                    Type
                  </Link>
                </td>
                <td>
                  <Link
                    class="sort-order"
                    to="/catalog/control/FindProduct?sortField=brandName&amp;noConditionFind=N"
                  >
                    Main Role
                  </Link>
                </td>
                <td>
                  <Link
                    class="sort-order"
                    to="/catalog/control/FindProduct?sortField=productName&amp;noConditionFind=N"
                  >
                    Created Date
                  </Link>
                </td>
                <td>
                  <Link
                    class="sort-order"
                    to="/catalog/control/FindProduct?sortField=description&amp;noConditionFind=N"
                  >
                    Last Modified Date
                  </Link>
                </td>
              </tr>
              {parties &&
                parties.map((party) => {
                  return (
                    <tr>
                      <Link
                        to={"/partymgr/control/main?id=" + party._id}
                        title={party._id}
                      >
                        {party._id}
                      </Link>
                      <td>{party.userLoginId}</td>
                      <td>{party.name}</td>
                      <td>{party.type}</td>
                      <td>{party.mainRole}</td>
                      <td>{party.createdDate}</td>
                      <td>{party.lastModifiedDate}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </DataTable>
      </div>
    );
  }
  constructor(props) {
    super(props);
    this.state = {
      _G49_: "collapsed",
      _G51_: "collapsed",
      _G53_: "collapsed",
      _G55_: "collapsed",
      _G57_: "collapsed",
      _G59_: "expanded",

      contactInformationType: "",
      partyId: "",
      userLoginId: "",
      lastName: "",
      firstName: "",
      groupName: "",
      roleTypeId: "",
      type: "",
      idValue: "",
      identificationType: "",
      shippingAddress1: "",
      shippingAddress2: "",
      shippingCity: "",
      shippingStateProvinceGeoId: "",
      shippingPostalCode: "",
      country: "",
      address1: "",
      address2: "",
      city: "",
      stateProvinceGeoId: "",
      postalCode: "",
      countryCode: "",
      areaCode: "",
      contactNumber: "",
      contactInformation: "",
      inventoryItemId: "",
      serialNumber: "",
      softIdentifier: "",
      cities: [],
    };
  }
  componentDidUpdate(nextProps) {
    if (nextProps.cities !== this.props.cities) {
      this.setState({ cities: this.props.cities });
    }
  }
  async componentDidMount() {
    this.props.allActive();
    this.props.allActiveCountries();
    const countries = Array.from(this.props.countries);
    // if (countries) {
    //   await this.setState({ country: countries[0]?._id });
    // }
    if (this.props.location.search) {
      const id = this.props.location.search.split("=")[1];
      const parties = Array.from(this.props.parties);
      var party = parties.filter((party) => {
        return party._id == id;
      });
      if (party.length > 0) {
        this.props.getCities(party[0].country);
        const {
          _id,
          userLoginId,
          name,
          type,
          mainRole,
          shippingAddress1,
          shippingAddress2,
          groupName,
          shippingCity,
          shippingStateProvinceGeoId,
          shippingPostalCode,
          country,
        } = party[0];
        var newName = ["", ""];
        if (name) {
          newName = name.split(",");
        }

        await this.setState({
          partyId: _id,
          shippingAddress1: shippingAddress1,
          shippingAddress2: shippingAddress2,
          shippingCity: shippingCity,
          shippingStateProvinceGeoId: shippingStateProvinceGeoId,
          shippingPostalCode: shippingPostalCode,
          country: country,
          groupName: groupName,
          userLoginId: userLoginId,
          firstName: newName[1],
          lastName: newName[0],
          type: type,
          roleTypeId: mainRole,
        });
      }
    }
    // check query param
    // exists then call function to set state
  }
  handleChange = (e) => {
    var value = e.target.value;
    var key = e.target.name;
    this.setState({ [key]: value });
  };
  getCities = (e) => {
    const countryId = e.target.value;
    this.props.getCities(countryId);
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    const mode = e.target.value;

    const {
      userLoginId,
      lastName,
      firstName,
      roleTypeId,
      type,
      partyId,
      groupName,
      shippingAddress1,
      shippingAddress2,
      shippingCity,
      shippingStateProvinceGeoId,
      shippingPostalCode,
      country,
    } = this.state;

    var data = {};
    data = firstName
      ? { ...data, name: lastName + "," + firstName }
      : { ...data };

    data = type ? { ...data, type } : { ...data };
    data = userLoginId ? { ...data, userLoginId } : { ...data };
    data = roleTypeId ? { ...data, mainRole: roleTypeId } : { ...data };
    data = shippingAddress1 ? { ...data, shippingAddress1 } : { ...data };
    data = shippingAddress2 ? { ...data, shippingAddress2 } : { ...data };
    data = shippingCity ? { ...data, shippingCity } : { ...data };
    data = shippingStateProvinceGeoId
      ? { ...data, shippingStateProvinceGeoId }
      : { ...data };
    data = shippingPostalCode ? { ...data, shippingPostalCode } : { ...data };
    data = country ? { ...data, country } : { ...data };
    data = groupName ? { ...data, groupName } : { ...data };
    const countries = Array.from(this.props.countries);
    if (mode == "Create" || mode == "Update") {
      if (
        (userLoginId.trim() === "" ||
          lastName.trim() === "" ||
          firstName.trim() === "" ||
          roleTypeId === "",
        type === "",
        country === "" || shippingStateProvinceGeoId === "")
      ) {
        Notify("danger", "Please fill all the required fields.", "Required");
        return;
      }
      if (this.state.partyId) {
        const date = new Date();
        data = { ...data, lastModifiedDate: date };
        this.props.update(data, partyId);
        this.props.history.goBack();
        console.log(this.props);

        // await this.setState({
        //   type: "",
        //   userLoginId: "",
        //   firstName: "",
        //   lastName: "",
        //   roleTypeId: "",
        //   shippingAddress1: "",
        //   shippingAddress2: "",
        //   groupName: "",
        //   shippingCity: "",
        //   country: "",
        //   shippingStateProvinceGeoId: "",
        //   shippingPostalCode: "",
        // });
      } else {
        this.props.create(data);
        await this.setState({
          type: "",
          userLoginId: "",
          firstName: "",
          lastName: "",
          roleTypeId: "",
          shippingAddress1: "",
          groupName: "",
          shippingAddress2: "",
          shippingCity: "",
          country: "",
          shippingStateProvinceGeoId: "",
          shippingPostalCode: "",
          cities: [],
        });
      }
    } else {
      data = partyId ? { ...data, _id: partyId } : { ...data };
      this.props.search(data);
    }
  };
  toggleA = (e, key) => {
    e.preventDefault();
    const old = this.state[key];
    switch (key) {
      case "expand":
        this.setAllToggle("expanded");
        break;
      case "collapse":
        this.setAllToggle("collapsed");
        break;
      default:
        this.setState({ [key]: old === "expanded" ? "collapsed" : "expanded" });
        break;
    }
  };
  setAllToggle = (state) => {
    this.setState({
      _G49_: state,
      _G51_: state,
      _G53_: state,
      _G55_: state,
      _G59_: state,
    });
  };
}

const mapStateToProps = (state) => ({
  parties: state.partyManager?.parties?.parties,
  countries: state.partyManager?.countries?.countries,
  cities: state.partyManager?.cities?.cities,
});

const mapDispatchToProps = (dispatch, router) => ({
  create: (data) => dispatch(createParty(data, router)),
  allActive: () => dispatch(allActiveParties()),
  delete: (_id) => dispatch(deleteParty(_id, router)),
  search: (data) => dispatch(searchParty(data, router)),
  update: (data, partyId) => dispatch(updateParty(data, partyId, router)),
  createCountries: (data) => dispatch(createCountries(data, router)),
  allActiveCountries: () => dispatch(allActiveCountries()),
  createCities: (data) => dispatch(createCities(data, router)),
  getCities: (data) => dispatch(getCities(data, router)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
