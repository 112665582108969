import React, { useState, useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Modal } from "@material-ui/core";
function SequenceModal(props) {
  const { presentIndex, dataLength, rowCol } = props;
  const [row, setRow] = useState(null);
  const [col, setCol] = useState(null);
  const [rowLength, setRowLength] = useState();
  const [colLength, setColLength] = useState();
  useEffect(() => {
    setRow(rowCol?.row);
    setCol(rowCol?.col);
  }, [rowCol]);
  useEffect(() => {
    if (dataLength <= 4) {
      setColLength(dataLength);
      setRowLength(1);
    } else {
      setColLength(4);
      let tempRow = dataLength / 4;
      let filterRow = tempRow % 1 === 0 ? tempRow : Math.floor(tempRow) + 1;
      setRowLength(filterRow);
    }
  }, [dataLength]);

  const submit = () => {

    props.onSubmit(presentIndex, row, col);
  };
  return (
    <Modal
      open={props.modalOpen}
      onClose={props.handleClose}
      //   style={{ overflow: "scroll" }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        style={{
          height: "300px",
          width: "300px",
          backgroundColor: "white",
          position: "absolute",
          top: "50px",
          border: "2px solid white",
          left: "600px",
          alignItems: "center",
          display: "flex",
          overflow: "scroll",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            backgroundColor: "#527894",
            borderRadius: "5px",
            width: "100%",
            height: "30px",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <CloseIcon
            onClick={(e) => props.handleClose(e)}
            style={{
              color: "#6caad4",
              marginRight: "5px",
              backgroundColor: "#e3f1fc",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          />
        </div>
        <div style={{ width: "96%", display: "flex", flexDirection: "column" }}>
          <label htmlFor={"from"}>Row No:</label>
          <select
            onChange={(e) => {
              setRow(e.target.value);
              console.log("e", e.target.value);
            }}
          >
            {[...Array(rowLength)]?.map((item, index) => (
              <option
                value={index + 1}
                key={index}
                selected={rowCol?.row === index + 1}
              >
                {index + 1}
              </option>
            ))}
          </select>
        </div>
        <div style={{ width: "96%", display: "flex", flexDirection: "column" }}>
          <label htmlFor={"from"}>Col No:</label>
          <select
            onChange={(e) => {
              setCol(e.target.value);
            }}
          >
            {[...Array(colLength)]?.map((item, index) => (
              <option
                value={index + 1}
                key={index}
                selected={rowCol?.col === index + 1}
              >
                {index + 1}
              </option>
            ))}
          </select>
        </div>
        <div
          style={{
            width: "96%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            marginTop: "10px",
          }}
        >
          <button type={"button"} onClick={() => submit()}>
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default SequenceModal;
