import React, { Component } from "react";
import { connect } from "react-redux";
import { putRequest } from "../../../store/services";
import { Link } from "react-router-dom";

export class FindOrders extends Component {
  constructor() {
    super();
    this.state = {
      foundOrders: [],
      odrderId: "",
      senderEmail: "",
      senderPhone: "",
      receiverEmail: "",
      receiverPhone: "",
      from: "",
      to: "",
      productId: "",
      subOrderId: "",
    };
    this.handleChang = this.handleChang.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChang(event) {
    const fieldName = event.target.name;
    this.setState({ [fieldName]: event.target.value });
  }
  async handleSubmit(e) {
    e.preventDefault();
    const {
      orderId,
      senderEmail,
      senderPhone,
      from,
      to,
      productId,
      receiverEmail,
      receiverPhone,
      subOrderId,
    } = this.state;
    let data = {};
    data = orderId ? { ...data, _id: orderId } : data;
    data = senderEmail ? { ...data, senderEmail: senderEmail } : data;
    data = senderPhone ? { ...data, senderPhone: senderPhone } : data;
    data = from ? { ...data, from: from } : data;
    data = to ? { ...data, to: to } : data;
    data = productId ? { ...data, productId: productId } : data;
    data = receiverEmail ? { ...data, receiverEmail } : data;
    data = receiverPhone ? { ...data, receiverPhone } : data;
    data = subOrderId ? { ...data, subOrderId } : data;
    const response = await putRequest(
      `orders/search`,
      data,
      false,
      { headers: { "access-token": this.props.token } },
      true
    );
    console.log("this response", response.data);
    this.setState({ foundOrders: response.data });
  }
  render() {
    const {
      orderId,
      foundOrders,
      senderEmail,
      senderPhone,
      from,
      to,
    } = this.state;
    return (
      <div id="content-main-section" class="leftonly">
        <div id="centerdiv" class="no-clear">
          <form id="lookuporder" method="post" name="lookuporder">
            <div id="findOrders" class="screenlet">
              <div class="screenlet-title-bar">
                <ul>
                  <li class="h3">Find Orders</li>
                  <li>
                    <a href="/partymgr/control/findparty?externalLoginKey=EL776180376190">
                      Lookup Party
                    </a>
                  </li>
                  <li>
                    <a href>Lookup Order(s)</a>
                  </li>
                </ul>
              </div>
              <div class="screenlet-body">
                <table class="basic-table" cellspacing="0">
                  <tbody>
                    <tr>
                      <td align="center" width="100%">
                        <table class="basic-table" cellspacing="0">
                          <tbody>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Order Id
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <input
                                  name="orderId"
                                  value={orderId}
                                  onChange={this.handleChang}
                                  type="text"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Sub Order Id
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <input
                                  name="subOrderId"
                                  type="text"
                                  value={this.state.subOrderId}
                                  onChange={this.handleChang}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Sender Email
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <input
                                  name="senderEmail"
                                  vlaue={senderEmail}
                                  onChange={this.handleChang}
                                  type="text"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Sender Phone
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <input
                                  name="senderPhone"
                                  value={senderPhone}
                                  onChange={this.handleChang}
                                  type="text"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                ReceiverEmail
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <input
                                  name="receiverEmail"
                                  type="text"
                                  value={this.state.receiverEmail}
                                  onChange={(e) =>
                                    this.setState({
                                      receiverEmail: e.target.value,
                                    })
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Receiver Phone
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <input
                                  name="receiverPhone"
                                  type="text"
                                  value={this.state.receiverPhone}
                                  onChange={(e) =>
                                    this.setState({
                                      receiverPhone: e.target.value,
                                    })
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Tracking Number
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <input
                                  name="trackingNumber"
                                  type="text"
                                  value=""
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Gateway Transaction Id
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <input
                                  name="paymentRefNum"
                                  type="text"
                                  value=""
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Payment Transaction Id
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <input
                                  name="orderPaymentPreferenceId"
                                  type="text"
                                  value=""
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Order Name
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <input name="orderName" type="text" />
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                External ID
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <input name="externalId" type="text" />
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Customer PO#
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <input
                                  name="correspondingPoId"
                                  type="text"
                                  value=""
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Internal Code
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <input
                                  name="internalCode"
                                  type="text"
                                  value=""
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Product ID
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <input
                                  name="productId"
                                  type="text"
                                  value={this.state.productId}
                                  onChange={(e) =>
                                    this.setState({ productId: e.target.value })
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Good Identification Type
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <select name="goodIdentificationTypeId">
                                  <option value="">
                                    Any good identification
                                  </option>
                                  <option value="EAN">EAN</option>
                                  <option value="GOOGLE_ID">Google Id</option>
                                  <option value="GOOGLE_ID_de">
                                    Google Id de
                                  </option>
                                  <option value="GOOGLE_ID_en_GB">
                                    Google Id en_GB
                                  </option>
                                  <option value="GOOGLE_ID_en_US">
                                    Google Id en_US
                                  </option>
                                  <option value="HS_CODE">
                                    Harmonized System Codes (HS Code)
                                  </option>
                                  <option value="INVOICE_EXPORT">
                                    replacement value for partyId in the invoice
                                    export function in accounting
                                  </option>
                                  <option value="ISBN">ISBN</option>
                                  <option value="LOC">
                                    Library of Congress
                                  </option>
                                  <option value="MANUFACTURER_ID_NO">
                                    Manufacturer (Model) Number
                                  </option>
                                  <option value="MODEL_YEAR">Model Year</option>
                                  <option value="OTHER_ID">Other</option>
                                  <option value="SKU">SKU</option>
                                  <option value="UPCA">UPCA</option>
                                  <option value="UPCE">UPCE</option>
                                </select>
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Good Identification
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <input
                                  name="goodIdentificationIdValue"
                                  type="text"
                                  value=""
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Inventory Item Id
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <input
                                  name="inventoryItemId"
                                  type="text"
                                  value=""
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Serial Number
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <input
                                  name="serialNumber"
                                  type="text"
                                  value=""
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Soft Identifier
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <input
                                  name="softIdentifier"
                                  type="text"
                                  value=""
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Role Type
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <div id="asmContainer0" class="asmContainer">
                                  <select
                                    id="asmSelect0"
                                    class="asmSelect"
                                    name="asmSelect0"
                                  >
                                    <option selected="selected">
                                      Select Role(s) For Party
                                    </option>
                                    <option value="">Any Role Type</option>
                                    <option value="ACCOUNT">Account</option>
                                    <option value="ACCOUNT_LEAD">
                                      Account Lead
                                    </option>
                                    <option value="ACCOUNTANT">
                                      Accountant
                                    </option>
                                    <option value="ADDRESSEE">Addressee</option>
                                    <option value="ADMIN">Administrator</option>
                                    <option value="AFFILIATE">Affiliate</option>
                                    <option value="AGENT">Agent</option>
                                    <option value="APPROVER">Approver</option>
                                    <option value="ASSOCIATION">
                                      Association
                                    </option>
                                    <option value="AUTOMATED_AGENT_ROLE">
                                      Automated Agent
                                    </option>
                                    <option value="BILL_FROM_VENDOR">
                                      Bill-From-Vendor
                                    </option>
                                    <option value="BILL_TO_CUSTOMER">
                                      Bill-To Customer
                                    </option>
                                    <option value="BCC">Blind Copy</option>
                                    <option value="BRANCH">Branch</option>
                                    <option value="BULK_CUSTOMER">
                                      Bulk Customer
                                    </option>
                                    <option value="BUYER">Buyer</option>
                                    <option value="CALENDAR_ROLE">
                                      Calendar
                                    </option>
                                    <option value="CAL_ATTENDEE">
                                      Calendar Attendee
                                    </option>
                                    <option value="CAL_DELEGATE">
                                      Calendar Delegate
                                    </option>
                                    <option value="CAL_HOST">
                                      Calendar Host
                                    </option>
                                    <option value="ICAL_MEMBER">
                                      Calendar Member
                                    </option>
                                    <option value="CAL_ORGANIZER">
                                      Calendar Organizer
                                    </option>
                                    <option value="CAL_OWNER">
                                      Calendar Owner
                                    </option>
                                    <option value="CC">Carbon Copy</option>
                                    <option value="CARRIER">Carrier</option>
                                    <option value="CASHIER">Cashier</option>
                                    <option value="Agent">CC Agent</option>
                                    <option value="CLIENT">Client</option>
                                    <option value="CLIENT_ANALYST">
                                      Client Analyst
                                    </option>
                                    <option value="CLIENT_BILLING">
                                      Client Billing
                                    </option>
                                    <option value="CLIENT_MANAGER">
                                      Client Manager
                                    </option>
                                    <option value="COMMENTATOR">
                                      Commentator
                                    </option>
                                    <option value="COMMEVENT_ROLE">
                                      Communication Participant
                                    </option>
                                    <option value="COMPETITOR">
                                      Competitor
                                    </option>
                                    <option value="CONSUMER">Consumer</option>
                                    <option value="CONTACT">Contact</option>
                                    <option value="CONTENT_ADMIN">
                                      Content Administrator
                                    </option>
                                    <option value="CONTENT_AUTHOR">
                                      Content Author
                                    </option>
                                    <option value="CONTENT_EDITOR">
                                      Content Editor
                                    </option>
                                    <option value="CONTENT_GUEST">
                                      Content Guest
                                    </option>
                                    <option value="CONTENT">
                                      Content Member
                                    </option>
                                    <option value="CONTENT_PUBLISHER">
                                      Content Publisher
                                    </option>
                                    <option value="CONTENT_USER">
                                      Content User
                                    </option>
                                    <option value="CONTRACTOR">
                                      Contractor
                                    </option>
                                    <option value="CORPORATE_USER">
                                      Corporate User
                                    </option>
                                    <option value="CUSTOMER">Customer</option>
                                    <option value="DELIVERY_BOY">
                                      Delivery Boy
                                    </option>
                                    <option value="DEPARTMENT">
                                      Department
                                    </option>
                                    <option value="DISTRIBUTION_CHANNEL">
                                      Distribution Channel
                                    </option>
                                    <option value="DISTRIBUTOR">
                                      Distributor
                                    </option>
                                    <option value="DIVISION">Division</option>
                                    <option value="EMAIL_ADMIN">
                                      Email Administrator
                                    </option>
                                    <option value="EMPLOYEE">Employee</option>
                                    <option value="END_USER_CUSTOMER">
                                      End-User Customer
                                    </option>
                                    <option value="EBAY_ACCOUNT">
                                      eBay Account
                                    </option>
                                    <option value="FAMILY_MEMBER">
                                      Family Member
                                    </option>
                                    <option value="FAM_ASSIGNEE">
                                      Fixed Asset Maint Assignee
                                    </option>
                                    <option value="HOSTING_SERVER">
                                      Hosting Server
                                    </option>
                                    <option value="HOUSEHOLD">Household</option>
                                    <option value="IMAGEAPPROVER">
                                      Image Approver
                                    </option>
                                    <option value="INTERNAL_ORGANIZATIO">
                                      Internal Organization
                                    </option>
                                    <option value="ISP">ISP</option>
                                    <option value="LEAD">Lead</option>
                                    <option value="LTD_ADMIN">
                                      Limited Administrator
                                    </option>
                                    <option value="LOGGEDIN">Logged In</option>
                                    <option value="MAIN_ROLE">Main Role</option>
                                    <option value="FAM_MANAGER">
                                      Maintenance Manager or Supervisor
                                    </option>
                                    <option value="FAM_SUPPLIER">
                                      Maintenance Supplier or Service
                                    </option>
                                    <option value="FAM_WORKER">
                                      Maintenance Worker
                                    </option>
                                    <option value="MANAGER">Manager</option>
                                    <option value="MANUFACTURER">
                                      Manufacturer
                                    </option>
                                    <option value="_NA_">Not Applicable</option>
                                    <option value="ORDER_CLERK">
                                      Order Clerk
                                    </option>
                                    <option value="ORGANIZATION_ROLE">
                                      Organization
                                    </option>
                                    <option value="ORGANIZATION_UNIT">
                                      Organization Unit
                                    </option>
                                    <option value="ORIGINATOR">
                                      Originator
                                    </option>
                                    <option value="OTHER_INTERNAL_ORGAN">
                                      Other Internal
                                    </option>
                                    <option value="OTHER_ORGANIZATION_U">
                                      Other Organization Unit
                                    </option>
                                    <option value="OWNER">Owner</option>
                                    <option value="PACKER">Packer</option>
                                    <option value="PARENT_ORGANIZATION">
                                      Parent Organization
                                    </option>
                                    <option value="PARTNER">Partner</option>
                                    <option value="PERSON_ROLE">Person</option>
                                    <option value="PICKER">Picker</option>
                                    <option value="PLACING_CUSTOMER">
                                      Placing Customer
                                    </option>
                                    <option value="PRODUCT_OWNER">
                                      Product Owner
                                    </option>
                                    <option value="PRODUCT_OWNER_COMP">
                                      Product Owner Company
                                    </option>
                                    <option value="PROJECT_TEAM">
                                      Project Team
                                    </option>
                                    <option value="PROSPECT">Prospect</option>
                                    <option value="PROVIDER_ACCOUNTING">
                                      Provider Accounting
                                    </option>
                                    <option value="PROVIDER_ANALYST">
                                      Provider Analyst
                                    </option>
                                    <option value="PROVIDER_FUNCTIONAL">
                                      Provider Functional
                                    </option>
                                    <option value="PROVIDER_MANAGER">
                                      Provider Manager
                                    </option>
                                    <option value="PROVIDER_TESTER">
                                      Provider Tester
                                    </option>
                                    <option value="PROVIDER_VALIDATOR">
                                      Provider Validator
                                    </option>
                                    <option value="RECEIVER">Receiver</option>
                                    <option value="REFERRER">Referrer</option>
                                    <option value="REGULATORY_AGENCY">
                                      Regulatory Agency
                                    </option>
                                    <option value="REQ_MANAGER">
                                      Request Manager
                                    </option>
                                    <option value="REQ_RESPOND">
                                      Request Respondent
                                    </option>
                                    <option value="REQUEST_ROLE">
                                      Request Role
                                    </option>
                                    <option value="REQ_TAKER">
                                      Request Taker
                                    </option>
                                    <option value="REQ_REQUESTER">
                                      Requesting Party
                                    </option>
                                    <option value="REVIEWER">Reviewer</option>
                                    <option value="SFA_ROLE">
                                      Sales Force Autm.
                                    </option>
                                    <option value="SALES_REP">
                                      Sales Representative
                                    </option>
                                    <option value="SCRUM_MASTER">
                                      SCRUM Master
                                    </option>
                                    <option value="SCRUM_TEAM">
                                      SCRUM Team
                                    </option>
                                    <option value="SCRUM_MEMBER">
                                      Scrum Member
                                    </option>
                                    <option value="SHAREHOLDER">
                                      Shareholder
                                    </option>
                                    <option value="SHIP_FROM_VENDOR">
                                      Ship-From Vendor
                                    </option>
                                    <option value="SHIP_TO_CUSTOMER">
                                      Ship-To Customer
                                    </option>
                                    <option value="SHIPMENT_CLERK">
                                      Shipment Clerk
                                    </option>
                                    <option value="SPONSOR">Sponsor</option>
                                    <option value="SPOUSE">Spouse</option>
                                    <option value="STAKEHOLDER">
                                      Stakeholder
                                    </option>
                                    <option value="STOCKER">Stocker</option>
                                    <option value="SUBSCRIBER">
                                      Subscriber
                                    </option>
                                    <option value="SUBSIDIARY">
                                      Subsidiary
                                    </option>
                                    <option value="SUPPLIER">Supplier</option>
                                    <option value="SUPPLIER_AGENT">
                                      Supplier Agent
                                    </option>
                                    <option value="TAX_AUTHORITY">
                                      Tax Authority
                                    </option>
                                    <option value="UNION">Union</option>
                                    <option value="VENDOR">Vendor</option>
                                    <option value="VISITOR">Visitor</option>
                                    <option value="WEB_MASTER">
                                      Web Master
                                    </option>
                                    <option value="WORKER">Worker</option>
                                    <option value="WORKFLOW_ROLE">
                                      Workflow
                                    </option>
                                    <option value="WF_OWNER">
                                      Workflow Owner
                                    </option>
                                  </select>
                                  <ol id="asmList0" class="asmList"></ol>
                                  <select
                                    id="roleTypeId"
                                    title="Select Role&amp;#x28;s&amp;#x29; For Party"
                                    multiple="multiple"
                                    name="roleTypeId"
                                  >
                                    <option id="asm0option0" value="">
                                      Any Role Type
                                    </option>
                                    <option id="asm0option1" value="ACCOUNT">
                                      Account
                                    </option>
                                    <option
                                      id="asm0option2"
                                      value="ACCOUNT_LEAD"
                                    >
                                      Account Lead
                                    </option>
                                    <option id="asm0option3" value="ACCOUNTANT">
                                      Accountant
                                    </option>
                                    <option id="asm0option4" value="ADDRESSEE">
                                      Addressee
                                    </option>
                                    <option id="asm0option5" value="ADMIN">
                                      Administrator
                                    </option>
                                    <option id="asm0option6" value="AFFILIATE">
                                      Affiliate
                                    </option>
                                    <option id="asm0option7" value="AGENT">
                                      Agent
                                    </option>
                                    <option id="asm0option8" value="APPROVER">
                                      Approver
                                    </option>
                                    <option
                                      id="asm0option9"
                                      value="ASSOCIATION"
                                    >
                                      Association
                                    </option>
                                    <option
                                      id="asm0option10"
                                      value="AUTOMATED_AGENT_ROLE"
                                    >
                                      Automated Agent
                                    </option>
                                    <option
                                      id="asm0option11"
                                      value="BILL_FROM_VENDOR"
                                    >
                                      Bill-From-Vendor
                                    </option>
                                    <option
                                      id="asm0option12"
                                      value="BILL_TO_CUSTOMER"
                                    >
                                      Bill-To Customer
                                    </option>
                                    <option id="asm0option13" value="BCC">
                                      Blind Copy
                                    </option>
                                    <option id="asm0option14" value="BRANCH">
                                      Branch
                                    </option>
                                    <option
                                      id="asm0option15"
                                      value="BULK_CUSTOMER"
                                    >
                                      Bulk Customer
                                    </option>
                                    <option id="asm0option16" value="BUYER">
                                      Buyer
                                    </option>
                                    <option
                                      id="asm0option17"
                                      value="CALENDAR_ROLE"
                                    >
                                      Calendar
                                    </option>
                                    <option
                                      id="asm0option18"
                                      value="CAL_ATTENDEE"
                                    >
                                      Calendar Attendee
                                    </option>
                                    <option
                                      id="asm0option19"
                                      value="CAL_DELEGATE"
                                    >
                                      Calendar Delegate
                                    </option>
                                    <option id="asm0option20" value="CAL_HOST">
                                      Calendar Host
                                    </option>
                                    <option
                                      id="asm0option21"
                                      value="ICAL_MEMBER"
                                    >
                                      Calendar Member
                                    </option>
                                    <option
                                      id="asm0option22"
                                      value="CAL_ORGANIZER"
                                    >
                                      Calendar Organizer
                                    </option>
                                    <option id="asm0option23" value="CAL_OWNER">
                                      Calendar Owner
                                    </option>
                                    <option id="asm0option24" value="CC">
                                      Carbon Copy
                                    </option>
                                    <option id="asm0option25" value="CARRIER">
                                      Carrier
                                    </option>
                                    <option id="asm0option26" value="CASHIER">
                                      Cashier
                                    </option>
                                    <option id="asm0option27" value="Agent">
                                      CC Agent
                                    </option>
                                    <option id="asm0option28" value="CLIENT">
                                      Client
                                    </option>
                                    <option
                                      id="asm0option29"
                                      value="CLIENT_ANALYST"
                                    >
                                      Client Analyst
                                    </option>
                                    <option
                                      id="asm0option30"
                                      value="CLIENT_BILLING"
                                    >
                                      Client Billing
                                    </option>
                                    <option
                                      id="asm0option31"
                                      value="CLIENT_MANAGER"
                                    >
                                      Client Manager
                                    </option>
                                    <option
                                      id="asm0option32"
                                      value="COMMENTATOR"
                                    >
                                      Commentator
                                    </option>
                                    <option
                                      id="asm0option33"
                                      value="COMMEVENT_ROLE"
                                    >
                                      Communication Participant
                                    </option>
                                    <option
                                      id="asm0option34"
                                      value="COMPETITOR"
                                    >
                                      Competitor
                                    </option>
                                    <option id="asm0option35" value="CONSUMER">
                                      Consumer
                                    </option>
                                    <option id="asm0option36" value="CONTACT">
                                      Contact
                                    </option>
                                    <option
                                      id="asm0option37"
                                      value="CONTENT_ADMIN"
                                    >
                                      Content Administrator
                                    </option>
                                    <option
                                      id="asm0option38"
                                      value="CONTENT_AUTHOR"
                                    >
                                      Content Author
                                    </option>
                                    <option
                                      id="asm0option39"
                                      value="CONTENT_EDITOR"
                                    >
                                      Content Editor
                                    </option>
                                    <option
                                      id="asm0option40"
                                      value="CONTENT_GUEST"
                                    >
                                      Content Guest
                                    </option>
                                    <option id="asm0option41" value="CONTENT">
                                      Content Member
                                    </option>
                                    <option
                                      id="asm0option42"
                                      value="CONTENT_PUBLISHER"
                                    >
                                      Content Publisher
                                    </option>
                                    <option
                                      id="asm0option43"
                                      value="CONTENT_USER"
                                    >
                                      Content User
                                    </option>
                                    <option
                                      id="asm0option44"
                                      value="CONTRACTOR"
                                    >
                                      Contractor
                                    </option>
                                    <option
                                      id="asm0option45"
                                      value="CORPORATE_USER"
                                    >
                                      Corporate User
                                    </option>
                                    <option id="asm0option46" value="CUSTOMER">
                                      Customer
                                    </option>
                                    <option
                                      id="asm0option47"
                                      value="DELIVERY_BOY"
                                    >
                                      Delivery Boy
                                    </option>
                                    <option
                                      id="asm0option48"
                                      value="DEPARTMENT"
                                    >
                                      Department
                                    </option>
                                    <option
                                      id="asm0option49"
                                      value="DISTRIBUTION_CHANNEL"
                                    >
                                      Distribution Channel
                                    </option>
                                    <option
                                      id="asm0option50"
                                      value="DISTRIBUTOR"
                                    >
                                      Distributor
                                    </option>
                                    <option id="asm0option51" value="DIVISION">
                                      Division
                                    </option>
                                    <option
                                      id="asm0option52"
                                      value="EMAIL_ADMIN"
                                    >
                                      Email Administrator
                                    </option>
                                    <option id="asm0option53" value="EMPLOYEE">
                                      Employee
                                    </option>
                                    <option
                                      id="asm0option54"
                                      value="END_USER_CUSTOMER"
                                    >
                                      End-User Customer
                                    </option>
                                    <option
                                      id="asm0option55"
                                      value="EBAY_ACCOUNT"
                                    >
                                      eBay Account
                                    </option>
                                    <option
                                      id="asm0option56"
                                      value="FAMILY_MEMBER"
                                    >
                                      Family Member
                                    </option>
                                    <option
                                      id="asm0option57"
                                      value="FAM_ASSIGNEE"
                                    >
                                      Fixed Asset Maint Assignee
                                    </option>
                                    <option
                                      id="asm0option58"
                                      value="HOSTING_SERVER"
                                    >
                                      Hosting Server
                                    </option>
                                    <option id="asm0option59" value="HOUSEHOLD">
                                      Household
                                    </option>
                                    <option
                                      id="asm0option60"
                                      value="IMAGEAPPROVER"
                                    >
                                      Image Approver
                                    </option>
                                    <option
                                      id="asm0option61"
                                      value="INTERNAL_ORGANIZATIO"
                                    >
                                      Internal Organization
                                    </option>
                                    <option id="asm0option62" value="ISP">
                                      ISP
                                    </option>
                                    <option id="asm0option63" value="LEAD">
                                      Lead
                                    </option>
                                    <option id="asm0option64" value="LTD_ADMIN">
                                      Limited Administrator
                                    </option>
                                    <option id="asm0option65" value="LOGGEDIN">
                                      Logged In
                                    </option>
                                    <option id="asm0option66" value="MAIN_ROLE">
                                      Main Role
                                    </option>
                                    <option
                                      id="asm0option67"
                                      value="FAM_MANAGER"
                                    >
                                      Maintenance Manager or Supervisor
                                    </option>
                                    <option
                                      id="asm0option68"
                                      value="FAM_SUPPLIER"
                                    >
                                      Maintenance Supplier or Service
                                    </option>
                                    <option
                                      id="asm0option69"
                                      value="FAM_WORKER"
                                    >
                                      Maintenance Worker
                                    </option>
                                    <option id="asm0option70" value="MANAGER">
                                      Manager
                                    </option>
                                    <option
                                      id="asm0option71"
                                      value="MANUFACTURER"
                                    >
                                      Manufacturer
                                    </option>
                                    <option id="asm0option72" value="_NA_">
                                      Not Applicable
                                    </option>
                                    <option
                                      id="asm0option73"
                                      value="ORDER_CLERK"
                                    >
                                      Order Clerk
                                    </option>
                                    <option
                                      id="asm0option74"
                                      value="ORGANIZATION_ROLE"
                                    >
                                      Organization
                                    </option>
                                    <option
                                      id="asm0option75"
                                      value="ORGANIZATION_UNIT"
                                    >
                                      Organization Unit
                                    </option>
                                    <option
                                      id="asm0option76"
                                      value="ORIGINATOR"
                                    >
                                      Originator
                                    </option>
                                    <option
                                      id="asm0option77"
                                      value="OTHER_INTERNAL_ORGAN"
                                    >
                                      Other Internal
                                    </option>
                                    <option
                                      id="asm0option78"
                                      value="OTHER_ORGANIZATION_U"
                                    >
                                      Other Organization Unit
                                    </option>
                                    <option id="asm0option79" value="OWNER">
                                      Owner
                                    </option>
                                    <option id="asm0option80" value="PACKER">
                                      Packer
                                    </option>
                                    <option
                                      id="asm0option81"
                                      value="PARENT_ORGANIZATION"
                                    >
                                      Parent Organization
                                    </option>
                                    <option id="asm0option82" value="PARTNER">
                                      Partner
                                    </option>
                                    <option
                                      id="asm0option83"
                                      value="PERSON_ROLE"
                                    >
                                      Person
                                    </option>
                                    <option id="asm0option84" value="PICKER">
                                      Picker
                                    </option>
                                    <option
                                      id="asm0option85"
                                      value="PLACING_CUSTOMER"
                                    >
                                      Placing Customer
                                    </option>
                                    <option
                                      id="asm0option86"
                                      value="PRODUCT_OWNER"
                                    >
                                      Product Owner
                                    </option>
                                    <option
                                      id="asm0option87"
                                      value="PRODUCT_OWNER_COMP"
                                    >
                                      Product Owner Company
                                    </option>
                                    <option
                                      id="asm0option88"
                                      value="PROJECT_TEAM"
                                    >
                                      Project Team
                                    </option>
                                    <option id="asm0option89" value="PROSPECT">
                                      Prospect
                                    </option>
                                    <option
                                      id="asm0option90"
                                      value="PROVIDER_ACCOUNTING"
                                    >
                                      Provider Accounting
                                    </option>
                                    <option
                                      id="asm0option91"
                                      value="PROVIDER_ANALYST"
                                    >
                                      Provider Analyst
                                    </option>
                                    <option
                                      id="asm0option92"
                                      value="PROVIDER_FUNCTIONAL"
                                    >
                                      Provider Functional
                                    </option>
                                    <option
                                      id="asm0option93"
                                      value="PROVIDER_MANAGER"
                                    >
                                      Provider Manager
                                    </option>
                                    <option
                                      id="asm0option94"
                                      value="PROVIDER_TESTER"
                                    >
                                      Provider Tester
                                    </option>
                                    <option
                                      id="asm0option95"
                                      value="PROVIDER_VALIDATOR"
                                    >
                                      Provider Validator
                                    </option>
                                    <option id="asm0option96" value="RECEIVER">
                                      Receiver
                                    </option>
                                    <option id="asm0option97" value="REFERRER">
                                      Referrer
                                    </option>
                                    <option
                                      id="asm0option98"
                                      value="REGULATORY_AGENCY"
                                    >
                                      Regulatory Agency
                                    </option>
                                    <option
                                      id="asm0option99"
                                      value="REQ_MANAGER"
                                    >
                                      Request Manager
                                    </option>
                                    <option
                                      id="asm0option100"
                                      value="REQ_RESPOND"
                                    >
                                      Request Respondent
                                    </option>
                                    <option
                                      id="asm0option101"
                                      value="REQUEST_ROLE"
                                    >
                                      Request Role
                                    </option>
                                    <option
                                      id="asm0option102"
                                      value="REQ_TAKER"
                                    >
                                      Request Taker
                                    </option>
                                    <option
                                      id="asm0option103"
                                      value="REQ_REQUESTER"
                                    >
                                      Requesting Party
                                    </option>
                                    <option id="asm0option104" value="REVIEWER">
                                      Reviewer
                                    </option>
                                    <option id="asm0option105" value="SFA_ROLE">
                                      Sales Force Autm.
                                    </option>
                                    <option
                                      id="asm0option106"
                                      value="SALES_REP"
                                    >
                                      Sales Representative
                                    </option>
                                    <option
                                      id="asm0option107"
                                      value="SCRUM_MASTER"
                                    >
                                      SCRUM Master
                                    </option>
                                    <option
                                      id="asm0option108"
                                      value="SCRUM_TEAM"
                                    >
                                      SCRUM Team
                                    </option>
                                    <option
                                      id="asm0option109"
                                      value="SCRUM_MEMBER"
                                    >
                                      Scrum Member
                                    </option>
                                    <option
                                      id="asm0option110"
                                      value="SHAREHOLDER"
                                    >
                                      Shareholder
                                    </option>
                                    <option
                                      id="asm0option111"
                                      value="SHIP_FROM_VENDOR"
                                    >
                                      Ship-From Vendor
                                    </option>
                                    <option
                                      id="asm0option112"
                                      value="SHIP_TO_CUSTOMER"
                                    >
                                      Ship-To Customer
                                    </option>
                                    <option
                                      id="asm0option113"
                                      value="SHIPMENT_CLERK"
                                    >
                                      Shipment Clerk
                                    </option>
                                    <option id="asm0option114" value="SPONSOR">
                                      Sponsor
                                    </option>
                                    <option id="asm0option115" value="SPOUSE">
                                      Spouse
                                    </option>
                                    <option
                                      id="asm0option116"
                                      value="STAKEHOLDER"
                                    >
                                      Stakeholder
                                    </option>
                                    <option id="asm0option117" value="STOCKER">
                                      Stocker
                                    </option>
                                    <option
                                      id="asm0option118"
                                      value="SUBSCRIBER"
                                    >
                                      Subscriber
                                    </option>
                                    <option
                                      id="asm0option119"
                                      value="SUBSIDIARY"
                                    >
                                      Subsidiary
                                    </option>
                                    <option id="asm0option120" value="SUPPLIER">
                                      Supplier
                                    </option>
                                    <option
                                      id="asm0option121"
                                      value="SUPPLIER_AGENT"
                                    >
                                      Supplier Agent
                                    </option>
                                    <option
                                      id="asm0option122"
                                      value="TAX_AUTHORITY"
                                    >
                                      Tax Authority
                                    </option>
                                    <option id="asm0option123" value="UNION">
                                      Union
                                    </option>
                                    <option id="asm0option124" value="VENDOR">
                                      Vendor
                                    </option>
                                    <option id="asm0option125" value="VISITOR">
                                      Visitor
                                    </option>
                                    <option
                                      id="asm0option126"
                                      value="WEB_MASTER"
                                    >
                                      Web Master
                                    </option>
                                    <option id="asm0option127" value="WORKER">
                                      Worker
                                    </option>
                                    <option
                                      id="asm0option128"
                                      value="WORKFLOW_ROLE"
                                    >
                                      Workflow
                                    </option>
                                    <option id="asm0option129" value="WF_OWNER">
                                      Workflow Owner
                                    </option>
                                  </select>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Party ID
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <div id="1_lookupId_partyId_auto"> </div>
                                <span class="field-lookup">
                                  <input
                                    id="1_lookupId_partyId"
                                    class="ui-autocomplete-input"
                                    autocomplete="off"
                                    maxlength="20"
                                    name="partyId"
                                    size="20"
                                    type="text"
                                  />
                                  <a href id="1_lookupId_button">
                                    {" "}
                                  </a>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                User Login ID
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <input
                                  name="userLoginId"
                                  type="text"
                                  value=""
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Order Type
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <select name="orderTypeId">
                                  <option value="">Any Order Type</option>
                                  <option value="PURCHASE_ORDER">
                                    Purchase Order
                                  </option>
                                  <option value="SALES_ORDER">
                                    Sales Order
                                  </option>
                                </select>
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Billing Account
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <input
                                  name="billingAccountId"
                                  type="text"
                                  value=""
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Created By
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <input name="createdBy" type="text" value="" />
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Sales Channel
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <select name="salesChannelEnumId">
                                  <option value="">Any Channel</option>
                                  <option value="GC_SALES_CHANNEL">
                                    Google Checkout Channel
                                  </option>
                                  <option value="WEB_SALES_CHANNEL">
                                    Web Channel
                                  </option>
                                  <option value="POS_SALES_CHANNEL">
                                    POS Channel
                                  </option>
                                  <option value="FAX_SALES_CHANNEL">
                                    Fax Channel
                                  </option>
                                  <option value="PHONE_SALES_CHANNEL">
                                    Phone Channel
                                  </option>
                                  <option value="EMAIL_SALES_CHANNEL">
                                    E-Mail Channel
                                  </option>
                                  <option value="SNAIL_SALES_CHANNEL">
                                    Snail Mail Channel
                                  </option>
                                  <option value="AFFIL_SALES_CHANNEL">
                                    Affiliate Channel
                                  </option>
                                  <option value="EBAY_SALES_CHANNEL">
                                    eBay Channel
                                  </option>
                                  <option value="ONLN_DSKTP_AFLT_NT">
                                    Online-Desktop-Affiliate Network
                                  </option>
                                  <option value="ONLN_DSKTP_ALLIANCE">
                                    Online-Desktop-Alliance
                                  </option>
                                  <option value="ONLN_DSKTP_DIRECT">
                                    Online-Desktop-direct
                                  </option>
                                  <option value="ONLN_DSKTP_EMAIL">
                                    Online-Desktop-email
                                  </option>
                                  <option value="ONLN_DSKTP_PPC">
                                    Online-Desktop-ppc
                                  </option>
                                  <option value="ONLN_DSKTP_ORGANIC">
                                    Online-Desktop-organic
                                  </option>
                                  <option value="ONLN_DSKTP_OTHERS">
                                    Online-Desktop-others
                                  </option>
                                  <option value="ONLN_MBL_AFLT_NT">
                                    Online-Mobile-affiliate network
                                  </option>
                                  <option value="ONLN_MBL_ALLIANCE">
                                    Online-Mobile-alliance
                                  </option>
                                  <option value="ONLN_MBL_DIRECT">
                                    Online-Mobile-direct
                                  </option>
                                  <option value="ONLN_MBL_EMAIL">
                                    Online-Mobile-email
                                  </option>
                                  <option value="ONLN_MBL_PPC">
                                    Online-Mobile-ppc
                                  </option>
                                  <option value="ONLN_MBL_ORGANIC">
                                    Online-Mobile-organic
                                  </option>
                                  <option value="ONLN_MBL_OTHERS">
                                    Online-Mobile-others
                                  </option>
                                  <option value="ONLN_TAB_AFLT_NT">
                                    Online-Tablet-affiliate network
                                  </option>
                                  <option value="ONLN_TAB_ALLIANCE">
                                    Online-Tablet-alliance
                                  </option>
                                  <option value="ONLN_TAB_DIRECT">
                                    Online-Tablet-direct
                                  </option>
                                  <option value="ONLN_TAB_EMAIL">
                                    Online-Tablet-email
                                  </option>
                                  <option value="ONLN_TAB_PPC">
                                    Online-Tablet-ppc
                                  </option>
                                  <option value="ONLN_TAB_ORGANIC">
                                    Online-Tablet-organic
                                  </option>
                                  <option value="ONLN_TAB_OTHERS">
                                    Online-Tablet-others
                                  </option>
                                  <option value="CALLCTR_ABC">
                                    Call Center-abandoned cart
                                  </option>
                                  <option value="CALLCTR_CBL">
                                    Call Center-cbl
                                  </option>
                                  <option value="CALLCTR_ODB">
                                    Call Center-odb
                                  </option>
                                  <option value="CALLCTR_BMF_ORDERS">
                                    Call Center-BMF Orders
                                  </option>
                                  <option value="CALLCTR_ORDERCALL">
                                    Call Center-ordercall
                                  </option>
                                  <option value="CALLCTR_PNC">
                                    Call Center-pnc
                                  </option>
                                  <option value="CALLCTR_CHAT">
                                    Call Center-Chat
                                  </option>
                                  <option value="CALLCTR_OTHERS">
                                    Call Center-others
                                  </option>
                                  <option value="CALLCTR_PNI">
                                    Call Center-PNI
                                  </option>
                                  <option value="CALLCTR_FNP_STAFF">
                                    Call Center-FNP Staff
                                  </option>
                                  <option value="WEBSITES_TAJONLN">
                                    Websites-tajonline
                                  </option>
                                  <option value="WEBSITES_INDIAMART">
                                    Websites-indiamart
                                  </option>
                                  <option value="WEBSITES_INDIATIMES">
                                    Websites-indiatimes
                                  </option>
                                  <option value="WEBSITES_OTHERS">
                                    Websites-others
                                  </option>
                                  <option value="WEBSITES">WEBSITES</option>
                                  <option value="MOBILE_ANDROID">
                                    Mobile App Android
                                  </option>
                                  <option value="MOBILE_IPHONE">
                                    Mobile App Iphone
                                  </option>
                                  <option value="MOBILE_BLACKBERRY">
                                    Mobile App Blackberry
                                  </option>
                                  <option value="MOBILE_WINDOWS">
                                    Mobile App Windows
                                  </option>
                                  <option value="SHOPHO_DIRECT_VENDOR">
                                    SHOPHO DIRECT VENDOR
                                  </option>
                                  <option value="SHOPHO_FNP_COCO">
                                    SHOPHO FNP-COCO
                                  </option>
                                  <option value="SHOPHO_FRANCHISEE">
                                    SHOPHO FNP-Franchisee
                                  </option>
                                  <option value="SHOPHO_INTERNATIONAL">
                                    SHOPHO INTERNATIONAL
                                  </option>
                                  <option value="SHOPHO_NON_FNP">
                                    SHOPHO NON FNP
                                  </option>
                                  <option value="PRDS_MNFG_EMAIL">
                                    Outsorucing vendor for manufacturing
                                  </option>
                                  <option value="PRDS_MNFG_UPDATE">
                                    Outsorucing vendor for update manufacturing
                                  </option>
                                  <option value="UNKNWN_SALES_CHANNEL">
                                    Unknown Channel
                                  </option>
                                </select>
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Product Store
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <select name="productStoreId">
                                  <option value="">Any Store</option>
                                  <option value="30000">FNPAE</option>
                                </select>
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Web Site
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <select name="orderWebSiteId">
                                  <option value="">Any Web Site</option>
                                  <option value="FNPProductApi">
                                    FNP mobileApp
                                  </option>
                                  <option value="FNPAEMobile">
                                    FNPAEMobile
                                  </option>
                                  <option value="FNPMobile">FNPMobile</option>
                                  <option value="FNP">fernsnpetals</option>
                                </select>
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Status
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <select name="orderStatusId">
                                  <option value="">Any Order Status</option>
                                  <option value="ORDER_CREATED">Waiting</option>
                                  <option value="ORDER_SENT">Sent</option>
                                  <option value="ORDER_PROCESSING">
                                    Processing
                                  </option>
                                  <option value="ORDER_APPROVED">
                                    Confirmed
                                  </option>
                                  <option value="ORDER_HOLD">Held</option>
                                  <option value="ORDER_COMPLETED">
                                    Completed
                                  </option>
                                  <option value="ORDER_REJECTED">
                                    Rejected
                                  </option>
                                  <option value="ORDER_CANCELLED">
                                    Cancelled
                                  </option>
                                </select>
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Contains BackOrders
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <select name="hasBackOrders">
                                  <option value="">Show All</option>
                                  <option value="Y">Only</option>
                                </select>
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Select Shipping Method
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <select name="shipmentMethod">
                                  <option value="">
                                    Select Shipping Method
                                  </option>
                                  <option value="AEV-N1010418_1HR@1_HR_DELIVERY">
                                    AEV-N1010418_1HR 1 Hour Delivery
                                  </option>
                                  <option value="AEV-N1261216_1HR@1_HR_DELIVERY">
                                    AEV-N1261216_1HR 1 Hour Delivery
                                  </option>
                                  <option value="AEV-N1010418_2DD@2_DAY_DELIVERY">
                                    AEV-N1010418_2DD Standard Delivery
                                  </option>
                                  <option value="AEV-N1261216_2DD@2_DAY_DELIVERY">
                                    AEV-N1261216_2DD Standard Delivery
                                  </option>
                                  <option value="AEV-N1261216_EG2DD@2_DAY_DELIVERY">
                                    AEV-N1261216_EG2DD Standard Delivery
                                  </option>
                                  <option value="TST_COURSUP@COUR_DELIVERY">
                                    TST_COURSUP Cour Delivery
                                  </option>
                                  <option value="AEV-N1261216_CNDD@C_NEXT_DAY_DELIVERY">
                                    AEV-N1261216_CNDD Standard Delivery
                                  </option>
                                  <option value="AEV-N1261216-GT@DIGITAL_DELIVERY">
                                    AEV-N1261216-GT Digital Delivery
                                  </option>
                                  <option value="AEV-N1261216_ED@EARLY_DELIVERY">
                                    AEV-N1261216_ED EARLIEST DELIVERY
                                  </option>
                                  <option value="AEV-N1261216_FDRK@EARLY_DELIVERY">
                                    AEV-N1261216_FDRK EARLIEST DELIVERY
                                  </option>
                                  <option value="AEV-N1010418@EARLY_MORNING">
                                    AEV-N1010418 Morning Delivery
                                  </option>
                                  <option value="AEV-N1010418-BD@EARLY_MORNING">
                                    AEV-N1010418-BD Morning Delivery
                                  </option>
                                  <option value="AEV-N1261216@EARLY_MORNING">
                                    AEV-N1261216 Morning Delivery
                                  </option>
                                  <option value="AEV-N1010418@EXPRESS_DELIVERY">
                                    AEV-N1010418 Standard-Delivery
                                  </option>
                                  <option value="AEV-N1010418-BD@EXPRESS_DELIVERY">
                                    AEV-N1010418-BD Standard-Delivery
                                  </option>
                                  <option value="AEV-N1261216@EXPRESS_DELIVERY">
                                    AEV-N1261216 Standard-Delivery
                                  </option>
                                  <option value="INT-1060619@EXPRESS_DELIVERY">
                                    INT-1060619 Standard-Delivery
                                  </option>
                                  <option value="INT-11290419@EXPRESS_DELIVERY">
                                    INT-11290419 Standard-Delivery
                                  </option>
                                  <option value="INT-1130719@EXPRESS_DELIVERY">
                                    INT-1130719 Standard-Delivery
                                  </option>
                                  <option value="INT-120419@EXPRESS_DELIVERY">
                                    INT-120419 Standard-Delivery
                                  </option>
                                  <option value="INT-1260819@EXPRESS_DELIVERY">
                                    INT-1260819 Standard-Delivery
                                  </option>
                                  <option value="INT-21290419@EXPRESS_DELIVERY">
                                    INT-21290419 Standard-Delivery
                                  </option>
                                  <option value="INT-2260819@EXPRESS_DELIVERY">
                                    INT-2260819 Standard-Delivery
                                  </option>
                                  <option value="INT-31290419@EXPRESS_DELIVERY">
                                    INT-31290419 Standard-Delivery
                                  </option>
                                  <option value="INT-3260819@EXPRESS_DELIVERY">
                                    INT-3260819 Standard-Delivery
                                  </option>
                                  <option value="INT-41290419@EXPRESS_DELIVERY">
                                    INT-41290419 Standard-Delivery
                                  </option>
                                  <option value="INT-4260819@EXPRESS_DELIVERY">
                                    INT-4260819 Standard-Delivery
                                  </option>
                                  <option value="INT-51290419@EXPRESS_DELIVERY">
                                    INT-51290419 Standard-Delivery
                                  </option>
                                  <option value="INT-5260819@EXPRESS_DELIVERY">
                                    INT-5260819 Standard-Delivery
                                  </option>
                                  <option value="INT-61290419@EXPRESS_DELIVERY">
                                    INT-61290419 Standard-Delivery
                                  </option>
                                  <option value="INT-71290419@EXPRESS_DELIVERY">
                                    INT-71290419 Standard-Delivery
                                  </option>
                                  <option value="TST_SUP12@EXPRESS_DELIVERY">
                                    TST_SUP12 Standard-Delivery
                                  </option>
                                  <option value="AEV-N1010418_ENDD@E_NEXT_DAY_DELIVERY">
                                    AEV-N1010418_ENDD Standard Delivery
                                  </option>
                                  <option value="AEV-N1261216_ENDD@E_NEXT_DAY_DELIVERY">
                                    AEV-N1261216_ENDD Standard Delivery
                                  </option>
                                  <option value="AEV-N1010418@FIXTIME_DELIVERY">
                                    AEV-N1010418 Fixed Time Delivery
                                  </option>
                                  <option value="AEV-N1261216@FIXTIME_DELIVERY">
                                    AEV-N1261216 Fixed Time Delivery
                                  </option>
                                  <option value="TST_SUP12@FIXTIME_DELIVERY">
                                    TST_SUP12 Fixed Time Delivery
                                  </option>
                                  <option value="TST_SUP13@FIXTIME_DELIVERY">
                                    TST_SUP13 Fixed Time Delivery
                                  </option>
                                  <option value="TST_SUP14@FIXTIME_DELIVERY">
                                    TST_SUP14 Fixed Time Delivery
                                  </option>
                                  <option value="TST_SUP15@FIXTIME_DELIVERY">
                                    TST_SUP15 Fixed Time Delivery
                                  </option>
                                  <option value="TST_SUP12@FIX_TIME">
                                    TST_SUP12 Fix Time Delivery
                                  </option>
                                  <option value="AEV-N1261216_FD@FREE_SHIPPING">
                                    AEV-N1261216_FD FREE SHIPPING
                                  </option>
                                  <option value="AEV-N1120219-3P@LAST_MINUTE">
                                    AEV-N1120219-3P Valentine-Delivery
                                  </option>
                                  <option value="AEV-N2120219-3P@LAST_MINUTE">
                                    AEV-N2120219-3P Valentine-Delivery
                                  </option>
                                  <option value="AEV-N3120219-3P@LAST_MINUTE">
                                    AEV-N3120219-3P Valentine-Delivery
                                  </option>
                                  <option value="AEV-N4120219-3P@LAST_MINUTE">
                                    AEV-N4120219-3P Valentine-Delivery
                                  </option>
                                  <option value="AEV-N5120219-3P@LAST_MINUTE">
                                    AEV-N5120219-3P Valentine-Delivery
                                  </option>
                                  <option value="AEV-N1010418@MIDNIGHT_DELIVERY">
                                    AEV-N1010418 Midnight Delivery
                                  </option>
                                  <option value="AEV-N1010418-BD@MIDNIGHT_DELIVERY">
                                    AEV-N1010418-BD Midnight Delivery
                                  </option>
                                  <option value="AEV-N1261216@MIDNIGHT_DELIVERY">
                                    AEV-N1261216 Midnight Delivery
                                  </option>
                                  <option value="TST_SUP12@MIDNIGHT_DELIVERY">
                                    TST_SUP12 Midnight Delivery
                                  </option>
                                  <option value="TST_SUP13@MIDNIGHT_DELIVERY">
                                    TST_SUP13 Midnight Delivery
                                  </option>
                                  <option value="TST_SUP14@MIDNIGHT_DELIVERY">
                                    TST_SUP14 Midnight Delivery
                                  </option>
                                  <option value="TST_SUP15@MIDNIGHT_DELIVERY">
                                    TST_SUP15 Midnight Delivery
                                  </option>
                                  <option value="TST_SUP12@MID_NIGHT">
                                    TST_SUP12 Mid Night Delivery
                                  </option>
                                  <option value="AEV-N1261216_VP@MOTHERS_DAY_DELIVER">
                                    AEV-N1261216_VP 1 Hour Delivery
                                  </option>
                                  <option value="AEV-N1261216_ED@NEXT_DAY_COURIER">
                                    AEV-N1261216_ED Next Day Courier
                                  </option>
                                  <option value="_NA_@NO_SHIPPING">
                                    _NA_ No Shipping
                                  </option>
                                  <option value="AEV-N1261216@STANDARD_DELIVERY">
                                    AEV-N1261216 Standard Delivery
                                  </option>
                                  <option value="TST_SUP12@STANDARD_DELIVERY">
                                    TST_SUP12 Standard Delivery
                                  </option>
                                  <option value="TST_SUP13@STANDARD_DELIVERY">
                                    TST_SUP13 Standard Delivery
                                  </option>
                                  <option value="TST_SUP14@STANDARD_DELIVERY">
                                    TST_SUP14 Standard Delivery
                                  </option>
                                  <option value="TST_SUP15@STANDARD_DELIVERY">
                                    TST_SUP15 Standard Delivery
                                  </option>
                                  <option value="AEV-N1261216@VALENTINE_DELIVERY">
                                    AEV-N1261216 Express-Delivery
                                  </option>
                                  <option value="AEV-N1261216_2DD@VALENTINE_DELIVERY">
                                    AEV-N1261216_2DD Express-Delivery
                                  </option>
                                  <option value="AEV-N1261216_CNDD@VALENTINE_DELIVERY">
                                    AEV-N1261216_CNDD Express-Delivery
                                  </option>
                                  <option value="AEV-N1261216_ED@VALENTINE_DELIVERY">
                                    AEV-N1261216_ED Express-Delivery
                                  </option>
                                  <option value="AEV-N1261216_ENDD@VALENTINE_DELIVERY">
                                    AEV-N1261216_ENDD Express-Delivery
                                  </option>
                                  <option value="AEV-N1261216_FD@VALENTINE_DELIVERY">
                                    AEV-N1261216_FD Express-Delivery
                                  </option>
                                  <option value="AEV-N1261216_FDRK@VALENTINE_DELIVERY">
                                    AEV-N1261216_FDRK Express-Delivery
                                  </option>
                                  <option value="AEV-N1010418_VP@VALENTINE_PRIORITY">
                                    AEV-N1010418_VP Express Delivery
                                  </option>
                                  <option value="AEV-N1261216@VALENTINE_PRIORITY">
                                    AEV-N1261216 Express Delivery
                                  </option>
                                  <option value="AEV-N1261216_2DD@VALENTINE_PRIORITY">
                                    AEV-N1261216_2DD Express Delivery
                                  </option>
                                  <option value="AEV-N1261216_CNDD@VALENTINE_PRIORITY">
                                    AEV-N1261216_CNDD Express Delivery
                                  </option>
                                  <option value="AEV-N1261216_ED@VALENTINE_PRIORITY">
                                    AEV-N1261216_ED Express Delivery
                                  </option>
                                  <option value="AEV-N1261216_ENDD@VALENTINE_PRIORITY">
                                    AEV-N1261216_ENDD Express Delivery
                                  </option>
                                  <option value="AEV-N1261216_FD@VALENTINE_PRIORITY">
                                    AEV-N1261216_FD Express Delivery
                                  </option>
                                  <option value="AEV-N1261216_VP@VALENTINE_PRIORITY">
                                    AEV-N1261216_VP Express Delivery
                                  </option>
                                </select>
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Viewed
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <select name="isViewed">
                                  <option value=""></option>
                                  <option value="Y">Yes</option>
                                  <option value="N">No</option>
                                </select>
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Address Verification (AVS)
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <input
                                  name="gatewayAvsResult"
                                  type="text"
                                  value=""
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Score
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <input
                                  name="gatewayScoreResult"
                                  type="text"
                                  value=""
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Date Filter
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <table class="basic-table" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td nowrap="nowrap">
                                        <input
                                          name="from"
                                          type="date"
                                          onChange={this.handleChang}
                                          value={from}
                                        />
                                        <span class="label ml-2">From</span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td nowrap="nowrap">
                                        <input
                                          name="to"
                                          value={to}
                                          onChange={this.handleChang}
                                          type="date"
                                        />
                                        <span class="label ml-2">Through</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Filter on Inventory problems
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <table class="basic-table" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td nowrap="nowrap">
                                        <input
                                          name="filterInventoryProblems"
                                          type="checkbox"
                                          value="Y"
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Filter on purchase orders partially received
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <table class="basic-table" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td nowrap="nowrap">
                                        <input
                                          name="filterPartiallyReceivedPOs"
                                          type="checkbox"
                                          value="Y"
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Filter on purchase orders open past their ETA
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <table class="basic-table" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td nowrap="nowrap">
                                        <input
                                          name="filterPOsOpenPastTheirETA"
                                          type="checkbox"
                                          value="Y"
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Filter on purchase orders with rejected items
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <table class="basic-table" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td nowrap="nowrap">
                                        <input
                                          name="filterPOsWithRejectedItems"
                                          type="checkbox"
                                          value="Y"
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Ship to Country
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <select name="countryGeoId">
                                  <option value="">---</option>
                                  <option value="AFG">Afghanistan</option>
                                  <option value="ALB">Albania</option>
                                  <option value="DZA">Algeria</option>
                                  <option value="ASM">American Samoa</option>
                                  <option value="AND">Andorra</option>
                                  <option value="AGO">Angola</option>
                                  <option value="AIA">Anguilla</option>
                                  <option value="ATA">Antarctica</option>
                                  <option value="ATG">
                                    Antigua And Barbuda
                                  </option>
                                  <option value="ARG">Argentina</option>
                                  <option value="ARM">Armenia</option>
                                  <option value="ABW">Aruba</option>
                                  <option value="AUS">Australia</option>
                                  <option value="AUT">Austria</option>
                                  <option value="AZE">Azerbaijan</option>
                                  <option value="ALA">Åland islands</option>
                                  <option value="BHS">Bahamas</option>
                                  <option value="BHR">Bahrain</option>
                                  <option value="BGD">Bangladesh</option>
                                  <option value="BRB">Barbados</option>
                                  <option value="BLR">Belarus</option>
                                  <option value="BEL">Belgium</option>
                                  <option value="BLZ">Belize</option>
                                  <option value="BEN">Benin</option>
                                  <option value="BMU">Bermuda</option>
                                  <option value="BTN">Bhutan</option>
                                  <option value="BOL">Bolivia</option>
                                  <option value="BES">
                                    Bonaire, Sint Eustatius and Saba
                                  </option>
                                  <option value="BIH">
                                    Bosnia And Herzegowina
                                  </option>
                                  <option value="BWA">Botswana</option>
                                  <option value="BVT">Bouvet Island</option>
                                  <option value="BRA">Brazil</option>
                                  <option value="IOT">
                                    British Indian Ocean Territory
                                  </option>
                                  <option value="BRN">Brunei Darussalam</option>
                                  <option value="BGR">Bulgaria</option>
                                  <option value="BFA">Burkina Faso</option>
                                  <option value="BDI">Burundi</option>
                                  <option value="KHM">Cambodia</option>
                                  <option value="CMR">Cameroon</option>
                                  <option value="CAN">Canada</option>
                                  <option value="CPV">Cape Verde</option>
                                  <option value="CYM">Cayman Islands</option>
                                  <option value="CAF">
                                    Central African Republic
                                  </option>
                                  <option value="TCD">Chad</option>
                                  <option value="CHL">Chile</option>
                                  <option value="CHN">China</option>
                                  <option value="CXR">Christmas Island</option>
                                  <option value="CCK">
                                    Cocos (keeling) Islands
                                  </option>
                                  <option value="COL">Colombia</option>
                                  <option value="COM">Comoros</option>
                                  <option value="COG">Congo</option>
                                  <option value="COD">
                                    Congo, The Democratic Republic Of The
                                  </option>
                                  <option value="COK">Cook Islands</option>
                                  <option value="CRI">Costa Rica</option>
                                  <option value="CIV">Cote D'ivoire</option>
                                  <option value="HRV">
                                    Croatia (local Name: Hrvatska)
                                  </option>
                                  <option value="CUB">Cuba</option>
                                  <option value="CUW">Curaçao</option>
                                  <option value="CYP">Cyprus</option>
                                  <option value="CZE">Czech Republic</option>
                                  <option value="DNK">Denmark</option>
                                  <option value="DJI">Djibouti</option>
                                  <option value="DMA">Dominica</option>
                                  <option value="DOM">
                                    Dominican Republic
                                  </option>
                                  <option value="TLS">East Timor</option>
                                  <option value="ECU">Ecuador</option>
                                  <option value="EGY">Egypt</option>
                                  <option value="SLV">El Salvador</option>
                                  <option value="ENGL">England</option>
                                  <option value="GNQ">Equatorial Guinea</option>
                                  <option value="ERI">Eritrea</option>
                                  <option value="EST">Estonia</option>
                                  <option value="ETH">Ethiopia</option>
                                  <option value="FLK">
                                    Falkland Islands (malvinas)
                                  </option>
                                  <option value="FRO">Faroe Islands</option>
                                  <option value="FJI">Fiji</option>
                                  <option value="FIN">Finland</option>
                                  <option value="FRA">France</option>
                                  <option value="FXX">
                                    France, Metropolitan
                                  </option>
                                  <option value="GUF">French Guiana</option>
                                  <option value="PYF">French Polynesia</option>
                                  <option value="ATF">
                                    French Southern Territories
                                  </option>
                                  <option value="GAB">Gabon</option>
                                  <option value="GMB">Gambia</option>
                                  <option value="GEO">Georgia</option>
                                  <option value="DEU">Germany</option>
                                  <option value="GHA">Ghana</option>
                                  <option value="GIB">Gibraltar</option>
                                  <option value="GRC">Greece</option>
                                  <option value="GRL">Greenland</option>
                                  <option value="GRD">Grenada</option>
                                  <option value="GLP">Guadeloupe</option>
                                  <option value="GUM">Guam</option>
                                  <option value="GTM">Guatemala</option>
                                  <option value="GGY">Guernsey</option>
                                  <option value="GIN">Guinea</option>
                                  <option value="GNB">Guinea-bissau</option>
                                  <option value="GUY">Guyana</option>
                                  <option value="HTI">Haiti</option>
                                  <option value="HMD">
                                    Heard And Mc Donald Islands
                                  </option>
                                  <option value="VAT">
                                    Holy See (vatican City State)
                                  </option>
                                  <option value="HND">Honduras</option>
                                  <option value="HKG">Hong Kong</option>
                                  <option value="HUN">Hungary</option>
                                  <option value="ISL">Iceland</option>
                                  <option value="IND">India</option>
                                  <option value="IDN">Indonesia</option>
                                  <option value="IRN">
                                    Iran (islamic Republic Of)
                                  </option>
                                  <option value="IRQ">Iraq</option>
                                  <option value="IRL">Ireland</option>
                                  <option value="IMN">Isle of Man</option>
                                  <option value="ISR">Israel</option>
                                  <option value="ITA">Italy</option>
                                  <option value="JAM">Jamaica</option>
                                  <option value="JPN">Japan</option>
                                  <option value="JEY">Jersey</option>
                                  <option value="JOR">Jordan</option>
                                  <option value="KAZ">Kazakhstan</option>
                                  <option value="KEN">Kenya</option>
                                  <option value="KIR">Kiribati</option>
                                  <option value="PRK">
                                    Korea, Democratic People's Republic Of
                                  </option>
                                  <option value="KOR">
                                    Korea, Republic Of
                                  </option>
                                  <option value="KWT">Kuwait</option>
                                  <option value="KGZ">Kyrgyzstan</option>
                                  <option value="LAO">
                                    Lao People's Democratic Republic
                                  </option>
                                  <option value="LVA">Latvia</option>
                                  <option value="LBN">Lebanon</option>
                                  <option value="LSO">Lesotho</option>
                                  <option value="LBR">Liberia</option>
                                  <option value="LBY">
                                    Libyan Arab Jamahiriya
                                  </option>
                                  <option value="LIE">Liechtenstein</option>
                                  <option value="LTU">Lithuania</option>
                                  <option value="LUX">Luxembourg</option>
                                  <option value="MAC">Macau</option>
                                  <option value="MKD">
                                    Macedonia, The Former Yugoslav Republic Of
                                  </option>
                                  <option value="MDG">Madagascar</option>
                                  <option value="MWI">Malawi</option>
                                  <option value="MYS">Malaysia</option>
                                  <option value="MDV">Maldives</option>
                                  <option value="MLI">Mali</option>
                                  <option value="MLT">Malta</option>
                                  <option value="MHL">Marshall Islands</option>
                                  <option value="MTQ">Martinique</option>
                                  <option value="MRT">Mauritania</option>
                                  <option value="MUS">Mauritius</option>
                                  <option value="MYT">Mayotte</option>
                                  <option value="MEX">Mexico</option>
                                  <option value="FSM">
                                    Micronesia, Federated States Of
                                  </option>
                                  <option value="MDA">
                                    Moldova, Republic Of
                                  </option>
                                  <option value="MCO">Monaco</option>
                                  <option value="MNG">Mongolia</option>
                                  <option value="MNE">Montenegro</option>
                                  <option value="MSR">Montserrat</option>
                                  <option value="MAR">Morocco</option>
                                  <option value="MOZ">Mozambique</option>
                                  <option value="MMR">Myanmar</option>
                                  <option value="NIRL">NIRL</option>
                                  <option value="NAM">Namibia</option>
                                  <option value="NRU">Nauru</option>
                                  <option value="NPL">Nepal</option>
                                  <option value="NLD">Netherlands</option>
                                  <option value="ANT">
                                    Netherlands Antilles
                                  </option>
                                  <option value="NCL">New Caledonia</option>
                                  <option value="NZL">New Zealand</option>
                                  <option value="NIC">Nicaragua</option>
                                  <option value="NER">Niger</option>
                                  <option value="NGA">Nigeria</option>
                                  <option value="NIU">Niue</option>
                                  <option value="NFK">Norfolk Island</option>
                                  <option value="MNP">
                                    Northern Mariana Islands
                                  </option>
                                  <option value="NOR">Norway</option>
                                  <option value="OMN">Oman</option>
                                  <option value="PAK">Pakistan</option>
                                  <option value="PLW">Palau</option>
                                  <option value="PSE">
                                    Palestinian Territory, Occupied
                                  </option>
                                  <option value="PAN">Panama</option>
                                  <option value="PNG">Papua New Guinea</option>
                                  <option value="PRY">Paraguay</option>
                                  <option value="PER">Peru</option>
                                  <option value="PHL">Philippines</option>
                                  <option value="PCN">Pitcairn</option>
                                  <option value="POL">Poland</option>
                                  <option value="PRT">Portugal</option>
                                  <option value="PRI">Puerto Rico</option>
                                  <option value="QAT">Qatar</option>
                                  <option value="REU">Reunion</option>
                                  <option value="ROU">Romania</option>
                                  <option value="RUS">
                                    Russian Federation
                                  </option>
                                  <option value="RWA">Rwanda</option>
                                  <option value="BLM">Saint Barthélemy</option>
                                  <option value="KNA">
                                    Saint Kitts And Nevis
                                  </option>
                                  <option value="LCA">Saint Lucia</option>
                                  <option value="MAF">
                                    Saint Martin (French part)
                                  </option>
                                  <option value="VCT">
                                    Saint Vincent And The Grenadines
                                  </option>
                                  <option value="WSM">Samoa</option>
                                  <option value="SMR">San Marino</option>
                                  <option value="STP">
                                    Sao Tome And Principe
                                  </option>
                                  <option value="SAU">Saudi Arabia</option>
                                  <option value="SCOT">Scotland</option>
                                  <option value="SEN">Senegal</option>
                                  <option value="SRB">Serbia</option>
                                  <option value="SYC">Seychelles</option>
                                  <option value="SLE">Sierra Leone</option>
                                  <option value="SGP">Singapore</option>
                                  <option value="SXM">
                                    Sint Maarten (Dutch part)
                                  </option>
                                  <option value="SVK">
                                    Slovakia (slovak Republic)
                                  </option>
                                  <option value="SVN">Slovenia</option>
                                  <option value="SLB">Solomon Islands</option>
                                  <option value="SOM">Somalia</option>
                                  <option value="ZAF">South Africa</option>
                                  <option value="SGS">
                                    South Georgia And The South Sandwich Islands
                                  </option>
                                  <option value="SSD">South Sudan</option>
                                  <option value="ESP">Spain</option>
                                  <option value="LKA">Sri Lanka</option>
                                  <option value="SHN">St. Helena</option>
                                  <option value="SPM">
                                    St. Pierre And Miquelon
                                  </option>
                                  <option value="SDN">Sudan</option>
                                  <option value="SUR">Suriname</option>
                                  <option value="SJM">
                                    Svalbard And Jan Mayen Islands
                                  </option>
                                  <option value="SWZ">Swaziland</option>
                                  <option value="SWE">Sweden</option>
                                  <option value="CHE">Switzerland</option>
                                  <option value="SYR">
                                    Syrian Arab Republic
                                  </option>
                                  <option value="TWN">Taiwan</option>
                                  <option value="TJK">Tajikistan</option>
                                  <option value="TZA">
                                    Tanzania, United Republic Of
                                  </option>
                                  <option value="THA">Thailand</option>
                                  <option value="TGO">Togo</option>
                                  <option value="TKL">Tokelau</option>
                                  <option value="TON">Tonga</option>
                                  <option value="TTO">
                                    Trinidad And Tobago
                                  </option>
                                  <option value="TUN">Tunisia</option>
                                  <option value="TUR">Turkey</option>
                                  <option value="TKM">Turkmenistan</option>
                                  <option value="TCA">
                                    Turks And Caicos Islands
                                  </option>
                                  <option value="TUV">Tuvalu</option>
                                  <option value="UGA">Uganda</option>
                                  <option value="UKR">Ukraine</option>
                                  <option selected="selected" value="ARE">
                                    United Arab Emirates
                                  </option>
                                  <option value="GBR">United Kingdom</option>
                                  <option value="USA">United States</option>
                                  <option value="UMI">
                                    United States Minor Outlying Islands
                                  </option>
                                  <option value="URY">Uruguay</option>
                                  <option value="UZB">Uzbekistan</option>
                                  <option value="VUT">Vanuatu</option>
                                  <option value="VEN">Venezuela</option>
                                  <option value="VNM">Viet Nam</option>
                                  <option value="VGB">
                                    Virgin Islands (british)
                                  </option>
                                  <option value="VIR">
                                    Virgin Islands (u.s.)
                                  </option>
                                  <option value="WALS">WALS</option>
                                  <option value="WLF">
                                    Wallis And Futuna Islands
                                  </option>
                                  <option value="ESH">Western Sahara</option>
                                  <option value="YEM">Yemen</option>
                                  <option value="YUG">Yugoslavia</option>
                                  <option value="ZMB">Zambia</option>
                                  <option value="ZWE">Zimbabwe</option>
                                </select>
                                <select name="includeCountry">
                                  <option value="Y">Only Include</option>
                                  <option value="N">Do Not Include</option>
                                </select>
                              </td>
                            </tr>
                            <tr>
                              <td class="label" align="right" width="25%">
                                Payment Status
                              </td>
                              <td width="5%"> </td>
                              <td>
                                <select name="paymentStatusId">
                                  <option value="">All</option>
                                  <option value="PAYMENT_AUTHORIZED">
                                    Authorized
                                  </option>
                                  <option value="PAYMENT_CANCELLED">
                                    Cancelled
                                  </option>
                                  <option value="PAYMENT_DECLINED">
                                    Declined
                                  </option>
                                  <option value="PAYMENT_NOT_AUTH">
                                    Not-Authorized
                                  </option>
                                  <option value="PAYMENT_NOT_RECEIVED">
                                    Not-Received
                                  </option>
                                  <option value="PAYMENT_RECEIVED">
                                    Received
                                  </option>
                                  <option value="PAYMENT_REFUNDED">
                                    Refunded
                                  </option>
                                  <option value="PAYMENT_SETTLED">
                                    Settled
                                  </option>
                                </select>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="3">
                                <hr />
                              </td>
                            </tr>
                            <tr>
                              <td align="right" width="25%">
                                {" "}
                              </td>
                              <td width="5%"> </td>
                              <td align="left">
                                <input name="showAll" type="hidden" value="Y" />
                                <input
                                  type="submit"
                                  onClick={this.handleSubmit}
                                  value="Find"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <input src="/images/spacer.gif" type="image" alt="" />
          </form>
          <br />
          <div id="findOrdersList" class="screenlet">
            <div class="screenlet-title-bar">
              <ul>
                <li class="h3">Order(s) Found</li>
              </ul>
            </div>
            <div class="screenlet-body">
              <form
                action="/ordermgr/control/searchorders"
                method="post"
                name="paginationForm"
              >
                <input name="viewSize" type="hidden" />
                <input name="viewIndex" type="hidden" />
                <input name="hideFields" type="hidden" />
              </form>
              <form method="post" name="massOrderChangeForm">
                <div> </div>
                <div align="right">
                  <input
                    name="screenLocation"
                    type="hidden"
                    value="component://order/widget/ordermgr/OrderPrintScreens.xml#OrderPDF"
                  />
                  <select name="serviceName">
                    {/* <option value="javascript:void(0);"> </option> */}
                    <option value="/ordermgr/control/massApproveOrders?hideFields=N">
                      Approve Order
                    </option>
                    <option value="/ordermgr/control/massHoldOrders?hideFields=N">
                      Hold Order
                    </option>
                    <option value="/ordermgr/control/massProcessOrders?hideFields=N">
                      Process Order
                    </option>
                    <option value="/ordermgr/control/massCancelOrders?hideFields=N">
                      Cancel Order
                    </option>
                    <option value="/ordermgr/control/massCancelRemainingPurchaseOrderItems?hideFields=N">
                      Cancel Remaining PO Items
                    </option>
                    <option value="/ordermgr/control/massRejectOrders?hideFields=N">
                      Reject Order
                    </option>
                    <option value="/ordermgr/control/massPickOrders?hideFields=N">
                      Pick Orders
                    </option>
                    <option value="/ordermgr/control/massQuickShipOrders?hideFields=N">
                      Quick Ship Entire Order
                    </option>
                    <option value="/ordermgr/control/massPrintOrders?hideFields=N">
                      Print (PDF)
                    </option>
                    <option value="/ordermgr/control/massCreateFileForOrders?hideFields=N">
                      Create File
                    </option>
                  </select>
                  <select name="printerName">
                    {/* <option value="javascript:void(0);"> </option> */}
                  </select>
                  <a href class="buttontext">
                    Run Action
                  </a>
                </div>
                <table class="basic-table hover-bar" cellspacing="0">
                  <tbody>
                    <tr class="header-row">
                      <th width="1%">
                        <input
                          id="checkAllOrders"
                          name="checkAllOrders"
                          type="checkbox"
                          value="1"
                        />
                      </th>
                      <th width="5%">Order Type</th>
                      <th width="5%">Order Id</th>
                      <th width="5%">Order Name</th>
                      <th width="5%">External ID</th>
                      <th width="5%">Customer Name</th>
                      <th width="5%">Customer Mobile</th>
                      <th align="right" width="5%">
                        Items Ordered
                      </th>
                      <th align="right" width="14%">
                        Order Item Details
                      </th>
                      <th align="right" width="10%">
                        Grand Total
                      </th>
                      <th width="5%">Status</th>
                      <th width="15%">Order Date</th>
                      <th width="5%">Order Amount</th>
                      <th width="5%">Party ID</th>
                      <th width="5%">Source</th>
                      <th width="5%">Sub Source</th>
                    </tr>
                    {foundOrders && foundOrders.length ? (
                      foundOrders.map((order) => {
                        return [
                          <tr>
                            <td></td>
                            <td></td>
                            <td>
                              <Link
                                to={`/ordermgr/control/orderview?orderId=${order._id}`}
                              >
                                {order._id}
                              </Link>
                            </td>
                            <td></td>
                            <td></td>
                            <td>{order.user.name}</td>
                            <td>{order.user.mobile}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{order.orderStatus}</td>
                            <td>{order.createdDate}</td>
                            <td>{order.amount}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>,
                        ];
                      })
                    ) : (
                      <tr>
                        <td colSpan="16">
                          <h2> !Record Not Found</h2>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({ token: state.auth.token });
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(FindOrders);
