import React, { Component } from "react";
import { Notify } from "../../../../constants/utils";

import "../../styles.scss";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  allActiveShipmentMethodsTypes,
  createPromoRules,
  getAllActivePromoRules,
  createRuleConditions,
  updatePromoRule,
  getAllActiveRuleConditions,
  updateRuleConditions,
  createRuleAttachments,
} from "../../../../store/actions/catalogActions";
import queryString from "query-string";
import { promoRules } from "../../../../store/reducers/catalogReducer";

class ProductRules extends Component {
  render() {
    const { feature, Interactions } = this.state;
    const query = queryString.parse(this.props.location.search);
    const promoId = query.promoId;
    const shipmentMethods = Array.from(this.props?.shipmentMethods);
    const promoRules = Array.from(this.props?.promoRules);
    const ruleConditions = Array.from(this.props?.ruleConditions);
    return (
      <div id="content-main-section" className="leftonly">
        <div id="centerdiv" className="no-clear">
          <div>
            <div>
              <ul style={{ display: "flex", listStyle: "none" }}>
                <a
                  href={"/catalog/control/EditProductPromo?promoId=" + promoId}
                  style={{ textDecoration: "none" }}
                >
                  <li className="featureLi">Promotion</li>
                </a>
                <a
                  href={"/catalog/control/PromoRules?promoId=" + promoId}
                  style={{ textDecoration: "none" }}
                >
                  <li className="featureLi">Rules</li>
                </a>
                <a
                  href={"/catalog/control/PromoStores?promoId=" + promoId}
                  style={{ textDecoration: "none" }}
                >
                  <li className="featureLi">Stores</li>
                </a>
                <a
                  href={"/catalog/control/PromoCodes?promoId=" + promoId}
                  style={{ textDecoration: "none" }}
                >
                  <li className="featureLi">Promotion Code</li>
                </a>
                <a
                  href={"/catalog/control/Content?promoId=" + promoId}
                  style={{ textDecoration: "none" }}
                >
                  <li className="featureLi">Content</li>
                </a>
              </ul>
            </div>
            <div>
              <tbody>
                <tr>
                  <button className="h3">
                    <a
                      style={{ textDecoration: "none" }}
                      href="/catalog/control/NewProductPromo"
                    >
                      New Product Promo
                    </a>
                  </button>
                </tr>
                <tr>
                  <h1>Rules for Flat 15% off on minimum purchase[ID: 21111]</h1>
                </tr>
              </tbody>
            </div>

            <div className="screenlet">
              <div className="screenlet-title-bar">
                <h3>Create</h3>
              </div>
              <div className="screenlet-body">
                <span className="label">Rule 01 </span>
                <input
                  onChange={(e) => this.setState({ ruleTitle: e.target.value })}
                  title="dd-MM-yyyy HH:mm:ss"
                  maxLength="30"
                  value={this.state.ruleTitle}
                  name="ruleTitle"
                  size="25"
                  type="text"
                />
                <input
                  type="submit"
                  value={promoRules[0] ? "update" : "create"}
                  onClick={(e) => this.createRule(e)}
                />
              </div>
              {promoRules && (
                <div className="screenlet-body">
                  <h3>Create Condtion</h3>
                  <form>
                    <span className="label">New</span>
                    <input name="productId" type="hidden" value="CAKE100" />
                    <select
                      value={this.state.conditionType}
                      onChange={(e) =>
                        this.setState({ conditionType: e.target.value })
                      }
                      name="featureId"
                      size="1"
                    >
                      <option>Please select A Delivery Conodition Type</option>
                      <option value="X_amount_of_Product_Y">
                        X amount of Product Y
                      </option>
                      <option value="cart_sub_total">Cart sub-total</option>
                      <option value="total_amount_of_product">
                        Total amount of Product
                      </option>
                      <option value="account_days_since_created">
                        Account Days since Created
                      </option>
                      {/* <option value="Delivery_Date_Condition">Delivery Date Condition</option> */}
                    </select>
                    <select
                      name="productFeatureGroupId"
                      value={this.state.condition}
                      size="1"
                      onChange={(e) =>
                        this.setState({ condition: e.target.value })
                      }
                    >
                      <option>please select a condition</option>
                      <option selected="is" value="Is">
                        Is
                      </option>
                      <option selected="is_not" value="Is_Not">
                        Is Not
                      </option>
                      <option value="Is_Less_Then">Is Less Then</option>
                      <option value="Is_Less_then_or_equal_to">
                        Is Less Then or Equal to
                      </option>
                      <option value="Is_greate_Then">Is Greater Then</option>
                      <option value="Is_greater_then_or_equal_to">
                        Is Greater Then or Equal to
                      </option>
                    </select>
                    <span className="label">Condition Value: </span>
                    <input
                      onChange={(e) =>
                        this.setState({ conditionValue: e.target.value })
                      }
                      maxLength="30"
                      value={this.state.conditionValue}
                      name="ruleTitle"
                      size="25"
                      type="text"
                    />
                    <span className="label">Other: </span>
                    <input
                      onChange={(e) => this.setState({ other: e.target.value })}
                      maxLength="30"
                      value={this.state.other}
                      name="ruleTitle"
                      size="25"
                      type="text"
                    />
                    <span className="label">Select Shipping Method:</span>
                    <select
                      name="type"
                      value={this.state.shipmentMethodsTypeId}
                      onChange={(e) =>
                        this.setState({ shipmentMethodsTypeId: e.target.value })
                      }
                    >
                      <option value="">Select a Shipping Method</option>
                      {shipmentMethods &&
                        shipmentMethods.map((shipmentMethod) => {
                          return (
                            <option value={shipmentMethod._id}>
                              {shipmentMethod.name}
                            </option>
                          );
                        })}
                    </select>
                    <input
                      type="submit"
                      value="Add"
                      onClick={(e) => this.handleCondition(e)}
                    />
                  </form>
                </div>
              )}
              {promoRules && (
                <div className="screenlet-body">
                  <h3>Create Actions</h3>
                  <form>
                    <span className="label">New</span>
                    <input name="productId" type="hidden" value="CAKE100" />
                    <select
                      value={this.state.actionType}
                      onChange={(e) =>
                        this.setState({ actionType: e.target.value })
                      }
                      name="featureId"
                      size="1"
                    >
                      <option>Please select A Delivery Action Type</option>
                      <option value="order_amount_flat">
                        Order Amount Flat
                      </option>
                      <option value="gift_with_purchase">
                        Gift Withe Purchase
                      </option>
                    </select>
                    <span className="label">Quantity: </span>
                    <input
                      onChange={(e) =>
                        this.setState({ actionQuantity: e.target.value })
                      }
                      maxLength="30"
                      value={this.state.actionQuantity}
                      name="ruleTitle"
                      size="25"
                      type="text"
                    />
                    <span className="label">Amount: </span>
                    <input
                      onChange={(e) =>
                        this.setState({ amount: e.target.value })
                      }
                      maxLength="30"
                      value={this.state.amount}
                      name="ruleTitle"
                      size="25"
                      type="text"
                    />
                    <input
                      type="submit"
                      value="Add"
                      onClick={(e) => this.handleAction(e)}
                    />
                  </form>
                </div>
              )}
            </div>

            <div className="screenlet">
              <div className="screenlet-title-bar">
                <h3>Edit</h3>
              </div>
              <div className="screenlet-body">
                <h3>Edit Conditions</h3>

                {this.state.ruleConditions.map((condition, index) => {
                  return (
                    <form>
                      <span className="label">
                        {"Condition: " + (index + 1)}
                      </span>
                      <input name="productId" type="hidden" value="CAKE100" />
                      <select
                        name="conditionType"
                        id={index}
                        value={this.state.ruleConditions[index].conditionType}
                        onChange={(e, index) =>
                          this.handleRuleConditionChange(e)
                        }
                        size="1"
                      >
                        <option>
                          Please select A Delivery Conodition Type
                        </option>
                        <option value="Delivery_Date_Condition">
                          Delivery Date Condition
                        </option>
                        <option value="X_amount_of_Product_Y">
                          X amount of Product Y
                        </option>
                        <option value="cart_sub_total">Cart sub-total</option>
                        <option value="total_amount_of_product">
                          Total amount of Product
                        </option>
                        <option value="account_days_since_created">
                          Account Days since Created
                        </option>
                      </select>
                      <select
                        name="condition"
                        id={index}
                        value={this.state.ruleConditions[index].condition}
                        size="1"
                        onChange={(e) => this.handleRuleConditionChange(e)}
                      >
                        <option>please select a condition</option>
                        <option value="Is">Is</option>
                        <option value="Is_Not">Is Not</option>
                        <option value="Is_Less_Then">Is Less Then</option>
                        <option value="Is_Less_then_or_equal_to">
                          Is Less Then or Equal to
                        </option>
                        <option value="Is_greate_Then">Is Greater Then</option>
                        <option value="Is_greater_then_or_equal_to">
                          Is Greater Then or Equal to
                        </option>
                      </select>
                      <span className="label">Condition Value: </span>
                      <input
                        onChange={(e) => this.handleRuleConditionChange(e)}
                        maxLength="30"
                        id={index}
                        value={this.state.ruleConditions[index].conditionValue}
                        name="conditionValue"
                        size="25"
                        type="text"
                      />
                      <span className="label">Other: </span>
                      <input
                        onChange={(e) => this.handleRuleConditionChange(e)}
                        maxLength="30"
                        id={index}
                        value={this.state.ruleConditions[index].other}
                        name="other"
                        size="25"
                        type="text"
                      />
                      <span className="label">Select Shipping Method:</span>
                      <select
                        name="shipmentMethodsTypeId"
                        id={index}
                        value={
                          this.state.ruleConditions[index].shipmentMethodsTypeId
                        }
                        onChange={(e) => this.handleRuleConditionChange(e)}
                      >
                        <option value="">Select a Shipping Method</option>
                        {shipmentMethods &&
                          shipmentMethods.map((shipmentMethod) => {
                            return (
                              <option value={shipmentMethod._id}>
                                {shipmentMethod.name}
                              </option>
                            );
                          })}
                      </select>
                      <input
                        type="submit"
                        id={index}
                        value="update"
                        onClick={(e) => this.handleConditionUpdate(e)}
                      />
                    </form>
                  );
                })}
              </div>
              {this.state.action?.actionType && (
                <div className="screenlet-body">
                  <h3>Edit Actions</h3>
                  <form>
                    <span className="label">Action 01</span>
                    <input name="productId" type="hidden" value="CAKE100" />
                    <select
                      value={this.state.action.actionType}
                      onChange={(e) => this.handleActionChange(e)}
                      name="actionType"
                      size="1"
                    >
                      <option>Please select A Delivery Action Type</option>
                      <option value="order_amount_flat">
                        Order Amount Flat
                      </option>
                      <option value="gift_with_purchase">
                        Gift Withe Purchase
                      </option>
                    </select>
                    <span className="label">Quantity: </span>
                    <input
                      onChange={(e) => this.handleActionChange(e)}
                      maxLength="30"
                      value={this.state.action.actionQuantity}
                      name="actionQuantity"
                      size="25"
                      type="text"
                    />
                    <span className="label">Amount: </span>
                    <input
                      onChange={(e) => this.handleActionChange(e)}
                      maxLength="30"
                      value={this.state.action.amount}
                      name="amount"
                      size="25"
                      type="text"
                    />
                    <input
                      type="submit"
                      value="update"
                      onClick={(e) => this.handleActionUpdate(e)}
                    />
                  </form>
                </div>
              )}
            </div>
            <div className="screenlet">
              <div className="screenlet-title-bar">
                <h3>Attach Products or Product Catagories</h3>
              </div>
              <div className="screenlet-body">
                <span className="label">ProductId: </span>
                <input
                  onChange={(e) => this.handleChange(e)}
                  maxLength="30"
                  value={this.state.productId}
                  onChange={(e) => this.setState({ productId: e.target.value })}
                  name="productId"
                  size="25"
                  type="text"
                />
                <input
                  type="submit"
                  value="Add"
                  name="productId"
                  onClick={(e) => this.addAttachment(e)}
                />
                <span className="label">CategoryId: </span>
                <input
                  onChange={(e) => this.handleChange(e)}
                  maxLength="30"
                  value={this.state.categoryId}
                  onChange={(e) =>
                    this.setState({ categoryId: e.target.value })
                  }
                  name="categoryId"
                  size="25"
                  type="text"
                />
                <input
                  type="submit"
                  value="Add"
                  name="categroyId"
                  onClick={(e) => this.addAttachment(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      ruleTitle: "",
      conditionType: "",
      condition: "",
      conditionValue: "",
      other: "",
      action: [],
      shipmentMethodsTypeId: "",
      ruleConditions: [],
      actionType: "",
      actionQuantity: "",
      amount: "",
      productId: "",
      categoryId: "",
    };
  }
  addAttachment(e) {
    let type = e.target.name;
    const promoRules = Array.from(this.props?.promoRules);
    const ruleId = promoRules[0]._id;
    if (type == "productId") {
      this.props.createRuleAttachments({
        ruleId,
        productId: this.state.productId,
      });
    } else {
      this.props.createRuleAttachments({
        ruleId,
        categoryId: this.state.categoryId,
      });
    }
    this.setState({ productId: "", categoryId: "" });
  }
  handleCondition(e) {
    e.preventDefault();
    const promoRules = Array.from(this.props?.promoRules);
    const ruleId = promoRules[0]._id;
    let data = [];
    const {
      conditionType,
      condition,
      conditionValue,
      other,
      shipmentMethodsTypeId,
    } = this.state;
    data = shipmentMethodsTypeId
      ? { ...data, shipmentMethodsTypeId: shipmentMethodsTypeId }
      : data;
    data = other ? { ...data, other: other } : data;
    data = { ...data, ruleId, conditionType, condition, conditionValue };
    this.props.createRuleConditions(data);
  }
  handleConditionUpdate(e) {
    e.preventDefault();
    let index = e.target.id;
    let data = this.state.ruleConditions[index];

    this.props.updateRuleConditions(data);
  }
  handleActionUpdate(e) {
    e.preventDefault();
    let data = this.state.action;
    this.props.updatePromoRule(data);
  }
  handleRuleConditionChange(e) {
    e.preventDefault();
    let value = e.target.value;
    let name = e.target.name;
    let index = e.target.id;
    let change = this.state.ruleConditions[index];
    change = { ...change, [name]: value };
    let updatedRuleConditions = [];
    for (let i = 0; i < this.state.ruleConditions.length; i++) {
      if (i == index) {
        updatedRuleConditions.push(change);
      } else {
        updatedRuleConditions.push(this.state.ruleConditions[i]);
      }
    }
    this.setState({ ruleConditions: updatedRuleConditions });
  }
  handleActionChange(e) {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    let change = this.state.action;
    change = { ...change, [name]: value };
    this.setState({ action: change });
  }
  createRule(e) {
    e.preventDefault();
    const query = queryString.parse(this.props.location.search);
    const promoId = query.promoId;
    const promoRules = Array.from(this.props?.promoRules);
    let data = [];
    if (promoRules[0]) {
      data = { _id: promoRules[0]._id, title: this.state.ruleTitle };
      this.props.updatePromoRule(data);
    } else {
      data = { promoId: promoId, title: this.state.ruleTitle };
      this.props.createPromoRules(data);
    }
  }
  handleAction(e) {
    e.preventDefault();
    const promoRules = Array.from(this.props?.promoRules);
    const ruleId = promoRules[0]._id;
    let data = [];
    const { actionType, actionQuantity, amount } = this.state;
    data = { _id: ruleId, actionType, actionQuantity, amount };
    this.props.updatePromoRule(data);
  }
  async componentDidMount() {
    await this.props.allActiveShipmentMethodsTypes();
    const query = queryString.parse(this.props.location.search);
    const promoId = query.promoId;
    await this.props.getAllActivePromoRules(promoId);

    const promoRules = Array.from(this.props?.promoRules);
    if (promoRules) {
      await this.props.getAllActiveRuleConditions(promoRules[0]?._id);
    }
    const ruleConditions = Array.from(this.props?.ruleConditions);
    if (ruleConditions) {
      this.setState({ ruleConditions });
    }

    // ruleConditions.map((condition)=>{
    //     this.setState({ruleCondition:{...ruleConditions,condition}});
    // })
    if (promoRules[0]) {
      await this.setState({
        action: {
          _id: promoRules[0]._id,
          actionType: promoRules[0].actionType,
          actionQuantity: promoRules[0].actionQuantity,
          amount: promoRules[0].amount,
        },
      });
      this.setState({ ruleTitle: promoRules[0]?.title });
    }
  }
  async componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.ruleConditions.length > this.state.ruleConditions.length) {
      this.setState({ ruleConditions: nextProps.ruleConditions });
    }
    if (nextProps.promoRules[0]?.actionType) {
      await this.setState({
        action: {
          _id: nextProps.promoRules[0]._id,
          actionType: nextProps.promoRules[0].actionType,
          actionQuantity: nextProps.promoRules[0].actionQuantity,
          amount: nextProps.promoRules[0].amount,
        },
      });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
}

const mapStateToProps = (state) => ({
  features: state?.catalog?.catalogs?.features,
  shipmentMethods:
    state?.catalog?.shipmentMethodTypesReducer?.shipment_method_types,
  promoRules: state?.catalog?.promoRules?.promoRulesList,
  ruleConditions: state?.catalog?.ruleConditions?.ruleConditionList,
});

const mapDispatchToProps = (dispatch, router) => ({
  allActiveShipmentMethodsTypes: () =>
    dispatch(allActiveShipmentMethodsTypes()),
  createPromoRules: (data) => dispatch(createPromoRules(data)),
  getAllActivePromoRules: (id) => dispatch(getAllActivePromoRules(id)),
  createRuleConditions: (data) => dispatch(createRuleConditions(data)),
  updatePromoRule: (data) => dispatch(updatePromoRule(data)),
  getAllActiveRuleConditions: (id) => dispatch(getAllActiveRuleConditions(id)),
  updateRuleConditions: (data) => dispatch(updateRuleConditions(data)),
  createRuleAttachments: (data) => dispatch(createRuleAttachments(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductRules)
);
