import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import '../../styles.scss';

class CreditCard extends Component {

    render() {
        const {userById, apiAddresses} = this.props;
        const query = new URLSearchParams(this.props?.location?.search);
        const partyId = query.get('partyId');

        return (
            <div id="searchOptions" className="screenlet" style={{marginTop: '10px'}}>
                <div className="screenlet-title-bar">
                    <ul>
                        <li className="h3">Add New Credit Card</li>
                    </ul>
                </div>

                <div id="searchOptions_col" className="screenlet-body">
                    <div id="search-options">
                        <form>
                            <table cellSpacing="0" className='basic-table'>
                                <tbody>
                                <tr>
                                    <td>
                                        <Link className="buttontext create"
                                              to={`viewprofile?partyId=${partyId}&_id=${userById?._id}`}>Cancel/Done</Link> {' '}
                                        <button type='submit' className='buttontext create'>
                                            Save
                                        </button>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td className='label'>
                                        <label>Company Name Card</label>
                                    </td>
                                    <td>
                                        <input type='text' className='custom-input-width'/>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td className='label'>
                                        <label>Prefix Card</label>
                                    </td>
                                    <td>
                                        <select>
                                            <option> --Select one--</option>
                                            <option value='mr'>Mr.</option>
                                            <option value='mrs'>Mrs.</option>
                                            <option value='ms'>Ms.</option>
                                            <option value='dr'>Dr.</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td className='label'>
                                        <label>First Name Card</label>
                                    </td>
                                    <td>
                                        <input type='text'/> <span className='required-text'> Required </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td className='label'>
                                        <label>Middle Name Card</label>
                                    </td>
                                    <td>
                                        <input type='text'/>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td className='label'>
                                        <label>Last Name Card</label>
                                    </td>
                                    <td>
                                        <input type='text'/> <span className='required-text'> Required </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td className='label'>
                                        <label>Suffix on Card</label>
                                    </td>
                                    <td>
                                        <select>
                                            <option> --Select one--</option>
                                            <option value='jr'>Jr.</option>
                                            <option value='sr'>Sr.</option>
                                            <option value='i'>I</option>
                                            <option value='ii'>II</option>
                                            <option value='iii'>III</option>
                                            <option value='iv'>IV</option>
                                            <option value='v'>V</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td className='label'>
                                        <label>Card Type</label>
                                    </td>
                                    <td>
                                        <select>
                                            <option> --Select one--</option>
                                            <option value="CCT_AMERICANEXPRESS">AmericanExpress</option>
                                            <option value="CCT_CARTEBLANCHE">CarteBlanche</option>
                                            <option value="CCT_DINERSCLUB">DinersClub</option>
                                            <option value="CCT_DISCOVER">Discover</option>
                                            <option value="CCT_ENROUTE">EnRoute</option>
                                            <option value="CCT_JCB">JCB</option>
                                            <option value="CCT_MASTERCARD">MasterCard</option>
                                            <option value="CCT_SOLO">Solo</option>
                                            <option value="CCT_SWITCH">Switch</option>
                                            <option value="CCT_UATP">UATP</option>
                                            <option value="CCT_VISA">Visa</option>
                                            <option value="CCT_VISAELECTRON">VisaElectron</option>
                                        </select>
                                        <span className='required-text'>Required</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td className='label'>
                                        <label>Card Number</label>
                                    </td>
                                    <td>
                                        <input type='text'/> <span className='required-text'> Required </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td className='label'>
                                        <label>Expiration Date</label>
                                    </td>
                                    <td>
                                        <select>
                                            <option></option>
                                            <option value="01">01</option>
                                            <option value="02">02</option>
                                            <option value="03">03</option>
                                            <option value="04">04</option>
                                            <option value="05">05</option>
                                            <option value="06">06</option>
                                            <option value="07">07</option>
                                            <option value="08">08</option>
                                            <option value="09">09</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select> {' '}

                                        <select>
                                            <option></option>
                                            <option value="2020">2020</option>
                                            <option value="2021">2021</option>
                                            <option value="2022">2022</option>
                                            <option value="2023">2023</option>
                                            <option value="2024">2024</option>
                                            <option value="2025">2025</option>
                                            <option value="2026">2026</option>
                                            <option value="2027">2027</option>
                                            <option value="2028">2028</option>
                                            <option value="2029">2029</option>
                                            <option value="2030">2030</option>
                                        </select>
                                        <span className='required-text'> Required </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td className='label'>
                                        <label>Decription</label>
                                    </td>
                                    <td>
                                        <input type='text'/>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td className='label'>
                                        <label>Billing Address</label>
                                    </td>
                                    <td>
                                        {
                                            apiAddresses.map((addr) => {
                                                return (
                                                    <div>
                                                        <input type="radio" name="address" id={addr._id}
                                                               value={addr._id}/>
                                                        <label htmlFor={addr._id}>
                                                            <span style={{marginLeft: '6px'}}>
                                                            <span style={{fontWeight: 'bold'}}>Shipping Destination Address</span>
                                                            <p>{addr.name}
                                                                <br/> {addr.recipientAddress}, {addr.recipientCity}</p>
                                                            <p>{addr.phone}</p>
                                                            (Created: {new Date(addr.createdDate).toLocaleString()})
                                                            </span>
                                                        </label>
                                                    </div>
                                                );
                                            })
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Link className="buttontext create"
                                              to={`viewprofile?partyId=${partyId}&_id=${userById?._id}`}>Cancel/Done</Link> {' '}
                                        <button type='submit' className='buttontext create'>
                                            Save
                                        </button>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                </div>

            </div>
        );

    }

}


const mapStateToProps = (state) => ({
    userById: state.partyManager?.users?.userById,
    apiAddresses: state.partyManager?.users?.apiAddresses
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CreditCard);
