import React, { Component } from "react";
import { Notify } from "../../../../constants/utils";

import "../../styles.scss";

import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  createPromoCodes,
  getAllActivePromoCodes,
  updatePromoCodes,
  getPromoCode,
  createBulkPromoCodes,
} from "../../../../store/actions/catalogActions";
import queryString from "query-string";
import XLSX from "xlsx";

class PromoCodes extends Component {
  render() {
    const query = queryString.parse(this.props.location.search);

    const promoId = query.promoId;
    const promoCodesList = Array.from(this.props?.promoCodesList);
    return (
      <div id="content-main-section" className="leftonly">
        <div id="centerdiv" className="no-clear">
          <div>
            <div>
              <ul style={{ display: "flex", listStyle: "none" }}>
                <a
                  href={"/catalog/control/EditProductPromo?promoId=" + promoId}
                  style={{ textDecoration: "none" }}
                >
                  <li className="featureLi">Promotion</li>
                </a>
                <a
                  href={"/catalog/control/PromoRules?promoId=" + promoId}
                  style={{ textDecoration: "none" }}
                >
                  <li className="featureLi">Rules</li>
                </a>
                <a
                  href={"/catalog/control/PromoStores?promoId=" + promoId}
                  style={{ textDecoration: "none" }}
                >
                  <li className="featureLi">Stores</li>
                </a>
                <a
                  href={"/catalog/control/PromoCodes?promoId=" + promoId}
                  style={{ textDecoration: "none" }}
                >
                  <li className="featureLi">Promotion Code</li>
                </a>
                <a
                  href={"/catalog/control/Content?promoId=" + promoId}
                  style={{ textDecoration: "none" }}
                >
                  <li className="featureLi">Content</li>
                </a>
              </ul>
            </div>
            <div>
              <tbody>
                <tr>
                  <button className="h3">
                    <a
                      style={{ textDecoration: "none" }}
                      href="/catalog/control/NewProductPromo"
                    >
                      New Product Promo
                    </a>
                  </button>
                </tr>
                <tr>
                  <h1>
                    Permotion Code for Flat 15% off on minimum purchase[ID:
                    21111]
                  </h1>
                </tr>
                <tr>
                  <button className="h3">
                    <a
                      style={{ textDecoration: "none" }}
                      href="/catalog/control/PromotionCodeEdit"
                    >
                      New Promotion Code
                    </a>
                  </button>
                </tr>
              </tbody>
            </div>

            <div className="screenlet">
              <div className="screenlet-title-bar">
                <h3>Find Product Promotion Code</h3>
              </div>
              <div className="screenlet-body">
                <div className="nav-pager">
                  <ul>
                    <li className="nav-first-disabled">First</li>
                    <li className="nav-previous-disabled">Previous</li>
                    <li className="nav-page-select">
                      Page
                      <select
                        name="page"
                        size="1"
                        onChange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1=200&amp;VIEW_INDEX_1='+this.value)"
                      >
                        <option selected="selected" value="0">
                          1
                        </option>
                      </select>
                    </li>
                    <li className="nav-next-disabled">Next</li>
                    <li className="nav-last-disabled">Last</li>
                    <li className="nav-pagesize">
                      <select
                        name="pageSize"
                        size="1"
                        onChange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')"
                      >
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option selected="selected" value="200">
                          200
                        </option>
                      </select>
                      Items per page
                    </li>
                    <li className="nav-displaying">Displaying 1 - 23 of 23</li>
                  </ul>
                </div>
                <form>
                  <input name="_useRowSubmit" type="hidden" value="Y" />{" "}
                  <input name="_checkGlobalScope" type="hidden" value="Y" />{" "}
                  <input name="productId" type="hidden" value="CAKE100" />
                  <table className="basic-table" cellSpacing="0">
                    <tbody>
                      <tr className="header-row">
                        <td>
                          <strong>Product Promo Code Id</strong>
                        </td>
                        <td>
                          <strong>User Entered</strong>
                        </td>
                        <td>
                          <strong>Require Email Or Party</strong>
                        </td>
                        <td>
                          <strong>Use Limit Per Code</strong>
                        </td>
                        <td>
                          <strong>Use Limit Per Customer</strong>
                        </td>
                        <td>
                          <strong>From Date</strong>
                        </td>
                        <td>
                          <strong>Through Date</strong>
                        </td>
                        <td>
                          <strong>Created By User</strong>
                        </td>
                        <td></td>
                      </tr>

                      {promoCodesList &&
                        promoCodesList?.map((promoCodesListItem) => (
                          <tr>
                            <td>
                              <Link
                                to={
                                  "/catalog/control/PromotionCodeEdit?promoCodeId:" +
                                  promoCodesListItem.promoCodeId
                                }
                                title={promoCodesListItem.promoCodeId}
                              >
                                {promoCodesListItem.promoCodeId}
                              </Link>
                            </td>
                            <td>{promoCodesListItem.userEntered}</td>
                            <td>{promoCodesListItem.requireEmailOrParty}</td>
                            <td>{promoCodesListItem.useLimitPerCode}</td>
                            <td>{promoCodesListItem.useLimitPerCustomer}</td>
                            <td>{promoCodesListItem.fromDate}</td>
                            <td>{promoCodesListItem.throughDate}</td>
                            <td></td>
                            <td>
                              <a
                                className="buttontext"
                                href
                                // onClick={(e) => this.handleRemove(e, slot._id)}
                              >
                                Remove
                              </a>
                            </td>
                            <td></td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </form>
                <div className="nav-pager">
                  <ul>
                    <li className="nav-first-disabled">First</li>
                    <li className="nav-previous-disabled">Previous</li>
                    <li className="nav-page-select">
                      Page
                      <select
                        name="page"
                        size="1"
                        onChange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1=200&amp;VIEW_INDEX_1='+this.value)"
                      >
                        <option selected="selected" value="0">
                          1
                        </option>
                      </select>
                    </li>
                    <li className="nav-next-disabled">Next</li>
                    <li className="nav-last-disabled">Last</li>
                    <li className="nav-pagesize">
                      <select
                        name="pageSize"
                        size="1"
                        onChange="submitPagination(this, '/catalog/control/ListShipmentMethodTypes?VIEW_SIZE_1='+this.value+'&amp;VIEW_INDEX_1=0')"
                      >
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option selected="selected" value="200">
                          200
                        </option>
                      </select>
                      Items per page
                    </li>
                    <li className="nav-displaying">Displaying 1 - 23 of 23</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="screenlet">
              <div className="screenlet-title-bar">
                <h3>Upload Set of Promotion Codes</h3>
              </div>
              <div className="screenlet-body">
                <form>
                  <span className="label">User Entered</span>
                  <select
                    name="actionType"
                    value={this.state.userEntered}
                    onChange={(e) =>
                      this.setState({ userEntered: e.target.value })
                    }
                    size="1"
                  >
                    <option selected="selected" value={1}>
                      Y
                    </option>
                    <option value={0}>N</option>
                  </select>
                  <span className="label">Req. Email or PartyId</span>
                  <select
                    name="actionType"
                    size="1"
                    value={this.state.requireEmailOrParty}
                    onChange={(e) =>
                      this.setState({ requireEmailOrParty: e.target.value })
                    }
                  >
                    <option value={1}>Y</option>
                    <option selected="selected" value={0}>
                      N
                    </option>
                  </select>
                  <span className="label">Use Limit per Code</span>
                  <input
                    onChange={(e) =>
                      this.setState({ useLimitPerCode: e.target.value })
                    }
                    maxLength="30"
                    value={this.state.useLimitPerCode}
                    name="actionQuantity"
                    size="25"
                    type="text"
                  />

                  <span className="label">Per Customer</span>
                  <input
                    onChange={(e) =>
                      this.setState({ useLimitPerCustomer: e.target.value })
                    }
                    maxLength="30"
                    value={this.state.useLimitPerCustomer}
                    name="actionQuantity"
                    size="25"
                    type="text"
                  />

                  <br />
                  <div>
                    <input
                      type="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={this.inputHandler}
                    />

                    <button type="button" onClick={this.uploadProducts}>
                      Upload
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      featureId: "",
      type: "",
      userEntered: 1,
      requireEmailOrParty: 0,
      useLimitPerCode: "",
      useLimitPerCustomer: "",
      productId: "",
      fromDate: "",
      throughDate: "",
      file: "",
      relation: "dependent feature",
    };
  }
  uploadProducts = () => {
    if (this.state.file) {
      var name = this.state.file.name;
      const reader = new FileReader();
      reader.onload = (evt) => {
        // evt = on_file_select event
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

        //removing the title row from rows of products
        data.shift();
        let dataObject = [];
        let placeHolder = [];
        //mapping values to keys and creating an array of arrays
        for (var i = 0; i < data.length; i++) {
          placeHolder = {
            promoCodeId: data[i][0],
            promoId: data[i][1],
            fromDate: data[i][2],
            throughDate: data[i][3],
          };
          placeHolder = {
            ...placeHolder,
            userEntered: this.state.userEntered,
            requireEmailOrParty: this.state.requireEmailOrParty,
            useLimitPerCode: this.state.useLimitPerCode,
            useLimitPerCustomer: this.state.useLimitPerCustomer,
          };
          dataObject.push(placeHolder);
        }
        this.props.createBulkPromoCodes(dataObject);
      };
      reader.readAsBinaryString(this.state.file);
    } else {
      Notify("danger", "Please select a file", "Error");
    }
  };

  async componentDidMount() {
    const query = queryString.parse(this.props.location.search);

    const promo = query.promoId;
    await this.props.getAllActivePromoCodes(promo);
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };
  inputHandler = (event) => {
    const file = event.target.files[0];
    const fileType = file.type;

    if (
      fileType !== ".csv" &&
      fileType !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      fileType !== "application/vnd.ms-excel"
    ) {
      Notify("danger", "File format is not supported", "Error");
      this.setState({ file: "" });
    } else {
      this.setState({ file });
    }
  };
}

const mapStateToProps = (state) => ({
  promoCodesList: state?.catalog?.promoCodesReducer.promoCodesList,
});

const mapDispatchToProps = (dispatch, router) => ({
  createPromoCodes: (data) => dispatch(createPromoCodes(data)),
  getAllActivePromoCodes: (data) => dispatch(getAllActivePromoCodes(data)),
  updatePromoCodes: (data) => dispatch(updatePromoCodes(data)),
  createBulkPromoCodes: (data) => dispatch(createBulkPromoCodes(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PromoCodes)
);
