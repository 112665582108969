import React, { Component } from "react";
import { connect } from "react-redux";
import {
  createCategories,
  getCategory,
  allActiveCatalogs,
  updateCategory,
} from "../../../../store/actions/catalogActions";
import { Notify } from "../../../../constants/utils";
import {
  getRequest,
  putRequest,
  postRequest,
} from "../../../../store/services";
import CategoryPopUp from "./categoryPopUp";
export class EditCategory extends Component {
  inputHandler = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const fileType = file.type;

    if (
      fileType !== "image/jpeg" &&
      fileType !== "image/png" &&
      fileType !== "image/webp"
    ) {
      this.setState({ file: "" });
      Notify("danger", "Invalid file type.", "Error");
    } else {
      this.setState({ file: file });
    }
  };
  inputHandlerMobile = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const fileType = file.type;

    if (
      fileType !== "image/jpeg" &&
      fileType !== "image/png" &&
      fileType !== "image/webp"
    ) {
      this.setState({ file: "" });
      Notify("danger", "Invalid file type.", "Error");
    } else {
      this.setState({ fileMobile: file });
    }
  };
  uploadPhoto = async () => {
    if (this.state.file !== "") {
      let formData = new FormData();
      formData.append("image", this.state.file);
      let image = await postRequest("cart/uploadImage", formData);
      let location = image.data;
      if (location) {
        this.setState({ imageURL: location, file: "" });
        this.state.inputRef.current.value = "";
      }
    } else {
      Notify("danger", "Please Select a file.", "Error");
    }
  };
  uploadPhotoMobile = async () => {
    if (this.state.fileMobile !== "") {
      let formData = new FormData();
      formData.append("image", this.state.fileMobile);
      let image = await postRequest("cart/uploadImage", formData);
      let location = image.data;
      if (location) {
        this.setState({ linkOneImageURL: location, fileMobile: "" });
        this.state.inputRefMobile.current.value = "";
      }
    } else {
      Notify("danger", "Please Select a file.", "Error");
    }
  };
  render() {
    const {
      _id,
      mode,
      type,
      catalog,
      name,
      sequence,
      urlName,
      slug,
      description,
      imageURL,
      linkOneImageURL,
      alternativeName,
      alternativeCanonical,
      detailScreen,
      parentCategory,
      childCategoryId,
      allProducts,
      childSequence,
      childName,
      categoryTitle,
      url,
    } = this.state;
    const { submitted, catalogs } = this.props;
    return (
      <div id="content-main-section" className="leftonly">
        <div id="centerdiv" className="no-clear">
          <div className="screenlet">
            <div class="button-bar tab-bar">
              <ul>
                <li>
                  <ul>
                    <li>
                      <a
                        href={
                          "/catalog/control/EditCategory?productCategoryId=" +
                          this.state._id
                        }
                      >
                        Category
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          "/catalog/control/ProductSequencing?productCategoryId=" +
                          this.state._id
                        }
                      >
                        Product Sequencing
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          "/catalog/control/Contents?productCategoryId=" +
                          this.state._id
                        }
                      >
                        Contents
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="screenlet-title-bar">
              <ul>
                <li className="h3">Create Product Category</li>
              </ul>
            </div>
            <CategoryPopUp
              handleCategorySelect={this.handleCategorySelect}
              handleClose={this.handleClose}
              modalOpen={this.state.modalOpen}
            />
            <div className="screenlet-body">
              <form onSubmit={this.submitForm}>
                <table className="basic-table" cellSpacing="0">
                  <tbody>
                    {/* <tr>
                      <td className="label" align="right">
                        Product Category ID
                      </td>
                      <td></td>
                      <td>
                        <input
                          maxLength="40"
                          name="productCategoryId"
                          size="20"
                          type="text"
                          value={_id}
                        />
                      </td>
                    </tr> */}
                    {/*<tr>*/}
                    {/*  <td className="label" align="right" width="26%">*/}
                    {/*    Product Category Type*/}
                    {/*  </td>*/}
                    {/*  <td></td>*/}
                    {/*  <td width="74%">*/}
                    {/*    <select*/}
                    {/*      size="1"*/}
                    {/*      value={type}*/}
                    {/*      class="required"*/}
                    {/*      name="type"*/}
                    {/*      onChange={this.handleChange}*/}
                    {/*    >*/}
                    {/*      <option value={""}>---Select one---</option>*/}
                    {/*      <option value="Other">Other</option>*/}
                    {/*      <option value={"Others"}>Others</option>*/}
                    {/*      <option value={"Souvenir Gifts"}>Souvenir Gifts</option>*/}
                    {/*      <option value={"Food Gifts"}>Food Gifts</option>*/}
                    {/*      <option value={"Gift an Experience"}>Gift an Experience</option>*/}
                    {/*      <option value="By Type">By Type</option>*/}
                    {/*      <option value={"Other Cakes"}>Other Cakes</option>*/}
                    {/*      <option value={"Other Combos"}>Other Combos</option>*/}
                    {/*      <option value={"By Flavour"}>By Flavour</option>*/}
                    {/*      <option value={"By Flower Category"}>By Flower Category</option>*/}
                    {/*      <option value={"By Feature"}>By Feature</option>*/}
                    {/*      <option value={"Flower By Colour"}>Flower By Colour</option>*/}
                    {/*      <option value={"Flower Combos"}>Flower Combos</option>*/}
                    {/*      <option value={"Shop By Occasion"}>Shop By Occasion</option>*/}
                    {/*      <option value={"Regular Occasions"}>Regular Occasions</option>*/}
                    {/*      <option value={"Shop by Recipient"}>Shop By Recipient</option>*/}
                    {/*      <option value={"Shop By Product Type"}>Shop By Product Type</option>*/}
                    {/*      <option value={"Shop By Flower Type"}>Shop By Flower Type</option>*/}
                    {/*      <option value={"Premium Services"}>Premium Services</option>*/}
                    {/*      <option value={"Birthday Cakes"}>Birthday Cakes</option>*/}
                    {/*      <option value={"Anniversary Cakes"}>Anniversary Cakes</option>*/}
                    {/*      <option calue="By City">By City</option>*/}
                    {/*      <option value="By Relation">By Relation</option>*/}
                    {/*      <option value="By Occasion">By Occasion</option>*/}
                    {/*      <option value="ADDON_CATEGORY">Addon Category</option>*/}
                    {/*      <option value="BEST_SELL_CATEGORY">*/}
                    {/*        Best Selling*/}
                    {/*      </option>*/}
                    {/*      <option value="CATALOG_CATEGORY">Catalog</option>*/}
                    {/*      <option value="CROSS_SELL_CATEGORY">*/}
                    {/*        Cross Sell*/}
                    {/*      </option>*/}
                    {/*      <option value="EBAY_CATEGORY">eBay</option>*/}
                    {/*      <option value="GIFT_CARD_CATEGORY">Gift Cards</option>*/}
                    {/*      <option value="GOOGLE_BASE_CATEGORY">*/}
                    {/*        Google Base*/}
                    {/*      </option>*/}
                    {/*      <option value="INDUSTRY_CATEGORY">Industry</option>*/}
                    {/*      <option value="INTERNAL_CATEGORY">Internal</option>*/}
                    {/*      <option value="MATERIALS_CATEGORY">Materials</option>*/}
                    {/*      <option value="MIXMATCH_CATEGORY">*/}
                    {/*        Mix and Match*/}
                    {/*      </option>*/}
                    {/*      <option value="PRODUCT_TYPE">Product Type</option>*/}
                    {/*      <option value="QUICKADD_CATEGORY">Quick Add</option>*/}
                    {/*      <option value="SEARCH_CATEGORY">Search</option>*/}
                    {/*      <option value="TAX_CATEGORY">Tax</option>*/}
                    {/*      <option value="USAGE_CATEGORY">Usage</option>*/}
                    {/*    </select>*/}
                    {/*  </td>*/}
                    {/*</tr>*/}
                    <tr>
                      <td className="label" align="right" width="26%">
                        Product Catalog
                      </td>
                      <td></td>
                      <td width="74%">
                        <select
                          name="catalog"
                          value={catalog}
                          class="required"
                          onChange={this.handleChange}
                        >
                          <option>---Select one---</option>
                          {catalogs?.map((catalog) => {
                            return (
                              <option value={catalog._id}>
                                {catalog.name}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td className="label" align="right" width="26%">
                        Product Category URL Name
                      </td>
                      <td></td>
                      <td width="74%">
                        <input
                          maxLength="60"
                          name="urlName"
                          size="60"
                          class="required"
                          type="text"
                          value={urlName}
                          onChange={this.handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label" align="right" width="26%">
                        Product Category Name
                      </td>
                      <td></td>
                      <td width="74%">
                        <input
                          maxLength="60"
                          name="name"
                          size="60"
                          class="required"
                          type="text"
                          value={name}
                          onChange={this.handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label" align="right" width="26%">
                        Slug
                      </td>
                      <td></td>
                      <td width="74%">
                        <input
                          maxLength="60"
                          name="slug"
                          size="60"
                          class="required"
                          type="text"
                          value={slug}
                          onChange={this.handleChange}
                        />
                      </td>
                    </tr>
                    {/*<tr>*/}
                    {/*  <td className="label" align="right" width="26%">*/}
                    {/*    URL*/}
                    {/*  </td>*/}
                    {/*  <td></td>*/}
                    {/*  <td width="74%">*/}
                    {/*    <input*/}
                    {/*        maxLength="60"*/}
                    {/*        name="url"*/}
                    {/*        size="60"*/}
                    {/*        className="required"*/}
                    {/*        type="text"*/}
                    {/*        value={url}*/}
                    {/*        onChange={this.handleChange}*/}
                    {/*    />*/}
                    {/*  </td>*/}
                    {/*</tr>*/}
                    <tr>
                      <td className="label" align="right" width="26%">
                        Sequence
                      </td>
                      <td></td>
                      <td width="74%">
                        <input
                          maxLength="60"
                          name="sequence"
                          size="5"
                          className="required"
                          type="number"
                          value={sequence}
                          onChange={this.handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label" align="right" width="26%">
                        Category Title
                      </td>
                      <td></td>
                      <td width="74%">
                        <input
                          type={"text"}
                          name="categoryTitle"
                          value={categoryTitle}
                          onChange={this.handleChange}
                        ></input>
                      </td>
                    </tr>
                    <tr>
                      <td className="label" align="right" width="26%">
                        Product Category Description
                      </td>
                      <td></td>
                      <td width="74%">
                        <textarea
                          cols="60"
                          name="description"
                          rows="2"
                          value={description}
                          onChange={this.handleChange}
                        ></textarea>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="label"
                        align="right"
                        valign="top"
                        width="20%"
                      >
                        Category Image URL
                      </td>
                      <td></td>
                      <td colSpan="4" valign="top" width="80%">
                        <input
                          maxLength="255"
                          name="imageURL"
                          size="60"
                          type="text"
                          value={imageURL}
                          onChange={this.handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="label"
                        align="right"
                        valign="top"
                        width="20%"
                      >
                        Link One Image URL
                      </td>
                      <td></td>
                      <td colSpan="4" valign="top" width="80%">
                        <input
                          maxLength="255"
                          name="linkOneImageURL"
                          size="60"
                          type="text"
                          value={linkOneImageURL}
                          onChange={this.handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="label"
                        align="right"
                        valign="top"
                        width="20%"
                      >
                        Alternative Name
                      </td>
                      <td></td>
                      <td>
                        <input
                          maxLength="255"
                          name="alternativeName"
                          size="60"
                          type="text"
                          value={alternativeName}
                          onChange={this.handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="label"
                        align="right"
                        valign="top"
                        width="20%"
                      >
                        Alternative Canonical
                      </td>
                      <td></td>
                      <td>
                        <input
                          maxLength="255"
                          name="alternativeCanonical"
                          size="60"
                          type="text"
                          value={alternativeCanonical}
                          onChange={this.handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label" align="right" width="26%">
                        Detail Screen
                      </td>
                      <td></td>
                      <td width="74%">
                        <input
                          maxLength="250"
                          name="detailScreen"
                          size="60"
                          type="text"
                          value={detailScreen}
                          onChange={this.handleChange}
                        />
                        <br />
                        <span className="tooltip">
                          Defaults to "categorydetail", for screens in other
                          files use something like:
                          "component://ecommerce/widget/CatalogScreens.xml#categorydetail"
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="label" align="right" width="26%">
                        CategoryId for all
                      </td>
                      <td></td>
                      <td width="74%">
                        <input
                          maxLength="10"
                          name="allProducts"
                          size="60"
                          type="number"
                          value={allProducts}
                          onChange={this.handleChange}
                        />
                        <br />
                        <span className="tooltip">
                          Defaults to "categorydetail", for screens in other
                          files use something like:
                          "component://ecommerce/widget/CatalogScreens.xml#categorydetail"
                        </span>
                      </td>
                    </tr>
                    {/*<tr>*/}
                    {/*  <td className="label" align="right" width="26%">*/}
                    {/*    Primary Parent Category*/}
                    {/*  </td>*/}
                    {/*  <td></td>*/}
                    {/*  <td width="74%">*/}
                    {/*    <div id="3_lookupId_primaryParentCategoryId_auto">*/}
                    {/*      {" "}*/}
                    {/*    </div>*/}
                    {/*    <span className="field-lookup">*/}
                    {/*      <input*/}
                    {/*        id="3_lookupId_primaryParentCategoryId"*/}
                    {/*        className="ui-autocomplete-input"*/}
                    {/*        autoComplete="off"*/}
                    {/*        maxLength="90"*/}
                    {/*        name="parentCategory"*/}
                    {/*        size="60"*/}
                    {/*        type="text"*/}
                    {/*        value={parentCategory}*/}
                    {/*        onChange={this.handleChange}*/}
                    {/*      />*/}
                    {/*      <a*/}
                    {/*        id="3_lookupId_button"*/}
                    {/*        // href="/"*/}
                    {/*        style={{ cursor: "pointer" }}*/}
                    {/*        onClick={() => this.setState({ modalOpen: true })}*/}
                    {/*      >*/}
                    {/*        {" "}*/}
                    {/*      </a>*/}
                    {/*    </span>*/}
                    {/*  </td>*/}
                    {/*</tr>*/}
                    <tr>
                      <td className="label" align="right" width="26%">
                        Add Child Categories
                      </td>
                      <td></td>
                      <input
                        maxLength="250"
                        name="childCategoryId"
                        size="10"
                        type="number"
                        placeholder={"Child Category Id"}
                        value={childCategoryId}
                        onChange={this.handleChange}
                      />
                      <input
                        maxLength="250"
                        name="childSequence"
                        size="10"
                        type="number"
                        placeholder={"Sequence"}
                        value={childSequence}
                        onChange={this.handleChange}
                      />
                      <select
                        size="1"
                        value={type}
                        className="required"
                        name="type"
                        onChange={this.handleChange}
                      >
                        <option value={""}>---Select one---</option>
                        <option value={"Flower Making Style"}>
                          Flower Making Style
                        </option>
                        <option value={"Gifts Selection"}>
                          Gifts Selection
                        </option>
                        <option value={"Combos Selection"}>
                          Combos Selection
                        </option>
                        <option value={"Personalised Gifts Selection"}>
                          Personalised Gifts Selection
                        </option>
                        <option value={"Chocolates Selection"}>
                          Chocolates Selection
                        </option>
                        <option value={"Plants Selection"}>
                          Plants Selection
                        </option>
                        <option value="Other">Other</option>
                        <option value={"Others"}>Others</option>
                        <option value={"Souvenir Gifts"}>Souvenir Gifts</option>
                        <option value={"Food Gifts"}>Food Gifts</option>
                        <option value={"Gift an Experience"}>
                          Gift an Experience
                        </option>
                        <option value="By Type">By Type</option>
                        <option value={"Other Cakes"}>Other Cakes</option>
                        <option value={"Other Combos"}>Other Combos</option>
                        <option value={"By Flavour"}>By Flavour</option>
                        <option value={"By Flower Category"}>
                          By Flower Category
                        </option>
                        <option value={"By Feature"}>By Feature</option>
                        <option value={"Flower By Colour"}>
                          Flower By Colour
                        </option>
                        <option value={"Flower Combos"}>Flower Combos</option>
                        <option value={"Shop By Occasion"}>
                          Shop By Occasion
                        </option>
                        <option value={"Regular Occasions"}>
                          Regular Occasions
                        </option>
                        <option value={"Shop By Recipient"}>
                          Shop By Recipient
                        </option>
                        <option value={"Shop By Product Type"}>
                          Shop By Product Type
                        </option>
                        <option value={"Shop By Flower Type"}>
                          Shop By Flower Type
                        </option>
                        <option value={"Premium Services"}>
                          Premium Services
                        </option>
                        <option value={"Birthday Cakes"}>Birthday Cakes</option>
                        <option value={"Anniversary Cakes"}>
                          Anniversary Cakes
                        </option>
                        <option calue="By City">By City</option>
                        <option value="By Relation">By Relation</option>
                        <option value="By Occasion">By Occasion</option>
                        <option value="ADDON_CATEGORY">Addon Category</option>
                        <option value="BEST_SELL_CATEGORY">Best Selling</option>
                        <option value="CATALOG_CATEGORY">Catalog</option>
                        <option value="CROSS_SELL_CATEGORY">Cross Sell</option>
                        <option value="EBAY_CATEGORY">eBay</option>
                        <option value="GIFT_CARD_CATEGORY">Gift Cards</option>
                        <option value="GOOGLE_BASE_CATEGORY">
                          Google Base
                        </option>
                        <option value="INDUSTRY_CATEGORY">Industry</option>
                        <option value="INTERNAL_CATEGORY">Internal</option>
                        <option value="MATERIALS_CATEGORY">Materials</option>
                        <option value="MIXMATCH_CATEGORY">Mix and Match</option>
                        <option value="PRODUCT_TYPE">Product Type</option>
                        <option value="QUICKADD_CATEGORY">Quick Add</option>
                        <option value="SEARCH_CATEGORY">Search</option>
                        <option value="TAX_CATEGORY">Tax</option>
                        <option value="USAGE_CATEGORY">Usage</option>
                      </select>
                      <input
                        maxLength="250"
                        name="childName"
                        size="10"
                        type="text"
                        placeholder={"Name for Child Category"}
                        value={childName}
                        onChange={this.handleChange}
                      />
                      <input
                        maxLength="250"
                        name="url"
                        size="10"
                        type="text"
                        placeholder={"Url For Child Category"}
                        value={this.state.url}
                        onChange={this.handleChange}
                      />
                      <span>
                        <button
                          type={"button"}
                          onClick={() => this.addChildCategory()}
                        >
                          Add
                        </button>{" "}
                      </span>
                    </tr>
                    <tr>
                      <td className="label" align="right" width="26%">
                        Child Categories
                      </td>
                      <td></td>
                      <td>
                        <ul>
                          <li style={{ display: "flex" }}>
                            <span style={{ width: "100px" }}>CategoryId</span>
                            <span style={{ width: "100px" }}>Sequence</span>
                            <span style={{ width: "100px" }}>Type</span>
                            <span style={{ width: "100px" }}>Name</span>
                            <span></span>
                          </li>
                          {this.state?.childCategoryArray?.map((cat) => {
                            return (
                              <li style={{ display: "flex" }}>
                                <span style={{ width: "100px" }}>
                                  {cat?._id}
                                </span>
                                <span style={{ width: "100px" }}>
                                  {cat?.childCategories?.sequence}
                                </span>
                                <span style={{ width: "100px" }}>
                                  {cat?.childCategories?.type}
                                </span>
                                <span style={{ width: "100px" }}>
                                  {cat?.childCategories?.name}
                                </span>
                                <span style={{ width: "100px" }}>
                                  <button
                                    type={"button"}
                                    onClick={() =>
                                      this.deleteChildCategory(cat?._id)
                                    }
                                  >
                                    Delete
                                  </button>
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2"></td>
                      <td>
                        <input
                          name="Create"
                          type="submit"
                          value={mode === "add" ? "Create" : "Update"}
                          // disabled={submitted}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
            <div className="screenlet-title-bar">
              <ul>
                <li className="h3">Upload Category Image</li>
              </ul>
            </div>
            <div className="screenlet-body">
              <input
                type="file"
                accept=""
                ref={this.state.inputRef}
                onChange={this.inputHandler}
              />
              <button type="button" onClick={this.uploadPhoto}>
                Upload
              </button>
            </div>
            {/*upload category image for mobile starts here*/}
            <div className="screenlet-title-bar">
              <ul>
                <li className="h3">Upload Category Image For Mobile</li>
              </ul>
            </div>
            <div className="screenlet-body">
              <input
                type="file"
                accept=""
                ref={this.state.inputRefMobile}
                onChange={this.inputHandlerMobile}
              />
              <button type="button" onClick={this.uploadPhotoMobile}>
                Upload
              </button>
            </div>
            {/*upload category image for mobile ends here*/}
          </div>
        </div>
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      apiCatalogs: [],
      editId: null,
      // form
      _id: "",
      type: "",
      catalog: "",
      name: "",
      description: "",
      imageURL: "",
      linkOneImageURL: "",
      alternativeName: "",
      alternativeCanonical: "",
      detailScreen: "",
      parentCategory: null,
      mode: "add",
      modalOpen: false,
      inputRef: React.createRef(),
      inputRefMobile: React.createRef(),
      childCategoryArray: [],
      file: "",
      fileMobile: "",
      show: "",
      urlName: "",
      slug: "",
      sequence: "",
      childCategoryId: "",
      allProducts: null,
      childSequence: "",
      childName: "",
      categoryTitle: "",
      url: "",
    };
  }
  deleteChildCategory = async (id) => {
    const url = new URLSearchParams(this.props?.location?.search);
    const category = url?.get("productCategoryId");
    let res = await putRequest(
      "categories/delete/" + id,
      { categoryId: category },
      true
    );
    this.setState({ childCategoryArray: res.data });
  };

  handleCategorySelect = (id) => {
    this.setState({ parentCategory: id, modalOpen: false });
  };
  handleClose = () => {
    this.setState({ modalOpen: false });
  };
  addChildCategory = async () => {
    if (!this.state.childCategoryId) {
      Notify("danger", "Please write a child category id", "Error");
      return;
    }
    if (!this.state.childSequence) {
      Notify("danger", "Please add sequence", "Error");
      return;
    }
    const url = new URLSearchParams(this.props?.location?.search);
    const category = url?.get("productCategoryId");
    let res = await postRequest(
      "categories/addChild",
      {
        url: this.state.url,
        parentCategoryId: category,
        childCategoryId: this.state.childCategoryId,
        sequence: this.state.childSequence,
        type: this.state.type,
        name: this.state.childName,
      },
      true
    );
    this.setState({
      childCategoryArray: res.data,
      childCategoryId: "",
      childSequence: "",
      type: "",
      childName: "",
      url: "",
    });
  };

  async componentDidMount() {
    this.props.allCatalogs();
    const url = new URLSearchParams(this.props?.location?.search);
    const category = url?.get("productCategoryId");
    if (category) {
      this.setState({ editId: category });
      var data = await this.props.categoryDetails(category, (data) => {
        if (data) {
          const {
            _id,
            type,
            catalog,
            name,
            sequence,
            description,
            imageURL,
            linkOneImageURL,
            alternativeName,
            alternativeCanonical,
            detailScreen,
            childCategoryArray,
            urlName,
            slug,
            allProducts,
            categoryTitle,
          } = data;

          this.setState({
            _id,
            type,
            catalog: catalog?._id,
            name,
            description,
            imageURL,
            sequence,
            linkOneImageURL,
            alternativeName,
            alternativeCanonical,
            detailScreen,
            // parentCategory: parentCategory?._id,
            childCategoryArray,
            urlName,
            slug,
            mode: "edit",
            allProducts,
            categoryTitle,
          });
        }
      });
      console.log("data==<", data);
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  submitForm = async (event) => {
    const data = this.state;
    data.slug = data.slug.trim();
    const mode = this.state.mode;
    const { catalog, name, slug, imageURL, linkOneImageURL, urlName } =
      this.state;
    if (!this.state.parentCategory) {
      delete data.parentCategory;
    }
    delete data.childCategoryArray;
    delete data.apiCatalogs;
    delete data.editId;
    // delete data.mode;
    delete data.inputRef;
    delete data.inputRefMobile;
    delete data.type;
    delete data.url;

    event.preventDefault();
    if (catalog === "" || name === "" || urlName === "" || slug === "") {
      Notify("danger", "Please Fill All the required Fields", "Error");
      return;
    }
    if (mode === "edit") {
      if (data.allProducts === "") {
        data.allProducts = null;
      }
      this.props.update(data, () => delete data.mode);
    } else {
      if (data.allProducts === "") {
        data.allProducts = null;
      }
      delete data._id;
      this.props.save(data, () => delete data.mode);
    }
  };
}

const mapStateToProps = (state) => ({
  token: state?.auth?.token,
  submitted: state.catalog?.categories?.submitted,
  catalogs: state.catalog?.catalogs?.active,
});

const mapDispatchToProps = (dispatch, router) => ({
  save: (data, callback) => dispatch(createCategories(data, router, callback)),
  categoryDetails: (id, cb) => dispatch(getCategory(id, cb)),
  allCatalogs: () => dispatch(allActiveCatalogs()),
  update: (data, callback) =>
    dispatch(updateCategory(data, router, true, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCategory);
