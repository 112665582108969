import React, { Component } from "react";
import { connect } from "react-redux";
import "../styles.scss";
import { withRouter } from "react-router-dom";
import { createOrders } from "../../../store/actions/orderActions";
class TestPaymentGateways extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      orderId: "",
      accessCode: "",
      merchantId: "",
      shaRequestPhrase: "",
      shaResponsePhrase: "",
      extra: "",
    };
  }
  render() {
    return (
      <div>
        <div
          style={{
            backgroundColor: "white",
            borderBottom: "2px solid LightGray",
          }}
        >
          <div className="screenlet-title-bar">
            <ul>
              <li className="h3">Create Payment for PayFort</li>
            </ul>
          </div>
          <div>
            <label> First Name</label>{" "}
            <input
              onChange={(e) => {
                this.setState({ firstName: e.target.value });
              }}
              value={this.state.firstName}
              type="text"
            />
            <label> Last Name</label>{" "}
            <input
              onChange={(e) => this.setState({ lastName: e.target.value })}
              value={this.state.lastName}
              type="text"
            />
            <label> Order Id</label>{" "}
            <input
              onChange={(e) => this.setState({ orderId: e.target.value })}
              value={this.state.orderId}
              type="text"
            />
            <label> Access Code</label>{" "}
            <input
              onChange={(e) => this.setState({ accessCode: e.target.value })}
              value={this.state.accessCode}
              type="text"
            />
            <label> Merchant Id</label>{" "}
            <input
              onChange={(e) => this.setState({ merchantId: e.target.value })}
              value={this.state.merchantId}
              type="text"
            />
            <label> SHA Request Phrase</label>{" "}
            <input
              onChange={(e) =>
                this.setState({ shaRequestPhrase: e.target.value })
              }
              value={this.state.shaRequestPhrase}
              type="text"
            />
            <label> SHA Response Phrase</label>{" "}
            <input
              onChange={(e) =>
                this.setState({ shaResponsePhrase: e.target.value })
              }
              type="text"
              value={this.state.shaResponsePhrase}
            />
            <label> Extra</label>{" "}
            <input
              onChange={(e) => this.setState({ extra: e.target.value })}
              type="text"
              value={this.state.extra}
            />
            <button onClick={this.onSubmit}> Submit</button>
          </div>
        </div>

        <hr />

        <div
          style={{
            backgroundColor: "white",
            borderBottom: "2px solid LightGray",
          }}
          className="mt-3"
        >
          <div className="screenlet-title-bar">
            <ul>
              <li className="h3">Create Payment for Paypal</li>
            </ul>
          </div>
          <div>
            <label> First Name</label> <input type="text" />
            <label> Last Name</label> <input type="text" />
            <label> Order Id</label> <input type="text" />
            <label> Amount</label> <input type="text" />
            <label> Merchant Id</label> <input type="text" />
            <label> Merchant Key</label> <input type="text" />
            <label> Payer Id</label> <input type="text" />
            <label> Email</label> <input type="text" />
            <button>Submit</button>
          </div>
        </div>

        <hr />

        <div
          style={{
            backgroundColor: "white",
            borderBottom: "2px solid LightGray",
          }}
        >
          <div className="screenlet-title-bar">
            <ul>
              <li className="h3">Verify Payment</li>
            </ul>
          </div>

          <div>
            <input type="text" />
            <button>Submit</button>
          </div>
        </div>

        <hr />

        <div
          style={{
            backgroundColor: "white",
            borderBottom: "2px solid LightGray",
          }}
        >
          <div className="screenlet-title-bar">
            <ul>
              <li className="h3">Refund Payment</li>
            </ul>
          </div>

          <div>
            <label> Order Id </label> <input type="text" />
            <label> Amount </label> <input type="text" />
            <button>Submit</button>
          </div>
        </div>
      </div>
    );
  }
  onSubmit = (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      orderId,
      accessCode,
      merchantId,
      shaRequestPhrase,
      shaResponsePhrase,
      extra,
    } = this.state;
    let data = {};
    let name = "";
    if (firstName) {
      name = firstName;
    }
    if (lastName) {
      name = name + "," + lastName;
    }
    data = name ? { ...data, name } : { ...data };
    data = orderId ? { ...data, orderId } : { ...data };
    data = accessCode ? { ...data, accessCode } : { ...data };
    data = merchantId ? { ...data, merchantId } : { ...data };
    data = shaRequestPhrase ? { ...data, shaRequestPhrase } : { ...data };
    data = shaResponsePhrase ? { ...data, shaResponsePhrase } : { ...data };
    data = extra ? { ...data, extra } : { ...data };
    this.setState({
      firstName: "",
      lastName: "",
      orderId: "",
      accessCode: "",
      merchantId: "",
      shaRequestPhrase: "",
      shaResponsePhrase: "",
      extra,
    });
    this.props.create(data);
  };
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch, router) => ({
  create: (data) => dispatch(createOrders(data, router)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TestPaymentGateways)
);
