import React, { Component } from "react";
import { connect } from "react-redux";

export class FindReturns extends Component {
  render() {
    return (
      <div id="screenlet_1" class="screenlet">
        <div class="screenlet-title-bar">
          <ul>
            <li class="h3">Find Return</li>
          </ul>
        </div>
        <div id="screenlet_1_col" class="screenlet-body">
          <div>
            <a class="buttontext" href="/ordermgr/control/returnMain">
              {" "}
              Create new return{" "}
            </a>
          </div>
          <form
            id="FindReturns"
            class="basic-form"
            action="/ordermgr/control/findreturn"
            method="post"
            name="FindReturns"
          >
            <input
              id="FindReturns_noConditionFind"
              name="noConditionFind"
              type="hidden"
              value="Y"
            />
            <table class="basic-table" cellspacing="0">
              <tbody>
                <tr>
                  <td class="label">
                    <label
                      id="FindReturns_returnHeaderTypeId_title"
                      for="FindReturns_returnHeaderTypeId"
                    >
                      Return HeaderType Id
                    </label>
                  </td>
                  <td>
                    <span class="ui-widget">
                      <select
                        id="FindReturns_returnHeaderTypeId"
                        name="returnHeaderTypeId"
                        size="1"
                      >
                        <option value=""> </option>
                        <option value="CUSTOMER_RETURN">
                          Return from Customer
                        </option>
                        <option value="VENDOR_RETURN">Return to Vendor</option>
                      </select>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="label">
                    <label
                      id="FindReturns_returnId_title"
                      for="FindReturns_returnId"
                    >
                      Return ID
                    </label>
                  </td>
                  <td>
                    <select class="selectBox" name="returnId_op">
                      <option value="equals">Equals</option>
                      <option value="like">Begins With</option>
                      <option selected="selected" value="contains">
                        Contains
                      </option>
                      <option value="empty">Is Empty</option>
                      <option value="notEqual">Not Equal</option>
                    </select>
                    <input name="returnId" size="25" type="text" />{" "}
                    <input
                      checked="checked"
                      name="returnId_ic"
                      type="checkbox"
                      value="Y"
                    />{" "}
                    Ignore Case
                  </td>
                </tr>
                <tr>
                  <td class="label">
                    <label
                      id="FindReturns_fromPartyId_title"
                      for="FindReturns_fromPartyId"
                    >
                      Return From Party
                    </label>
                  </td>
                  <td>
                    <div id="0_lookupId_FindReturns_fromPartyId_auto"> </div>
                    <span class="field-lookup">
                      <input
                        id="0_lookupId_FindReturns_fromPartyId"
                        class="ui-autocomplete-input"
                        autocomplete="off"
                        name="fromPartyId"
                        size="25"
                        type="text"
                      />{" "}
                      <a href id="0_lookupId_button">
                        {" "}
                      </a>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="label">
                    <label
                      id="FindReturns_billingAccountId_title"
                      for="FindReturns_billingAccountId"
                    >
                      Billing Account
                    </label>
                  </td>
                  <td>
                    <select class="selectBox" name="billingAccountId_op">
                      <option value="equals">Equals</option>
                      <option value="like">Begins With</option>
                      <option selected="selected" value="contains">
                        Contains
                      </option>
                      <option value="empty">Is Empty</option>
                      <option value="notEqual">Not Equal</option>
                    </select>
                    <input name="billingAccountId" size="25" type="text" />{" "}
                    <input
                      checked="checked"
                      name="billingAccountId_ic"
                      type="checkbox"
                      value="Y"
                    />{" "}
                    Ignore Case
                  </td>
                </tr>
                <tr>
                  <td class="label">
                    <label
                      id="FindReturns_statusId_title"
                      for="FindReturns_statusId"
                    >
                      Status
                    </label>
                  </td>
                  <td>
                    <span class="ui-widget">
                      <select
                        id="FindReturns_statusId"
                        name="statusId"
                        size="1"
                      >
                        <option value=""> </option>
                        <option value="RETURN_ACCEPTED">Accepted</option>
                        <option value="RETURN_CANCELLED">Cancelled</option>
                        <option value="RETURN_COMPLETED">Completed</option>
                        <option value="RETURN_MAN_REFUND">
                          Manual Refund Required
                        </option>
                        <option value="RETURN_RECEIVED">Received</option>
                        <option value="RETURN_REQUESTED">Requested</option>
                      </select>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="label">
                    <label
                      id="FindReturns_entryDate_title"
                      for="FindReturns_entryDate"
                    >
                      Entry Date
                    </label>
                  </td>
                  <td>
                    <span class="view-calendar">
                      <input
                        id="entryDate_fld0_value"
                        class="hasDatepicker"
                        title="dd-MM-yyyy HH:mm:ss"
                        maxlength="30"
                        name="entryDate_fld0_value"
                        size="25"
                        type="text"
                      />
                      <button
                        class="ui-datepicker-trigger"
                        type="button"
                      ></button>
                      <select class="selectBox" name="entryDate_fld0_op">
                        <option selected="selected" value="equals">
                          Equals
                        </option>
                        <option value="sameDay">Same Day</option>
                        <option value="greaterThanFromDayStart">
                          Greater Than From Day Start
                        </option>
                        <option value="greaterThan">Greater Than</option>
                      </select>
                      <input
                        id="entryDate_fld1_value"
                        class="hasDatepicker"
                        title="dd-MM-yyyy HH:mm:ss"
                        maxlength="30"
                        name="entryDate_fld1_value"
                        size="25"
                        type="text"
                      />
                      <button
                        class="ui-datepicker-trigger"
                        type="button"
                      ></button>
                      <select class="selectBox" name="entryDate_fld1_op">
                        <option value="opLessThan">Less Than</option>
                        <option value="upToDay">Up To Day</option>
                        <option value="upThruDay">Up Through Day</option>
                        <option value="empty">Is Empty</option>
                      </select>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="label"> </td>
                  <td colspan="4">
                    <input
                      class="smallSubmit"
                      name="submitButton"
                      type="submit"
                      value="Find"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
          <div class="nav-pager">
            <ul>
              <li class="nav-first-disabled">First</li>
              <li class="nav-previous-disabled">Previous</li>
              <li class="nav-next-disabled">Next</li>
              <li class="nav-last-disabled">Last</li>
              <li class="nav-pagesize">
                <select
                  name="pageSize"
                  size="1"
                  onchange="submitPagination(this, '')"
                >
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option selected="selected" value="200">
                    200
                  </option>
                </select>
                Items per page
              </li>
              <li class="nav-displaying">Displaying 1 - 0 of 0</li>
            </ul>
          </div>
          <table class="basic-table hover-bar" cellspacing="0">
            <tbody>
              <tr class="header-row">
                <td>
                  <label
                    id="ListReturns_returnId_title"
                    for="ListReturns_returnId"
                  >
                    Return ID
                  </label>
                </td>
                <td>
                  <label
                    id="ListReturns_entryDate_title"
                    for="ListReturns_entryDate"
                  >
                    Entry Date
                  </label>
                </td>
                <td>
                  <label
                    id="ListReturns_fromPartyId_title"
                    for="ListReturns_fromPartyId"
                  >
                    Return From Party
                  </label>
                </td>
                <td>
                  <label
                    id="ListReturns_destinationFacilityId_title"
                    for="ListReturns_destinationFacilityId"
                  >
                    Destination Facility
                  </label>
                </td>
                <td>
                  <label
                    id="ListReturns_statusId_title"
                    for="ListReturns_statusId"
                  >
                    Status
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="nav-pager">
            <ul>
              <li class="nav-first-disabled">First</li>
              <li class="nav-previous-disabled">Previous</li>
              <li class="nav-next-disabled">Next</li>
              <li class="nav-last-disabled">Last</li>
              <li class="nav-pagesize">
                <select
                  name="pageSize"
                  size="1"
                  onchange="submitPagination(this, '')"
                >
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option selected="selected" value="200">
                    200
                  </option>
                </select>
                Items per page
              </li>
              <li class="nav-displaying">Displaying 1 - 0 of 0</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FindReturns);
