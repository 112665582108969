import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Collapse } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
// import AddIcon from "@material-ui/icons/Add";
import { withRouter, Link } from "react-router-dom";
// import RemoveIcon from "@material-ui/icons/Remove";
import { getUsers } from "../../../store/actions/authActions";
import { searchCustomer } from "../../../store/actions/orderActions";

export class UserPopUp extends Component {
  state = {
    collapse: false,
    collapseInner: true,
    categories: [],
    pageNo: 1,
    limit: 10,
    users: [],
    searchQuery: "",
  };

  static getDerivedStateFromProps(props, state) {
    if (!props.modalOpen && state.searchQuery) {
      return {
        searchQuery: "",
      };
    }
    return null; // No change to state
  }
  async componentDidMount() {
    let users = await this.props.search();
    this.setState({ users: users });
  }

  handleChange = (e) => {
    this.setState({ searchQuery: e.target.value.trim() }, () => {
      if (this.state.searchQuery)
        this.props.searchCustomer(this.state.searchQuery);
    });
  };

  render() {
    // console.log("these are categories", this.props.categories);
    const customers = this.state.searchQuery
      ? this.props.customers
      : this.state.users;
    return (
      <div>
        <Modal
          open={this.props.modalOpen}
          onClose={this.props.handleClose}
          //   style={{ overflow: "scroll" }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            style={{
              height: "600px",
              width: "800px",
              backgroundColor: "white",
              position: "absolute",
              top: "50px",
              border: "2px solid white",
              left: "600px",
              alignItems: "center",
              display: "flex",
              overflow: "scroll",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                backgroundColor: "#527894",
                borderRadius: "5px",
                width: "100%",
                height: "30px",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <CloseIcon
                onClick={(e) => this.props.handleClose(e)}
                style={{
                  color: "#6caad4",
                  marginRight: "5px",
                  backgroundColor: "#e3f1fc",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              />
            </div>
            <input
              // className="form-control"
              list="datalistOptions"
              id="exampleDataList"
              placeholder="Type to search customer..."
              autocomplete="off"
              size="60"
              style={{
                height: "1.8em",
                border: "1px solid",
                margin: 10,
                padding: 10,
              }}
              onChange={this.handleChange}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "10px",
                width: "100%",
              }}
            >
              <button
                onClick={() => {
                  if (this.state.pageNo > 1) {
                    this.setState({ pageNo: this.state.pageNo - 1 });
                  }
                }}
                style={{
                  display: "flex",
                  marginLeft: "10px",
                }}
              >
                Previous
              </button>
              <button
                onClick={() => {
                  let pages = Math.ceil(customers.length / this.state.limit);
                  if (this.state.pageNo < pages) {
                    this.setState({ pageNo: this.state.pageNo + 1 });
                  }
                }}
                style={{
                  display: "flex",
                  marginRight: "10px",
                }}
              >
                next
              </button>
            </div>
            <div style={{ width: "100%" }}>
              <table class="basic-table" cellspacing="15">
                <tr>
                  <th width="10%"> UserId</th>
                  <th width="10%">Email Address</th>
                  <th width="10%">PhoneNo</th>
                </tr>
                {customers.length ? (
                  customers.map(
                    (category, index) =>
                      (this.state.pageNo - 1) * this.state.limit <= index &&
                      this.state.pageNo * this.state.limit > index && (
                        <tr key={index}>
                          <td width="10%">
                            <button
                              onClick={() =>
                                this.props.handleUserSelect(category._id)
                              }
                            >
                              {category._id}
                            </button>
                          </td>
                          <td width="10%">{category.email}</td>
                          <td width="10%">{category.mobile}</td>
                        </tr>
                      )
                  )
                ) : (
                  <tr>
                    <td
                      colSpan="3"
                      style={{ textAlign: "center", fontSize: 16 }}
                    >
                      No data found
                    </td>
                  </tr>
                )}
              </table>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
  componentDidUpdate(prevProps) {
    if (this.props.categories !== prevProps.categories) {
      this.setState({ categories: this.props.categories });
    }
  }
}
const mapStateToProps = (state) => ({
  categories: state.catalog?.categories?.categories_searched,
  customers: state?.orderReducer?.customers,
});

const mapDispatchToProps = (dispatch, router) => ({
  search: () => dispatch(getUsers()),
  searchCustomer: (q) => dispatch(searchCustomer(q)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserPopUp)
);
